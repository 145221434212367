<script setup>
import { computed, ref } from 'vue';
import { useUpdatePackConfiguration } from '@/queries/pack-configurations/useUpdatePackConfiguration';
import { usePriorityError } from '@/composables/usePriorityError';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';

const props = defineProps({
  pack: {
    type: Object,
    required: true,
  },
});

const showConfirmationDialog = ref(false);

const packId = computed(() => props.pack.id);
const packName = computed(() => props.pack.name);
const packStatus = computed(() => props.pack.status);

const packCategory = computed(() => {
  return props.pack.isStopMotion ? 'stop motion' : props.pack.category;
});

const formattedDate = computed(() => {
  return new Date(props.pack.updated_at).toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
});

const nextStatus = computed(() => {
  const nextStatusLookup = {
    draft: {
      status: 'published',
      headerText: `publish ${packName.value}`,
      buttonText: 'publish',
      copy: 'once a pack is published, you will no longer be able to edit most of the pack details.',
    },
    published: {
      status: 'archived',
      headerText: `archive ${packName.value}`,
      buttonText: 'archive',
      copy: 'archiving a pack makes it unavailable to customers.',
    },
    archived: {
      status: 'published',
      headerText: `unarchive and publish ${packName.value}`,
      buttonText: 'publish',
      copy: 'this pack will be immediately available to customers.',
    },
  };
  return nextStatusLookup[packStatus.value];
});

const {
  mutate: updatePackConfiguration,
  error: mutateError,
  isPending: isMutating,
} = useUpdatePackConfiguration();

const priorityError = usePriorityError(mutateError);

const toggleConfirmationDialog = status => {
  showConfirmationDialog.value = status;
};

const handlePackStatusChange = () => {
  updatePackConfiguration(
    {
      id: packId.value,
      status: nextStatus.value.status,
    },
    {
      onSuccess: () => {
        toggleConfirmationDialog(false);
      },
    }
  );
};
</script>

<template>
  <div
    class="pack-card"
    :class="{ 'pack-card--archived': packStatus === 'archived' }"
    :data-cypress="`component-pack-card-${pack.name
      .toLowerCase()
      .replace(/\s+/g, '-')}`"
  >
    <p class="pack-card__name" data-cypress="text-pack-name">
      {{ pack.name }}
    </p>
    <div class="pack-card__details">
      <div class="pack-card__category">
        <div
          class="pack-card__category-inner"
          :class="`pack-card__category--${
            packCategory === 'stop motion' ? 'stop-motion' : packCategory
          }`"
        >
          <p>{{ packCategory }}</p>
        </div>
      </div>
      <time :datetime="pack.updated_at" class="pack-card__created">
        {{ formattedDate }}
      </time>
      <div class="pack-card__status">
        <div
          class="pack-card__status-inner"
          :class="`pack-card__status--${packStatus}`"
        >
          <p data-cypress="text-pack-status">{{ packStatus }}</p>
        </div>
      </div>
    </div>
    <div class="pack-card__action">
      <SoonaButton
        v-if="packStatus === 'draft'"
        class="pack-card__action pack-card__action--publish"
        :variation="'secondary-black'"
        size="medium"
        :copy="'publish'"
        @click="toggleConfirmationDialog(true)"
      />
      <button
        v-if="packStatus === 'published'"
        class="u-button-reset pack-card__action--btn-archive"
        @click="toggleConfirmationDialog(true)"
      >
        archive
      </button>
      <SoonaButton
        v-if="packStatus === 'archived'"
        class="pack-card__action pack-card__action--unarchive"
        :variation="'tertiary'"
        size="medium"
        :copy="'unarchive'"
        @click="toggleConfirmationDialog(true)"
      />
      <SoonaButton
        v-if="packStatus !== 'archived'"
        element="router-link"
        variation="tertiary"
        size="medium"
        :copy="'edit'"
        :to="{
          name: 'crew-packs-edit',
          params: { packConfigurationId: props.pack.id },
        }"
      />
      <SoonaButton
        v-if="packStatus === 'archived'"
        element="router-link"
        :variation="'tertiary'"
        size="medium"
        copy="view"
        :to="{
          name: 'crew-packs-edit',
          params: { packConfigurationId: props.pack.id },
        }"
      />
    </div>
  </div>
  <SoonaDialog
    v-if="showConfirmationDialog"
    @close="() => toggleConfirmationDialog(false)"
  >
    <template #heading>{{ nextStatus.headerText }}?</template>
    {{ nextStatus.copy }}
    <SoonaError v-if="priorityError">
      {{ priorityError }}
    </SoonaError>
    <template #footer="{ close }">
      <SoonaButton variation="tertiary" @on-click="close">cancel</SoonaButton>
      <SoonaButton
        data-cypress="button-dialog-confirm"
        @on-click="() => handlePackStatusChange()"
      >
        {{ nextStatus.buttonText }}
      </SoonaButton>
    </template>
  </SoonaDialog>
  <SoonaLoading loading-text="updating..." :is-loading="isMutating" />
</template>

<style lang="scss" scoped>
@use '@/variables_fonts';
@use '@/variables';

.pack-card {
  align-items: baseline;
  background: variables.$white-default;
  border: 1px solid variables.$gray-30;
  border-radius: 0.625rem;
  column-gap: 0.5rem;
  display: grid;
  grid-template-columns: 0.75fr 1.25fr 0.75fr;
  margin-bottom: 1rem;
  padding: 2rem;
  width: 100%;

  &--archived {
    background-color: variables.$gray-30;

    .pack-card__name {
      color: variables.$gray-50;
    }

    .pack-card__created {
      color: variables.$gray-60;
    }
  }

  &__name {
    @include variables_fonts.u-subheader--heavy;
  }

  &__details {
    align-items: baseline;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.5rem;
  }

  &__category {
    &-inner {
      border-radius: 3.125rem;
      padding: 0.3125rem 0.625rem;
      padding-top: 0.4375rem;
      width: max-content;
    }

    &--photo {
      background: variables.$tangerine-20;
    }

    &--video {
      background-color: variables.$green-apple-20;
    }

    &--stop-motion {
      background-color: variables.$bubbletape-pink-20;
    }

    p {
      color: variables.$black-default;
      font-size: 11px;
      font-weight: 800;
      justify-self: end;
      letter-spacing: 0.09375rem;
      text-transform: uppercase;
    }
  }

  &__created {
    @include variables_fonts.u-label--regular;
  }

  &__status {
    &-inner {
      border-radius: 0.0625rem;
      padding: 0.25rem 0.5rem;
      width: max-content;
    }

    &--published {
      background-color: variables.$avo-toast-20;
      border: 0.0625rem solid variables.$avo-toast-30;

      p {
        color: variables.$avo-toast-40;
      }
    }

    &--draft {
      background-color: variables.$gray-20;
      border: 0.0625rem solid variables.$gray-30;

      p {
        color: variables.$gray-60;
      }
    }

    &--archived {
      background-color: variables.$roses-20;
      border: 0.0625rem solid variables.$roses-30;

      p {
        color: variables.$roses-60;
      }
    }

    p {
      @include variables_fonts.u-badge--big;
    }
  }

  &__action {
    justify-self: end;

    &--btn-archive {
      @include variables_fonts.u-body--regular;

      border: 0.0625rem solid transparent;
      border-radius: 0.3125rem;
      color: variables.$roses-70;
      padding: 0.5rem 1rem;
      text-decoration: underline;

      &:hover,
      &:focus-visible {
        background-color: variables.$gray-10;
        color: variables.$roses-80;
      }

      &:active {
        background-color: variables.$gray-20;
        color: variables.$roses-60;
      }
    }

    &--publish,
    &--unarchive,
    &__action {
      margin-right: 1rem;
    }
  }
}

@media (max-width: variables.$screen-xs-max) {
  .pack-card {
    grid-template-columns: 1fr;
    row-gap: 1rem;

    &__created {
      justify-self: center;
    }
    &__status {
      justify-self: end;
    }
  }
}
</style>
