<script setup>
import { computed } from 'vue';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useRoute } from 'vue-router';
import { useReservationTimeline } from '@/queries/reservations/useTimeline';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

const props = defineProps({
  reservationId: {
    default: undefined,
    type: Number,
    required: true,
  },
});
const reservationId = computed(() => props.reservationId);
const timezone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;
const { data: timelineData } = useReservationTimeline(reservationId, {
  timezone,
});
const timeline = computed(() => timelineData.value);

const stepStatus = stepNum => {
  let statuses = { modifierClass: '', text: null };

  if (stepNum < timeline.value?.step_num) {
    statuses.modifierClass += 'timeline__step--filled';
    statuses.text = 'completed';
  }

  if (stepNum - 1 === timeline.value?.step_num) statuses.text = 'upcoming';

  if (stepNum === timeline.value?.step_num) {
    if (stepNum === 5) {
      statuses.modifierClass += 'timeline__step--filled';
      statuses.text = 'completed';
    } else {
      statuses.modifierClass += 'timeline__step--active';
      statuses.text = 'in-progress';
    }
  }

  return statuses;
};

const { linkClicked } = useBaseEvents();
const route = useRoute();
function handleCtaClick(ctaText) {
  if (ctaText !== null) {
    linkClicked({
      context: route.meta.context,
      subContext: 'timeline',
      linkLabel: ctaText,
      linkHref: '/reservation',
    });
  }
}
</script>

<template>
  <section class="timeline">
    <header class="timeline__header">
      <h2 class="timeline__status">
        <strong>{{ timeline?.status }}</strong> {{ timeline?.status_subtitle }}
      </h2>
    </header>
    <p class="u-a11y-only">booking steps:</p>
    <ol class="timeline__steps">
      <li
        class="u-small--regular timeline__step"
        :class="stepStatus(1).modifierClass"
        :title="stepStatus(1).text ? `${stepStatus(1).text} step` : null"
      >
        <SoonaIcon name="clipboard-list" size="medium" />
        <span v-if="stepStatus(1).text" class="u-a11y-only"
          >{{ stepStatus(1).text }}: </span
        >shoot prep
      </li>
      <li
        class="u-small--regular timeline__step"
        :class="stepStatus(2).modifierClass"
        :title="stepStatus(2).text ? `${stepStatus(2).text} step` : null"
      >
        <SoonaIcon name="calendar-day" size="medium" />
        <span v-if="stepStatus(2).text" class="u-a11y-only"
          >{{ stepStatus(2).text }}: </span
        >soona schedules
      </li>
      <li
        class="u-small--regular timeline__step"
        :class="stepStatus(3).modifierClass"
        :title="stepStatus(3).text ? `${stepStatus(3).text} step` : null"
      >
        <SoonaIcon name="camera-alt-1" size="medium" />
        <span v-if="stepStatus(3).text" class="u-a11y-only"
          >{{ stepStatus(3).text }}: </span
        >shoot
      </li>
      <li
        class="u-small--regular timeline__step"
        :class="stepStatus(4).modifierClass"
        :title="stepStatus(4).text ? `${stepStatus(4).text} step` : null"
      >
        <SoonaIcon name="pen-swirl" size="medium" />
        <span v-if="stepStatus(4).text" class="u-a11y-only"
          >{{ stepStatus(4).text }}: </span
        >editing
      </li>
      <li
        class="u-small--regular timeline__step"
        :class="stepStatus(5).modifierClass"
        :title="stepStatus(5).text ? `${stepStatus(5).text} step` : null"
      >
        <SoonaIcon name="image-square" size="medium" />
        <span v-if="stepStatus(5).text" class="u-a11y-only"
          >{{ stepStatus(5).text }}: </span
        >final edits
      </li>
    </ol>
    <SoonaButton
      v-if="timeline?.cta_text"
      element="router-link"
      to="./"
      :copy="timeline?.cta_text"
      @on-click="handleCtaClick(timeline?.cta_text)"
    />
  </section>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.timeline {
  &__header {
    margin-bottom: 0.75rem;
    color: variables.$black-default;
  }

  &__status {
    @include variables_fonts.u-body--regular;

    strong {
      font-weight: 800;
    }
  }

  &__steps {
    display: flex;
    gap: 0.25rem;
    list-style: none;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  &__step {
    @include variables_fonts.u-small--regular;

    flex: 1 1 20%;
    display: block;
    text-align: center;
    color: variables.$gray-60;
    transition: color 0.3s ease-out;

    svg {
      display: block;
      margin: 0 auto;
      color: variables.$gray-40;
      transition: color 0.3s ease-out;
    }

    &::before {
      content: '';
      display: block;
      border-radius: 0.3125rem;
      height: 0.375rem;
      background-color: variables.$gray-20;
      margin-bottom: 0.25rem;
      transition: background-color 0.3s ease-out;
    }

    &--filled::before {
      background-color: variables.$periwink-blue-50;
    }

    &--active {
      color: variables.$black-default;

      &::before {
        animation: animate-active 2s infinite linear reverse;
        background-image: linear-gradient(
          90deg,
          #5566ea,
          #ccd4ff,
          #5566ea,
          #ccd4ff
        );
        background-size: 300% 100%;
      }

      svg {
        color: variables.$gray-60;
      }
    }
  }

  @media (min-width: variables.$screen-sm-min) {
    &__header {
      margin-bottom: 1rem;
    }

    &__status {
      @include variables_fonts.u-subheader--regular;
    }

    &__steps {
      gap: 0.5rem;
    }
  }
}

@keyframes animate-active {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 0;
  }
}
</style>
