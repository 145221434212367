<script setup>
import { computed, ref, watch } from 'vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaSkeleton from '@/components/ui_library/SoonaSkeleton.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import {
  useGetCatalogItemShopifyProducts,
  useCatalogItem,
} from '@/queries/useCatalog';
import { usePriorityError } from 'src/composables/usePriorityError';

const props = defineProps({
  accountId: {
    type: [Number, String],
    required: true,
  },
  productUpdating: {
    type: Boolean,
    required: true,
  },
  productId: {
    type: [Number, String],
    required: true,
  },
  file: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['slot-selected']);
const selectedImageSlot = ref({});
const accountId = computed(() => props.accountId);
const listingUpdating = computed(() => props.listingUpdating);
const productId = computed(() => props.productId);
const file = computed(() => props.file);
const filename = computed(() => file.value?.title ?? '');
const filePreviewUrl = computed(
  () => file.value?.preview?.url ?? file.value?.preview_url ?? ''
);

const {
  data: shopifyProductData,
  isLoading: isShopifyProductLoading,
  error: shopifyProductError,
} = useGetCatalogItemShopifyProducts(accountId, productId);

const {
  data: catalogItemData,
  isLoading: isCatalogItemLoading,
  error: catalogItemError,
} = useCatalogItem(accountId, productId);

const isLoading = computed(
  () =>
    isShopifyProductLoading.value ||
    isCatalogItemLoading.value ||
    listingUpdating.value
);

const priorityError = usePriorityError(shopifyProductError, catalogItemError);

const shopifyProduct = computed(() => shopifyProductData.value?.data[0] ?? {});

const isVariant = computed(() => shopifyProduct.value?.variant_id ?? false);

const imageSlots = computed(() => {
  let imagesHash = {};
  shopifyProduct.value.images?.forEach(
    (image, index) => (imagesHash[index] = image)
  );
  const hashLength = Object.keys(imagesHash).length;
  if (!isVariant.value || hashLength === 0) {
    imagesHash[hashLength] = null;
  }
  return imagesHash;
});

const catalogItem = computed(() => catalogItemData.value);

const publishingOperations = computed(() => {
  const operations = [];
  Object.keys(imageSlots.value).forEach(index => {
    const image = imageSlots.value[index];
    const replacementImage = selectedImageSlot.value[index];
    const imagePosition = image?.position;
    if (replacementImage) {
      operations.push({
        filename: filename.value,
        position: imagePosition,
        src: replacementImage.src,
      });
    } else if (image) {
      operations.push({
        position: imagePosition,
        id: image.id,
      });
    }
  });
  return operations;
});

function selectImageSlot(slotPosition) {
  selectedImageSlot.value = {};
  selectedImageSlot.value[slotPosition] = {
    src: filePreviewUrl.value,
  };
}

watch(publishingOperations, operations => {
  if (Object.keys(selectedImageSlot.value).length > 0) {
    emit('slot-selected', operations);
  }
});
</script>
<template>
  <div class="shopify-publish-image-selector">
    <template v-if="isLoading">
      <SoonaSkeleton
        class="shopify-publish-image-selector__loading-slot"
        randomize-timing
      />
    </template>
    <SoonaError v-else-if="priorityError">
      we are having trouble retrieving your Shopify product information. please
      try again later.
    </SoonaError>
    <div v-else class="shopify-publish-image-selector__image-preview">
      <div class="shopify-publish-image-selector__image-wrapper">
        <p class="shopify-publish-image-selector__product-name">
          {{ catalogItem?.name ?? '' }}
        </p>
        <img
          class="shopify-publish-image-selector__image"
          :src="filePreviewUrl"
          alt=""
        />
        <p class="shopify-publish-image-selector__filename">
          {{ file?.title ?? '' }}
        </p>
      </div>
    </div>
    <div class="shopify-publish-image-selector__image-slots-wrapper">
      <h3 class="shopify-publish-image-selector__title">
        select an image slot in your Shopify gallery
      </h3>
      <p class="shopify-publish-image-selector__subtitle">
        your new asset can fill an empty slot or replace an existing image.
      </p>
      <div class="shopify-publish-image-selector__image-slots">
        <div
          v-for="(slot, index) in imageSlots"
          :key="index"
          class="shopify-publish-image-selector__image-slot"
        >
          <div v-if="isLoading || priorityError">
            <div class="shopify-publish-image-selector__loading-slot-wrapper">
              <SoonaSkeleton
                class="shopify-publish-image-selector__loading-slot"
                randomize-timing
              />
            </div>
          </div>
          <div v-else>
            <div
              v-if="selectedImageSlot[index] || imageSlots[index]"
              class="shopify-publish-image-selector__slot-image-wrapper"
            >
              <img
                class="shopify-publish-image-selector__slot-image"
                :src="selectedImageSlot[index]?.src || imageSlots[index].src"
                :class="[
                  {
                    'shopify-publish-image-selector__slot-image--selected':
                      selectedImageSlot[index],
                  },
                ]"
              />
              <div
                v-if="imageSlots[index] && !selectedImageSlot[index]"
                class="shopify-publish-image-selector__image-replace-button-wrapper"
              >
                <SoonaButton
                  class="shopify-publish-image-selector__image-replace-button"
                  variation="secondary-black"
                  size="small"
                  @click="() => selectImageSlot(index)"
                >
                  replace
                </SoonaButton>
              </div>
            </div>
            <div
              v-else
              class="shopify-publish-image-selector__default-image"
              @click="() => selectImageSlot(index)"
            >
              <SoonaIcon name="plus" />
            </div>
          </div>
          <p class="shopify-publish-image-selector__slot-title">
            {{ index === '0' ? 'main' : Number(index) + 1 }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables';

.shopify-publish-image-selector {
  &__image-preview {
    display: flex;
    align-items: center;
    flex-direction: column;

    @media (min-width: variables.$screen-sm-min) {
      flex-direction: row;
    }
  }

  &__icon {
    flex: 1;
    color: variables.$gray-60;
    display: none;

    @media (min-width: variables.$screen-sm-min) {
      display: block;
    }
  }

  &__product-name {
    font-weight: 700;
  }

  &__product-name {
    flex: 5;
  }

  &__image-wrapper {
    flex: 1;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    width: 100%;
  }

  &__image {
    max-height: 10rem;
  }

  &__image-slots-wrapper {
    margin-top: 2rem;
  }

  &__title {
    font-weight: 800;
  }

  &__subtitle {
    color: variables.$gray-60;
  }

  &__image-slots {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
    gap: 1rem;
  }

  &__image-slot {
    position: relative;
    width: 100%;
    margin-bottom: 2rem;
    text-align: center;
    cursor: pointer;

    @media (min-width: variables.$screen-xs-min) {
      width: 45%;
    }

    @media (min-width: variables.$screen-sm-min) {
      width: 31.5%;
    }
  }

  &__default-image {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: variables.$gray-10;
    border: 0.125rem dashed variables.$gray-40;
    color: variables.$gray-60;
    cursor: pointer;

    &:hover {
      background-color: variables.$gray-20;
    }
  }

  &__slot-image-wrapper {
    position: relative;
    min-height: 10rem;
    max-height: 10rem;
    margin-bottom: 1rem;

    &:hover {
      .shopify-publish-image-selector__image-replace-button-wrapper {
        opacity: 1;
      }
    }
  }

  &__image-replace-button-wrapper {
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: 0.7s ease;
    background-color: rgba(0, 0, 0, 0.5);
    min-height: 10rem;
    max-height: 10rem;
    border-radius: 0.3125rem;
  }

  &__image-replace-button {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 1;
    transition: 0.5s ease;
  }

  &__slot-image,
  &__default-image,
  &__loading-slot {
    min-height: 10rem;
    max-height: 10rem;
    border-radius: 0.3125rem;
    transition: 0.5s ease;
    height: 100%;
    margin-bottom: 1rem;
  }

  &__slot-image--selected {
    outline-color: variables.$periwink-blue-60;
    outline-style: solid;
    outline-width: 0.125rem;
    padding: 0.1rem;
  }

  &__slot-title {
    font-weight: 800;
  }

  &__slot-title,
  &__filename {
    font-size: 0.875rem;
  }
}
</style>
