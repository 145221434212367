export const paths = [
  {
    path: '/account/:accountId/listing-insights',
    name: 'listing-insights-dashboard',
    component: () =>
      import(
        '@/components/user/anytime/listing_insights/dashboard/Dashboard.vue'
      ),
    meta: {
      context: 'listing insights dashboard',
      requires_auth: true,
      show_quest: true,
    },
    props: route => ({
      // force the component to unmount and mount again when the accountId changes
      key: route.params.accountId,
      accountId: route.params.accountId,
    }),
  },
  {
    path: '/account/:accountId/listing-insights/listings',
    name: 'listing-insights-store-list',
    component: () =>
      import(
        '@/components/user/anytime/listing_insights/listings_list/ListingsList.vue'
      ),
    meta: {
      context: 'listing insights store list',
      requires_auth: true,
      show_quest: true,
    },
    props: route => ({
      // force the component to unmount and mount again when the accountId changes
      key: route.params.accountId,
      accountId: route.params.accountId,
    }),
  },
  {
    path: '/example-listing/:exampleListingId',
    name: 'example-listing-detail',
    component: () =>
      import(
        '@/components/user/anytime/listing_insights/listing_detail/example/ExampleListingDetail.vue'
      ),
    meta: {
      context: 'listing insights example listing detail',
      requires_auth: true,
      show_quest: true,
    },
    props: route => ({
      exampleListingId: route.params.exampleListingId,
    }),
  },
  {
    path: '/account/:accountId/listing-insights/integration/:integrationId/external-product/:externalProductId/active-listing',
    component: () =>
      import(
        '@/components/user/anytime/listing_insights/listing_detail/ListingDetail.vue'
      ),
    meta: {
      context: 'listing insights listing detail',
      requires_auth: true,
      show_quest: true,
    },
    props: route => ({
      // force the component to unmount and mount again when the accountId changes
      key: route.params.accountId + route.params.platform,
      accountId: route.params.accountId,
      integrationId: route.params.integrationId,
      externalProductId: route.params.externalProductId,
    }),
    children: [
      {
        path: '',
        name: 'listing-optimize',
        component: () =>
          import(
            '@/components/user/anytime/listing_insights/listing_detail/ListingOptimize.vue'
          ),
        meta: {
          context: 'listing insights listing detail',
          requires_auth: true,
        },
      },
      {
        path: ':pathmatch(.*)*',
        name: 'notFound',
        redirect: () => {
          return { name: 'listing-optimize' };
        },
      },
    ],
  },
  {
    path: '/account/:accountId/listing-insights/action/:actionSlug',
    component: () =>
      import(
        '@/components/user/anytime/listing_insights/listing_actions/views/ActionViewTemplate.vue'
      ),
    meta: {
      context: 'listing insights action',
      requires_auth: true,
      requires_flag: 'toaster_listing_actions',
      show_quest: true,
    },
    props: route => ({
      // force the component to unmount and mount again when the accountId changes
      key: route.params.accountId + route.params.actionSlug,
      accountId: route.params.accountId,
      actionSlug: route.params.actionSlug,
    }),
    children: [
      {
        path: '',
        name: 'listing-insights-actions',
        component: () =>
          import(
            '@/components/user/anytime/listing_insights/listing_actions/views/ActionsList.vue'
          ),
        meta: {
          context: 'listing insights actions',
          requires_auth: true,
        },
      },
      {
        path: 'complete',
        name: 'complete-actions',
        component: () =>
          import(
            '@/components/user/anytime/listing_insights/listing_actions/views/ActionsList.vue'
          ),
        meta: {
          context: 'listing insights completed actions',
          requires_auth: true,
        },
      },
      {
        path: ':pathmatch(.*)*',
        name: 'notFound',
        redirect: () => {
          return { name: 'listing-insights-actions' };
        },
      },
    ],
  },
  {
    path: '/account/:accountId/listing-insights/action/:listingActionId/asset/:digitalAssetId',
    name: 'listing-action-media-view',
    component: () =>
      import(
        '@/components/user/anytime/listing_insights/listing_actions/views/ListingActionAssetEditor.vue'
      ),
    meta: {
      requires_flag: 'toaster_lifestyle_listing_actions',
      requires_auth: true,
      context: 'media editor',
    },
    props: route => ({
      accountId: route.params.accountId,
      digitalAssetId: route.params.digitalAssetId,
      listingActionId: route.params.listingActionId,
    }),
  },
  // REDIRECTS FROM OLD ROUTES
  {
    // will always redirect /account/:accountId/listing-insights/:platform to /account/:accountId/listing-insights
    path: '/account/:accountId/listing-insights/:platform',
    redirect: route => ({
      name: 'listing-insights-dashboard',
      query: { ...route.query },
    }),
  },
  {
    // redirect original dashboard URL to multi-integration dashboard
    path: '/account/:accountId/listing-insights/integration/:integrationId?',
    redirect: route => ({
      name: 'listing-insights-dashboard',
      query: { ...route.query },
    }),
  },
  {
    // redirect original store list URL to multi-integration store list
    path: '/account/:accountId/listing-insights/integration/:integrationId?/store-list',
    redirect: route => ({
      name: 'listing-insights-store-list',
      query: { ...route.query },
    }),
  },
];

export default paths;
