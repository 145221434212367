import industries from '@app/assets/lists/industries/industries.json';

const state = {
  industries: industries,
  // TODO: deprecate after booking_three_point_oh is fully merged in (unless shot list tags needs this specific list)
  brandCategoryIndustries: [
    { id: 0, title: 'me!', enum: 'me!', icon: '🙋‍♀️' },
    { id: 1, title: 'beauty!', enum: 'beauty', icon: '💄' },
    {
      id: 2,
      title: 'health & wellness',
      enum: 'health_and_wellness',
      icon: '🧘🏼‍♀️',
    },
    { id: 3, title: 'fashion', enum: 'fashion', icon: '👟' },
    { id: 4, title: 'food & bev', enum: 'food_and_bev', icon: '🥤' },
    { id: 5, title: 'pet products', enum: 'pet_products', icon: '🐈' },
    { id: 6, title: 'tech', enum: 'tech', icon: '📱' },
    { id: 7, title: 'toys & games', enum: 'toys_and_games', icon: '🧩' },
    { id: 8, title: 'home & garden', enum: 'home_and_garden', icon: '🏡' },
    {
      id: 9,
      title: 'professional services',
      enum: 'professional_services',
      icon: '💡',
    },
    { id: 10, title: 'CBD', enum: 'CBD', icon: '🌿' },
    { id: 11, title: 'other', enum: 'other', icon: '💬' },
  ],
};

const getters = {};

const mutations = {};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
