<script setup>
import { useRoute } from 'vue-router';
import { ref, computed } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaUpload from 'src/components/uploader/SoonaUpload.vue';
import TextArea from 'src/components/shared/TextArea.vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';

const props = defineProps({
  proServiceRequirement: {
    type: Object,
    required: true,
  },
  reservationId: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['cancel', 'submitGroceryList']);

const route = useRoute();

const { buttonClicked, inputChanged } = useBaseEvents();

// eslint-disable-next-line vue/no-setup-props-reactivity-loss
const groceryList = ref(
  props.proServiceRequirement.pro_service_extras?.grocery_list ?? ''
);
// eslint-disable-next-line vue/no-setup-props-reactivity-loss
const groceryImageUrl = ref(
  props.proServiceRequirement.grocery_shopping_reference_image?.url ?? ''
);
// eslint-disable-next-line vue/no-setup-props-reactivity-loss
const groceryImageSignedId = ref(
  props.proServiceRequirement.grocery_shopping_reference_image?.signed_id ??
    null
);

const groceryImageLabel = computed(() => {
  return groceryImageUrl.value
    ? 'replace image'
    : 'upload grocery reference image';
});

function getImgLabelHTML(label) {
  return `<u>${label}</u>. 1 image max`;
}

function handleImageUploadComplete(blob, label) {
  groceryImageUrl.value = `/rails/active_storage/blobs/${blob.signed_id}/${blob.filename}`;
  groceryImageSignedId.value = blob.signed_id;
  inputChanged({
    context: route.meta.context,
    subContext: 'pro service grocery shopping modal',
    inputLabel: label, // label is passed in so its set at time of click, not calculated after upload.
    inputType: 'file',
    inputValue: null,
  });
}

function removeRefImg() {
  groceryImageUrl.value = '';
  groceryImageSignedId.value = null;
}

function submitGroceryList() {
  buttonClicked({
    context: route.meta.context,
    subContext: `grocery shopping `,
    buttonLabel: 'save',
    buttonAction: `submits grocery shopping data`,
  });
  emit('submitGroceryList', {
    extrasPayload: { grocery_list: groceryList.value },
    imagesPayload: {
      grocery_shopping_reference_image: groceryImageSignedId.value,
    },
  });
}

function cancelGroceryList() {
  groceryList.value = '';
  groceryImageUrl.value = '';
  groceryImageSignedId.value = '';
  emit('cancel');
}
</script>

<template>
  <SoonaDialog @close="cancelGroceryList">
    <template #heading>grocery shopping details and extras</template>
    <p>add grocery list</p>
    <TextArea
      v-model:model-value="groceryList"
      class="grocery-list-text"
      :rows="8"
      placeholder="input grocery list"
    />
    <SoonaUpload
      :upload-complete="
        blob => handleImageUploadComplete(blob, groceryImageLabel)
      "
      class-overide="reference-img-btn"
      :hide-icon="true"
      :is-multiple="false"
      :label="getImgLabelHTML(groceryImageLabel)"
      accept=".jpeg, .jpg, .png, .gif"
    />
    <span v-if="groceryImageUrl" class="delete-image-wrapper">
      <img :src="groceryImageUrl" class="thumbnail-img" />
      <button class="delete-btn" @click="removeRefImg()">
        <SoonaIcon name="xmark" />
        <span class="u-a11y-only">Remove this image</span>
      </button>
    </span>
    <template #footer="{ close }">
      <SoonaButton variation="tertiary" @on-click="close">cancel</SoonaButton>
      <SoonaButton
        data-cypress="button-dialog-confirm"
        @on-click="submitGroceryList"
      >
        save
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>

<style lang="scss" scoped>
@use '@/variables';

.grocery-list-modal {
  .delete-image-wrapper {
    margin: 1.25rem 0 0 0;
    display: flex;

    .thumbnail-img {
      display: block;
      height: 5.8125rem;
      width: 5.8125rem;
      border-radius: 5px;
      object-fit: cover;
    }

    .delete-btn {
      align-items: center;
      background-color: variables.$gray-80;
      display: flex;
      height: 1.25rem;
      justify-content: center;
      width: 1.25rem;
      border-radius: 50%;
      padding-left: 0.3125rem;
      padding-top: 0;
      margin-left: -0.9375rem;
      margin-top: -0.3125rem;
    }
  }

  :deep(.file-label) {
    font-size: 0.875rem;
    letter-spacing: 0.031rem;
  }
}
</style>
