<script setup>
import { computed } from 'vue';
import { useGetAcceptableInvitation } from '@/queries/invitations/useGetAcceptableInvitation';
import { useStore } from 'vuex';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';

const props = defineProps({
  bookingId: {
    default: null,
    type: String,
  },
  errorMessage: {
    default: null,
    type: String,
  },
  invitationId: {
    required: true,
    type: String,
  },
  token: {
    required: true,
    type: String,
  },
});

const store = useStore();

const bookingId = computed(() => props.bookingId);
const errorMessage = computed(() => props.errorMessage?.replace(/_/g, ' '));
const invitationId = computed(() => props.invitationId);
const token = computed(() => props.token);

const accountId = computed(() => store.state.currentUser.accountId);
const acceptableParams = computed(() => {
  return { token: token.value };
});

const {
  data: invitation,
  isLoading,
  error: acceptableInvitationError,
} = useGetAcceptableInvitation(accountId, invitationId, acceptableParams);

const errorToDisplay = computed(() => {
  if (
    acceptableInvitationError.value &&
    acceptableInvitationError.value.response?.status !== 403
  ) {
    return acceptableInvitationError.value.message;
  }

  return errorMessage.value;
});

const canAccept = computed(
  () => invitation.value?.is_acceptable && !errorMessage.value
);

const acceptUrl = computed(() => {
  let queryParams = new URLSearchParams({ token: token.value });

  if (bookingId.value) queryParams.set('booking_id', bookingId.value);

  return `/invitations/${invitationId.value}/accept?${queryParams.toString()}`;
});

const invitationCopy = computed(() => {
  if (invitation.value?.status == 'accepted') {
    return `welcome to the squad! you've joined the team at ${invitation.value?.name}. we're excited to see what you create!`;
  } else if (canAccept.value) {
    return `hey! looks like you’ve been invited to join ${invitation.value?.name || 'someone awesome'} on soona.`;
  } else {
    return 'oh no! it looks like something went wrong. please contact the account admin to try again.';
  }
});
</script>

<template>
  <article class="invitation">
    <SoonaLoading v-if="isLoading" is-loading is-contained />
    <template v-else>
      <div class="invitation__emoji">
        {{ canAccept || invitation?.status == 'accepted' ? '🎉' : '🙊' }}
      </div>
      <p class="invitation__text">{{ invitationCopy }}</p>
      <SoonaButton
        v-if="canAccept"
        copy="accept invite"
        element="a"
        :href="acceptUrl"
      />
      <SoonaButton v-else copy="continue" element="a" href="/" />
      <SoonaError v-if="errorToDisplay">{{ errorToDisplay }}</SoonaError>
    </template>
  </article>
</template>

<style lang="scss" scoped>
@use '@/variables';

.invitation {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__emoji {
    align-items: center;
    background-color: variables.$periwink-blue-40;
    border-radius: 50%;
    display: flex;
    font-size: 4.6875rem;
    height: 9.8125rem;
    justify-content: center;
    margin-bottom: 2.5rem;
    width: 9.8125rem;
  }

  &__text {
    margin-bottom: 2.25rem;
    max-width: 27.5rem;
    text-align: center;
  }
}
</style>
