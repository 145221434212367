<script setup>
import { computed, ref } from 'vue';
import PageContainerOffset from '@/components/platform-layout/PageContainerOffset.vue';
import SoonaSlider from '@/components/ui_library/SoonaSlider.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import BeatLoader from '@/components/shared/BeatLoader.vue';
import SoonaFlag from 'src/components/ui_library/SoonaFlag.vue';

const props = defineProps({
  isLoading: {
    type: Boolean,
    default: false,
  },
  uploadMultiple: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['toolFileBrowseUpload', 'toolFileDropUpload']);

const isLoading = computed(() => props.isLoading);
const uploadMultiple = computed(() => props.uploadMultiple);

const tools = computed(() => {
  const toolUploadButtons = [
    {
      action: 'ai-scenes',
      caption: 'AI scenes',
      icon: 'wand-magic-sparkles',
      'flag-text': 'beta',
    },
    {
      action: 'ai-props',
      caption: 'AI props',
      icon: 'hand-holding',
      'flag-text': 'beta',
    },
    {
      action: 'background-color',
      caption: 'background color',
      icon: 'paint-roller',
    },
    {
      action: 'remove-background',
      caption: 'remove background',
      icon: 'scissors',
    },
    { action: 'blur', caption: 'blur background', icon: 'blur' },
    { action: 'shadows', caption: 'shadows', icon: 'shadows' },
    { action: 'resize', caption: 'resize', icon: 'resize' },
  ];

  return toolUploadButtons;
});

const toolDraggedOver = ref('');
</script>

<template>
  <form
    class="editor-tools"
    method="post"
    action=""
    enctype="multipart/form-data"
    @submit.prevent
  >
    <h3 id="editing-tools-headline" class="u-subheader--heavy">tools</h3>
    <PageContainerOffset>
      <SoonaSlider
        tag-name="ul"
        :no-animation="true"
        aria-labelledby="editing-tools-headline"
        class="u-body--heavy editor-tools__tools"
      >
        <li
          v-for="(tool, index) in tools"
          :key="index"
          class="editor-tools__tool-li"
          :class="{
            'editor-tools__tool-li--dragover':
              toolDraggedOver === tool.action && !isLoading,
          }"
          @drag.prevent.stop
          @dragstart.prevent.stop
          @dragover.prevent.stop="toolDraggedOver = tool.action"
          @dragenter.prevent.stop="toolDraggedOver = tool.action"
          @dragleave.prevent.stop="toolDraggedOver = ''"
          @dragend.prevent.stop="toolDraggedOver = ''"
          @drop.prevent.stop="
            toolDraggedOver = '';
            emit('toolFileDropUpload', $event, tool.action, tool.caption);
          "
        >
          <label
            class="editor-tools__tool"
            :class="{
              'editor-tools__tool--loading': isLoading,
            }"
          >
            <SoonaFlag
              v-if="tool['flag-text']"
              class="editor-tools__tool-flag"
              size="small"
              :title="tool['flag-text']"
              type="pill"
            />
            <span class="editor-tools__tool-icon">
              <BeatLoader v-if="isLoading" size="0.75rem" />
              <SoonaIcon v-else :name="tool.icon" size="x-large" />
            </span>
            <input
              v-if="!isLoading"
              type="file"
              class="u-a11y-only"
              accept=".png,.jpg,.jpeg"
              :multiple="uploadMultiple"
              @change="
                emit('toolFileBrowseUpload', $event, tool.action, tool.caption)
              "
            />
            {{ tool.caption }}
          </label>
        </li>
      </SoonaSlider>
    </PageContainerOffset>
  </form>
</template>

<style scoped lang="scss">
@use '@/variables';

.editor-tools {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__tools:deep(.soona-slider__content) {
    align-items: flex-start;
  }

  &__tool {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 0.5rem;
    padding: 0.5rem;
    border-radius: 0.625rem;
    background-color: variables.$white-default;
    transition: 0.1s background-color ease-out;
    color: variables.$black-default;
    cursor: pointer;
    width: 7.5rem;

    &-flag {
      position: absolute;
      top: 0;
      right: 1rem;
      padding: 0.0625rem 0.25rem;
      color: variables.$periwink-blue-80;
      background-color: variables.$periwink-blue-20;

      &:deep(.soona-flag__title) {
        font-weight: 900;
        line-height: 1.4;
        display: flex;
        align-items: center;
      }
    }

    &--loading {
      cursor: wait;
    }

    &:not(.editor-tools__tool--loading):hover,
    &:focus-within:has(:focus-visible) {
      background-color: variables.$gray-10;
    }

    &:focus-within:has(:focus-visible) {
      outline: 0.125rem solid variables.$periwink-blue-50;
      outline-offset: -0.125rem;
    }

    &-li {
      position: relative;
      outline: 0.125rem dashed transparent;
      outline-offset: -0.125rem;
      border-radius: 0.625rem;
      transition:
        outline-offset 0.1s ease-in-out,
        outline-color 0.1s ease-in-out;

      &--dragover {
        outline-offset: -0.25rem;
        outline-color: variables.$periwink-blue-50;
      }
    }
  }

  &__tool-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: variables.$bubbletape-pink-20;
    min-height: 5.25rem;
    border-radius: 0.625rem;

    svg {
      width: 2.375rem;
      height: 2.375rem;
    }
  }
}
</style>
