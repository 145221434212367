<script setup>
import { computed } from 'vue';
import { onKeyStroke } from '@vueuse/core';
import { useRouter } from 'vue-router';
import { useGetOrderAddOn } from 'src/queries/orders/useGetOrderAddOn';
import { usePriorityError } from 'src/composables/usePriorityError';
import SoonaBackButton from '@/components/ui_library/SoonaBackButton.vue';
import SoonaError from 'src/components/ui_library/SoonaError.vue';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';
import AnimatedCollection from './AddOnLineItem/AnimatedCollection.vue';
import DigitalAsset from './AddOnLineItem/DigitalAsset.vue';
import ReservationFile from './AddOnLineItem/ReservationFile.vue';
import UploadDigitalAssetEdits from './UploadDigitalAssetEdits.vue';

const props = defineProps({
  orderId: {
    required: true,
    type: [Number, String],
  },
  addOnLineItemId: {
    required: true,
    type: [Number, String],
  },
});

defineOptions({ inheritAttrs: false });

const router = useRouter();

const orderId = computed(() => props.orderId);
const addOnLineItemId = computed(() => props.addOnLineItemId);

const { data, error } = useGetOrderAddOn({
  orderId,
  addOnLineItemId,
});

const priorityError = usePriorityError(error);

onKeyStroke('ArrowLeft', () => {
  if (!data.value.previous) return;

  router.push({
    name: 'crew-order-add-on-line-item',
    params: {
      orderId: orderId.value,
      addOnLineItemId: data.value.previous,
    },
  });
});

onKeyStroke('ArrowRight', () => {
  if (!data.value.next) return;

  router.push({
    name: 'crew-order-add-on-line-item',
    params: {
      orderId: orderId.value,
      addOnLineItemId: data.value.next,
    },
  });
});

const goToOrder = () => {
  router.push({
    name: 'crew-order',
    params: { orderId: orderId.value },
  });
};

onKeyStroke('ArrowUp', e => {
  if (!e.altKey) return;

  goToOrder();
});
</script>
<template>
  <SoonaError v-if="priorityError">{{ priorityError }}</SoonaError>
  <div class="review-header">
    <SoonaBackButton
      :button-text="`add on order #${orderId} (opt/alt + up-arrow)`"
      @click="goToOrder"
    />
    <UploadDigitalAssetEdits
      v-if="data"
      :account-id="data.order.account_id"
      :order-id="data.order.id"
      :reservation-id="data.order.reservation_id"
      :original-digital-asset-id="data.digital_asset.id"
      variation="secondary-black"
    />
  </div>
  <div v-if="data" class="review-wrapper">
    <DigitalAsset v-if="data.digital_asset" :add-on="data" />
    <ReservationFile
      v-else-if="data.media_type === 'photo' || data.media_type === 'video'"
      :add-on="data"
    />
    <AnimatedCollection
      v-else-if="data.media_type === 'animation'"
      :add-on="data"
    />
    <div class="navigation-wrapper">
      <router-link
        v-if="data.previous"
        class="naviagtion-link"
        :to="{
          name: 'crew-order-add-on-line-item',
          params: { orderId: orderId, addOnLineItemId: data.previous },
        }"
      >
        <SoonaIcon name="arrow-left" class="navigation-link__icon" />
        previous (left-arrow)
      </router-link>
      <div class="navigation-wrapper__next">
        <router-link
          v-if="data.next"
          class="navigation-link__next"
          :to="{
            name: 'crew-order-add-on-line-item',
            params: { orderId: orderId, addOnLineItemId: data.next },
          }"
        >
          next (right-arrow)
          <SoonaIcon name="arrow-right" class="navigation-link__icon" />
        </router-link>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables';

.review-header {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.review-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.navigation-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &__next {
    flex: 1 1 auto;
    text-align: right;
  }
}

.navigation-link {
  &__icon {
    vertical-align: middle;
  }
}
</style>
