<script setup>
import { computed, reactive, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import SoonaForm from 'src/components/ui_library/SoonaForm.vue';
import SoonaTextfield from 'src/components/ui_library/SoonaTextfield.vue';
import BookingCheckbox from 'src/components/booking/sign_in/BookingCheckbox.vue';
import SoonaError from 'src/components/ui_library/SoonaError.vue';
import isNil from 'lodash/isNil';
import SoonaTelephoneField from '../ui_library/SoonaTelephoneField.vue';

const props = defineProps({
  user: {
    type: Object,
    required: true,
  },
  onChange: {
    type: Function,
    required: true,
  },
  checkoutBuffer: {
    type: Boolean,
    required: false,
    default: false,
  },
  companyName: {
    type: String,
    required: false,
    default: '',
  },
});

const store = useStore();
const errorMessages = computed(() => store.getters['flash/errorMessages']);

// eslint-disable-next-line vue/no-setup-props-reactivity-loss
const userData = reactive({
  name: props.user.name,
  email: props.user.email,
  companyName: props.companyName || '',
  phoneNumber: props.user.phone || '',
  acceptTextMessages: true,
});

const showError = computed(() => {
  return !isNil(
    errorMessages.value?.find(({ text }) => text === 'phone number is invalid.')
  );
});

watch(
  () => userData.phoneNumber,
  () => {
    // clear out flash error below, in the payment section
    if (showError.value) {
      store.dispatch(
        'flash/removeFlashMessage',
        errorMessages.value?.find(
          ({ text }) => text === 'phone number is invalid.'
        )
      );
    }
  }
);

onMounted(() => {
  props.onChange(userData);
});
</script>
<template>
  <div>
    <fieldset
      class="account-info"
      :class="{ 'checkout-buffer': checkoutBuffer }"
    >
      <div class="account-info__header">
        <legend class="account-info__title">Account Info</legend>
      </div>
      <SoonaForm>
        <SoonaTextfield
          v-model="userData.name"
          class="account-info__textfield"
          label="name"
          type="text"
          :disabled="true"
        />
        <SoonaTextfield
          v-model="userData.email"
          class="account-info__textfield"
          label="email"
          type="email"
          :disabled="true"
        />
        <SoonaTextfield
          v-model="userData.companyName"
          class="account-info__textfield"
          label="company name"
          type="text"
          @on-blur="onChange(userData)"
          ><template #helper>optional</template></SoonaTextfield
        >
        <SoonaTelephoneField
          v-model="userData.phoneNumber"
          class="account-info__textfield"
          :required="true"
          label="phone number"
          cypress-name="downpayment-input-phone"
          @on-blur="onChange(userData)"
          ><template #helper>required</template></SoonaTelephoneField
        >
        <BookingCheckbox
          v-model="userData.acceptTextMessages"
          class="account-info__booking-checkbox"
        >
          yes! soona can text me! i understand message and data rates may apply.
        </BookingCheckbox>
      </SoonaForm>
    </fieldset>
    <SoonaError v-if="showError">
      {{ userData.phoneNumber }}
      please enter a valid phone number to continue.
    </SoonaError>
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.account-info {
  position: relative;
  border: 1px solid variables.$gray-30;
  border-radius: 0.625rem;
  padding: 1.5rem;
  background-color: variables.$periwink-blue-10;
  min-width: auto;

  &::before {
    @include variables_fonts.u-body--heavy;

    content: '1';
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -1.25rem;
    left: 50%;
    transform: translateX(-50%);
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    border: 1px solid variables.$gray-30;
    background-color: variables.$white-default;
    color: variables.$gray-60;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
      color: variables.$black-default;
    }
  }

  &__title {
    @include variables_fonts.u-title--heavy;
    font-size: 1.5rem;

    text-transform: lowercase;
  }

  &__textfield {
    padding: 0;
    margin: 1rem 0;
  }

  &__booking-checkbox {
    width: 100%;
  }

  .soona-flash {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    border-radius: 0.3125rem;
    border: 1px solid transparent;
    margin: 0.625rem 0 3.25rem 0;
    padding: 0.625rem;

    &--error {
      border-color: variables.$roses-30;
      background-color: variables.$roses-10;
    }

    &__icon {
      flex: 0 0 1.25rem;
      margin: 0.125rem 0.5rem 0 0;
    }

    &__message {
      @include variables_fonts.u-body--regular;
    }
  }
}

.checkout-buffer {
  margin-top: 2.5rem;
}
</style>
