<template>
  <div class="availability-dashboard-grid">
    <h2 class="availability-header">
      your availability in {{ accountLocation.city }} ({{ timezoneShortName }})
    </h2>
    <div class="availability-text">
      let us know your general availability for your local studio(s). we will do
      our best to not offer shoots outside of your preferences.
    </div>
    <div class="availability-text">
      indicate your availability
      <strong> {{ currentShootScheduleRange }} </strong>. please turn off your
      services if you don't have availability during those dates. you can always
      add future availability beyond
      {{ shootScheduleEnd.toLocaleString('default', { month: 'long' }) }}
      {{ shootScheduleEnd.getDate() }}.
    </div>
  </div>
  <div class="availability-component-container">
    <div class="week-header">
      <span class="long-date">
        {{ startOfWeek }} - {{ endOfWeek }},
        {{ startOfCurrentWeek.toLocaleString('default', { year: 'numeric' }) }}
      </span>
      <span class="short-date">{{ startOfWeek }} - {{ endOfWeek }}</span>
      <div class="availability-button-row">
        <SoonaButton
          v-if="!edit"
          variation="secondary-black"
          copy="edit availability"
          class="desktop-button"
          :on-click="enableEdit"
        />
        <SoonaButton
          v-if="!edit"
          variation="secondary-black"
          copy="edit"
          class="mobile-button"
          :on-click="enableEdit"
        />
        <SoonaButton
          v-if="edit"
          variation="tertiary"
          copy="cancel"
          :on-click="cancelEdit"
        ></SoonaButton>
        <SoonaButton
          v-if="edit"
          copy="save"
          data-cypress="psp-availability-save"
          :on-click="saveAvailability"
        />
      </div>
    </div>
    <hr />
    <div class="week-schedule-container">
      <div class="week-schedule-header">
        <button
          class="button week-navigation decrement-week"
          :disabled="fourMonthsInThePast > startOfCurrentWeek"
          @click="lastWeek"
        >
          <span class="u-a11y-only">previous week</span>
          <SoonaIcon name="chevron-left" />
        </button>
        <div
          v-for="(date, index) in daysInWeek"
          :key="index"
          :class="[edit ? 'edit-mode' : '', 'weekday-header']"
        >
          <div class="weekday-info-container">
            <span class="weekday-title">
              {{ date.toLocaleString('default', { weekday: 'short' }) }}
            </span>
            <span class="weekday-title short">
              {{ date.toLocaleString('default', { weekday: 'narrow' }) }}
            </span>
            <span
              :class="[
                isToday(date) ? 'today' : '',
                futureDate(date) ? 'future' : '',
                edit ? 'edit-mode' : '',
                isCurrentSchedulingDate(date) ? 'active-day' : '',
                'weekday-number',
              ]"
            >
              <span v-if="isToday(date)" class="u-a11y-only">today</span>
              <span class="date-circle">{{ date.getDate() }}</span>
              <span
                v-if="hasSetAvailability(date)"
                class="notify-circle"
              ></span>
            </span>
          </div>
          <button
            class="weekday-info-button"
            @click="setCurrentSchedulingDay(date)"
          >
            <span class="weekday-title short">
              {{ date.toLocaleString('default', { weekday: 'narrow' }) }}
            </span>
            <span
              :class="[
                isToday(date) ? 'today' : '',
                futureDate(date) ? 'future' : '',
                edit ? 'edit-mode' : '',
                isCurrentSchedulingDate(date) ? 'active-day' : '',
                'weekday-number',
              ]"
            >
              <span v-if="isToday(date)" class="u-a11y-only">today</span>
              <span class="date-circle">{{ date.getDate() }}</span>
              <span
                v-if="hasSetAvailability(date)"
                class="notify-circle"
              ></span>
            </span>
          </button>
        </div>
        <button
          class="button week-navigation increment-week"
          :disabled="fourMonthsInTheFuture < startOfCurrentWeek"
          @click="nextWeek"
        >
          <span class="u-a11y-only">next week</span>
          <SoonaIcon name="chevron-right" />
        </button>
      </div>

      <p v-if="edit" class="active-date">
        {{
          currentSchedulingDate.toLocaleString('default', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })
        }}
      </p>
      <div class="week-time-selection">
        <div v-if="!edit" class="mobile-times">
          <div v-for="(hour, i) in hours" :key="i" class="time">
            {{ hour.mobileLabel }}
            <hr />
          </div>
        </div>
        <div
          v-for="(date, index) in daysInWeek"
          :key="index"
          :class="[
            !isActiveDate(date) && edit ? 'hidden' : '',
            edit ? 'editable' : '',
            'weekday-time-selection',
          ]"
        >
          <SoonaButton
            variation="secondary-gray"
            size="medium"
            copy="all day"
            :aria-pressed="checkedAllDayField(date)"
            :class="[
              futureOrToday(date) ? 'future' : '',
              edit ? 'editable' : '',
              'availability-toggle',
              'all-day',
            ]"
            :disabled="!edit || (pastDate(date) && !isToday(date))"
            @click="toggleAllDate(date)"
          />
          <SoonaButton
            v-for="(hour, i) in hours"
            :key="i"
            variation="secondary-gray"
            :copy="hour.label"
            size="medium"
            :data-hour="hour.value"
            :data-date="date"
            data-cypress="psp-availability-date"
            :aria-pressed="checkedDateField(date, hour.value)"
            :class="[
              edit ? 'editable' : '',
              futureOrToday(date) ? 'future' : '',
              'availability-toggle',
            ]"
            :disabled="!edit || (pastDate(date) && !isToday(date))"
            @click="toggleHour(date, hour.value)"
          />
          <SoonaButton
            v-if="edit"
            variation="secondary-black"
            copy="reset availability"
            class="mobile-button reset-day-button"
            :on-click="showResetSelectionDayModal"
          ></SoonaButton>
        </div>
        <div v-if="!edit" class="mobile-times">
          <div v-for="(hour, i) in hours" :key="i" class="time"></div>
        </div>
      </div>
      <div v-if="edit" class="mobile-day-buttons">
        <SoonaButton
          variation="secondary-black"
          :copy="
            previousSchedulingDay.toLocaleString('default', {
              weekday: 'long',
            })
          "
          :on-click="decrementSchedulingDay"
          size="medium"
        >
          <template #icon-left>
            <SoonaIcon name="arrow-left" />
          </template>
        </SoonaButton>
        <SoonaButton
          variation="secondary-black"
          :copy="
            nextSchedulingDay.toLocaleString('default', { weekday: 'long' })
          "
          :on-click="incrementSchedulingDay"
          size="medium"
        >
          <template #icon-right>
            <SoonaIcon name="arrow-right" />
          </template>
        </SoonaButton>
      </div>
      <div v-if="edit" class="copy-reset-buttons">
        <SoonaButton
          variation="secondary-black"
          copy="copy previous week"
          class="desktop-button"
          data-cypress="psp-copy-previous-week"
          :on-click="showCopyPreviousWeekModal"
        ></SoonaButton>
        <SoonaButton
          variation="secondary-black"
          copy="reset availability"
          class="desktop-button"
          data-cypress="psp-reset-availability"
          :on-click="showResetSelectionWeekModal"
        ></SoonaButton>
      </div>
      <SoonaDialog v-if="availabilityModalOpen" @close="closeAvailabilityModal">
        <template #heading>
          <div class="modal-header">
            <WarningIcon
              :fill-color="PeriwinkBlue60"
              :background-color="PeriwinkBlue20"
            />
            {{ modalData.header }}
          </div>
        </template>
        <p>{{ modalData.body }}</p>
        <template #footer="{ close }">
          <SoonaButton variation="tertiary" @on-click="close">
            cancel
          </SoonaButton>
          <SoonaButton
            data-cypress="button-dialog-confirm"
            @on-click="modalData.confirmClick"
          >
            {{ modalData.confirmCopy }}
          </SoonaButton>
        </template>
      </SoonaDialog>
    </div>
    <hr />
    <div class="availability-button-row">
      <SoonaButton
        v-if="!edit"
        variation="secondary-black"
        copy="edit availability"
        class="desktop-button"
        data-cypress="psp-edit-availability"
        :on-click="enableEdit"
      />
      <SoonaButton
        v-if="!edit"
        variation="secondary-black"
        copy="edit"
        class="mobile-button"
        :on-click="enableEdit"
      />
      <SoonaButton
        v-if="edit"
        variation="tertiary"
        copy="cancel"
        :on-click="cancelEdit"
      />
      <SoonaButton v-if="edit" copy="save" :on-click="saveAvailability" />
    </div>
  </div>
</template>

<script>
import * as Sentry from '@sentry/vue';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { timeZoneAbbr } from '@/lib/date-formatters';
import { mapActions, mapState } from 'vuex';
import isEqual from 'lodash/isEqual';
import isMatch from 'lodash/isMatch';
import range from 'lodash/range';
import xor from 'lodash/xor';
import { PeriwinkBlue20, PeriwinkBlue60 } from '@/variables.module.scss';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';
import WarningIcon from 'src/components/svgs/WarningIcon.vue';

dayjs.extend(utc);
dayjs.extend(timezone);

export default {
  name: 'Availability',
  components: { SoonaButton, SoonaDialog, SoonaIcon, WarningIcon },
  props: ['currentEvent'],
  setup() {
    return { PeriwinkBlue20, PeriwinkBlue60 };
  },
  data() {
    return {
      availabilityEvents: [],
      selectedTimes: {},
      allDayHours: range(8, 18),
      currentView: 'agendaWeek',
      startOfCurrentWeek: new Date(),
      fourMonthsInThePast: dayjs().subtract(4, 'M').endOf('month'),
      fourMonthsInTheFuture: dayjs().add(4, 'M').endOf('month'),
      currentSchedulingDate: new Date(),
      shootScheduleStart: new Date(),
      shootScheduleEnd: new Date(),
      uniqueMatches: new Set(),
      saturdayScheduling: false,
      eveningScheduling: false,
      edit: false,
      availabilityModalOpen: false,
      modalData: {},
      errors: [],
      hours: [
        { value: 8, label: '8am - 9am', mobileLabel: '8am' },
        { value: 9, label: '9am - 10am', mobileLabel: '9am' },
        { value: 10, label: '10am - 11am', mobileLabel: '10am' },
        { value: 11, label: '11am -12pm', mobileLabel: '11am' },
        { value: 12, label: '12pm - 1pm', mobileLabel: '12pm' },
        { value: 13, label: '1pm - 2pm', mobileLabel: '1pm' },
        { value: 14, label: '2pm - 3pm', mobileLabel: '2pm' },
        { value: 15, label: '3pm - 4pm', mobileLabel: '3pm' },
        { value: 16, label: '4pm - 5pm', mobileLabel: '4pm' },
        { value: 17, label: '5pm - 6pm', mobileLabel: '5pm' },
      ],
    };
  },
  computed: {
    ...mapState({
      userId: state => state.account.account_userId,
      accountLocation: state => state.account.owner.locations[0],
      stateAvailabilityEvents: state => state.account.pro_service_availability,
    }),
    timezoneShortName() {
      return timeZoneAbbr(this.accountLocation.timezone);
    },
    startOfWeek() {
      const firstDayInWeek = this.daysInWeek[0];
      const options = { month: 'short' };
      const firstDayMonth = new Intl.DateTimeFormat('en-US', options).format(
        firstDayInWeek
      );
      const startofWeekString = firstDayMonth + ' ' + firstDayInWeek.getDate();
      return startofWeekString;
    },
    endOfWeek() {
      const lastDay = new Date(this.daysInWeek[this.daysInWeek.length - 1]);
      const options = { month: 'short' };
      const lastDayMonth = new Intl.DateTimeFormat('en-US', options).format(
        lastDay
      );
      const endOfWeekString = lastDayMonth + ' ' + lastDay.getDate();
      return endOfWeekString;
    },
    daysInWeek() {
      const days = this.saturdayScheduling ? 6 : 5;
      let curr = new Date(this.startOfCurrentWeek);
      let week = [];

      for (let i = 1; i <= days; i++) {
        let first = curr.getDate() - curr.getDay() + i;
        let day = new Date(curr.setDate(first));
        week.push(day);
      }
      return week;
    },
    accountId() {
      return Number(this.$route.params.accountId);
    },
    currentShootScheduleRange() {
      return `${this.shootScheduleStart.toLocaleString('default', {
        month: 'long',
      })} ${this.shootScheduleStart.getDate()} - ${this.shootScheduleEnd.toLocaleString(
        'default',
        { month: 'long' }
      )} ${this.shootScheduleEnd.getDate()}`;
    },
    previousSchedulingDay() {
      const previousDay = new Date(this.currentSchedulingDate);
      previousDay.setDate(previousDay.getDate() - 1);
      while (previousDay.getDay() === 0 || previousDay.getDay() === 6) {
        previousDay.setDate(previousDay.getDate() - 1);
      }
      return previousDay;
    },
    nextSchedulingDay() {
      const nextDay = new Date(this.currentSchedulingDate);
      nextDay.setDate(nextDay.getDate() + 1);
      while (nextDay.getDay() === 0 || nextDay.getDay() === 6) {
        nextDay.setDate(nextDay.getDate() + 1);
      }
      return nextDay;
    },
    fullWeeek() {
      return this.currentSchedulingDate == this.startOfCurrentWeek;
    },
    currentSchedulingDateShort() {
      return this.currentSchedulingDate.toLocaleString('default', {
        month: 'short',
        day: 'numeric',
      });
    },
  },
  watch: {
    stateAvailabilityEvents(newEvents) {
      const unseen = newEvents.filter(
        event => !this.availabilityEvents.some(ae => ae.id == event.id)
      );
      this.availabilityEvents = [...this.availabilityEvents, ...unseen];

      unseen.forEach(e => {
        const tz = this.accountLocation.timezone;
        let start = new Date(
          new Date(e.start).toLocaleString(navigator.language, { timeZone: tz })
        );
        let end = new Date(
          new Date(e.end).toLocaleString(navigator.language, { timeZone: tz })
        );
        const key = this.dateKey(start);
        this.selectedTimes[key] ?? (this.selectedTimes[key] = []);
        while (start < end) {
          const h = start.getHours();
          if (!this.selectedTimes[key].includes(h)) {
            this.selectedTimes[key].push(h);
          }
          start.setHours(h + 1);
        }
      });
    },
  },
  created() {
    this.shootScheduleStart.setDate(this.daysInWeek[0].getDate());
    this.shootScheduleEnd.setDate(this.daysInWeek[0].getDate() + 34);
    this.startOfCurrentWeek = new Date(
      this.startOfCurrentWeek.setDate(
        this.startOfCurrentWeek.getDate() - this.startOfCurrentWeek.getDay() + 1
      )
    );
    this.loadProServiceAvailability({
      accountId: this.accountId,
      start: this.startOfCurrentWeek,
      view: this.currentView,
    }).catch(error =>
      Sentry.captureException(
        new Error('failed to load pro-service availability'),
        {
          extra: { error },
        }
      )
    );
  },
  methods: {
    ...mapActions('events', ['createUserEvent', 'deleteEvent']),
    ...mapActions('account', ['loadProServiceAvailability', 'loadAccount']),
    dateKey(date) {
      return dayjs(date).format('YYYY/MM/DD');
    },
    lastWeek() {
      this.decrementCurrentWeek();
      this.setSchedulingDayToMondayOrToday();
    },
    decrementCurrentWeek() {
      this.startOfCurrentWeek = new Date(
        this.startOfCurrentWeek.setDate(this.startOfCurrentWeek.getDate() - 7)
      );
      this.loadProServiceAvailability({
        accountId: this.accountId,
        start: this.startOfCurrentWeek,
        view: this.currentView,
      });
    },
    nextWeek() {
      this.incrementCurrentWeek();
      this.setSchedulingDayToMondayOrToday();
    },
    incrementCurrentWeek() {
      this.startOfCurrentWeek = new Date(
        this.startOfCurrentWeek.setDate(this.startOfCurrentWeek.getDate() + 7)
      );
      this.loadProServiceAvailability({
        accountId: this.accountId,
        start: this.startOfCurrentWeek,
        view: this.currentView,
      });
    },
    checkedDateField(date, hour) {
      let key = this.dateKey(date);
      return this.selectedTimes[key]?.includes(hour);
    },
    checkedAllDayField(date) {
      const key = this.dateKey(date);
      const events = this.selectedTimes[key];
      return events && isEqual(events, this.allDayHours);
    },
    pastDate(date) {
      const unixDate = new Date(date).getTime();
      return unixDate < Date.now();
    },
    futureDate(date) {
      return !this.pastDate(date);
    },
    isToday(date) {
      const today = new Date();
      return date.setHours(0, 0, 0, 0) == today.setHours(0, 0, 0, 0);
    },
    futureOrToday(date) {
      return this.futureDate(date) || this.isToday(date);
    },
    isCurrentSchedulingDate(date) {
      return (
        date.setHours(0, 0, 0, 0) ==
        this.currentSchedulingDate.setHours(0, 0, 0, 0)
      );
    },
    utcDate(dateString, hour) {
      return dayjs(dateString)
        .set('hour', hour)
        .tz(this.accountLocation.timezone, true)
        .utc()
        .format();
    },
    decrementSchedulingDay() {
      this.currentSchedulingDate = this.previousSchedulingDay;
      const weekCutoff = new Date(this.startOfCurrentWeek);
      weekCutoff.setDate(weekCutoff.getDate() - 1);
      if (this.currentSchedulingDate < weekCutoff) {
        this.decrementCurrentWeek();
      }
    },
    incrementSchedulingDay() {
      this.currentSchedulingDate = this.nextSchedulingDay;
      const nextWeek = new Date(this.startOfCurrentWeek);
      nextWeek.setDate(nextWeek.getDate() + 6);
      if (this.currentSchedulingDate > nextWeek) {
        this.incrementCurrentWeek();
      }
    },
    setSchedulingDayToMondayOrToday() {
      const today = this.daysInWeek.find(date => this.isToday(date));
      if (today) {
        this.currentSchedulingDate = today;
      } else {
        this.currentSchedulingDate = this.startOfCurrentWeek;
      }
    },
    setCurrentSchedulingDay(newDate) {
      this.currentSchedulingDate = newDate;
    },
    isActiveDate(date) {
      return (
        date.setHours(0, 0, 0, 0) ==
        this.currentSchedulingDate.setHours(0, 0, 0, 0)
      );
    },
    showCopyPreviousWeekModal() {
      const fullWeek = this.fullWeeek ? 'the week of ' : '';
      this.modalData = {
        header: 'copy previous week',
        body: `copying previous week will overwrite any existing availability preferences for ${fullWeek}${this.currentSchedulingDateShort} - ${this.endOfWeek}`,
        confirmCopy: 'copy',
        confirmClick: this.copyPreviousWeek,
      };
      this.availabilityModalOpen = true;
    },

    showResetSelectionWeekModal() {
      const fullWeek = this.fullWeeek ? 'the week of ' : '';
      this.modalData = {
        header: 'reset selection',
        body: `resetting availability will clear any existing availability preferences for ${fullWeek}${this.currentSchedulingDateShort} - ${this.endOfWeek}`,
        confirmCopy: 'reset',
        confirmClick: this.resetWeek,
      };
      this.availabilityModalOpen = true;
    },
    showResetSelectionDayModal() {
      this.modalData = {
        header: 'reset selection',
        body: `resetting availability will clear any existing availability preferences for the day of ${this.currentSchedulingDateShort}`,
        confirmCopy: 'reset',
        confirmClick: this.resetDay,
      };
      this.availabilityModalOpen = true;
    },
    closeAvailabilityModal() {
      this.modalData = {};
      this.availabilityModalOpen = false;
    },
    enableEdit() {
      this.edit = true;
    },
    cancelEdit() {
      this.availabilityEvents = [];
      this.selectedTimes = {};
      this.loadProServiceAvailability({
        accountId: this.accountId,
        start: this.startOfCurrentWeek,
        view: this.currentView,
      });
      this.edit = false;
    },
    resetDay() {
      this.selectedTimes[this.dateKey(this.currentSchedulingDate)] = [];
      this.closeAvailabilityModal();
    },
    resetWeek() {
      this.daysInWeek.forEach(day => {
        if (this.futureOrToday(day)) {
          this.selectedTimes[this.dateKey(day)] = [];
        }
      });
      this.closeAvailabilityModal();
    },
    async copyPreviousWeek() {
      const previousWeekStart = new Date();
      previousWeekStart.setDate(this.startOfCurrentWeek.getDate() - 7);
      if (!this.selectedTimes[this.dateKey(previousWeekStart)]) {
        await this.loadProServiceAvailability({
          accountId: this.accountId,
          start: previousWeekStart,
          view: this.currentView,
        });
      }
      this.daysInWeek.forEach(day => {
        if (this.futureOrToday(day)) {
          const dayLastWeek = new Date(day.getTime());
          dayLastWeek.setDate(day.getDate() - 7);
          const timesLastWeek = this.selectedTimes[this.dateKey(dayLastWeek)];
          this.selectedTimes[this.dateKey(day)] = timesLastWeek ?? [];
        }
      });
      this.closeAvailabilityModal();
    },

    sortArray(arr) {
      if (arr) {
        return arr.sort(function (a, b) {
          return a - b;
        });
      } else {
        return [];
      }
    },
    toggleHour(date, hour) {
      const key = this.dateKey(date);
      this.selectedTimes[key] ?? (this.selectedTimes[key] = []);
      this.selectedTimes[key] = this.sortArray(
        xor(this.selectedTimes[key], [hour])
      );
    },
    toggleAllDate(date) {
      const key = this.dateKey(date);
      if (isEqual(this.selectedTimes[key], this.allDayHours)) {
        delete this.selectedTimes[key];
      } else {
        this.selectedTimes[key] = this.allDayHours;
      }
    },
    getAppointmentGroups(key, hours) {
      hours = this.sortArray(hours);
      return hours
        .reduce((events, hour) => {
          const lastSubArray = events[events.length - 1];
          if (
            !lastSubArray ||
            lastSubArray[lastSubArray.length - 1] !== hour - 1
          ) {
            events.push([]);
          }
          events[events.length - 1].push(hour);
          return events;
        }, [])
        .map(hourGroup => {
          return {
            start: this.utcDate(key, hourGroup[0]),
            end: this.utcDate(key, hourGroup[hourGroup.length - 1] + 1),
            type: 'AvailabilityEvent',
          };
        });
    },
    buildEvents() {
      return Object.entries(this.selectedTimes)
        .map(([date, hours]) => this.getAppointmentGroups(date, hours))
        .flat();
    },
    dedupeEvents(events) {
      const duplicateEvents = events.filter(event =>
        this.availabilityEvents.some(e => isMatch(e, event))
      );
      const oldEvents = this.availabilityEvents.filter(
        event => !duplicateEvents.some(e => isMatch(event, e))
      );
      const newEvents = events.filter(
        event => !duplicateEvents.some(e => isMatch(e, event))
      );
      return { newEvents, oldEvents };
    },
    saveAvailability() {
      const events = this.buildEvents();
      const { newEvents, oldEvents } = this.dedupeEvents(events);
      if (oldEvents || newEvents) {
        oldEvents.forEach(
          async event => await this.deleteEvent({ eventId: event.id })
        );
        newEvents.forEach(
          async event =>
            await this.createUserEvent({
              userId: this.userId,
              event: event,
            })
        );
        setTimeout(() => {
          this.availabilityEvents = [];
          this.selectedTimes = {};
          this.loadProServiceAvailability({
            accountId: this.accountId,
            start: this.startOfCurrentWeek,
            view: this.currentView,
          });
          this.edit = false;
        }, 200);
      } else {
        this.edit = false;
      }
    },
    hasSetAvailability(date) {
      const key = this.dateKey(date);
      return this.selectedTimes[key]?.length > 0;
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';

.availability-dashboard-grid {
  margin: 1rem;

  .availability-header {
    text-align: left;
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 900;
    color: variables.$black-default;
    margin-bottom: 0.75rem;
  }

  .availability-text {
    color: variables.$black-default;
    margin-bottom: 1.25rem;
  }

  @media screen and (max-width: 768px) {
    .availability-header {
      margin-top: 2.875rem;
    }
  }
}

.availability-component-container {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: variables.$white-default !important;

  hr {
    height: 0.1rem;
    background-color: variables.$gray-30;
  }

  .mobile-button {
    display: none;
  }

  .week-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.125rem;
    font-weight: 900;
    color: variables.$black-default;
    padding-top: 0.75rem;
    align-items: center;
    text-transform: capitalize;
    .short-date {
      letter-spacing: 0.25px;
      display: none;
    }
  }

  .availability-button-row {
    display: flex;
    flex-flow: row wrap;
    gap: 1rem;
    justify-content: flex-end;
  }

  .week-schedule-container {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    align-items: center;

    .active-date {
      font-size: 1rem;
      font-weight: 700;
      width: 100%;
      display: none;
    }

    .mobile-day-buttons {
      display: none;
    }

    .week-schedule-header {
      display: flex;
      gap: 0.75rem;
      text-align: center;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 900;
      letter-spacing: 0.225rem;
      color: variables.$black-default;
      text-transform: uppercase;
      align-items: center;

      .weekday-header {
        align-items: center;
        position: relative;
        .weekday-info-button {
          font-size: inherit;
          font-style: normal;
          font-weight: inherit;
          line-height: inherit;
          letter-spacing: inherit;
          display: none;
          width: inherit;
          background: none;
          padding: 0;
        }
        .weekday-title {
          width: 6.25rem;
          padding-left: 0.225rem;
          color: variables.$gray-60;
          line-height: normal;
          display: block;
          margin-bottom: 0.625rem;
          &.short {
            display: none;
          }
        }
        .weekday-number {
          display: flex;
          position: relative;
          justify-content: center;
          color: variables.$gray-40;
          min-height: 2.25rem;
          .date-circle {
            text-align: baseline;
            min-width: 2.25rem;
            border-radius: 50%;
            padding: 0.45rem 0.35rem 0.45rem 0.55rem;
          }
          &.today {
            color: variables.$periwink-blue-70;
            .date-circle {
              color: variables.$black-default;
              background-color: variables.$friendly-red-20;
            }
          }
          &.future {
            color: variables.$periwink-blue-70;
            .date-circle {
              color: variables.$black-default;
            }
          }
          &.edit-mode {
            &.active-day {
              color: variables.$white-default;
              .date-circle {
                color: variables.$white-default;
                background-color: variables.$friendly-red-50;
              }
            }
          }
          .notify-circle {
            position: absolute;
            min-width: 0.25rem;
            height: 0.25rem;
            border-radius: 50%;
            background-color: currentColor;
            bottom: 0.2rem;
          }
        }
      }
    }

    .soona-button {
      &--secondary-gray {
        white-space: nowrap;
      }
      &:disabled {
        cursor: default;
      }
    }

    .week-navigation {
      font-size: 0.875rem;
      border: none;

      &:focus {
        box-shadow: none;
      }
    }
    .copy-reset-buttons {
      display: flex;
      padding-top: 1.25rem;
      column-gap: 0.625rem;
      justify-content: space-between;
    }

    .week-time-selection {
      display: flex;
      gap: 0.75rem;
      .mobile-times {
        display: none;
        hr {
          width: 75vw;
          height: 0.1rem;
          margin: 0.5rem 0rem 0rem 0rem;
          background-color: variables.$gray-30;
        }
      }
      .weekday-time-selection {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 6.25rem;
        gap: 1rem;
        .availability-toggle {
          align-items: center;
          border: 0.0625rem solid transparent;
          border-radius: 0.3125rem;
          border-color: variables.$gray-30;
          font-size: 0.75rem;
          width: 6.25rem;
          height: 2.5rem;
          &.future {
            color: variables.$gray-60;
          }
          &[aria-pressed='true'] {
            border-color: variables.$gray-40;
            background-color: variables.$gray-10;
            &.future {
              color: variables.$black-default;
              background-color: variables.$periwink-blue-20;
              border: none;
            }
          }
          &.editable {
            &[aria-pressed='true'] {
              &.future {
                border: 0.0625rem solid transparent;
                border-color: variables.$periwink-blue-70;
                &:hover {
                  background-color: variables.$periwink-blue-20;
                }
              }
            }
            &.future {
              border-color: variables.$gray-80;
              color: variables.$gray-80;
              &:hover {
                background-color: variables.$periwink-blue-10;
                border-color: variables.$periwink-blue-60;
                box-shadow: 0 4px 8px variables.$gray-30;
                transition: box-shadow 0.2s ease-out;
              }
            }
          }
        }
      }
    }
    .date {
      display: none;
    }
  }
  @media only screen and (max-width: 768px) {
    .mobile-button {
      display: block;
    }
    .desktop-button {
      display: none;
    }

    .week-header {
      gap: 0rem;
      .short-date {
        display: inline;
      }
      .long-date {
        display: none;
      }
    }
    .week-schedule-container {
      .active-date {
        display: block;
      }
      .mobile-day-buttons {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }

      .soona-button {
        min-width: 0rem;
        &--secondary-gray {
          &.soona-button--medium {
            padding: 0rem;
          }
        }
      }
      .week-navigation {
        width: 10vw;
      }
      .week-schedule-header {
        .weekday-header {
          width: 10vw;
          .weekday-title {
            width: 100%;
            display: none;
            &.short {
              display: block;
            }
          }
          &.edit-mode {
            .weekday-info-container {
              display: none;
            }
            .weekday-info-button {
              display: block;
            }
          }
        }
      }

      .week-time-selection {
        .mobile-times {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          .time {
            width: 10vw;
            height: 1.25rem;
            font-size: 0.75rem;
            font-weight: 700;
            text-align: center;
            text-transform: uppercase;
          }
        }
        .weekday-time-selection {
          width: 10vw;
          &.editable {
            width: 80vw;
            max-width: none;
          }
          &.hidden {
            display: none;
          }

          .reset-day-button {
            width: 100%;
            margin-top: 1.25rem;
            margin-bottom: 0.625rem;
          }

          .availability-toggle {
            width: 1.563rem;
            height: 1.25rem;
            overflow: hidden;
            font-size: 0rem;
            border: none;
            background-color: variables.$gray-10;

            &.future {
              background-color: variables.$gray-20;
            }
            &[aria-pressed='true'] {
              background-color: variables.$periwink-blue-20;
              &.future {
                background-color: variables.$periwink-blue-70;
              }
            }
            &.editable {
              display: block;
              height: 2.5rem;
              width: 100%;
              background-color: variables.$white-default;
              font-size: 1rem;
              font-weight: 700;
              border: 0.0625rem solid transparent;
              border-color: variables.$gray-30;
              &[aria-pressed='true'] {
                background-color: variables.$gray-10;
                &.future {
                  background-color: variables.$periwink-blue-20;
                  border-color: variables.$periwink-blue-70;
                }
              }
            }
          }
          .all-day {
            display: none;
            &.editable {
              display: block;
              margin-bottom: 1.25rem;
            }
          }
        }
      }
    }
  }
}

.modal-header {
  align-items: center;
  display: flex;
  gap: 0.25rem;
}
</style>
