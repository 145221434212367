<script setup>
import { ref, computed, watchEffect } from 'vue';
import SoonaDialog from 'src/components/ui_library/SoonaDialog.vue';
import SoonaButton from '../ui_library/SoonaButton.vue';
import CalendarDatePicker from '../shared/CalendarDatePicker.vue';
import {
  convertTodddDDMMMYYY,
  convertToHHMM12,
  timeZoneAbbr,
  browserTimeZone,
} from 'src/lib/date-formatters';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

const props = defineProps({
  rangeStart: {
    type: [String, null],
    required: true,
  },
  rangeEnd: {
    type: [String, null],
    required: true,
  },
  label: {
    type: String,
    default: 'date range',
  },
  location: {
    type: Object,
  },
});
const emits = defineEmits(['update:rangeStart', 'update:rangeEnd']);
dayjs.extend(utc);
dayjs.extend(timezone);

const startOpen = ref(false);
const endOpen = ref(false);
const _currentMonth = new Date();
_currentMonth.setDate(1);
_currentMonth.setHours(0, 0, 0, 0);
const currentMonth = ref(_currentMonth);

const selectedStartDay = ref(null);
const selectedEndDay = ref(null);

const rangeStart = computed({
  get() {
    return props.rangeStart;
  },
  set(value) {
    emits('update:rangeStart', value);
  },
});

const rangeEnd = computed({
  get() {
    return props.rangeEnd;
  },
  set(value) {
    emits('update:rangeEnd', value);
  },
});

const locationTimezone = computed(() => {
  return props.location?.timezone;
});

function startDayNotSelectable(day) {
  return day < new Date().setHours(0, 0, 0, 0);
}

function startTimeNotSelectable(time) {
  return new Date(time) < new Date();
}

function endDayNotSelectable(day) {
  return (
    day < new Date().setHours(0, 0, 0, 0) ||
    (selectedStartDay.value && day < selectedStartDay.value)
  );
}

function endTimeNotSelectable(time) {
  return (
    new Date(time) < new Date() || (rangeStart.value && time < rangeStart.value)
  );
}

const rangeStartCopy = computed(() => {
  if (!rangeStart.value) {
    return 'no date selected';
  }
  const time = locationTimezone.value
    ? convertToHHMM12(rangeStart.value, locationTimezone.value)
    : convertToHHMM12(rangeStart.value);

  const timeZone = locationTimezone.value
    ? timeZoneAbbr(locationTimezone.value)
    : browserTimeZone();
  return convertTodddDDMMMYYY(rangeStart.value) + ' ' + time + ' ' + timeZone;
});

const rangeEndCopy = computed(() => {
  if (!rangeEnd.value) {
    return 'no date selected';
  }
  const time = locationTimezone.value
    ? convertToHHMM12(rangeEnd.value, locationTimezone.value)
    : convertToHHMM12(rangeEnd.value);

  const timeZone = locationTimezone.value
    ? timeZoneAbbr(locationTimezone.value)
    : browserTimeZone();
  return convertTodddDDMMMYYY(rangeEnd.value) + ' ' + time + ' ' + timeZone;
});

const startCopy = computed(() => {
  return 'select start date';
});

const endCopy = computed(() => {
  return 'select end date';
});

function updateStartTime(time) {
  rangeStart.value = time;
  startOpen.value = false;
}

function updateEndTime(time) {
  rangeEnd.value = time;
  endOpen.value = false;
}

function resetRange() {
  selectedStartDay.value = null;
  selectedEndDay.value = null;
  rangeStart.value = null;
  rangeEnd.value = null;
}

watchEffect(() => {
  if (new Date(rangeStart.value) > new Date(rangeEnd.value)) {
    selectedEndDay.value = null;
    rangeEnd.value = null;
  }
});
</script>
<template>
  <fieldset class="date-range">
    <legend class="date-range__legend">{{ label }}</legend>
    <div class="date-range__set">
      <h2 class="date-range__label">start date</h2>
      <p>{{ rangeStartCopy }}</p>
      <SoonaButton
        variation="secondary-gray"
        class="date-range__button"
        :copy="startCopy"
        @click="startOpen = true"
      />
    </div>
    <div class="date-range__set">
      <h2 class="date-range__label">end date</h2>
      <p>{{ rangeEndCopy }}</p>
      <SoonaButton
        variation="secondary-gray"
        class="date-range__button"
        :copy="endCopy"
        @click="endOpen = true"
      />
    </div>
    <SoonaButton variation="tertiary" copy="reset" @click="resetRange" />
    <SoonaDialog v-if="startOpen" max-width="55rem" @close="startOpen = false">
      <template #heading>
        <strong>{{ startCopy }}</strong>
      </template>
      <CalendarDatePicker
        v-model:current-month="currentMonth"
        v-model:selected-day="selectedStartDay"
        :selected-time-slot="rangeStart"
        :selected-timezone="locationTimezone"
        :day-not-selectable="startDayNotSelectable"
        :time-not-selectable="startTimeNotSelectable"
        :is-vertical="false"
        @update:selected-time-slot="updateStartTime"
      />
    </SoonaDialog>
    <SoonaDialog v-if="endOpen" max-width="55rem" @close="endOpen = false">
      <template #heading>
        <strong>{{ endCopy }}</strong>
      </template>
      <CalendarDatePicker
        v-model:current-month="currentMonth"
        v-model:selected-day="selectedEndDay"
        :selected-time-slot="rangeEnd"
        :selected-timezone="locationTimezone"
        :day-not-selectable="endDayNotSelectable"
        :time-not-selectable="endTimeNotSelectable"
        :is-vertical="false"
        @update:selected-time-slot="updateEndTime"
      />
    </SoonaDialog>
  </fieldset>
</template>
<style scoped lang="scss">
@use '@/variables_fonts';
.date-range {
  &__legend {
    @include variables_fonts.u-body--heavy;
    width: 100%;
  }
  &__set {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }
  &__label {
    @include variables_fonts.u-label--heavy;
    width: 100%;
  }
  &__button {
    font-size: 0.9rem !important;
    width: 100%;
  }
}
</style>
