import { unref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from 'src/queries/query-keys';
import { getBestAutoApplyDiscount } from '@/api/reservation';

export function useGetBestAutoApplyCoupon(
  reservationId,
  { products, orderType } = {},
  queryOptions
) {
  return useQuery({
    queryKey: queryKeys.bestAutoApplyCoupon(reservationId, products, orderType),
    queryFn: ({ signal }) =>
      getBestAutoApplyDiscount(
        unref(reservationId),
        { products: unref(products), orderType },
        signal
      ),
    ...queryOptions,
  });
}
