<script setup>
import { computed } from 'vue';
import { useSoonaToast } from 'src/components/ui_library/soona_toast/useSoonaToast';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import colors from '@/variables.module.scss';
import { useSoonaIconData } from '@/components/ui_library/soona_icon/useSoonaIconData';

// head over to /soona-toast in dev mode to see!

const { addToast } = useSoonaToast();
const soonaIconData = useSoonaIconData();
const colorKeys = Object.keys(colors);

const iconKeys = computed(() => {
  if (!soonaIconData.value) return [];
  return Object.keys(soonaIconData.value);
});

const messages = [
  'blobby would like to connect with you on LinkedIn',
  'lowercase letters only, plz',
  'quality content for all',
  'bubba has arrived for his booking',
  'don’t forget your color cards!',
  'what if soona was named hoobastank?',
];

const getRandomIndex = arr => {
  return arr[Math.floor(Math.random() * arr.length)];
};

const handleAddToast = options => {
  const showSubtext = Math.floor(Math.random() * 2) === 0;
  addToast(getRandomIndex(messages), {
    ...options,
    subtext: showSubtext ? getRandomIndex(messages) : undefined,
  });
};

const handleToastSlots = () => {
  const icon = getRandomIndex(iconKeys.value);
  const iconColor = getRandomIndex(colorKeys);
  const iconBg = getRandomIndex(colorKeys);

  handleAddToast({
    iconName: icon,
    iconColor: colors[iconColor],
    iconBg: colors[iconBg],
  });
};
</script>

<template>
  <div class="u-container soona-toast-test-page">
    <h1 class="u-headline--heavy">SoonaToast test page</h1>

    <p>smash these buttons.</p>
    <p>as many times as you darn well please.</p>

    <SoonaButton
      type="button"
      variation="secondary-gray"
      copy="SoonaToast info"
      @click="handleAddToast({ variation: 'info' })"
    />
    <SoonaButton
      type="button"
      variation="secondary-gray"
      copy="SoonaToast success"
      @click="handleAddToast({ variation: 'success' })"
    />
    <SoonaButton
      type="button"
      copy="SoonaToast alert"
      variation="secondary-gray"
      @click="handleAddToast({ variation: 'alert' })"
    />
    <SoonaButton
      type="button"
      copy="SoonaToast error"
      variation="secondary-gray"
      @click="handleAddToast({ variation: 'error' })"
    />
    <SoonaButton
      type="button"
      variation="secondary-gray"
      copy="SoonaToast no icon"
      @click="handleAddToast"
    />
    <SoonaButton
      type="button"
      variation="secondary-gray"
      copy="SoonaToast variation with override"
      @click="handleAddToast({ variation: 'info', iconName: 'clapperboard' })"
    />
    <SoonaButton
      type="button"
      variation="secondary-gray"
      @click="handleToastSlots"
    >
      🎰 SoonaToast slots 🎰
    </SoonaButton>
  </div>
</template>

<style lang="scss" scoped>
.soona-toast-test-page {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  h1 {
    margin-bottom: 1.5rem;
  }

  p {
    margin-bottom: 1rem;
  }

  button {
    margin-bottom: 0.5rem;
  }
}
</style>
