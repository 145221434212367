<script setup>
import { computed, ref } from 'vue';
import SoonaItemCard from 'src/components/ui_library/SoonaItemCard.vue';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaError from 'src/components/ui_library/SoonaError.vue';
import BaseProductPickerDialog from '../picker/BaseProductPickerDialog.vue';
import InventoryItemProduct from './InventoryItemProduct.vue';
import InventoryItemDescription from './InventoryItemDescription.vue';
import PickerProductOption from '../picker/PickerProductOption.vue';
import { useCatalogItem } from 'src/queries/useCatalog';
import { useUpdateInventoryItem } from '@/queries/inventory/useUpdateInventoryItem';
import { usePriorityError } from 'src/composables/usePriorityError';

const props = defineProps({
  accountId: { type: [String, Number], required: true },
  item: {
    type: Object,
    required: true,
  },
  packageId: {
    type: Number,
    required: true,
  },
});

const itemId = computed(() => props.item.id);

const accountId = computed(() => props.accountId);

const catalogItemId = computed(() => props.item.catalog_item_id);

const hasCatalogItemId = computed(() => (catalogItemId.value ? true : false));

const id = computed(() => `inventory-item-${itemId.value}`);

const packageId = computed(() => props.packageId);

const {
  data: productData,
  isLoading: isLoadingProduct,
  error: productError,
} = useCatalogItem(accountId, catalogItemId, {
  enabled: hasCatalogItemId,
});

const {
  mutate,
  isPending: isUpdating,
  error: updateError,
} = useUpdateInventoryItem(itemId, accountId);

const product = computed(() => productData.value ?? null);

const pickerIsOpen = ref(false);

const select = productId => {
  mutate({
    catalog_item_id: productId,
    inventory_package_id: packageId.value,
  });
  pickerIsOpen.value = false;
};

const priorityError = usePriorityError(productError, updateError);

const isLoading = computed(
  () => (isLoadingProduct.value && hasCatalogItemId.value) || isUpdating.value
);
</script>

<template>
  <div>
    <SoonaItemCard
      :aria-labelledby="`${id}-heading`"
      :allow-preview="true"
      :image-url="item.image_url"
      :image-hq-url="item.image_hq_url"
      :inventory-count="item?.quantity ?? 1"
    >
      <template #content>
        <div class="inventory-item__wrapper">
          <div class="inventory-item__content">
            <InventoryItemProduct
              class="inventory-item__product"
              :account-id="accountId"
              :product="product"
              :product-error="productError"
              :is-loading="isLoading"
              @open-product-picker="() => (pickerIsOpen = true)"
            />
            <InventoryItemDescription
              class="inventory-item__description"
              :description="item?.description"
            />
          </div>
          <div class="inventory-item__actions">
            <SoonaButton
              v-if="product"
              copy="identify"
              size="medium"
              variation="tertiary"
              :on-click="() => (pickerIsOpen = true)"
            />
          </div>
        </div>
      </template>
    </SoonaItemCard>
    <SoonaError v-if="priorityError">
      {{ priorityError }}
    </SoonaError>
  </div>

  <BaseProductPickerDialog
    v-if="pickerIsOpen"
    :account-id="accountId"
    show-create
    :show-inventory-counts="false"
    @cancel="pickerIsOpen = false"
    @select="select"
  >
    <template #heading>identify product</template>
    <template #product-options="{ catalogItems }">
      <PickerProductOption
        v-for="catalogItem in catalogItems"
        :key="catalogItem.id"
        :product="catalogItem"
        @select="select"
      />
    </template>
  </BaseProductPickerDialog>
</template>

<style lang="scss" scoped>
@use '@/variables';

.inventory-item {
  &__wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    gap: 0.3125rem;
    padding: 0.5rem 0 0.5rem 1rem;
  }

  &__product {
    flex: 1 0 auto;
    min-width: 0;
    max-width: 100%;
  }

  &__description {
    flex: 1 1 auto;
    width: 100%;
    padding-right: 1rem;
  }

  &__actions {
    flex: 1 1 auto;
    display: flex;
    justify-content: flex-end;
    padding: 0.5rem 0.75rem 0.5rem 0;
  }
}

@media (min-width: variables.$screen-sm-min) {
  .inventory-item {
    &__content {
      display: flex;
      height: 100%;
      flex-flow: row nowrap;
      flex: 1 1 75%;
    }

    &__product {
      flex: 1 0 14.375rem;
    }

    &__description {
      padding-right: 0;
    }
  }
}
</style>
