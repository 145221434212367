<script setup>
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';

defineProps({
  maxScenes: {
    required: true,
    type: Number,
  },
  shootLength: {
    required: true,
    type: String,
  },
});

const emit = defineEmits(['add-scene', 'close-dialog']);
</script>

<template>
  <SoonaDialog role="alertdialog" @close="emit('close-dialog')">
    <template #heading>that’s a lot of scenes!</template>
    <p class="u-subheader--heavy subheading">
      we recommend a maximum of {{ maxScenes }} scenes for a
      {{ shootLength }} shoot.
    </p>
    <p class="u-body--regular">
      every shoot is different. if there are only subtle differences between
      your scenes it may be possible to set up and shoot more than the
      recommended amount. but we may reach out to reschedule if we think you’ll
      need more time.
    </p>
    <template #footer="{ close }">
      <SoonaButton
        copy="add more scenes anyway"
        variation="tertiary"
        @on-click="emit('add-scene')"
      />
      <SoonaButton copy="okay, I’m done" @on-click="close" />
    </template>
  </SoonaDialog>
</template>

<style lang="scss" scoped>
.subheading {
  padding-bottom: 0.5rem;
}
</style>
