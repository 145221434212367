<script setup>
import CreateCredit from './CreateCredit.vue';
import AvailableCredits from './AvailableCredits.vue';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import { computed } from 'vue';
import BookingCreditDetails from './BookingCreditDetails.vue';
import { useCapability } from 'src/composables/useCapability';
import { useAccountCreditInfo } from '@/queries/account/useAccountCreditInfo';
import SoonaLink from '@/components/ui_library/SoonaLink.vue';

const props = defineProps({
  accountId: {
    type: [Number, String],
    required: true,
  },
  currentPage: {
    type: String,
    required: true,
  },
  reservationId: {
    type: [Number, String],
    default: null,
  },
});

const { hasCapability: isFtSoonaStaff } = useCapability({
  capability: 'ft_soona_staff',
});

const accountId = computed(() => props.accountId);

const { data: creditInfo } = useAccountCreditInfo(accountId);

const reservationId = computed(() => {
  return props.reservationId ? Number(props.reservationId) : null;
});

const availablePreferredPhotoCredits = computed(() => {
  return creditInfo.value?.available_preferred_photo_credits?.length;
});

const downPaymentCredits = computed(() => {
  return creditInfo.value?.down_payment_credits?.filter(
    c => !reservationId.value || c.reservation_id === reservationId.value
  );
});

const totalDownPaymentCreditsRemaining = computed(() => {
  return downPaymentCredits.value?.length;
});

const availableAccountPhotoCredits = computed(() => {
  return creditInfo.value?.available_account_photo_credits?.length;
});

const availableAccountVideoCredits = computed(() => {
  return creditInfo.value?.available_account_video_credits?.filter(
    c => c.content_category === 'default'
  ).length;
});

const availableAccountBTSVideoCredits = computed(() => {
  return creditInfo.value?.available_account_video_credits?.filter(
    c => c.content_category === 'behind_the_scenes'
  ).length;
});

const availableAccountGifCredits = computed(() => {
  return creditInfo.value?.available_account_animation_credits?.length;
});

const downPaymentPhotoCreditRemaining = computed(() => {
  return creditInfo.value?.down_payment_credits?.filter(
    c =>
      !c.used_at &&
      c.credit_type === 'photo' &&
      (!reservationId.value || c.reservation_id === reservationId.value)
  ).length;
});

const downPaymentVideoCreditRemaining = computed(() => {
  return creditInfo.value?.down_payment_credits?.filter(
    c =>
      !c.used_at &&
      c.credit_type === 'video' &&
      c.content_category === 'default' &&
      (!reservationId.value || c.reservation_id === reservationId.value)
  ).length;
});

const downPaymentBTSVideoCreditRemaining = computed(() => {
  return creditInfo.value?.down_payment_credits?.filter(
    c =>
      !c.used_at &&
      c.credit_type === 'video' &&
      c.content_category === 'behind_the_scenes' &&
      (!reservationId.value || c.reservation_id === reservationId.value)
  ).length;
});

const downPaymentGifCreditRemaining = computed(() => {
  return creditInfo.value?.down_payment_credits?.filter(
    c =>
      !c.used_at &&
      c.credit_type === 'animation' &&
      (!reservationId.value || c.reservation_id === reservationId.value)
  ).length;
});

const availablePreferredPhotoCopy = computed(() => {
  if (
    availablePreferredPhotoCredits.value === 0 &&
    ['profilePage'].includes(props.currentPage)
  ) {
    return 'maximize your creative budget and get the best quality content for less. enroll in preferred today to unlock discounts.';
  }

  return null;
});

const availablePreferredPhotoCreditItems = computed(() => {
  if (
    ['crewTools', 'bookingPage'].includes(props.currentPage) ||
    availablePreferredPhotoCredits.value > 0
  ) {
    return [{ label: 'photos', quantity: availablePreferredPhotoCredits }];
  }

  return null;
});

const contactSalesLink = 'https://soona.co/contact-sales';
</script>
<template>
  <div class="available-account-credits">
    <div class="available-account-credits__content">
      <AvailableCredits
        :current-page="currentPage"
        :credit-items="[
          { label: 'photos', quantity: availableAccountPhotoCredits },
          { label: 'video clips', quantity: availableAccountVideoCredits },
          { label: 'gifs', quantity: availableAccountGifCredits },
          { label: 'BTS', quantity: availableAccountBTSVideoCredits },
        ]"
        title="account credits"
        cypress-name="account-credits-overview"
      />
      <AvailableCredits
        :current-page="currentPage"
        :credit-items="availablePreferredPhotoCreditItems"
        :text-body="availablePreferredPhotoCopy"
        title="preferred credits"
        cypress-name="preferred-credits-overview"
      />
      <AvailableCredits
        v-if="currentPage === 'bookingPage'"
        :current-page="currentPage"
        :credit-items="[
          { label: 'photos', quantity: downPaymentPhotoCreditRemaining },
          { label: 'video clips', quantity: downPaymentVideoCreditRemaining },
          { label: 'gifs', quantity: downPaymentGifCreditRemaining },
          { label: 'BTS', quantity: downPaymentBTSVideoCreditRemaining },
        ]"
        title="booking credits"
        cypress-name="booking-credits-overview"
      >
        <template #footer>
          <CreateCredit
            v-if="isFtSoonaStaff"
            :account-id="accountId"
            :credit-reason-default="
              reservationId == null ? 'pre_paid' : 'down_payment'
            "
            :lock-credit-reason="true"
            :reservation-id="reservationId"
          />
          <BookingCreditDetails
            v-if="isFtSoonaStaff && totalDownPaymentCreditsRemaining"
            :credits="downPaymentCredits"
          />
        </template>
      </AvailableCredits>
      <AvailableCredits
        v-if="currentPage === 'crewTools' && isFtSoonaStaff"
        :current-page="currentPage"
        cypress-name="add-credits-button-overview"
      >
        <template v-if="isFtSoonaStaff" #footer>
          <SoonaButton
            class="add-credits-link"
            copy="add credits"
            element="router-link"
            variation="secondary-black"
            size="medium"
            data-cypress="add-credits-link"
            :to="{
              name: 'account-additional-order',
            }"
          />
        </template>
      </AvailableCredits>
    </div>
    <span class="available-account-credits__link">
      <SoonaLink
        :to="contactSalesLink"
        size="small"
        target="_blank"
        rel="noopener noreferrer"
        >chat with sales</SoonaLink
      >
    </span>
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables';

.available-account-credits {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  border-radius: 0.75rem;
  box-shadow: 0 0.25rem 0.375rem rgba(0, 0, 0, 0.1);
  background-color: variables.$white-default !important;
  container: available-account-credits / inline-size;

  .available-account-credits__content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;

    :deep(.add-credits-link) {
      float: right;
      border: 1px solid variables.$gray-30;
    }
  }

  &__link {
    align-self: flex-end;
  }
}
</style>
