<script setup>
import { computed } from 'vue';
import SoonaSortable from '../../../SoonaSortable.vue';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';

const props = defineProps({
  list: {
    type: Array,
    default: () => [],
    required: true,
  },
});
const emits = defineEmits(['remove', 'reorder']);

const list = computed(() => props.list);

const options = computed(() => {
  return {
    // selector to the wrapper element in order to bind events to
    draggable: '.draggable',
    animation: 150,
    ghostClass: 'ghost',
    dragClass: 'drag',
  };
});

const routerLinkPath = item => {
  return item.asset_url;
};

const previewURL = item => {
  return item.preview.url;
};

const updateSort = e => {
  emits('reorder', e);
};

const remove = item => {
  emits('remove', item.id);
};

const showTrash = computed(() => {
  return list.value?.length > 1;
});
</script>

<template>
  <SoonaSortable
    :item-key="item => item.id"
    :list="list"
    :options="options"
    class="is-flex flex-wrap"
    @update="updateSort"
  >
    <template #item="{ element: item }">
      <div :key="item.id" class="draggable">
        <img :src="previewURL(item)" :alt="`thumbnail of ${item.title}`" />
        <div class="item-meta">
          <router-link
            v-if="routerLinkPath(item)"
            class="media-link u-small--regular"
            :to="{ path: routerLinkPath(item) }"
            :title="item.title"
          >
            {{ item.title }}
          </router-link>
          <button
            v-if="showTrash"
            class="u-button-reset"
            type="button"
            @click="() => remove(item)"
          >
            <SoonaIcon name="trash" size="medium" />
            <span class="u-a11y-only">remove collection item</span>
          </button>
        </div>
      </div>
    </template>
  </SoonaSortable>
</template>

<style lang="scss" scoped>
@use '@/variables';

.draggable {
  cursor: move;
  position: relative;
  height: 100%;
  width: calc(25% - 0.5rem);
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

.flex-wrap {
  flex-wrap: wrap;
}

.ghost {
  opacity: 0.7;
}

.draggable img:hover,
.ghost img {
  outline: 3px solid variables.$periwink-blue-60;
  outline-offset: -3px;
}

.item-meta {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.media-link {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: variables.$gray-50;
  text-decoration: underline;
}
</style>
