<script setup>
import { toRefs, computed } from 'vue';

import AIStudioIFrameContainer from '@/components/media-editor/AIStudioIFrameContainer.vue';
import { useMokkerAITemplatesUrl } from '@/queries/mokker/useMokkerTemplateUrl';

const props = defineProps({
  accountId: {
    type: String,
    required: true,
  },
  templateId: {
    type: String,
    required: true,
  },
});

const { accountId, templateId } = toRefs(props);

const { data: iframeUrlData } = useMokkerAITemplatesUrl(accountId, templateId);

const iframeUrl = computed(() => iframeUrlData.value?.result);

const backUrl = computed(() => ({
  name: 'ai-studio-templates',
  params: {
    accountId: accountId.value,
  },
}));
</script>

<template>
  <AIStudioIFrameContainer
    v-if="iframeUrl"
    :back-url="backUrl"
    :iframe-url="iframeUrl"
    :account-id="accountId"
  />
</template>

<style scoped lang="scss"></style>
