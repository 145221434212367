<template>
  <div class="shotlist-view">
    <div class="shotlist-view__inner">
      <div
        class="shotlist-view__header has-text-centered"
        :class="{ 'shotlist-view__header--pack': !canEditShotList }"
      >
        <h2 class="shotlist-view__title title has-text-centered">
          {{ canEditShotList ? shotlistHeader : 'here are your dream scenes!' }}
        </h2>
        <p
          v-if="canEditShotList"
          class="shotlist-subtitle is-italic has-text-centered"
        >
          {{ shotlistSubheader }}
        </p>

        <div class="has-text-centered">
          <SoonaButton
            v-if="canOpenShotList"
            copy="reopen shot list"
            data-cypress="button-reopen-shot-list"
            @click="handleReopen"
          />
        </div>
      </div>
      <SoonaButton
        v-if="
          !submitted &&
          !shootWrapped &&
          canEditShotList &&
          shotList.length > 0 &&
          (capabilities.ft_soona_staff.hasCapability || isAccountReservation)
        "
        copy="submit shot list"
        data-cypress="button-submit-shot-list"
        @click="showSubmitModal"
      />
      <shotlist-header
        v-if="currentReservation.pack_configuration"
        :pack="currentReservation.pack_configuration"
      />
      <pack-video-preview
        v-if="
          currentReservation.pack_configuration &&
          currentReservation.pack_configuration.isPackVideo &&
          !currentReservation.pack_configuration.isStopMotion
        "
        :pack-vimeo-id="currentReservation.pack_configuration.preview_vimeo_id"
        :pack-name="currentReservation.pack_configuration.name"
        page-description="get excited! here is a finished video made with this pack:"
        view="shotlist-view"
        class="shotlist-pack-video-preview"
      />
      <ShotListSceneCard
        :shot-list="shotList"
        :show-delete-modal="showDeleteModal"
        :current-reservation="currentReservation"
        :submitted="submitted"
        :shoot-wrapped="shootWrapped"
        :current-user-is-authorized="currentUserIsAuthorized"
        :can-edit-shot-list="!isLoading && canEditShotList"
      />
      <div
        v-if="canEditShotList"
        id="new-shot-button"
        class="shotlist-view__actions"
      >
        <SoonaButton
          copy="add new shot"
          variation="secondary-black"
          data-cypress="button-add-new-shot"
          @click="handleNewShot"
        />
        <SoonaButton
          v-if="canSubmitShotList"
          class="shotlist-view__secondary-submit-btn"
          copy="submit shot list"
          data-cypress="button-submit-shot-list"
          @click="showSubmitModal"
        />
      </div>
      <span v-else id="new-shot-button" />
      <!--  delete confirmation modal -->
      <SoonaDialog v-if="showDeleteConfirmationModal" @close="closeDeleteModal">
        <template #heading>
          <SoonaIcon class="warning-icon" name="circle-exclamation-solid" />
          are you sure you want to delete this shot?
        </template>
        <template #footer="{ close }">
          <SoonaButton variation="tertiary" @on-click="close">
            cancel
          </SoonaButton>
          <SoonaButton @on-click="deleteShot">
            yes, delete my shot
          </SoonaButton>
        </template>
      </SoonaDialog>
      <!-- submit shot list confirmation modal -->
      <SoonaDialog
        v-if="showSubmitConfirmationModal"
        class="confirm-shot-list-modal"
        @close="closeSubmitModal"
      >
        <template #heading>dream shoot ready to go?</template>
        <p>
          our crew will review your shot list ahead of your shootday to make
          sure we have everything to achieve your dreams! please submit your
          list at least
          <strong>48 hours before your shoot.</strong>
          anything added within 24 hours of the scheduled shoot time may require
          a new booking!
        </p>
        <template #footer="{ close }">
          <SoonaButton
            variation="tertiary"
            data-cypress="button-dialog-cancel"
            @on-click="close"
          >
            cancel
          </SoonaButton>
          <SoonaButton
            data-cypress="button-dialog-confirm"
            @on-click="submitShotListAndSurvey"
          >
            yes, submit my shot list
          </SoonaButton>
        </template>
      </SoonaDialog>
    </div>
    <div
      v-if="shootWrapped && shotList.length == 0"
      class="mt-xl has-text-centered"
    >
      <h3 class="u-headline--heavy">
        <span class="mr-m">👋</span><i>your shoot has wrapped</i
        ><span class="ml-m">👋</span>
      </h3>
      <p>nothing to see here!</p>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { useRoute } from 'vue-router';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useShotListEvents } from '@/composables/segment/useShotListEvents';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';
import ShotListSceneCard from './ShotListSceneCard.vue';
import ShotlistHeader from './shotlist/PickAPackShotlistHeader.vue';
import PackVideoPreview from 'src/components/booking/PickAPack/PackVideoPreview.vue';
import { ref } from 'vue';
import { useCapabilities } from '@/composables/useCapabilities';

export default {
  name: 'ShotlistView',
  components: {
    SoonaButton,
    SoonaDialog,
    SoonaIcon,
    ShotListSceneCard,
    ShotlistHeader,
    PackVideoPreview,
  },
  setup() {
    const { buttonClicked } = useBaseEvents();
    const { sceneStarted } = useShotListEvents();
    const route = useRoute();

    const onButtonClick = (buttonLabel, buttonAction, subContext) => {
      buttonClicked({
        context: route.meta.context,
        subContext,
        buttonLabel,
        buttonAction,
      });
    };

    const onSceneStart = () => {
      sceneStarted({
        context: route.meta.context,
      });
    };

    const capabilitiesRef = ref([
      { capability: 'ft_soona_staff' },
      { capability: 'edit_any_client_shot_list' },
    ]);

    const capabilities = useCapabilities(capabilitiesRef);

    return {
      capabilities,
      onButtonClick,
      onSceneStart,
    };
  },
  data() {
    return {
      showDeleteConfirmationModal: false,
      showSubmitConfirmationModal: false,
      shotToDelete: undefined,
      goals: '',
    };
  },
  computed: {
    ...mapGetters('shotList', ['canEdit', 'isLoading', 'submitted']),
    shootWrapped: function () {
      return this.currentReservation
        ? this.currentReservation.status == 'completed' ||
            this.currentReservation.status == 'pending_selects' ||
            this.currentReservation == 'edits_required'
        : true;
    },
    shootStarted: function () {
      return this.currentReservation.status === 'in_progress';
    },
    ...mapState({
      shotList: state => state.shotList.currentShotList,
      currentShot: state => state.shotList.currentShot,
      allTagCategories: state => state.shotList.allTagCategories,
      currentReservation: state => state.reservation.currentReservation,
      currentUser: state => state.currentUser,
      isAccountReservation: state =>
        state.currentUser.accountId ==
        state.reservation.currentReservation.account_id,
    }),
    shotlistHeader: function () {
      if (this.shootWrapped || this.submitted) {
        if (this.shotList.length > 0) {
          return 'your final shot list. what a beauty!';
        } else {
          return '';
        }
      } else {
        return "let's plan your dream scenes!";
      }
    },
    shotlistSubheader: function () {
      if (this.shootWrapped) {
        return '';
      } else if (this.submitted) {
        return 'thanks for submitting to the crew for review!';
      } else if (this.shootIsUnscheduled) {
        if (this.canEdit && this.shotList.length == 0) {
          return 'use the soona shot builder to help us understand each shot you need.';
        } else {
          return 'add a new shot for every angle, background, or product.';
        }
      } else {
        if (this.moreThanOneDayBeforeShoot) {
          return 'please provide a complete shot list at least 2 days before your shoot to ensure we can capture all your scenes. shot lists are locked 24 hours before the scheduled shoot time and additional scenes may require a new booking.';
        } else {
          return 'your shot list is now locked. anything added within 24 hours of the scheduled shoot time may require a new booking!';
        }
      }
    },
    shootIsUnscheduled() {
      return this.currentReservation.start == null;
    },
    moreThanOneDayBeforeShoot() {
      let currentDateTime = new Date();
      let dayBeforeStart = new Date(this.currentReservation.start);
      dayBeforeStart.setDate(dayBeforeStart.getDate() - 1);
      return currentDateTime < dayBeforeStart;
    },
    currentUserIsAuthorized() {
      return (
        this.capabilities.edit_any_client_shot_list.hasCapability ||
        this.isAccountReservation
      );
    },
    canOpenShotList() {
      const shotListIsOpenable = this.submitted && !this.shootWrapped;
      return shotListIsOpenable && this.currentUserIsAuthorized;
    },
    canSubmitShotList() {
      const shotListIsSubmittable = !this.submitted && this.shotList.length > 2;
      return shotListIsSubmittable && this.currentUserIsAuthorized;
    },
    canEditShotList() {
      const shotListIsEditable =
        !this.submitted &&
        !this.shootWrapped &&
        !this.currentReservation.isPickAPack;
      return shotListIsEditable && this.currentUserIsAuthorized;
    },
  },
  mounted: function () {
    this.goals = this.currentReservation.description;
    if (this.currentReservation.pack_configuration) {
      this.loadPackConfiguration(this.currentReservation.pack_configuration.id);
    }
  },
  methods: {
    ...mapActions('shotList', [
      'newShot',
      'hideShot',
      'submitShotList',
      'draftShotList',
    ]),
    ...mapActions('pickAPack', ['loadPackConfiguration']),
    handleNewShot() {
      this.newShot();
      this.onButtonClick('add new shot', 'opens the shot builder');
      this.onSceneStart();
    },
    handleReopen() {
      this.draftShotList();
      this.onButtonClick('reopen shot list', 'reopens shot list');
    },
    showDeleteModal(shot) {
      //this.$track('Delete Clicked', {
      //   shot: this.currentShot,
      //   reservation: this.currentReservation,
      // });
      this.showDeleteConfirmationModal = !this.showDeleteConfirmationModal;
      this.shotToDelete = shot;
    },
    closeDeleteModal() {
      //this.$track('Delete Cancelled', {
      //   shot: this.currentShot,
      //   reservation: this.currentReservation,
      // });
      this.showDeleteConfirmationModal = false;
    },
    deleteShot() {
      //this.$track('Yes, Delete My Shot Clicked', {
      //   shot: this.currentShot,
      //   reservation: this.currentReservation,
      // });
      this.hideShot(this.shotToDelete);
      this.showDeleteConfirmationModal = !this.showDeleteConfirmationModal;
    },
    showSubmitModal() {
      this.showSubmitConfirmationModal = !this.showSubmitConfirmationModal;
      this.onButtonClick(
        'submit shot list',
        'opens modal to confirm shot list submission'
      );
    },
    closeSubmitModal() {
      this.showSubmitConfirmationModal = !this.showSubmitConfirmationModal;
      this.onButtonClick(
        'cancel',
        'closes shot list submission modal',
        'submit shot list modal'
      );
    },
    submitShotListAndSurvey() {
      this.showSubmitConfirmationModal = false;
      this.submitShotList(this.currentReservation.id);
      this.createSurvey();
      this.onButtonClick(
        'yes! submit my shot list',
        'submits shot list',
        'submit shot list modal'
      );
    },
    createSurvey() {
      let properties = {
        reservationId: this.currentReservation.id,
        reservationType: this.currentReservation.reservation_type,
        location: this.currentReservation.location?.name,
        industry: this.currentReservation.industry,
        referrer: this.currentReservation.referrer,
      };

      this.currentReservation.tags?.forEach(tag => {
        properties[tag.title] = true;
      });

      this.$survey('MxnCvTkI5mfsL7ll', {
        email: this.currentUser.email,
        name: this.currentUser.name,
        properties: properties,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';
@import '@/buttons';

.shotlist-view {
  &__inner {
    align-items: center;
    background: variables.$white-default;
    display: flex;
    flex-direction: column;
  }

  &__header {
    width: 100%;
    margin-bottom: 1rem;

    &--pack {
      margin-bottom: 0;
    }
  }

  &__title {
    margin: 0;
    padding-bottom: 36px;
  }

  &__actions {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 1em;

    > div {
      padding-bottom: 10px;
    }
  }

  &__secondary-submit-btn {
    margin-top: 1rem;
  }
}

.warning-icon {
  color: variables.$friendly-red-50;
  margin-right: 10px;
}

.shotlist-pack-video-preview {
  margin-top: 20px;
}
</style>
