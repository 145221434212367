<script setup>
import { computed, ref, watchEffect } from 'vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import BaseProductPicker from '../products/picker/BaseProductPicker.vue';
import AmazonPublishImageSelector from './AmazonPublishImageSelector.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import PickerProductOption from '@/components/user/anytime/products/picker/PickerProductOption.vue';
import ProductPickerGroupTitle from '@/components/user/anytime/products/picker/ProductPickerGroupTitle.vue';
import { useUpdateCatalogItemAmazonListing } from '@/queries/useCatalog';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useRoute } from 'vue-router';
import { useAssetEvents } from 'src/composables/segment/useAssetEvents';

const props = defineProps({
  accountId: {
    type: [Number, String],
    required: true,
  },
  file: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['close', 'asset-published']);
const accountId = computed(() => props.accountId);
const file = computed(() => props.file);
const fileId = computed(() => file.value?.id);
const searchRef = ref(null);
const selectedProduct = ref(null);
const selectedSlotPayload = ref(false);

const selectedCatalogItemId = computed(() => selectedProduct.value?.id);

const { linkClicked } = useBaseEvents();
const { assetPublishedToListing } = useAssetEvents();
const route = useRoute();

const { mutate: updateAmazonListing, isPending: isUpdating } =
  useUpdateCatalogItemAmazonListing(accountId, selectedCatalogItemId);

watchEffect(() => {
  if (searchRef.value) {
    searchRef.value.focus();
  }
});

const search = ref('');

function closeDialog() {
  emit('close');
}

function select(_, product) {
  selectedProduct.value = product;
}

function groupProducts(catalogItems) {
  return catalogItems.reduce((acc, item) => {
    const itemCopy = { ...item };
    let group = itemCopy.attached_to_digital_asset_id
      ? 'this asset'
      : 'more products';

    itemCopy.buttonVariation =
      group === 'this asset' ? 'primary' : 'secondary-black';
    acc[group] ??= [];
    acc[group].push(itemCopy);
    return acc;
  }, {});
}

function goBackToProductSelect() {
  selectedProduct.value = null;
  selectedSlotPayload.value = null;
}

function slotSelected(payload) {
  selectedSlotPayload.value = payload;
}

function publishToAmazon() {
  if (route.meta.context) {
    linkClicked({
      context: route.meta.context,
      subContext: 'amazon publish dialog',
      linkLabel: 'publish',
      linkHref: '#publish-to-amazon',
    });
  }
  updateAmazonListing(
    { images: selectedSlotPayload.value },
    {
      onSuccess: () => {
        emit('asset-published', {
          variation: 'success',
          message: 'successfully published to Amazon listing',
          noDismissButton: true,
        });
        assetPublishedToListing({
          context: route.meta.context,
          subContext: 'amazon publish dialog',
          catalogItemId: selectedCatalogItemId.value,
          platform: 'Amazon',
          productName: selectedProduct.value.name,
          productType: selectedProduct.value.product_type
            .toLowerCase()
            .replace('_', ' '),
          sku: selectedProduct.value.sku,
        });
      },
      onError: () => {
        emit('asset-published', {
          variation: 'error',
          message:
            'we are having trouble publishing to your Amazon listing. please try again later.',
          noDismissButton: true,
        });
      },
    }
  );
}

const orderBy = computed(() => {
  return fileId.value ? 'attached_to_digital_asset_id' : 'name';
});
</script>

<template>
  <SoonaDialog max-width="48rem" top-affixed @close="closeDialog">
    <template #heading>publish to listing</template>
    <template #header>
      <SoonaTextfield
        v-if="!selectedProduct"
        ref="searchRef"
        v-model="search"
        type="search"
        label="search products"
        name="products-search"
        placeholder="product name or SKU"
        hide-label
        class="amazon-publish-dialog__search"
      >
        <template #icon-left>
          <SoonaIcon name="search" size="small" />
        </template>
      </SoonaTextfield>
    </template>
    <BaseProductPicker
      v-if="!selectedProduct"
      :account-id="accountId"
      :attached-to-digital-asset-id="fileId"
      :multiselect="false"
      :show-create="false"
      :search="search"
      filter="only_amazon_products"
      :order-by="orderBy"
    >
      <template #product-options="{ catalogItems }">
        <template
          v-for="(items, group) in groupProducts(catalogItems)"
          :key="group"
        >
          <ProductPickerGroupTitle>{{ group }}</ProductPickerGroupTitle>
          <PickerProductOption
            v-for="product in items"
            :key="product.id"
            :product="product"
            :button-variation="product.buttonVariation"
            @select="select"
          />
        </template>
      </template>
    </BaseProductPicker>
    <AmazonPublishImageSelector
      v-if="selectedProduct"
      :account-id="accountId"
      :catalog-item-id="selectedCatalogItemId"
      :file="file"
      :listing-updating="isUpdating"
      @slot-selected="slotSelected"
    />
    <template v-if="selectedProduct" #footer>
      <SoonaButton
        type="button"
        variation="tertiary"
        copy="cancel"
        @on-click="() => goBackToProductSelect()"
      />
      <SoonaButton
        variation="primary"
        copy="publish"
        :loading="isUpdating"
        :disabled="!selectedSlotPayload"
        @on-click="() => publishToAmazon()"
      />
    </template>
  </SoonaDialog>
</template>

<style lang="scss" scoped>
.amazon-publish-dialog {
  &__search {
    margin-top: 1rem;
    padding-bottom: 0;
  }
}
</style>
