import { http } from '@/config/vue-axios';
import { mapPaginationHeaders } from '@/lib/api-transforms';

/**
 *
 * @param {Object} [params]
 * @param {string | number} [reservationId]
 * @param {number} [params.activeAssetId]
 * @param {number} [params.currentPage]
 * @param {number} [params.itemsPerPage]
 * @param {AbortSignal} [signal]
 */
export async function reservationDigitalAssets(
  reservationId,
  { activeAssetId, currentPage, itemsPerPage, filters, facets } = {},
  signal
) {
  const params = { ...filters };

  if (activeAssetId) params['active_asset_id'] = activeAssetId;
  if (currentPage) params['page'] = currentPage;
  if (itemsPerPage) params['items'] = itemsPerPage;
  if (facets) params['facets'] = facets;

  const response = await http.get(
    `/reservations/${reservationId}/reservation_digital_assets`,
    {
      params,
      signal,
    }
  );

  return {
    pagination: mapPaginationHeaders(response.headers),
    assets: response.data?.reservation_digital_assets || [],
    bagCount: response.data?.bag_count,
    staffPicksCount: response.data?.staff_pick_count,
    favoritesCount: response.data?.favorite_count,
    hiddenCount: response.data?.hidden_count,
    totalCount: response.data?.total_count,
  };
}

/**
 *
 * @param {number} reservationId
 * @param {Object} [body]
 */
export async function createReservationDigitalAsset({ reservationId, body }) {
  const response = await http.post(
    `/reservations/${reservationId}/reservation_digital_assets`,
    { reservation_digital_asset: body }
  );

  return response.data;
}
