<script setup>
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { computed, ref } from 'vue';
import orderBy from 'lodash/orderBy';
import ProServiceSelectionCard from './ProServiceSelectionCard.vue';
import ProServiceProviderServiceInfo from './ProServiceProviderServiceInfo.vue';
import ProServiceSelectionActionOverview from './ProServiceSelectionActionOverview.vue';
import UpgradeCard from 'src/components/user/anytime/UpgradeCard.vue';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import { convertToMMDDYYYY } from 'src/lib/date-formatters.js';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useReservation } from '@/composables/useReservation';
import { useCapabilities } from '@/composables/useCapabilities';

const props = defineProps({
  crewAssignments: {
    type: Array,
    default: null,
  },
  isAssignedCrew: {
    type: Boolean,
    default: false,
  },
});
const store = useStore();
const route = useRoute();

const capabilitiesRef = ref([
  { capability: 'manage_account_pro_service_requirements' },
  { capability: 'ft_soona_staff' },
  { capability: 'send_pro_service_reminders' },
]);

const capabilities = useCapabilities(capabilitiesRef);

const crewAssignments = computed(() => props.crewAssignments);

const currentUser = computed(() => store.state.currentUser);

const currentReservation = computed(
  () => store.state.reservation.currentReservation
);

const assignments = computed(() => {
  return crewAssignments.value
    ? crewAssignments.value.filter(
        crew => crew.user_id == currentUser.value.userID
      )
    : [];
});

const { linkClicked } = useBaseEvents();

const proServiceRequirements = computed(
  () => store.state.proService.proServiceRequirements
);

const purchasedProServices = computed(() =>
  orderBy(
    store.state.reservation.currentReservation.purchased_pro_services,
    ['typeform', ps => ps.name === 'grocery shopping', 'name'],
    ['asc', 'desc', 'asc']
  )
);

const pspServiceRequirements = computed(() => {
  let requirements = [];
  assignments.value.forEach(assignment => {
    return proServiceRequirements.value?.forEach(requirement => {
      if (assignment.crew_role === requirement.selected_tags[0].title) {
        requirements.push(requirement);
      }
    });
  });
  return requirements;
});

const modelRequirements = computed(() => {
  return proServiceRequirements.value?.filter(
    req =>
      req.service_tag.title === 'hand model' ||
      req.service_tag.title === 'full body model' ||
      req.service_tag.title === 'foot model' ||
      req.service_tag.title === 'pet model' ||
      req.service_tag.title === 'content creator'
  );
});

function resendNotification() {
  store.dispatch('reservation/resendProServiceNotifications', {
    reservationId: currentReservation.value.id,
  });
  if (route.meta.context) {
    linkClicked({
      context: route.meta.context,
      subContext: 'pro service selection footer',
      linkLabel: 'Send Email Reminder',
      linkHref: '#send-email-reminder',
    });
  }
}

const { isPack, notStarted } = useReservation(
  computed(() => currentReservation.value.id)
);

const showUpgradeCard = computed(() => {
  return notStarted.value && !isPack.value;
});
</script>
<template>
  <div class="pro-service-selection w-full is-flex flex-column">
    <h2 class="pro-service-title">pro services</h2>
    <p
      v-if="capabilities.manage_account_pro_service_requirements.hasCapability"
      class="pro-service-sub-title"
    >
      make selections below for your pro services to be shoot ready.
    </p>
    <hr />
    <ProServiceSelectionActionOverview
      v-if="capabilities.ft_soona_staff.hasCapability && modelRequirements"
      :crew-assignments="
        crewAssignments && crewAssignments.length > 0 ? crewAssignments : []
      "
      :model-requirements="modelRequirements"
      :purchased-pro-services="purchasedProServices"
    />
    <hr v-if="capabilities.ft_soona_staff.hasCapability && modelRequirements" />
    <ProServiceProviderServiceInfo
      v-if="isAssignedCrew && !capabilities.ft_soona_staff.hasCapability"
      :crew-assignments="assignments"
      :current-reservation="currentReservation"
      :psp-service-requirements="pspServiceRequirements"
    />
    <template v-else>
      <ProServiceSelectionCard
        v-for="(proServiceRequirement, index) in proServiceRequirements"
        :key="index"
        :pro-service-requirement="proServiceRequirement"
        :location="currentReservation.location.name"
        :reservation="currentReservation"
        :purchased-pro-services="purchasedProServices"
      />
    </template>
    <UpgradeCard
      v-if="showUpgradeCard"
      :reservation-id="currentReservation.id"
    />
    <p class="need-help-text">
      need help finding the right model? connect with our crew. We’d be happy to
      help.
      <a href="mailto:hey@soona.co" class="help-email">hey@soona.co</a>
    </p>
    <hr v-if="capabilities.send_pro_service_reminders.hasCapability" />
    <div
      v-if="capabilities.send_pro_service_reminders.hasCapability"
      class="reminder-section"
    >
      <p
        v-if="
          currentReservation.pro_services_reminder_sent_at &&
          currentReservation.pro_services_reminder_sent_by
        "
        class="last-sent"
      >
        last sent:
        {{
          convertToMMDDYYYY(currentReservation.pro_services_reminder_sent_at)
        }}
        by {{ currentReservation.pro_services_reminder_sent_by }}
      </p>
      <SoonaButton
        :on-click="resendNotification"
        copy="send email reminder"
        variation="secondary-black"
      />
    </div>
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables_fonts';

.pro-service-selection {
  text-align: left;
  .pro-service-title {
    font: normal normal bold 1.25rem/1.125rem Lato;
  }
  .pro-service-sub-title {
    font: normal normal normal 1rem/1.875rem Lato;
    margin: 1rem 0 0 0;
  }
}
.need-help-text {
  margin: 1.5rem 1rem 0rem 1rem;
  text-transform: lowercase;
  font-size: 1rem;
  font-weight: 700;

  .help-email {
    text-decoration: underline;
  }
}
.reminder-section {
  display: flex;
  margin-left: auto;
  align-items: center;
  .last-sent {
    @include variables_fonts.u-label--regular;
    margin-right: 1rem;
  }
}
</style>
