<script setup>
import { computed, onMounted, onBeforeUnmount, ref } from 'vue';
import { useCopy } from 'src/composables/useCopy';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import { generateHTML } from '@tiptap/core';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { usePromotionEvents } from '@/composables/segment/usePromotionEvents';
import { useRoute } from 'vue-router';

const props = defineProps({
  widgets: {
    type: Array,
    required: true,
  },
  disableHyperlink: {
    type: Boolean,
    default: false,
    required: false,
  },
});

const route = useRoute();
const { buttonClicked, linkClicked } = useBaseEvents();
const { promotionViewed, promotionSelected } = usePromotionEvents();

const { copyText, copySuccess } = useCopy();

const numberOfPromos = computed(() => props.widgets.length);
let activeIndex = ref(0);
let activeWidget = computed(() => props.widgets.at(activeIndex.value));

function trackButtonClicked(label, action) {
  if (route.meta.context) {
    buttonClicked({
      context: route.meta.context,
      subContext: 'booking banner',
      buttonLabel: label,
      buttonAction: action,
    });
  }
}

function trackLinkClicked(label) {
  if (route.meta.context) {
    linkClicked({
      context: route.meta.context,
      subContext: 'booking banner',
      linkLabel: label,
      linkHref:
        activeWidget.value.link_enabled === true
          ? activeWidget.value.link_url
          : null,
    });
  }
}

function trackPromotionViewed() {
  promotionViewed({
    creativeName: activeWidget.value.promo_title,
    creativeSlot: 'booking banner',
    promotionName: activeWidget.value.promo_code,
  });
}

function trackPromotionSelected() {
  promotionSelected({
    creativeName: activeWidget.value.promo_title,
    creativeSlot: 'booking banner',
    promotionName: activeWidget.value.promo_code,
  });
}

function copyCodeClicked() {
  copyText(activeWidget.value.promo_code);
  trackPromotionSelected();
}

let activeWidgetDescription = computed(() => {
  return activeWidget.value.description
    ? generateHTML(activeWidget.value.description, [StarterKit, Underline])
    : {};
});

let linkModalDescription = computed(() => {
  return activeWidget.value.link_modal_description
    ? generateHTML(activeWidget.value.link_modal_description, [
        StarterKit,
        Underline,
      ])
    : {};
});

let firstRotation = ref(true);
function switchPromo() {
  if (activeIndex.value < numberOfPromos.value - 1) {
    copySuccess.value = false;
    activeWidget.value = props.widgets.at(activeIndex.value);
    activeIndex.value += 1;
  } else {
    copySuccess.value = false;
    firstRotation.value = false;
    activeIndex.value = 0;
    activeWidget.value = props.widgets.at(activeIndex.value);
  }

  if (firstRotation.value) {
    trackPromotionViewed();
  }
}

let timer = null;
function startRotation() {
  if (numberOfPromos.value > 1) {
    timer = setInterval(switchPromo, 7000);
  }
}
function stopRotation() {
  clearInterval(timer);
}

let linkModalVisible = ref(false);

function showLinkModal() {
  stopRotation();
  linkModalVisible.value = true;
}

function ctaClicked() {
  trackLinkClicked(activeWidget.value.link_cta);

  if (activeWidget.value.link_enabled === true) {
    if (
      props.disableHyperlink !== true &&
      activeWidget.value.link_url.includes('https://book.soona.co')
    ) {
      location.href = activeWidget.value.link_url;
    } else {
      window.open(activeWidget.value.link_url, '_blank');
    }
  }
  if (activeWidget.value.link_modal_enabled === true) {
    showLinkModal();
  }
}

function hideLinkModal() {
  trackButtonClicked('got it', 'close banner modal');

  linkModalVisible.value = false;
  startRotation();
}

function iconOption(iconName) {
  if (iconName === 'internal') {
    return 'arrow-right';
  } else if (iconName === 'external') {
    return 'arrow-up-right-from-square';
  } else if (iconName === 'sale') {
    return 'tag';
  }
}

onMounted(() => {
  trackPromotionViewed();
  startRotation();
});

onBeforeUnmount(() => {
  if (timer) {
    stopRotation();
  }
});
</script>

<template>
  <header v-if="activeWidget" class="header-promotion-widget">
    <div class="header-promotion-widget__desktop">
      <div class="hide-promotion-section"></div>
      <div class="description-section">
        <div
          v-if="activeWidgetDescription && activeWidgetDescription.length > 0"
          v-html="activeWidgetDescription"
        />
        <p v-else>placeholder description</p>
        <span>
          <button
            v-if="activeWidget.copy_code_enabled"
            class="u-button-reset copy-btn"
            @click="copyCodeClicked"
          >
            <SoonaIcon name="duplicate" />
            <span class="u-a11y-only">copy discount code</span>
          </button>
          <span v-show="copySuccess" class="copied text-s">copied!</span>
        </span>
      </div>
      <div class="link-section">
        <a
          v-if="activeWidget.link_modal_enabled"
          class="link"
          @click="ctaClicked"
          >{{ activeWidget.link_cta }}
        </a>
        <a v-if="activeWidget.link_enabled" class="link" @click="ctaClicked"
          >{{ activeWidget.link_cta }}
          <SoonaIcon
            v-if="activeWidget.link_icon_enabled"
            :name="iconOption(activeWidget.link_icon)"
            size="medium"
          />
        </a>
      </div>
    </div>
    <a
      class="header-promotion-widget__mobile"
      @click="
        activeWidget.link_enabled || activeWidget.link_modal_enabled
          ? ctaClicked()
          : null
      "
    >
      <div class="description-section">
        <div
          v-if="activeWidgetDescription && activeWidgetDescription.length > 0"
          v-html="activeWidgetDescription"
        />
        <p v-else>placeholder description</p>
      </div>
    </a>
    <SoonaDialog v-if="linkModalVisible" @close="hideLinkModal">
      <template #heading>
        {{ activeWidget.title || activeWidget.promo_title }}
      </template>
      <div
        v-if="linkModalDescription && linkModalDescription.length > 0"
        class="modal-body-text"
        v-html="linkModalDescription"
      />
      <template #footer="{ close }">
        <SoonaButton @on-click="close">got it</SoonaButton>
      </template>
    </SoonaDialog>
  </header>
</template>

<style scoped lang="scss">
@use '@/variables';
.header-promotion-widget {
  &__desktop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    background-color: v-bind('activeWidget?.background_color');
    color: v-bind('activeWidget?.text_color');
    transition: background-color 0.75s ease;
  }

  &__mobile {
    display: none;
  }

  .description-section {
    font-size: 18px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    word-break: break-word;
  }

  .hide-promotion-section {
    width: 15%;
  }

  .link-section {
    text-align: right;
    width: 15%;
  }

  .link {
    align-items: center;
    color: v-bind('activeWidget?.text_color');
    display: inline-flex;
    gap: 0.5rem;
    text-decoration: none;
    font-size: 14px;
    text-decoration: underline;
  }

  .link-icon {
    margin-left: 8px;
  }

  .modal-body-text {
    white-space: pre-wrap;
    word-break: break-word;
  }

  .copy-btn {
    align-items: center;
    color: v-bind('activeWidget?.text_color');
    display: inline-flex;
    margin-left: 0.5rem;
    justify-content: center;
  }

  .copied {
    margin-left: 6px;
  }

  @media (max-width: variables.$screen-xxs-max) {
    &__desktop {
      display: none;
    }

    &__mobile {
      display: flex;
      padding: 1rem;
      background-color: v-bind('activeWidget?.background_color');
      color: v-bind('activeWidget?.text_color');
      transition: background-color 0.75s ease;
    }

    .description-section {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      word-break: break-word;
    }
  }
}
</style>
