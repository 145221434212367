<script setup>
import { computed, ref, watch, watchEffect } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useFlag } from 'src/composables/useFlag';
import SoonaLoading from '@/components/SoonaLoading.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import ShowAccount from 'src/components/crew/booking/steps/customer/ShowAccount.vue';
import SoonaSelect from '@/components/ui_library/SoonaSelect.vue';
import PaymentSelector from '../add_on_order/PaymentSelector.vue';
import SoonaTextfield from 'src/components/ui_library/SoonaTextfield.vue';
import ShootOptionsSelector from '../add_on_order/ShootOptionsSelector.vue';
import { queryKeys } from '@/queries/query-keys';
import { useQueryClient } from '@tanstack/vue-query';

const props = defineProps({
  accountId: {
    type: Number,
    required: true,
  },
});
const emit = defineEmits(['compOrder']);
const store = useStore();
const router = useRouter();
const queryClient = useQueryClient();
const productsInCart = computed(() => store.getters['account/productsInCart']);
const accountEmail = computed(() => store.getters['account/accountEmail']);
const errorMessage = ref('');
const loadingLocation = ref(false);
const orderProcessing = ref(false);
const selectedLocation = ref('');
const selectedCreditTypes = ref([]);
const selectedPreferredTypes = ref(0);
const selectedPaymentOption = ref('');
const compReason = ref('');
const email = ref(accountEmail);
const emailSubject = ref('');
const emailNotes = ref('');
const showFollowupInfo = computed(() => {
  return (
    selectedPaymentOption.value === 'card_on_file' ||
    selectedPaymentOption.value === 'send_invoice'
  );
});
const paymentsPaymentMethodsFlag = useFlag('payments_payment_methods');
const btnDisabled = computed(() => {
  if (
    selectedCreditTypes.value.length > 0 &&
    ((showFollowupInfo.value &&
      selectedPaymentOption.value &&
      email.value &&
      emailSubject.value &&
      emailNotes.value) ||
      (selectedPaymentOption.value === 'comp_order' && compReason.value))
  ) {
    return false;
  } else {
    return true;
  }
});

const accountCreditProducts = computed(() => {
  const photoProducts = store.getters['account/photoAccountCreditProducts'];
  const videoProducts = store.getters['account/videoAccountCreditProducts'];
  const gifProducts = store.getters['account/gifAccountCreditProducts'];
  let array = photoProducts.concat(videoProducts, gifProducts);
  return array.map(product => {
    return { label: product.name, value: product.id };
  });
});
const defaultPayment = computed(() => {
  if (paymentsPaymentMethodsFlag) {
    const payment = store.getters['account/defaultPaymentMethod'];

    if (payment?.payment_method_type === 'card') {
      return payment;
    }
  } else {
    return store.state.payment.defaultPaymentInfo;
  }
  return null;
});
const paymentOptions = computed(() => {
  const options = [
    {
      value: 'send_invoice',
      label: 'send invoice',
      description: ['customer must complete invoice for credits to be active'],
    },
    {
      value: 'comp_order',
      label: 'comp order',
      description: [
        'customer will not be charged',
        'order will appear in history with $0.00 charge',
      ],
    },
  ];

  if (selectedPreferredTypes.value > 0) {
    return options;
  }

  if (defaultPayment.value && paymentsPaymentMethodsFlag) {
    options.unshift({
      value: 'card_on_file',
      label: 'charge card on file',
      subTitle:
        (defaultPayment.value?.payment_method_data?.brand ?? 'card brand') +
        ' ending in ' +
        (defaultPayment.value?.payment_method_data?.last4 ?? 'xxxx'),
      description: [],
      disabled: !defaultPayment.value,
    });
  } else if (defaultPayment.value) {
    options.unshift({
      value: 'card_on_file',
      label: 'charge card on file',
      subTitle:
        (defaultPayment.value?.payment_method_data?.brand ?? 'card brand') +
        ' ending in ' +
        (defaultPayment.value?.payment_method_data?.last4 ?? 'xxxx'),
      description: [],
      disabled: !defaultPayment.value,
    });
  }

  return options;
});
const confirmBtnText = computed(() => {
  switch (selectedPaymentOption.value) {
    case 'card_on_file':
      return 'CHARGE ORDER';
    case 'send_invoice':
      return 'CREATE ORDER';
    case 'comp_order':
      return 'CREATE COMPED ORDER';
    default:
      return 'CONFIRM ORDER';
  }
});

watch(
  () => productsInCart.value,
  () => {
    if (productsInCart.value) {
      selectedCreditTypes.value = selectedCreditTypes.value.filter(product =>
        productsInCart.value.some(cartProduct => product === cartProduct.id)
      );
    }
  },
  { deep: true }
);
watch(emailNotes, notes => {
  store.dispatch('account/setAdditionalChargeMemo', notes);
});
watchEffect(() => {
  if (props.accountId !== null) {
    store.dispatch('payment/fetchDefaultPayment', props.accountId);
  }
});
function minimumCredits(productName) {
  switch (productName) {
    case 'Preferred 50 Photo Credit':
      return 50;
    case 'Preferred 200 Photo Credit':
      return 200;
    case 'Preferred 500 Photo Credit':
      return 500;
    default:
      return 1;
  }
}
function selectPayment(option) {
  selectedPaymentOption.value = option;
  if (selectedPaymentOption.value === 'card_on_file') {
    emailSubject.value = 'thanks for your order!';
    store.dispatch('account/setAdditionalChargeImmediately', true);
    store.dispatch('account/setCompedOrder', false);
    emit('compOrder', false);
  } else if (selectedPaymentOption.value === 'send_invoice') {
    store.dispatch('account/setAdditionalChargeImmediately', false);
    store.dispatch('account/setCompedOrder', false);
    emailSubject.value = 'order invoice - please complete!';
    emit('compOrder', false);
  } else {
    store.dispatch('account/setAdditionalChargeImmediately', true);
    store.dispatch('account/setCompedOrder', true);
    emit('compOrder', true);
  }
}
function selectCompReason(reason) {
  compReason.value = reason;
  store.dispatch('account/setCreditReason', reason);
}

async function handleConfirm() {
  orderProcessing.value = true;
  await store.dispatch('account/setAdditionalChargeMemo', emailNotes.value);

  store
    .dispatch('account/createAdditionalChargeOrder', {
      isShopifyOrder: false,
      stripe: defaultPayment.value?.external_payment_method_id,
    })
    .then(async ([, error]) => {
      if (error) {
        errorMessage.value =
          error.status + ' ' + error.config.url + ' ' + error.data.message;
        orderProcessing.value = false;
      } else {
        await queryClient.invalidateQueries({
          queryKey: queryKeys.accountCreditInfo(props.accountId),
        });
        store.dispatch('account/clearAdditionalChargeItems');
        await router.push(`/account/${props.accountId}/crew-tools`);
        orderProcessing.value = false;
      }
    });
}
function selectLocation(option) {
  if (selectedLocation.value != option.value) {
    loadingLocation.value = true;
    selectedLocation.value = option.value;
    store
      .dispatch('account/loadAdditionalChargeProducts', {
        id: option.value,
      })
      .then(() => {
        loadingLocation.value = false;
      });
    selectedCreditTypes.value = [];
    compReason.value = '';
    selectedPaymentOption.value = '';
    emailSubject.value = '';
    emailNotes.value = '';
    store.dispatch('account/clearAdditionalChargeItems');
  }
}
function onSelectProduct(product) {
  let quantity = minimumCredits(product.label);
  store.dispatch('account/setAdditionalChargeItems', {
    [product.value]: quantity,
  });
  if (product.label.toLowerCase().includes('preferred')) {
    selectedPreferredTypes.value += 1;
  }
  store.dispatch('account/setAdditionalChargeImmediately', true);
}
function onDeselectProduct(product) {
  store.dispatch('account/removeAdditionalChargeItem', product.value);
  if (product.label.toLowerCase().includes('preferred')) {
    selectedPreferredTypes.value -= 1;
  }
}
</script>

<template>
  <div class="account-order-builder">
    <SoonaLoading
      v-if="orderProcessing"
      :is-loading="orderProcessing"
      loading-text="loading"
    />
    <div class="account-order-builder__content__body">
      <section
        class="account-order-builder__content__padding crew-booking__content__step-section"
      >
        <div class="account-order-builder__content__step-section__step">
          <h3 class="account-order-builder__content__step-section__heading">
            customer
          </h3>
          <ShowAccount :account-id="accountId" />
        </div>
        <div class="account-order-builder__content__step-section__step">
          <h3
            class="account-order-builder__content__step-section__heading"
            data-cypress="select-order-location"
          >
            shoot options
          </h3>
          <ShootOptionsSelector
            v-model:model-value="selectedLocation"
            class="account-order-builder__content__step-section__soona-select"
            :editable="true"
            @select-location="selectLocation"
          />
        </div>
        <div class="account-order-builder__content__step-section__step">
          <h3
            class="account-order-builder__content__step-section__heading"
            data-cypress="select-order-products"
          >
            account credits
          </h3>
          <SoonaSelect
            v-model:model-values="selectedCreditTypes"
            class="account-order-builder__content__step-section__soona-select"
            :options="accountCreditProducts"
            placeholder="select"
            :disabled="!selectedLocation || accountCreditProducts.length <= 0"
            :searchable="false"
            :multi="true"
            data-cypress="select-account-order-product"
            @option:selecting="onSelectProduct"
            @option:deselecting="onDeselectProduct"
          >
            <template #label>credit type</template>
          </SoonaSelect>
        </div>
        <div class="account-order-builder__content__step-section__step">
          <h3 class="account-order-builder__content__step-section__heading">
            payment & communication
          </h3>
          <PaymentSelector
            :disabled="
              !selectedCreditTypes.length ||
              !selectedLocation ||
              accountCreditProducts.length <= 0 ||
              loadingLocation
            "
            :payment-options="paymentOptions"
            @select-payment="selectPayment"
            @select-comp-reason="selectCompReason"
          />
          <span class="payment-options" />
        </div>
        <div
          v-if="showFollowupInfo"
          class="account-order-builder__content__step-section__step"
        >
          <SoonaTextfield
            v-model:model-value="email"
            label="email"
            type="text"
            required
          />
          <SoonaTextfield
            v-model:model-value="emailSubject"
            label="email subject"
            type="text"
            required
          />
          <SoonaTextfield
            v-model:model-value="emailNotes"
            :required="true"
            label="notes*"
            placeholder="detail explaining charge for client"
            element="textarea"
            type="text"
            data-cypress="account-order-notes-input"
          />
        </div>

        <div class="account-order-builder__content__step-section__step">
          <SoonaButton
            class="create-order-btn"
            :on-click="handleConfirm"
            :copy="confirmBtnText"
            :disabled="btnDisabled"
            data-cypress="account-order-create-button"
          />
        </div>
      </section>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.account-order-builder {
  flex: 1 0 auto;

  &__content {
    &__body {
      margin: 1rem auto 0 auto;
      max-width: 34.875rem;
    }
    &__padding {
      padding: 0 1rem;
    }
    &__step-section {
      padding: 0 1rem;
      border-bottom: 0.0625rem solid variables.$gray-30;
      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }
      &__heading {
        @include variables_fonts.u-title--heavy;
        color: variables.$black-default;
        margin-bottom: 1.5rem;
      }
      &__label {
        @include variables_fonts.u-label--heavy;
        align-items: center;
        color: variables.$black-default;
        display: flex;
        margin-bottom: -0.75rem;
      }
      &__step {
        margin-bottom: 1.5rem;
        border-bottom: 0.0625rem solid #d3d6d9;
      }
      &__soona-select {
        margin-bottom: 1.5rem;
      }
    }
  }
  .payment-options {
    height: 0.375rem;
    display: block;
  }
  .create-order-btn {
    width: 100%;
    margin-bottom: 1.25rem;
  }
}
</style>
