<script setup>
import { computed } from 'vue';
import Download from '@/components/user/anytime/Download.vue';
import SoonaFlag from '@/components/ui_library/SoonaFlag.vue';
import AddOnOrderLineItemPendingEdit from '@/components/user/anytime/crew/AddOnOrderLineItemPendingEdit.vue';
import AddOnOrderLineItemRejectedEdit from '@/components/user/anytime/crew/AddOnOrderLineItemRejectedEdit.vue';
import {
  BlackDefault,
  FriendlyRed50,
  Tangerine30,
  Gray20,
  PeriwinkBlue30,
  WhiteDefault,
  AvoToast60,
  AvoToast20,
} from 'src/variables.module.scss';

const props = defineProps({
  order: Object,
  reservationFile: Object,
});

const order = computed(() => props.order);
const orderIsPaid = computed(() => props.order.status == 'paid');

const reservationFile = computed(() => props.reservationFile);

const reservationLineItems = computed(() => {
  return order.value.reservation_line_items
    .filter(rli => rli.reservation_file)
    .filter(
      rli =>
        rli.reservation_file.id === reservationFile.value.id &&
        rli.product.product_type !== 'add_on'
    );
});

const pendingEdits = computed(() => {
  return (
    reservationFile.value?.derivatives?.filter(
      derivative => derivative.edit_status === 'pending_edit'
    ) ?? []
  );
});

const rejectedEdits = computed(() => {
  return (
    reservationFile.value?.derivatives?.filter(
      derivative => derivative.edit_status === 'rejected_edit'
    ) ?? []
  );
});

const hasPendingEdit = computed(() => {
  return pendingEdits.value?.length > 0;
});

const hasRejectedEdit = computed(() => {
  return rejectedEdits.value?.length > 0;
});

const hasEdit = computed(() => {
  return reservationFile?.value?.derivatives?.some(
    derivative => derivative.edit_status === 'edit'
  );
});

const previewUrl = computed(() => {
  if (pendingEdits.value?.length > 0) {
    return pendingEdits.value[0].preview_url;
  } else {
    return reservationFile.value?.preview_url;
  }
});

const requiresManualEdit = computed(() => {
  const noPendingEdit = !hasPendingEdit.value;

  return (
    !hasEdit.value &&
    (reservationFile.value?.requires_manual_edit || noPendingEdit)
  );
});
</script>
<template>
  <div class="reservation-file columns">
    <div class="column is-one-quarter">
      <router-link
        :to="{
          name: 'crew-order-reservation-file-review',
          params: { orderId: order.id, reservationFileId: reservationFile.id },
        }"
      >
        <img
          v-if="previewUrl"
          :src="previewUrl"
          alt=""
          class="reservation-file-thumbnail"
        />
      </router-link>
      <Download
        v-if="reservationFile.raw_url"
        :reservation-file="reservationFile"
        variation="secondary-gray"
      />
    </div>

    <div class="reservation-file-add-ons column is-one-quarter">
      <div class="reservation-file-add-ons__editor-flags">
        <SoonaFlag
          v-if="hasPendingEdit"
          :background-color="Tangerine30"
          :text-color="BlackDefault"
          title="auto"
        />
        <SoonaFlag
          v-else-if="hasRejectedEdit"
          :background-color="Gray20"
          :text-color="BlackDefault"
          title="auto"
        />
        <SoonaFlag
          v-if="requiresManualEdit"
          :background-color="FriendlyRed50"
          :text-color="WhiteDefault"
          title="needs edit"
        />
        <SoonaFlag
          v-if="hasEdit"
          :background-color="AvoToast60"
          :text-color="AvoToast20"
          title="edit"
        />
      </div>
      <p class="reservation-file-name u-body--heavy">
        {{ reservationFile.title }}
      </p>
      <SoonaFlag
        v-if="reservationFile.credited_by_preferred_credits"
        :background-color="PeriwinkBlue30"
        :text-color="BlackDefault"
        title="preferred"
      />
      <p v-if="reservationLineItems.length > 0" class="section-title">
        ADD-ONS:
      </p>
      <ul class="add-on-items">
        <li
          v-for="rli in reservationLineItems"
          :key="rli.id"
          class="add-on-item is-size-7"
        >
          {{ rli.product.name }} ({{
            Number(rli.amount_collected).toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })
          }})
        </li>
      </ul>
      <div v-if="orderIsPaid">
        <AddOnOrderLineItemPendingEdit
          v-for="pendingEdit in pendingEdits"
          :key="pendingEdit.id"
          :reservation-file="pendingEdit"
        />
        <AddOnOrderLineItemRejectedEdit
          v-for="rejectedEdit in rejectedEdits"
          :key="rejectedEdit.id"
          :reservation-file="rejectedEdit"
        />
      </div>
    </div>
    <div class="reservation-file-notes column is-one-half">
      <p v-if="reservationFile.note" class="section-title">NOTES:</p>
      <p class="note-text">
        {{ reservationFile.note }}
      </p>
    </div>
  </div>
</template>
<style lang="scss">
.reservation-file {
  padding: 1.5rem;
  @media only screen and (min-width: 768px) {
    display: flex;
  }

  .reservation-file-thumbnail,
  .reservation-file-add-ons,
  .reservation-file-notes {
    @media only screen and (min-width: 768px) {
      margin-right: 1.5rem;
    }
  }

  .reservation-file-add-ons {
    &__editor-flags {
      display: flex;
      gap: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }

  .reservation-file-name {
    margin-bottom: 1.5rem;
  }

  .section-title {
    font-weight: 800;
    letter-spacing: 0.1rem;
    margin-top: 0.5rem;
  }

  .add-on-items {
    line-height: 1.1;
    margin-bottom: 0.5rem;

    .add-on-item {
      padding: 0;
      display: block;
    }
  }

  .note-text {
    font-weight: 800;
    font-style: italic;
    white-space: pre-wrap;
  }
}
</style>
