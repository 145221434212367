<script setup>
import { computed, ref, toRefs, watch } from 'vue';

import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';

import { useSocialMedias, useSocialMediaSizes } from '@/queries/useSocialMedia';
import { useSoonaToast } from '@/components/ui_library/soona_toast/useSoonaToast';
import { useSaveResizedDigitalAsset } from '@/queries/digital_assets/useSaveResizedDigitalAsset';
import { useCreateGalleryFile } from '@/queries/media/useCreateGalleryFile';
import { useIntersectionObserver } from '@vueuse/core';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useRoute } from 'vue-router';

import CustomSizes from '@/components/user/anytime/gallery/media-editor/media-multiplier/resizer/CustomSizes.vue';
import SocialMedias from '@/components/user/anytime/gallery/media-editor/media-multiplier/SocialMedias.vue';
import SocialMediaSizes from '@/components/user/anytime/gallery/media-editor/media-multiplier/resizer/SocialMediaSizes.vue';
import ResizerListItem from '@/components/user/anytime/gallery/media-editor/media-multiplier/ResizerListItem.vue';

import * as Sentry from '@sentry/vue';
import {
  COMMON,
  CUSTOM,
  PLATFORM,
} from '@/components/user/anytime/gallery/media-editor/media-multiplier/resizer/filter_symbols';

const props = defineProps({
  file: {
    type: Object,
    default: undefined,
  },
  hasFreemiumAccess: {
    type: Boolean,
    default: true,
  },
  isMobileView: {
    type: Boolean,
    default: false,
  },
  selectedFilter: {
    type: Object,
    default: () => {},
  },
  selectionData: {
    type: Object,
    required: true,
  },
});

const emits = defineEmits([
  'update-selector',
  'show-paywall-dialog',
  'close-editor',
  'close-panel',
]);

const { file, hasFreemiumAccess, isMobileView, selectedFilter, selectionData } =
  toRefs(props);

const route = useRoute();
const { linkClicked } = useBaseEvents();
const { addToast } = useSoonaToast();

const activeView = ref('');
const selectedSocialMedia = ref('');
const selectedSize = ref({ name: '', width: 0, height: 0 });
const lockedAspectRatio = ref(true);

const accountId = computed(() => file.value?.account_id);
const assetId = computed(() => file.value?.id);

const isPhoto = computed(() => file.value?.media_type === 'photo');

const fileWidth = computed(() => file.value?.width || file.value?.media?.width);
const fileHeight = computed(
  () => file.value?.height || file.value?.media?.height
);

const fileSizes = computed(() => ({
  width: fileWidth.value,
  height: fileHeight.value,
}));

const fileSubjectType = computed(() => {
  if (!file.value) return '';
  switch (file.value?.subject_type) {
    case 'reservation_file':
      return 'ReservationFile';
    default:
      return 'DigitalAsset';
  }
});

const {
  mutate: saveEditToGallery,
  isPending: createLoading,
  error: createError,
} = useCreateGalleryFile();

const {
  mutate: saveResizedDigitalAsset,
  isPending: saveResizedDigitalAssetLoading,
  error: saveResizedDigitalAssetError,
} = useSaveResizedDigitalAsset(accountId, assetId);

const { data: socialMedias, isLoading: socialMediasLoading } =
  useSocialMedias();
const { data: socialMediaSizes, isLoading: socialMediaSizesLoading } =
  useSocialMediaSizes();

const selectedSocialMediaSizes = computed(() => {
  if (selectedSocialMedia.value === null) return [];

  return socialMediaSizes.value.filter(
    item => item.social_media === selectedSocialMedia.value.toLowerCase()
  );
});

// mobile filters
const filterCustomSize = computed(
  () => selectedFilter.value?.symbol === CUSTOM
);
const filterPlatform = computed(
  () => selectedFilter.value?.symbol === PLATFORM
);
const filterCommonSizes = computed(
  () => selectedFilter.value?.symbol === COMMON
);

const resizerRef = ref();
const selectSocialMedia = ({ name }) => {
  activeView.value = 'social-media-sizes';
  selectedSocialMedia.value = name;
  resizerRef.value?.scrollIntoView({ behavior: 'smooth' });
};

const coordinatesData = () => ({
  coordinates: selectionData.value,
});
const targetSizeData = () => {
  if (selectedSocialMedia.value === null || !selectedSize.value.name) return {};

  return {
    target_size: {
      social_media:
        selectedSocialMedia.value.toLowerCase() || selectedSize.value.name,
      width: selectedSize.value.width,
      height: selectedSize.value.height,
    },
  };
};

const selectCustomSizes = () => {
  linkClicked({
    context: route.meta.context,
    subContext: 'media editor resize',
    linkLabel: 'custom / common sizes',
    linkHref: null,
  });

  activeView.value = 'custom-sizes';
};

const resizeSelection = ({
  name,
  width = 0,
  height = 0,
  ratio = true,
  custom = false,
}) => {
  if (!width || !height) ratio = false;

  lockedAspectRatio.value = ratio;

  selectedSize.value = { name, width, height };

  linkClicked({
    context: route.meta.context,
    subContext: 'media editor resize',
    linkLabel: name,
    linkHref: null,
  });
  emits('update-selector', {
    width,
    height,
    custom,
    ratio: lockedAspectRatio.value,
  });
};

// reset
const customSizesRef = ref();
const resetSelector = () => {
  if (activeView.value !== 'custom-sizes') activeView.value = '';

  selectedSocialMedia.value = '';
  selectedSize.value = {
    name: '',
    width: fileWidth.value,
    height: fileHeight.value,
  };

  lockedAspectRatio.value = true;
  customSizesRef.value?.resetSelectedCommonSize();

  emits('update-selector', {
    ...fileSizes.value,
    ratio: true,
  });
};

// save
const handleSuccess = (e = null) => {
  addToast(`we're generating your image...`, {
    variation: 'success',
  });
  resetSelector();
  emits('close-editor', e);
};

const handleError = errorRef => {
  Sentry.captureException(new Error('failed to resize image'), {
    extra: errorRef.value?.response,
  });
  addToast('error saving edit', {
    variation: 'error',
  });
};

const saveAsReservationFile = () => {
  saveEditToGallery(
    {
      file_id: file.value?.id,
      mode: 'resize',
      mode_inputs: {
        ...coordinatesData(),
        ...targetSizeData(),
      },
    },
    {
      onSuccess: handleSuccess,
      onError: () => handleError(createError),
    }
  );
};

const saveAsDigitalAsset = () => {
  saveResizedDigitalAsset(
    {
      mode_inputs: {
        ...coordinatesData(),
        ...targetSizeData(),
      },
    },
    {
      onSuccess: e => handleSuccess(e),
      onError: () => handleError(saveResizedDigitalAssetError),
    }
  );
};

const handleSaveToGallery = async () => {
  if (fileSubjectType.value === 'ReservationFile') {
    saveAsReservationFile();
  } else {
    saveAsDigitalAsset();
  }
};

defineExpose({ handleSaveToGallery, handleResetImage: resetSelector });

const resetImageEnabled = computed(
  () =>
    selectedSize.value.name !== '' ||
    selectionData.value.width !== fileWidth.value ||
    selectionData.value.height !== fileHeight.value ||
    !lockedAspectRatio.value
);

watch(
  assetId,
  () => {
    if (!assetId.value) return;
    if (!isPhoto.value) {
      emits('close-panel');
    } else {
      resetSelector();
    }
  },
  {
    immediate: true,
  }
);

const isLoading = computed(
  () => createLoading.value || saveResizedDigitalAssetLoading.value
);
const toggleAspectRatio = () => {
  return resizeSelection({
    name: selectedSize.value.name,
    ...selectionData.value,
    ratio: !lockedAspectRatio.value,
  });
};

const resetRef = ref();
useIntersectionObserver(
  resetRef,
  ([e]) => {
    e.target.classList.toggle(
      'image-resizer__footer--pinned',
      e.intersectionRatio < 1
    );
  },
  {
    threshold: 1,
  }
);
</script>

<template>
  <SoonaLoading v-if="isLoading" is-loading />
  <div
    v-if="!isMobileView || !!selectedFilter"
    ref="resizerRef"
    class="image-resizer"
    :class="[`image-resizer--${isMobileView ? 'mobile' : 'desktop'}`]"
  >
    <TransitionGroup name="resizer-nav">
      <ResizerListItem
        v-if="activeView === '' && !isMobileView && !selectedFilter"
        key="custom-sizes-menu-item"
        icon="resize"
        @click="selectCustomSizes"
      >
        custom / common sizes
      </ResizerListItem>
      <CustomSizes
        v-if="
          activeView === 'custom-sizes' || filterCustomSize || filterCommonSizes
        "
        ref="customSizesRef"
        key="custom-sizes-panel"
        :is-mobile-view="isMobileView"
        :locked-aspect-ratio="lockedAspectRatio"
        :file-sizes="fileSizes"
        :mobile-filter="selectedFilter"
        :selected-sizes="selectedSize"
        :selection-size="selectionData"
        @close-panel="activeView = ''"
        @resize-area="resizeSelection"
        @toggle-aspect-ratio="toggleAspectRatio"
      />
      <SocialMedias
        v-else-if="activeView === '' || (filterPlatform && !activeView)"
        key="social-medias-menu-item"
        :has-freemium-access="hasFreemiumAccess"
        :is-loading="socialMediasLoading"
        :is-mobile-view="isMobileView"
        :social-medias="socialMedias"
        :selected-social-media="selectedSocialMedia"
        @select="selectSocialMedia"
        @show-paywall-dialog="emits('show-paywall-dialog')"
      />
      <SocialMediaSizes
        v-else-if="
          activeView === 'social-media-sizes' || (filterPlatform && !activeView)
        "
        :key="`social-medias-panel-${selectedSocialMedia}`"
        :is-loading="socialMediaSizesLoading"
        :is-mobile-view="isMobileView"
        :social-media-name="selectedSocialMedia"
        :social-media-items="selectedSocialMediaSizes"
        :selected-size="selectedSize.name"
        @close-panel="activeView = ''"
        @resize-selection="resizeSelection"
      />
    </TransitionGroup>
  </div>
  <div
    v-if="
      !isMobileView ||
      (!!selectedFilter && !(filterPlatform && hasFreemiumAccess))
    "
    ref="resetRef"
    class="image-resizer__footer"
    :class="[`image-resizer__footer--${isMobileView ? 'mobile' : 'desktop'}`]"
  >
    <SoonaButton
      :disabled="!resetImageEnabled"
      variation="secondary-gray"
      class="image-resizer__footer--button"
      @on-click="resetSelector"
    >
      reset adjustments
    </SoonaButton>
  </div>
</template>

<style lang="scss" scoped>
@use 'src/variables';

.image-resizer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 1rem;
  margin-top: 0.25rem;
  overflow: hidden;

  &__footer {
    &--desktop {
      position: sticky;
      bottom: -0.0625rem;
      background-color: variables.$white-default;
      padding: 0.25rem 1rem 1rem 0.9375rem;
      margin: auto -1rem 0 -0.9375rem;
      transform: translateY(1rem);
      transition: box-shadow 0.1s ease-out;

      &--pinned {
        padding-bottom: 1.0625rem;
        box-shadow: 0 -0.5rem 0.625rem 0 rgba(0, 0, 0, 0.16);
      }
    }

    &--mobile {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }

    &--button {
      width: 100%;
    }
  }
}

.custom-size {
  &--desktop {
    position: absolute;
  }
}

.resizer-nav-enter-from,
.resizer-nav-leave-to {
  &.custom-size {
    transform: translateX(100%);
  }
  &.social-media-sizes {
    transform: translateX(100%);
  }
}

.resizer-nav-enter-active {
  transition:
    transform 0.2s ease-in-out,
    opacity 0.2s ease-in;
}
.resizer-nav-leave-active {
  transition:
    transform 0.2s ease-in-out,
    opacity 0.2s ease-out;
}

@media (prefers-reduced-motion: reduce) {
  .resizer-nav-enter-active,
  .resizer-nav-leave-active {
    transition: none;
  }
}
</style>
