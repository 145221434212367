<template>
  <div>
    <div class="shotlist-header has-text-centered">
      <h2 class="title has-text-centered">
        <b>let's build your dream shot list.</b>
      </h2>
      <p>
        guide the soona crew by building each shot! have your own process?
        please contact the crew!
      </p>
    </div>

    <div class="shot-list-edit__container">
      <!-- Custom description input field -->
      <SoonaTextfield
        v-model="description"
        class="shot-list-edit__textarea"
        element="textarea"
        label="description"
        :maxlength="500"
        placeholder="give us more detail on how the shot looks"
        rows="5"
        data-cypress="shot-list-description-textarea"
        @blur="() => handleInputChange('description', 'text', 'onBlur')"
      >
        <template #helper>optional</template>
      </SoonaTextfield>
      <div
        v-for="tagCategory in visibleTagCategories"
        :key="'tc' + tagCategory.id"
        class="shot-list-edit__tag-wrapper"
        :class="{
          'shot-list-edit__tag-wrapper--content':
            tagCategory.title === 'Content',
        }"
      >
        <div class="tag-header">
          <h3 class="shot-list-edit__tag-heading">
            {{ tagCategory.is_props ? 'soona props' : tagCategory.title }}
            <span
              v-if="tagCategory.skippable"
              class="shot-list-edit__tag-optional"
            >
              (optional)
            </span>
          </h3>
          <p
            v-if="tagCategory.description !== ''"
            class="tag-question is-lowercase u-small--regular"
            v-html="tagCategory.description"
          />
          <SoonaError
            v-if="errors.some(e => e.id == tagCategory.id)"
            :ref="el => errorElements.push(el)"
          >
            {{ errors.find(e => e.id == tagCategory.id).message }}
          </SoonaError>
          <!-- custom-tag modal -->
          <custom-tag-modal
            class="modal"
            :class="{ 'is-active': showCustomSubjectModal }"
            :tag-category="tagCategory"
            :stars="industries"
            :close-preview-modal="closePreviewModal"
            :submit-custom-tag="submitCustomSubjectTag"
          />
          <ReferenceImgModal
            v-if="tagCategory.is_reference_image && showRefModal"
            :tag-category="tagCategory"
            @close-dialog="closePreviewModal"
          />
          <AddPropsModal
            v-if="tagCategory.is_props && showAddPropsModal"
            :tag-category="tagCategory"
            @close-dialog="closePreviewModal"
          />
          <AddCustomPropsModal
            v-if="tagCategory.is_custom_props && showAddCustomPropsModal"
            :submit-custom-props-tag="submitCustomPropsTag"
            @close-dialog="closePreviewModal"
          />
        </div>

        <div class="tag-section">
          <!-----dropdown-->
          <div
            v-if="tagCategory.title === 'Shot Subject'"
            class="custom-tag-dropdown"
          >
            <div
              v-if="userTagsForCategory(tagCategory).length > 0"
              class="select soona-select is-fullwidth"
            >
              <select
                v-model="userChosenCustomTag"
                :disabled="false"
                data-cypress="shot-list-select-subject"
              >
                <option
                  v-for="customTag in userTagsForCategory(tagCategory)"
                  :key="customTag.id"
                  :value="customTag"
                  :disabled="disableSelectChoice(customTag)"
                >
                  {{ customTag.title }}
                </option>
              </select>
            </div>
          </div>

          <div v-if="tagCategory.is_custom_props" class="custom-tag-dropdown">
            <div
              v-if="customPropsTagsForCategory(tagCategory).length > 0"
              class="select soona-select is-fullwidth"
            >
              <select v-model="customPropChosenTag" :disabled="false">
                <option :value="{}">add existing custom props</option>
                <option
                  v-for="customTag in customPropsTagsForCategory(tagCategory)"
                  :key="customTag.id"
                  :value="customTag"
                  :disabled="disableSelectChoice(customTag)"
                >
                  {{ customTag.title }}
                </option>
              </select>
            </div>
          </div>

          <!-- loop through soona scene tags -->
          <div class="tag-section">
            <shot-tag
              v-for="tag in tagsForCategory(tagCategory)"
              :key="tag.id"
              :tag-to-display="tag"
              :is-selected="isTagSelected(tag.id)"
              :tag-category="tagCategory"
              :handle-click="clearErrorAndToggleTag"
            />
          </div>

          <span v-if="tagCategory.is_props">
            <!-- loop through custom user tags -->
            <span
              v-for="shotTag in currentShot.shot_tags.filter(
                st => st.tag.tag_category_id === tagCategory.id
              )"
              :key="'st' + shotTag.id"
            >
              <shot-tag
                :tag-to-display="shotTag.tag"
                :tag-category="tagCategory"
                :removable="true"
                :is-selected="true"
                :click-remove="
                  () => removeCustomTag(tagCategory.title, shotTag.tag)
                "
              />
            </span>
          </span>
          <span v-else>
            <!-- loop through custom user tags -->
            <span
              v-for="tag in userTagsForCategory(tagCategory)"
              :key="'t' + tag.id"
            >
              <shot-tag
                v-if="isTagSelected(tag.id)"
                :tag-to-display="tag"
                :tag-category="tagCategory"
                :removable="true"
                :is-selected="true"
                :click-remove="() => removeCustomTag(tagCategory.title, tag)"
              />
            </span>
          </span>
          <!-- add new custom tag -->
          <button
            v-if="
              tagCategory.customizable && tagCategory.title === 'Shot Subject'
            "
            key="t-1"
            title="create new"
            class="shot-tag-btn create"
            :class="{ tall: tagCategory.uploadable }"
            @click="openCreateForTagCategory(tagCategory.id, tagCategory.title)"
          >
            <span class="plus-sign">+</span>
            <p class="shot-tag-title is-lowercase u-title--heavy">
              add new subject
            </p>
          </button>
          <button
            v-else-if="tagCategory.customizable && tagCategory.uploadable"
            title="create new"
            class="reference-img-btn"
            @click="openRefImgModal(tagCategory.id, tagCategory.title)"
          >
            +
          </button>
          <span
            v-for="tag in selectedReferenceImageTags(tagCategory)"
            :key="'t' + tag.id"
            class="shot-tag-btn user-tag-container shot-tag-image"
            :title="tag.title"
            :class="{ 'btn-is-selected': isTagSelected(tag.id) }"
          >
            <img
              :src="tag.image_url"
              alt="uploadable image"
              class="uploadable-img"
            />
            <button
              class="remove-img-btn is-large"
              @click="removeReferenceImage(tag)"
            >
              <SoonaIcon name="xmark" />
            </button>
          </span>
          <template v-if="tagCategory.is_props">
            <!-- add soona props -->
            <span
              v-for="tag in selectedSoonaPropTags(tagCategory)"
              :key="tag.id"
            >
              <shot-tag
                :tag-to-display="tag"
                :tag-category="tagCategory"
                :removable="true"
                :is-selected="true"
                :click-remove="() => removePropTag(tag)"
              />
            </span>
          </template>
          <button
            v-if="tagCategory.customizable && tagCategory.is_props"
            title="create new"
            class="shot-tag-btn create prop-shot-tag"
            :class="{ tall: tagCategory.uploadable }"
            @click="openAddPropsModal(tagCategory.id, tagCategory.title)"
          >
            <span class="plus-sign">+</span>
            <p class="shot-tag-title is-lowercase u-title--heavy">
              add soona prop
            </p>
          </button>
          <button
            v-if="tagCategory.is_custom_props"
            title="add new custom prop"
            class="shot-tag-btn create"
            :class="{ tall: tagCategory.uploadable }"
            @click="openAddCustomPropsModal(tagCategory.id, tagCategory.title)"
          >
            <span class="plus-sign">+</span>
            <p class="shot-tag-title is-lowercase u-title--heavy">
              add custom prop
            </p>
          </button>
        </div>
      </div>
      <SoonaAlert v-if="hasCustomPropsSelected" class="custom-prop-alert">
        be sure to ship your custom props to soona! if you need fresh
        ingredients or flowers: email us at hey@soona.co to add the grocery
        shopping pro service.
      </SoonaAlert>
      <div class="edit-view-btns">
        <SoonaButton data-cypress="shot-list-save-button" @click="attemptSave">
          {{ buttonText }}
        </SoonaButton>
        <SoonaButton variation="tertiary" @click="cancelEdit">
          cancel
        </SoonaButton>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, toValue } from 'vue';
import { mapMutations, mapState, mapActions, mapGetters, useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { useBaseEvents } from 'src/composables/segment/useBaseEvents';
import { useShotListEvents } from '@/composables/segment/useShotListEvents';
import * as types from 'src/store/mutation-types';
import ShotTag from 'src/components/ShotTag.vue';
import CustomTagModal from './ShotlistCustomTagModal.vue';
import ReferenceImgModal from './shotlist/ReferenceImgModal.vue';
import AddPropsModal from './shotlist/AddPropsModal.vue';
import AddCustomPropsModal from './shotlist/AddCustomPropsModal.vue';
import SoonaAlert from 'src/components/ui_library/SoonaAlert.vue';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaError from 'src/components/ui_library/SoonaError.vue';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaTextfield from 'src/components/ui_library/SoonaTextfield.vue';
import { useIndustries } from 'src/queries/useIndustries';

export default {
  name: 'ShotlistEdit',
  components: {
    ShotTag,
    CustomTagModal,
    ReferenceImgModal,
    AddPropsModal,
    AddCustomPropsModal,
    SoonaAlert,
    SoonaButton,
    SoonaError,
    SoonaIcon,
    SoonaTextfield,
  },
  props: {
    reservationId: Number,
    accountId: Number,
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const { buttonClicked, inputChanged } = useBaseEvents();
    const { sceneItemSelected, sceneItemSelectionOpened } = useShotListEvents();
    const { data: industries } = useIndustries();
    const subContext = 'shot builder';

    const errorElements = ref([]);

    const handleButtonClick = (buttonLabel, buttonAction) => {
      buttonClicked({
        context: route.meta.context,
        subContext: subContext,
        buttonLabel,
        buttonAction,
      });
    };

    const handleInputChange = (inputLabel, inputType, inputValue) => {
      inputChanged({
        context: route.meta.context,
        subContext: subContext,
        inputLabel,
        inputType,
        inputValue,
      });
    };

    const handleSceneItemSelection = (
      group,
      sceneItemLabel,
      sceneItemValue
    ) => {
      sceneItemSelected({
        context: route.meta.context,
        subContext: subContext,
        group,
        sceneItemLabel,
        sceneItemValue,
      });
    };

    const handleSceneItemSelectionOpened = (
      group,
      sceneItemLabel,
      sceneItemAction
    ) => {
      sceneItemSelectionOpened({
        context: route.meta.context,
        subContext: subContext,
        group,
        sceneItemLabel,
        sceneItemAction,
      });
    };

    const removeCustomTag = (group, tag) => {
      store.dispatch('shotList/toggleTag', tag);
      handleSceneItemSelection(group, tag.title, false);
    };

    return {
      handleButtonClick,
      handleInputChange,
      handleSceneItemSelection,
      handleSceneItemSelectionOpened,
      industries,
      removeCustomTag,
      errorElements,
    };
  },
  data() {
    return {
      createTagTagCategoryId: undefined,
      previewTagTagCategoryId: undefined,
      tagCategoryIdforShopify: undefined,
      customTagTitle: '',
      canCreateCustomTag: true,
      errors: [],
      customTagError: undefined,
      userChosenCustomTag: {},
      showCustomSubjectModal: false,
      showRefModal: false,
      showAddPropsModal: false,
      showAddCustomPropsModal: false,
      customPropChosenTag: {},
      showTooltip: false,
    };
  },
  computed: {
    ...mapGetters('shotList', [
      'visibleTagCategories',
      'isTagSelected',
      'isCreateMode',
      'savedSelectedReferenceImgIds',
      'savedSelectedSoonaPropTagsIds',
    ]),
    ...mapState({
      currentSelectedTags: state => state.shotList.currentShot.shot_tags,
      shotList: state => state.shotList.currentShotList,
      currentShot: state => state.shotList.currentShot,
      currentUser: state => state.currentUser,
      currentReservation: state => state.reservation.currentReservation,
    }),
    description: {
      get() {
        return this.currentShot.description;
      },
      set(value) {
        this.SET_SHOT_DESCRIPTION(value);
      },
    },
    invalidCategories: function () {
      return this.visibleTagCategories.filter(
        tc =>
          !tc.skippable &&
          !this.currentShot.shot_tags.some(
            st => st.tag.tag_category_id == tc.id
          )
      );
    },
    tagsForCategory: () => tagCategory => {
      return tagCategory && tagCategory.tags
        ? tagCategory.tags.filter(tag => tag.display_rule === 'true')
        : [];
    },
    isPhotoBooking() {
      return this.currentReservation.tags.some(
        t => t.title.toLowerCase() === 'photo'
      );
    },
    isVideoBooking() {
      return this.currentReservation.tags.some(
        t => t.title.toLowerCase() === 'video'
      );
    },
    userTagsForCategory: () => tagCategory => {
      return tagCategory &&
        tagCategory.account_tags &&
        tagCategory.title != 'Reference Image'
        ? tagCategory.account_tags.filter(tag => tag.display_rule === 'true')
        : [];
    },
    customPropsTagsForCategory: () => tagCategory => {
      return tagCategory &&
        tagCategory.account_tags &&
        tagCategory.is_custom_props
        ? tagCategory.account_tags.filter(tag => tag.display_rule === 'true')
        : [];
    },
    shotIndex() {
      let shot = this.shotList.find(s => s.id == this.currentShot.id);
      return this.shotList.indexOf(shot) + 1;
    },
    hasCustomPropsSelected() {
      let customPropsTC = this.visibleTagCategories.find(
        vtc => vtc.is_custom_props
      );
      let customPropsAccountTagsIds = customPropsTC.account_tags.map(
        tag => tag.id
      );
      return this.currentSelectedTags.some(st =>
        customPropsAccountTagsIds.includes(st.tag_id)
      );
    },
    buttonText() {
      return this.isCreateMode ? 'add to list' : 'update shot';
    },
  },
  watch: {
    userChosenCustomTag(newValue) {
      if (Object.keys(newValue).length > 0) {
        this.toggleTag(newValue);
        this.disableSelectChoice(newValue);
        this.userChosenCustomTag = {};
        this.handleInputChange('shot subject', 'dropdown', newValue.title);
      }
    },
    customPropChosenTag(newValue) {
      if (Object.keys(newValue).length > 0) {
        this.toggleTag(newValue);
        this.disableSelectChoice(newValue);
        this.customPropChosenTag = {};
      }
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    ...mapMutations('shotList', [
      types.SET_SHOT_DESCRIPTION,
      types.SET_SHOT_LIST_MODE_VIEW,
      types.SET_SHOT_CREATOR,
    ]),
    ...mapActions('shotList', [
      'saveShot',
      'toggleTag',
      'createTag',
      'loadShotList',
      'removeReferenceImgTag',
      'removePropTCTag',
    ]),
    openCreateForTagCategory(id, group) {
      this.createTagTagCategoryId = id;
      this.showCustomSubjectModal = true;
      this.handleSceneItemSelectionOpened(
        group,
        'add new subject',
        'opens modal to add subject'
      );
    },
    openRefImgModal(id, group) {
      this.createTagTagCategoryId = id;
      this.showRefModal = true;
      this.handleSceneItemSelectionOpened(
        group,
        '+',
        'opens modal to upload reference image'
      );
    },
    openAddPropsModal(id, group) {
      this.createTagTagCategoryId = id;
      this.showAddPropsModal = true;
      this.handleSceneItemSelectionOpened(
        group,
        'add soona prop',
        'opens modal to add prop'
      );
    },
    openAddCustomPropsModal(id, group) {
      this.createTagTagCategoryId = id;
      this.showAddCustomPropsModal = true;
      this.handleSceneItemSelectionOpened(
        group,
        'add custom prop',
        'opens modal to add prop'
      );
    },
    async submitCustomSubjectTag(customTag) {
      if (this.canCreateCustomTag && customTag.title != '') {
        this.canCreateCustomTag = false;
        try {
          await this.createTag({
            tagCategoryId: this.createTagTagCategoryId,
            title: customTag.title,
            description: customTag.chosenStar.slug,
          });
          this.canCreateCustomTag = true;
          this.createTagTagCategoryId = undefined;
        } catch (error) {
          this.customTagError = 'oops! we had a problem adding your tag.';
        }
      }
    },
    async submitCustomPropsTag(customTag) {
      if (this.canCreateCustomTag && customTag.title != '') {
        this.canCreateCustomTag = false;
        try {
          await this.createTag({
            tagCategoryId: this.createTagTagCategoryId,
            title: customTag.title,
            description: customTag.title,
          });
          this.canCreateCustomTag = true;
          this.createTagTagCategoryId = undefined;
        } catch (error) {
          this.customTagError =
            'oops! we had a problem adding your custom prop tag.';
        }
      }
    },
    async createProductTags(result) {
      if (this.canCreateCustomTag) {
        this.canCreateCustomTag = false;
        for (const select of result.selection) {
          if (select.hasOnlyDefaultVariant) {
            await this.createTag({
              tagCategoryId: this.tagCategoryIdforShopify,
              title: select.title,
              description: 'Shopify',
            });
          } else {
            for (const variant of select.variants) {
              await this.createTag({
                tagCategoryId: this.tagCategoryIdforShopify,
                title: variant.displayName,
                description: 'Shopify',
              });
            }
          }
        }
        this.canCreateCustomTag = true;
        this.customTagTitle = '';
        this.createTagTagCategoryId = undefined;
      }
    },
    attemptSave() {
      this.errors = [];
      if (this.invalidCategories.length == 0) {
        this.SET_SHOT_CREATOR(this.currentUser.userID);
        this.saveShot();
        this.handleButtonClick(
          this.buttonText,
          'adds shot to list & closes shot builder'
        );
      } else {
        this.invalidCategories.forEach(tc => {
          this.errors.push({
            id: tc.id,
            message: 'oops! we need you to add one of these',
          });
        });
        this.$nextTick(function () {
          toValue(this.errorElements)[0]?.$el?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        });
      }
    },
    clearErrorAndToggleTag(tagCategoryId, tag, group) {
      this.errors = this.errors.filter(e => e.id != tagCategoryId);
      this.toggleTag(tag);

      const isSelected = !!this.currentSelectedTags.find(
        st => st.tag_id === tag.id
      );
      this.handleSceneItemSelection(group, tag.title, isSelected);
    },
    closePreviewModal() {
      this.createTagTagCategoryId = undefined;
      this.canCreateCustomTag = true;
      this.customTagError = undefined;
      this.showRefModal = false;
      this.showCustomSubjectModal = false;
      this.showAddPropsModal = false;
      this.showAddCustomPropsModal = false;
    },
    disableSelectChoice(customTag) {
      if (this.currentSelectedTags) {
        let answer = this.currentSelectedTags.filter(
          tag => tag.tag.id === customTag.id
        );
        return answer.length > 0;
      }
    },
    cancelEdit() {
      this.loadShotList({
        reservationId: this.reservationId,
        accountId: this.accountId,
      });
      this.handleButtonClick(
        'cancel',
        'closes shot builder without saving shot'
      );
      this.SET_SHOT_LIST_MODE_VIEW();
    },
    selectedReferenceImageTags(tagCategory) {
      return tagCategory &&
        tagCategory.account_tags &&
        tagCategory.is_reference_image
        ? tagCategory.account_tags
            .filter(
              tag =>
                tag.display_rule === 'true' &&
                this.savedSelectedReferenceImgIds(this.currentShot).includes(
                  tag.id
                )
            )
            .sort((a, b) => b.id - a.id)
        : [];
    },
    async removeReferenceImage(tag) {
      try {
        await this.removeReferenceImgTag(tag);
      } catch (error) {
        this.customTagError =
          'oops! we had a problem deleting your reference images.';
      }
    },
    selectedSoonaPropTags(tagCategory) {
      if (!tagCategory?.is_props) return [];

      return tagCategory.tags
        .filter(tag =>
          this.savedSelectedSoonaPropTagsIds(this.currentShot).includes(tag.id)
        )
        .sort((a, b) => b.id - a.id);
    },
    async removePropTag(tag) {
      try {
        await this.removePropTCTag(tag);
      } catch (error) {
        this.customTagError =
          'oops! we had a problem deleting your soona prop.';
      }
    },
    toolTipOppositeContentCopy(tagTitle) {
      if (tagTitle === 'Video') return 'photo';
      return 'video';
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.shot-list-edit {
  &__container {
    box-shadow: variables.$elevation-2;
    margin-top: 1rem 0;
    padding: 2rem;
  }

  &__textarea {
    resize: vertical;
  }

  &__tag-wrapper {
    padding: 2rem 0;

    &--content {
      padding-top: 0;
    }
  }

  &__tag-heading {
    @include variables_fonts.u-label--heavy;

    padding-bottom: 0.5rem;
    text-transform: lowercase;
  }

  &__tag-optional {
    @include variables_fonts.u-label--regular;
  }
}

.shotlist-header {
  margin: 1em;
  width: 100%;
  display: inline-block;
}
.description-wrapper {
  display: block;
  margin: 12px;
}
.tag-header {
  margin: 1em;
  font-size: 12px;
  letter-spacing: 2px;
  position: relative;
}
.error {
  color: variables.$friendly-red-50;
  letter-spacing: normal !important;
}
.card-wrapper {
  position: relative;
}
.preview-card {
  background: variables.$white-default;
  max-width: 50vw;
  max-height: 50vh;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  position: relative;
  flex-wrap: wrap;
  overflow: scroll;
  padding: 1em;
}
.preview-section {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 0.5em;
}
.preview-tag-btn {
  height: fit-content;
  padding: 0.25em;
  font-weight: 800;
  background: variables.$white-default;
  border-radius: 7px !important;
  border: variables.$white-default solid 2px;
  margin: 0.5em;
  transition: border-color 0.4s linear;
  -moz-transition: border-color 0.4s linear;
  -o-transition: border-color 0.4s linear;
  -webkit-transition: border-color 0.4s linear;
}
.preview-img {
  max-height: 130px;
  padding: 0.5em;
}
.shot-tag-title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  &.previewable {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 25px;
    padding-top: 3px;
    background: variables.$white-default;
    border-radius: 0px 0px 5px 5px;
  }
}
.preview-close-btn {
  background: transparent !important;
  position: absolute;
  top: 0;
  right: 0;
  margin: 1em;
}
.custom-tag-dropdown {
  width: 98%;
  border-radius: 7px;
  box-shadow: 0px 3px 6px #00000029;
  margin: 0 8px 20px 6px;
  select {
    border: none;
    color: variables.$gray-50;
  }
}
.custom-tag-card {
  background: variables.$white-default;
  min-width: 30vw;
  max-width: 35vw;
  max-height: 50vh;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  position: relative;
  flex-wrap: wrap;
  overflow: scroll;
  padding: 1.5em;
}
.custom-tag-title {
  margin: 0em 0em 1em 0em;
  letter-spacing: 1px;
}
.choose-product-btn {
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 1px;
  font-weight: 800;
  padding: 0.5em 1.5em 0.5em 1.5em;
  margin: 0.5rem 1.5rem 0.5rem 1.5rem;
  height: 3em;
  border-radius: 3px !important;
}
.custom-tag-input {
  box-shadow: none !important;
  width: 100%;
  height: 3em;
  margin: 1em 0em;
}

.tag-question {
  letter-spacing: 0px;
}
.open-preview-btn {
  background: transparent;
  color: variables.$periwink-blue-60;
  text-decoration: underline !important;
  cursor: pointer;
  padding: 1px 0px 2px;
}
.open-preview-btn:hover {
  color: variables.$gray-90;
}
.tag-section {
  margin-left: 4px;
  justify-content: space-evenly;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.shot-tag-btn {
  min-width: 150px;
  max-width: 300px;
  height: 75px;
  border-radius: 7px;
  border: variables.$white-default solid 2px;
  font-weight: 800;
  background: variables.$white-default;
  margin-left: 4px;
  transition: all 0.5s linear;
  position: relative;
  &.create {
    display: flex;
    transition: all 0.25s linear;
    border: none;
    box-shadow: 0px 3px 6px #00000029;
    padding: 0 20px 0 0;
    align-items: center;
    .plus-sign {
      background-color: variables.$periwink-blue-40;
      color: variables.$white-default;
      font-size: 30px;
      display: inline-block;
      height: 75px;
      width: 75px;
      border-top-left-radius: 7px;
      border-bottom-left-radius: 7px;
      margin-right: 20px;
      padding-top: 18px;
    }
  }
}
.reference-img-btn {
  width: 150px;
  height: 100px;
  border-radius: 7px;
  font-size: 30px;
  font-weight: 800;
  position: relative;
  transition: all 0.25s linear;
  background-color: variables.$white-default;
  border: variables.$periwink-blue-60 solid 0.125rem;
  color: variables.$periwink-blue-60;
  margin: 3px;
}
.btn-is-selected {
  border: variables.$gray-90 solid 2px !important;
  border-radius: 7px !important;
}
.disabled-tag {
  opacity: 50%;
  color: variables.$gray-50;
}
.disabled-tag-tooltip {
  position: relative;
  left: 100px;
  bottom: 75px;
}
.user-tag-container {
  position: relative;
  text-align: center;
  overflow: hidden;
  padding: 5;
}
.shot-tag-image {
  height: 100px;
  margin: 3px;
}
.uploadable-img {
  height: 100px;
  width: 150px;
  object-fit: cover;
}
.edit-view-btns {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
}
.remove-img-btn {
  align-items: center;
  color: variables.$gray-60;
  display: flex;
  background: variables.$gray-10;
  border-radius: 7px;
  height: 20px;
  justify-content: center;
  width: 20px;
  padding: 0;
  position: absolute;
  top: -10px;
  right: -10px;
  margin: 1em;
  transition: 0.2s;

  &:hover {
    color: variables.$gray-90;
    background: variables.$gray-30;
  }
}
.custom-prop-alert {
  margin-left: 25%;
  width: 50%;
}
</style>
