import { createStore } from 'vuex';
import errors from 'src/store/modules/errors';
import flash from 'src/store/modules/flash';
import currentUser from 'src/store/modules/currentUser';
import reservations from 'src/store/modules/reservations';
import order from 'src/store/modules/order';
import locations from 'src/store/modules/locations';
import schedule from 'src/store/modules/schedule';
import anytime from 'src/store/modules/anytime';
import tagSurvey from 'src/store/modules/tagSurvey';
import shotList from 'src/store/modules/shotList';
import dashboard from 'src/store/modules/dashboard';
import reservation from 'src/store/modules/reservation';
import user from 'src/store/modules/user';
import crewAssignment from 'src/store/modules/crewAssignment';
import styleQuiz from 'src/store/modules/styleQuiz';
import account from 'src/store/modules/account';
import inventoryPackages from 'src/store/modules/inventoryPackages';
import payment from 'src/store/modules/payment';
import employeeType from 'src/store/modules/employeeType';
import events from 'src/store/modules/events';
import proService from 'src/store/modules/proService';
import pickAPack from 'src/store/modules/pickAPack';
import soonaGenie from 'src/store/modules/soonaGenie';
import industry from 'src/store/modules/industry';
import trendSetBuilder from 'src/store/modules/trendSetBuilder';
import flag from 'src/store/modules/flag';

export const store = createStore({
  modules: {
    reservations,
    errors,
    flash,
    currentUser,
    order,
    locations,
    schedule,
    anytime,
    tagSurvey,
    shotList,
    dashboard,
    reservation,
    user,
    account,
    crewAssignment,
    styleQuiz,
    inventoryPackages,
    payment,
    employeeType,
    events,
    proService,
    pickAPack,
    soonaGenie,
    industry,
    trendSetBuilder,
    flag,
  },
  strict: import.meta.env.DEV,
});
