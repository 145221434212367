import { ref, unref } from 'vue';
import { DirectUpload } from '@rails/activestorage';

/**
 *
 * @param {Ref<number>} maxConcurrent
 * @param {Ref<number>} uploadInterval
 * @param {Ref<number>} fileSizeLimitMb
 * @param {Ref<Function | null> | Function | null} [validateFile]
 * @param {Ref<Function | null> | Function | null} [onUploadError]
 * @param {Ref<Function | null> | Function | null} [onUploadComplete]
 * @param {Ref<number>} availableStorageBytes
 * @returns {{startUpload: Function, handleUpload: Function, cancelUploads: Function, handleDrop: Function, percentComplete: Ref<number>, activeUploads: Ref<[]>}}
 */
export function useUploader(
  maxConcurrent,
  uploadInterval,
  fileSizeLimitMb,
  validateFile,
  onUploadError,
  onUploadComplete,
  availableStorageBytes,
  preventLowResolutionImageUpload = false,
  preventUploadGreaterThanTwentyMegapixels = false
) {
  const percentComplete = ref(0);
  const activeUploads = ref([]);
  const uploader = ref(null);

  function uploadComplete(blob) {
    unref(onUploadComplete)(blob);
  }

  function fileSizeTooBig(file) {
    return fileSizeLimitMb.value && file.size / 1048576 > fileSizeLimitMb.value;
  }

  function fileResolutionTooLow(file) {
    return new Promise((resolve, reject) => {
      if (!preventLowResolutionImageUpload.value) return resolve(false);

      let reader = new FileReader();

      reader.onload = function (e) {
        let image = new Image();

        image.onload = function () {
          let height = this.naturalHeight;
          let width = this.naturalWidth;
          if (Math.max(height, width) < 1600) {
            return resolve(true);
          }
          resolve(false);
        };

        image.onerror = reject;
        image.src = e.target.result;
      };

      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  function fileGreaterThanTwentyMegapixels(file) {
    return new Promise((resolve, reject) => {
      if (!preventUploadGreaterThanTwentyMegapixels.value)
        return resolve(false);

      let reader = new FileReader();

      reader.onload = function (e) {
        let image = new Image();

        image.onload = function () {
          let height = this.naturalHeight;
          let width = this.naturalWidth;
          // images uploaded to shopify must be less than 20 megapixels (20 million pixels)
          if (height * width > 20000000) {
            return resolve(true);
          }
          resolve(false);
        };

        image.onerror = reject;
        image.src = e.target.result;
      };

      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  function createUploadTracker(file) {
    const tracker = {
      loaded: 0,
      total: file.size,
    };

    return tracker;
  }

  function startUpload(upload) {
    const previousError = upload.error;
    upload.error = undefined;
    upload.activated = true;
    upload.tracker.directUploadWillStoreFileWithXHR = request => {
      upload.tracker.xhrRequest = request;
      request.upload.addEventListener('progress', event => {
        upload.tracker.loaded = event.loaded;
        upload.tracker.total = event.total;
        activeUploads.value.splice(
          activeUploads.value.indexOf(upload),
          1,
          upload
        );
        if (upload.tracker.loaded == upload.tracker.total) {
          upload.completed = true;
        }
      });
    };
    const u = new DirectUpload(
      upload.file,
      '/rails/active_storage/direct_uploads',
      upload.tracker
    );
    u.create((error, blob) => {
      if (error) {
        upload.error = error;
        if (!previousError) {
          setTimeout(() => {
            startUpload(upload);
          }, 2000);
        }
      } else if (!error) {
        uploadComplete(blob);
      }
    });
  }

  function calculatePercentageComplete() {
    const totalLoaded = activeUploads.value
      .map(a => a.tracker.loaded)
      .reduce((a, b) => a + b, 0);
    const total = activeUploads.value
      .map(a => a.tracker.total)
      .reduce((a, b) => a + b, 0);
    percentComplete.value = (totalLoaded / total) * 100;
  }

  function startUploads() {
    uploader.value = setInterval(() => {
      if (
        activeUploads.value.filter(u => u.activated && !u.completed).length <
          maxConcurrent.value &&
        activeUploads.value.filter(u => !u.activated).length > 0
      ) {
        startUpload(activeUploads.value.find(u => !u.activated));
      }
      calculatePercentageComplete();
      if (percentComplete.value === 100) {
        activeUploads.value.splice(0, activeUploads.value.length);
        clearInterval(uploader.value);
      }
    }, uploadInterval.value);
  }

  async function uploadFile(file) {
    let tracker = createUploadTracker(file);
    const validationErrorMessage =
      unref(validateFile) && unref(validateFile)(file);

    if (validationErrorMessage) {
      activeUploads.value.push({
        file,
        tracker,
        activated: true,
        completed: false,
        error: validationErrorMessage,
        canRetry: false,
      });
      if (unref(onUploadError)) unref(onUploadError)(validationErrorMessage);
    } else if (fileSizeTooBig(file)) {
      activeUploads.value.push({
        file,
        tracker,
        activated: true,
        completed: false,
        error: `failed to upload file; must be less than ${fileSizeLimitMb.value}mb`,
        canRetry: false,
      });
      if (unref(onUploadError)) {
        unref(onUploadError)(
          `failed to upload file; must be less than ${fileSizeLimitMb.value}mb`
        );
      }
    } else if (await fileResolutionTooLow(file)) {
      activeUploads.value.push({
        file,
        tracker,
        activated: true,
        completed: false,
        error: `please upload an image that is at least 1600px on the longest side.`,
        canRetry: false,
      });
      if (unref(onUploadError)) {
        unref(onUploadError)(
          `please upload an image that is at least 1600px on the longest side.`
        );
      }
    } else if (await fileGreaterThanTwentyMegapixels(file)) {
      activeUploads.value.push({
        file,
        tracker,
        activated: true,
        completed: false,
        error: `please upload an image that is less than 20 megapixels.`,
        canRetry: false,
      });
      if (unref(onUploadError)) {
        unref(onUploadError)(
          `please upload an image that is less than 20 megapixels.`
        );
      }
    } else {
      activeUploads.value.push({
        file,
        tracker,
        activated: false,
        completed: false,
        error: null,
        canRetry: true,
      });
    }
  }

  function notEnoughAvailableStorage(files) {
    const totalBytes = files.reduce((sum, f) => sum + f.size, 0);

    if (totalBytes <= availableStorageBytes.value) return false;

    if (unref(onUploadError)) {
      unref(onUploadError)(
        `insufficient storage space available; ${
          Math.round(
            (availableStorageBytes.value / 1048576 + Number.EPSILON) * 100
          ) / 100
        }mb of asset storage remaining`
      );
    }
    return true;
  }

  function uploadFiles(files) {
    if (availableStorageBytes?.value && notEnoughAvailableStorage(files))
      return;
    files.forEach(file => uploadFile(file));
    startUploads();
  }

  function handleUpload(e) {
    //this.$track('Image Upload Started', { account: this.account });
    uploadFiles(Array.from(e.target.files));
    e.target.value = null;
  }

  function handleDrop(e) {
    uploadFiles([...e.dataTransfer.files]);
  }

  function cancelUploads() {
    activeUploads.value.forEach(upload => {
      upload.tracker.xhrRequest?.abort();
    });

    activeUploads.value.splice(0, activeUploads.value.length);
    clearInterval(uploader.value);
  }

  function removeUploadFile(upload) {
    activeUploads.value.splice(activeUploads.value.indexOf(upload), 1);
  }

  return {
    handleUpload,
    handleDrop,
    activeUploads,
    percentComplete,
    startUpload,
    cancelUploads,
    removeUploadFile,
  };
}
