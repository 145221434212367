<script setup>
import { computed, ref } from 'vue';
import SoonaAlert from 'src/components/ui_library/SoonaAlert.vue';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaSelect from '../../../ui_library/SoonaSelect.vue';
import SoonaSuccess from 'src/components/ui_library/SoonaSuccess.vue';
import SoonaTextfield from 'src/components/ui_library/SoonaTextfield.vue';
import SoonaUpload from 'src/components/uploader/SoonaUpload.vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useProServiceFormData } from '@/composables/useProServiceFormData';

const props = defineProps({
  products: {
    type: Object,
    required: true,
  },
  formData: {
    type: Object,
    required: true,
  },
  serviceType: {
    type: String,
    required: true,
  },
  isSubmitted: {
    type: Boolean,
    required: true,
  },
  context: {
    type: String,
    required: true,
  },
  paymentsModelDetailsFlag: {
    type: Boolean,
    required: true,
  },
});
const emits = defineEmits(['update-selection']);

const uploadPercentComplete = ref(0);

const formData = computed(() => props.formData);
const products = computed(() => props.products);
const serviceType = computed(() => props.serviceType);
const isSubmitted = computed(() => props.isSubmitted);

const { inputChanged } = useBaseEvents();

const {
  manicureOptions,
  pedicureOptions,
  pressOnColorOptions,
  shavedLegsOptions,
  wardrobeOptions,
  wardrobeSelectLabel,
  wardrobeSelectSubtext,
  hmuOptions,
} = useProServiceFormData({
  productsList: products.value,
  serviceType: serviceType.value,
});

const manicureRefImgLabel = computed(() =>
  formData.value.extrasImages.manicure_reference_image?.url
    ? 'replace image'
    : 'upload manicure reference image'
);

const pedicureRefImgLabel = computed(() =>
  formData.value.extrasImages.pedicure_reference_image?.url
    ? 'replace image'
    : 'upload pedicure reference image'
);

const hmuRefImgLabel = computed(() =>
  formData.value.extrasImages.hmu_reference_image?.url
    ? 'replace image'
    : 'upload hair & makeup reference image'
);

const manicureInfoCopy = computed(() => {
  const price =
    formData.value.manicureSelection == 'manicure custom'
      ? '$99 custom'
      : '$39 press on';
  return `one ${price} manicure will be added to your shoot and you will be charged a one time fee.`;
});

const pedicureInfoCopy = computed(
  () =>
    'one $99 pedicure custom will be added to your shoot and you will be charged a one time fee.'
);

function updatePercentageComplete(value) {
  uploadPercentComplete.value = value;
}

function removeRefImg(type) {
  formData.value.extrasImages[type] = {
    url: null,
    signed_id: null,
  };
}

function getImgLabelHTML(label) {
  return `<u>${label}</u>. 1 image max`;
}

function handleSelectChange(options, selection, label, selectionType) {
  emits('update-selection', selection, options, selectionType);

  const selectedOptionLabel = options.find(
    option => option.value == selection
  )?.label;
  inputChanged({
    context: props.context,
    subContext: 'pro service model extras modal',
    inputLabel: label,
    inputType: 'search',
    inputValue: selectedOptionLabel,
  });
}

function handleTextInputBlur(label) {
  inputChanged({
    context: props.context,
    subContext: 'pro service model extras modal',
    inputLabel: label,
    inputType: 'text',
    inputValue: null,
  });
}

function handleImageUploadComplete(blob, type, label) {
  blob.url = `/rails/active_storage/blobs/${blob.signed_id}/${blob.filename}`;
  formData.value.extrasImages[type] = {
    url: blob.url,
    signed_id: blob.signed_id,
  };
  inputChanged({
    context: props.context,
    subContext: 'pro service model extras modal',
    inputLabel: label,
    inputType: 'file',
    inputValue: null,
  });
}
</script>
<template>
  <p v-if="serviceType == 'full body model'" class="fbm-header">
    confirm your choice of
    <a
      href="https://soona.co/pro-services/model-standard-wardrobe"
      target="_blank"
      rel="noopener noreferrer"
      >standard wardrobe</a
    >
    and bare nails for your model or indicate an upgrade below.
  </p>
  <SoonaTextfield
    v-model="formData.noteForModel"
    class="element"
    label="note for soona about this model"
    placeholder="add note for this model"
    :maxlength="150"
    data-cypress="model-details-note"
    @on-blur="handleTextInputBlur('note for soona about this model')"
  >
    <template #helper>optional</template>
  </SoonaTextfield>
  <div
    v-if="['full body model', 'hand model'].includes(serviceType)"
    class="manicure-section field-section"
  >
    <SoonaSelect
      v-model="formData.manicureSelection"
      class="element"
      placeholder="select"
      :options="manicureOptions"
      :disabled="isSubmitted"
      data-cypress="model-details-manicure-preference"
      @update:model-value="
        handleSelectChange(
          manicureOptions,
          formData.manicureSelection,
          'select nail preference',
          'manicure'
        )
      "
    >
      <template #label>select manicure nail preference</template>
    </SoonaSelect>
    <SoonaSuccess
      v-if="
        (formData.manicureSelection == 'manicure custom' ||
          formData.manicureSelection == 'manicure press on') &&
        !paymentsModelDetailsFlag
      "
    >
      {{ manicureInfoCopy }}
    </SoonaSuccess>
    <SoonaSelect
      v-if="formData.manicureSelection == 'manicure press on'"
      v-model="formData.manicurePressOnColorSelection"
      class="element"
      placeholder="press on manicure color"
      :options="pressOnColorOptions"
      @update:model-value="
        handleSelectChange(
          pressOnColorOptions,
          formData.manicurePressOnColorSelection,
          'press on manicure color',
          'manicure-color'
        )
      "
    >
      <template #label>press on manicure color</template>
    </SoonaSelect>
    <SoonaTextfield
      v-if="formData.manicureSelection == 'manicure custom'"
      v-model="formData.noteForCustomManicure"
      class="element"
      label="manicure custom color & detail"
      placeholder="add your manicure custom vision"
      :maxlength="150"
      @on-blur="handleTextInputBlur('manicure custom color & detail')"
    />
    <div v-if="formData.manicureSelection == 'manicure custom'">
      <SoonaUpload
        class="reference-img-btn"
        class-overide="reference-img-btn"
        :upload-complete="
          blob =>
            handleImageUploadComplete(
              blob,
              'manicure_reference_image',
              manicureRefImgLabel
            )
        "
        :hide-icon="true"
        :is-multiple="false"
        :label="getImgLabelHTML(manicureRefImgLabel)"
        accept=".jpeg, .jpg, .png, .gif"
        @percentage-complete="updatePercentageComplete"
      />
      <span
        v-if="
          formData.extrasImages.manicure_reference_image &&
          formData.extrasImages.manicure_reference_image.url &&
          formData.manicureSelection === 'manicure custom'
        "
        class="delete-image-wrapper"
      >
        <img
          :src="formData.extrasImages.manicure_reference_image.url"
          class="thumbnail-img"
        />
        <button
          class="delete-btn"
          @click="removeRefImg('manicure_reference_image')"
        >
          <SoonaIcon name="xmark" />
          <span class="u-a11y-only">Remove this image</span>
        </button>
      </span>
    </div>
  </div>
  <div
    v-if="['full body model', 'foot model'].includes(serviceType)"
    class="manicure-section field-section"
  >
    <SoonaSelect
      v-model="formData.pedicureSelection"
      class="element"
      placeholder="select"
      :options="pedicureOptions"
      :disabled="isSubmitted"
      data-cypress="model-details-pedicure-preference"
      @update:model-value="
        handleSelectChange(
          pedicureOptions,
          formData.pedicureSelection,
          'select nail preference',
          'pedicure'
        )
      "
    >
      <template #label>select pedicure nail preference</template>
    </SoonaSelect>
    <SoonaSuccess
      v-if="
        formData.pedicureSelection == 'pedicure custom' &&
        !paymentsModelDetailsFlag
      "
    >
      {{ pedicureInfoCopy }}
    </SoonaSuccess>
    <SoonaTextfield
      v-if="formData.pedicureSelection == 'pedicure custom'"
      v-model="formData.noteForCustomPedicure"
      class="element"
      label="pedicure custom color & detail"
      placeholder="add your pedicure custom vision"
      :maxlength="150"
      @on-blur="handleTextInputBlur('pedicure custom color & detail')"
    />
    <div v-if="formData.pedicureSelection == 'pedicure custom'">
      <SoonaUpload
        class="reference-img-btn"
        class-overide="reference-img-btn"
        :upload-complete="
          blob =>
            handleImageUploadComplete(
              blob,
              'pedicure_reference_image',
              pedicureRefImgLabel
            )
        "
        :hide-icon="true"
        :is-multiple="false"
        :label="getImgLabelHTML(pedicureRefImgLabel)"
        accept=".jpeg, .jpg, .png, .gif"
        @percentage-complete="updatePercentageComplete"
      />
      <span
        v-if="
          formData.extrasImages.pedicure_reference_image &&
          formData.extrasImages.pedicure_reference_image.url &&
          formData.pedicureSelection === 'pedicure custom'
        "
        class="delete-image-wrapper"
      >
        <img
          :src="formData.extrasImages.pedicure_reference_image.url"
          class="thumbnail-img"
        />
        <button
          class="delete-btn"
          @click="removeRefImg('pedicure_reference_image')"
        >
          <SoonaIcon name="xmark" />
          <span class="u-a11y-only">Remove this image</span>
        </button>
      </span>
    </div>
    <div
      v-if="['foot model', 'full body model'].includes(serviceType)"
      class="wardrobe-section"
    >
      <SoonaSelect
        v-model="formData.shavedLegsSelection"
        class="element"
        placeholder="select"
        :options="shavedLegsOptions"
        @update:model-value="
          handleSelectChange(
            shavedLegsOptions,
            shavedLegsSelection,
            'do you need this model to arrive with shaved legs?'
          )
        "
      >
        <template #label
          >do you need this model to arrive with shaved legs?</template
        >
      </SoonaSelect>
    </div>
  </div>
  <div
    v-if="serviceType == 'full body model'"
    class="hmu-section field-section"
  >
    <SoonaSelect
      v-model="formData.hmuSelection"
      class="element"
      placeholder="select"
      :options="hmuOptions"
      :disabled="isSubmitted"
      data-cypress="model-details-hair-and-makeup"
      @update:model-value="
        handleSelectChange(
          hmuOptions,
          formData.hmuSelection,
          'select hair & makeup preference',
          'hair and makeup'
        )
      "
    >
      <template #label>select hair & makeup preference</template>
      <template #subtext
        >our models arrive with clean natural hair + little to no makeup unless
        otherwise indicated</template
      >
    </SoonaSelect>
    <p v-if="formData.hmuSelection == 'yes'" class="hmu-description">
      describe hair & makeup vision
    </p>
    <SoonaTextfield
      v-if="formData.hmuSelection == 'yes'"
      v-model="formData.noteForHMU"
      class="element"
      label="this will be shared with your hair and makeup artist."
      placeholder="add your hair & makeup vision"
      :maxlength="150"
      @on-blur="
        handleTextInputBlur(
          'this will be shared with your hair and makeup artist.'
        )
      "
    />
    <div v-if="formData.hmuSelection == 'yes'">
      <SoonaUpload
        class="reference-img-btn"
        class-overide="reference-img-btn"
        :upload-complete="
          blob =>
            handleImageUploadComplete(
              blob,
              'hmu_reference_image',
              hmuRefImgLabel
            )
        "
        :hide-icon="true"
        :is-multiple="false"
        :label="getImgLabelHTML(hmuRefImgLabel)"
        accept=".jpeg, .jpg, .png, .gif"
        @percentage-complete="updatePercentageComplete"
      />
      <span
        v-if="
          formData.extrasImages.hmu_reference_image &&
          formData.extrasImages.hmu_reference_image.url &&
          formData.hmuSelection === 'yes'
        "
        class="delete-image-wrapper"
      >
        <img
          :src="formData.extrasImages.hmu_reference_image.url"
          class="thumbnail-img"
        />
        <button class="delete-btn" @click="removeRefImg('hmu_reference_image')">
          <SoonaIcon name="xmark" />
          <span class="u-a11y-only">Remove this image</span>
        </button>
      </span>
    </div>
  </div>
  <div
    v-if="['pet model', 'foot model', 'full body model'].includes(serviceType)"
    class="wardrobe-section"
  >
    <SoonaSelect
      v-model="formData.wardrobeSelection"
      class="element"
      placeholder="select"
      :options="wardrobeOptions"
      data-cypress="model-details-wardrobe"
      @update:model-value="
        handleSelectChange(
          wardrobeOptions,
          formData.wardrobeSelection,
          wardrobeSelectLabel
        )
      "
    >
      <template #label>{{ wardrobeSelectLabel }}</template>
      <template #subtext>{{ wardrobeSelectSubtext }}</template>
    </SoonaSelect>
    <SoonaAlert v-if="formData.wardrobeSelection == 'custom wardrobe'">
      please send multiple sizes to ensure fit. model size and shipping address
      is available after confirming these details.
    </SoonaAlert>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.fbm-header {
  @include variables_fonts.u-body--regular;

  margin-bottom: 1.5rem;

  a {
    text-decoration: underline;
  }
}

.delete-image-wrapper {
  margin: 0 0.5rem 0 0;
  display: flex;

  .thumbnail-img {
    display: block;
    height: 5.8125rem;
    width: 5.8125rem;
    border-radius: 0.3125rem;
    object-fit: cover;
  }

  .delete-btn {
    align-items: center;
    background-color: variables.$gray-80;
    border-radius: 50%;
    color: variables.$white-default;
    display: flex;
    height: 1.25rem;
    justify-content: center;
    margin-left: -0.9375rem;
    margin-top: -0.3125rem;
    padding: 0;
    width: 1.25rem;
  }
}

.hmu-description {
  margin-top: 1.5625rem;
}

.soona-uploader {
  font-size: 0.875rem;
}
</style>
