<template class="UnscheduleBookingCard">
  <div class="control unschedule-booking-card">
    <div>
      <span
        class="shoot-readiness"
        :title="shootReadinessHoverText"
        :class="shootReadiness"
      ></span>
      <SoonaAlert v-if="activeTransferStatus?.type === 'info'">
        {{ activeTransferStatus.label }}
      </SoonaAlert>
      <SoonaSuccess v-else-if="activeTransferStatus?.type === 'success'">
        {{ activeTransferStatus.label }}
      </SoonaSuccess>
      <div v-else class="extra-spacing"></div>
    </div>

    <div class="unschedule-card-body">
      <div
        v-if="booking.schedule_status"
        class="u-small--heavy status-badge-wrapper"
      >
        <p class="schedule-status">
          <span
            class="status-badge is-pulled-left"
            :class="booking.schedule_status"
          ></span>
          {{ booking.schedule_status.replaceAll('_', ' ') }}
        </p>
      </div>

      <div class="u-label--heavy card-main-content">
        <div class="first-row">
          <p>
            <span v-if="booking.shoot_type === 'video'"> 🎥 </span>
            {{ booking.account_name.toLowerCase() }}
          </p>
          <div class="status-emojis">
            <span
              v-if="booking.package_status"
              :title="booking.package_status"
              >{{ packageStatus }}</span
            >
            <span v-if="booking.purchased_pro_services" title="pro service"
              >🧑‍🎤</span
            >
            <span
              v-if="booking.shoot_exceptions.length > 0"
              title="shoot exception"
              >⚠️</span
            >
          </div>
        </div>

        <div class="second-row">
          <div class="flag-wrapper">
            <SoonaFlag
              v-for="flag in filteredAccountFlags"
              :key="flag.id"
              font-size="0.5rem"
              padding-size="0 0.375rem"
              :title="flag.title"
              :text-color="flag.text_color"
              :background-color="flag.background_color"
            />
          </div>
          <span
            v-if="csrStepsSuccess"
            title="“required for scheduling” tasks completed by customer"
          >
            {{ requiredStepsCompleted?.length }}/{{ requiredStepsAll?.length }}
          </span>
        </div>

        <div class="booking-card__footer u-small--regular">
          <p class="booking-name">
            {{ booking.name
            }}<strong v-if="booking.is_pick_a_pack">
              qty: {{ booking.pack_configuration.quantity }}</strong
            >
          </p>
          <p v-if="onTheWayPackage">ETD: {{ displayOnTheWayPackageETA }}</p>
          <p
            v-if="!isUnassignedBay"
            class="booking-bay"
            :style="{ backgroundColor: `${booking.color}` }"
          >
            {{ booking.space }}
          </p>
          <span v-if="isGhost(booking)">👻</span>
          <p class="age-text">{{ reservationAge(booking) }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import SoonaAlert from 'src/components/ui_library/SoonaAlert.vue';
import SoonaFlag from 'src/components/ui_library/SoonaFlag.vue';
import SoonaSuccess from 'src/components/ui_library/SoonaSuccess.vue';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useReservationReadinessSteps } from '@/queries/reservation-readiness-steps/useReservationReadinessSteps';
import {
  BingoBlue,
  BubbletapePink30,
  FriendlyRed50,
  PeriwinkBlue40,
  WhiteDefault,
} from 'src/variables.module.scss';

dayjs.extend(relativeTime);

export default {
  name: 'UnscheduleBookingCard',
  components: { SoonaAlert, SoonaFlag, SoonaSuccess },
  props: {
    booking: Object,
  },
  setup(props) {
    const { data: csrSteps, isSuccess: csrStepsSuccess } =
      useReservationReadinessSteps(computed(() => props.booking.id));

    const requiredStepsAll = computed(() => {
      return csrSteps.value?.filter(
        step => step.required_for_slug === 'scheduling'
      );
    });

    const requiredStepsCompleted = computed(() => {
      return requiredStepsAll.value?.filter(step => step.complete);
    });

    return {
      requiredStepsCompleted,
      requiredStepsAll,
      csrStepsSuccess,
      BingoBlue,
      BubbletapePink30,
      FriendlyRed50,
      PeriwinkBlue40,
      WhiteDefault,
    };
  },
  computed: {
    isUnassignedBay() {
      return this.booking.space === 'unassigned';
    },
    packageStatus() {
      let packageStatus;
      if (this.booking.package_status === 'on_the_way') {
        packageStatus = '🚚';
      } else if (this.booking.package_status === 'delivered') {
        packageStatus = '📦';
      } else if (this.booking.package_status === 'in_studio') {
        packageStatus = '✅';
      } else if (this.booking.package_status === 'stored') {
        packageStatus = '🗃';
      } else if (this.booking.package_status === 'returned') {
        packageStatus = '🔙';
      } else {
        packageStatus = '🗑';
      }
      return packageStatus;
    },
    displayLabel() {
      let label = null;

      if (this.booking.is_account_vip && this.booking.is_account_fast_pass) {
        label = {
          title: 'vip/fp',
          text_color: this.WhiteDefault,
          background_color: this.BingoBlue,
        };
      } else if (this.booking.is_account_fast_pass) {
        label = {
          title: 'fast pass',
          text_color: this.WhiteDefault,
          background_color: this.PeriwinkBlue40,
        };
      } else if (this.booking.is_account_vip) {
        label = {
          title: 'vip',
          text_color: this.WhiteDefault,
          background_color: this.FriendlyRed50,
        };
      }

      return label;
    },
    onTheWayPackage() {
      if (this.booking.package_status === 'on_the_way') {
        return true;
      } else {
        return false;
      }
    },
    displayOnTheWayPackageETA() {
      return this.booking.on_the_way_package_eta
        ? dayjs(this.booking.on_the_way_package_eta).format('MM/DD')
        : 'tbd';
    },
    shootReadiness() {
      return this.booking.shoot_readiness;
    },
    shootReadinessHoverText() {
      if (this.booking.shoot_readiness === 'shoot_readiness_green') {
        return 'ready';
      } else if (this.booking.shoot_readiness === 'shoot_readiness_yellow') {
        return 'pending package';
      } else if (this.booking.shoot_readiness === 'shoot_readiness_orange') {
        return 'pending shotlist';
      } else {
        return 'not ready';
      }
    },
    activeTransferStatus() {
      let transfer = this.booking.transfer_package_requirement?.find(
        x => x.status === 'pending' || x.status === 'initiated'
      );
      if (transfer) {
        if (transfer.status === 'pending') {
          return { label: 'transfer needed', type: 'info' };
        } else if (transfer.status === 'initiated') {
          return { label: 'in transfer', type: 'success' };
        }
      }
      return null;
    },
    filteredAccountFlags() {
      let flags = this.booking.main_account_flags?.filter(
        flag => flag.slug === 'preferred'
      );

      if (this.displayLabel) flags.unshift(this.displayLabel);

      if (this.booking.first_time_customer) {
        flags.unshift({
          title: 'new',
          background_color: this.BubbletapePink30,
        });
      }

      return flags;
    },
  },
  methods: {
    reservationAge(r) {
      return dayjs().to(dayjs(r.created_at));
    },
    isGhost(r) {
      return dayjs().diff(dayjs(r.created_at), 'days') > 30;
    },
  },
};
</script>
<style lang="scss" scoped>
@use '@/variables';

.unschedule-booking-card {
  .schedule-status {
    align-items: center;
    display: flex;
    padding-bottom: 0.5rem;
  }

  .status-badge {
    border-radius: 50%;
    margin-right: 0.5rem;
    padding: 0.25rem;
  }

  .card-main-content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .first-row {
    display: flex;
    justify-content: space-between;
  }

  .title {
    display: flex;
    gap: 0.375rem;
    flex-wrap: wrap;
  }

  .status-emojis {
    display: flex;
    gap: 0.5rem;
  }

  .second-row {
    display: flex;
    justify-content: space-between;
  }

  .flag-wrapper {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 0.25rem;
  }

  .booking-card__footer {
    align-items: center;
    display: flex;
    gap: 0.5rem;
    justify-content: space-between;
  }

  .booking-name {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .booking-bay {
    align-items: center;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    padding: 0.25rem 0.75rem;
    flex-shrink: 0;
  }

  .unschedule-card-body {
    padding-right: 0.75em;
    padding-left: 0.75em;
  }

  .shoot-readiness {
    display: block;
    max-width: 43.75rem;
    height: 0.25rem;
    position: relative;
  }

  .shoot_readiness_red {
    background: variables.$friendly-red-60;
  }

  .shoot_readiness_yellow {
    background: variables.$daisy-50;
  }

  .shoot_readiness_orange {
    background: #de9f65;
  }

  .shoot_readiness_green {
    background: #3e9963;
  }

  .extra-spacing {
    padding-top: 0.5rem;
  }
}
</style>
<style lang="scss">
.unschedule-booking-card {
  .soona-alert {
    border-radius: 0;
    padding: 0.375rem 0.625rem;

    .emoji-slot {
      margin-right: 0.5rem;
    }

    .title-slot {
      text-transform: uppercase;
      font-size: 0.75rem;
      line-height: 1rem;
    }
  }
}
</style>
