<template>
  <SoonaDialog class="add-custom-props-modal" @close="$emit('close-dialog')">
    <template #heading>add a custom prop</template>
    <template #header>
      <p>
        add your custom props then please ship it to us or email us at
        hey@soona.co to add grocery shopping to your shoot
      </p>
    </template>
    <SoonaTextfield
      v-model:model-value="customTag.title"
      :required="true"
      label="custom prop name"
      placeholder="name your custom prop"
      type="email"
      :maxlength="32"
      @keyup.enter="handleSubmit(customTag)"
    />
    <template #footer>
      <div class="add-custom-props-modal__action-btns">
        <SoonaButton
          copy="cancel"
          variation="secondary-gray"
          @on-click="$emit('close-dialog')"
        />
        <SoonaButton
          copy="save"
          :disabled="disableSubmit"
          @on-click="handleSubmit(customTag)"
        />
      </div>
    </template>
  </SoonaDialog>
</template>

<script>
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaTextfield from 'src/components/ui_library/SoonaTextfield.vue';

export default {
  name: 'AddCustomPropsModal',
  components: {
    SoonaButton,
    SoonaDialog,
    SoonaTextfield,
  },
  props: {
    tagCategory: Object,
    submitCustomPropsTag: Function,
  },
  emits: ['close-dialog'],
  data() {
    return {
      customTag: {
        title: '',
      },
      customTagReferenceImage: {},
    };
  },
  computed: {
    disableSubmit() {
      return !this.customTag.title;
    },
  },
  methods: {
    handleSubmit(customTag) {
      //this.$track('Save Clicked', { custom_tag: this.customTag });
      this.submitCustomPropsTag(customTag);
      this.$emit('close-dialog');
      this.customTag.title = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.add-custom-props-modal {
  &__action-btns {
    display: flex;
    gap: 0.75rem;
  }
}
</style>
