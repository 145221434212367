<script setup>
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import AddPackage from 'src/components/user/anytime/inventory/AddPackage.vue';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import DisplayInventoryPackage from './DisplayInventoryPackage.vue';
import { useCapability } from 'src/composables/useCapability';
import { useInventoryPackages } from '@/queries/inventory/useInventoryPackages';
import PaginatorFull from 'src/components/directory/PaginatorFull.vue';
import ManageReturnAddress from 'src/components/user/anytime/inventory/return-address/ManageReturnAddress.vue';
import SoonaNoResults from '@/components/ui_library/SoonaNoResults.vue';
import { useReservation } from '@/composables/useReservation';
import { keepPreviousData } from '@tanstack/vue-query';

const { hasCapability } = useCapability({ capability: 'ft_soona_staff' });

const store = useStore();
const isAnytime = computed(() => store.getters['reservation/isAnytime']);
const currentReservationAccountId = computed(
  () => store.state.reservation.currentReservation?.account_id
);
const currentReservationId = computed(
  () => store.state.reservation.currentReservation?.id
);

const { isSendingPackage } = useReservation(currentReservationId);

const newShipmentClicked = () => {
  store.dispatch('reservation/newShipment');
};

const currentPage = ref(1);
const itemsPerPage = ref(10);
// go back to the first page when itemsPerPage changes
watch(itemsPerPage, () => {
  currentPage.value = 1;
});

const { data: packagesData } = useInventoryPackages(
  {
    reservationId: currentReservationId,
    currentPage,
    itemsPerPage,
  },
  {
    placeholderData: keepPreviousData,
  }
);

const packages = computed(() => packagesData.value?.inventoryPackages || []);

const packagesPagination = computed(() => {
  if (packagesData.value) {
    return packagesData.value.pagination;
  } else {
    return {
      totalCount: 0,
      currentPage: 0,
      totalPages: 0,
      itemsPerPage: 0,
    };
  }
});

const displayManageReturnAddress = computed(() => {
  return packages.value.some(x => x.post_shoot_option === 'return_shipping');
});

const showPackageList = computed(() => {
  // we need backwards compatibility for reservations w/ no PSO but have packages
  return (
    isSendingPackage.value || packages.value?.length > 0 || hasCapability.value
  );
});
</script>

<template>
  <div>
    <div v-if="displayManageReturnAddress" class="return-address-item">
      <ManageReturnAddress :account-id="currentReservationAccountId" />
    </div>
    <div
      v-if="showPackageList"
      id="shipping-progress"
      class="shipping-progress__inventory-packages shipping-progress__component"
      data-cypress="inventory-packages"
    >
      <h2 class="u-subheader--heavy shipping-progress--title">
        package status
      </h2>
      <template v-if="packages.length > 0">
        <div v-for="inventoryPackage in packages" :key="inventoryPackage.id">
          <DisplayInventoryPackage
            :inventory-package="inventoryPackage"
            :reservation-id="currentReservationId"
            :reservation-account-id="currentReservationAccountId"
          />
          <hr />
        </div>
        <PaginatorFull
          v-model:page="currentPage"
          v-model:records-per-page="itemsPerPage"
          class="pagination"
          :total-pages="packagesPagination.totalPages"
          :total-count="packagesPagination.totalCount"
          :page-count="packages.length"
        />
      </template>
      <template v-else>
        <SoonaNoResults>
          <template #header> nothing here yet! </template>
          <template #body>
            create a shipping label or add a package. check off your shipping
            to-do when you’re done!
          </template>
        </SoonaNoResults>
      </template>
      <div class="shipping-action-buttons">
        <SoonaButton
          v-if="packages.length > 0"
          class="inventory-link"
          element="a"
          :href="`/user/anytime#/account/${currentReservationAccountId}/products/inventory`"
          size="medium"
          variation="tertiary"
          copy="manage package inventory"
        />
        <div v-if="isAnytime || hasCapability" class="package-actions">
          <AddPackage
            :account-id="currentReservationAccountId"
            :reservation-id="currentReservationId"
          />
          <SoonaButton
            element="router-link"
            :to="`/reservation/${currentReservationId}/shipping`"
            :copy="
              packages.length > 0 ? 'ship more' : 'create a shipping label'
            "
            size="medium"
            @click="newShipmentClicked"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.return-address-item,
.shipping-progress__inventory-packages {
  &:not(:last-child) {
    margin-bottom: 2.75rem;
  }
}

.pagination {
  margin-top: 1rem;
}

.shipping-progress--title {
  margin-bottom: 1rem;
}

.shipping-action-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 0.5rem;
  align-items: center;
  border-top: 1px solid variables.$gray-30;
  padding-top: 1.25rem;
  margin-top: 1.25rem;
}

@media only screen and (max-width: variables.$screen-xs-max) {
  .shipping-action-buttons {
    flex-direction: column;
    align-items: initial;
  }
}

.package-actions {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-left: auto;
}

.shipping-progress__component {
  border: 0.0625rem solid variables.$gray-30;
  box-shadow: variables.$elevation-0;
  border-radius: 0.625rem;
  padding: 1rem;
}

@media (min-width: variables.$screen-sm-min) {
  .return-address-item,
  .shipping-progress__inventory-packages {
    &:not(:last-child) {
      margin-bottom: 3rem;
    }
  }
  .shipping-progress__component {
    padding: 1.5rem;
  }

  #shipping-progress {
    scroll-margin: var(--app-header-height, 5.125rem);
  }
}
</style>
