<script setup>
import { computed, ref, toRefs } from 'vue';
import ListingActionTemplate from './ListingActionTemplate.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import { useDeleteListingActionDigitalAssets } from '@/queries/useListingActions';
import { usePriorityError } from '@/composables/usePriorityError';
import { useUpdateListingAction } from '@/queries/useListingActions';
import { useCreatePublishTask } from '@/queries/useDigitalAssetPublishTasks';
import ComparisonViewer from './ComparisonViewer.vue';
import useSubscriptionActionPermissions from '@/components/user/anytime/listing_insights/listing_actions/useSubscriptionActionPermissions';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { usePlatformName } from './usePlatformName';
import MainProductOnWhiteActionButtons from './MainProductOnWhiteActionButtons.vue';
import { useRoute } from 'vue-router';
import { useMainProductOnWhiteActions } from './useMainProductOnWhiteActions';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
  listingAction: {
    type: Object,
    required: true,
  },
  externalProductId: {
    type: String,
    default: null,
  },
  integrationId: {
    type: String,
    default: null,
  },
});

const emit = defineEmits(['showSubscriptionsDialog']);

const { accountId, listingAction, externalProductId, integrationId } =
  toRefs(props);

const { buttonClicked } = useBaseEvents();
const route = useRoute();
const subContext = computed(() =>
  route.name === 'listing-optimize'
    ? 'listing detail page'
    : 'optimize amazon main image product on white'
);

const hasReplacementAsset = computed(() => {
  return listingAction.value.replacement_asset_preview_url;
});

const { platform } = usePlatformName(listingAction);

const { linkSourceAsset, updateListingActionError, optimizeTriggered } =
  useMainProductOnWhiteActions(accountId, listingAction, {
    externalProductId,
    integrationId,
  });

// loading state
const isOptimizing = computed(() => {
  if (listingAction.value.most_recent_bulk_action) {
    return listingAction.value.most_recent_bulk_action.processing;
  } else {
    return optimizeTriggered.value;
  }
});

const publishTriggered = ref(false);
const isPublishing = computed(() => {
  if (listingAction.value.most_recent_publish_task) {
    return listingAction.value.most_recent_publish_task.processing;
  } else {
    return publishTriggered.value;
  }
});

const listingActionId = computed(() => listingAction.value.id);
const { mutate: updateListingAction } = useUpdateListingAction(
  accountId,
  listingActionId
);

const { mutate: createPublishTask } = useCreatePublishTask(accountId);

// undo changes module
const {
  mutate: deleteListingDigitalAssets,
  error: deleteListingDigitalAssetsError,
} = useDeleteListingActionDigitalAssets(accountId, {
  externalProductId,
  integrationId,
});

const undoChanges = () => {
  deleteListingDigitalAssets(listingAction.value.id);
  buttonClicked({
    context: 'listing insights',
    subContext: subContext.value,
    buttonLabel: 'undo changes',
    buttonAction: 'undo changes',
  });
};

// publish module
const handlePublish = () => {
  publishTriggered.value = true;

  buttonClicked({
    context: 'listing insights',
    subContext: subContext.value,
    buttonLabel:
      listingAction.value === 'failed_to_publish'
        ? 'retry'
        : `publish to ${platform.value}`,
    buttonAction: 'publish',
  });

  createPublishTask(
    {
      publish_assets: [
        {
          digital_asset_id: listingAction.value.replacement_asset.id,
          external_product_id: listingAction.value.external_product_id,
          image_position: listingAction.value.original_asset_image_position,
        },
      ],
    },
    {
      onSuccess: () => {
        publishTriggered.value = false;
      },
    }
  );
};

// comparison viewer module
const showComparison = ref(false);

const { canTakeAction, availableCreditsError, userCanUpgrade } =
  useSubscriptionActionPermissions(accountId);

// select source image module
const captureSelectSourceImageEvent = () => {
  if (canTakeAction.value) {
    buttonClicked({
      context: 'listing insights',
      subContext: subContext.value,
      buttonLabel: 'optimize',
      buttonAction: 'select source image',
    });
  } else {
    emit('showSubscriptionsDialog');
    buttonClicked({
      context: 'listing insights',
      subContext: subContext.value,
      buttonLabel: 'optimize',
      buttonAction: 'upgrade',
    });
  }
};

const handleLinkSourceAsset = digitalAssetId => {
  linkSourceAsset(digitalAssetId);
  buttonClicked({
    context: 'listing insights',
    subContext: subContext.value,
    buttonLabel: 'continue',
    buttonAction: 'product on white',
  });
};

const resetListingAction = () => {
  updateListingAction({
    status: 'pending_approval',
  });
  buttonClicked({
    context: 'listing insights',
    subContext: subContext.value,
    buttonLabel: 'cancel',
    buttonAction: 'reset listing action',
  });
};

const handleUpgradeClick = () => {
  if (userCanUpgrade.value) {
    emit('showSubscriptionsDialog', 'tier-two');
    buttonClicked({
      context: 'listing insights',
      subContext: subContext.value,
      buttonLabel: 'upgrade for more',
      buttonAction: 'upgrade to standard',
    });
  } else {
    window.location.href =
      "mailto:hey@soona.co?subject=Upgrade Subscription&body=Hi, I'd like to upgrade my subscription!";
    buttonClicked({
      context: 'listing insights',
      subContext: subContext.value,
      buttonLabel: 'upgrade for more',
      buttonAction: 'send email to customer service',
    });
  }
};

// error handling
const priorityError = usePriorityError(
  deleteListingDigitalAssetsError,
  availableCreditsError,
  updateListingActionError
);
</script>

<template>
  <ListingActionTemplate
    :is-optimizing="isOptimizing"
    :is-publishing="isPublishing"
    :listing-action="listingAction"
    @open-comparison-viewer="showComparison = true"
  >
    <template #error>
      <SoonaError v-if="priorityError" no-margin>
        {{ priorityError }}
      </SoonaError>
    </template>
    <template v-if="hasReplacementAsset" #listing-rule>
      <div class="listing-action-rule">
        <SoonaIcon name="image-square" />
        <div class="listing-action-rule__text">
          <div>
            <div class="listing-action-rule__text--replacement">
              optimized image
              <SoonaButton
                variation="icon-plain-gray"
                size="small"
                @click="showComparison = true"
              >
                <SoonaIcon name="arrow-up-right-from-square" />
              </SoonaButton>
            </div>
            <div class="listing-action-rule__text--original">
              original image
              <SoonaButton
                variation="icon-plain-gray"
                size="small"
                @click="showComparison = true"
              >
                <SoonaIcon name="arrow-up-right-from-square" />
              </SoonaButton>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #actions>
      <MainProductOnWhiteActionButtons
        :account-id="accountId"
        :listing-action="listingAction"
        @capture-select-source-image-event="captureSelectSourceImageEvent"
        @handle-upgrade-click="handleUpgradeClick"
        @handle-link-source-asset="handleLinkSourceAsset($event)"
        @handle-upload-complete="handleUploadComplete($event)"
        @handle-reset-listing-action="resetListingAction"
        @handle-publish="handlePublish"
        @undo-changes="undoChanges"
      />
    </template>
  </ListingActionTemplate>
  <ComparisonViewer
    v-if="showComparison"
    :listing-action-slug="listingAction.listing_action_slug"
    :original-asset="listingAction.original_asset"
    :replacement-asset="listingAction.replacement_asset"
    @close="showComparison = false"
  />
</template>

<style lang="scss" scoped>
@use 'src/variables';

.listing-action-rule {
  display: flex;
  gap: 0.5rem;

  &__text {
    &--original {
      color: variables.$gray-50;
    }
  }
}

.listing-action-active {
  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-end;
  }
}
</style>
