<script setup>
import { computed, ref, toRefs, watchEffect } from 'vue';
import SoonaTextfield from 'src/components/ui_library/SoonaTextfield.vue';
import { useReservation } from 'src/queries/useReservation';
import { useUpdateReservation } from 'src/queries/useUpdateReservation';
import { usePriorityError } from 'src/composables/usePriorityError';
import { useIncompleteSteps } from 'src/components/crew/booking/useIncompleteSteps.js';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaForm from '@/components/ui_library/SoonaForm.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaAlert from '@/components/ui_library/SoonaAlert.vue';

const props = defineProps({
  reservationId: String,
  slug: String,
});
const { reservationId, slug } = toRefs(props);

const { data: reservation, isLoading, error } = useReservation(reservationId);

const {
  mutate,
  isPending: isMutating,
  error: updateError,
} = useUpdateReservation(reservationId);

const title = ref('');

const goal = ref('');

const savedTitle = computed(() =>
  reservation.value?.isHeadshotsForAll
    ? 'headshots for all'
    : (reservation.value?.name ?? '')
);
const savedGoal = computed(() => reservation.value?.description ?? '');

watchEffect(() => {
  title.value = savedTitle.value;
  goal.value = savedGoal.value;
});

const isDirty = computed(() => {
  return title.value !== savedTitle.value || goal.value !== savedGoal.value;
});

const { stepIsIncomplete } = useIncompleteSteps(reservation, slug);

const priorityError = usePriorityError(error, updateError, stepIsIncomplete);

const isBusy = computed(() => isLoading.value || isMutating.value);

function save(event) {
  const data = new FormData(event.target);

  mutate({
    name: data.get('name'),
    description: data.get('description'),
  });
}
</script>

<template>
  <SoonaForm v-slot="{ hasErrors }" @submit="save">
    <SoonaTextfield
      v-model="title"
      label="title"
      name="name"
      placeholder="title this shoot"
      :disabled="isBusy"
      :required="true"
      :rules="['required']"
      data-cypress="input-title"
    />
    <SoonaTextfield
      v-model="goal"
      label="detail"
      name="description"
      placeholder="add detail about this shoot"
      element="textarea"
      :disabled="isBusy"
      :required="true"
      :rules="['required']"
      data-cypress="input-goal"
    />

    <SoonaAlert v-if="isDirty" class="booking-details-alert">
      you have unsaved changes. be sure to save to prevent them from being
      overwritten.
    </SoonaAlert>

    <SoonaButton
      copy="save shoot details"
      variation="primary"
      type="submit"
      size="medium"
      :disabled="isBusy || hasErrors || !isDirty"
      data-cypress="save-shoot-details"
    />
  </SoonaForm>

  <SoonaError v-if="priorityError">
    {{ priorityError }}
  </SoonaError>
</template>

<style scoped>
.booking-details-alert {
  margin: 0 0 1rem;
}
</style>
