<script setup>
import { computed, ref, toRef } from 'vue';
import { useAccount } from '@/composables/useAccount';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import BulkCreditsModal from 'src/components/modal-payment-flows/preferred/BulkCreditsModal.vue';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';

const route = useRoute();
const store = useStore();
const accountId = computed(() => store.state.currentUser.accountId);
const { account } = useAccount(toRef(() => accountId.value));
const { linkClicked } = useBaseEvents();

const preferredCredits = computed(
  () => account.value?.preferred_credit_count || 0
);

const linkLabel = 'open modal to purchase credits';
const showModal = ref(false);

const handleCloseModal = () => (showModal.value = false);

const handleOpenModal = () => {
  showModal.value = true;

  linkClicked({
    context: route.meta.context,
    subContext: 'platform sidebar',
    linkLabel: linkLabel,
    linkHref: null,
  });
};
</script>

<template>
  <button class="u-button-reset credit-purchase" @click="handleOpenModal">
    <p class="u-small--heavy">{{ preferredCredits }} preferred credits</p>
    <SoonaIcon name="arrow-right" size="small" />
    <span class="u-a11y-only">{{ linkLabel }}</span>
  </button>
  <BulkCreditsModal
    v-if="showModal"
    show-congrats-links
    @close="handleCloseModal"
  />
</template>

<style lang="scss" scoped>
@use '@/variables';

.credit-purchase {
  align-items: center;
  background-color: variables.$white-default;
  border: 0.0625rem solid variables.$gray-30;
  border-top: none;
  border-radius: 0;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  display: flex;
  justify-content: space-between;
  padding: 0.75rem;
  width: 100%;

  &:focus-visible {
    outline: 0.125rem solid variables.$periwink-blue-60;
  }

  &:hover {
    background-color: variables.$bubbletape-pink-10;
  }

  &:active {
    background-color: variables.$bubbletape-pink-20;
  }
}
</style>
