// this mixin is used to provide "booking progress state"
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('reservation', [
      'hasProServices',
      'hasPickedProServicesPreferences',
      'hasModelService',
      'hasHandModel',
      'hasPetModel',
      'hasFullBodyModel',
      'hasShotList',
    ]),
    ...mapGetters('shotList', ['buildingIt', 'buildingItComplete']),
    isBooked: function () {
      return (
        this.currentReservation.status === 'preshoot' &&
        !this.isShootDay &&
        !this.isOrder &&
        !this.isEditing &&
        !this.isCompleted
      );
    },
    isCanceled: function () {
      return this.currentReservation.status === 'canceled';
    },
    isPendingShotlist: function () {
      return this.currentReservation.status === 'pending_shotlist';
    },
    isPreshoot: function () {
      return this.currentReservation.status === 'preshoot';
    },
    isShootDay: function () {
      return this.currentReservation.status === 'in_progress';
    },
    isOrder: function () {
      return this.currentReservation.status === 'pending_selects';
    },
    isEditing: function () {
      return this.currentReservation.status === 'edits_required';
    },
    isCompleted: function () {
      return this.currentReservation.status === 'completed';
    },
    isAnytime: function () {
      return this.currentReservation.reservation_type === 'anytime';
    },
    isSurprise: function () {
      return this.currentReservation.reservation_type === 'surprise';
    },
    isHeadshotsForAll: function () {
      return this.currentReservation.reservation_type === 'headshots_for_all';
    },
    isPhoto() {
      return this.currentReservation.tags.some(
        t => t.title.toLowerCase() === 'photo'
      );
    },
    isVideo() {
      return this.currentReservation.tags.some(
        t => t.title.toLowerCase() === 'video'
      );
    },
    isNotScheduled() {
      return this.currentReservation.start == undefined || null;
    },
  },
};
