<script setup>
import { computed, onMounted, ref, watchEffect } from 'vue';
import { onKeyStroke } from '@vueuse/core';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useBulkCreditEvents } from '@/composables/segment/useBulkCreditEvents';
import { useTeleportTo } from '@/composables/useTeleportTo';
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap';
import { useDialogSoonaSelect } from '@/composables/useDialogSoonaSelect';
import { usePreferredCreditProducts } from '@/composables/usePreferredCreditProducts';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import bulkCreditTiers from '@app/assets/lists/bulk-credits/bulk-credit-tiers.json';
import CheckoutStep from 'src/components/modal-payment-flows/preferred/steps/CheckoutStep.vue';
import CongratsStep from 'src/components/modal-payment-flows/preferred/steps/CongratsStep.vue';
import InviteStep from 'src/components/modal-payment-flows/preferred/steps/InviteStep.vue';
import LandingStep from 'src/components/modal-payment-flows/preferred/steps/LandingStep.vue';
import PricingStep from 'src/components/modal-payment-flows/preferred/steps/PricingStep.vue';
import WaitlistStep from 'src/components/modal-payment-flows/preferred/steps/WaitlistStep.vue';
import uniqueId from 'lodash/uniqueId';
import { useDialogContext } from '@/composables/useDialog';

const props = defineProps({
  flow: {
    default: 'landing-first',
    type: String,
  },
  planSlugs: {
    default: () => ['preferred_tier_1', 'preferred_tier_2'],
    type: Array,
  },
  showCongratsLinks: {
    default: false,
    type: Boolean,
  },
  showInexpensiveList: {
    default: true,
    type: Boolean,
  },
  showLearnMoreCtas: {
    default: false,
    type: Boolean,
  },
  showMonthlyYearlyToggle: {
    default: false,
    type: Boolean,
  },
});

const emits = defineEmits(['close']);

const id = uniqueId('bulk-credits-dialog-');
const { checkAllowEscapeClose } = useDialogContext({ id });

const { linkClicked, modalDisplayed } = useBaseEvents();
const { bulkCreditsStarted } = useBulkCreditEvents();
const route = useRoute();
const store = useStore();

const flowList = {
  'landing-first': ['landing', 'pricing', 'checkout', 'congrats'],
  'waitlist-landing': ['waitlist', 'checkout', 'congrats'],
};

const showInexpensiveList = computed(() => props.showInexpensiveList);

const featureList = [
  {
    is_checked: true,
    details: 'creative account rep',
  },
  {
    is_checked: true,
    details: 'preferred scheduling',
  },
  {
    is_checked: true,
    details: 'discounted photos & pro services',
  },
  {
    is_checked: true,
    details: 'free premium editing',
  },
];

const flow = computed(() => props.flow);

const featuredTiers = bulkCreditTiers
  .filter(t => t.slug === props.planSlugs[0] || t.slug === props.planSlugs[1])
  .map(t => ({ ...t, price: +t.min_credits * +t.photo_price }));

const allTiers = bulkCreditTiers
  .filter(t => t.dropdown_label)
  .map(t => ({
    ...t,
    price: +t.min_credits * +t.photo_price,
    slug: !t.slug && +t.min_credits < 200 ? 'preferred_tier_1' : t.slug,
  }));

const context = route.meta.context;
const modalRef = ref(null);
const prevSteps = ref([]);
const selectedPlan = ref(null);
const stepNum = ref(0);

onKeyStroke('Escape', () => {
  if (checkAllowEscapeClose(modalRef)) {
    emits('close');
  }
});
useFocusTrap(modalRef, { immediate: true });

const accountId = computed(() => store.state.currentUser?.accountId);
const activeStep = computed(() => flowList[flow.value][stepNum.value]);
const isFirstStep = computed(() => stepNum.value === 0);
const subContext = computed(() => `bulk credits modal ${activeStep.value}`);

const featureSubtitle = computed(() =>
  activeStep.value === 'landing'
    ? 'see how to access:'
    : 'all photo packages include'
);

const { creditCount, currentCreditProduct } =
  usePreferredCreditProducts(accountId);

const showBackButton = computed(() => {
  if (isFirstStep.value || activeStep.value === 'congrats') return false;
  return true;
});

const landingButtonLabel = computed(() => {
  if (showInexpensiveList.value) return featuredTiers[0].bulk_credits_title;
  return featuredTiers.at(-1).bulk_credits_title;
});

const handleBackClick = () => {
  const lastStep = prevSteps.value.pop();

  linkClicked({
    context: context,
    subContext: subContext.value,
    linkLabel: 'back',
    linkHref: null,
  });

  stepNum.value = flowList[flow.value].indexOf(lastStep);
};

const handleCloseClick = () => {
  linkClicked({
    context: context,
    subContext: subContext.value,
    linkLabel: 'close modal',
    linkHref: null,
  });

  emits('close');
};

const handleNextClick = stepName => {
  prevSteps.value = [...prevSteps.value, activeStep.value];

  if (stepName) stepNum.value = flowList[flow.value].indexOf(stepName);
  else if (!flowList[flow.value][stepNum.value + 1]) prevSteps.value.pop();
  else stepNum.value += 1;
};

const updateCreditCount = newVal => (creditCount.value = newVal);

const handleSelectPlan = plan => {
  selectedPlan.value = plan;
  updateCreditCount(plan.min_credits);
};

watchEffect(() => {
  if (currentCreditProduct.value) {
    store.dispatch('account/clearAdditionalChargeItems');
    store.dispatch('account/setAdditionalChargeItems', {
      [currentCreditProduct.value.id]: +selectedPlan.value?.min_credits,
    });
  }
});

watchEffect(() => {
  if (activeStep.value) {
    modalDisplayed({
      context: route.meta.context,
      subContext: 'preferred funnel',
      modalName: `${activeStep.value}`,
    });
  }
});

// lifecycle hooks
onMounted(() => {
  if (showInexpensiveList.value) handleSelectPlan(featuredTiers[0]);
  else handleSelectPlan(featuredTiers.at(-1));

  bulkCreditsStarted({
    context: context,
    subContext: subContext.value,
  });
});

const teleportTo = useTeleportTo();

useDialogSoonaSelect(modalRef);
</script>

<template>
  <Teleport :to="teleportTo">
    <div ref="modalRef" class="bulk-credits-modal" @click.self="emits('close')">
      <div class="bulk-credits-modal__card">
        <LandingStep
          v-if="activeStep === 'landing'"
          :all-plans-button-label="landingButtonLabel"
          :context="context"
          :feature-list="featureList"
          :feature-subtitle="featureSubtitle"
          :show-back-button="showBackButton"
          :show-learn-more-button="showLearnMoreCtas"
          :sub-context="subContext"
          @back="handleBackClick"
          @close="handleCloseClick"
          @next="handleNextClick"
        >
          <template #heading>
            join preferred & save up to 30% on content
          </template>
          <template #subheading>
            unlock discounts & our most loved features.
          </template>
        </LandingStep>

        <WaitlistStep
          v-if="activeStep === 'waitlist'"
          :context="context"
          :sub-context="subContext"
          @back="handleCloseClick"
          @close="handleCloseClick"
          @next="handleNextClick"
        />

        <PricingStep
          v-if="activeStep === 'pricing'"
          :context="context"
          :plan-data="featuredTiers"
          :show-back-button="showBackButton"
          :show-learn-more-button="showLearnMoreCtas"
          :show-monthly-yearly-toggle="showMonthlyYearlyToggle"
          :sub-context="subContext"
          @back="handleBackClick"
          @close="handleCloseClick"
          @next="handleNextClick"
          @select-plan="handleSelectPlan"
        >
          <template #heading>
            join preferred & save up to 30% on content
          </template>
          <template #subheading>
            unlock free premium editing & discounts. access perks & additional
            features.
          </template>
        </PricingStep>

        <CheckoutStep
          v-if="activeStep === 'checkout'"
          :account-id="accountId"
          :all-plans="allTiers"
          :context="context"
          :current-credit-product="currentCreditProduct"
          :feature-list="featureList"
          :feature-subtitle="featureSubtitle"
          :selected-plan="selectedPlan"
          :show-back-button="showBackButton"
          :sub-context="subContext"
          @back="handleBackClick"
          @close="handleCloseClick"
          @next="handleNextClick"
          @select-plan="handleSelectPlan"
        />

        <InviteStep
          v-if="activeStep === 'invite'"
          :account-id="accountId"
          :context="context"
          :show-back-button="showBackButton"
          :sub-context="subContext"
          @back="handleBackClick"
          @close="handleCloseClick"
          @next="handleNextClick"
        />

        <CongratsStep
          v-if="activeStep === 'congrats'"
          :context="context"
          :show-back-button="showBackButton"
          :show-links="showCongratsLinks"
          :sub-context="subContext"
          @back="handleBackClick"
          @close="handleCloseClick"
          @next="handleNextClick"
        />
      </div>
    </div>
  </Teleport>
</template>

<style lang="scss" scoped>
@use '@/variables';

.bulk-credits-modal {
  align-items: center;
  background-color: rgba(63, 67, 75, 0.7);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100vw;

  &__card {
    background-color: variables.$white-default;
    border-radius: 0.625rem;
    height: 100%;
    max-height: 100dvh;
    max-width: 64rem;
    position: relative;
    width: 100%;

    @supports not (height: 100dvh) {
      // have bottom margin on mobile to prevent browser URL bar from overlapping content
      margin-bottom: 3rem;
      max-height: calc(100vh - 3rem);
    }

    @media (min-width: variables.$screen-md-min) {
      margin-bottom: 0;
      margin-left: 2rem;
      margin-right: 5rem;
      max-height: min(45rem, calc(100% - 4rem));
    }
  }
}
</style>
