<script setup>
import { computed, ref, toRefs, watchEffect } from 'vue';
import { useReservation } from 'src/queries/useReservation';
import { useUpdateReservation } from 'src/queries/useUpdateReservation';
import { useIncompleteSteps } from 'src/components/crew/booking/useIncompleteSteps';
import { usePriorityError } from 'src/composables/usePriorityError';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaForm from '@/components/ui_library/SoonaForm.vue';
import SoonaTextfield from 'src/components/ui_library/SoonaTextfield.vue';
import SoonaAlert from '@/components/ui_library/SoonaAlert.vue';

const props = defineProps({
  reservationId: String,
  slug: String,
});
const { reservationId, slug } = toRefs(props);

const {
  data: reservationData,
  isLoading: isLoadingReservation,
  error: reservationLoadingError,
} = useReservation(reservationId);

const customerName = computed(() => reservationData.value?.account?.name);
const resEmailBody = computed(
  () => reservationData.value?.custom_booking_email?.body ?? ''
);

const body = ref(resEmailBody.value);

watchEffect(() => {
  body.value = resEmailBody.value;
});

const isDirty = computed(() => body.value !== resEmailBody.value);

const {
  mutate: updateReservation,
  isPending: isUpdatingReservation,
  error: updateReservationError,
} = useUpdateReservation(reservationId);

const isBusy = computed(() => {
  return isLoadingReservation.value || isUpdatingReservation.value;
});

const { stepIsIncomplete } = useIncompleteSteps(reservationData, slug);

const priorityError = usePriorityError(
  reservationLoadingError,
  updateReservationError,
  stepIsIncomplete
);

function update(event) {
  const data = new FormData(event.target);

  updateReservation({
    custom_booking_email: {
      body: data.get('custom-message'),
      subject: data.get('email-subject'),
      preheader: data.get('email-preheader'),
      closing_message: data.get('closing-message'),
    },
  });
}
</script>

<template>
  <SoonaForm
    v-slot="{ hasErrors }"
    class="custom-booking-email"
    @submit="update"
  >
    <p>👋 {{ customerName }},</p>

    <SoonaTextfield
      v-model="body"
      label="extra note to customer"
      name="custom-message"
      placeholder="note details..."
      rows="3"
      element="textarea"
      :disabled="isBusy"
      data-cypress="customer-email-note"
    />
    <p class="custom-booking-email__helper-text">
      * don’t include a salutation. add an extra personalized note to the
      customer email. include some specifics about the shoot and why it was
      created. the email will automatically contain next steps.
    </p>
    <p>
      your next steps are to review the booking details and finish checking out.
    </p>

    <SoonaAlert v-if="isDirty" class="custom-booking-email__alert">
      you have unsaved changes. be sure to save to prevent them from being
      overwritten.
    </SoonaAlert>

    <SoonaButton
      variation="primary"
      copy="save email"
      type="submit"
      size="medium"
      :disabled="hasErrors || isBusy || !isDirty"
    />

    <SoonaError v-if="priorityError">
      {{ priorityError }}
    </SoonaError>
  </SoonaForm>
</template>

<style lang="scss" scoped>
@use '@/variables';

.custom-booking-email {
  :deep(.soona-textfield) {
    padding-bottom: 0;
  }
  hr {
    height: 0.0625rem;
    background-color: variables.$gray-30;
    margin: 0 0 1.5rem;
  }

  &__helper-text {
    font-size: 0.875rem;
    font-style: italic;
  }

  p {
    margin-bottom: 1.5rem;
  }

  &__alert {
    margin: 0 0 1rem;
  }
}
</style>
