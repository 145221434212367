<template>
  <div class="AddOnOrderCollections">
    <div v-for="collection in collections" :key="collection.id">
      <hr />
      <h3 class="u-subheader--all-caps-black">
        {{ collection.title }}
        <a
          :href="`/user/anytime#/album/${collection.id}`"
          target="_blank"
          class="title-link-alt"
        >
          <SoonaIcon name="arrow-up-right-from-square" size="medium" />
        </a>
      </h3>
      <div class="collection-details-row">
        <div class="collection-details-column">
          <p>
            <span class="u-label--heavy">dimension:</span>
            {{ collection.options.dimensions }}
          </p>
          <p>
            <span class="u-label--heavy">speed:</span>
            {{ formatSpeed(collection.options.speed) }}
          </p>
        </div>
        <div class="collection-details-column">
          <p>
            <span class="u-label--heavy">format(s):</span>
            {{ collection.options.format.join('/') }} [{{
              collection.options.direction
            }}]
          </p>
        </div>
      </div>
      <p class="collection-notes">
        <span class="u-label--heavy">notes:</span>
        {{ collection.description }}
      </p>
      <div v-if="reservationLineItemsFor(collection).length > 0">
        <p class="u-label--heavy">add-ons:</p>
        <ul class="add-on-items">
          <li
            v-for="rli in reservationLineItemsFor(collection)"
            :key="rli.id"
            class="add-on-item is-size-7"
          >
            {{ rli.product.name }} ({{
              Number(rli.amount_collected).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })
            }})
          </li>
        </ul>
      </div>
      <div
        v-for="reservationFile in collection.reservation_files"
        :key="reservationFile.id"
        class="reservation-file columns"
      >
        <div class="column is-one-quarter">
          <img
            v-if="reservationFile.preview_url"
            :src="reservationFile.preview_url"
            alt=""
            class="reservation-file-thumbnail"
          />
          <Download
            v-if="reservationFile.raw_url"
            :reservation-file="reservationFile"
          />
        </div>
        <p class="reservation-file-name column is-one-quarter u-body--heavy">
          {{ reservationFile.title }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Download from 'src/components/user/anytime/Download.vue';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';

export default {
  name: 'AddOnOrderCollections',
  components: {
    Download,
    SoonaIcon,
  },
  props: {
    collections: {
      type: Array,
      required: true,
    },
    order: {
      type: Object,
      required: true,
    },
  },
  methods: {
    reservationLineItemsFor(collection) {
      return this.order.reservation_line_items
        .filter(rli => rli.collection)
        .filter(
          rli =>
            rli.collection.id === collection.id &&
            rli.product.product_type !== 'add_on'
        );
    },
    formatSpeed(speed) {
      return `${speed} ms, ${(1000 / speed).toFixed(1)} frames/s`;
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';
.collection-details-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 0.5rem;

  .collection-details-column {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}
.collection-notes {
  margin-bottom: 0.5rem;
}

.image-container {
  flex-basis: 0;
  flex-grow: 1;
  margin-right: 1rem;
  width: 25%;

  @media only screen and (max-width: variables.$screen-xs-max) {
    max-width: none;
  }
}

.image-title {
  flex-basis: 0;
  flex-grow: 1;
  margin-bottom: 1.5rem;
}
</style>
