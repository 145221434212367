<script setup>
import { computed } from 'vue';
import SoonaSelect from 'src/components/ui_library/SoonaSelect.vue';
import SoonaTextfield from 'src/components/ui_library/SoonaTextfield.vue';
import SoonaFilter from 'src/components/shared/SoonaFilter.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

const props = defineProps({
  searchText: {
    type: String,
    required: true,
  },
  selectedFilter: {
    type: String,
    required: true,
  },
  sortBy: {
    type: Object,
    required: true,
  },
  filterOptions: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits([
  'update:searchText',
  'update:selectedFilter',
  'update:sortBy',
]);

const search = computed({
  get() {
    return props.searchText;
  },
  set(search) {
    emit('update:searchText', search);
  },
});

const filter = computed({
  get() {
    return props.selectedFilter;
  },
  set(filter) {
    emit('update:selectedFilter', filter);
  },
});

const handleFilterChange = option => {
  filter.value = option;
};

const sortOptions = [
  { value: 'name asc', label: 'alphabetical' },
  { value: 'created_at desc', label: 'newest' },
  // { value: 'pack_price asc', label: 'price' }, // disabled until we fix the backend
];

const selectedSortOption = computed({
  get() {
    return `${props.sortBy.orderBy} ${props.sortBy.direction}`;
  },
  set(option) {
    emit('update:sortBy', {
      orderBy: option.split(' ')[0],
      direction: option.split(' ')[1],
    });
  },
});
</script>

<template>
  <div class="pack-filters">
    <div class="pack-filters__line">
      <div class="pack-filters__search">
        <SoonaTextfield
          v-model="search"
          type="search"
          placeholder="search products"
          :maxlength="27"
          :show-counter="false"
        >
          <template #icon-left>
            <SoonaIcon name="search" size="small" />
          </template>
        </SoonaTextfield>
      </div>
    </div>
    <div class="pack-filters__line">
      <div class="pack-filters__filters">
        <SoonaFilter
          :options="filterOptions"
          :selected-filter="filter"
          :on-change="handleFilterChange"
        />
      </div>
      <div class="pack-filters__sort">
        <SoonaSelect v-model="selectedSortOption" :options="sortOptions" inline>
          <template #label>sort by</template>
        </SoonaSelect>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

// mobile first styles
.pack-filters {
  background-color: variables.$periwink-blue-10;
  border: 0.0625rem solid variables.$gray-30;
  border-radius: 0.625rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__line {
    display: flex;
    gap: 1rem;
    flex-flow: row wrap;
    justify-content: space-between;

    @media (min-width: variables.$screen-xl-min) {
      flex-flow: row;
    }
  }

  &__header {
    @include variables_fonts.u-headline--heavy;
  }

  &__search {
    width: 100%;

    :deep(.soona-textfield) {
      padding-bottom: 0;
    }
  }

  &__filters > * {
    justify-content: flex-start;
  }

  &__sort {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.75rem;

    label {
      @include variables_fonts.u-label--heavy;

      color: variables.$black-default;
    }

    :deep(.vs__dropdown-toggle) {
      background-color: variables.$input-background-color;
      min-width: 10.9375rem;
    }
  }

  &__btn {
    @include variables_fonts.u-body--heavy;

    /* need to set so iPads don't use blue text */
    color: variables.$black-default;

    background-color: variables.$white-default;
    border-radius: 0.25rem !important; // override anytime.scss
    border: 0.0625rem solid variables.$gray-30 !important; // override anytime.scss
    min-width: max-content;
    padding: 0.5rem 0.8125rem 0.5625rem;
    display: flex;
    align-items: center;
    gap: 0.25rem;

    &:hover {
      background-color: variables.$friendly-red-20;
      border-color: variables.$friendly-red-20 !important;
    }

    &[class$='-active'] {
      background-color: variables.$friendly-red-20;
      border-color: variables.$friendly-red-40 !important;
    }
  }
}
</style>
