<script setup>
import { computed, toRef } from 'vue';
import PaymentInterstitial from '@/components/booking/v3/p2/PaymentInterstitial.vue';
import { useReservation } from '@/composables/useReservation';
import { usePaymentInterstitialRedirect } from '@/components/booking/v3/p2/usePaymentInterstitialRedirect';

const props = defineProps({
  reservationId: String,
});

const { reservation, isFree } = useReservation(toRef(props, 'reservationId'));

const isOrderTotalZero = computed(() => {
  return reservation.value?.down_payment_order_total === '0.0';
});

const { redirectTo } = usePaymentInterstitialRedirect(reservation);
</script>
<template>
  <PaymentInterstitial :reservation-id="reservationId">
    <template v-if="isFree || isOrderTotalZero" #heading>
      booking created!
    </template>
    <template v-else #heading>payment received!</template>
    <template #bold-subheading>now hold tight...</template>
    <template #subheading>you’ll be redirected to your next steps</template>
    <template #payment-description>
      <p>
        if you don’t want to wait:
        <router-link :to="redirectTo">continue now!</router-link>
      </p>
      <p>or <router-link to="/booking/new">book again</router-link></p>
    </template>
  </PaymentInterstitial>
</template>
