<script setup>
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import { computed } from 'vue';
import { useUpdateDigitalAsset } from '@/queries/digital_assets/useUpdateDigitalAsset';
import { useSoonaToast } from '@/components/ui_library/soona_toast/useSoonaToast';

const props = defineProps({
  asset: {
    type: Object,
    required: true,
    default: () => ({}),
  },
});

const { addToast } = useSoonaToast();

const isVisibleToAll = computed(() => props.asset.visibility === 'all');
const accountId = computed(() => props.asset.account_id);

const { mutate: updateDigitalAsset, isPending: isUpdatingDigitalAsset } =
  useUpdateDigitalAsset(accountId);

const isLoading = computed(() => !!isUpdatingDigitalAsset.value);

const updateVisibility = visibility => {
  updateDigitalAsset(
    {
      assetId: props.asset.id,
      body: {
        visibility,
      },
    },
    {
      onError: () => {
        const msgVerb = visibility === 'crew' ? 'hiding' : 'un-hiding';
        addToast(`error ${msgVerb} asset`, {
          variation: 'error',
          noDismissButton: true,
        });
      },
    }
  );
};
</script>
<template>
  <SoonaButton
    v-if="isVisibleToAll"
    :loading="isLoading"
    :disabled="isLoading"
    copy="hide"
    variation="secondary-gray"
    @on-click="() => updateVisibility('crew')"
  />
  <SoonaButton
    v-else
    :loading="isLoading"
    :disabled="isLoading"
    copy="show"
    variation="secondary-gray"
    @on-click="() => updateVisibility('all')"
  />
</template>
