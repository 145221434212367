<script setup>
import { computed, ref, watchEffect } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import SoonaForm from 'src/components/ui_library/SoonaForm.vue';
import SoonaTextfield from 'src/components/ui_library/SoonaTextfield.vue';
import SoonaToggle from 'src/components/ui_library/SoonaToggle.vue';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import { useAddresses } from '@/queries/useAddresses';
import { useCreateAddress } from 'src/queries/useCreateAddress';
import { useUpdateAddress } from 'src/queries/useUpdateAddress';
import { useUpdateInventoryPackage } from '@/queries/inventory/useUpdateInventoryPackage';
import { usePriorityError } from '@/composables/usePriorityError';
import SoonaError from '../../../../ui_library/SoonaError.vue';
import SoonaSelect from '@/components/ui_library/SoonaSelect.vue';
import { queryKeys } from 'src/queries/query-keys';
import { useQueryClient } from '@tanstack/vue-query';

const props = defineProps({
  accountId: {
    type: Number,
    required: true,
  },
  isForcedDefault: {
    type: Boolean,
    default: false,
  },
  packageToUpdate: {
    type: Number,
    required: false,
  },
  isNewAddress: {
    type: Boolean,
    required: false,
  },
});
const emit = defineEmits(['cancel', 'displayReturnAddressDialog', 'success']);

const store = useStore();
const route = useRoute();

const savedAddressId = ref(undefined);
const showNewAddressForm = ref(false);

// add new address
const queryClient = useQueryClient();

const name = ref('');
const address1 = ref('');
const address2 = ref('');
const city = ref('');
const state = ref('');
const zip = ref('');
const isDefault = ref(true);

const isFormValid = computed(() =>
  Boolean(
    name.value && address1.value && city.value && state.value && zip.value
  )
);

const accountId = computed(() => props.accountId);

const {
  mutate: createAddress,
  isPending: createAddressIsPending,
  error: createAddressError,
} = useCreateAddress(accountId);

const {
  mutate: updateAddress,
  isPending: updateAddressIsPending,
  isError: updateAddressError,
} = useUpdateAddress(accountId);

// saved addresses
const { data: addressData, isLoading: addressesLoading } =
  useAddresses(accountId);

const savedAddresses = computed(() => addressData.value?.addresses || []);
const defaultAddress = computed(() => addressData.value?.default || undefined);

const isForcedDefault = computed(
  () => props.isForcedDefault ?? !defaultAddress.value
);

const addressOptions = computed(() => {
  return (
    savedAddresses.value.map(address => ({
      heading: address.name,
      label: `${address.address_1} ${address.address_2}, ${address.city}, ${address.state} ${address.postal_code}`,
      value: address.id,
    })) || []
  );
});

watchEffect(() => {
  if (defaultAddress.value) {
    savedAddressId.value = defaultAddress.value.id;
  }

  showNewAddressForm.value = savedAddresses.value.length === 0;

  if (props.isNewAddress) {
    showNewAddressForm.value = true;
  }
});

const packageId = computed(() => props.packageToUpdate);
const {
  mutate: updatePackage,
  isPending: updatePackageIsPending,
  isError: updatePackageError,
} = useUpdateInventoryPackage(packageId);

function submitForm() {
  if (showNewAddressForm.value && isFormValid.value) {
    createAddress(
      {
        name: name.value,
        address_1: address1.value,
        address_2: address2.value,
        city: city.value,
        state: state.value,
        postal_code: zip.value,
        is_default: isForcedDefault.value ? true : isDefault.value,
        package_to_update: packageId.value,
        address_type: 'shipping',
      },
      {
        onSuccess: () => {
          emit('success');

          if (isDefault.value) {
            queryClient.invalidateQueries({
              queryKey: queryKeys.actionItems(accountId.value),
            });
          }
        },
      }
    );
  } else if (packageId.value) {
    updatePackage(
      { return_address_id: savedAddressId.value },
      {
        onSuccess: async () => {
          await store.dispatch(
            'inventoryPackages/loadPackages',
            // kind of hacky, but by far the easiest solution
            // this at least falls back to more data instead of less
            route.params.reservationId
              ? { reservationId: route.params.reservationId }
              : { accountId: accountId.value }
          );
          emit('success');
        },
      }
    );
  } else if (!showNewAddressForm.value) {
    const selectedAddress = savedAddresses.value.find(
      x => x.id === savedAddressId.value
    );
    updateAddress(
      { ...selectedAddress, is_default: true, address_type: 'shipping' },
      {
        onSuccess: () => {
          emit('success');

          queryClient.invalidateQueries({
            queryKey: queryKeys.actionItems(accountId.value),
          });
        },
      }
    );
  }
}

function cancel() {
  emit('cancel');
}

const priorityError = usePriorityError(
  createAddressError,
  updatePackageError,
  updateAddressError
);
</script>

<template>
  <SoonaDialog @close="cancel">
    <template #heading>
      <strong id="return-address-form-heading">return address</strong>
    </template>
    <SoonaForm
      id="return-address-form"
      class="return-address-form"
      aria-labelledby="return-address-form-heading"
      @submit="submitForm"
    >
      <div v-if="showNewAddressForm">
        <SoonaTextfield
          v-model="name"
          name="name-company"
          label="name or company"
          type="text"
          required
          :rules="['required']"
        />
        <SoonaTextfield
          v-model="address1"
          name="address1"
          label="street address"
          type="text"
          required
          :rules="['required']"
          autocomplete="address-line1"
        />
        <SoonaTextfield
          v-model="address2"
          name="address2"
          label="street address 2 (optional)"
          type="text"
          autocomplete="address-line2"
        />
        <SoonaTextfield
          v-model="city"
          class="city-field"
          name="city"
          label="city"
          type="text"
          required
          :rules="['required']"
          autocomplete="address-level2"
        />
        <div class="return-address-form__state-zip">
          <SoonaTextfield
            v-model="state"
            name="state"
            label="state"
            type="text"
            pattern="[A-z]{2}"
            required
            :maxlength="2"
            :rules="['required']"
            autocomplete="address-level1"
          >
            <template #helper>two-character</template>
          </SoonaTextfield>

          <SoonaTextfield
            v-model="zip"
            name="zip"
            label="zip"
            type="text"
            pattern="[0-9]{5}"
            required
            :rules="['required']"
            autocomplete="postal-code"
          >
            <template #helper>five-digit</template>
          </SoonaTextfield>
        </div>
        <SoonaToggle
          v-if="!isForcedDefault"
          v-model="isDefault"
          type="checkbox"
          label="make this my default address"
        />
        <p class="return-address-form__form-info u-label--regular">
          your default return address will be automatically assigned to new
          packages, but packages can be individually re-assigned to a different
          address from your inventory tab.
        </p>
      </div>
      <div v-else class="return-address-form__existing-addresses">
        <SoonaSelect
          v-model="savedAddressId"
          class="category-dropdown"
          placeholder="select location"
          :disabled="addressesLoading"
          :options="addressOptions"
          :searchable="true"
        />
        <SoonaButton
          class="return-address-form__existing-addresses-add-address"
          type="button"
          variation="tertiary"
          copy="add new address"
          @on-click="() => (showNewAddressForm = true)"
        />
      </div>

      <SoonaError v-if="priorityError">
        {{ priorityError }}
      </SoonaError>
    </SoonaForm>
    <template #footer="{ close }">
      <SoonaButton
        type="button"
        variation="tertiary"
        copy="cancel"
        @on-click="close"
      />
      <SoonaButton
        type="submit"
        form="return-address-form"
        variation="primary"
        copy="save"
        :loading="
          createAddressIsPending ||
          updatePackageIsPending ||
          updateAddressIsPending
        "
      />
    </template>
  </SoonaDialog>
</template>

<style lang="scss" scoped>
@use '@/variables';

.return-address-form {
  color: variables.$black-default;

  &__existing-addresses {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    &-add-address {
      justify-content: flex-start;
    }
  }

  &__state-zip {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    & > *:nth-child(1) {
      flex: 2 1 11rem;
    }
    & > *:nth-child(2) {
      flex: 3 1 15rem;
    }
  }

  &__form-info {
    margin: 1rem 0;
  }
}
</style>
