<script setup>
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

const props = defineProps({
  context: {
    type: String,
  },
  subContext: {
    type: String,
  },
});

const { linkClicked } = useBaseEvents();

const linkHref = 'https://soona.co/contact-sales';
const linkLabel = 'chat with us';

const trackChatClick = () => {
  linkClicked({
    context: props.context,
    subContext: props.subContext,
    linkLabel: linkLabel,
    linkHref: linkHref,
  });
};
</script>

<template>
  <aside class="chat-banner">
    <div>
      <h3 class="u-body--heavy">looking for an enterprise solution?</h3>
      <p class="u-label--regular">
        soona has custom packages for larger companies to get the best price on
        all of our offerings.
      </p>
    </div>
    <SoonaButton
      element="a"
      :href="linkHref"
      target="_blank"
      rel="noopener noreferrer"
      variation="secondary-black"
      @on-click="trackChatClick"
    >
      {{ linkLabel }}
      <SoonaIcon name="arrow-right" size="medium" />
    </SoonaButton>
  </aside>
</template>

<style lang="scss" scoped>
.chat-banner {
  align-items: center;
  // $periwink-blue-20 & 0.7 opacity
  background-color: rgb(227, 231, 253, 0.7);
  border-radius: 0.625rem;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: space-between;
  padding: 1rem 1.5rem 1rem 1.25rem;
}
</style>
