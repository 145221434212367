<script setup>
import { ref, computed } from 'vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

const today = new Date();
const notificationDate = computed(
  () => new Date(today.getFullYear(), today.getMonth(), today.getDate() + 23)
);
const trialEndDate = computed(
  () => new Date(today.getFullYear(), today.getMonth(), today.getDate() + 31)
);
const trialTimelineItemsInfo = ref([
  {
    id: 'first',
    icon: 'stars',
    date: 'today',
    title: 'free trial for 30 days',
    subheading: 'cancel anytime',
  },
  {
    id: 'second',
    icon: 'mail',
    date: notificationDate.value.toLocaleDateString('en-us', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }),
    title: 'email reminder',
    subheading: 'we’ll remind you about your trial',
  },
  {
    id: 'third',
    icon: 'crown',
    date: trialEndDate.value.toLocaleDateString('en-us', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }),
    title: 'your plan begins',
    subheading: 'unless you’ve canceled your trial',
  },
]);
</script>
<template>
  <div class="trial-timeline">
    <img
      class="trial-timeline__star-top"
      src="@images/subscriptions/north_star.svg"
      alt=""
    />
    <h5 class="u-display--heavy">your soona trial</h5>
    <ul class="trial-timeline__list">
      <li
        v-for="item in trialTimelineItemsInfo"
        :key="`trial-timeline__list-item--${item.id}`"
        class="trial-timeline__list-item"
      >
        <div class="trial-timeline__list-item--icon">
          <SoonaIcon :name="item.icon" />
        </div>
        <div class="trial-timeline__list-item--content">
          <h6 class="u-subheader--heavy">
            <span>{{ item.date }} - </span>
            {{ item.title }}
          </h6>
          <span>{{ item.subheading }}</span>
        </div>
      </li>
    </ul>
  </div>
  <img
    class="trial-timeline__bottom-image"
    src="@images/subscriptions/subx_bottom_collage@2x-min.png"
    alt=""
  />
</template>
<style lang="scss" scoped>
@use '@/variables';

.trial-timeline {
  color: variables.$white-default;
  display: flex;
  flex-direction: column;

  h5 {
    padding-bottom: 3.25rem;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 3.25rem;
    z-index: 1;

    &-item {
      line-height: 1rem;
      display: flex;
      position: relative;
      gap: 1rem;

      &--icon {
        background: variables.$white-default;
        color: variables.$bubbletape-pink-40;
        width: 3.125rem;
        height: 3.125rem;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        box-shadow: 0 0.125rem 0.75rem variables.$black-translucent-12;
        z-index: 1;
      }

      &--content {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;

        h6 {
          & span {
            text-transform: uppercase;
          }
        }
      }

      &:first-child &--icon {
        background: variables.$bubbletape-pink-40;
        color: variables.$white-default;
      }

      &:first-child &--icon {
        &::before,
        &::after {
          content: '';
          display: block;
          position: absolute;
          width: 0.625rem;
          height: 12rem;
          background: variables.$gray-30;
          z-index: 0;
          top: 3rem;
          left: 1.1rem;

          @media (min-width: variables.$screen-md-min) and (max-width: 64.0625rem) {
            height: 14rem;
          }

          @media (min-width: 64.0625rem) {
            left: 1.25rem;
          }
        }

        &::after {
          height: 2rem;
          background: variables.$bubbletape-pink-40;
          border-radius: 0 0 2rem 2rem;

          @media (min-width: 64.0625rem) {
            height: 2rem;
            left: 1.25rem;
          }
        }
      }
    }
  }

  &__star-top {
    position: absolute;
    right: 1rem;
    top: 2rem;
    height: 4.9375rem !important;
    width: 4.9375rem !important;
  }

  &__bottom-image {
    position: absolute;
    right: 0;
    bottom: 0;
    width: fit-content;
    height: auto !important;
  }
}
</style>
