<script setup>
import { computed, provide } from 'vue';
import ImageQualityAccordion from '@/components/user/anytime/listing_insights/listing_detail/accordions/image_quality/ImageQualityAccordion.vue';
import GalleryCountAccordion from '@/components/user/anytime/listing_insights/listing_detail/accordions/gallery_count/GalleryCountAccordion.vue';
import NextSteps from '../NextSteps.vue';
import VideoAccordion from '@/components/user/anytime/listing_insights/listing_detail/accordions/video/VideoAccordion.vue';
import VisualMixAccordion from '@/components/user/anytime/listing_insights/listing_detail/accordions/visual_mix/VisualMixAccordion.vue';
import { useFlag } from '@/composables/useFlag';
import { useRoute } from 'vue-router';

const props = defineProps({
  accountId: {
    type: [String, Number],
  },
  displayNextSteps: {
    type: Boolean,
    default: true,
  },
  displayVideoAccordion: {
    type: Boolean,
    default: true,
  },
  listingActions: {
    type: Array,
    default: () => [],
  },
  listingData: {
    type: Object,
    required: true,
  },
  platform: {
    type: String,
    required: true,
    validator: function (value) {
      return ['Amazon', 'Shopify'].includes(value);
    },
  },
});

const emit = defineEmits(['refreshListing', 'showSubscriptionsDialog']);

const refreshListing = () => {
  emit('refreshListing');
};

const listingData = computed(() => props.listingData);
const listingActions = computed(() => props.listingActions);

// listing actions module
const route = useRoute();
const externalProductId = computed(
  () => route.params.externalProductId ?? null
);
const integrationId = computed(() => route.params.integrationId ?? null);
provide('externalProductId', externalProductId);
provide('integrationId', integrationId);

// listing data module
const computeListingData = (rule, ruleGroup) => {
  return computed(() => {
    return {
      ...listingData.value?.score_breakdown.find(x => x.rule === rule),
      rules_result: listingData.value?.rule_results.find(
        x => x.rule_group === ruleGroup
      ),
    };
  });
};

const imageQualityData = computeListingData('image quality', 'image quality');

const galleryCountData = computeListingData('gallery count', 'gallery count');

const visualMixData = computeListingData('visual mix', 'visual content mix');

const videoData = computed(() => {
  return {
    rules_result: listingData.value?.rule_results.find(
      x => x.rule_group === 'video count'
    ),
  };
});

// listing actions module
const actionableListingDetailFlag = useFlag(
  'toaster_actionable_listing_detail'
);

const getRuleSlugs = listingData => {
  return listingData.rules_result.rules.map(rule => rule.rule_slug) ?? [];
};

const findApplicableListingActions = (ruleSlugs, listingActions) => {
  return (
    listingActions.filter(action =>
      ruleSlugs.includes(action.listing_rule_slug)
    ) ?? []
  );
};

const useApplicableListingActions = listingData => {
  const ruleSlugs = getRuleSlugs(listingData);

  return findApplicableListingActions(ruleSlugs, listingActions.value);
};
</script>

<template>
  <div
    class="listing-optimize__wrapper"
    :class="{
      'listing-optimize__wrapper--full-width':
        !displayNextSteps || actionableListingDetailFlag,
    }"
  >
    <div class="listing-optimize__accordion">
      <ImageQualityAccordion
        :data="imageQualityData"
        :actions="useApplicableListingActions(imageQualityData)"
        :platform="platform"
        :assets="listingData.assets"
        @refresh-listing="refreshListing"
      />
      <GalleryCountAccordion
        :data="galleryCountData"
        :assets="listingData.assets"
        :platform="platform"
      />
      <VisualMixAccordion
        :account-id="accountId"
        :data="visualMixData"
        :actions="useApplicableListingActions(visualMixData)"
        :assets="listingData.assets"
        :platform="platform"
        @show-subscriptions-dialog="$emit('showSubscriptionsDialog', $event)"
      />
      <VideoAccordion
        v-if="displayVideoAccordion"
        :platform="platform"
        :data="videoData"
      />
    </div>
    <div
      v-if="displayNextSteps && accountId && !actionableListingDetailFlag"
      class="listing-optimize__next-steps"
    >
      <NextSteps :account-id="accountId" :platform="platform" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.listing-optimize {
  &__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr minmax(22rem, 1fr);
    gap: 1rem;

    &--full-width {
      display: flex;
      flex-direction: column;
    }
  }

  &__accordion {
    grid-column-start: 1;
    grid-column-end: 3;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__next-steps {
    grid-column-start: 3;
    grid-column-end: 4;
  }

  @media (max-width: variables.$screen-xs-max) {
    &__wrapper {
      display: flex;
      flex-direction: column;
    }
  }
}
</style>
