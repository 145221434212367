<script setup>
import { computed, inject, toRefs } from 'vue';
import SoonaFlag from '@/components/ui_library/SoonaFlag.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { FriendlyRed30 } from 'src/variables.module.scss';
import { useFlag } from '@/composables/useFlag';
import { usePriorityError } from '@/composables/usePriorityError';
import { useVisualMixHeaderIcon } from './useVisualMixHeaderIcon';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import LifestyleAction from '@/components/user/anytime/listing_insights/listing_actions/actions/LifestyleAction.vue';
import MainProductOnWhiteAction from '@/components/user/anytime/listing_insights/listing_actions/actions/MainProductOnWhiteAction.vue';
import LifestyleActionButtons from '@/components/user/anytime/listing_insights/listing_actions/actions/LifestyleActionButtons.vue';
import MainProductOnWhiteActionButtons from '@/components/user/anytime/listing_insights/listing_actions/actions/MainProductOnWhiteActionButtons.vue';
import { useLifestyleActions } from '@/components/user/anytime/listing_insights/listing_actions/actions/useLifestyleActions';
import { useMainProductOnWhiteActions } from '@/components/user/anytime/listing_insights/listing_actions/actions/useMainProductOnWhiteActions';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import useSubscriptionActionPermissions from '@/components/user/anytime/listing_insights/listing_actions/useSubscriptionActionPermissions';

const props = defineProps({
  accountId: { type: [String, Number], default: undefined }, // accountId is undefined when accessed from temporary listing insights
  listingAction: { type: Object, default: () => {} },
  rule: { type: Object, required: true },
  pass: { type: Boolean, default: false },
});

const emit = defineEmits(['showSubscriptionsDialog']);
const { buttonClicked } = useBaseEvents();
const { accountId, listingAction, rule } = toRefs(props);
const ruleSlugOrTitle = computed(() => rule.value.rule_slug ?? rule.value.rule);
const headerIcon = useVisualMixHeaderIcon(ruleSlugOrTitle);
const externalProductId = inject('externalProductId');
const integrationId = inject('integrationId');

const isProductOnWhiteRule = computed(() => {
  return rule.value.rule === 'product on white';
});

const qualifyingImages = computed(() => {
  return rule.value.images.length;
});

const recommendationText = computed(() => {
  if (props.pass) {
    return '';
  }
  if (isProductOnWhiteRule.value) {
    if (rule.value.main_image_product_on_white.score !== 1) {
      return 'required for main image';
    }
    return 'add 1';
  }
  if (rule.value.rule === 'multiple angles') {
    return `add ${3 - qualifyingImages.value}`;
  }
  if (['UGC', 'infographic', 'lifestyle', 'model'].includes(rule.value.rule)) {
    return 'add 1 or more';
  }
  return 'add 1';
});

const recommendImageCount = computed(() => {
  if (rule.value.rule === 'multiple angles') {
    return 3;
  }
  return 1;
});

const displayImageCount = computed(() => {
  return (
    !isProductOnWhiteRule.value ||
    !(
      rule.value.product_on_white_score > 0 &&
      rule.value.main_image_product_on_white_score === 0
    )
  );
});

// listing actions module
const actionableListingDetailFlag = useFlag(
  'toaster_actionable_listing_detail'
);

const listingActionId = computed(() => props.listingAction?.id ?? null);

const displayListingActionButtons = computed(
  () =>
    actionableListingDetailFlag.value &&
    listingActionId.value &&
    listingAction.value.status === 'active' &&
    listingAction.value.listing_action_slug !== 'missing-content' &&
    externalProductId.value &&
    accountId.value
);

const { linkSourceAsset: linkSourceAssetMainProductOnWhiteAction } =
  useMainProductOnWhiteActions(
    accountId,
    listingAction,
    {
      externalProductId,
      integrationId,
    },
    {
      enabled:
        displayListingActionButtons.value &&
        listingAction.value.listing_action_slug ===
          'main-image-product-on-white',
    }
  );

const hasProcessingBulkAction = computed(() => {
  return (
    listingAction.value.most_recent_bulk_action &&
    listingAction.value.most_recent_bulk_action.status !== 'delivered'
  );
});

const displayListingAction = computed(() => {
  return (
    actionableListingDetailFlag.value &&
    listingActionId.value &&
    (['pending_approval', 'publishing', 'failed_to_publish'].includes(
      props.listingAction?.status
    ) ||
      hasProcessingBulkAction.value)
  );
});

const listingActionComponentLookup = {
  lifestyle: LifestyleAction,
  'main-image-product-on-white': MainProductOnWhiteAction,
};

const listingActionButtonsComponentLookup = {
  lifestyle: LifestyleActionButtons,
  'main-image-product-on-white': MainProductOnWhiteActionButtons,
};

const { canTakeAction, availableCreditsError, userCanUpgrade } =
  useSubscriptionActionPermissions(accountId);

// lifestyle listing actions module
const {
  linkSourceAsset: linkSourceAssetLifestyleAction,
  handleUploadComplete,
} = useLifestyleActions(
  accountId,
  listingAction,
  {
    externalProductId,
    integrationId,
  },
  {
    enabled:
      displayListingActionButtons.value &&
      listingAction.value.listing_action_slug === 'lifestyle',
  }
);

const handleLinkSourceAsset = digitalAssetId => {
  if (listingAction.value.listing_action_slug === 'lifestyle') {
    linkSourceAssetLifestyleAction(digitalAssetId);
    buttonClicked({
      context: 'listing insights',
      subContext: 'listing detail page',
      buttonLabel: 'continue',
      buttonAction: 'open ai studio',
    });
  } else if (
    listingAction.value.listing_action_slug === 'main-image-product-on-white'
  ) {
    linkSourceAssetMainProductOnWhiteAction(digitalAssetId);
    buttonClicked({
      context: 'listing insights',
      subContext: 'listing detail page',
      buttonLabel: 'continue',
      buttonAction: 'product on white',
    });
  }
};

const handleUpgradeClick = () => {
  if (userCanUpgrade.value) {
    emit('showSubscriptionsDialog', 'tier-two');
    buttonClicked({
      context: 'listing insights',
      subContext: 'listing detail page',
      buttonLabel: 'upgrade for more',
      buttonAction: 'upgrade to standard',
    });
  } else {
    window.location.href =
      "mailto:hey@soona.co?subject=Upgrade Subscription&body=Hi, I'd like to upgrade my subscription!";
    buttonClicked({
      context: 'listing insights',
      subContext: 'listing detail page',
      buttonLabel: 'upgrade for more',
      buttonAction: 'send email to customer service',
    });
  }
};

const captureSelectSourceImageEvent = () => {
  const buttonLabelLookup = {
    lifestyle: 'create with AI',
    'main-image-product-on-white': 'optimize',
  };
  if (canTakeAction.value) {
    buttonClicked({
      context: 'listing insights',
      subContext: 'listing detail page',
      buttonLabel: buttonLabelLookup[listingAction.value.listing_action_slug],
      buttonAction: 'select source image',
    });
  } else {
    emit('showSubscriptionsDialog');
    buttonClicked({
      context: 'listing insights',
      subContext: 'listing detail page',
      buttonLabel: buttonLabelLookup[listingAction.value.listing_action_slug],
      buttonAction: 'upgrade',
    });
  }
};

// error handling
const priorityError = usePriorityError(availableCreditsError);
</script>

<template>
  <li v-if="displayListingAction" class="listing-action__wrapper">
    <component
      :is="listingActionComponentLookup[listingAction.listing_action_slug]"
      :account-id="accountId"
      :listing-action="listingAction"
      :external-product-id="externalProductId"
      :integration-id="integrationId"
    />
  </li>
  <li v-else class="result__wrapper">
    <div class="result__icon">
      <SoonaIcon :name="headerIcon" />
    </div>
    <div class="result__content">
      <div class="result__content--text">
        <span class="result__title--wrapper">
          <h4 class="result__title">
            <span class="u-body--heavy"> {{ rule.rule }}</span>
            <span v-if="displayImageCount">
              {{ qualifyingImages }}/{{ recommendImageCount }}
            </span>
          </h4>
          <SoonaIcon
            v-if="pass"
            class="result__icon--pass"
            name="circle-check-solid"
            size="small"
          />
          <SoonaFlag v-else :background-color="FriendlyRed30">
            <template #icon-left>
              <SoonaIcon
                class="result__icon--fail"
                name="status-dot-small"
                size="small"
              />
            </template>
            <template #title>
              {{ recommendationText }}
            </template>
          </SoonaFlag>
        </span>
        <p class="result__description">
          {{ rule.rule_description }}
        </p>
        <SoonaError v-if="priorityError">
          {{ priorityError }}
        </SoonaError>
      </div>
      <template v-if="displayListingActionButtons">
        <component
          :is="
            listingActionButtonsComponentLookup[
              listingAction.listing_action_slug
            ]
          "
          :account-id="accountId"
          :listing-action="listingAction"
          :external-product-id="externalProductId"
          :integration-id="integrationId"
          @capture-select-source-image-event="captureSelectSourceImageEvent"
          @handle-upgrade-click="handleUpgradeClick"
          @handle-link-source-asset="handleLinkSourceAsset($event)"
          @handle-upload-complete="handleUploadComplete($event)"
        />
        <!-- @show-subscriptions-dialog="emit('showSubscriptionsDialog', $event)"
          @optimize-triggered="optimizeTriggered = $event" -->
      </template>
    </div>
  </li>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.result {
  &__wrapper {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    border-bottom: 0.0625rem solid variables.$gray-30;
    padding: 0.5rem 1rem 1.5rem 1rem;
  }

  &__content {
    display: flex;
    width: 100%;
    gap: 1rem;
    justify-content: space-between;

    &--text {
      flex: 0 1 75%;
    }
  }

  &__title {
    display: flex;
    gap: 0.5rem;

    &--wrapper {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      gap: 0.5rem;
    }
  }

  &__icon {
    &--pass {
      color: variables.$avo-toast-40;
    }

    &--fail {
      color: variables.$friendly-red-50;
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}
</style>
