<template>
  <div class="pack-video-details">
    <p class="description" :class="view">{{ pageDescription }}</p>
    <button
      class="thumbnail-button"
      :class="view"
      @click="openVideoPreviewModal"
    >
      <img
        :src="thumbnailUrl"
        alt="thumbnail"
        class="pack-image"
        :class="view"
      />
    </button>
    <PackVideoPreviewModal
      v-if="showAddVideoPreviewModal"
      class="modal is-active"
      :vimeo-id="packVimeoId"
      @close="closePreviewModal"
    />
    <p class="page-info">
      looking to create a custom video that looks different than this example?
      build your own
      <a
        class="here-link"
        href="/book/new"
        target="_blank"
        rel="noopener noreferrer"
        @click="buildYourOwn"
        >here</a
      >
    </p>
  </div>
</template>

<script>
import PackVideoPreviewModal from './PackVideoPreviewModal.vue';
export default {
  name: 'PackVideoPreview',
  components: { PackVideoPreviewModal },
  props: {
    packVimeoId: String,
    packName: String,
    pageDescription: String,
    view: String,
  },
  data() {
    return {
      showAddVideoPreviewModal: false,
      thumbnailUrl: '',
    };
  },
  mounted() {
    this.getVimeoThumbnailUrl(this.packVimeoId);
  },
  methods: {
    buildYourOwn() {
      this.$track('Book - Build Your Own Link Clicked', {
        pack_name: this.packName,
      });
    },
    openVideoPreviewModal() {
      this.showAddVideoPreviewModal = true;
    },
    closePreviewModal() {
      this.showAddVideoPreviewModal = false;
    },
    getVimeoThumbnailUrl(packVimeoId) {
      let vimeoOembedUrl = `https://vimeo.com/api/oembed.json?url=https%3A//vimeo.com/${packVimeoId}`;
      fetch(vimeoOembedUrl)
        .then(response => response.json())
        .then(data => {
          this.thumbnailUrl = data.thumbnail_url_with_play_button;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';

.pack-video-details {
  width: 100%;
  .description {
    margin-bottom: 24px;
  }
  .description.shotlist-view {
    font-weight: 800;
    font-size: 18px;
    text-align: center;
  }
  .description,
  .page-info {
    color: variables.$black-default;
  }
  .thumbnail-button {
    border: none;
    background: none;
    margin-bottom: 24px;
    padding: 0;
    cursor: pointer;
  }
  .thumbnail-button.shotlist-view {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .pack-image:hover {
    opacity: 0.8;
  }
  .pack-image.pack-confirm {
    width: 386px;
  }
}
</style>
<style lang="scss">
@use '@/variables';

.page-info .here-link {
  text-decoration: underline;
  color: variables.$periwink-blue-60;
}
</style>
