<script setup>
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import PromotionItem from '@/components/user/anytime/admin/promotions/PromotionItem.vue';
import { useGetPromotions } from '@/queries/usePromotions';
import { computed, ref } from 'vue';
import { keepPreviousData } from '@tanstack/vue-query';

// NAVIGATION
const page = ref(1);
const itemsPerPage = ref(25);
const orderBy = ref(undefined);
const direction = ref('asc');
const viewArchived = ref(false);

const nextPage = () => {
  page.value += 1;
};

const previousPage = () => {
  page.value -= 1;
};

const toggleDirection = () => {
  if (direction.value === 'asc') {
    direction.value = 'desc';
  } else {
    direction.value = 'asc';
  }
};

const sortPromotions = field => {
  page.value = 1;

  if (orderBy.value === field) {
    toggleDirection();
  } else {
    direction.value = 'asc';
    orderBy.value = field;
  }
};

const activeOrderField = field => {
  return field == orderBy.value;
};

const toggleViewArchived = () => {
  page.value = 1;
  viewArchived.value = !viewArchived.value;
};

// PROMOTIONS
const { data: promotionsData } = useGetPromotions(
  {
    page,
    itemsPerPage: itemsPerPage,
    orderBy: orderBy,
    direction: direction,
    viewArchived: viewArchived,
  },
  {
    placeholderData: keepPreviousData,
  }
);

const promotions = computed(() => promotionsData.value?.promotions || []);
const pagination = computed(() => promotionsData.value?.pagination || {});
</script>

<template>
  <div class="promotions-list">
    <div class="promotions-list__header">
      <h2 class="promotions-list__title u-display--regular">promos</h2>
      <SoonaButton
        variation="primary"
        copy="add a promo"
        element="router-link"
        to="/admin/promotions/create"
        data-cypress="add-a-promo-btn"
      >
        <template #icon-left>
          <SoonaIcon name="plus" />
        </template>
      </SoonaButton>
    </div>

    <div class="promotions-list__list">
      <div class="promotions-list__list-rows">
        <div class="promotions-list__list-row">
          <button
            class="promotions-list__list-row-btn"
            @click.prevent="() => sortPromotions('title')"
          >
            <h3
              :class="{ active: activeOrderField('title') }"
              class="promotions-list__list-row-header"
            >
              title
              <SoonaIcon
                v-if="activeOrderField('title')"
                :name="direction === 'desc' ? 'chevron-down' : 'chevron-up'"
                size="medium"
              />
            </h3>
          </button>
        </div>
        <div id="type" class="promotions-list__list-row">
          <button
            class="promotions-list__list-row-btn"
            @click.prevent="() => sortPromotions('type')"
          >
            <h3
              :class="{ active: activeOrderField('type') }"
              class="promotions-list__list-row-header"
            >
              type
              <SoonaIcon
                v-if="activeOrderField('type')"
                :name="direction === 'desc' ? 'chevron-down' : 'chevron-up'"
                size="medium"
              />
            </h3>
          </button>
        </div>
        <div id="date-range" class="promotions-list__list-row">
          <button
            class="promotions-list__list-row-btn"
            @click.prevent="() => sortPromotions('start_at')"
          >
            <h3
              :class="{ active: activeOrderField('start_at') }"
              class="promotions-list__list-row-header"
            >
              date range
              <SoonaIcon
                v-if="activeOrderField('start_at')"
                :name="direction === 'desc' ? 'chevron-down' : 'chevron-up'"
                size="medium"
              />
            </h3>
          </button>
        </div>
        <div id="last-editor" class="promotions-list__list-row">
          <h3 class="promotions-list__list-row-header">last editor</h3>
        </div>
        <div class="promotions-list__list-row">
          <h3 class="promotions-list__list-row-header">status</h3>
        </div>
        <div class="promotions-list__list-row">
          <button
            class="promotions-list__list-row-btn"
            @click.prevent="() => sortPromotions('active')"
          >
            <h3
              :class="{ active: activeOrderField('active') }"
              class="promotions-list__list-row-header"
            >
              on/off
              <SoonaIcon
                v-if="activeOrderField('active')"
                :name="direction === 'desc' ? 'chevron-down' : 'chevron-up'"
                size="medium"
              />
            </h3>
          </button>
        </div>
        <div class="promotions-list__list-row">
          <h3 class="promotions-list__list-row-header"></h3>
        </div>
      </div>
      <PromotionItem
        v-for="promotion in promotions"
        :key="promotion.id"
        :promotion="promotion"
      />
    </div>

    <div class="promotions-list__footer">
      <div class="promotions-list__view-archived">
        <a
          class="button is-small mt-m ml-l"
          @click.prevent="() => toggleViewArchived()"
          >{{ viewArchived ? 'view non-archived' : 'view archived' }}</a
        >
      </div>
      <div class="promotions-list__pagination">
        <a
          v-if="page > 1"
          class="button is-small mt-m ml-l"
          @click.prevent="previousPage"
          >previous
        </a>
        <a
          v-if="pagination.totalPages > pagination.currentPage"
          class="button is-small mt-m ml-l"
          @click.prevent="nextPage"
          >next
        </a>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.promotions-list {
  #type,
  #date-range,
  #last-editor {
    @media (max-width: variables.$screen-xs-max) {
      display: none;
    }
  }

  &__header {
    display: flex;
    padding-bottom: 32px;
  }
  h2 {
    padding-right: 32px;
  }
  &__list {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__list-rows {
    align-items: center;
    display: flex;
    padding: 0 20px 12px 20px;
    width: 100%;
  }
  &__list-row {
    &:first-child {
      width: 25%;

      @media (max-width: variables.$screen-xs-max) {
        width: 30%;
        margin-right: 10px;
      }
    }
    &:nth-child(2) {
      width: 15%;
    }
    &:nth-child(3) {
      width: 18%;
    }
    &:nth-child(4) {
      width: 18%;
    }
    &:nth-child(5) {
      width: 10%;

      @media (max-width: variables.$screen-xs-max) {
        width: 25%;
        margin-right: 10px;
      }
    }
    &:nth-child(6) {
      width: 7%;

      @media (max-width: variables.$screen-xs-max) {
        width: 10%;
      }
    }
    &:nth-child(7) {
      width: 5%;
    }
  }
  &__list-row-btn {
    @include variables_fonts.u-label--regular;

    align-items: center;
    background: transparent;
    border: none;
    color: variables.$gray-50;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    padding: 12px;
    padding-left: 0;
    position: relative;
    transition: 0.3s;
    &:hover {
      color: variables.$periwink-blue-60;
    }
    &--active {
      &:after {
        border-bottom: 8px solid variables.$gray-50;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        content: '';
        height: 0;
        position: absolute;
        right: -2px;
        width: 0;
      }
    }
  }

  &__list-row-header {
    @include variables_fonts.u-label--regular;

    align-items: center;
    background: transparent;
    border: none;
    color: variables.$gray-50;
    display: flex;
    justify-content: flex-start;
    padding-bottom: 12px;
    padding-left: 0;
    position: relative;
  }

  .active {
    color: variables.$friendly-red-50;
    font-weight: 800;
    text-decoration: underline;

    .header-chevron {
      margin-left: 5px;
      transition: transform 300ms ease-out;
    }
  }

  &__footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}
</style>
