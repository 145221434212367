<script setup>
import { computed, ref } from 'vue';
import { convertToHHMM12, convertToMMDDYY } from '@/lib/date-formatters';
import { useCapability } from '@/composables/useCapability';
import { useDeleteShot } from '@/queries/useShotList';
import { usePriorityError } from '@/composables/usePriorityError';
import { useReservation } from '@/composables/useReservation';
import { useUpdateShot } from '@/queries/useShotList';
import {
  BubbletapePink30,
  BubbletapePink80,
  FriendlyRed50,
  Tangerine30,
  Tangerine80,
} from '@/variables.module.scss';
import SceneDetails from '@/components/user/anytime/scene_selector/SceneDetails.vue';
import SceneProducts from '@/components/user/anytime/scene_selector/SceneProducts.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaFlag from '@/components/ui_library/SoonaFlag.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';
import SoonaToggle from '@/components/ui_library/SoonaToggle.vue';
import SoonaTooltip from '@/components/ui_library/SoonaTooltip.vue';
import CustomerScene from './scenes/CustomerScene.vue';

const props = defineProps({
  isEditable: {
    type: Boolean,
    default: false,
  },
  isPack: {
    type: Boolean,
    default: false,
  },
  reservationAccountId: {
    required: true,
    type: [Number, String],
  },
  reservationId: {
    required: true,
    type: [Number, String],
  },
  scene: {
    required: true,
    type: Object,
  },
  submittedAt: {
    default: null,
    type: String,
  },
});

defineEmits(['duplicate']);

const { hasCapability: isFtSoonaStaff } = useCapability({
  capability: 'ft_soona_staff',
});

const reservationId = computed(() => props.reservationId);
const scene = computed(() => props.scene);

const showDeleteDialog = ref(false);
const editScene = ref(false);

const {
  mutate: deleteShot,
  isPending: isLoadingDeleteShot,
  error: deleteShotError,
} = useDeleteShot(reservationId);

const { isInProgress, isWrapped } = useReservation(reservationId);

const isLoading = computed(() => isLoadingDeleteShot.value);

const handleDelete = () => {
  showDeleteDialog.value = false;

  deleteShot({ shotId: scene.value.id });
};

const sceneType = computed(() => {
  const typeTitle = scene.value.shot_tags.find(
    tag => tag.tag.tag_category_title === 'Content'
  )?.tag.title;

  if (typeTitle === 'Photo') return 'camera';
  return 'video';
});

const formattedDate = computed(() => convertToMMDDYY(scene.value.updated_at));
const formattedTime = computed(() => convertToHHMM12(scene.value.updated_at));

const isCreatedAfterSubmission = computed(() => {
  if (!props.submittedAt) return false;
  return new Date(scene.value.created_at) > new Date(props.submittedAt);
});

const isEditedSinceSubmission = computed(() => {
  if (!props.submittedAt) return false;
  return new Date(scene.value.updated_at) > new Date(props.submittedAt);
});

const {
  mutate: updateShot,
  isPending: isUpdatingShot,
  error: updateShotError,
} = useUpdateShot(reservationId);

const handleToggleSceneComplete = () => {
  updateShot({
    id: scene.value?.id,
    status: scene.value?.status === 'complete' ? 'incomplete' : 'complete',
    shot_tags_attributes: scene.value?.shot_tags.map(tag => ({
      tag_id: tag.tag_id,
    })),
  });
};

const priorityError = usePriorityError(deleteShotError, updateShotError);
</script>

<template>
  <CustomerScene
    v-if="editScene"
    class="scene-card__edit"
    :active-scene-id="scene.id"
    :reservation-id="reservationId"
    @exit-scene-view="editScene = false"
  />
  <article
    v-else
    class="scene-card"
    :aria-labelledby="`scene-${scene.id}-heading`"
  >
    <SoonaLoading v-if="isLoading || isUpdatingShot" is-loading is-contained />
    <SoonaError v-if="priorityError" class="scene-selector__error">
      {{ priorityError }}
    </SoonaError>
    <div class="scene-card__header">
      <div class="scene-card__heading">
        <slot name="heading-start" />
        <h3 :id="`scene-${scene.id}-heading`" class="u-subheader--heavy">
          scene {{ scene.order }}
        </h3>
        <SoonaIcon :name="sceneType" size="medium" />
      </div>
      <template v-if="isEditable && !isPack">
        <div class="scene-card__action-btns">
          <SoonaButton
            size="medium"
            variation="tertiary"
            @on-click="showDeleteDialog = true"
          >
            <SoonaIcon name="trash" /> delete
          </SoonaButton>
          <SoonaButton
            size="medium"
            variation="tertiary"
            @on-click="$emit('duplicate', scene)"
          >
            <SoonaIcon name="clone" /> duplicate
          </SoonaButton>
          <SoonaButton
            size="medium"
            variation="tertiary"
            @on-click="editScene = true"
          >
            <SoonaIcon name="pen-square" /> edit
          </SoonaButton>
        </div>
        <div class="scene-card__icon-btns">
          <SoonaTooltip>
            <template
              #default="{ setRef, mouseenter, focus, mouseleave, blur }"
            >
              <SoonaButton
                :ref="el => setRef(el)"
                aria-label="delete"
                variation="icon-plain-gray"
                @on-click="showDeleteDialog = true"
                @mouseenter="mouseenter"
                @focus="focus"
                @mouseleave="mouseleave"
                @blur="blur"
              >
                <SoonaIcon name="trash" />
              </SoonaButton>
            </template>
            <template #tooltip-content>delete this scene</template>
          </SoonaTooltip>
          <SoonaTooltip>
            <template
              #default="{ setRef, mouseenter, focus, mouseleave, blur }"
            >
              <SoonaButton
                :ref="el => setRef(el)"
                aria-label="duplicate"
                variation="icon-plain-gray"
                @on-click="$emit('duplicate', scene)"
                @mouseenter="mouseenter"
                @focus="focus"
                @mouseleave="mouseleave"
                @blur="blur"
              >
                <SoonaIcon name="clone" />
              </SoonaButton>
            </template>
            <template #tooltip-content>duplicate this scene</template>
          </SoonaTooltip>
          <SoonaTooltip>
            <template
              #default="{ setRef, mouseenter, focus, mouseleave, blur }"
            >
              <SoonaButton
                :ref="el => setRef(el)"
                aria-label="edit"
                variation="icon-plain-gray"
                @on-click="editScene = true"
                @mouseenter="mouseenter"
                @focus="focus"
                @mouseleave="mouseleave"
                @blur="blur"
              >
                <SoonaIcon name="pen-square" />
              </SoonaButton>
            </template>
            <template #tooltip-content>edit this scene</template>
          </SoonaTooltip>
        </div>
      </template>
      <SoonaToggle
        v-else-if="isFtSoonaStaff && (isInProgress || isWrapped)"
        class="scene-card__complete-scene-toggle"
        :model-value="scene.status === 'complete'"
        :disabled="isUpdatingShot || isWrapped"
        label="complete scene"
        type="checkbox"
        hide-visual-label
        @update:model-value="handleToggleSceneComplete"
      />
    </div>
    <div class="scene-card__content">
      <SceneDetails :selected-scene="scene" />
      <SceneProducts
        :account-id="reservationAccountId"
        :selected-scene="scene"
      />
    </div>
    <div class="scene-card__edited">
      <p v-if="!isPack" class="scene-card__edited--timestamp u-label--regular">
        edited on {{ formattedDate }} at {{ formattedTime }}
      </p>
      <SoonaFlag
        v-if="isCreatedAfterSubmission"
        :background-color="BubbletapePink30"
        :text-color="BubbletapePink80"
        title="new"
        type="pill"
      />
      <SoonaFlag
        v-else-if="isEditedSinceSubmission"
        :background-color="Tangerine30"
        :text-color="Tangerine80"
        title="edited"
        type="pill"
      />
    </div>
  </article>
  <SoonaDialog
    v-if="showDeleteDialog"
    role="alertdialog"
    :icon-color="FriendlyRed50"
    icon-name="circle-exclamation-solid"
    @close="showDeleteDialog = false"
  >
    <template #heading>are you sure you want to delete this scene?</template>
    <template #footer="{ close }">
      <SoonaButton variation="tertiary" @on-click="close">cancel</SoonaButton>
      <SoonaButton @on-click="handleDelete">yes, delete my scene</SoonaButton>
    </template>
  </SoonaDialog>
</template>

<style lang="scss" scoped>
@use '@/variables';

.scene-card {
  background-color: variables.$white-default;
  border: 0.0625rem solid variables.$gray-30;
  border-radius: 0.625rem;
  box-shadow: variables.$elevation-0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem;
  position: relative;

  &__edit {
    margin-top: 0;
  }

  &__header {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-between;
  }

  &__heading {
    align-items: center;
    display: flex;
    gap: 0.25rem;

    svg {
      color: variables.$gray-60;
    }
  }

  &__action-btns {
    display: none;
  }

  &__icon-btns {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }

  &__complete-scene-toggle {
    font-size: 1.875rem;
    & :deep(input) {
      margin: 0;
    }
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    & > * {
      flex: 1 1 20rem;
    }
  }

  &__edited {
    align-items: center;
    align-self: flex-end;
    display: flex;
    gap: 1rem;

    &--timestamp {
      color: variables.$gray-70;
    }
  }

  @media (min-width: variables.$screen-sm-min) {
    &__action-btns {
      display: flex;
      gap: 1rem;
    }

    &__icon-btns {
      display: none;
    }
  }
}
</style>
