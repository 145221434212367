<script setup>
import { computed, ref, toRefs } from 'vue';
import { useNotes } from '@/queries/notes/useNotes';
import { usePriorityErrors } from '@/composables/usePriorityErrors';
import NoteCreate from './NoteCreate.vue';
import NoteDisplay from '@/components/notes/NoteDisplay.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

const props = defineProps({
  digitalAssetId: {
    type: [Number, String],
    default: null,
  },
});

const { digitalAssetId } = toRefs(props);

const itemsPerPage = ref(10);
const currentPage = ref(1);
const {
  data: notesData,
  isLoading,
  error,
} = useNotes(
  'digital_assets',
  digitalAssetId,
  {
    currentPage,
    itemsPerPage,
  },
  {
    enabled: computed(() => !!digitalAssetId.value),
  }
);

const notes = computed(() => notesData.value?.notes ?? []);

// const notesPagination = computed(() => {
//   if (notesData.value) {
//     return notesData.value.pagination;
//   } else {
//     return {
//       totalCount: 0,
//       currentPage: 0,
//       totalPages: 0,
//       itemsPerPage: 0,
//     };
//   }
// });

const priorityErrors = usePriorityErrors(error);
</script>

<template>
  <div class="digital-asset-notes">
    <SoonaLoading v-if="isLoading" is-loading is-contained />
    <SoonaError
      v-if="priorityErrors"
      :priority-errors="priorityErrors"
      no-margin
    />
    <div v-if="notes && notes?.length < 1" class="digital-asset-notes__empty">
      <SoonaIcon name="message-circle-lines-alt" />
      <p class="u-label--heavy">no comments on the current version</p>
    </div>
    <template v-for="note of notes" :key="note.id">
      <NoteDisplay
        :note="note"
        subject-type="digital_assets"
        :subject-id="digitalAssetId"
      />
    </template>
    <NoteCreate subject-type="digital_assets" :subject-id="digitalAssetId" />
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.digital-asset-notes {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  overflow-y: auto;

  &__empty {
    display: flex;
    padding: 1.5rem 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    align-self: stretch;
    background: variables.$gray-10;
    border-radius: 0.625rem;
    color: variables.$gray-60;
    text-align: center;
  }
}
</style>
