<script setup>
import { useStore } from 'vuex';
import { computed } from 'vue';
import { useSiteNavEvents } from '../useSiteNavEvents';
import AccountsList from './AccountsList.vue';
import SoonaUserAvatar from '@/components/ui_library/SoonaUserAvatar.vue';
import SidebarLink from './SidebarLink.vue';
import SidebarList from './SidebarList.vue';
import SidebarTermsLink from './SidebarTermsLink.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { useAccount } from '@/composables/useAccount';
import { useCapability } from '@/composables/useCapability';
import { useFlag } from '@/composables/useFlag';
import { useMe } from '@/composables/user/useMe';
import SidebarSignOutButton from '@/components/platform-layout/sidebar/SidebarSignOutButton.vue';

defineEmits(['close']);

const store = useStore();

const { currentAccountId, me } = useMe();
const { account: currentAccount } = useAccount(currentAccountId);
const isSoftSignUp = computed(() => store.getters['currentUser/isSoftSignUp']);
const roles = computed(() => store.getters['currentUser/roles']);
const isShopifyUser = computed(() => roles.value?.includes('shopify'));
const orderedAccounts = computed(
  () => store.getters['currentUser/orderedAccounts']
);

const { trackLinkClicked } = useSiteNavEvents();

const { hasCapability: manage_collaborators } = useCapability({
  capability: 'manage_collaborators',
  subjectType: 'account',
  subjectId: currentAccountId,
});

const apolloCreateAccount = useFlag('apollo_create_account');
</script>

<template>
  <nav class="user-menu" aria-label="user">
    <div class="user-menu__top">
      <SoonaButton
        size="large"
        variation="tertiary"
        class="user-menu__back-button"
        @on-click="$emit('close')"
      >
        <SoonaIcon name="arrow-left" class="user-menu__back-button__icon" />
        main menu
      </SoonaButton>
    </div>
    <p
      v-if="!apolloCreateAccount && orderedAccounts?.length < 2"
      class="user-menu__profile"
    >
      <SoonaUserAvatar
        class="user-account-avatar"
        :src="currentAccount?.avatar_url"
        img-size="2rem"
        svg-size="2rem"
      />
      <span class="user-menu__profile__container">
        <span class="user-menu__profile__account-name">
          <span class="u-a11y-only">current account:&nbsp;</span>
          {{ currentAccount?.name }}
        </span>
        <span class="user-menu__profile__user-name">
          <span class="u-a11y-only">current user:&nbsp;</span>
          {{ me?.name }}
        </span>
      </span>
    </p>
    <AccountsList
      v-else
      :account-name="currentAccount?.name"
      :ordered-accounts="orderedAccounts"
      :user-avatar-src="currentAccount?.avatar_url"
      :user-name="me?.name"
    />
    <SidebarList class="user-menu__user-links-list" aria-label="user links">
      <SidebarLink
        v-if="manage_collaborators"
        :to="`/account/${currentAccountId}/collaborators`"
        sidebar-icon="user-plus-alt-1"
        @click="trackLinkClicked('team members', '/account/collaborators')"
      >
        team members
      </SidebarLink>
      <SidebarLink
        :to="`/account/${currentAccountId}/profile`"
        sidebar-icon="briefcase"
        data-cypress="account-profile-link"
        @click="trackLinkClicked('account profile', '/account/profile')"
      >
        account settings
      </SidebarLink>
      <SidebarLink
        v-if="!isSoftSignUp && !isShopifyUser"
        to="/user-settings"
        sidebar-icon="user-alt-1"
        @click="trackLinkClicked('user settings', `/user-settings`)"
      >
        user settings
      </SidebarLink>
      <SidebarLink
        :to="`/account/${currentAccountId}/subscriptions`"
        sidebar-icon="crown"
        :internal-custom-active="
          ({ path }) =>
            path.startsWith(`/account/${currentAccountId}/subscriptions`)
        "
        @click="trackLinkClicked('manage plan', '/account/subscriptions')"
      >
        manage plan
      </SidebarLink>
      <SidebarLink
        :to="`/account/${currentAccountId}/orders`"
        sidebar-icon="dollar-sign"
        @click="trackLinkClicked('billing history', '/account/orders')"
      >
        billing history
      </SidebarLink>
      <SidebarSignOutButton />
    </SidebarList>

    <SidebarList class="user-menu__terms-list" aria-label="terms links">
      <SidebarTermsLink href="https://support.soona.co">
        help center
      </SidebarTermsLink>
      <SidebarTermsLink href="https://soona.co/terms">
        terms of use
      </SidebarTermsLink>
      <SidebarTermsLink href="https://soona.co/privacy-policy">
        privacy policy
      </SidebarTermsLink>
      <SidebarTermsLink href="https://soona.co">soona.co</SidebarTermsLink>
    </SidebarList>
  </nav>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.user-menu {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
  overflow-y: auto;
  padding-top: 1rem;
  outline-offset: -3px; /* browser outlines are generally in pixels */

  &__top {
    display: flex;
    padding: 0.75rem 1rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.75rem;
    align-self: stretch;
    margin-bottom: 1rem;
  }

  &__back-button {
    &__icon {
      @media screen and (prefers-reduced-motion: no-preference) {
        transition: transform 0.1s ease-out;
      }
    }

    &:hover,
    &:focus-visible {
      .user-menu__back-button__icon {
        transform: translateX(-0.1875rem);
      }
    }
  }

  &__profile {
    display: flex;
    padding: 0.625rem 1rem 0.625rem 1.25rem;
    align-items: center;
    gap: 0.5rem;
    align-self: stretch;

    &__container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex: 1 0 0;
    }

    &__account-name {
      @include variables_fonts.u-label--heavy;
    }

    &__user-name {
      @include variables_fonts.u-small--regular;
    }
  }

  .user-account-avatar {
    color: variables.$black-default;
  }

  &__user-links-list {
    padding: 0.5rem;
  }

  &__terms-list {
    padding: 1rem;
    margin-top: auto;
  }
}
</style>
