<script setup>
import { useInfiniteEditsCollectionDigitalAssets } from '@/queries/edits_collection_digital_assets/useInfiniteEditsCollectionDigitalAssets';
import { computed, ref } from 'vue';
import { useInfiniteDigitalAssets } from '@/composables/useInfiniteDigitalAssets';
import SmallInfiniteGallery from '@/components/infinite_asset_gallery/SmallInfiniteGallery.vue';
import EditsCollectionDigitalAssetCard from '@/components/user/anytime/reservation/gallery/edits/EditsCollectionDigitalAssetCard.vue';
import AssignOriginalDialog from '@/components/user/anytime/reservation/gallery/edits/AssignOriginalDialog.vue';
import DownloadAll from '@/components/user/anytime/reservation/gallery/edits/DownloadAll.vue';
import { useEditsCollectionDigitalAssetsFacets } from '@/queries/edits_collection_digital_assets/useEditsCollectionDigitalAssetsFacets';
import { useDownloadFacets } from '@/composables/digital_assets/useDownloadFacets';
import CanvaButton from '@/components/shared/CanvaButton.vue';
import { useFlag } from '@/composables/useFlag';
import { useInfiniteGalleryWrapper } from '@/components/infinite_asset_gallery/useInfiniteGalleryWrapper';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
  collectionId: {
    type: [String, Number],
    required: true,
  },
  reservationId: {
    type: [String, Number],
    required: true,
  },
});

const canvaButtonEnabled = useFlag('apollo_canva_button');

const collectionId = computed(() => props.collectionId);
const reservationId = computed(() => props.reservationId);

const wrapper = ref();
const itemsPerPage = ref(30);

const { gutter, rowHeight, rowWidth } = useInfiniteGalleryWrapper({
  wrapperEl: wrapper,
  heightRem: 12.5,
  gapRem: 0.75,
});

const filters = computed(() => ({
  reservation_id: reservationId.value,
  edit_status: 'accepted',
  visibility: 'all',
}));

const {
  assetRows,
  fetchPage,
  isLoading: isLoadingAssets,
} = useInfiniteDigitalAssets(collectionId, {
  rowWidth,
  itemsPerPage,
  gutter,
  height: rowHeight,
  filters,
  query: useInfiniteEditsCollectionDigitalAssets,
});

// edits collection digital asset facets
const { showWebDownload } = useDownloadFacets([collectionId], {
  itemPerPage: 1,
  facets: 1,
  filters,
  query: useEditsCollectionDigitalAssetsFacets,
});

// assign original
const showOriginalDialog = ref(false);
const assignedEdit = ref(null);

const toggleShowOriginalDialog = (val, ecda) => {
  showOriginalDialog.value = val;

  assignedEdit.value = showOriginalDialog.value ? ecda?.digital_asset : null;
};

// end assign original

function generateRouteLocation(editsCollectionDigitalAsset) {
  if (editsCollectionDigitalAsset.placeholder) return {};

  return {
    name: 'reservation-asset-edit-view',
    params: {
      reservationId: reservationId.value,
      digitalAssetId: editsCollectionDigitalAsset.digital_asset.id,
    },
  };
}
</script>

<template>
  <div v-if="!isLoadingAssets && assetRows.length > 0" ref="wrapper">
    <div class="completed-edits__header">
      <h3 class="u-body--heavy">your edits</h3>
      <div class="completed-edits__header-right">
        <CanvaButton v-if="canvaButtonEnabled" />
        <DownloadAll
          :show-web="showWebDownload"
          :account-id="accountId"
          :reservation-id="reservationId"
        />
      </div>
    </div>
    <SmallInfiniteGallery
      v-slot="{ data }"
      :rows="assetRows"
      :initial-scroll-position="0"
      :height="rowHeight"
      :gap="gutter"
    >
      <EditsCollectionDigitalAssetCard
        v-for="ecda in data.assets"
        :key="ecda.id"
        :flex-grow="data.width / rowWidth > 0.6 ? 1 : 0"
        :edits-collection-digital-asset="ecda"
        :to="generateRouteLocation(ecda)"
        @request-page="pageNumber => fetchPage(pageNumber)"
        @open-assign-original-dialog="toggleShowOriginalDialog(true, ecda)"
      />
    </SmallInfiniteGallery>
    <AssignOriginalDialog
      v-if="showOriginalDialog"
      :account-id="accountId"
      :reservation-id="reservationId"
      :digital-asset-id="assignedEdit?.id"
      @close="() => toggleShowOriginalDialog(false, null)"
    />
  </div>
</template>

<style lang="scss" scoped>
.completed-edits {
  &__header {
    display: flex;
    flex-flow: row wrap;
    gap: 0.5rem 1rem;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
  }

  &__header-right {
    display: flex;
    flex-flow: row wrap;
    gap: 0.5rem;
    align-items: center;
  }
}
</style>
