<script setup>
import { computed } from 'vue';
import SoonaVimeoPlayer from '../../SoonaVimeoPlayer.vue';

const props = defineProps({
  vimeoId: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['close']);

const vimeoId = computed(() => props.vimeoId);
</script>

<template>
  <div class="modal-background">
    <div class="modal-content">
      <SoonaVimeoPlayer :vimeo-id="vimeoId" @close="emit('close')" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal-content {
  display: flex;
  align-items: center !important;
  justify-content: center;
  position: relative;
  height: 100%;
  overflow: visible;
  margin: 0 auto !important;
  padding: 0 !important;
}
</style>
