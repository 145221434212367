<script setup>
// TODO: APL-1166
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { PeriwinkBlue50 } from 'src/variables.module.scss';
import BenefitList from 'src/components/modal-payment-flows/preferred/BenefitList.vue';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import StepLayout from 'src/components/modal-payment-flows/preferred/StepLayout.vue';

const props = defineProps({
  context: {
    type: String,
  },
  subContext: {
    type: String,
  },
});

const emits = defineEmits(['close', 'next']);

const { linkClicked } = useBaseEvents();

const benefitList = [
  {
    icon: 'chart-simple',
    title: 'listing insights',
    description:
      'integrate your Amazon storefront and receive creative recommendations for your product listings.',
  },
  {
    icon: 'wand-magic-sparkles',
    title: 'media editor',
    description:
      'multiply a single asset into many with our AI-powered editing tools.',
  },
];

const hubSpotCalendarLink = 'https://soona.co/contact-sales';

const handleContinueClick = () => {
  linkClicked({
    context: props.context,
    subContext: props.subContext,
    linkLabel: 'buy credits - get immediate access',
    linkHref: null,
  });

  emits('next');
};

const handleHubSpotClick = () => {
  linkClicked({
    context: props.context,
    subContext: props.subContext,
    linkLabel: 'talk with our team',
    linkHref: hubSpotCalendarLink,
  });
};
</script>

<template>
  <StepLayout
    class="waitlist-step"
    :right-column-bg-color="PeriwinkBlue50"
    :show-back-button="true"
    @back="emits('close')"
    @close="emits('close')"
    @next="emits('next')"
  >
    <template #heading>
      join preferred to unlock our beta AI tools ✨
    </template>

    <BenefitList
      class="waitlist-step__benefits"
      :benefit-list="benefitList"
      has-larger-text
    />
    <div class="waitlist-step__action-btns">
      <p class="u-subheader--heavy">interested in our beta features?</p>
      <SoonaButton
        element="a"
        copy="talk with our team"
        :href="hubSpotCalendarLink"
        rel="noopener noreferrer"
        target="_blank"
        data-cypress="button-bulk-credits-modal-hubsopot-calendar"
        @on-click="handleHubSpotClick"
      />
      <p class="u-body--heavy waitlist-step__fancypants-copy">or</p>
      <SoonaButton
        copy="buy credits - get immediate access"
        variation="secondary-black"
        @on-click="handleContinueClick"
      />
    </div>
    <template #main-image>
      <video class="waitlist-step__video" autoplay loop playsinline muted>
        <source src="@images/bulk-credits/tools_promo.mp4" type="video/mp4" />
      </video>
    </template>
  </StepLayout>
</template>

<style lang="scss" scoped>
@use '@/variables';

.waitlist-step {
  &__benefits {
    flex-grow: 1;
  }

  &__action-btns {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: auto;
    padding-top: 2rem;
  }

  &__fancypants-copy {
    color: variables.$gray-40;
    position: relative;
    text-align: center;

    &::before,
    &::after {
      background-color: variables.$gray-30;
      content: '';
      height: 0.063rem;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: calc(50% - 20px);
    }

    &::before {
      left: 0;
    }

    &::after {
      right: 0;
    }
  }

  &__video {
    height: 100%;
    object-fit: cover;
  }
}
</style>
