import { http } from '../config/vue-axios';

/**
 *
 * @param {Object} reservationFile
 * @param {Object} [body]
 */
export async function updateReservationFile({
  reservationId,
  reservationFileId,
  body,
}) {
  const response = await http.put(
    `/reservations/${reservationId}/reservation_files/${reservationFileId}`,
    {
      reservation_file: body,
    }
  );

  return response.data;
}

/**
 *
 * @param {Object} reservationFile
 * @param {Number} rotation
 */
export async function rotate({ reservationId, reservationFileId, rotation }) {
  const response = await http.put(
    `/reservations/${reservationId}/reservation_files/${reservationFileId}/rotate.json`,
    {
      reservation_file: { rotation },
    }
  );

  return response.data;
}

/**
 *
 * @param {number} reservationId
 * @param {Object} [body]
 */
export async function createReservationFile({ reservationId, body }) {
  const response = await http.post(
    `/reservations/${reservationId}/reservation_files`,
    { reservation_file: body }
  );

  return response.data;
}
