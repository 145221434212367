<script setup>
import { computed, ref, watchEffect } from 'vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import GifPickerOption from './GifPickerOption.vue';
import SoonaForm from '@/components/ui_library/SoonaForm.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';
import GifPreview from '@/components/user/anytime/gifs/preview/GifPreview.vue';
import GifDimension from '@/components/user/anytime/gifs/settings/GifDimension.vue';
import GifFormat from '@/components/user/anytime/gifs/settings/GifFormats.vue';
import GifPlayback from '@/components/user/anytime/gifs/settings/GifPlayback.vue';
import GifSpeed from '@/components/user/anytime/gifs/settings/GifSpeed.vue';
import { useCreateAnimatedCollection } from '@/queries/animated_collections/useCreateAnimatedCollection';
import SoonaLoading from '@/components/SoonaLoading.vue';
import { useInfiniteAnimatedCollections } from '@/queries/animated_collections/useInfiniteAnimatedCollections';
import { useUpdateAnimatedCollection } from '@/queries/animated_collections/useUpdateAnimatedCollection';
import { usePriorityErrors } from '@/composables/usePriorityErrors';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import { useMe } from '@/composables/user/useMe';
import { useRouter } from 'vue-router';

const props = defineProps({
  accountId: {
    type: [Number, String],
    required: true,
  },
  reservationId: {
    type: [Number, String],
    required: true,
  },
  selectedAssets: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(['close', 'cancel']);
const router = useRouter();

const close = () => {
  emit('close');
};

const accountId = computed(() => props.accountId);
const { currentUserId: authedUserId } = useMe();

const localSelectedAssets = ref([]);
watchEffect(() => {
  localSelectedAssets.value = props.selectedAssets;
});

const nonPhotosSelectedForGif = computed(() => {
  return localSelectedAssets.value.some(da => da.media_type !== 'photo');
});
const hiddenFilesSelectedForGif = computed(() => {
  return localSelectedAssets.value.some(da => da.visibility !== 'all');
});

const {
  data: animatedCollectionPages,
  fetchNextPage,
  hasNextPage,
} = useInfiniteAnimatedCollections(accountId, {
  itemsPerPage: 5,
});

const animatedCollections = computed(() =>
  (animatedCollectionPages.value?.pages || []).flatMap(
    page => page.animated_collections
  )
);

const customError = ref(undefined);

const redirectToEditAnimatePage = collectionId => {
  const query = {};
  if (props.reservationId) query.reservation_id = props.reservationId;

  router.push({
    name: 'edit-animation',
    params: {
      accountId: accountId.value,
      collectionId: collectionId,
    },
    query,
  });
};

const { mutate: updateGifFiles, error: updateGifFilesError } =
  useUpdateAnimatedCollection(accountId);

const bulkAddToGif = collectionId => {
  if (nonPhotosSelectedForGif.value) {
    customError.value = { message: 'can only add photos to gif animation' };
  }
  if (hiddenFilesSelectedForGif.value) {
    customError.value = {
      message: 'can only add visible files to gif animation',
    };
  }

  if (customError.value) return;

  updateGifFiles(
    {
      collectionId: collectionId,
      body: {
        animated_collection_digital_assets_attributes:
          localSelectedAssets.value?.map(da => ({
            digital_asset_id: da.id,
            added_by_user_id: authedUserId.value,
          })),
      },
    },
    {
      onSuccess: ({ id }) => {
        emit('cancel');
        redirectToEditAnimatePage(id);
      },
    }
  );
};

// create gif module
const createMode = ref(false);

const title = ref('');
const dimension = ref('square');
const formats = ref(['gif']);
const playback = ref('loop');
const speed = ref(666.66);

const resetOptions = () => {
  createMode.value = false;
  title.value = '';
  dimension.value = 'as_is';
  formats.value = ['gif'];
  playback.value = 'loop';
  speed.value = 666.66;
};

const { mutate, isPending } = useCreateAnimatedCollection(accountId);

const createGif = event => {
  const data = new FormData(event.target);
  const title = data.get('title');
  const options = {
    dimensions: data.get('dimension'),
    direction: data.get('playback'),
    format: data.getAll('formats'),
    speed: Number(data.get('speed')),
  };

  mutate(
    {
      account_id: accountId.value,
      title,
      options,
      animated_collection_digital_assets_attributes:
        localSelectedAssets.value?.map((asset, idx) => ({
          digital_asset_id: asset.id,
          added_by_user_id: authedUserId.value,
          order: idx + 1,
        })),
    },
    {
      onSuccess: ({ id }) => {
        resetOptions();
        emit('cancel');
        redirectToEditAnimatePage(id);
      },
    }
  );
};

const isInvalidGif = computed(() => {
  return (
    isPending.value ||
    !title.value ||
    !localSelectedAssets.value.length ||
    !formats.value.length
  );
});

const updateSelectedAssets = digitalAssets => {
  // we need to update the original RDA list with the provided DA list
  const newDAOrderIds = digitalAssets.map(da => da.id);

  localSelectedAssets.value = localSelectedAssets.value
    ?.filter(da => newDAOrderIds.includes(da.id))
    .sort((a, b) => newDAOrderIds.indexOf(a.id) - newDAOrderIds.indexOf(b.id));
};

const gifPreviewAssets = computed(() => {
  return localSelectedAssets.value?.map(da => ({
    ...da,
    asset_url: `/reservation/${props.reservationId}/asset/${da.id}`,
  }));
});

const priorityErrors = usePriorityErrors(updateGifFilesError, customError);
</script>

<template>
  <SoonaDialog max-width="48rem" top-affixed @close="close">
    <template #heading>
      {{ createMode ? 'create new gif' : 'add to gif' }}
    </template>
    <template #header>
      <div v-if="$slots.header" class="gif-picker__header-slot">
        <slot name="header" />
      </div>
    </template>

    <SoonaError v-if="priorityErrors" :priority-errors="priorityErrors" />

    <div v-if="createMode" class="gif-picker__create">
      <SoonaForm id="new-gif-form" @submit="createGif">
        <SoonaLoading
          v-if="isPending"
          is-contained
          is-loading
          loading-text="creating gif"
        />
        <SoonaTextfield
          v-model="title"
          class="gif-title-field"
          type="text"
          name="title"
          label="title"
          placeholder="type a title"
        />
        <div class="left-col">
          <GifPreview
            :selected-assets="gifPreviewAssets"
            :dimension="dimension"
            :playback="playback"
            :speed="speed"
            @on-update-selected-assets="updateSelectedAssets"
          />
          <!-- speed -->
          <GifSpeed v-model="speed" />
        </div>
        <div class="right-col">
          <!-- dimensions -->
          <GifDimension v-model="dimension" />
          <!-- format -->
          <GifFormat v-model="formats" />
          <!-- playback -->
          <GifPlayback v-model="playback" />
        </div>
      </SoonaForm>
    </div>

    <template v-else>
      <SoonaButton
        :disabled="selectedAssets.length < 2"
        variation="secondary-gray"
        size="medium"
        class="gif-picker__add-gif-button"
        data-cypress="button-open-create-gif-form"
        @on-click="createMode = true"
      >
        <SoonaIcon name="plus-large" />
        create new gif
      </SoonaButton>
      <div v-if="animatedCollections.length > 0" class="gif-picker__options">
        <p>or choose existing</p>
        <GifPickerOption
          v-for="gif in animatedCollections"
          :key="gif.id"
          :gif="gif"
          @select="bulkAddToGif"
        />
        <div v-if="hasNextPage" class="load-more-button-container">
          <SoonaButton
            variation="secondary-black"
            size="medium"
            @click="fetchNextPage"
          >
            load more
          </SoonaButton>
        </div>
      </div>
    </template>

    <template v-if="createMode" #footer>
      <SoonaButton
        copy="cancel"
        variation="tertiary"
        @click.prevent="resetOptions"
      />
      <SoonaButton
        form="new-gif-form"
        type="submit"
        copy="create"
        :disabled="isInvalidGif"
      />
    </template>
  </SoonaDialog>
</template>

<style lang="scss" scoped>
@use '@/variables';

.gif-picker {
  &__add-gif-button {
    width: 100%;
    margin-bottom: 1.5rem;

    svg {
      color: variables.$gray-60;
    }
  }

  &__options {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;

    p {
      text-align: center;
      margin-bottom: 1.25rem;
    }
  }
}

#new-gif-form {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  & .soona-textfield {
    width: 100%;
  }
  & .left-col {
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  & .right-col {
    width: 38%;
    padding: 0 0.75rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
}
</style>
