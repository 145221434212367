<script setup>
import { computed, ref, watchEffect, onMounted } from 'vue';
import { onKeyStroke } from '@vueuse/core';
import { useAccount } from '@/composables/useAccount';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useTeleportTo } from '@/composables/useTeleportTo';
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap';
import { useDialogSoonaSelect } from '@/composables/useDialogSoonaSelect';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { useTiers } from '@/queries/tiers/useTiers';
import BillingCycleStep from '@/components/modal-payment-flows/subscriptions/steps/BillingCycleStep.vue';
import CheckoutStep from '@/components/modal-payment-flows/subscriptions/steps/CheckoutStep.vue';
import CongratsStep from '@/components/modal-payment-flows/subscriptions/steps/CongratsStep.vue';
import LandingStep from '@/components/modal-payment-flows/subscriptions/steps/LandingStep.vue';
import PricingStep from '@/components/modal-payment-flows/subscriptions/steps/PricingStep.vue';
import PlatformTrialLandingStep from '@/components/modal-payment-flows/subscriptions/steps/PlatformTrialLandingStep.vue';
import BookingTrialPromoInformation from '@/components/modal-payment-flows/subscriptions/steps/BookingTrialPromoInformation.vue';
import uniqueId from 'lodash/uniqueId';
import { useDialogContext } from '@/composables/useDialog';
import { useFlag } from '@/composables/useFlag';

const props = defineProps({
  flow: {
    default: 'full',
    type: String,
  },
  priceInterval: {
    default: 'year',
    type: String,
    validator: function (value) {
      return ['month', 'year'].includes(value);
    },
  },
  reservationId: {
    default: null,
    type: String,
  },
  selectedTierSlug: {
    default: 'tier-one',
    type: String,
    validator: function (value) {
      return ['tier-one', 'tier-two', 'tier-three'].includes(value);
    },
  },
  standaloneSubContext: {
    default: null,
    type: String,
  },
});

const emits = defineEmits(['close']);

const { linkClicked, modalDisplayed } = useBaseEvents();
const route = useRoute();
const store = useStore();

const apolloTrialForEveryoneFlag = useFlag('apollo_trial_for_everyone');

const selectedPriceInterval = ref(null);

const accountId = computed(() => store.state.currentUser?.accountId);
const reservationId = computed(() => props.reservationId);
const {
  account,
  isLoading: accountIsLoading,
  eligibleForPlatformSubscriptionTrial,
} = useAccount(accountId);
const { data: tiers, isLoading: tiersAreLoading } = useTiers();

const flowList = {
  // the default flow from most entrypoints
  full: ['landing', 'pricing', 'billing-cycle', 'checkout', 'congrats'],
  // sidenav uses shorter flow
  pricingAndCheckout: ['pricing', 'checkout', 'congrats'],
  // from marketing site
  checkoutOnly: ['checkout', 'congrats'],
  promoInformationOnly: ['booking-checkout-promo-information'],
  trialInfo: [
    'platform-trial-landing-step',
    'pricing',
    'billing-cycle',
    'checkout',
    'congrats',
  ],
  short: ['billing-cycle', 'checkout', 'congrats'],
};

const flow = computed(() => props.flow);

const dialogRef = ref(null);
const id = uniqueId('subscriptions-dialog-');
const { checkAllowEscapeClose } = useDialogContext({ id });
const headingId = 'subscriptions-dialog-label';
const prevSteps = ref([]);
const selectedPrice = ref(null);
const selectedTier = ref(null);
const stepNum = ref(0);

onKeyStroke('Escape', () => {
  if (checkAllowEscapeClose(dialogRef)) {
    emits('close');
  }
});
useFocusTrap(dialogRef, { immediate: true });

const activeStep = computed(() => flowList[flow.value][stepNum.value]);
const context = computed(() => route.meta?.context);
const isFirstStep = computed(() => stepNum.value === 0);
const isLoading = computed(
  () => accountIsLoading.value || tiersAreLoading.value
);
const offerTrial = computed(
  () =>
    apolloTrialForEveryoneFlag.value &&
    (eligibleForPlatformSubscriptionTrial.value ||
      activeStep.value === 'congrats')
);
const subContext = computed(
  () => props.standaloneSubContext || `subscriptions dialog ${activeStep.value}`
);

const showBackButton = computed(() => {
  if (isFirstStep.value || activeStep.value === 'congrats') return false;
  return true;
});

const handleBackClick = () => {
  const lastStep = prevSteps.value.pop();

  linkClicked({
    context: context.value,
    subContext: subContext.value,
    linkLabel: 'back',
    linkHref: null,
  });

  stepNum.value = flowList[flow.value].indexOf(lastStep);
};

const handleCloseClick = () => {
  linkClicked({
    context: context.value,
    subContext: subContext.value,
    linkLabel: 'close modal',
    linkHref: null,
  });

  emits('close');
};

const handleNextClick = stepName => {
  prevSteps.value = [...prevSteps.value, activeStep.value];

  if (stepName) stepNum.value = flowList[flow.value].indexOf(stepName);
  else if (!flowList[flow.value][stepNum.value + 1]) prevSteps.value.pop();
  else stepNum.value += 1;
};

const handleSelectTier = tier => {
  selectedPriceInterval.value = selectedPrice.value.recurring_interval;

  selectedTier.value = tier;

  selectedPrice.value = tier.product.prices.find(
    p => p.recurring_interval === selectedPriceInterval.value
  );
};

const handleReset = () => {
  const selectedTier = tiers.value.find(t => t.slug === 'tier-one');
  handleSelectTier(selectedTier);
};

watchEffect(() => {
  if (tiers.value && !selectedTier.value && !selectedPrice.value) {
    if (route.query?.recurring_interval) {
      selectedPriceInterval.value = route.query?.recurring_interval;
    } else if (account.value?.current_subscription_recurring_interval) {
      selectedPriceInterval.value =
        account.value?.current_subscription_recurring_interval;
    } else {
      selectedPriceInterval.value = 'year';
    }

    selectedTier.value = tiers.value.find(
      p => p.slug === props.selectedTierSlug
    );

    selectedPrice.value = selectedTier.value?.product?.prices?.find(
      p => p.recurring_interval === selectedPriceInterval.value
    );
  }
});

watchEffect(() => {
  if (activeStep.value) {
    const modalName =
      activeStep.value === 'landing'
        ? `${props.selectedTierSlug} ${activeStep.value}`
        : activeStep.value;
    modalDisplayed({
      context: route.meta.context,
      subContext: props.standaloneSubContext || 'subscription funnel',
      modalName: modalName,
    });
  }
});

const teleportTo = useTeleportTo();

useDialogSoonaSelect(dialogRef);

onMounted(() => {
  if (props.priceInterval) {
    selectedPriceInterval.value = props.priceInterval;
  }
});
</script>

<template>
  <Teleport :to="teleportTo">
    <div
      ref="dialogRef"
      class="subscriptions-dialog"
      role="dialog"
      :aria-labelledby="headingId"
      data-cypress="subscriptions-dialog"
      @click.self="emits('close')"
    >
      <div class="subscriptions-dialog__card">
        <BookingTrialPromoInformation
          v-if="activeStep === 'booking-checkout-promo-information'"
          :context="context"
          :heading-id="headingId"
          :is-loading="isLoading"
          :selected-tier="selectedTier"
          :sub-context="subContext"
          @close="handleCloseClick"
        />
        <PlatformTrialLandingStep
          v-if="activeStep === 'platform-trial-landing-step'"
          :context="context"
          :heading-id="headingId"
          :is-loading="isLoading"
          :selected-tier="selectedTier"
          :sub-context="subContext"
          badge-text="free platform trial"
          @close="handleCloseClick"
          @reset-tier="handleReset"
          @next="handleNextClick"
        />
        <LandingStep
          v-if="activeStep === 'landing'"
          :has-active-subscription="!!account?.subscription"
          :context="context"
          :heading-id="headingId"
          :is-loading="isLoading"
          :selected-tier="selectedTier"
          :show-back-button="showBackButton"
          :sub-context="subContext"
          :offer-trial="offerTrial"
          @back="handleBackClick"
          @close="handleCloseClick"
          @next="handleNextClick"
        />
        <PricingStep
          v-else-if="activeStep === 'pricing'"
          v-model:price="selectedPrice"
          :account-id="accountId"
          :active-subscription="account?.subscription"
          :all-tiers="tiers"
          :context="context"
          :heading-id="headingId"
          :is-loading="isLoading"
          :is-trialing="account?.is_trialing"
          :selected-tier="selectedTier"
          :show-back-button="showBackButton"
          :sub-context="subContext"
          :offer-trial="offerTrial"
          @back="handleBackClick"
          @close="handleCloseClick"
          @next="handleNextClick"
          @select-tier="handleSelectTier"
        />
        <BillingCycleStep
          v-else-if="activeStep === 'billing-cycle'"
          v-model:price="selectedPrice"
          :context="context"
          :is-loading="isLoading"
          :selected-tier="selectedTier"
          :show-back-button="showBackButton"
          :sub-context="subContext"
          :offer-trial="offerTrial"
          @back="handleBackClick"
          @close="handleCloseClick"
          @next="handleNextClick"
        />
        <CheckoutStep
          v-else-if="activeStep === 'checkout'"
          v-model:price="selectedPrice"
          :account-id="accountId"
          :has-active-subscription="!!account?.subscription"
          :context="context"
          :is-loading="isLoading"
          :selected-tier="selectedTier"
          :show-back-button="showBackButton"
          :sub-context="subContext"
          :offer-trial="offerTrial"
          :reservation-id="reservationId"
          @back="handleBackClick"
          @close="handleCloseClick"
          @next="handleNextClick"
        />
        <CongratsStep
          v-else-if="activeStep === 'congrats'"
          :context="context"
          :selected-tier="selectedTier"
          :show-back-button="showBackButton"
          :sub-context="subContext"
          :offer-trial="offerTrial"
          @close="handleCloseClick"
        />
      </div>
    </div>
  </Teleport>
</template>

<style lang="scss" scoped>
@use '@/variables';

.subscriptions-dialog {
  align-items: center;
  background-color: rgba(63, 67, 75, 0.7);
  bottom: 0;
  display: flex;
  height: 100dvh;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100vw;

  @supports not (height: 100dvh) {
    max-height: 100vh;
  }

  &__card {
    background-color: variables.$white-default;
    height: 100%;
    max-height: 100dvh;
    max-width: 64rem;
    position: relative;
    width: 100%;

    @supports not (height: 100dvh) {
      max-height: 100vh;
    }

    @media (min-width: variables.$screen-md-min) {
      border-radius: 0.625rem;
      margin-bottom: 0;
      margin-left: 2rem;
      margin-right: 5rem;
      max-height: min(45rem, calc(100% - 4rem));
    }
  }
}
</style>
