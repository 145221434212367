<script setup>
import { computed, ref } from 'vue';
import { useDigitalAssets } from '@/queries/digital_assets/useDigitalAssets';
import SoonaLink from '@/components/ui_library/SoonaLink.vue';
import PageContainerOffset from '@/components/platform-layout/PageContainerOffset.vue';
import SoonaSlider from '@/components/ui_library/SoonaSlider.vue';
import SoonaImageCard from '@/components/ui_library/SoonaImageCard.vue';
import SoonaSkeleton from '@/components/ui_library/SoonaSkeleton.vue';
import SoonaNoResults from 'src/components/ui_library/SoonaNoResults.vue';
import SubscriptionsDialog from 'src/components/modal-payment-flows/subscriptions/SubscriptionsDialog.vue';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
});

const accountId = computed(() => props.accountId);

const showPaywallDialog = ref(false);

// TODO:
//  remove origin once we can comingle user uploads and reservation images
//  (or maybe not? I suppose that’s a product question
const { data: recentDigitalAssets } = useDigitalAssets({
  accountId,
  itemsPerPage: 14,
  filters: { origin: 'customer', ownership: 'customer', media_type: 'photo' },
});
</script>

<template>
  <aside class="recent-uploads">
    <h3 id="me-landing-recent-uploads" class="u-subheader--heavy">
      recent uploads
    </h3>
    <SoonaLink
      v-if="recentDigitalAssets?.assets?.length > 0"
      :to="`/account/${accountId}/gallery/uploads`"
      variation="black"
    >
      see all
    </SoonaLink>
    <div v-if="!recentDigitalAssets" class="recent-uploads__loading">
      <div v-for="index in 8" :key="index" class="recent-uploads__skeleton">
        <SoonaSkeleton class="recent-uploads__skeleton-img" />
        <SoonaSkeleton class="recent-uploads__skeleton-caption" />
        <SoonaSkeleton class="recent-uploads__skeleton-caption" />
      </div>
    </div>
    <SoonaNoResults
      v-else-if="recentDigitalAssets.assets?.length === 0"
      mask="blob-empty"
      class="recent-uploads__no-results"
    >
      <template #header>no uploads yet!</template>
      <template #body>upload your photos at the top of this page</template>
    </SoonaNoResults>
    <PageContainerOffset v-else class="recent-uploads__images">
      <SoonaSlider
        v-if="recentDigitalAssets?.assets"
        tag-name="ul"
        aria-labelledby="me-landing-recent-uploads"
      >
        <SoonaImageCard
          v-for="(recentUpload, index) in recentDigitalAssets.assets"
          :key="recentUpload.id"
          size="medium"
          inner-element="router-link"
          :animation-delay="`${0.08 * index}s`"
          :to="{
            name: 'uploads-assets-media-view',
            params: {
              accountId: accountId,
              digitalAssetId: recentUpload.id,
            },
          }"
        >
          <img
            :src="recentUpload.preview?.url"
            class="recent-uploads__image"
            alt=""
          />
          <template #caption>
            {{ recentUpload.title }}
          </template>
        </SoonaImageCard>
      </SoonaSlider>
    </PageContainerOffset>
  </aside>
  <SubscriptionsDialog
    v-if="showPaywallDialog"
    selected-tier-slug="tier-one"
    @close="() => (showPaywallDialog = false)"
  />
</template>

<style scoped lang="scss">
.recent-uploads {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;

  &__images {
    flex: 1 0 100%;
  }

  &__image {
    background-image: url(@images/checkertile.svg);
  }

  &__unlock {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &__loading {
    flex: 1 0 100%;
    display: flex;
    gap: 1.5rem;
    padding: 0.5rem;
  }

  &__skeleton {
    flex: 0 0 9.25rem;
    width: 9.25rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding-bottom: 0.5rem;

    &-img {
      min-height: 7.6875rem;
      border-radius: 0.625rem;
    }

    &-caption {
      height: 1rem;
    }
  }

  &__no-results {
    padding: 1.5rem;

    > :deep(.no-results__image) {
      height: 5.625rem;
      width: auto;
    }
  }

  @media screen and (min-width: 64rem) {
    &__loading {
      padding: 0.5rem 0;
    }
  }

  @media screen and (min-width: 75rem) {
    &__loading {
      padding: 0.5rem;
    }
  }
}
</style>
