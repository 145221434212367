<script setup>
import { ref } from 'vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaForm from '@/components/ui_library/SoonaForm.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import { usePriorityError } from '@/composables/usePriorityError';
import { useCreateFeedback } from '@/queries/feedback/useCreateFeedback';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useRoute } from 'vue-router';

const props = defineProps({
  subjectId: {
    type: [Number, String],
    required: true,
  },
  open: {
    type: Boolean,
    required: true,
  },
});
const emits = defineEmits(['close']);
const route = useRoute();

const hasSelectedChoice = ref(false);
const selectedFeedback = ref(null);
const negativeFeedbackNotes = ref('');
const { linkClicked } = useBaseEvents();

const {
  mutate: createFeedback,
  isPending: isMutating,
  error: createError,
} = useCreateFeedback();

const closeDialog = () => {
  if (isMutating.value) return;

  negativeFeedbackNotes.value = '';
  hasSelectedChoice.value = false;
  selectedFeedback.value = null;

  emits('close');
};

const selectChoice = option => {
  hasSelectedChoice.value = true;
  selectedFeedback.value = option;

  linkClicked({
    context: route.meta.context,
    subContext: 'media editor feedback',
    linkLabel: option === 'positive' ? 'looks good' : 'needs work',
    linkHref: null,
  });
};

const priorityErrorDialog = usePriorityError(createError);

const submitFeedback = (event, close) => {
  const data = new FormData(event.target);
  const selectedPositiveRating = data.get('positive-rating');
  const ratingOption = selectedPositiveRating ? 'looks good' : 'needs work';

  const selectedOptions = [ratingOption];

  const dataToSubmit = {
    subject_type: 'DigitalAsset',
    subject_id: props.subjectId,
    notes: data.get('feedback-notes'),
    options: selectedOptions,
    category: 'media_editor',
  };

  createFeedback(dataToSubmit, {
    onSuccess: () => {
      close();
    },
  });
};
</script>
<template>
  <SoonaDialog v-if="open" role="dialog" @close="closeDialog">
    <template #heading> help us improve!</template>
    <template #default="{ close }">
      <SoonaForm id="me-feedback-form" @submit="e => submitFeedback(e, close)">
        <fieldset>
          <legend class="feedback-choices-legend">
            did the edits made with our media editor tools meet your
            expectations?
          </legend>
          <div class="feedback-choices">
            <label class="feedback__item">
              <input
                name="positive-rating"
                type="radio"
                :checked="selectedFeedback === 'positive'"
                class="u-a11y-only"
                @change="selectChoice('positive')"
              />
              <span class="feedback__item__emoji">
                <SoonaIcon name="thumbs-up" />
              </span>
              looks good
            </label>
            <label class="feedback__item">
              <input
                name="negative-rating"
                type="radio"
                :checked="selectedFeedback === 'negative'"
                class="u-a11y-only"
                @change="selectChoice('negative')"
              />
              <span class="feedback__item__emoji">
                <SoonaIcon name="thumbs-down" />
              </span>
              needs work
            </label>
          </div>
        </fieldset>
        <SoonaTextfield
          v-if="selectedFeedback === 'negative'"
          v-model="negativeFeedbackNotes"
          class="me-feedback__notes"
          label="describe what’s wrong"
          name="feedback-notes"
          placeholder="optional"
          element="textarea"
        />
        <SoonaError v-if="priorityErrorDialog" class="promo-code__dialog-error">
          {{ priorityErrorDialog }}
        </SoonaError>
      </SoonaForm>
    </template>
    <template #footer="{ close }">
      <SoonaButton
        type="button"
        variation="tertiary"
        size="medium"
        @click="close"
      >
        cancel
      </SoonaButton>
      <SoonaButton
        form="me-feedback-form"
        type="submit"
        :disabled="!(hasSelectedChoice && selectedFeedback)"
      >
        submit
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>
<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.feedback-choices-legend {
  margin-bottom: 1.25rem;
}

.feedback-choices {
  display: flex;
  justify-content: center;
  gap: 2rem;
}

.me-feedback__notes {
  padding: 0;
  margin-top: 1.25rem;
}

.feedback__item {
  @include variables_fonts.u-label--regular;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0.5rem 0.5rem 0.5rem;
  color: variables.$black-default;
  cursor: pointer;
  gap: 0.5rem;

  &__emoji {
    display: block;
    color: variables.$gray-60;
    border-radius: 0.625rem;
    padding: 1rem;
    border: 0.0625rem solid variables.$gray-30;

    svg {
      display: block;
      width: 2.5rem;
      height: 2.5rem;
    }

    input:focus-visible + & {
      outline: 0.125rem variables.$periwink-blue-60 solid;
    }
  }

  &:hover .feedback__item__emoji {
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.16);
  }

  input:checked +,
  &:active {
    .feedback__item__emoji {
      border-color: variables.$black-default;
    }
  }
}
</style>
