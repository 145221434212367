<template>
  <div class="admin-bookings">
    <AdminBookingsNav />
    <section class="dashboard-section">
      <div class="columns dashboard-flex-container">
        <div class="column is-6 dashboard-search-column">
          <label class="label" for="bookings-admin-search">search</label>
          <div class="field has-addons">
            <div class="control">
              <input
                id="bookings-admin-search"
                v-model="queryFilter"
                class="input"
                type="text"
                placeholder="type keywords..."
                data-cypress="input-account-search"
                @keyup.enter="searchSubmitted"
              />
              <button
                v-if="queryFilter.length > 0"
                class="clear-search-btn is-pulled-right"
                @click="clearSearchInput"
              >
                <SoonaIcon name="xmark" size="medium" />
              </button>
            </div>
            <div class="control">
              <a
                class="search-bookings-btn"
                data-cypress="button-submit-search"
                @click="searchSubmitted"
              >
                <SoonaIcon name="search" size="medium" />
              </a>
            </div>
            <button
              title="filter by accounts that allow soona to use their edited content for marketing purposes!"
              class="shareable-btn"
              :class="{ 'shareable-active': shareableFilter }"
              @click="toggleShareable"
            >
              shareable
            </button>
          </div>
        </div>
        <div class="column is-6 dashboard-location-filter">
          <div class="field is-pulled-right">
            <label class="label">location</label>
            <div class="control location-filter-control">
              <div class="select location-filter-select-container">
                <select
                  v-model="currentLocation"
                  class="location-filter-select"
                >
                  <option value>All</option>
                  <option
                    v-for="location in locations"
                    :key="location.id"
                    :value="location.name"
                  >
                    {{ location.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      v-if="accounts && accounts.length > 0"
      class="dashboard-section mt-l"
    >
      <SoonaSlider tag-name="ul" override-u-container>
        <li v-for="account in accounts" :key="account.id">
          <AccountSearchResult :result="account" />
        </li>
      </SoonaSlider>
      <div
        v-if="showSearchPagination"
        class="admin-bookings__pagination-buttons"
      >
        <SoonaButton
          variation="secondary-black"
          :disabled="searchPage === 1"
          @on-click="changeSearchPage('prev')"
        >
          load prev
        </SoonaButton>
        <SoonaButton
          variation="secondary-black"
          :disabled="!fullSearchPage"
          @on-click="changeSearchPage('next')"
        >
          load next
        </SoonaButton>
      </div>
    </section>
    <router-view
      :shareable-filter="shareableFilter"
      :location-filter="locationFilter"
      :name-filter="searchFilter"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import AdminBookingsNav from 'src/components/user/anytime/admin/AdminBookingsNav.vue';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';
import { useRoute } from 'vue-router';
import { useTitle } from '@vueuse/core';
import { computed } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaSlider from '@/components/ui_library/SoonaSlider.vue';
import AccountSearchResult from '@/components/user/anytime/admin/AccountSearchResult.vue';

export default {
  components: {
    AccountSearchResult,
    SoonaSlider,
    SoonaButton,
    AdminBookingsNav,
    SoonaIcon,
  },
  setup() {
    const route = useRoute();
    useTitle(computed(() => `${route.meta.page_title} | soona`));
  },
  data() {
    return {
      shareableFilter: false,
      queryFilter: '',
      searchFilter: '',
      locationFilter: '',
      searchPage: 1,
    };
  },
  computed: {
    ...mapState({
      selectedLocation: state => state.locations.currentLocation,
      locations: state => state.locations.locations,
      accounts: state => state.dashboard.accounts,
    }),
    currentLocation: {
      get() {
        return this.locationFilter;
      },
      set(value) {
        this.locationFilter = value;
        if (value) {
          this.loadLocationByName({ name: value }).then(() => {
            localStorage.setItem(
              'defaultLocation',
              JSON.stringify(this.selectedLocation)
            );
          });
        } else {
          localStorage.removeItem('defaultLocation');
        }
      },
    },
    fullSearchPage() {
      return this.accounts?.length === 10;
    },
    showSearchPagination() {
      return this.accounts?.length === 10 || this.searchPage > 1;
    },
  },
  mounted() {
    this.resetAccounts();
    this.loadLocations().then(() => {
      if (localStorage.getItem('defaultLocation')) {
        this.currentLocation = JSON.parse(
          localStorage.getItem('defaultLocation')
        ).name;
      }
    });
  },
  methods: {
    ...mapActions('locations', ['loadLocationByName', 'loadLocations']),
    ...mapActions('dashboard', ['loadAccounts', 'resetAccounts']),
    searchSubmitted() {
      this.searchPage = 1;
      this.loadAccounts({ query: this.queryFilter });
      this.searchFilter = this.queryFilter;
    },
    changeSearchPage(direction) {
      if (direction === 'prev') this.searchPage -= 1;
      if (direction === 'next') this.searchPage += 1;
      this.loadAccounts({ query: this.queryFilter, page: this.searchPage });
    },
    clearSearchInput() {
      this.searchPage = 1;
      this.queryFilter = '';
      this.resetAccounts();

      if (this.searchFilter.length > 0) {
        this.searchFilter = '';
      }
    },
    toggleShareable() {
      return (this.shareableFilter = !this.shareableFilter);
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';
@import '@/buttons';

.admin-bookings {
  &__pagination-buttons {
    margin-top: 0.5rem;
    display: flex;
    flex-flow: row wrap;
    gap: 1rem;
    justify-content: space-between;
  }
}

.dashboard-flex-container {
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
}

.dashboard-search-column {
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
}

.dashboard-location-filter {
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
}

.location-filter-control {
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.location-filter-select-container {
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.location-filter-select {
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.shareable-btn {
  margin-left: 20px;
  padding: 0 20px;
  height: 36px;
  background-color: variables.$gray-20;
  height: 40px;
}

.shareable-active {
  color: variables.$white-default;
  background-color: variables.$friendly-red-50;
}

.search-bookings-btn {
  align-items: center;
  background-color: variables.$periwink-blue-60;
  color: variables.$white-default;
  display: flex;
  justify-content: center;
  padding: 0.5rem;
  border-radius: 0 5px 5px 0 !important;
  height: 40px;
}

.clear-search-btn {
  z-index: 99999;
  position: relative;
  bottom: 29px;
  right: 5px;
  padding: 0;
  font-size: 18px;
  color: gray;
  background-color: variables.$white-default;
}

.clear-search-btn:hover {
  color: variables.$black-default !important;
}
</style>
