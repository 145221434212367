<script setup>
import { watchEffect } from 'vue';
import SoonaError from 'src/components/ui_library/SoonaError.vue';

const props = defineProps({
  nextButtonCopy: String,
  backButtonCopy: String,
  onNextClick: Function,
  onBackClick: Function,
  isLoading: Boolean,
  isStepValid: Boolean,
  showNext: {
    type: Boolean,
    default: true,
  },
  showBack: {
    type: Boolean,
    default: true,
  },
  hideFooter: {
    type: Boolean,
    default: false,
  },
  noSidecart: {
    type: Boolean,
    default: false,
  },
  leftAligned: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['setSidecartFooterProps']);

watchEffect(() => {
  emit('setSidecartFooterProps', {
    noSidecart: props.noSidecart,
    leftAligned: props.leftAligned,
    hideFooter: props.hideFooter,
    showNext: props.showNext,
    isLoading: props.isLoading,
    isStepValid: props.isStepValid,
    onNextClick: props.onNextClick,
    onBackClick: props.onBackClick,
    nextButtonCopy: props.nextButtonCopy,
    backButtonCopy: props.backButtonCopy,
  });
});
</script>

<template>
  <div
    class="booking-layout"
    :class="{
      'booking-layout--no-top-padding': leftAligned,
    }"
  >
    <div
      class="prebooking"
      :class="{
        'u-container': noSidecart && !leftAligned,
        'prebooking--sidecart': !noSidecart,
        'prebooking--no-footer': hideFooter,
        'prebooking--left-aligned': leftAligned,
      }"
    >
      <div
        :class="{
          'u-container': !noSidecart,
          'left-aligned-container': leftAligned,
        }"
      >
        <SoonaError v-if="$slots['priority-error']" class="prebooking__error">
          <slot name="priority-error" />
        </SoonaError>
        <slot />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.booking-layout {
  display: flex;
  align-items: flex-start;
  flex-grow: 1;
  padding-top: 2.5rem;

  &--no-top-padding {
    padding-top: 0;
  }
}

.prebooking {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 3.125rem;
  max-width: 62.375rem;

  &__error {
    width: 100%;
  }

  &--no-footer {
    padding-bottom: 0;
  }

  .u-container {
    max-width: 60rem;
  }

  @media (min-width: 48rem) {
    padding-top: 1.125rem;
    padding-bottom: 6.3125rem;
    margin-top: -1.125rem;

    &--no-footer {
      padding-bottom: 1.125rem;
    }
  }
}

@media (min-width: variables.$screen-sm-min) {
  .prebooking--left-aligned {
    display: flex;
    max-width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
    padding: 0rem 0rem;
    margin-top: 0rem;

    .left-aligned-container {
      height: 100%;
      width: 100%;
    }
  }
}

@media (min-width: 60rem) {
  .prebooking--sidecart {
    max-width: calc(100% - 22.0625rem);
  }
}

@media (min-width: 75rem) {
  .prebooking--sidecart {
    max-width: calc(100% - 27.5625rem);
  }
}

@media (min-width: 117.625rem) {
  .prebooking--sidecart {
    max-width: calc(100% - 27.5625rem);
  }
}
</style>
