<script setup>
import { useTitle } from '@vueuse/core';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { onMounted, ref } from 'vue';
import TrendHeader from './TrendHeader.vue';
import TrendCreateAccountDialog from './TrendCreateAccountDialog.vue';
import TrendSteps from './TrendSteps.vue';
import TrendChatWithSales from './TrendChatWithSales.vue';
import TrendGetInspiredVideos from './TrendGetInspiredVideos.vue';
import WhatIsUGC from './WhatIsUGC.vue';
import UGCTestimonial from './UGCTestimonial.vue';

useTitle('user generated content | soona');
const { pageViewed } = useBaseEvents();
onMounted(() => {
  pageViewed();
});

const createBriefDialogOpen = ref(false);
</script>

<template>
  <div class="ugc-page">
    <TrendHeader @create-brief-click="createBriefDialogOpen = true" />

    <TrendGetInspiredVideos />

    <TrendSteps />

    <TrendChatWithSales />

    <UGCTestimonial @create-brief-click="createBriefDialogOpen = true" />

    <WhatIsUGC @create-brief-click="createBriefDialogOpen = true" />

    <TrendCreateAccountDialog
      v-if="createBriefDialogOpen"
      @close="createBriefDialogOpen = false"
    />
  </div>
</template>

<style lang="scss" scoped>
.ugc-page {
  display: flex;
  flex-direction: column;
  gap: 3.5rem;
  container: ugc-page / inline-size;
}
</style>
