<template>
  <div class="shoot-details">
    <img class="shoot-thumbnail" src="@images/studio-banner.png" />
    <p class="u-body--heavy">
      {{ account.name }}
    </p>
    <p class="shoot-name u-display--heavy">
      {{ reservation.name }}
    </p>
    <p class="shoot-description">{{ reservation.description }}</p>
    <template v-if="timezone">
      <p class="currently-scheduled has-text-weight-bold">
        currently scheduled:
      </p>
      <p class="currently-scheduled-time u-headline--heavy">
        {{ formattedDate }}
      </p>
      <SoonaButton
        v-if="!showTimeZoneSelection"
        copy="change timezone"
        variation="tertiary"
        @on-click="() => (showTimeZoneSelection = true)"
      />
      <TimezonePicker
        v-else
        :timezones="timeZonesList"
        :selected-timezone="timezone"
        :on-change="onTimezoneChange"
      />
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import TimezonePicker from 'src/components/shared/TimezonePicker.vue';

export default {
  name: 'ShootSummary',
  components: {
    SoonaButton,
    TimezonePicker,
  },
  props: {
    reservation: Object,
    account: Object,
    showTimezoneOption: Boolean,
    timezone: String,
    onTimezoneChange: Function,
  },
  data() {
    return {
      showTimeZoneSelection: false,
    };
  },
  computed: {
    ...mapGetters('account', ['timeZonesList', 'guessedTimeZone']),
    formattedDate() {
      const dateFormatter = new Intl.DateTimeFormat('default', {
        timeZone: this.timezone,
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
      const timeformatter = new Intl.DateTimeFormat('default', {
        timeZone: this.timezone,
        hour: 'numeric',
        minute: 'numeric',
        timeZoneName: 'short',
      });

      return `${dateFormatter.format(
        new Date(this.reservation.start)
      )} @ ${timeformatter.format(new Date(this.reservation.start))}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';

.shoot-details {
  box-sizing: border-box;
  width: 50%;
  padding-right: 5.625rem;
  border-right: solid 0.125rem variables.$gray-20;

  @media (max-width: variables.$screen-xs-max) {
    padding: 0;
    width: 100%;
    border: none;
    border-bottom: solid 0.125rem variables.$gray-20;
    padding-bottom: 2.25rem;
  }

  > p {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .shoot-name {
    line-height: 1.2;
  }

  .shoot-description {
    overflow-wrap: break-word;
    word-wrap: break-word;
  }

  .currently-scheduled {
    margin-top: 1.5rem;
  }

  .currently-scheduled-time {
    margin-bottom: 0.5rem;
    text-transform: capitalize;
  }

  .shoot-thumbnail {
    border-radius: 0.5rem;
    width: 100%;
    height: auto;
  }
}
</style>
