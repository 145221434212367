<script setup>
import { computed } from 'vue';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaChip from '@/components/ui_library/SoonaChip.vue';
import { useReservation } from '@/composables/useReservation';
import ProductFlags from '@/components/user/anytime/products/product-page/ProductFlags.vue';
import SoonaHelperText from '@/components/ui_library/SoonaHelperText.vue';
import photoImage from 'images/photo.png';

const props = defineProps({
  reservationId: {
    type: Number,
    required: true,
  },
});

const reservationId = computed(() => props.reservationId);

const {
  reservation,
  scheduledDateFormatted,
  lengthOfShoot,
  scheduledStartTime,
  scheduledEndTime,
  reservationLocation,
  nameTruncated,
  hasUnassignedLocation,
  targetPlatforms,
  requiredCatalogItems,
  shootType,
  groupedPurchasedProServices,
  isPack,
} = useReservation(reservationId);

const shootAddress = computed(() =>
  hasUnassignedLocation.value ? 'unassigned' : reservationLocation.value
);
const shootGoals = computed(() => reservation.value?.description);
const shootDate = computed(() => scheduledDateFormatted.value);
const shootLength = computed(() => lengthOfShoot.value?.display);
const shootStartTime = computed(() => scheduledStartTime.value);
const shootEndTime = computed(() => scheduledEndTime.value);
const shootProductCount = computed(() => requiredCatalogItems.value?.length);
const shootMoodboard = computed(() => reservation.value?.moodboard);
const shootTargetPlatforms = computed(() => targetPlatforms.value);
const shootUpgrades = computed(() => groupedPurchasedProServices.value);
const shootTargetPlatformsLength = computed(
  () => shootTargetPlatforms.value?.length
);
const containsProps = computed(() =>
  requiredCatalogItems.value?.some(item => item.type === 'CatalogProp')
);
const containsArchivedProducts = computed(() =>
  requiredCatalogItems.value?.some(item => item.status === 'archived')
);
const containsLargeProducts = computed(() =>
  requiredCatalogItems.value?.some(item => item.is_larger_than_twenty_inches)
);
const productsNeedAssembly = computed(() =>
  requiredCatalogItems.value?.some(item => item.needs_assembly)
);
const productsNeedSteaming = computed(() =>
  requiredCatalogItems.value?.some(item => item.needs_steaming)
);

const flagsProduct = computed(() => {
  return {
    status: containsArchivedProducts.value ? 'archived' : '',
    type: containsProps.value ? 'CatalogProp' : '',
    is_larger_than_twenty_inches: containsLargeProducts.value,
    needs_assembly: productsNeedAssembly.value,
    needs_steaming: productsNeedSteaming.value,
  };
});

const platformLogos = {
  Amazon: 'amazon-logo',
  Shopify: 'shopify-logo',
  Instagram: 'instagram-logo',
  Etsy: 'etsy-logo',
  other: 'globe',
};

const shootLengthTooltipCopy = computed(() => {
  if (shootType.value === 'photo') {
    return 'we use your estimated number of photos to calculate the approximate length of your shoot. model & upgrade prices are dependent on shoot length.';
  } else if (shootType.value === 'video') {
    return 'we use your video type to determine the length of your shoot.';
  } else {
    return 'we use a variety of factors to determine your shoot length.';
  }
});
</script>

<template>
  <section id="booking-details-card" class="booking-details-card">
    <h2 class="booking-details-card__heading">shoot summary</h2>
    <div class="booking-details-card__card">
      <div class="booking-details-card__section">
        <div class="booking-details-card__title-wrapper">
          <h3 class="booking-details-card__title" data-cypress="shoot-title">
            {{ nameTruncated }}
          </h3>
          <SoonaButton
            variation="icon-transparent"
            element="router-link"
            class="booking-details-card__edit"
            :to="{
              name: 'shoot-summary',
              params: { reservationId: reservationId },
            }"
          >
            <SoonaIcon name="pen-square" />
          </SoonaButton>
        </div>
      </div>
      <ul class="booking-details-card__list">
        <li class="booking-details-card__section">
          <div class="booking-details-card__label-wrapper">
            <SoonaIcon
              class="booking-details-card__icon"
              name="calendar-solid"
            />
            <template v-if="shootDate">
              <time class="booking-details-card__title">
                {{ shootDate }}
              </time>
            </template>
            <template v-else
              ><p class="booking-details-card__title">
                not scheduled yet
              </p></template
            >
          </div>
        </li>
        <li
          v-if="shootDate && shootStartTime && shootEndTime"
          class="booking-details-card__section"
        >
          <div>
            <dl class="booking-details-card__label-wrapper">
              <SoonaIcon class="booking-details-card__icon" name="clock" />
              <dt class="u-a11y-only">shoot length</dt>
              <dd class="booking-details-card__title">
                {{ shootStartTime }} - {{ shootEndTime }}
              </dd>
            </dl>
          </div>
        </li>
        <li
          v-else-if="!(isPack && shootType === 'video')"
          class="booking-details-card__section"
        >
          <div>
            <dl class="booking-details-card__label-wrapper">
              <SoonaIcon class="booking-details-card__icon" name="clock" />
              <dt class="u-a11y-only">shoot length</dt>
              <dd class="booking-details-card__title">{{ shootLength }}</dd>
              <SoonaHelperText
                class="booking-details-card__tooltip"
                title="length of shoot"
              >
                {{ shootLengthTooltipCopy }}
              </SoonaHelperText>
            </dl>
          </div>
        </li>
        <li class="booking-details-card__section">
          <div class="booking-details-card__label-wrapper">
            <SoonaIcon class="booking-details-card__icon" name="mailbox-alt" />
            <address class="booking-details-card__title">
              soona REF: {{ reservation.id }}
            </address>
          </div>
          <address class="booking-details-card__content-wrapper">
            <span v-if="typeof shootAddress === 'string'">
              {{ shootAddress }}
            </span>
            <template v-else-if="reservationLocation">
              <p>
                {{ reservationLocation['address1'] }}
                {{ reservationLocation['address2'] }}
                <br />
                {{ reservationLocation['city'] }},
                {{ reservationLocation['state'] }}
                {{ reservationLocation['zip'] }}
              </p>
            </template>
          </address>
        </li>
        <li class="booking-details-card__section">
          <div class="booking-details-card__label-wrapper">
            <SoonaIcon class="booking-details-card__icon" name="shapes" />
            <span class="booking-details-card__title">
              {{ shootProductCount }} product{{
                shootProductCount === 1 ? '' : 's'
              }}
            </span>
            <ProductFlags
              class="booking-details-card__flags"
              :product="flagsProduct"
            />
          </div>
          <div class="booking-details-card__content-wrapper">
            <SoonaButton
              variation="tertiary"
              element="router-link"
              :to="{
                name: 'shoot-products',
                params: { reservationId: reservationId },
              }"
              >view products</SoonaButton
            >
          </div>
        </li>
        <li class="booking-details-card__section">
          <dl>
            <div class="booking-details-card__label-wrapper">
              <SoonaIcon class="booking-details-card__icon" name="globe" />
              <dt class="booking-details-card__title">
                {{ shootTargetPlatformsLength }} destination{{
                  shootTargetPlatformsLength === 1 ? '' : 's'
                }}
              </dt>
            </div>
            <dd class="booking-details-card__content-wrapper">
              <SoonaChip
                v-for="targetPlatform in shootTargetPlatforms"
                :key="targetPlatform"
                :soona-icon="platformLogos[targetPlatform]"
                size="medium"
                class="booking-details-card__destination"
              >
                <span class="booking-details-card__destination-text">{{
                  targetPlatform
                }}</span>
              </SoonaChip>
            </dd>
          </dl>
        </li>
        <li class="booking-details-card__section">
          <dl>
            <div class="booking-details-card__label-wrapper">
              <SoonaIcon
                class="booking-details-card__icon"
                name="bullseye-arrow"
              />
              <dt class="booking-details-card__title">shoot goals</dt>
            </div>
            <dd class="booking-details-card__content-wrapper">
              {{ shootGoals }}
            </dd>
          </dl>
        </li>
        <li v-if="shootMoodboard" class="booking-details-card__section">
          <dl>
            <div class="booking-details-card__label-wrapper">
              <SoonaIcon
                class="booking-details-card__icon"
                name="image-square"
              />
              <dt class="booking-details-card__title">moodboard</dt>
            </div>
            <dd class="booking-details-card__content-wrapper">
              <a
                :href="shootMoodboard"
                target="_blank"
                class="booking-details-card__external-link"
              >
                {{ shootMoodboard }}
              </a>
            </dd>
          </dl>
        </li>
        <li class="booking-details-card__section">
          <dl>
            <div class="booking-details-card__label-wrapper">
              <SoonaIcon
                class="booking-details-card__icon"
                name="dazzling-star"
              />
              <dt class="booking-details-card__title">upgrades</dt>
            </div>
            <dd class="booking-details-card__content-wrapper">
              <SoonaChip
                v-for="service in shootUpgrades"
                :key="service.id"
                :image-alt="service.name"
                :image-src="service.thumbnail_url ?? photoImage"
                size="medium"
              >
                {{ service.name }}
                {{ service.quantity > 1 ? `(${service.quantity})` : '' }}
              </SoonaChip>
              <SoonaButton
                variation="tertiary"
                element="router-link"
                :to="{
                  name: 'shoot-upgrades',
                  params: { reservationId: reservationId },
                }"
              >
                <SoonaIcon name="plus" />
                more
              </SoonaButton>
            </dd>
          </dl>
        </li>
      </ul>
    </div>
  </section>
</template>
<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';
@import '@/buttons';

.booking-details-card {
  border-radius: 0.625rem;

  &__heading {
    @include variables_fonts.u-body--heavy;

    margin-bottom: 1rem;
    color: variables.$black-default;
  }

  &__card {
    background-color: variables.$periwink-blue-10;
    border-radius: 0.625rem;
    width: 100%;
    padding: 1.5rem;
    border: 0.0625rem solid #d3d6d9;
  }

  &__list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1.5rem;
  }

  &__flags,
  &__tooltip {
    margin-left: 0.5rem;
  }

  &__title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1.5rem;
  }

  &__label-wrapper {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 0.5rem;
  }

  &__content-wrapper {
    display: flex;
    flex-flow: row wrap;
    gap: 0.5rem;
    font-style: initial;
    margin-left: 2rem;
  }

  &__title {
    @include variables_fonts.u-body--heavy;
    white-space: nowrap;
    font-style: initial;

    &--light {
      font-weight: 300;
    }
  }

  &__icon {
    color: variables.$black-default;
    margin-right: 0.5rem;
  }

  &__external-link {
    word-break: break-word;
    color: variables.$black-default;
    text-decoration: underline;
    transition: color 0.1s ease-out;

    &:hover,
    &:focus-visible {
      color: variables.$periwink-blue-70;
    }

    &:active {
      text-decoration: none;
    }
  }

  &__destination {
    color: variables.$gray-60;
  }

  &__destination-text {
    color: variables.$black-default;
  }

  &__edit {
    svg {
      color: variables.$gray-60;
      transition: color 0.1s ease-out;
    }

    &:hover,
    &:focus-visible {
      svg {
        color: variables.$black-default;
      }
    }
  }

  @media (min-width: variables.$screen-sm-min) {
    margin-bottom: 1.75rem;

    &__heading {
      @include variables_fonts.u-subheader--heavy;
    }
  }
}
</style>
