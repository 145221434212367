<script setup>
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';

const emits = defineEmits(['onClick']);
</script>

<template>
  <SoonaButton
    variation="tertiary"
    copy="use a different account"
    type="button"
    @on-click="() => emits('onClick')"
  />
</template>
