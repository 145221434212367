<script setup>
import { computed, ref } from 'vue';
import SoonaButton from '../ui_library/SoonaButton.vue';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';
import ProServiceDropdownFilter from 'src/components/user/anytime/pro_service/ProServiceDropdownFilter.vue';
import ProServiceSelectionFilterModal from 'src/components/user/anytime/pro_service/ProServiceSelectionFilterModal.vue';
import LocationDropdown from 'src/components/directory/LocationDropdown.vue';
import { useGetTagFollowUpCategories } from '@/queries/useGetTagFollowUpCategories';
import { useShowTag } from '@/queries/useShowTag';

const props = defineProps({
  proServiceType: {
    type: [String, null],
    required: true,
  },
  proServiceTypeId: {
    type: [Number, null],
    required: true,
  },
  numProviders: {
    type: Number,
    default: 0,
  },
});

const emits = defineEmits(['close']);

const filters = defineModel('filters', {
  type: Array,
  default: [],
  required: true,
});

const locationIds = defineModel('locationIds', {
  type: Array,
  default: [],
  required: true,
});

const showFilterModal = ref(false);

const proServiceTypeId = computed(() => props.proServiceTypeId);
const proServiceType = computed(() => props.proServiceType);
const numProviders = computed(() => props.numProviders);

const { data: tagData } = useShowTag(computed(() => proServiceTypeId.value));

const { data: tagFollowUpCategoriesData } = useGetTagFollowUpCategories(
  proServiceTypeId,
  {
    enabled: computed(() => !!proServiceTypeId.value),
  }
);

const tagFollowUpCategories = computed(
  () => tagFollowUpCategoriesData?.value || []
);

const filteredProServiceCategories = computed(() => {
  return tagFollowUpCategories.value?.filter(
    tc =>
      tc.title !== 'manicure' &&
      tc.title !== 'manicure color' &&
      tc.title !== 'pronoun'
  );
});

const basicInfoTagCategories = computed(() => {
  return filteredProServiceCategories.value.filter(
    tc => tc.special_category === 'psp_trait_basic_info'
  );
});

const quickFilters = computed(() => {
  if (tagData.value?.filter_names) {
    const filterNamesArray = tagData.value?.filter_names.split(',');
    return filteredProServiceCategories.value.filter(tc =>
      filterNamesArray.includes(tc.title)
    );
  } else {
    return basicInfoTagCategories.value;
  }
});

const filterModalConfirmText = computed(() => {
  return numProviders.value > 0
    ? 'view ' + numProviders.value + ' models'
    : 'no matching models';
});

const proServiceTypeEmoji = computed(() => {
  switch (proServiceType.value) {
    case 'full body model':
      return '💁🏽';
    case 'hand model':
      return '👋';
    case 'foot model':
      return '👟';
    case 'pet model':
      return '🐶';
    default:
      return '';
  }
});

function traitSelect(item) {
  let indexNumber = filters.value.findIndex(tag => tag === item);
  let proxy = [...filters.value];
  if (indexNumber > -1) {
    proxy.splice(indexNumber, 1);
  } else {
    proxy.push(item);
  }
  filters.value = proxy;
}

function clearFilters() {
  filters.value = [];
}
</script>
<template>
  <div class="pro-service-filters-header">
    <div class="pro-service-filters-header__top">
      <SoonaButton size="large" variation="tertiary" @on-click="emits('close')">
        <SoonaIcon name="arrow-left" />
      </SoonaButton>
      <span class="u-headline--heavy">
        {{ proServiceTypeEmoji }}
        {{ proServiceType }}s
      </span>
    </div>
    <div class="pro-service-filters-header__bottom">
      <LocationDropdown v-model:location-ids="locationIds" />
      <ProServiceDropdownFilter
        v-for="traitCategory in quickFilters"
        :key="traitCategory.id"
        :model-value="filters"
        :tag-category="traitCategory"
        :pro-service-provider-type="proServiceType"
        @update:model-value="traitSelect($event)"
      />
      <SoonaButton
        v-if="quickFilters.length !== filteredProServiceCategories.length"
        size="small"
        variation="tertiary"
        copy="show more filters"
        @on-click="() => (showFilterModal = true)"
      />
    </div>
    <ProServiceSelectionFilterModal
      v-if="showFilterModal"
      :model-value="filters"
      :main-tag="{ title: proServiceType, id: proServiceTypeId }"
      :confirm-button-copy="filterModalConfirmText"
      @confirm-clicked="() => (showFilterModal = false)"
      @cancel-clicked="() => (showFilterModal = false)"
      @update:model-value="traitSelect($event)"
      @clear-filters="clearFilters"
    />
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables';

.pro-service-filters-header {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: variables.$periwink-blue-10;
  padding: 2rem;

  &__top {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  &__bottom {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
}
</style>
