import { http } from '@/config/vue-axios';
import { mapPaginationHeaders } from '@/lib/api-transforms';

// ALBUM
export async function albumFile(collectionId, mediaId, params, signal) {
  const response = await http.get(
    `/collections/${collectionId}/media_files/${mediaId}`,
    params,
    {
      signal,
    }
  );

  return response.data;
}

export async function albumCarouselFiles(
  assetCollectionId,
  { activeAssetId, currentPage, itemsPerPage } = {},
  signal
) {
  const params = {};

  if (activeAssetId) params['active_asset_id'] = activeAssetId;
  if (currentPage) params['page'] = currentPage;
  if (itemsPerPage) params['items'] = itemsPerPage;

  const response = await http.get(
    `/collections/${assetCollectionId}/media_files`,
    {
      params,
      signal,
    }
  );

  return {
    pagination: mapPaginationHeaders(response.headers),
    files: response.data,
  };
}

// GALLERY
export async function galleryFile(mediaId, { album, filter }, signal) {
  let params = {};

  if (album) params['album'] = album;
  if (filter) params['filter'] = filter;

  const response = await http.get(`/media/gallery_files/${mediaId}`, {
    params,
    signal,
  });

  return response.data;
}

export async function createGalleryFile(params) {
  const response = await http.post('/media/gallery_files', params);

  return response.data;
}

export async function requestShadows(mediaId) {
  const response = await http.post(`/media/gallery_files/${mediaId}/shadows`);
  return response.data;
}

/**
 *
 * @param {Object} [params]
 * @param {string | number} [assetCollectionId]
 * @param {number} [params.currentPage]
 * @param {number} [params.itemsPerPage]
 * @param {number} [params.activeAssetId]
 * @param {number} [params.album]
 * @param {number} [params.filter]
 * @param {AbortSignal} [signal]
 */
export async function carouselFiles(
  assetCollectionId,
  {
    activeAssetId,
    album,
    collectionContext,
    currentPage,
    filter,
    itemsPerPage,
  } = {},
  signal
) {
  const params = {
    collection_id: assetCollectionId,
    collection_context: collectionContext,
  };

  if (currentPage) params['page'] = currentPage;
  if (itemsPerPage) params['items'] = itemsPerPage;
  if (activeAssetId) params['active_asset_id'] = activeAssetId;
  if (album) params['album'] = album;
  if (filter) params['filter'] = filter;

  const response = await http.get(`/media`, {
    params,
    signal,
  });

  return {
    pagination: mapPaginationHeaders(response.headers),
    files: response.data,
  };
}
