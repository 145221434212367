<script setup>
import { ref, watch, onMounted, onBeforeUnmount } from 'vue';
import StarterKit from '@tiptap/starter-kit';
import { Editor, EditorContent } from '@tiptap/vue-3';
import Underline from '@tiptap/extension-underline';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import CharacterCount from '@tiptap/extension-character-count';
import Placeholder from '@tiptap/extension-placeholder';
import { PreventNewLines } from './custom_plugins/PreventNewLines';

const props = defineProps({
  disableNewLines: {
    type: Boolean,
    default: false,
  },
  maxCharacters: {
    type: Number,
    required: false,
  },
  minHeight: {
    type: String,
    default: '200px',
  },
  modelValue: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    required: false,
  },
  toolbarActions: {
    type: Object,
    default: () => ({
      bold: true,
      italic: true,
      underline: true,
      strike: true,
    }),
  },
});

const emits = defineEmits(['update:model-value']);

const editor = ref(null);

// todo: reactivity is broken here, whoever changes logic here next, please look if it can be fixed (use `() => props.modelValue` instead of `props.modelValue`)
// eslint-disable-next-line vue/no-setup-props-reactivity-loss
watch(props.modelValue, value => {
  const isSame =
    JSON.stringify(this.editor.getJSON()) === JSON.stringify(value);

  if (isSame) {
    return;
  }

  editor.value.commands.setContent(value, false);
});

onMounted(() => {
  editor.value = new Editor({
    extensions: [
      StarterKit,
      Underline,
      CharacterCount.configure({
        limit: props.maxCharacters,
      }),
      Placeholder.configure({
        placeholder: props.placeholder || 'Write something...',
      }),
      // only use PreventNewLines if disableNewLines is true
      props.disableNewLines ? PreventNewLines : null,
    ],
    content: props.modelValue,
    onUpdate: () => {
      emits('update:model-value', editor.value.getJSON());
    },
  });
});

// destroy the editor and all its event listeners
onBeforeUnmount(() => {
  editor.value.destroy();
});
</script>

<template>
  <div class="soona-editor">
    <div class="soona-editor__header">
      <slot name="label" />
      <div
        v-if="editor && props.maxCharacters"
        class="soona-editor__header-character-count u-label--regular"
      >
        {{ editor.storage.characterCount.characters() }}/{{
          props.maxCharacters
        }}
        characters
      </div>
    </div>

    <editor-content :editor="editor" />

    <div v-if="editor" class="soona-editor__toolbar">
      <div class="soona-editor__button-row">
        <button
          v-if="toolbarActions.underline"
          class="soona-editor__button"
          :aria-pressed="editor.isActive('underline')"
          title="underline"
          @click="editor.chain().focus().toggleUnderline().run()"
        >
          <SoonaIcon name="underline" size="small" />
        </button>
        <button
          v-if="toolbarActions.bold"
          class="soona-editor__button"
          :aria-pressed="editor.isActive('bold')"
          title="bold"
          @click="editor.chain().focus().toggleBold().run()"
        >
          <SoonaIcon name="bold" size="small" />
        </button>
        <button
          v-if="toolbarActions.italic"
          class="soona-editor__button"
          :aria-pressed="editor.isActive('italic')"
          title="italic"
          @click="editor.chain().focus().toggleItalic().run()"
        >
          <SoonaIcon name="italic" size="small" />
        </button>
        <button
          v-if="toolbarActions.strike"
          class="soona-editor__button"
          :aria-pressed="editor.isActive('strike')"
          title="strike"
          @click="editor.chain().focus().toggleStrike().run()"
        >
          <SoonaIcon name="strikethrough" size="small" />
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.soona-editor {
  :deep(.ProseMirror) {
    min-height: v-bind('props.minHeight');
    border: 0.0625rem solid variables.$gray-40;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    padding: 1rem;
  }

  :deep(.ProseMirror p.is-editor-empty:first-child::before) {
    content: attr(data-placeholder);
    float: left;
    color: variables.$gray-60;
    pointer-events: none;
    height: 0;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.25rem;
  }

  &__toolbar {
    background: variables.$gray-20;
    padding: 0.75rem;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border: 0.0625rem solid variables.$gray-40;
    border-top: 0;
  }

  &__button-row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
  }

  &__button {
    display: flex;
    align-items: center;
    padding: 0.375rem;
    border: none;
    border-radius: 0;
    background: transparent;
    cursor: pointer;
    transition: all 0.1s ease-out;
    box-sizing: border-box;
    color: variables.$gray-60;

    &[aria-pressed='true'] {
      background: variables.$gray-10;
      border-radius: 0.25rem;
      box-shadow: inset 0 0 0 0.0625rem variables.$gray-40;
    }

    &:hover,
    &:focus-visible {
      background: variables.$gray-10;
      border-radius: 0.25rem;
      box-shadow: inset 0 0 0 0.0625rem variables.$gray-40;
    }
  }
}
</style>
