<template>
  <SoonaDialog @close="closeModal">
    <template #heading>
      <span v-if="!deleting">
        {{ currentEvent ? 'edit' : 'new' }} unavailability event
      </span>
      <span v-else>delete event</span>
    </template>

    <template v-if="!deleting">
      <SoonaForm
        id="unavilability-form"
        :aria-describedby="
          dateTimesAreInvalid ? 'unavilability-form-invalid' : undefined
        "
        @submit="createOrUpdate"
      >
        <p
          v-if="dateTimesAreInvalid"
          id="unavilability-form-invalid"
          role="alert"
        >
          end time must be after start time.
        </p>
        <div class="date-fields">
          <SoonaTextfield
            v-model:model-value="startDate"
            type="date"
            name="startDate"
            label="start date"
            cypress-name="start-date"
            :min="dateRange.minDate"
            :max="startDateMax"
            :required="true"
            :rules="['min', 'max', 'required']"
          />
          <SoonaTextfield
            v-model:model-value="startTime"
            type="time"
            name="startTime"
            label="start time"
            cypress-name="start-time"
            :step="900"
            :required="true"
            :rules="['required', 'step']"
          >
            <template #helper>
              {{ displayTimeZone }}
            </template>
          </SoonaTextfield>
        </div>
        <div class="date-fields">
          <SoonaTextfield
            v-model:model-value="endDate"
            type="date"
            name="endDate"
            label="end date"
            cypress-name="end-date"
            :min="endDateMin"
            :max="dateRange.maxDate"
            :required="true"
            :rules="['min', 'max', 'required']"
          />
          <SoonaTextfield
            v-model:model-value="endTime"
            type="time"
            name="endTime"
            label="end time"
            cypress-name="end-time"
            :step="900"
            :required="true"
            :rules="['required', 'step']"
          >
            <template #helper>
              {{ displayTimeZone }}
            </template>
          </SoonaTextfield>
        </div>
      </SoonaForm>
    </template>
    <template v-else>
      <p>are you sure you want to delete this event? this cannot be undone.</p>
    </template>

    <template #footer="{ close }">
      <SoonaButton
        v-if="currentEvent && !deleting"
        class="delete-event-btn"
        copy="delete event"
        variation="tertiary"
        data-cypress="delete-unavailability"
        @on-click="deleteModal"
      />
      <SoonaButton
        copy="cancel"
        :disabled="loading"
        variation="tertiary"
        @on-click="close"
      />
      <SoonaButton
        v-if="deleting"
        copy="ok"
        :disabled="loading"
        :loading="loading"
        data-cypress="confirm-delete-unavailability"
        @on-click="() => deleteUnavailabilityEvent(currentEvent)"
      />
      <SoonaButton
        v-else
        copy="save"
        :disabled="dateTimesAreInvalid || loading"
        form="unavilability-form"
        :loading="loading"
        type="submit"
        data-cypress="save-unavailability"
      />
    </template>
  </SoonaDialog>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaForm from 'src/components/ui_library/SoonaForm.vue';
import SoonaTextfield from 'src/components/ui_library/SoonaTextfield.vue';

/**
 * @description date and time strings need the month, data, hour,
 * and minute segments to be two characters, padded with '0's
 * @param {number} num
 * @returns string
 */
function padNum(num) {
  return num.toString().padStart(2, '0');
}

/**
 *
 * @param {Date} date
 * @returns string
 */
function formatDateForInput(date) {
  // yyyy-mm-dd format at local time
  return `${date.getFullYear()}-${padNum(date.getMonth() + 1)}-${padNum(
    date.getDate()
  )}`;
}

/**
 *
 * @param {Date} date
 * @returns string
 */
function formatTimeForInput(date) {
  // HH:MM (24 hour format) format at local time
  return `${padNum(date.getHours())}:${padNum(date.getMinutes())}`;
}

export default {
  name: 'UnavailabilityEventModal',
  components: {
    SoonaButton,
    SoonaDialog,
    SoonaForm,
    SoonaTextfield,
  },
  props: ['currentEvent', 'timezone', 'closeModal'],
  data() {
    const today = new Date();
    const minDate = new Date(today.setDate(today.getDate() - 1));
    const maxDate = new Date(
      today.getFullYear() + 1,
      today.getMonth(),
      today.getDate()
    );

    return {
      loading: false,
      deleting: false,
      startDate: this.determineStart(),
      endDate: this.determineEnd(),
      startTime: this.determineStartTime(),
      endTime: this.determineEndTime(),
      dateRange: {
        minDate: formatDateForInput(minDate),
        maxDate: formatDateForInput(maxDate),
      },
    };
  },
  computed: {
    ...mapState({
      account: state => state.account,
    }),
    displayTimeZone() {
      return this.determineTimeZoneName();
    },
    dateTimesAreInvalid() {
      const allFieldsSelected =
        this.startDate && this.endDate && this.startTime && this.endDate;
      if (allFieldsSelected) {
        // invalid if start date is greater than end date
        return (
          this.formatTimeToSubmit(this.startDate, this.startTime).getTime() >
          this.formatTimeToSubmit(this.endDate, this.endTime).getTime()
        );
      }
      return false;
    },
    startDateMax() {
      if (this.endDate) {
        return this.endDate;
      }
      return this.dateRange.maxDate;
    },
    endDateMin() {
      if (this.startDate) {
        return this.startDate;
      }
      return this.dateRange.minDate;
    },
  },
  methods: {
    ...mapActions('events', ['createUserEvent', 'updateEvent', 'deleteEvent']),
    async createOrUpdate() {
      this.loading = true;
      if (this.currentEvent) {
        await this.updateEvent({
          eventId: this.currentEvent.id,
          event: {
            start: this.formatTimeToSubmit(this.startDate, this.startTime),
            end: this.formatTimeToSubmit(this.endDate, this.endTime),
          },
        });
      } else {
        await this.createUserEvent({
          userId: this.account.owner.id,
          event: {
            start: this.formatTimeToSubmit(this.startDate, this.startTime),
            end: this.formatTimeToSubmit(this.endDate, this.endTime),
            type: 'UnavailabilityEvent',
          },
        });
      }
      this.loading = false;
      this.resetForm();
      this.closeModal();
    },
    deleteModal() {
      this.deleting = true;
    },
    async deleteUnavailabilityEvent(event) {
      this.loading = true;
      await this.deleteEvent({
        eventId: event.id,
      });
      this.loading = false;
      this.closeModal();
    },
    determineStart() {
      if (this.currentEvent) {
        const date = new Date(this.currentEvent.start);
        return formatDateForInput(date);
      }
      return '';
    },
    determineStartTime() {
      if (this.currentEvent) {
        const date = new Date(this.currentEvent.start);
        return formatTimeForInput(date);
      }
      return '';
    },
    determineEnd() {
      if (this.currentEvent) {
        const date = new Date(this.currentEvent.end);
        return formatDateForInput(date);
      }
      return '';
    },
    determineEndTime() {
      if (this.currentEvent) {
        const date = new Date(this.currentEvent.end);
        return formatTimeForInput(date);
      }
      return '';
    },
    determineTimeZoneName() {
      var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      let date = new Date();
      let options = {
        timeZone: timeZone,
        timeZoneName: 'short',
        hour: 'numeric',
        minute: 'numeric',
      };
      let shortname = new Intl.DateTimeFormat('en-US', options)
        .formatToParts(date)
        .filter(option => option.type === 'timeZoneName')[0].value;
      return shortname;
    },
    formatTimeToSubmit(date, time) {
      return new Date(`${date}T${time}`);
    },
    cancel() {
      this.resetForm();
      this.closeModal();
    },
    cancelDelete() {
      this.deleting = false;
      this.closeModal();
    },
    resetForm() {
      this.startDate = '';
      this.endDate = '';
      this.startTime = '';
      this.endTime = '';
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';

.date-fields {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: stretch;
  margin-bottom: 0.5rem;

  :deep(.soona-textfield) {
    padding-bottom: 0;
    width: 100%;
  }
}

form > [role='alert'] {
  color: variables.$friendly-red-50;
  letter-spacing: normal;
}

.delete-event-btn {
  margin-right: auto;
}

@media (min-width: variables.$screen-sm-min) {
  .date-fields {
    flex-direction: row;
  }
}
</style>
