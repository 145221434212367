<script setup>
import { computed } from 'vue';
import AssetAddOns from '@/components/checkout/AssetAddOns.vue';
import AssetHeading from '@/components/checkout/AssetHeading.vue';
import AssetImage from '@/components/checkout/AssetImage.vue';
import StandardEditCopy from '@/components/checkout/StandardEditCopy.vue';

const props = defineProps({
  accountId: {
    required: true,
    type: Number,
  },
  addOns: {
    default: () => [],
    type: Array,
  },
  asset: {
    required: true,
    type: Object,
  },
  isOrder: {
    default: false,
    required: true,
  },
  reservationId: {
    type: Number,
    required: true,
  },
});

const accountId = computed(() => props.accountId);
const asset = computed(() => props.asset);
const assetId = computed(() => asset.value.id);

const assetTotal = computed(
  () =>
    +props.addOns.find(
      a => a.product.name.includes('Add On') || a.product.name === 'gif'
    )?.amount_collected || 0
);
</script>

<template>
  <div class="checkout-asset" :data-cypress="`checkout-asset-${asset.id}`">
    <AssetImage
      :content-type="asset.media_type"
      :watermark-url="asset.preview_url || asset.preview?.url"
      :image-url="asset.preview_url || asset.preview?.url"
      :is-video="asset.media_type === 'video'"
      :is-gif="asset.media_type === 'animation'"
      :title="asset.title"
    />
    <div class="checkout-asset__right">
      <AssetHeading
        :asset-id="assetId"
        :reservation-id="reservationId"
        :show-link="false"
        :show-price="isOrder"
        :title="asset.title"
        :total="assetTotal"
      />
      <AssetAddOns
        v-if="addOns.length"
        :account-id="accountId"
        :add-on-list="
          addOns.filter(
            a => !a.product.name.includes('Add On') && a.product.name !== 'gif'
          )
        "
        :is-photo="asset.media_type === 'photo'"
        read-only
        :reservation-id="reservationId"
      />
      <StandardEditCopy v-if="addOns.length" />
    </div>
    <div v-if="asset.note" class="checkout-asset__bottom">
      <p class="u-label--heavy">editing notes</p>
      <p class="u-body--regular">{{ asset.note.content }}</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.checkout-asset {
  background-color: variables.$white-default;
  border-bottom: 0.0625rem solid variables.$gray-30;
  display: grid;
  grid-template-columns: 6.25rem auto;
  grid-template-rows: auto min-content 1fr auto;
  padding: 1rem;

  &__right {
    grid-area: 2 / 2 / 2 / 2;
    margin-left: 0.75rem;
    padding-bottom: 1rem;

    > ul {
      padding-bottom: 0.5rem;
    }
  }

  &__bottom {
    grid-area: 3 / 1 / 3 / 3;

    + p {
      padding-bottom: 0.25rem;
    }
  }

  @media (min-width: variables.$screen-md-min) {
    grid-template-columns: 10.25rem auto;

    &__bottom {
      grid-area: 3 / 2 / 3 / 2;
      margin-left: 0.75rem;
    }
  }
}
</style>
