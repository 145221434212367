<script setup>
import SoonaToggle from 'src/components/ui_library/SoonaToggle.vue';
import SoonaEditor from 'src/components/ui_library/soona_editor/SoonaEditor.vue';

const props = defineProps({
  bookingBannerFormEnabled: {
    type: Boolean,
    required: true,
  },
  description: {
    type: Object,
    required: false,
  },
  copyCodeToggle: {
    type: Boolean,
    required: true,
  },
  copyCodeEnabled: {
    type: Boolean,
    required: true,
  },
});

const emits = defineEmits(['update:model-value']);

const handleCopyToggle = value => {
  emits('update:model-value', 'copy_code_enabled', value);
};
</script>

<template>
  <section class="banner-section">
    <div class="banner-section__header">
      <h3 class="u-title--heavy">banner in booking flow</h3>
      <div class="banner-section__toggle">
        <SoonaToggle
          :model-value="props.bookingBannerFormEnabled"
          align="right"
          type="switch"
          @update:model-value="emits('update:model-value', 'enabled', $event)"
        />
      </div>
    </div>

    <div class="banner-section__divider" />

    <div
      v-if="props.bookingBannerFormEnabled"
      class="banner-section__form-container"
    >
      <SoonaEditor
        id="banner-copy"
        name="banner-copy"
        class="banner-section__soona-editor"
        :disable-new-lines="true"
        :max-characters="75"
        :min-height="`fit-content`"
        :model-value="props.description"
        :placeholder="`enter the copy for terms and conditions of the promo!`"
        @update:model-value="emits('update:model-value', 'description', $event)"
      >
        <template #label>
          <label class="u-body--regular" for="banner-copy">banner copy*</label>
        </template>
      </SoonaEditor>
    </div>

    <div
      v-if="props.copyCodeToggle && props.bookingBannerFormEnabled"
      class="banner-section__toggle-field"
    >
      <label class="u-body--regular" for="discount-code"
        >copy code button
      </label>
      <div class="banner-section__toggle">
        <SoonaToggle
          :model-value="copyCodeEnabled"
          align="right"
          type="switch"
          @update:model-value="handleCopyToggle"
        />
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
@use '@/variables';

.banner-section {
  margin-bottom: 16px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__divider {
    width: 100%;
    height: 1px;
    background: variables.$gray-30;
    margin: 8px 0;
  }

  &__form-container {
    display: flex;
    flex-direction: column;
  }

  &__soona-editor {
    margin-bottom: 16px;
  }

  &__toggle {
    display: flex;
    align-items: center;

    :deep(.soona-toggle-wrapper) {
      display: block;
    }
    :deep(.soona-toggle-label) {
      display: inline !important;
    }
  }
  &__toggle-field {
    display: flex;
    align-items: center;
    margin: 8px 0;
  }
}
</style>
