<script setup>
import { computed } from 'vue';
import {
  browserTimeZone,
  convertToDayLong,
  convertToHHMM12,
  convertToMMDDYYYYmonthAbr,
} from '@/lib/date-formatters';

const props = defineProps({
  date: {
    type: [String, Date],
    required: true,
  },
  isUpcoming: {
    type: Boolean,
    required: true,
  },
});

const dateObj = computed(() => new Date(props.date));
const datetime = computed(() => dateObj.value.toISOString());

const dayOfWeek = computed(() => convertToDayLong(dateObj.value));
const displayDate = computed(() => convertToMMDDYYYYmonthAbr(dateObj.value));
const displayTime = computed(
  () => convertToHHMM12(dateObj.value) + ' ' + browserTimeZone()
);
</script>

<template>
  <template v-if="isUpcoming">
    <time :datetime="datetime" class="res-date-primary">
      <span class="u-lowercase">{{ dayOfWeek }}</span
      ><span class="u-lowercase res-date-primary__date">{{ displayDate }}</span
      ><span class="u-a11y-only">&nbsp;{{ displayTime }}</span>
    </time>
    <p class="res-date-secondary" aria-hidden="true">
      {{ displayTime }}
    </p>
  </template>
  <time v-else :datetime="datetime" class="u-lowercase res-date-secondary">
    {{ displayDate }}
  </time>
</template>

<style scoped lang="scss">
@use '@/variables';
@use '@/variables_fonts';

.res-date-primary {
  @include variables_fonts.u-title--heavy;
  color: variables.$black-default;

  &__date::before {
    content: '|';
    color: variables.$friendly-red-50;
    padding-left: 0.25em;
    padding-right: 0.25em;
  }
}

.res-date-secondary {
  @include variables_fonts.u-subheader--regular;
  color: variables.$black-default;
}
</style>
