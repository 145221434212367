<template>
  <div class="ProServiceSelectionSummaryCard">
    <button
      :id="'extras-summary-expand-' + proServiceRequirement.id"
      class="extras-summary-header-button u-button-reset"
      :aria-expanded="isExtraDetailsOpen"
      :aria-controls="
        'extras-summary-expand-region-' + proServiceRequirement.id
      "
      @click="toggleFilterOpen"
    >
      <h2 v-if="pspType === 'model'" class="title">MODEL DETAILS & EXTRAS</h2>
      <h2 v-else class="title">DETAILS & NOTES</h2>
      <SoonaIcon class="header-chevron" name="chevron-down" />
      <span class="u-a11y-only">toggle pro service filters</span>
    </button>
    <div v-if="isExtraDetailsOpen" class="extra-details-wrapper">
      <div v-if="proServiceRequirement.pro_service_extras?.note">
        <hr class="line" />
        <div class="general-note">
          <span class="service-icon">📝</span>
          <span class="service-text">
            <h3 class="u-body--heavy">note to soona:</h3>
            <p>{{ proServiceRequirement.pro_service_extras.note }}</p>
          </span>
        </div>
      </div>
      <div
        v-for="(
          value, propertyName
        ) in proServiceRequirement.pro_service_extras"
        :key="propertyName"
        class="extras-section"
      >
        <hr
          v-if="value.selection || (propertyName === 'grocery_list' && value)"
          class="line"
        />
        <span class="bottom-wrapper">
          <span class="left-side">
            <span class="service-icon">{{ serviceIcon(propertyName) }}</span>
            <span class="service-text">
              <h3 v-if="propertyName !== 'note'" class="u-body--heavy">
                {{
                  propertyName === 'hmu'
                    ? 'hair & makeup'
                    : propertyName.replaceAll('_', ' ')
                }}: {{ selectionText(propertyName, value.selection) }}
                <a
                  v-if="
                    isFullBodyModelStandardWardrobe(
                      propertyName,
                      value.selection
                    )
                  "
                  class="service-link"
                  href="https://soona.co/pro-services/model-standard-wardrobe"
                  target="_blank"
                  rel="noopener noreferrer"
                  >more details</a
                >
              </h3>
              <div v-if="propertyName === 'grocery_list'" class="grocery-list">
                {{ value }}
              </div>
              <span
                class="selection-note"
                v-html="detailsSubText(value.selection, value.selectionNote)"
              ></span>
            </span>
          </span>
          <div v-if="imageSource(propertyName, value)" class="right-side">
            <span
              v-viewer="{
                toolbar: true,
                navbar: false,
                title: false,
                backdrop: true,
                zoomable: false,
                keyboard: true,
                scalable: false,
                fullscreen: false,
              }"
            >
              <img
                :src="imageSource(propertyName, value)"
                :class="
                  value.selection == 'press-on manicure' ? 'press-on' : ''
                "
              />
            </span>
          </div>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';

export default {
  name: 'ProServiceSelectionSummaryCard',
  components: {
    SoonaIcon,
  },
  props: {
    proServiceRequirement: Object,
    reservation: Object,
  },
  data() {
    return {
      isExtraDetailsOpen: true,
    };
  },
  computed: {
    ...mapState({
      pressOnColorOptions: state => state.proService.pressOnColorOptions,
    }),
    pspType() {
      if (
        this.proServiceRequirement.service_tag.title === 'hand model' ||
        this.proServiceRequirement.service_tag.title === 'full body model' ||
        this.proServiceRequirement.service_tag.title === 'pet model' ||
        this.proServiceRequirement.service_tag.title === 'foot model'
      ) {
        return 'model';
      } else {
        return null;
      }
    },
  },
  methods: {
    imageSource(propertyName, value) {
      for (let option of this.pressOnColorOptions) {
        if (option.label === value.selectionNote) {
          return option.image;
        }
      }
      if (
        value.selection === 'yes' &&
        this.proServiceRequirement?.hmu_reference_image?.url
      ) {
        return this.proServiceRequirement.hmu_reference_image.url;
      } else if (
        value.selection === 'manicure custom' &&
        this.proServiceRequirement?.manicure_reference_image?.url
      ) {
        return this.proServiceRequirement.manicure_reference_image.url;
      } else if (
        value.selection === 'pedicure custom' &&
        this.proServiceRequirement?.pedicure_reference_image?.url
      ) {
        return this.proServiceRequirement.pedicure_reference_image.url;
      } else if (
        propertyName === 'grocery_list' &&
        this.proServiceRequirement?.grocery_shopping_reference_image?.url
      ) {
        return this.proServiceRequirement.grocery_shopping_reference_image.url;
      }
      return value.refImgUrl ? value.refImgUrl : null;
    },
    serviceIcon(propertyName) {
      if (propertyName === 'hmu') {
        return '👩‍🎤';
      } else if (propertyName === 'manicure' || propertyName === 'pedicure') {
        return '💅';
      } else if (propertyName === 'wardrobe') {
        return '👔';
      } else if (propertyName === 'grocery_list') {
        return '🍊';
      } else if (propertyName === 'shaved_legs') {
        return '🦵';
      }
      return '';
    },
    selectionText(propertyName, value) {
      if (propertyName === 'hmu') {
        return value === 'no'
          ? 'model will arive with clean natural hair + little to no makeup'
          : value + ' - this model';
      } else if (propertyName === 'manicure' || propertyName === 'pedicure') {
        return value === 'bare'
          ? 'model will arrive with clean bare nails'
          : value;
      } else if (propertyName === 'wardrobe') {
        if (value === 'standard wardrobe') {
          return this.proServiceRequirement.service_tag.title ===
            'full body model' ||
            this.proServiceRequirement.service_tag.title === 'foot model'
            ? 'models will arrive with standard wardrobe pieces.'
            : 'pet model will arrive with no wardrobe';
        } else {
          return 'custom wardrobe';
        }
      } else if (propertyName === 'shaved_legs') {
        switch (value) {
          case 'yes':
            return 'model will arrive with shaved legs';
          case 'no':
            return 'model will not arrive with shaved legs';
          default:
            return 'no preference as to whether the model has shaved legs';
        }
      }
      return '';
    },
    isFullBodyModelStandardWardrobe(propertyName, value) {
      return (
        propertyName === 'wardrobe' &&
        value === 'standard wardrobe' &&
        this.proServiceRequirement.service_tag.title === 'full body model'
      );
    },
    detailsSubText(selction, selectionNote) {
      return selction === 'custom wardrobe'
        ? `<p> view model details for sizes. send multiple sizes to ensure model fit to:</p>
          <p>soona REF ${this.reservation.id}</p><p>${this.reservation.location.address1}</p> <p>${this.reservation.location.city}, ${this.reservation.location.state}, ${this.reservation.location.zip}</p>`
        : selectionNote;
    },
    toggleFilterOpen() {
      this.isExtraDetailsOpen = !this.isExtraDetailsOpen;
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.ProServiceSelectionSummaryCard {
  background-color: variables.$periwink-blue-10;
  padding: 1.25rem 1.25rem 0.4375rem;
  width: 100%;

  .extras-summary-header-button {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.625rem;

    .title {
      @include variables_fonts.u-subheader--all-caps-black;

      margin-bottom: 0rem;
      color: variables.$black-default;
    }

    .header-chevron {
      margin-left: 5px;
      color: variables.$black-default;
      transition: transform 300ms ease-out;
      cursor: pointer;
    }
  }

  .extras-summary-header-button[aria-expanded='true'] {
    .header-chevron {
      transform: rotate(180deg);
    }
  }

  .extra-details-wrapper {
    margin-bottom: 0.8125rem;
  }

  .line {
    background-color: variables.$gray-30;
    height: 0.0625rem;
    margin: 0.625rem 0;
  }

  .general-note {
    display: flex;
    width: 75%;
    margin-bottom: 10px;
  }

  .service-icon {
    width: 45px !important;
    font-size: 25px;
  }

  .service-text {
    display: flex;
    flex-direction: column;
    margin-top: 7px;
    max-width: 85% !important;

    .grocery-list {
      white-space: pre-wrap;
      margin-top: 0.625rem;
    }
  }

  .service-link {
    font-weight: 400;
    text-decoration: underline;
  }

  .extras-section {
    display: flex;
    flex-direction: column;

    .bottom-wrapper {
      display: flex;

      .left-side {
        display: flex;
        width: 85%;
      }

      .right-side {
        width: 25%;
        justify-content: flex-end;
        display: flex;

        img {
          height: 5.625rem;
          width: 5.625rem;
          object-fit: cover;
          border-radius: 0.3125rem;

          &.press-on {
            height: 2.0625rem;
            width: 2.0625rem;
          }

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: variables.$screen-xxs-max) {
  .ProServiceSelectionSummaryCard {
    .extras-summary-header-button {
      .title {
        font-size: 1rem;
      }
    }

    .bottom-wrapper {
      flex-direction: column;

      .left-side {
        width: 100% !important;
      }

      .right-side {
        width: 100% !important;
        justify-content: flex-start !important;
        margin: 0.9375rem 0 0.9375rem 2.8125rem !important;
      }
    }
  }
}
</style>
