export const testPagePaths = [
  {
    path: '/soona-icon',
    name: 'soonaIconTestPage',
    component: () =>
      import('@/components/ui_library/soona_icon/SoonaIconTestPage.vue'),
    meta: {
      requires_auth: true,
      requires_capability: 'soona_staff',
    },
  },
  {
    path: '/soona-toast',
    name: 'soonaToastTestPage',
    component: () =>
      import('@/components/ui_library/soona_toast/SoonaToastTestPage.vue'),
    meta: {
      requires_auth: true,
      requires_capability: 'soona_staff',
    },
  },
  {
    path: '/soona-skeleton',
    name: 'soonaSkeletonTestPage',
    component: () =>
      import('@/components/ui_library/SoonaSkeletonTestPage.vue'),
    meta: {
      requires_auth: true,
      requires_capability: 'soona_staff',
    },
  },
  {
    path: '/soona-button',
    name: 'soonaButtonTestPage',
    component: () => import('@/components/ui_library/SoonaButtonTestPage.vue'),
    meta: {
      requires_auth: true,
      requires_capability: 'soona_staff',
    },
  },
  {
    path: '/soona-dialog',
    name: 'soonaDialogTestPage',
    component: () => import('@/components/ui_library/SoonaDialogTestPage.vue'),
    meta: {
      requires_auth: true,
      requires_capability: 'soona_staff',
    },
  },
];
