<script setup>
import { ref } from 'vue';
import SoonaMeter from '@/components/ui_library/SoonaMeter.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import IntegrateAccountsDialog from '@/components/shared/IntegrateAccountsDialog.vue';

const showConnectDialog = ref(false);
</script>
<template>
  <div class="get-score-meter">
    <div class="get-score-meter__label">
      <SoonaIcon name="amazon-logo" />
      <SoonaIcon name="shopify-logo" />
      <p class="u-subheader--heavy">how do yours rate?</p>
    </div>
    <div class="get-score-meter__meter-wrapper">
      <div class="get-score-meter__meter-section">
        <p class="get-score-meter__score u-display--regular">
          <span class="u-display--heavy">00</span>/100
        </p>
        <SoonaMeter
          class="get-score-meter__meter"
          accessible-title="overall score"
        />
      </div>
      <SoonaButton
        variation="solid-black"
        class="get-score-meter__cta"
        @click="showConnectDialog = true"
      >
        get your score
        <template #icon-right>
          <SoonaIcon name="arrow-right" />
        </template>
      </SoonaButton>
    </div>
    <IntegrateAccountsDialog
      v-if="showConnectDialog"
      @close="showConnectDialog = false"
    />
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables';

.get-score-meter {
  display: flex;
  flex-direction: column;
  box-shadow: variables.$elevation-1;
  background-color: variables.$white-default;
  border-radius: 0.625rem;
  margin: 0 1rem;
  position: relative;
  top: -6.25rem;
  padding: 2rem;
  gap: 1rem;

  &__score {
    color: variables.$gray-60;
  }

  &__meter-section {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
  }

  &__meter {
    flex-grow: 1;
  }

  &__cta {
    flex: none;
    white-space: nowrap;
    width: 100%;
  }

  &__label,
  &__meter-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  &__meter-wrapper {
    align-items: center;
    gap: 1rem;
  }

  @media (min-width: variables.$screen-xxs-min) {
    gap: 0;

    &__meter-section {
      flex-wrap: nowrap;
      gap: 1rem;
    }
  }

  @media (min-width: 640px) {
    margin: 0 2rem;

    &__label,
    &__meter-wrapper {
      flex-wrap: nowrap;
    }

    &__cta {
      width: auto;
    }
  }
}
</style>
