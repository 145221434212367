<script setup>
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useRoute } from 'vue-router';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';

const props = defineProps({
  accountName: {
    default: null,
    type: String,
  },
  albumTitle: {
    default: null,
    type: String,
  },
  isMyAccount: {
    default: true,
    type: Boolean,
  },
});

const { linkClicked } = useBaseEvents();
const route = useRoute();

const crumbCopy = copy => {
  if (props.albumTitle && copy === 'album_title') return props.albumTitle;
  if (copy === 'album_title') return 'untitled album';
  if (!props.isMyAccount && copy === 'my albums') {
    return `${props.accountName}'s albums`;
  }
  return copy;
};

const trackClick = (target, label) => {
  const href = target.parentElement?.attributes?.href?.value?.slice(1);

  linkClicked({
    context: route.meta.context,
    subContext: 'breadcrumbs',
    linkLabel: label,
    linkHref: href,
  });
};
</script>

<template>
  <nav class="breadcrumbs" aria-label="breadcrumb">
    <ol>
      <li
        v-for="(crumb, i) of route.meta.breadcrumbs"
        :key="`${crumb.routeName}-${i}`"
      >
        <SoonaButton
          v-if="crumb.routeName"
          element="router-link"
          :copy="crumbCopy(crumb.copy)"
          :to="{ name: crumb.routeName }"
          variation="tertiary"
          @on-click="e => trackClick(e.target, crumb.copy)"
        />
        <span v-else aria-current="page">{{ crumbCopy(crumb.copy) }}</span>
      </li>
    </ol>
  </nav>
</template>

<style lang="scss" scoped>
@use '@/variables';

.breadcrumbs {
  color: variables.$gray-60;

  ol {
    display: flex;
    gap: 0.25rem;
    flex-wrap: wrap;
    list-style: none;
  }

  li {
    display: flex;
    gap: 0.25rem;
  }

  li + li::before {
    display: inline-block;
    margin: 0 0.25em;
    transform: rotate(15deg);
    border-right: 0.1em solid currentcolor;
    height: 0.8em;
    content: '';
    align-self: center;
  }
}
</style>
