<template>
  <div class="InventoryPackageForm">
    <div class="title-wrapper">
      <h1 class="title">package details</h1>
      <display-post-shoot-option
        v-if="inventoryPackage"
        :inventory-package="inventoryPackage"
      />
    </div>
    <div class="top-wrapper">
      <div class="package-details">
        <div v-if="errors && errors.length" class="has-text-danger">
          <p><strong>please correct the following errors</strong></p>
          <p v-for="error in errors" :key="error">
            {{ error }}
          </p>
        </div>
        <div class="InventoryPackageForm__flex">
          <div v-if="inventoryPackage" class="InventoryPackageForm__id">
            <label class="formLabel" for="package-id">package ID</label>
            <p id="package-id">{{ inventoryPackage.id }}</p>
          </div>
          <div class="InventoryPackageForm__status">
            <label class="formLabel" for="select-status">package status</label>
            <div
              class="select soona-select is-fullwidth package-status-select-wrapper"
            >
              <select id="select-status" v-model="status">
                <option
                  v-for="(item, value) in getPackageStatuses"
                  :key="item"
                  :value="value"
                >
                  {{ item }}
                </option>
              </select>
            </div>
          </div>
          <div
            v-if="inventoryPackage.storage_label_qr_code"
            class="storage-label"
          >
            <img :src="inventoryPackage.storage_label_qr_code" />
            <SoonaButton
              size="small"
              element="a"
              copy="view label"
              variation="tertiary"
              target="_blank"
              :href="`/inventory_packages/${inventoryPackage.id}/storage_label`"
            />
          </div>
        </div>
        <div class="delivery-wrapper">
          <div class="delivery-method">
            <label class="formLabel" for="select-provider">
              delivery method
            </label>
            <div class="select soona-select is-fullwidth">
              <select
                id="select-provider"
                v-model="shippingDetail.selectedProvider"
                :disabled="isExistingPackage"
              >
                <option
                  v-for="item in getProviders"
                  :key="item.id"
                  :value="item.name"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="tracking">
            <SoonaTextfield
              v-model:model-value="shippingDetail.trackingNumber"
              label="tracking number"
              type="text"
              :disabled="
                disableTrackingNumberEdit ||
                shippingDetail.selectedProvider === 'drop off'
              "
              :placeholder="
                shippingDetail.selectedProvider === 'drop off'
                  ? ''
                  : 'e.g. 124315135'
              "
            >
              <template
                v-if="
                  disableTrackingNumberEdit &&
                  shippingDetail.selectedProvider !== 'drop off'
                "
                #helper
              >
                <button
                  class="tracking-edit-btn u-button-reset"
                  @click="clickAllowTrackingNumberEdit"
                >
                  <SoonaIcon name="pen-square" size="small" />
                  <span class="u-a11y-only"
                    >allow editing of tracking number</span
                  >
                </button>
              </template>
            </SoonaTextfield>
          </div>
        </div>
        <div v-if="useCustomProvider" class="custom-wrapper">
          <SoonaTextfield
            v-model:model-value="shippingDetail.customProvider"
            label="custom provider"
            :disabled="isExistingPackage"
            type="text"
            placeholder="enter your provider"
          />
        </div>
        <div class="package-dimensions">
          <div class="dimension-item">
            <SoonaTextfield
              v-model:model-value="length"
              label="length"
              type="number"
              placeholder="inches"
            />
          </div>

          <div class="dimension-item">
            <SoonaTextfield
              v-model:model-value="width"
              label="width"
              type="number"
              placeholder="inches"
            />
          </div>

          <div class="dimension-item">
            <SoonaTextfield
              v-model:model-value="height"
              label="height"
              type="number"
              placeholder="inches"
            />
          </div>

          <div class="dimension-item">
            <SoonaTextfield
              v-model:model-value="weight"
              label="weight"
              type="number"
              placeholder="pounds"
            />
          </div>
        </div>
        <div class="storage-wrapper">
          <div id="store-at-studio-wrapper" class="content-details">
            <label class="formLabel" for="stored-at-studio">
              stored at studio
            </label>
            <div class="select soona-select is-fullwidth">
              <select id="stored-at-studio" v-model="storedAtStudio">
                <option
                  v-for="location in studioLocationOptions"
                  :key="location.value"
                  :value="location.value"
                >
                  {{ location.label }}
                </option>
              </select>
            </div>
          </div>

          <div id="storage-location-wrapper" class="content-details">
            <SoonaTextfield
              v-model:model-value="storageLocation"
              label="storage location"
              type="text"
              placeholder="e.g. dfs - row 1 - bin 4"
            />
          </div>
        </div>
        <div class="content-details">
          <SoonaTextfield
            v-model:model-value="description"
            label="package contents"
            element="textarea"
            :maxlength="160"
            class="package-content-textfield"
          />
        </div>
      </div>
      <div class="tracking-label-upload">
        <div v-if="shippingDetail.labelImageUrl">
          <img :src="shippingDetail.labelImageUrl" class="thumbnail" />
          <SoonaUpload
            class="replace-uploader"
            class-overide="replace-label-btn"
            :upload-complete="blob => updateLabel(shippingDetail, blob)"
            :hide-icon="true"
            :is-multiple="false"
            label="replace"
            accept=".jpeg, .jpg, .png, .gif"
          />
        </div>
        <SoonaUpload
          v-else
          class="upload-item-button"
          class-overide="upload-item-button"
          :icon-size-overide="true"
          :upload-complete="blob => updateLabel(shippingDetail, blob)"
          :hide-icon="false"
          :is-multiple="false"
          label="add shipping label photo"
          accept=".jpeg, .jpg, .png, .gif"
        />
      </div>
    </div>
    <hr />
    <div class="inventory-wrapper">
      <h2 class="title">package inventory</h2>
      <div class="inventory-items">
        <InventoryPackageItem
          v-for="item in items"
          :key="item.uniqueId"
          :account-id="accountId"
          :reservation-id="
            $route.query.reservationId ||
            (inventoryPackage.latest_reservation &&
              inventoryPackage.latest_reservation.id)
          "
          :inventory-item="item"
          @update:inventory-item="val => updateInventoryItem(val)"
        />
        <SoonaUpload
          class="upload-item-button"
          class-overide="upload-item-button"
          :icon-size-overide="true"
          :upload-complete="blob => createItemFromImage(blob)"
          :hide-icon="false"
          :is-multiple="true"
          label="add photo"
          accept=".jpeg, .jpg, .png, .gif"
        />
      </div>
    </div>
    <button
      v-if="canDelete"
      class="delete-link"
      @click="toggleConfirmDeleteModal"
    >
      delete package
    </button>
    <!-- delete confirmation modal -->
    <SoonaDialog
      v-if="showConfirmDeleteModal"
      @close="toggleConfirmDeleteModal"
    >
      <template #heading>delete package</template>
      <p>are you sure you want to delete this package?</p>
      <p>this cannot be undone.</p>
      <template #footer="{ close }">
        <SoonaButton
          variation="tertiary"
          data-cypress="button-dialog-cancel"
          @on-click="close"
        >
          cancel
        </SoonaButton>
        <SoonaButton
          data-cypress="button-dialog-confirm"
          @on-click="deleteThisPackage"
        >
          yes, delete my package
        </SoonaButton>
      </template>
    </SoonaDialog>
    <!-- -->
    <button
      class="button submit-button"
      :disabled="disabled || !isValid"
      @click="handleSave"
    >
      SAVE DETAILS
    </button>
    <button class="button cancel-button" @click="handleCancel">cancel</button>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState, useStore } from 'vuex';
import { computed } from 'vue';
import DisplayPostShootOption from './DisplayPostShootOption.vue';
import InventoryPackageItem from './InventoryPackageItem.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaTextfield from 'src/components/ui_library/SoonaTextfield.vue';
import SoonaUpload from 'src/components/uploader/SoonaUpload.vue';
import { packageStatuses } from 'src/lib/inventory/constants';
import { useInventory } from '@/composables/useInventory';
import uniqueId from 'lodash/uniqueId';

export default {
  name: 'InventoryPackageForm',
  components: {
    DisplayPostShootOption,
    InventoryPackageItem,
    SoonaButton,
    SoonaDialog,
    SoonaIcon,
    SoonaTextfield,
    SoonaUpload,
  },
  props: {
    inventoryPackage: Object,
    onSave: Function,
    errors: Object,
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const { postShootOptions } = useInventory();
    const store = useStore();

    const currentReservation = computed(
      () => store.state.reservation.currentReservation
    );

    const orderedPostShootOptions = computed(() => {
      return postShootOptions.value?.filter(
        pso => pso.value !== 'already_stored'
      );
    });

    const chosenPostShootOption = computed(() => {
      return orderedPostShootOptions.value?.find(
        pso => pso.value === currentReservation.value?.package_option
      )?.value;
    });

    return { chosenPostShootOption, orderedPostShootOptions, postShootOptions };
  },
  data() {
    return {
      status: '',
      width: '',
      height: '',
      length: '',
      weight: '',
      storedAtStudio: undefined,
      storageLocation: '',
      description: '',
      items: [],
      disableTrackingNumberEdit: false,
      shippingDetail: {
        id: '',
        customProvider: '',
        selectedProvider: '',
        trackingNumber: '',
        labelImage: undefined, // we cannot pass an empty string to ActiveStorage
        labelImageUrl: '',
      },
      showConfirmDeleteModal: false,
    };
  },
  computed: {
    ...mapGetters('reservation', ['getProviders']),
    ...mapGetters('locations', ['locations']),
    ...mapState({
      currentReservation: state => state.reservation.currentReservation,
    }),
    getPackageStatuses() {
      return packageStatuses;
    },
    studioLocationOptions() {
      let result = [];
      this.locations.forEach(x =>
        result.push({ label: x.short_name, value: x.id })
      );
      return result;
    },
    getSelectedLocation() {
      return this.locations.find(x => x.id === this.storedAtStudio);
    },
    useCustomProvider() {
      return this.shippingDetail.selectedProvider === 'other';
    },
    isShippo() {
      return this.inventoryPackage?.isShippo;
    },
    isExistingPackage() {
      return !!this.inventoryPackage?.id;
    },
    isValid() {
      const hasProviderAndNumber =
        (this.shippingDetail.selectedProvider === 'other' &&
          this.shippingDetail.customProvider &&
          this.shippingDetail.trackingNumber) ||
        this.shippingDetail.selectedProvider === 'drop off' ||
        (this.shippingDetail.selectedProvider &&
          this.shippingDetail.trackingNumber);

      return hasProviderAndNumber && this.status;
    },
    canDelete() {
      return (
        this.inventoryPackage?.id &&
        this.status === 'on_the_way' &&
        !this.isShippo
      );
    },
    accountId() {
      return this.$route.params.accountId;
    },
  },
  watch: {
    inventoryPackage: function (newData) {
      this.status = newData.status;
      this.width = newData.width;
      this.height = newData.height;
      this.length = newData.length;
      this.weight = newData.weight;
      this.storedAtStudio = newData.location?.id;
      this.storageLocation = newData.storage_location;
      this.description = newData.description;
      this.items = newData.inventory_items.map(item => ({
        ...item,
        uniqueId: item.id ?? item.uniqueId,
      }));
      this.shippingDetail.id = newData.inbound_shipping_detail?.id;
      this.shippingDetail.customProvider =
        newData.inbound_shipping_detail?.custom_provider;
      this.shippingDetail.selectedProvider =
        newData.inbound_shipping_detail?.shipping_provider;
      this.shippingDetail.trackingNumber =
        newData.inbound_shipping_detail?.tracking_number;
      this.shippingDetail.labelImage =
        newData.inbound_shipping_detail?.label_image;
      this.shippingDetail.labelImageUrl =
        newData.inbound_shipping_detail?.label_image_url;
    },
    'shippingDetail.selectedProvider': function (newData, oldData) {
      if (newData === 'drop off' || oldData === 'drop off') {
        this.shippingDetail.trackingNumber = undefined;
      }
      if (newData !== 'other') {
        this.shippingDetail.customProvider = undefined;
      }
    },
    isExistingPackage: function (newVal) {
      if (newVal) {
        this.disableTrackingNumberEdit = true;
      }
    },
  },
  async mounted() {
    await this.loadLocations();
    if (!this.isExistingPackage) {
      this.storedAtStudio = this.currentReservation.location?.id;
    }
    this.disableTrackingNumberEdit = this.isExistingPackage;
  },

  methods: {
    ...mapActions('inventoryPackages', ['deletePackage']),
    ...mapActions('locations', ['loadLocations']),
    toggleConfirmDeleteModal() {
      this.showConfirmDeleteModal = !this.showConfirmDeleteModal;
    },
    createItemFromImage(blob) {
      const newItem = {
        _destroy: null,
        description: null,
        id: null,
        image: blob.signed_id,
        image_url: `/rails/active_storage/blobs/${blob.signed_id}/${blob.filename}`,
        quantity: 1,
        uniqueId: uniqueId('inventory-item-new-'),
        catalog_item_id: null,
      };

      this.items = [...this.items, newItem];
    },
    updateLabel(shippingDetail, blob) {
      shippingDetail.labelImage = blob.signed_id;
      shippingDetail.labelImageUrl = `/rails/active_storage/blobs/${blob.signed_id}/${blob.filename}`;
    },
    deleteThisPackage() {
      let resId =
        this.$route.query.reservationId ||
        this.inventoryPackage.latest_reservation?.id;
      this.deletePackage({
        packageAttributes: { inventoryPackageId: this.inventoryPackage.id },
      });
      this.toggleConfirmDeleteModal();

      if (this.$route.query.returnToInventory) {
        this.$router.push(`/account/${this.accountId}/inventory`);
      } else if (this.$route.query.returnToShippingSummary) {
        this.$router.push(
          `/shipping_summary?location=${this.$route.query.location}`
        );
      } else if (resId) {
        this.$router.push(`/reservation/${resId}/info`);
      } else {
        this.$router.push(`/account/${this.accountId}/products/inventory`);
      }
    },
    handleSave() {
      let packagePSO =
        this.inventoryPackage && this.inventoryPackage.post_shoot_option
          ? this.inventoryPackage.post_shoot_option
          : this.chosenPostShootOption;

      const reservationId =
        this.$route.query.reservationId ??
        this.inventoryPackage.latest_reservation?.id;

      const shippingDetailAttributes = {
        id: this.shippingDetail.id,
        label_image: this.shippingDetail.labelImage,
        reservation_id: reservationId,
        shipping_provider: this.useCustomProvider
          ? this.shippingDetail.customProvider
          : this.shippingDetail.selectedProvider,
        tracking_number: this.shippingDetail.trackingNumber,
        shipping_direction: 'inbound',
      };

      const info = {
        description: this.description,
        height: this.height,
        inventory_items_attributes: this.items.map(item => {
          /* eslint-disable no-unused-vars */
          const {
            catalog_item_name,
            image_url,
            sku,
            uniqueId,
            ...adjustedItem
          } = item;

          return adjustedItem;
        }),
        length: this.length,
        reservation_id: reservationId,
        shipping_details_attributes: [shippingDetailAttributes],
        status: this.status,
        storage_location: this.storageLocation,
        location_id: this.storedAtStudio,
        post_shoot_option: packagePSO,
        weight: this.weight,
        width: this.width,
        routeQuery: this.$route.query.location,
      };
      this.onSave(info);
    },
    handleCancel() {
      if (this.$route.query.returnToInventory) {
        this.$router.push(`/account/${this.accountId}/inventory`);
      } else if (this.$route.query.returnToProductInventory) {
        this.$router.push(`/account/${this.accountId}/products/inventory`);
      } else if (this.$route.query.returnToPreviousPage) {
        this.$router.go(-1);
      } else if (this.$route.query.returnToShippingSummary) {
        this.$router.push(
          `/shipping_summary?location=${this.$route.query.location}`
        );
      } else {
        let resId =
          this.$route.query.reservationId ||
          this.inventoryPackage.latest_reservation?.id;
        if (resId) {
          this.$router.push(`/reservation/${resId}/info`);
        } else {
          this.$router.push(`/account/${this.accountId}/products/inventory`);
        }
      }
    },
    clickAllowTrackingNumberEdit() {
      this.disableTrackingNumberEdit = false;
    },
    updateInventoryItem(value) {
      let index = this.items.findIndex(x => x.uniqueId === value.uniqueId);
      let updatedItems = [...this.items];
      updatedItems[index] = value;

      this.items = updatedItems;
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

label {
  @include variables_fonts.u-label--heavy;

  color: variables.$black-default;
  text-align: left;
  margin-bottom: 0;
  padding-bottom: 0.4375rem;
  display: block;
}

.InventoryPackageForm {
  display: block;

  &__flex {
    display: flex;
  }

  &__id {
    width: 25%;
  }

  &__status {
    width: 75%;
  }
}
.title-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 0;
}
.top-wrapper {
  width: 100%;
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.storage-label {
  display: flex;
  flex-direction: column;
}

.package-details {
  width: 66%;
  .formLabel {
    font-size: 14px;
    display: block;
  }

  .package-status-select-wrapper {
    width: 55%;
    margin-bottom: 10px;
  }

  .delivery-wrapper {
    display: flex;
    justify-content: space-between;
    .delivery-method {
      width: 35%;
      #select-provider {
        margin-bottom: 10px;
      }
    }
    .tracking {
      width: 60%;
      #tracking {
        width: 55%;
      }

      :deep(.soona-textfield__label-wrapper) {
        justify-content: flex-start;
        gap: 0.25rem;
      }

      .tracking-edit {
        border: none;
        background: none;
        padding: 0 0.25rem;

        &:hover {
          color: variables.$periwink-blue-60;
        }
      }
    }
  }

  .tracking-edit-btn {
    display: inline-flex;
  }

  .custom-wrapper {
    margin-bottom: 10px;
  }

  .package-dimensions {
    display: flex;
    justify-content: space-between;
    .dimension-item {
      width: 23%;
    }
  }

  .storage-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    #store-at-studio-wrapper {
      width: 35%;
    }
    #storage-location-wrapper {
      width: 60%;
    }
  }

  .content-details {
    width: 100%;

    .package-content-textfield {
      max-width: none;
    }
  }
}

.tracking-label-upload {
  width: 200px;
  height: auto;
  .soona-uploader {
    background: variables.$gray-10;
    height: 200px;
    width: 100%;
    margin-bottom: 1rem;
    margin-top: 10px;
    padding: 1rem;
    border-radius: 3px;
    border: 0.125rem dashed variables.$gray-20;
    font-size: 12px;
    color: variables.$gray-50;
  }
  .soona-uploader:hover {
    border: 2px dashed variables.$periwink-blue-60;
    color: variables.$periwink-blue-60;
    cursor: pointer;
  }
  .thumbnail {
    margin-top: 10px;
    border: 0.0625rem solid variables.$gray-10;
  }
  .replace-uploader,
  .replace-uploader:hover {
    height: auto;
    color: variables.$white-default;
    background-color: variables.$friendly-red-50;
    border: 0.125rem solid variables.$gray-10;
    margin: 0;
    padding: 6px;
  }

  .replace-label-btn {
    z-index: 10;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    height: 28px;
    font-weight: 900;
    font-size: 12px;
    width: 100%;
    max-width: 6rem;
    margin-bottom: 1rem;
    margin-top: 0;
    display: flex;
    justify-content: space-between;
    border-radius: 999px;
    padding: 5px 0 0 16px;
    letter-spacing: 0.1rem;
  }
}

.delete-link {
  font-size: 14px;
  color: variables.$periwink-blue-60;
  text-decoration: underline;
  background-color: variables.$white-default;
  margin-top: 26px;
}

.cancel-button {
  float: right;
  margin: 20px 7px 0 0;
  font-size: 14px;
  color: variables.$periwink-blue-60;
  text-decoration: underline;
  background-color: variables.$white-default;
}

.submit-button {
  float: right;
  margin: 20px 7px 0 0;
  background-color: variables.$friendly-red-50;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: variables.$white-default;
  border: none;
  padding: 0 15px;
  font-weight: 800;
  letter-spacing: 0.1rem;
  font-size: 14px;
}

.thumbnail {
  border-radius: 3px;
  width: 100%;
  height: auto;
}

.inventory-wrapper {
  .inventory-items {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(186px, 1fr));
    grid-column-gap: 0.5rem;
    grid-row-gap: 1rem;
    justify-items: center;

    .soona-uploader.upload-item-button {
      background: variables.$gray-10;
      border: 0.125rem dashed variables.$gray-20;
      border-radius: 3px;
      font-size: 12px;
      color: variables.$gray-50;
      width: 180px;
      height: 245px;
      margin-top: 0;
    }
    .soona-uploader.upload-item-button:hover {
      border: 0.125rem dashed variables.$periwink-blue-60;
      color: variables.$periwink-blue-60;
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 1176px) {
  .top-wrapper {
    justify-content: space-evenly;
    padding-bottom: 1rem;
    .package-details {
      width: 90%;
    }
  }
  .title-wrapper {
    padding: 0 20px;
  }
  .tracking-label-upload {
    .soona-uploader {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    .thumbnail {
      margin-top: 1rem;
      height: 200px;
      object-fit: contain;
    }
  }
}

@media only screen and (max-width: 480px) {
  .inventory-wrapper {
    h2 {
      margin-left: 20px;
    }
  }
  .inventory-items {
    grid-column-gap: 0 !important;
  }
  .inventory-wrapper .inventory-items .soona-uploader {
    width: 165px !important;
  }
  .delete-link {
    margin-left: 10px;
  }
  .submit-button {
    margin-right: 15px;
  }
}
</style>
