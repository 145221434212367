<script setup>
import AssetActions from './AssetActions.vue';
import SoonaFlag from '@/components/ui_library/SoonaFlag.vue';
import SoonaSelect from '@/components/ui_library/SoonaSelect.vue';

defineProps({
  addOn: {
    required: true,
    type: Object,
  },
  flagData: {
    required: true,
    type: Object,
  },
  options: {
    required: true,
    type: Array,
  },
});

const asset = defineModel('asset', {
  default: null,
  type: Object,
});
</script>

<template>
  <div class="asset-heading">
    <div class="asset-heading__top">
      <h2 class="u-subheader--heavy">{{ asset?.title }}</h2>
      <SoonaFlag
        :background-color="flagData.backgroundColor"
        :text-color="flagData.textColor"
        :title="flagData.title"
      />
    </div>
    <div class="u-label--regular asset-heading__bottom">
      <SoonaSelect v-model="asset" :options="options" />
      <AssetActions
        v-if="asset"
        :account-id="addOn.order.account_id"
        :add-on="addOn"
        :digital-asset="asset"
        :order-id="addOn.order.id"
        :enable-review="addOn.order.status === 'paid'"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.asset-heading {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__top {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-between;
    padding: 0 1.5rem;
    width: 100%;
  }

  &__bottom {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-between;
    padding: 0 1rem;
    width: 100%;
  }
}
</style>
