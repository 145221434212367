import { computed, toRef } from 'vue';
import { useTimeoutFn } from '@vueuse/core';
import { useFlag } from '@/composables/useFlag';
import { useRouter } from 'vue-router';
import { useAccount } from '@/composables/useAccount';

export const usePaymentInterstitialRedirect = (
  reservation,
  extraParams = {}
) => {
  const { query } = { ...extraParams };
  const customerQuestionnaireFlag = useFlag('toaster_customer_questionnaire');

  const router = useRouter();

  const { account, hasCompletedCustomerQuestionnaire } = useAccount(
    toRef(() => reservation.value?.account_id)
  );

  const redirectTo = computed(() => {
    if (customerQuestionnaireFlag.value) {
      if (hasCompletedCustomerQuestionnaire.value) {
        return {
          name: 'info',
          params: { reservationId: reservation.value.id },
          query,
        };
      } else {
        return `/account/${account.value?.id}/customer-questionnaire`;
      }
    } else {
      return {
        name: 'info',
        params: { reservationId: reservation.value.id },
        query,
      };
    }
  });

  const { start: startInterstitialTimeout } = useTimeoutFn(
    () => {
      router.push(redirectTo.value);
    },
    6500,
    { immediate: false }
  );

  return {
    startInterstitialTimeout,
    redirectTo,
  };
};
