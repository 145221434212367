<script setup>
import { computed, ref, toRefs, watch } from 'vue';
import { refDebounced } from '@vueuse/core';
import { useCatalog } from '@/queries/useCatalog';
import PaginatorSmall from '@/components/PaginatorSmall.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaSkeleton from '@/components/ui_library/SoonaSkeleton.vue';
import PickerCreateProduct from '@/components/user/anytime/products/picker/PickerCreateProduct.vue';
import { usePriorityError } from '@/composables/usePriorityError';
import { keepPreviousData } from '@tanstack/vue-query';

const props = defineProps({
  accountId: {
    type: [Number, String],
    required: true,
  },
  multiselect: {
    type: Boolean,
    default: false,
  },
  attachedToDigitalAssetId: {
    type: [Number, String],
    default: null,
  },
  attachedToReservationId: {
    type: [Number, String],
    default: null,
  },
  search: {
    type: String,
    required: true,
  },
  filter: {
    type: String,
    default: null,
  },
  orderBy: {
    type: String,
    default: 'name',
  },
  showCreate: {
    type: Boolean,
    default: false,
  },
  includeVariants: {
    type: Boolean,
    default: false,
  },
  showInventoryCounts: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['cancel', 'select']);

const {
  accountId,
  attachedToDigitalAssetId,
  attachedToReservationId,
  search,
  filter: filterPropValue,
  orderBy: orderByValue,
} = toRefs(props);

const searchDebounced = refDebounced(search, 200);
const currentPage = ref(1);
const itemsPerPage = ref(20);
watch([searchDebounced], () => {
  currentPage.value = 1;
});

const filterProp = computed(() => {
  if (filterPropValue.value) {
    return filterPropValue.value;
  }

  return searchDebounced.value ? 'include_archived' : null;
});

const includeVariants = computed(() => props.includeVariants);
const showInventoryCounts = computed(() => props.showInventoryCounts);

const {
  data: productData,
  error,
  isLoading,
} = useCatalog(
  accountId,
  {
    query: searchDebounced,
    filter: filterProp,
    orderBy: orderByValue,
    direction: 'asc',
    currentPage,
    itemsPerPage,
    attachedToDigitalAssetId: attachedToDigitalAssetId,
    attachedToReservationId,
    includeVariants: includeVariants,
    showBookingCount: false,
    showInventoryCount: showInventoryCounts,
  },
  {
    placeholderData: keepPreviousData,
  }
);

const priorityError = usePriorityError(error);

const catalogItemsPagination = computed(() =>
  productData.value
    ? productData.value.pagination
    : {
        totalCount: 0,
        currentPage: 0,
        totalPages: 0,
        itemsPerPage: 0,
      }
);

const catalogItems = computed(() => productData.value?.catalogItems ?? []);

const createViewIsActive = ref(false);

function cancel() {
  emit('cancel');
}

function select(productId, product) {
  emit('select', productId, product);
  createViewIsActive.value = false;
}
</script>
<template>
  <template v-if="!createViewIsActive">
    <SoonaError v-if="priorityError">{{ priorityError }}</SoonaError>

    <SoonaButton
      v-if="showCreate"
      variation="secondary-gray"
      size="medium"
      class="product-picker__add-product-button"
      @on-click="createViewIsActive = true"
    >
      <SoonaIcon name="plus-large" />
      create new product
    </SoonaButton>
    <div v-if="isLoading" class="product-picker__loading-state">
      <SoonaSkeleton
        v-for="i in 3"
        :key="i"
        class="product-picker__loading-state--card"
      />
    </div>
    <div v-else>
      <div v-if="catalogItems.length > 0" class="product-picker__options">
        <slot name="product-options" :catalog-items="catalogItems" />
      </div>
      <PaginatorSmall
        class="product-picker__paginator"
        :page="catalogItemsPagination.currentPage"
        :pages="catalogItemsPagination.totalPages"
        @page-change="currentPage = $event"
      />
      <p v-if="catalogItems.length < 1" class="product-picker__no-results">
        no results
      </p>
    </div>
  </template>
  <PickerCreateProduct
    v-else-if="showCreate"
    :account-id="accountId"
    :initial-name="search"
    :multiselect="multiselect"
    @cancel="cancel"
    @select="select"
  />
</template>
<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.product-picker {
  &__loading-state {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &--card {
      height: 4.5rem;
      width: 100%;
      border-radius: 0.625rem;
    }
  }

  &__no-results {
    margin: 4rem auto;
    text-align: center;
    @include variables_fonts.u-body--regular;
    color: variables.$gray-60;
  }

  &__options {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }

  &__paginator:not(:last-child) {
    margin-bottom: 1rem;
  }

  &__add-product-button {
    width: 100%;
    margin-bottom: 1.5rem;

    svg {
      color: variables.$gray-60;
    }
  }
}
</style>
