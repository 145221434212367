<script setup>
import { computed, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import AddOnsV2 from 'src/components/user/anytime/AddOnsV2.vue';
import AlertNotification from 'src/components/AlertNotification.vue';
import PaginatorFull from 'src/components/directory/PaginatorFull.vue';
import { useBag } from 'src/queries/bag/useBag';
import SoonaLoading from 'src/components/SoonaLoading.vue';
import { keepPreviousData } from '@tanstack/vue-query';

const props = defineProps({
  accountId: {
    type: String,
    required: true,
  },
});

const router = useRouter();

const isProcessing = ref(false);

const currentAccountId = computed(() => props.accountId);

const currentPage = ref(1);
const itemsPerPage = ref(25);
const perPageOptions = [10, 25, 50];

watch(itemsPerPage, () => {
  currentPage.value = 1;
});
watch(currentPage, () => {
  window.scrollTo(0, 0);
});

const {
  data: bagData,
  isLoading,
  isFetching,
  error,
} = useBag(
  currentAccountId,
  {
    currentPage,
    itemsPerPage,
  },
  {
    placeholderData: keepPreviousData,
    enabled: computed(() => !!currentAccountId.value),
  }
);

const bag = computed(() => bagData.value?.data?.line_items || []);

const bagTotals = computed(() => {
  return {
    subTotal: bagData.value?.data?.sub_total,
    discountTotal: bagData.value?.data?.discount_total,
    total: bagData.value?.data?.total,
  };
});

const bagPagination = computed(() => {
  if (bagData.value) {
    return bagData.value.pagination;
  } else {
    return {
      totalCount: 0,
      currentPage: 0,
      totalPages: 0,
      itemsPerPage: 0,
    };
  }
});

const hasContents = computed(() => bag.value.length > 0);

function toCurrency(value, currency = 'USD') {
  return value?.toLocaleString('en-US', {
    style: 'currency',
    currency,
  });
}

function openMedia(lineItem) {
  router.push(
    `/reservation/${lineItem.reservation_id}/asset/${lineItem.digital_asset_id}`
  );
}
</script>

<template>
  <div>
    <div v-if="isLoading || hasContents">
      <section class="cart-container">
        <div class="columns is-mobile is-centered">
          <div class="column">
            <div class="">
              <h3 class="u-title--heavy is-size-7-mobile mb-m">
                bag for account {{ currentAccountId }}
              </h3>
              <PaginatorFull
                v-model:page="currentPage"
                v-model:records-per-page="itemsPerPage"
                :total-pages="bagPagination.totalPages"
                :total-count="bagPagination.totalCount"
                :page-count="bag.length"
                :records-per-page-options="perPageOptions"
              />
            </div>
            <div class="columns is-mobile">
              <div
                class="column is-offset-three-quarters-tablet is-offset-8-mobile is-2-mobile has-text-right"
              >
                <h3 class="is-size-7-mobile">
                  <b>price</b>
                </h3>
              </div>
              <div class="column is-2-mobile has-text-right">
                <h3 class="is-size-7-mobile">
                  <b>total</b>
                </h3>
              </div>
            </div>
            <hr />
          </div>
        </div>
      </section>
      <section class="cart-container">
        <div class="columns is-mobile is-centered">
          <div class="column">
            <SoonaLoading
              v-if="isLoading || isFetching"
              :is-loading="true"
              :is-dark="false"
              :loading-text="'please wait'"
            />
            <div class="orders-container">
              <div v-for="lineItem in bag" :key="lineItem.id">
                <div class="columns is-mobile">
                  <div class="column is-three-quarters-tablet is-8-mobile">
                    <div class="line-item">
                      <div
                        class="is-one-quarter-tablet is-3-mobile line-item-image"
                      >
                        <figure
                          class="image is-1by1"
                          @click="openMedia(lineItem)"
                        >
                          <img
                            :src="lineItem.preview?.url || lineItem.preview_url"
                            class="shopping-cart-img"
                          />
                        </figure>
                      </div>
                      <div class="is-half-tablet is-5-mobile line-item-details">
                        <p class="item-title is-size-7-mobile">
                          <b>{{ lineItem.title }}</b>
                        </p>
                        <br />
                        <AddOnsV2
                          :asset="lineItem"
                          :show-add-on-descriptions="true"
                          :read-only="true"
                        />
                        <br />
                        <p class="note-text u-body--regular">
                          {{ lineItem.note?.content }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="column is-2-mobile has-text-right">
                    <div class="line-summary" style="position: relative">
                      <p class="price is-size-7-mobile">
                        {{
                          lineItem.base_price === 29
                            ? toCurrency(39)
                            : toCurrency(lineItem.base_price)
                        }}
                      </p>
                      <div>
                        <div
                          v-for="mediaLineItem in lineItem.media_add_on_line_items"
                          :key="mediaLineItem.product.id"
                        >
                          <p
                            v-if="mediaLineItem.selected"
                            class="is-size-7-mobile"
                          >
                            + {{ toCurrency(mediaLineItem.price) }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="column is-2-mobile has-text-right">
                    <p class="price is-size-7-mobile">
                      {{ toCurrency(lineItem.price) }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <PaginatorFull
              v-model:page="currentPage"
              v-model:records-per-page="itemsPerPage"
              :total-pages="bagPagination.totalPages"
              :total-count="bagPagination.totalCount"
              :page-count="bag.length"
              :records-per-page-options="perPageOptions"
            />
          </div>
        </div>
      </section>
      <section>
        <div class="cart-container">
          <div class="columns is-mobile is-centered">
            <div class="column">
              <div class="columns is-mobile">
                <div
                  class="column is-three-quarters-tablet is-half-mobile"
                ></div>
                <div class="column has-text-left has-text-right-mobile">
                  <p class="is-size-7-mobile">subtotal:</p>
                  <p class="is-size-7-mobile">credits:</p>
                  <h3 class="u-body--heavy is-size-7-mobile">total:</h3>
                </div>
                <div class="column has-text-right">
                  <p class="is-size-7-mobile">
                    {{ toCurrency(bagTotals.subTotal) }}
                  </p>
                  <p class="is-size-7-mobile">
                    {{ toCurrency(bagTotals.discountTotal) }}
                  </p>
                  <p class="u-body--heavy is-size-7-mobile">
                    {{ toCurrency(bagTotals.total) }}
                  </p>
                  <br />
                  <SoonaLoading
                    :is-loading="isProcessing"
                    :is-dark="false"
                    :loading-text="'please wait a moment'"
                  />
                </div>
              </div>
            </div>
          </div>
          <alert-notification
            :alert-key="'order-summary'"
            :small-container="true"
          />
        </div>
      </section>
    </div>

    <section v-show="hasContents">
      <div class="cart-container">
        <div class="columns is-centered is-vcentered">
          <div class="column is-6">
            <div v-if="error" class="notification has-text-danger">
              {{ error }}
            </div>
          </div>
        </div>
      </div>
    </section>
    <div v-if="!hasContents && !isLoading">
      <section>
        <div class="empty-cart-container">
          <div class="columns is-centered is-vcentered">
            <div class="column is-8 has-text-centered">
              <h3 class="u-title--heavy is-size-7-mobile has-text-danger">
                this account's bag is empty
              </h3>
            </div>
          </div>
          <SoonaLoading
            :is-loading="isProcessing"
            :is-dark="false"
            :loading-text="'please wait a moment'"
          />
        </div>
      </section>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.question-button {
  cursor: pointer;
}
.question-icon {
  color: #aaaaaa;
  font-size: 0.75em;
}
.line-item {
  display: flex;

  .line-item-image {
    flex-grow: 0.5;
    margin-right: 20px;
  }
  .line-item-details {
    flex-grow: 1;
  }
}

.add-on {
  display: flex;
  align-items: flex-start;
  margin: 5px 0;

  .add-ons-checkbox {
    margin: 2px 5px 0 0;
  }
}
.line-item-image-placeholder {
  flex-grow: 0.3;
  margin-right: 20px;
}

:deep(.paginator) {
  margin: 1rem 0;
}

.item-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.note-text {
  white-space: pre-wrap;
}

.shopping-cart-img {
  object-fit: cover;
  cursor: pointer;
}

.empty-cart-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 50vh;
}

@media only screen and (max-width: 768px) {
  .line-item {
    display: block;
  }
}
</style>

<style lang="scss">
.cart-container {
  margin: 0 1rem;
  max-width: none;

  @media (min-width: 23.5rem) {
    margin: 0;
  }
}
</style>
