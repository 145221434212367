<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';

const props = defineProps({
  copy: {
    default: 'sign in with Google',
  },
});

const route = useRoute();

const redirectTo = computed(() => {
  let redirect = route.query?.redirect ?? '/';

  if (redirect.startsWith('http')) {
    redirect = encodeURIComponent(redirect);
  }

  return redirect;
});

const queryParams = computed(() => {
  if (!route.query) return null;

  const params = new URLSearchParams(route.query);

  if (params.get('canva_user_token')) {
    params.delete('canva_user_token');
  }

  if (params.get('redirect')) {
    params.delete('redirect');
  }

  return params.toString();
});

const canvaQueryParams = computed(() => {
  if (!route.query) return null;

  const params = new URLSearchParams(route.query);

  if (!params.get('canva_user_token')) {
    return null;
  }

  return params.toString();
});

const linkAction = computed(() => {
  if (canvaQueryParams.value) {
    return `/auth/google_oauth2?${canvaQueryParams.value}`;
  } else if (redirectTo.value) {
    return `/auth/google_oauth2?redirect=${redirectTo.value}${queryParams.value ? `&${queryParams.value}` : ''}`;
  } else {
    return `/auth/google_oauth2${queryParams.value ? `?${queryParams.value}` : ''}`;
  }
});

const csrfToken = computed(() => {
  return document
    .querySelector("meta[name='csrf-token']")
    ?.getAttribute('content');
});
</script>

<template>
  <form v-if="csrfToken" class="google-sso" :action="linkAction" method="post">
    <SoonaButton
      id="google_oauth2"
      class="google-sso__action-button"
      type="submit"
      variation="secondary-gray"
      :copy="props.copy"
      size="medium"
    >
      <template #icon-left>
        <img src="@images/google-icon.svg" alt="google logo" />
      </template>
    </SoonaButton>
    <input type="hidden" name="authenticity_token" :value="csrfToken" />
  </form>
</template>

<style scoped lang="scss">
@use '@/variables_fonts';

.google-sso {
  width: 100%;

  .soona-button {
    @include variables_fonts.u-label--regular;

    width: 100%;
  }
}
</style>
