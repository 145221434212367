<script setup>
import { computed, ref } from 'vue';
import BaseProductPickerDialog from '../products/picker/BaseProductPickerDialog.vue';
import PickerProductOption from '../products/picker/PickerProductOption.vue';
import QuantityIncrementor from '@/components/booking/QuantityIncrementor.vue';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaUpload from 'src/components/uploader/SoonaUpload.vue';
import { useCatalogItem } from 'src/queries/useCatalog';
import ProductPickerGroupTitle from '@/components/user/anytime/products/picker/ProductPickerGroupTitle.vue';
import { component as Viewer } from 'v-viewer';

const props = defineProps({
  accountId: { type: [String, Number], required: true },
  reservationId: { type: [String, Number], default: null },
  inventoryItem: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['update:inventoryItem']);

const accountId = computed(() => props.accountId);
const reservationId = computed(() => props.reservationId);

// quantity
const decrementQuantity = () => {
  emit('update:inventoryItem', {
    ...props.inventoryItem,
    quantity: props.inventoryItem.quantity - 1,
  });
};

const incrementQuantity = () => {
  emit('update:inventoryItem', {
    ...props.inventoryItem,
    quantity: props.inventoryItem.quantity + 1,
  });
};

// image
const updateItemImage = blob => {
  emit('update:inventoryItem', {
    ...props.inventoryItem,
    image_url: `/rails/active_storage/blobs/${blob.signed_id}/${blob.filename}`,
    image: blob.signed_id,
  });
};

// description
const itemDescription = computed({
  get() {
    return props.inventoryItem.description;
  },
  set(value) {
    emit('update:inventoryItem', {
      ...props.inventoryItem,
      description: value,
    });
  },
});

// product info
const isProductPickerOpen = ref(false);

const orderBy = computed(() => {
  if (reservationId.value) {
    return 'attached_to_reservation_id';
  } else {
    return 'name';
  }
});

const selectCatalogItem = productId => {
  emit('update:inventoryItem', {
    ...props.inventoryItem,
    catalog_item_id: productId,
  });
  isProductPickerOpen.value = false;
};

const { data: productData, isSuccess: productDataSuccess } = useCatalogItem(
  accountId,
  computed(() => props.inventoryItem.catalog_item_id),
  {
    enabled: computed(() => !!props.inventoryItem.catalog_item_id),
  }
);

const product = computed(() => productData.value ?? {});

const sku = computed(
  () =>
    product.value?.external_identifiers.find(
      x => x.external_identifier_type.slug === 'sku'
    )?.value
);

// remove/restore
const removeItem = () => {
  emit('update:inventoryItem', {
    ...props.inventoryItem,
    _destroy: 1,
  });
};
const restoreItem = () => {
  emit('update:inventoryItem', {
    ...props.inventoryItem,
    _destroy: null,
  });
};

function groupProducts(catalogItems) {
  return catalogItems.reduce((acc, item) => {
    const itemCopy = { ...item };
    const group =
      reservationId.value &&
      itemCopy.attached_to_reservation_id === Number(reservationId.value)
        ? 'this booking'
        : 'more products';

    itemCopy.buttonVariation =
      group === 'this booking' ? 'primary' : 'secondary-black';
    acc[group] ??= [];
    acc[group].push(itemCopy);
    return acc;
  }, {});
}
</script>

<template>
  <div class="tile">
    <button
      v-if="inventoryItem._destroy !== 1"
      class="remove-btn"
      @click="
        () => {
          removeItem();
        }
      "
    >
      <SoonaIcon name="xmark" />
    </button>
    <div v-if="inventoryItem.image_url" class="thumbnail-wrapper">
      <viewer
        :options="{
          toolbar: false,
          navbar: false,
          backdrop: true,
          minZoomRatio: 0.01,
          maxZoomRatio: 2,
        }"
      >
        <img
          :src="inventoryItem.image_url"
          class="thumbnail"
          :class="inventoryItem._destroy !== 1 ? '' : 'opaque'"
          alt="inventory item"
        />
      </viewer>
    </div>
    <QuantityIncrementor
      v-if="inventoryItem._destroy !== 1"
      class="quantity-counter"
      :value="inventoryItem.quantity"
      :min="1"
      @on-increment="incrementQuantity"
      @on-decrement="decrementQuantity"
    />
    <button
      v-if="inventoryItem._destroy == 1"
      class="restore-btn"
      @click="
        () => {
          restoreItem();
        }
      "
    >
      RESTORE
    </button>

    <textarea
      v-model="itemDescription"
      aria-label="notes"
      class="description"
      :class="inventoryItem._destroy !== 1 ? '' : 'opaque'"
      maxlength="160"
      :disabled="inventoryItem._destroy == 1"
      :placeholder="inventoryItem._destroy !== 1 ? 'add notes' : ''"
    />
    <div>
      <div v-if="productDataSuccess" class="product-info">
        <p class="product-info__name">
          {{ product.name }}
        </p>
        <p v-if="sku" class="product-info__sku">sku: {{ sku }}</p>
      </div>
      <SoonaButton
        v-else
        copy="identify"
        size="medium"
        @click="isProductPickerOpen = true"
      />
      <BaseProductPickerDialog
        v-if="isProductPickerOpen"
        :account-id="accountId"
        :attached-to-reservation-id="reservationId"
        :order-by="orderBy"
        show-create
        @cancel="isProductPickerOpen = false"
        @select="selectCatalogItem"
      >
        <template #heading>identify product</template>
        <template #product-options="{ catalogItems }">
          <template v-if="orderBy === 'attached_to_reservation_id'">
            <template
              v-for="(items, group) in groupProducts(catalogItems)"
              :key="group"
            >
              <ProductPickerGroupTitle>{{ group }}</ProductPickerGroupTitle>
              <PickerProductOption
                v-for="catalogItem in items"
                :key="catalogItem.id"
                :product="catalogItem"
                :button-variation="catalogItem.buttonVariation"
                @select="selectCatalogItem"
              />
            </template>
          </template>
          <template v-else-if="orderBy === 'name'">
            <PickerProductOption
              v-for="catalogItem in catalogItems"
              :key="catalogItem.id"
              :product="catalogItem"
              :button-variation="catalogItem.buttonVariation"
              @select="selectCatalogItem"
            />
          </template>
        </template>
      </BaseProductPickerDialog>
    </div>

    <SoonaUpload
      class="replace-item-image-button"
      class-overide="replace-item-image-button"
      :upload-complete="blob => updateItemImage(blob)"
      :hide-icon="true"
      :is-multiple="false"
      label="replace photo"
      accept=".jpeg, .jpg, .png, .gif"
    />
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 180px;
  min-height: 245px;

  .thumbnail-wrapper {
    &:hover {
      cursor: pointer;

      .thumbnail {
        transform: scale(1.05);
      }
    }
  }

  .thumbnail {
    border-radius: 3px;
    width: 180px;
    height: 130px;
    object-fit: cover;
    margin-bottom: 1rem;
  }
  .quantity-counter {
    margin-top: -2.375rem;
    margin-bottom: 1rem;
  }
  .restore-btn {
    z-index: 10;
    margin-top: -38px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    height: 28px;
    color: variables.$periwink-blue-60;
    border: 1px solid variables.$periwink-blue-60;
    background-color: variables.$white-default;
    font-weight: 900;
    font-size: 12px;
    width: 100%;
    max-width: 6rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    border-radius: 999px;
    padding: 5px 0 0 16px;
    letter-spacing: 0.1rem;
  }
  .remove-btn {
    align-items: center;
    color: variables.$white-default;
    display: flex;
    background-color: variables.$friendly-red-50;
    border-radius: 50%;
    height: 18px;
    justify-content: center;
    margin-right: -174px;
    margin-top: -7px;
    padding: 0;
    position: absolute;
    width: 18px;
    z-index: 1;
  }
  .opaque {
    opacity: 0.2;
  }
}
.description {
  margin: 0.5rem;
  border: 0.625rem solid variables.$gray-20;
  padding: 5px;
  max-width: 90%;
  max-height: 80px;
}

.product-info {
  &__name {
    @include variables_fonts.u-body--heavy;
    margin-bottom: 0;
    padding: 0 0.25rem;
    text-align: center;
  }

  &__sku {
    @include variables_fonts.u-small--regular;
    margin-bottom: 0;
    padding: 0 0.25rem;
    text-align: center;
  }
}
.soona-uploader.upload-item-button {
  background: variables.$gray-10;
  border: 0.125rem dashed variables.$gray-20;
  border-radius: 3px;
  font-size: 12px;
  color: variables.$gray-50;
  width: 180px;
  height: 245px;
  margin-top: 0;
}
.soona-uploader.upload-item-button:hover {
  border: 2px dashed variables.$periwink-blue-60;
  color: variables.$periwink-blue-60;
  cursor: pointer;
}
.soona-uploader.replace-item-image-button {
  @include variables_fonts.u-label--regular;

  color: variables.$black-default;
  margin-top: 0.5rem;
  text-decoration: underline;

  &:hover {
    color: variables.$periwink-blue-70;
  }
}
</style>
