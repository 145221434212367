<script setup>
import { computed } from 'vue';
import SoonaSelect from '@/components/ui_library/SoonaSelect.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import { useDeleteReEditCollectionDigitalAsset } from '@/queries/re_edits_collection_digital_assets/useDeleteReEditsCollectionDigitalAsset';
import { useUpdateReEditsCollectionDigitalAsset } from '@/queries/re_edits_collection_digital_assets/useUpdateReEditsCollectionDigitalAsset';
import SoonaLoading from '@/components/SoonaLoading.vue';
import SoonaToggle from '@/components/ui_library/SoonaToggle.vue';

const props = defineProps({
  accountId: {
    type: [Number, String],
    required: true,
  },
  collectionId: {
    type: [Number, String],
    required: true,
  },
  reEdit: {
    type: Object,
    required: true,
  },
  selectedReEdits: {
    type: Array,
    required: true,
    default: () => [],
  },
  subCategoryOptions: {
    type: Array,
    required: true,
    default: () => [],
  },
});

const emit = defineEmits(['select-re-edit', 'unselect-re-edit']);

const reEdit = computed(() => props.reEdit);
const asset = computed(() => reEdit.value.digital_asset);
const reservationId = computed(
  () => props.reEdit.reservation_digital_asset.reservation_id
);
const reEditId = computed(() => reEdit.value.id);
const accountId = computed(() => props.accountId);
const collectionId = computed(() => props.collectionId);
const selectedReEdits = computed(() => props.selectedReEdits);
const selectedSubCategories = computed(() => {
  return reEdit.value?.options?.re_edit_categories;
});
const subCategoriesSelectDisabled = computed(() => {
  return selectedReEdits.value?.length > 0;
});

const isSelected = computed(() => {
  return selectedReEdits.value?.some(sre => sre.id === reEdit.value.id);
});

const { mutate: destroyReEditAsset, isPending: destroyingReEditAsset } =
  useDeleteReEditCollectionDigitalAsset(accountId, collectionId);

const cancelReEditAssetRequest = () => {
  // todo: loading
  destroyReEditAsset({ cdaId: reEditId });
};

const emitChange = checked => {
  if (checked) {
    emit('select-re-edit', reEdit.value);
  } else {
    emit('unselect-re-edit', reEdit.value.id);
  }
};

const { mutate: updateReEditCategories, isPending: updatingReEditCategories } =
  useUpdateReEditsCollectionDigitalAsset(accountId, collectionId);

const updateCategories = categories => {
  updateReEditCategories({
    cdaId: reEditId,
    body: {
      re_edit_categories: categories,
    },
  });
};

const onSelect = option => {
  let currentSelection = selectedSubCategories.value.slice();
  currentSelection = currentSelection.filter(cs => cs !== option.value);
  currentSelection.push(option.value);
  updateCategories(currentSelection);
};

const onDeselect = option => {
  let currentSelection = selectedSubCategories.value.slice();
  currentSelection = currentSelection.filter(cs => cs !== option.value);
  updateCategories(currentSelection);
};

const isLoading = computed(() => {
  return destroyingReEditAsset.value || updatingReEditCategories.value;
});

const previewRatio = computed(() => asset.value?.preview?.ratio ?? 1);
</script>

<template>
  <li class="re-edits-row">
    <SoonaLoading v-if="isLoading" is-loading is-contained />
    <div class="re-edit-select">
      <SoonaToggle
        :model-value="isSelected"
        type="checkbox"
        :label="asset.title"
        hide-visual-label
        @update:model-value="emitChange"
      />
    </div>
    <router-link
      class="re-edit-link"
      :to="{
        name: 'reservation-asset-re-edit-view',
        params: {
          reservationId,
          digitalAssetId: asset.id,
        },
      }"
    >
      <img
        :src="asset.preview.url"
        class="re-edits-row__img"
        :alt="asset.preview?.alt_text"
      />
      <span class="u-visually-hidden">{{ asset.title }}</span>
    </router-link>
    <div class="re-edits-info">
      <h4 class="u-body--all-caps-black">notes</h4>
      <p class="re-edit-notes">
        {{ asset.note?.content }}
      </p>
      <h5 class="u-label--heavy">re-edit sub category</h5>
      <SoonaSelect
        class="category-dropdown"
        placeholder="select reason"
        :multi="true"
        :model-values="selectedSubCategories"
        :options="subCategoryOptions"
        :searchable="true"
        :disabled="subCategoriesSelectDisabled"
        @option:selecting="onSelect"
        @option:deselecting="onDeselect"
      />
      <SoonaButton
        variation="tertiary"
        size="medium"
        copy="cancel re-edit"
        @click="cancelReEditAssetRequest()"
      >
      </SoonaButton>
    </div>
  </li>
</template>

<style lang="scss" scoped>
@use '@/variables';

.re-edits-row {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding-inline: 0;

  &:first-child {
    margin-top: 1rem;
  }

  &:not(:last-child) {
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 0.0625rem solid variables.$gray-30;
  }

  &__img {
    display: block;
    max-height: 14rem;
    aspect-ratio: v-bind(previewRatio);
    object-fit: cover;
  }

  .re-edit-select {
    display: flex;
    align-items: center;
    font-size: 1.875rem;
    flex: 0 0 1.875rem;
  }

  .re-edit-link {
    flex: 0 0 14rem;
  }

  .re-edits-info {
    flex: 1 1 14rem;
  }

  .re-edit-notes {
    margin-bottom: 1rem;
  }

  .category-dropdown {
    margin-top: 0.5rem;
  }

  .cancel-button {
    background-color: transparent;
    margin-top: 0.5rem;
    text-decoration: underline;
  }
}
</style>
