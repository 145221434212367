<script setup>
import { computed, onMounted, ref, watchEffect } from 'vue';
import { useAuth } from '@/composables/useAuth';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useRoute } from 'vue-router';
import AllInOneConsent from '@/components/shared/AllInOneConsent.vue';
import SoonaAuthLayout from '@/components/authentication/SoonaAuthLayout.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';
import { useMe } from '@/composables/user/useMe';

const props = defineProps({
  basePath: {
    default: '/',
    type: String,
  },
  isExternalAuthPortal: {
    default: false,
    type: Boolean,
  },
  accountCreationSource: {
    default: '',
    type: String,
  },
  redirectToPath: {
    default() {
      return '/';
    },
    type: String,
  },
  referralCode: {
    default: null,
    type: String,
  },
  referralName: {
    default: null,
    type: String,
  },
  token: {
    default: null,
    type: String,
  },
});

const isExternalAuthPortal = computed(() => props.isExternalAuthPortal);

const {
  authenticationErrors,
  createUser,
  data,
  isLoading,
  sendAuthMessage,
  userAuthToken,
} = useAuth({ generateAuthToken: isExternalAuthPortal });
const { me } = useMe();

const { inputChanged, linkClicked, pageViewed } = useBaseEvents();
const route = useRoute();

const context = computed(() => route.meta.context);
const subContext = 'sign up';
const nameRef = ref(null);

const redirectToPath = computed(() => props.redirectToPath);

// external auth token signing in
const authTokenResponseMessage = computed(
  () =>
    `account_id:${me.value?.account_id},auth_token:${userAuthToken.value?.auth_token}`
);

const authTokenSignIn = () => {
  sendAuthMessage(authTokenResponseMessage.value);
  if (window.opener) window.close();
};

watchEffect(() => {
  if (userAuthToken.value) {
    authTokenSignIn();
  }
});
// end external auth token signing in

const handleSignUp = () => {
  const invitation = redirectToPath.value.match(/\/invitation\/(\d+)/);
  const invitationId = invitation ? invitation[1] : null;

  const attributes = {
    email: data.email,
    name: data.name,
    password: data.password,
    referral_code: props.referralCode,
    username: data.email,
    account_creation_source: props.accountCreationSource,
    invitation_token: props.token,
    invitation_id: invitationId,
  };

  createUser(attributes, redirectToPath.value);
};

const trackLinkClicked = () => {
  linkClicked({
    context: context.value,
    subContext: subContext,
    linkLabel: 'create account',
    linkHref: null,
  });
};

const trackInputChange = (inputType, inputLabel) => {
  inputChanged({
    context: context.value,
    subContext: subContext,
    inputLabel: inputLabel,
    inputType: inputType,
    inputValue: null,
  });
};

const handleSubmit = () => {
  trackLinkClicked();
  handleSignUp();
};

onMounted(() => {
  nameRef.value?.focus();
  pageViewed();
  if (window.opener) {
    window.onbeforeunload = sendAuthMessage(authTokenResponseMessage.value);
  }
});
</script>

<template>
  <SoonaAuthLayout
    class="soona-sign-up"
    :base-path="basePath"
    :error-messages="authenticationErrors"
    :is-external-auth-portal="isExternalAuthPortal"
    :is-loading="isLoading"
    :referral-name="referralName"
    type="sign-up"
    @handle-submit="handleSubmit"
  >
    <SoonaTextfield
      ref="nameRef"
      v-model="data.name"
      autocomplete="name"
      autofocus
      label="name"
      name="name"
      placeholder="first & last"
      required
      :rules="['required']"
      type="text"
      cypress-name="input-sign-up-name"
      @on-blur="e => trackInputChange('text', 'name')"
    />
    <SoonaTextfield
      v-model="data.email"
      autocomplete="email"
      label="email"
      name="email"
      placeholder="name@company.com"
      required
      :rules="['required', 'email']"
      type="email"
      cypress-name="input-sign-up-email"
      @on-blur="e => trackInputChange('email', 'email')"
    />
    <SoonaTextfield
      v-model="data.password"
      class="soona-sign-up__password-input"
      label="password"
      :minlength="8"
      name="password"
      autocomplete="new-password"
      placeholder="password"
      required
      :rules="['required', 'minlength']"
      show-password-icon
      type="password"
      cypress-name="input-sign-up-password"
      @on-blur="e => trackInputChange('password', 'password')"
    >
      <template #helper>8 characters minimum</template>
    </SoonaTextfield>
    <div class="soona-sign-up__submit-btn-wrapper">
      <SoonaButton
        copy="create account"
        type="submit"
        data-cypress="button-sign-up-submit"
      />
    </div>
    <AllInOneConsent />
  </SoonaAuthLayout>
</template>

<style lang="scss" scoped>
.soona-sign-up {
  &__password-input {
    padding-bottom: 1rem;
  }

  &__submit-btn-wrapper {
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
  }
}
</style>
