<script setup>
import { computed, ref } from 'vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaPaymentMethods from '@/components/SoonaPaymentMethods.vue';

const props = defineProps({
  paymentMethods: {
    type: Array,
    required: true,
  },
  accountId: {
    type: [Number, String],
    required: true,
  },
  activeSubscriptionId: {
    type: [Number, String],
    required: false,
  },
});

const emit = defineEmits([
  'addPaymentMethod',
  'updatePaymentMethod',
  'removePaymentMethod',
]);

const showPaymentModal = ref(false);

const paymentMethods = computed(() => props.paymentMethods);
const hasActiveSubscription = computed(() => !!props.activeSubscriptionId);
const accountId = computed(() => props.accountId);

const paymentMethodType = paymentMethod => {
  if (paymentMethod.payment_method_type === 'card') {
    return 'credit-card';
  }
  if (paymentMethod.payment_method_type === 'us_bank_account') {
    return 'bank-account';
  }
};

const last4 = paymentMethod => {
  if (paymentMethod.payment_method_type === 'card') {
    return `**** ${paymentMethod?.payment_method_data?.last4}`;
  }
  if (paymentMethod.payment_method_type === 'us_bank_account') {
    return `**** ${paymentMethod?.payment_method_data?.last4}`;
  }
};

const paymentMethodName = paymentMethod => {
  if (paymentMethod.payment_method_type === 'card') {
    return `${paymentMethod?.payment_method_data?.brand} ${paymentMethod?.payment_method_data?.funding}`;
  }
  if (paymentMethod.payment_method_type === 'us_bank_account') {
    return `bank account`;
  }
};

const primarySub1 = paymentMethod => {
  if (paymentMethod.payment_method_type === 'card') {
    return `${paymentMethod?.payment_method_data?.brand} ${paymentMethod?.payment_method_data?.funding}`;
  }
  if (paymentMethod.payment_method_type === 'us_bank_account') {
    return `${paymentMethod?.payment_method_data?.bank_name?.toLowerCase()}`;
  }
};

const primarySub2 = paymentMethod => {
  if (paymentMethod.payment_method_type === 'card') {
    return `expires ${paymentMethod?.payment_method_data?.exp_month}/${paymentMethod?.payment_method_data?.exp_year}`;
  }
  if (paymentMethod.payment_method_type === 'us_bank_account') {
    return `${paymentMethod?.payment_method_data?.account_type}`;
  }
};

const primaryPaymentMethod = computed(() => {
  return paymentMethods.value?.find(paymentMethod => paymentMethod.default);
});
const secondaryPaymentMethods = computed(() => {
  return paymentMethods.value?.filter(paymentMethod => !paymentMethod.default);
});

const removePaymentMethod = paymentMethod => {
  emit('removePaymentMethod', paymentMethod);
};

const updatePaymentMethod = paymentMethod => {
  emit('updatePaymentMethod', paymentMethod);
};

function handleAddPaymentMethod(paymentMethodId, paymentMethodType) {
  emit('addPaymentMethod', {
    external_payment_method_id: paymentMethodId,
    payment_method_type: paymentMethodType,
  });
  showPaymentModal.value = false;
}
</script>

<template>
  <div
    class="payment-methods"
    :class="{ not_subscriber: !hasActiveSubscription }"
  >
    <div v-if="primaryPaymentMethod" class="primary-payment-method">
      <h3>primary payment method</h3>
      <div class="payment-card-primary">
        <div class="payment-card-primary__white">
          <SoonaIcon
            :name="paymentMethodType(primaryPaymentMethod)"
            size="medium"
            class="icon"
          />
          <div class="details">
            <div>{{ paymentMethodName(primaryPaymentMethod) }}</div>
          </div>
        </div>
        <div class="payment-card-primary__blue">
          <div class="details">
            <div class="bank-name">{{ primarySub1(primaryPaymentMethod) }}</div>
            <div class="account-type">
              {{ primarySub2(primaryPaymentMethod) }}
            </div>
          </div>
          <div class="account-number">{{ last4(primaryPaymentMethod) }}</div>
        </div>
      </div>
    </div>

    <div
      v-if="secondaryPaymentMethods?.length > 0"
      class="other-payment-methods"
    >
      <h3>other payment methods</h3>
      <div
        v-for="(paymentMethod, index) in secondaryPaymentMethods"
        :key="index"
        class="payment-card"
      >
        <div class="payment-card__icon-container">
          <SoonaIcon
            :name="paymentMethodType(paymentMethod)"
            size="medium"
            class="payment-card__icon"
          />
        </div>
        <div class="payment-card__details">
          <div class="account-type">{{ paymentMethodName(paymentMethod) }}</div>
          <div class="payment-card__account-number">
            {{ last4(paymentMethod) }}
          </div>
        </div>
        <div class="payment-card__actions">
          <SoonaButton
            variation="tertiary"
            copy="set as primary"
            @on-click="updatePaymentMethod(paymentMethod)"
          />
          <SoonaButton
            variation="tertiary"
            copy="remove"
            @on-click="removePaymentMethod(paymentMethod)"
          />
        </div>
      </div>
    </div>
    <SoonaButton
      class="add-payment-method"
      variation="solid-black"
      size="medium"
      copy="add payment method +"
      @on-click="showPaymentModal = true"
    />
  </div>
  <SoonaDialog v-if="showPaymentModal" @close="showPaymentModal = false">
    <template #heading>
      <p>add payment method</p>
    </template>
    <SoonaPaymentMethods
      :stripe-payment-required="true"
      :force-form="true"
      :on-payment-action="handleAddPaymentMethod"
      :total="100"
      :account-id="accountId"
      :payment-method-types="['card', 'us_bank_account']"
      action-text="save payment method"
    >
    </SoonaPaymentMethods>
  </SoonaDialog>
</template>

<style lang="scss" scoped>
@use '@/variables';

.payment-methods {
  font-family: Arial, sans-serif;
  background-color: #f9f9ff;
  border-radius: 10px;

  p {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
  }

  .subtext {
    color: #666;
    font-size: 14px;
    margin-bottom: 20px;
  }

  .primary-payment-method,
  .other-payment-methods {
    margin-bottom: 20px;

    h3 {
      margin-bottom: 10px;
      font-size: 16px;
      font-weight: bold;
    }
  }

  .payment-card-primary {
    display: flex;
    flex-direction: column;

    &__white {
      display: flex;
      align-items: center;
      background-color: #fff;
      color: #000;
      padding: 1rem;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;

      .icon {
        width: 3rem;
        height: 3rem;
        color: #5566ea;
        margin-right: 10px;
      }

      .details {
        font-weight: 800;
        flex-grow: 1;
        margin-right: 15px;
      }
    }

    &__blue {
      display: flex;
      align-items: center;
      background-color: #6464ff;
      color: #000;
      padding: 1rem;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;

      .details {
        flex-grow: 1;
        margin-right: 15px;
        border-top-left-radius: 1rem;

        .bank-name,
        .account-type {
          color: #fff;
          font-size: 14px;
        }

        .bank-name {
          font-weight: 800;
        }

        .account-type {
          color: #fff;
          background-color: #6464ff;
        }
      }

      .account-number {
        font-size: 18px;
        color: #fff;
        font-weight: bold;
      }
    }
  }

  .payment-card {
    display: flex;
    align-items: center;
    padding: 0.5rem;

    &__icon-container {
      margin-right: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      color: #5566ea;
      border: 1px solid #c9d0fc;
      background-color: #ffffff;
      border-radius: 50%;
      width: 4rem;
      height: 4rem;
    }
    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 2.5rem;
      width: 2.5rem;
      background-size: cover;
      color: #5566ea;
    }

    &__details {
      flex-grow: 1;

      .account-type {
        font-size: 14px;
        font-weight: 800;
        color: #000;
      }

      &__account-number {
        font-size: 14px;
        color: #888;
      }
    }

    &__actions {
      display: flex;
      gap: 0.5rem;
      text-decoration: underline;
      flex-direction: column;

      @media (min-width: variables.$screen-sm-min) {
        flex-direction: row;
      }
    }
  }
}

.not_subscriber {
  width: 100%;

  @media (min-width: variables.$screen-sm-min) {
    width: 50%;
  }
}
</style>
