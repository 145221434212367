<script setup>
import { computed, ref } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaUpload from '@/components/uploader/SoonaUpload.vue';

const props = defineProps({
  imageType: {
    type: String,
    required: false,
    validator: function (value) {
      return ['main', 'product', 'scene'].includes(value);
    },
  },
  packImageUrl: {
    type: String,
    required: false,
  },
  setPackImage: {
    type: Function,
    required: false,
  },
  setPackImageBlob: {
    type: Function,
    required: true,
  },
  acceptedFileTypes: {
    type: String,
    required: false,
    default: '.jpeg, .jpg, .png, .mp4, .mov',
  },
});

const acceptedFileTypes = computed(() => props.acceptedFileTypes);

const packImageType = computed(() => props.imageType);
const packImageName = ref(undefined);
const packImageSize = ref(undefined);
const packImageUploaded = ref(false);

const packImageIsVideo = computed(() => {
  return (
    props.packImageUrl?.includes('.mp4') || props.packImageUrl?.includes('.mov')
  );
});

const handleImageUploadComplete = blob => {
  packImageUploaded.value = true;
  packImageName.value = blob.filename;
  packImageSize.value = Math.floor(+blob.byte_size / 1000);

  if (props.setPackImage) props.setPackImage(blob.signed_id);
  props.setPackImageBlob(blob);
};

const removeImage = () => {
  packImageUploaded.value = false;
  packImageName.value = undefined;
  packImageSize.value = undefined;

  if (props.setPackImage) props.setPackImage(undefined);
  props.setPackImageBlob(undefined);
};
</script>

<template>
  <div class="pack-image">
    <div v-if="$slots['label']">
      <label class="pack-image__label" :for="`pack-${packImageType}-image`">
        <slot name="label"></slot>
      </label>
    </div>
    <div v-if="$slots['subtext']" class="pack-image__subtext">
      <p>
        <slot name="subtext"></slot>
      </p>
    </div>
    <div v-if="packImageUrl & !packImageIsVideo" class="pack-image__main">
      <img :src="packImageUrl" alt="pack main image" />
    </div>
    <SoonaUpload
      :id="`pack-${imageType}-image`"
      class="pack-image__upload"
      :class="{
        'pack-image__upload--complete': packImageUploaded && packImageUrl,
      }"
      :icon-size-overide="true"
      class-overide="pack-image__upload-btn"
      :upload-complete="handleImageUploadComplete"
      :is-multiple="false"
      label="ideally under 150kb, jpg preferred"
      :accept="acceptedFileTypes"
    />
    <div
      v-if="packImageUploaded && packImageUrl"
      class="pack-image__uploaded-file"
    >
      <div class="pack-image__uploaded-file--details">
        <SoonaIcon name="image-square" size="medium" />
        <p v-if="packImageName" class="u-body--heavy">
          {{ packImageName }}
          <span v-if="packImageSize" class="u-label--regular"
            >{{ packImageSize }}kb</span
          >
        </p>
      </div>

      <SoonaButton
        title="remove image"
        variation="icon-plain-gray"
        size="small"
        @on-click="removeImage"
      >
        <SoonaIcon name="xmark" />
        <span class="u-a11y-only">remove image</span>
      </SoonaButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables.scss';
@use '@/variables_fonts.scss';
.pack-image {
  &__label {
    @include variables_fonts.u-label--heavy;
    align-items: center;
    color: variables.$black-default;
    display: flex;
  }

  &__main {
    display: flex;
    justify-content: center;
    margin: 1rem 0;
  }

  &__uploaded-file {
    align-items: center;
    border: 0.0625rem solid variables.$gray-30;
    border-radius: 0.3125rem;
    display: flex;
    justify-content: space-between;
    padding: 0.3125rem 0.625rem;

    &--details {
      align-items: center;
      display: flex;
      gap: 0.25rem;
    }
  }
}
</style>

<style lang="scss">
@use '@/variables.scss';
@use '@/variables_fonts.scss';

.pack-image {
  &__upload {
    background-color: variables.$white-default;
    border: 0.0625rem dashed variables.$gray-40;
    border-radius: 0.3125rem;
    cursor: pointer;
    height: 10.3125rem;
    margin: 0.625rem 0;
    transition: 0.3s;
    width: 100%;

    &:hover {
      background-color: variables.$gray-10;
    }

    &--complete {
      background-color: variables.$avo-toast-20;
      border-color: variables.$avo-toast-40;
      margin: 0.625rem 0;

      &:hover {
        background-color: variables.$avo-toast-20;
      }

      .pack-image__upload-btn {
        &:before {
          color: variables.$black-default;
          content: 'file upload complete!';
        }
      }

      .file-icon {
        display: none;
      }

      .file-label {
        color: transparent;
      }
    }

    .file {
      display: block;
      height: 100%;
    }

    label {
      height: 100%;
    }

    &-btn {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      padding: 2.5rem 0;
      width: 100%;

      &:before {
        @include variables_fonts.u-label--heavy;
        content: 'drag and drop, or click to browse';
        position: absolute;
        text-align: center;
      }
    }

    .icon-wrapper {
      flex-grow: 1;
      position: relative;
    }

    .file-icon {
      height: 1.3125rem;
      position: relative;
      width: auto;
      z-index: 1;

      path {
        fill: variables.$white-default;
      }
    }

    .file-label {
      @include variables_fonts.u-label--regular;
    }
  }
}
</style>
