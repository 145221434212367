import { toValue } from 'vue';
import { http } from '@/config/vue-axios';
import { mapPaginationHeaders } from '@/lib/api-transforms';

export async function editsCollectionDigitalAssets(
  collectionId,
  {
    activeAssetId,
    currentPage,
    itemsPerPage,
    filters,
    facets,
    visibility,
  } = {},
  signal
) {
  const params = { ...toValue(filters) };

  if (activeAssetId) params['active_asset_id'] = activeAssetId;
  if (currentPage) params['page'] = currentPage;
  if (itemsPerPage) params['items'] = itemsPerPage;
  if (facets) params['facets'] = facets;
  if (visibility) params['visibility'] = visibility;

  const response = await http.get(
    `/collections/${toValue(collectionId)}/edits_collection_digital_assets`,
    {
      params,
      signal,
    }
  );

  return {
    pagination: mapPaginationHeaders(response.headers),
    assets: response.data?.edits_collection_digital_assets || [],
    photo_count: response.data?.photo_count,
    gif_count: response.data?.gif_count,
    video_count: response.data?.video_count,
  };
}
