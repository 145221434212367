<script setup>
import { computed } from 'vue';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';

const props = defineProps({
  filePages: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['updatePage']);

const filePages = computed(() => props.filePages);

const filesPagination = computed(() => {
  if (filePages.value) {
    return filePages.value.pagination;
  } else {
    return {
      totalCount: 0,
      currentPage: 0,
      totalPages: 0,
      itemsPerPage: 0,
    };
  }
});

const filesPaginationCurrentPage = computed(
  () => filesPagination.value.currentPage
);

const isPrevActive = computed(
  () =>
    filesPagination.value.currentPage > 1 &&
    filesPagination.value.totalPages > 1
);
const isNextActive = computed(
  () => filesPagination.value.currentPage < filesPagination.value.totalPages
);
</script>

<template>
  <div class="carousel-base">
    <div class="carousel-base__inner">
      <p class="u-label--regular carousel-base__title">
        <slot name="title" />
      </p>
      <div class="carousel-base__content">
        <SoonaButton
          :disabled="!isPrevActive"
          variation="icon-gray-outline"
          @on-click="emit('updatePage', filesPaginationCurrentPage - 1)"
        >
          <SoonaIcon name="arrow-left" />
          <span class="u-a11y-only">previous image</span>
        </SoonaButton>
        <slot />
        <SoonaButton
          class="carousel-base__next-btn"
          :disabled="!isNextActive"
          variation="icon-gray-outline"
          @on-click="emit('updatePage', filesPaginationCurrentPage + 1)"
        >
          <SoonaIcon name="arrow-right" />
          <span class="u-a11y-only">next image</span>
        </SoonaButton>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.carousel-base {
  background-color: variables.$white-default;
  border-top: 0.0625rem solid variables.$gray-30;

  &__inner {
    padding: 1.25rem 2rem 1.5rem;
    max-width: 80rem;
    margin: 0 auto;
  }

  &__title {
    margin-bottom: 1rem;
  }

  &__content {
    align-items: center;
    display: flex;
    gap: 0.5rem;
  }

  &__next-btn {
    margin-left: auto;
  }
}

:slotted(.carousel-base__img-link) {
  position: relative;
  border-radius: 0.3125rem;
  height: 4.1875rem;
  overflow: hidden;
  width: 5.5rem;
  box-shadow: 0 0 0 0 variables.$gray-30;
  transition: box-shadow 0.1s ease-out;

  &:hover {
    box-shadow: 0 0 0 0.25rem variables.$gray-30;
  }

  &:active {
    transition: none;
  }
}

:slotted(.carousel-base__img-link--active, .carousel-base__img-link:active) {
  box-shadow:
    0 0 0 0.125rem variables.$white-default,
    0 0 0 0.25rem variables.$periwink-blue-70;

  &:hover {
    box-shadow:
      0 0 0 0.125rem variables.$white-default,
      0 0 0 0.25rem variables.$periwink-blue-80;
  }
}
</style>
