import { useDigitalAsset } from '@/queries/digital_assets/useDigitalAsset';

import { computed } from 'vue';
import { truncate } from '@/lib/text';

export function useMediaEditorDigitalAsset(accountId, assetId, params = {}) {
  const {
    data: asset,
    error,
    isLoading,
    isSuccess,
  } = useDigitalAsset(
    { accountId, assetId, params },
    {
      enabled: computed(() => !!accountId.value && !!assetId.value),
    }
  );

  const assetUuid = computed(() => asset.value?.uuid);
  const previewUrl = computed(() => asset.value?.preview?.url);
  const webUrl = computed(() => asset.value?.web?.url);
  const maskUrl = computed(() => asset.value?.mask?.url);
  const foregroundUrl = computed(() => asset.value?.foreground?.url);
  const mediaUrl = computed(() => asset.value?.media?.url);
  const mediaWidth = computed(() => asset.value?.media?.width);
  const mediaHeight = computed(() => asset.value?.media?.height);
  const mediaType = computed(() => asset.value?.media_type);
  const assetAspectRatio = computed(() => mediaWidth.value / mediaHeight.value);
  const title = computed(() => asset.value?.title);
  const truncatedTitle = computed(() => truncate(title.value, 50, true));

  const createdInEditor = computed(
    () => asset.value?.asset_type === 'platform'
  );
  const isPhoto = computed(
    () =>
      asset.value?.media_type === 'photo' &&
      asset.value?.content_type !== 'image/gif'
  );

  const isGif = computed(() => asset.value?.media_type === 'animation');

  const isIndependentRawAsset = computed(
    () =>
      (!asset.value?.preview?.url && asset.value?.raw?.url) ||
      asset.value?.raw_only
  );

  const reservationId = computed(
    () => asset.value?.reservation_digital_asset?.reservation_id
  );

  const rdaId = computed(() => asset.value?.reservation_digital_asset?.id);

  const pendingEditorTasks = computed(
    () => (asset.value?.pending_editor_tasks || 0) > 0
  );

  const ownedByCustomer = computed(() => asset.value?.ownership === 'customer');
  const ownedBySoona = computed(() => asset.value?.ownership === 'soona');

  const inBag = computed(() => !!asset.value?.bag_collection_digital_asset?.id);

  return {
    asset,
    assetUuid,
    assetAspectRatio,
    createdInEditor,
    error,
    isLoading,
    isSuccess,
    isPhoto,
    isGif,
    isIndependentRawAsset,
    previewUrl,
    title,
    truncatedTitle,
    foregroundUrl,
    maskUrl,
    webUrl,
    mediaUrl,
    mediaWidth,
    mediaHeight,
    mediaType,
    reservationId,
    rdaId,
    pendingEditorTasks,
    ownedByCustomer,
    ownedBySoona,
    inBag,
  };
}
