<script setup>
import { computed, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaFlag from '@/components/ui_library/SoonaFlag.vue';
import SoonaTooltip from '@/components/ui_library/SoonaTooltip.vue';
import SchedulingPreferences from '@/components/user/anytime/customer_shoot_readiness/scheduling_preferences/SchedulingPreferences.vue';
import { useReservation } from '@/composables/useReservation';
import { timeZoneAbbr } from '@/lib/date-formatters';
import { GreenApple20 } from '@/variables.module.scss';
import { useCapability } from '@/composables/useCapability';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useTitle } from '@vueuse/core';

const editMode = ref(false);
const cancelEdits = ref(false);

const route = useRoute();
const reservationId = computed(() => route.params.reservationId);

const { hasCapability: isSoonaStaff } = useCapability({
  capability: 'soona_staff',
});

// segment
const { linkClicked, pageViewed } = useBaseEvents();
useTitle(computed(() => `${route.meta.page_title} | soona`));

onMounted(() => {
  pageViewed();
});

const {
  isSuccess: reservationLoaded,
  reservationLocation,
  scheduledStartTime,
} = useReservation(reservationId);

const studioTimezoneDisplay = computed(() => {
  return `studio timezone: ${timeZoneAbbr(reservationLocation.value?.timezone)}`;
});

const handleCancelEdit = () => {
  cancelEdits.value = true;
  editMode.value = false;
};

const handleCancelEditReset = () => {
  cancelEdits.value = false;
};

const handleEditClick = () => {
  editMode.value = true;

  linkClicked({
    context: 'reservation',
    subContext: 'availability',
    linkLabel: 'edit availability',
    linkHref: null,
  });
};
</script>
<template>
  <div class="availability">
    <div class="availability__header">
      <h3 class="u-title--heavy">your availability</h3>
      <SoonaFlag
        v-if="reservationLoaded && isSoonaStaff"
        font-weight="bolder"
        :title="studioTimezoneDisplay"
        :background-color="GreenApple20"
        type="pill"
      />
    </div>

    <p class="u-body--regular">
      share your preferred days and time ranges for your shoot below. we will do
      our best to accommodate your preferences.
      <strong
        >we will not be able to schedule your shoot until we have received your
        shipments.</strong
      >
    </p>

    <hr />

    <div class="availability__preferences">
      <SchedulingPreferences
        class="availability__preferences--form"
        :edit-mode="editMode"
        form-id="scheduling-preferences-form"
        :cancel-edits="cancelEdits"
        :reservation-id="reservationId"
        :show-form-header="false"
        @close="editMode = $event"
        @reset-cancel-edits="handleCancelEditReset"
      />

      <div class="availability__preferences--buttons">
        <SoonaButton
          v-if="editMode"
          type="submit"
          form="scheduling-preferences-form"
          variation="primary"
          copy="save availability"
        />
        <SoonaTooltip v-else>
          <template
            #default="{
              setRef,
              mouseenter,
              focus,
              mouseleave,
              blur,
              ariaDescribedby,
            }"
          >
            <SoonaButton
              :ref="el => setRef(el)"
              variation="secondary-gray"
              copy="edit availability"
              :disabled="!!scheduledStartTime"
              :aria-describedby="ariaDescribedby"
              @mouseenter="mouseenter"
              @focus="focus"
              @mouseleave="mouseleave"
              @blur="blur"
              @on-click="() => handleEditClick()"
            />
          </template>
          <template v-if="!!scheduledStartTime" #tooltip-content
            >editing unavailable once shoot has been scheduled.</template
          >
        </SoonaTooltip>
      </div>
    </div>
    <hr />

    <div class="availability__footer">
      <div v-if="editMode" class="availability__footer--buttons">
        <SoonaButton
          variation="tertiary"
          copy="cancel"
          @on-click="handleCancelEdit()"
        />
        <SoonaButton
          type="submit"
          form="scheduling-preferences-form"
          variation="primary"
          copy="save availability"
        />
      </div>
      <SoonaTooltip v-else placement="top">
        <template
          #default="{
            setRef,
            mouseenter,
            focus,
            mouseleave,
            blur,
            ariaDescribedby,
          }"
        >
          <SoonaButton
            :ref="el => setRef(el)"
            variation="secondary-gray"
            copy="edit availability"
            :disabled="!!scheduledStartTime"
            :aria-describedby="ariaDescribedby"
            @mouseenter="mouseenter"
            @focus="focus"
            @mouseleave="mouseleave"
            @blur="blur"
            @on-click="() => handleEditClick()"
          />
        </template>
        <template v-if="!!scheduledStartTime" #tooltip-content
          >editing unavailable once shoot has been scheduled.</template
        >
      </SoonaTooltip>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.availability {
  &__header {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 1rem;
    align-items: center;
    margin: 1rem 0;
  }

  &__preferences {
    display: flex;
    flex-wrap: wrap-reverse;
    gap: 1rem;

    &--form {
      flex-grow: 1;
      max-width: 40rem;
    }
    &--buttons {
      margin-left: auto;
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;

    &--buttons {
      display: flex;
      gap: 1rem;
    }
  }
}
</style>
