<template>
  <div v-if="isSoonaStaff" class="mt-l add-crew-assignment-container">
    <DisplayCrewAssignment
      v-for="crewAssignment in displayedCrewAssignments"
      :key="crewAssignment.id"
      :crew-assignment="crewAssignment"
      :reservation-id="reservationId"
      :remove-crew="removeCrew"
    />
    <CrewAssignmentModal
      v-if="crewAssignmentModalVisible"
      :modal-visible="crewAssignmentModalVisible"
      :close-modal="hideCrewAssignmentModal"
      :assign-crew-directly="false"
      :reservation-start="reservationStart"
      :reservation-end="reservationEnd"
      :reservation-id="reservationId"
    />
    <SoonaButton
      v-if="isFtSoonaStaff"
      copy="add crew"
      :disabled="disabled"
      size="medium"
      @on-click="showCrewAssignmentModal"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { useCapability } from '@/composables/useCapability';
import DisplayCrewAssignment from './DisplayCrewAssignment.vue';
import CrewAssignmentModal from './CrewAssignmentModal.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import dateTimeMixin from 'src/mixins/dateTimeMixin';

export default {
  name: 'AddCrewToReservation',
  components: {
    DisplayCrewAssignment,
    CrewAssignmentModal,
    SoonaButton,
  },
  mixins: [dateTimeMixin],
  props: {
    disabled: Boolean,
    reservationId: Number,
    reservationStart: {
      type: [String, Object],
    },
    reservationEnd: {
      type: [String, Object],
    },
  },
  setup() {
    const { hasCapability: isFtSoonaStaff } = useCapability({
      capability: 'ft_soona_staff',
    });

    const { hasCapability: isSoonaStaff } = useCapability({
      capability: 'soona_staff',
    });

    return { isFtSoonaStaff, isSoonaStaff };
  },
  data() {
    return {
      crewAssignmentModalVisible: false,
    };
  },
  computed: {
    ...mapState({
      nonPersistedCrewAssignments: state => state.reservations.reservation.crew,
    }),
    displayedCrewAssignments() {
      return this.nonPersistedCrewAssignments.filter(
        ca =>
          ca._destroy !== 1 &&
          (ca.status === 'accepted' || ca.status === 'sent')
      );
    },
  },
  watch: {
    reservationId: function (val) {
      if (val) {
        this.loadCrewAssignments(val);
      }
    },
  },
  mounted() {
    if (this.reservationId) {
      this.loadCrewAssignments(this.reservationId);
    }
  },
  methods: {
    ...mapActions(['removeCrewAssignmentFromReservation']),
    ...mapActions('crewAssignment', ['loadCrewAssignments']),
    showCrewAssignmentModal() {
      this.crewAssignmentModalVisible = true;
    },
    hideCrewAssignmentModal() {
      this.crewAssignmentModalVisible = false;
    },
    removeCrew(crew) {
      //removes crew from the reservations store reservation.crew object
      this.removeCrewAssignmentFromReservation(crew);
    },
  },
};
</script>
