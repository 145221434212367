import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from 'src/queries/query-keys';
import { http } from '@/config/vue-axios';

/**
 * @param {string | number} accountId
 */
export function useGenerateEmbeddings(accountId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      const response = await http.post(
        `/accounts/${accountId.value}/embeddings`
      );
      return response.data;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.digitalAssets(accountId),
      });
    },
  });
}
