<script setup>
import { computed, ref } from 'vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import ProductIntegrations from './ProductIntegrations.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import ProductIntegrationConnectionLinks from './ProductIntegrationConnectionLinks.vue';
import { useMe } from '@/composables/user/useMe';
import { useAccount } from '@/queries/account/useAccount';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import { usePriorityError } from 'src/composables/usePriorityError';

const props = defineProps({
  accountId: { type: String, required: true },
  integrationsToDisplay: { type: [Array, String], default: 'all' },
  page: { type: Number, required: false, default: 1 },
});

const emit = defineEmits(['close', 'openRemoveAmazonDataDialog']);
const page = computed(() => props.page);
const currentPage = ref(page.value);
const accountId = computed(() => props.accountId);
const { data: account, error: errorAccount } = useAccount(accountId);
const { currentAccountId } = useMe();
const isMyAccount = computed(
  () => currentAccountId.value === Number(accountId.value)
);

const header = computed(() => {
  if (currentPage.value === 1) {
    return `${isMyAccount.value ? 'my' : account.value?.name + '’s'} connected accounts`;
  } else if (currentPage.value === 2) {
    return 'connect new account';
  } else {
    return '';
  }
});

function closeModal() {
  emit('close');
}

function nextPage() {
  currentPage.value += 1;
}

function backPage() {
  currentPage.value -= 1;
}

const { priorityError } = usePriorityError(errorAccount);
</script>

<template>
  <SoonaDialog max-width="50%" @close="closeModal">
    <template #heading> {{ header }} </template>
    <template #default>
      <SoonaError v-if="priorityError">{{ priorityError }}</SoonaError>
      <div class="dialog__body">
        <div v-if="currentPage === 1" class="dialog__body__manage__accounts">
          <p class="dialog__body__header">
            these accounts are connected to soona
          </p>
          <ProductIntegrations
            :account-id="accountId"
            :integrations-to-display="integrationsToDisplay"
            @open-remove-amazon-data-dialog="
              emit('openRemoveAmazonDataDialog', $event)
            "
            @close="emit('close')"
          />
          <div>
            <SoonaButton
              class="dialog__body__btn"
              variation="secondary-gray"
              @click="nextPage()"
            >
              <template #icon-left>
                <SoonaIcon name="plus-large" />
              </template>
              connect new account
            </SoonaButton>
          </div>
        </div>
        <div v-else-if="currentPage === 2">
          <ProductIntegrationConnectionLinks
            :account-id="accountId"
            :integrations-to-display="integrationsToDisplay"
          />
        </div>
      </div>
    </template>
    <template v-if="currentPage === 2" #footer>
      <div class="dialog__footer">
        <SoonaButton
          class="dialog__body__btn"
          variation="tertiary"
          copy="back"
          @click="backPage()"
        >
          <template #icon-left>
            <SoonaIcon name="arrow-left" />
          </template>
        </SoonaButton>
      </div>
    </template>
  </SoonaDialog>
</template>

<style lang="scss" scoped>
@use '@/variables_fonts';
.dialog {
  &__body {
    &__manage__accounts {
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }
  }

  &__footer {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }
}
</style>
