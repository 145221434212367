<template>
  <div class="vertical-gallery">
    <div class="gallery-title-container">
      <h2 class="u-title--heavy">{{ title }}</h2>
      <div v-if="type == 'assignment' && !disableCalendar">
        <div
          v-if="
            !isProServiceAccount ||
            (isMyAccount && canManageMyUnavailabilityEvents)
          "
          class="unavailability-modal-btn button booking-action-button mt-m calendar-item"
          data-cypress="add-unavailability"
          @click="openUnavailabilityEventModal"
        >
          add unavailability
        </div>
        <unavailability-event-modal
          v-if="showUnavailabilityEventModal"
          :close-modal="closeUnavailabilityEventModal"
        />
        <calendar-view-toggle
          class="calendar-item"
          :active="!calendarView"
          :dark-icon="groupHorizontalLinesImage"
          :light-icon="groupHorizontalLinesAltImage"
          icon-class="calendar-card-pick-icon"
          type="cards"
          data-cypress="assignments-view"
          @change-calendar-view="changeCalendarView"
        />
        <calendar-view-toggle
          class="calendar-item"
          :active="calendarView"
          :dark-icon="calendarRegularImage"
          :light-icon="calendarAltRegularImage"
          icon-class="calendar-card-pick-icon"
          type="calendar"
          data-cypress="availability-calendar-view"
          @change-calendar-view="changeCalendarView"
        />
      </div>
      <SoonaButton
        v-if="ownAccount && type !== 'assignment'"
        copy="book now"
        element="a"
        href="/book/new"
        size="medium"
      />
    </div>
    <div
      v-if="
        clientDrafts.length > 0 ||
        suggestedDrafts.length > 0 ||
        sortedReservations.length > 0
      "
      class="filter-wrapper"
    >
      <button
        v-if="upcomingReservations.length > 0"
        class="filter-button"
        :class="bookingsToShow == '' ? 'selected' : ''"
        @click.prevent="updateBookingsToShow('')"
      >
        upcoming
      </button>
      <button
        v-if="clientDrafts.length > 0"
        class="filter-button"
        :class="bookingsToShow == 'client_draft' ? 'selected' : ''"
        @click.prevent="updateBookingsToShow('client_draft')"
      >
        drafts
      </button>
      <button
        v-if="suggestedDrafts.length > 0"
        class="filter-button"
        :class="bookingsToShow == 'suggested_draft' ? 'selected' : ''"
        @click.prevent="updateBookingsToShow('suggested_draft')"
      >
        suggested
      </button>
    </div>
    <p v-if="bookingsToShow === 'suggested_draft'" class="suggested-draft-text">
      suggested bookings from the crew are here. personalized just for you!
      complete the booking to get started.
    </p>
    <p v-if="bookingsToShow === 'client_draft'" class="suggested-draft-text">
      your saved booking drafts are here. complete your booking to get started.
    </p>
    <assignments-calendar
      v-if="calendarView && !disableCalendar"
      ref="assignmentsCalendar"
      :assigned-reservations="sortedReservations"
    />
    <div
      v-if="!calendarView || disableCalendar"
      :class="
        type === 'assignment'
          ? 'assignments-cards-container'
          : 'cards-container'
      "
    >
      <span v-for="reservation in sortedReservations" :key="reservation.id">
        <div v-if="reservation.status !== 'draft'">
          <assignment-card
            v-if="type === 'assignment'"
            :title="title"
            :reservation="reservation"
            :own-account="ownAccount"
            :type="type"
          />
          <a v-else :href="`/user/anytime#/reservation/${reservation.id}/info`">
            <gallery-card
              :title="title"
              :reservation="reservation"
              :own-account="ownAccount"
              :type="bookingsToShow"
            />
          </a>
        </div>
        <gallery-card
          v-else
          :title="title"
          :reservation="reservation"
          :own-account="ownAccount"
          :type="bookingsToShow"
        />
      </span>
      <div v-if="hasNextPage" class="load-more-button-container">
        <SoonaButton
          variation="secondary-black"
          size="medium"
          @click="fetchNextPage"
        >
          <template #copy>load more</template>
        </SoonaButton>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { useCapability } from '@/composables/useCapability';
import orderBy from 'lodash/orderBy';
import AssignmentCard from './AssignmentCard.vue';
import GalleryCard from './GalleryCard.vue';
import AssignmentsCalendar from 'src/components/shared/assignmentsCalendar.vue';
import UnavailabilityEventModal from 'src/components/shared/UnavailabilityEventModal.vue';
import CalendarViewToggle from 'src/components/shared/calendarViewToggle.vue';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import groupHorizontalLinesImage from 'images/group-horizontal-lines.svg';
import groupHorizontalLinesAltImage from 'images/group-horizontal-lines-alt.svg';
import calendarRegularImage from 'images/calendar-regular.svg';
import calendarAltRegularImage from 'images/calendar-alt-regular.svg';

export default {
  components: {
    GalleryCard,
    AssignmentCard,
    AssignmentsCalendar,
    UnavailabilityEventModal,
    CalendarViewToggle,
    SoonaButton,
  },
  props: {
    title: String,
    reservations: Array,
    ownAccount: Boolean,
    type: String,
    disableCalendar: Boolean,
    hasNextPage: {
      type: Boolean,
      required: false,
      default: false,
    },
    fetchNextPage: {
      type: Function,
      required: false,
    },
  },
  setup() {
    const { hasCapability: canManageMyUnavailabilityEvents } = useCapability({
      capability: 'manage_my_unavailability_events',
    });

    return {
      canManageMyUnavailabilityEvents,
      groupHorizontalLinesImage,
      groupHorizontalLinesAltImage,
      calendarRegularImage,
      calendarAltRegularImage,
    };
  },
  data() {
    return {
      page: 1,
      calendarView: true,
      showUnavailabilityEventModal: false,
      bookingsToShow: '',
    };
  },
  computed: {
    ...mapState({
      isMyAccount: state => state.currentUser.accountId === state.account.id,
      account: state => state.account,
    }),
    ...mapGetters('account', ['isProServiceAccount']),
    sortedReservations: function () {
      switch (this.bookingsToShow) {
        case 'client_draft':
          return orderBy(
            this.clientDrafts,
            ['start', 'created_at'],
            ['asc', 'asc']
          );
        case 'suggested_draft':
          return orderBy(
            this.suggestedDrafts,
            ['start', 'created_at'],
            ['asc', 'asc']
          );
        default:
          return orderBy(
            this.upcomingReservations,
            ['start', 'created_at'],
            ['asc', 'asc']
          );
      }
    },
    upcomingReservations() {
      return this.reservations.filter(r => r.status !== 'draft');
    },
    clientDrafts() {
      let clientDrafts = this.reservations.filter(
        reservation =>
          reservation.status === 'draft' &&
          reservation.draft_type === 'client_draft'
      );
      return clientDrafts;
    },
    suggestedDrafts() {
      let suggestedDrafts = this.reservations.filter(
        reservation =>
          reservation.status === 'draft' &&
          reservation.draft_type === 'suggested_draft'
      );
      return suggestedDrafts;
    },
  },
  watch: {
    clientDrafts: {
      handler(newVal, oldVal) {
        if (newVal.length > 0 && newVal !== oldVal) {
          this.updateBookingsToShow('client_draft');
        }
      },
      deep: true,
    },
    suggestedDrafts: {
      handler(newVal, oldVal) {
        if (newVal.length > 0 && newVal !== oldVal) {
          this.updateBookingsToShow('suggested_draft');
        }
      },
      deep: true,
    },
    upcomingReservations: {
      handler(newVal, oldVal) {
        if (newVal.length > 0 && newVal !== oldVal) {
          this.updateBookingsToShow('');
        }
      },
      deep: true,
    },
  },
  beforeMount() {
    if (this.isMyAccount && this.isProServiceAccount) this.calendarView = false;
  },
  methods: {
    changeCalendarView(type) {
      if (type === 'calendar') {
        this.calendarView = true;
      } else {
        this.calendarView = false;
      }
    },
    openUnavailabilityEventModal() {
      this.showUnavailabilityEventModal = true;
    },
    closeUnavailabilityEventModal() {
      this.showUnavailabilityEventModal = false;
      this.$refs.assignmentsCalendar.refreshEvents();
    },
    updateBookingsToShow(type) {
      this.bookingsToShow = type;
    },
  },
};
</script>

<style lang="scss">
@use '@/variables';
@use '@/variables_fonts';

.vertical-gallery {
  @media (min-width: variables.$screen-sm-min) {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  .gallery-title-container {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 1rem;
    width: 100%;
  }

  .cards-container {
    flex: 1 1 0.0625rem;
    min-height: 15.625rem;
    overflow-x: hidden;
    overflow-y: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    width: 100%;

    &::-webkit-scrollbar {
      display: none;
    }

    @media only screen and (max-width: variables.$screen-xs-max) {
      max-height: 18.75rem;
    }
  }

  .booking-action-button {
    background: variables.$friendly-red-50;
    box-shadow: 0 0.25rem 0.375rem rgba(0, 0, 0, 0.1);
    color: variables.$white-default;
    font-size: 0.625rem;
    font-weight: 800;
    height: 3em;
    letter-spacing: 0.0625rem;
    padding: 0.5em 1.5em 0.5em 1.5em;
    text-transform: uppercase;
  }
}

.assignments-cards-container {
  max-height: 700px;
  overflow-y: auto;
  overflow-x: hidden;

  @media only screen and (max-width: 768px) {
    max-height: 300px;
  }
}

.calendar-item {
  grid-area: 2 / 2 / 3 / 3;
  vertical-align: bottom;
}

.card-calendar-toggle {
  box-shadow: 0px 3px 6px #00000029;
}
.calendar-card-pick-icon {
  width: 13px;
  margin: 3px;
  padding-right: 2px;
}

.filter-wrapper {
  width: 100%;
  margin-bottom: 10px;
  .filter-button {
    @include variables_fonts.u-label--heavy;
    color: variables.$black-default;

    background: none;
    padding: 3px 10px 5px;
    border: 1px solid variables.$gray-30 !important;
    border-radius: 40px !important;
    margin: 0 5px;
    &.selected {
      border: 1px solid variables.$friendly-red-40 !important;
      background: variables.$friendly-red-20;
    }
  }
}

.suggested-draft-text {
  margin-left: 5px;
}

.load-more-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
