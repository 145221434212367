<script setup>
import { computed, ref, watchEffect } from 'vue';
import { convertToDDMMYYHHMM12z } from '@/lib/date-formatters';
import SoonaSelect from 'src/components/ui_library/SoonaSelect.vue';
import AssetSummary from './AssetSummary.vue';
import AddOnOrderUploadEdits from '../AddOnOrderUploadEdits.vue';

const props = defineProps({
  addOn: {
    required: true,
    type: Object,
  },
});

const reservationFile = computed(() => props.addOn.reservation_file);

const left = ref(false);
const right = ref(false);

const formatOption = reservationFile => {
  return {
    label: `${reservationFile.edit_status} - ${convertToDDMMYYHHMM12z(
      reservationFile.created_at
    )}`,
    value: reservationFile,
  };
};

const choices = computed(() => {
  if (!reservationFile.value) return [];

  const opts = [formatOption(reservationFile.value)];
  reservationFile.value.derivatives.forEach(derivative =>
    opts.push(formatOption(derivative))
  );
  return opts;
});

watchEffect(() => {
  if (!choices.value) return;

  const existingLeft = choices.value.find(c => c.value.id === left.value?.id);
  const existingRight = choices.value.find(c => c.value.id === right.value?.id);

  left.value = existingLeft?.value ?? choices.value.at(0)?.value;
  right.value =
    choices.value.length > 1
      ? (existingRight?.value ?? choices.value.at(-1)?.value)
      : false;
});
</script>
<template>
  <div class="columns-wrapper">
    <div v-if="left" :key="left.file.url" class="columns-wrapper__column">
      <img
        v-if="addOn.media_type === 'photo'"
        :src="left.file.url"
        :alt="`${left.edit_status} uploaded at ${left.created_at}`"
      />
      <video
        v-else-if="addOn.media_type === 'video'"
        :key="left.preview_url"
        :poster="left.preview_url"
        controls
        controlsList="nodownload"
        class="preview-media"
      >
        <source :key="left.file.url" :src="left.file.url" type="video/mp4" />
      </video>
      <div class="derivative-selector-wrapper">
        <AssetSummary
          :reservation-file="left"
          :order-id="addOn.order.id"
          :original-reservation-file-id="reservationFile.id"
          :add-on-line-item-id="addOn.id"
          :enable-review="addOn.order.status === 'paid'"
        />
        <SoonaSelect v-model="left" :options="choices">
          <template #label>left</template>
        </SoonaSelect>
      </div>
    </div>
    <div v-if="right" :key="right.file.url" class="columns-wrapper__column">
      <img
        v-if="addOn.media_type === 'photo'"
        :src="right.file.url"
        :alt="`${right.edit_status} uploaded at ${right.created_at}`"
      />
      <video
        v-else-if="addOn.media_type === 'video'"
        :key="right.preview_url"
        :poster="right.preview_url"
        controls
        controlsList="nodownload"
        class="preview-media"
      >
        <source :key="left.file.url" :src="left.file.url" type="video/mp4" />
      </video>
      <div class="derivative-selector-wrapper">
        <AssetSummary
          :reservation-file="right"
          :order-id="addOn.order.id"
          :original-reservation-file-id="reservationFile.id"
          :add-on-line-item-id="addOn.id"
          :enable-review="addOn.order.status === 'paid'"
        />
        <SoonaSelect v-model="right" :options="choices">
          <template #label>right</template>
        </SoonaSelect>
      </div>
    </div>
    <div v-else class="columns-wrapper__column">
      <p>nothing to compare</p>
    </div>
  </div>
  <div class="columns-wrapper">
    <div class="columns-wrapper__column">
      <div class="notes-wrapper">
        <h2 class="section-title">notes</h2>
        <p>{{ reservationFile.note }}</p>
      </div>
      <div class="add-ons-wrapper">
        <h2 class="section-title">add-ons</h2>
        <ul class="add-on-items">
          <li
            v-for="mediaAddOn in addOn.media_add_ons"
            :key="mediaAddOn.id"
            class="add-on-item is-size-7"
          >
            {{ mediaAddOn.name }} ({{
              Number(mediaAddOn.total).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })
            }})
          </li>
        </ul>
      </div>
    </div>
    <div class="columns-wrapper__column">
      <AddOnOrderUploadEdits
        v-if="reservationFile"
        :order-id="addOn.order.id"
        :reservation-id="reservationFile.reservation_id"
        :original-reservation-file-id="reservationFile.id"
        variation="secondary-gray"
      />
    </div>
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.section-title {
  @include variables_fonts.u-label--heavy;
}

.notes-wrapper {
  margin: 0.25rem;
}

.add-ons-wrapper {
  margin: 0.25rem;
}

.review-wrapper {
  display: flex;
  flex-direction: column;
}

.columns-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.columns-wrapper__column {
  flex: 1 1 30rem;
}

.derivative-selector-wrapper {
  width: 100%;
  background: variables.$gray-10;
  border: 0.0625rem solid variables.$gray-30;
  border-radius: variables.$control-radius;
  padding: 0.75rem;
  margin-top: 0.25rem;
}
</style>
