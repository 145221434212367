<script setup>
import { computed, ref, defineAsyncComponent, watch, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useReservationActionBar } from '@/components/user/anytime/gallery/media-editor/useReservationActionBar';
import {
  BACKGROUND_COLOR_ACTION,
  REMOVE_BACKGROUND_ACTION,
  BLUR_BACKGROUND_ACTION,
  PREMIUM_EDITS_ACTION,
  RESIZE_ACTION,
  SHADOWS_ACTION,
  AI_SCENES_ACTION,
  AI_PROPS_ACTION,
  ADJUST_ACTION,
  RE_EDITS_ACTION,
} from '@/components/user/anytime/gallery/media-editor/mediaEditorActionKeys';
import { useReservationDigitalAsset } from '@/queries/reservation_digital_assets/useReservationDigitalAsset';
import { useMediaEditor } from '@/components/user/anytime/gallery/media-editor/useMediaEditor';
import ActionBar from '@/components/user/anytime/gallery/media-editor/action-bars/ActionBar.vue';
import ActionBarButton from '@/components/user/anytime/gallery/media-editor/action-bars/ActionBarButton.vue';
import MediaMultiplierLoading from '@/components/user/anytime/gallery/media-editor/media-multiplier/MediaMultiplierLoading.vue';
import MediaMultiplierError from '@/components/user/anytime/gallery/media-editor/media-multiplier/MediaMultiplierError.vue';
import RemoveBackground from '@/components/user/anytime/gallery/media-editor/media-multiplier/RemoveBackground.vue';
import ImageResizer from '@/components/user/anytime/gallery/media-editor/media-multiplier/resizer/ImageResizer.vue';

import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import ActionBarSecondaryButton from '@/components/user/anytime/gallery/media-editor/action-bars/ActionBarSecondaryButton.vue';

import backgroundColor from 'images/media-editor/background-color.png';
import blurBackground from 'images/media-editor/blur-background.png';
import PremiumEditsDigitalAsset from '@/components/user/anytime/gallery/media-editor/PremiumEditsDigitalAsset.vue';
import RequestedReEditRDA from '@/components/user/anytime/gallery/media-editor/media-multiplier/RequestedReEditRDA.vue';
import { useMokkerRedirect } from '@/queries/mokker/useMokkerRedirect';
import { useSoonaToast } from '@/components/ui_library/soona_toast/useSoonaToast';
import { useMediaEditorDigitalAsset } from '@/composables/digital_assets/useMediaEditorDigitalAsset';
import { useFlag } from '@/composables/useFlag';
import { useMe } from '@/composables/user/useMe';

const props = defineProps({
  activeAction: {
    type: Symbol,
  },
  asset: {
    type: Object,
    default: undefined,
  },
  iframeUrl: {
    type: String,
    default: null,
  },
  selectionData: {
    type: Object,
    default: () => {},
  },
});

const emits = defineEmits([
  'update-action',
  'update-selector',
  'draw-canvas',
  'show-foreground',
  'close-editor',
  'show-paywall-dialog',
  'is-notes-dirty',
  'show-feedback-dialog',
  'set-iframe-url',
]);

const AsyncShiftBackgroundColor = defineAsyncComponent({
  loader: async () =>
    await import(
      '@/components/user/anytime/gallery/media-editor/media-multiplier/ShiftBackgroundColor.vue'
    ),
  loadingComponent: MediaMultiplierLoading,
  delay: 100,
  errorComponent: MediaMultiplierError,
});

const AsyncBlurBackground = defineAsyncComponent({
  loader: async () =>
    await import(
      '@/components/user/anytime/gallery/media-editor/media-multiplier/BlurBackground.vue'
    ),
  loadingComponent: MediaMultiplierLoading,
  delay: 100,
  errorComponent: MediaMultiplierError,
});

const AsyncAddShadows = defineAsyncComponent({
  loader: async () =>
    await import(
      '@/components/user/anytime/gallery/media-editor/media-multiplier/AddShadows.vue'
    ),
  loadingComponent: MediaMultiplierLoading,
  delay: 100,
  errorComponent: MediaMultiplierError,
});

const pegasusPaywallRemoveBackgroundFlag = useFlag(
  'pegasus_paywall_remove_background'
);

const activeAction = computed(() => props.activeAction);
const iframeUrl = computed(() => props.iframeUrl);
const asset = computed(() => props.asset);
const assetId = computed(() => asset.value?.id);
const accountId = computed(() => asset.value?.account_id);

const { currentAccountId } = useMe();
const isMyAccount = computed(() => currentAccountId.value === accountId.value);

const {
  isPhoto,
  assetUuid,
  reservationId,
  rdaId,
  pendingEditorTasks,
  foregroundUrl,
  maskUrl,
  mediaUrl,
  ownedByCustomer,
  ownedBySoona,
} = useMediaEditorDigitalAsset(accountId, assetId);

const { data: reservationDigitalAsset } = useReservationDigitalAsset(
  {
    reservationId: reservationId,
    rdaId: rdaId,
  },
  {
    enabled: computed(() => !!rdaId.value && !!reservationId.value),
  }
);

const hasReRDAReEditRequest = computed(
  () =>
    reservationDigitalAsset.value?.digital_asset
      ?.re_edits_collection_digital_asset?.id
);

const enableAIStudio = computed(
  () => ownedByCustomer.value && !!assetUuid.value && isMyAccount.value
);

const shiftBackgroundComponent = ref(null);
const removeBackgroundComponent = ref(null);
const blurBackgroundComponent = ref(null);
const imageResizerComponent = ref(null);
const addShadowsComponent = ref(null);

const {
  flyoutHeading,
  flyoutSubheading,
  isAdjustActive,
  isAdjustOpen,
  isAIScenesActive,
  isAIPropsActive,
  isBlurBackgroundActive,
  isImageResizerActive,
  isLeftFlyoutOpen,
  isPremiumEditsActive,
  isReEditRequestActive,
  isRemoveBackgroundActive,
  isShiftBackgroundColorActive,
  isShadowsActive,
  showBackButton,
} = useMediaEditor({
  activeAction,
});

const { linkClicked } = useBaseEvents();

const handleDrawCanvas = cd => {
  emits('draw-canvas', cd);
};

const emitActiveAction = val => {
  emits('update-action', val);
};

const handleSaveToGallery = () => {
  if (isShiftBackgroundColorActive.value) {
    shiftBackgroundComponent.value.handleSaveToGallery();
  } else if (isRemoveBackgroundActive.value) {
    removeBackgroundComponent.value.handleSaveToGallery();
  } else if (isBlurBackgroundActive.value) {
    blurBackgroundComponent.value.handleSaveToGallery();
  } else if (isImageResizerActive.value) {
    imageResizerComponent.value.handleSaveToGallery();
  } else if (isShadowsActive.value) {
    addShadowsComponent.value.handleSaveToGallery();
  }
};

const handleResetImage = () => {
  if (isShiftBackgroundColorActive.value) {
    shiftBackgroundComponent.value.handleResetImage();
  } else if (isRemoveBackgroundActive.value) {
    removeBackgroundComponent.value.handleResetImage();
  } else if (isBlurBackgroundActive.value) {
    blurBackgroundComponent.value.handleResetImage();
  } else if (isImageResizerActive.value) {
    imageResizerComponent.value.handleResetImage();
  } else if (isShadowsActive.value) {
    addShadowsComponent.value.handleResetImage();
  }
};

defineExpose({ handleSaveToGallery, handleResetImage });

const {
  canAdjustBackgroundOnOwnAccount,
  staffCanAdjustBackgroundOnClientAccount,
} = useReservationActionBar({
  accountId,
});

const hasSubscriptionDownloadAccess = computed(
  () =>
    canAdjustBackgroundOnOwnAccount?.value ||
    staffCanAdjustBackgroundOnClientAccount?.value
);

const route = useRoute();

const handleLinkClicked = actionText => {
  linkClicked({
    context: route.meta.context,
    subContext: 'media editor menu',
    linkLabel: actionText,
    linkHref: null,
  });
};

const handleButtonClick = (actionText, actionSymbol) => {
  handleLinkClicked(actionText);
  emitActiveAction(actionSymbol);
};

const handlePaywallButtonClick = (actionText, actionSymbol) => {
  if (hasSubscriptionDownloadAccess.value) {
    handleButtonClick(actionText, actionSymbol);
  } else {
    emits('show-paywall-dialog');
  }
};

const handleRemoveBackgroundButtonClick = () => {
  if (
    !pegasusPaywallRemoveBackgroundFlag.value ||
    hasSubscriptionDownloadAccess.value
  ) {
    handleButtonClick('remove background', REMOVE_BACKGROUND_ACTION);
  } else {
    emits('show-paywall-dialog');
  }
};

const clickPremiumEdits = () => {
  linkClicked({
    context: route.meta.context,
    subContext: 'media editor menu',
    linkLabel: 'premium edits',
    linkHref: null,
  });
  emitActiveAction(PREMIUM_EDITS_ACTION);
};

// ai studio iframe
const { mutate: getMokkerRedirect, isPending: aiStudioRedirectLoading } =
  useMokkerRedirect();

const { addToast } = useSoonaToast();

const failureToast = () => {
  emits('set-iframe-url', undefined);
  addToast(`Failed to open AI studio`, {
    variation: 'error',
  });
};

const loadIframeUrl = () => {
  getMokkerRedirect(assetUuid.value, {
    onSuccess: response => {
      if (response.result) {
        const urlSuffix = isAIPropsActive.value ? '&photo_prop=1' : '';
        emits('set-iframe-url', `${response.result}${urlSuffix}`);
      } else {
        failureToast();
      }
    },
    onError: failureToast,
  });
};

const handleAIStudioButtonClick = (actionText, actionSymbol) => {
  handleButtonClick(actionText, actionSymbol);

  const closingAIScenes =
    isAIScenesActive.value && actionSymbol === AI_SCENES_ACTION;
  const closingAIProps =
    isAIPropsActive.value && actionSymbol === AI_PROPS_ACTION;

  if (closingAIScenes || closingAIProps) {
    emits('set-iframe-url', undefined);
    return;
  }

  loadIframeUrl();
};

const updateResizerData = data => emits('update-selector', data);

const shareFeedback = () => {
  linkClicked({
    context: route.meta.context,
    subContext: 'media editor menu',
    linkLabel: 'share feedback',
    linkHref: null,
  });
  emits('show-feedback-dialog');
};

const handleBackClick = () => {
  // when we add more subnavs have `MediaEditorProvider` in place, we'll want each component
  // to provide their back action symbol when the components register themselves with the provider.
  emits('update-action', ADJUST_ACTION);
};

// load iframe when AI scenes or AI props are active
// have to watch assetUuid because it's not set when the component is mounted
const loadIframeWhenReady = ref(false);
onMounted(() => {
  if (isAIScenesActive.value || isAIPropsActive.value) {
    loadIframeWhenReady.value = true;
  }
});

watch(assetUuid, () => {
  if (loadIframeWhenReady.value) {
    loadIframeUrl();
    loadIframeWhenReady.value = false;
  }
});
</script>

<template>
  <ActionBar
    class="left-action-bar"
    :is-flyout-open="isLeftFlyoutOpen && !iframeUrl"
    position="left"
  >
    <ActionBarButton
      v-if="isPhoto"
      column="left"
      icon-name="sliders"
      label="adjust"
      :enabled="!!maskUrl && !pendingEditorTasks"
      :loading="pendingEditorTasks"
      :is-selected="isAdjustActive"
      @on-click="() => handleButtonClick('adjust', ADJUST_ACTION)"
    />
    <ActionBarButton
      v-if="isPhoto"
      column="left"
      icon-name="scissors"
      label="remove background"
      :enabled="!!foregroundUrl && !pendingEditorTasks"
      :loading="pendingEditorTasks || !foregroundUrl"
      :is-selected="isRemoveBackgroundActive"
      :is-paywalled="pegasusPaywallRemoveBackgroundFlag"
      @on-click="handleRemoveBackgroundButtonClick"
    />
    <ActionBarButton
      v-if="isPhoto"
      column="left"
      icon-name="wand-magic-sparkles"
      label="AI scenes"
      :enabled="!!mediaUrl && !!enableAIStudio"
      :is-selected="isAIScenesActive"
      :loading="aiStudioRedirectLoading && isAIScenesActive"
      @on-click="handleAIStudioButtonClick('AI scenes', AI_SCENES_ACTION)"
    >
      <template #content-bottom>
        <small class="u-badge--small action-bar-left__beta">beta</small>
      </template>
    </ActionBarButton>
    <ActionBarButton
      v-if="isPhoto"
      column="left"
      icon-name="hand-holding"
      label="AI props"
      :enabled="!!mediaUrl && !!enableAIStudio"
      :is-selected="isAIPropsActive"
      :loading="aiStudioRedirectLoading && isAIPropsActive"
      @on-click="handleAIStudioButtonClick('AI props', AI_PROPS_ACTION)"
    >
      <template #content-bottom>
        <small class="u-badge--small action-bar-left__beta">beta</small>
      </template>
    </ActionBarButton>
    <ActionBarButton
      v-if="isPhoto"
      column="left"
      icon-name="shadows"
      label="shadows"
      :enabled="!!maskUrl && !pendingEditorTasks"
      :loading="pendingEditorTasks"
      :is-selected="isShadowsActive"
      is-paywalled
      @on-click="() => handlePaywallButtonClick('shadows', SHADOWS_ACTION)"
    />
    <ActionBarButton
      v-if="isPhoto"
      column="left"
      icon-name="resize"
      label="resize"
      :enabled="!!mediaUrl"
      :is-selected="isImageResizerActive"
      :show-quest-helper="true"
      @on-click="() => handleButtonClick('resize', RESIZE_ACTION)"
    />
    <ActionBarButton
      v-if="hasReRDAReEditRequest"
      column="left"
      icon-name="circle-exclamation"
      label="requested re-edit"
      :is-selected="isReEditRequestActive"
      @on-click="handleButtonClick('re-edits', RE_EDITS_ACTION)"
    />
    <ActionBarButton
      v-if="ownedBySoona && rdaId"
      column="left"
      icon-name="pen-swirl"
      label="premium edits"
      :is-selected="isPremiumEditsActive"
      @on-click="clickPremiumEdits"
    />
    <template v-if="flyoutHeading" #flyout-heading>
      <template v-if="showBackButton">
        <SoonaButton
          class="action-bar-left__back"
          variation="icon-gray-outline"
          size="medium"
          @on-click="handleBackClick"
        >
          <SoonaIcon name="arrow-left" size="medium" />
        </SoonaButton>
      </template>
      {{ flyoutHeading }}
    </template>
    <template v-if="flyoutSubheading" #flyout-subheading>
      {{ flyoutSubheading }}
    </template>
    <template #flyout-content>
      <template v-if="isAdjustOpen">
        <ActionBarSecondaryButton
          column="left"
          :thumb-image="backgroundColor"
          title="background color"
          description="adjust the background color of your image"
          :enabled="!!maskUrl && !pendingEditorTasks"
          @on-click="
            () => handleButtonClick('background color', BACKGROUND_COLOR_ACTION)
          "
        />
        <hr />
        <ActionBarSecondaryButton
          :thumb-image="blurBackground"
          title="blur background"
          description="add blur to your background and make your product pop!"
          :enabled="!!maskUrl && !pendingEditorTasks"
          @on-click="
            () => handleButtonClick('background color', BLUR_BACKGROUND_ACTION)
          "
        />
      </template>
      <PremiumEditsDigitalAsset
        v-if="isPremiumEditsActive"
        :asset="asset"
        @is-notes-dirty="emits('is-notes-dirty', $event)"
      />
      <AsyncShiftBackgroundColor
        v-else-if="isShiftBackgroundColorActive"
        ref="shiftBackgroundComponent"
        :file="asset"
        @draw-canvas="handleDrawCanvas($event)"
        @close-editor="emits('close-editor', $event)"
        @close-panel="emitActiveAction(undefined)"
      />
      <AsyncBlurBackground
        v-else-if="isBlurBackgroundActive"
        ref="blurBackgroundComponent"
        :file="asset"
        @draw-canvas="handleDrawCanvas($event)"
        @close-editor="emits('close-editor', $event)"
        @close-panel="emitActiveAction(undefined)"
      />
      <RemoveBackground
        v-else-if="isRemoveBackgroundActive"
        ref="removeBackgroundComponent"
        :file="asset"
        @show-foreground="emits('show-foreground', $event)"
        @close-editor="emits('close-editor', $event)"
        @close-panel="emitActiveAction(undefined)"
      />
      <ImageResizer
        v-else-if="isImageResizerActive"
        ref="imageResizerComponent"
        :file="asset"
        :selection-data="selectionData"
        :has-freemium-access="!hasSubscriptionDownloadAccess"
        @update-selector="updateResizerData"
        @close-editor="emits('close-editor', $event)"
        @show-paywall-dialog="emits('show-paywall-dialog')"
        @close-panel="emitActiveAction(undefined)"
      />
      <RequestedReEditRDA
        v-else-if="isReEditRequestActive"
        :key="reservationDigitalAsset?.id"
        :asset="asset"
        :reservation-digital-asset="reservationDigitalAsset"
        @close-panel="emitActiveAction(undefined)"
      />
      <AsyncAddShadows
        v-else-if="isShadowsActive"
        ref="addShadowsComponent"
        :file="asset"
        @close-editor="emits('close-editor', $event)"
        @draw-canvas="handleDrawCanvas($event)"
        @close-panel="emitActiveAction(undefined)"
      />
    </template>
    <div v-if="canAdjustBackgroundOnOwnAccount" class="me-feedback-action">
      <SoonaIcon
        class="me-feedback-action__icon"
        name="heart-duotone"
        size="small"
      />
      <p class="u-label--small me-feedback-action__text">
        help us improve our media editor!
      </p>
      <button
        type="button"
        class="u-button-reset u-label--small me-feedback-action__button"
        @click="shareFeedback"
      >
        share feedback
      </button>
    </div>
  </ActionBar>
</template>
<style lang="scss" scoped>
@use 'src/variables';

.action-bar-left {
  &__back {
    margin-right: 0.75rem;
    margin-top: 0.25rem;
  }

  &__beta {
    margin-top: 0.25rem;
    padding: 0.0625rem 0.25rem;
    background: variables.$periwink-blue-20;
    color: variables.$periwink-blue-80;
    border-radius: 3.125rem;
  }
}

.me-feedback-action {
  margin-top: auto;
  padding: 0.5rem;

  &__icon {
    color: variables.$periwink-blue-40;
  }

  &__text {
    color: variables.$gray-60;
  }

  &__button {
    text-decoration: underline;

    &:hover,
    &:focus-visible {
      text-decoration: none;
    }
  }
}
</style>
