import { unref } from 'vue';
import { useMutation } from '@tanstack/vue-query';
import { createReservationFile } from '@/api/reservationFiles';

/**
 *
 * @param {Number | Ref<Number>} reservationId
 */
export function useCreateReservationFile(reservationId) {
  return useMutation({
    mutationFn: body =>
      createReservationFile({ reservationId: unref(reservationId), body }),
  });
}
