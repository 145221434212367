<script setup>
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import { useRoute } from 'vue-router';

const { linkClicked } = useBaseEvents();
const route = useRoute();

const linkLabel = "let's go!";
const linkHref =
  import.meta.env.VITE_HOSTNAME === 'book.soona.co'
    ? import.meta.env.VITE_TRY_SOONA_FREE_URL
    : `/book/pack/3?discount=threefree`;

const handleLinkClick = () => {
  linkClicked({
    context: route.meta.context,
    subContext: 'trending packs',
    linkLabel: linkLabel,
    linkHref: linkHref,
  });
};
</script>

<template>
  <div class="trending-free-pack">
    <p class="u-label--regular trending-free-pack__title">
      get <strong>3 free photo credits</strong> for your first shoot
    </p>
    <div class="trending-free-pack__img-container">
      <img src="@images/platform-home/trending-free-pack.png" alt="" />
    </div>
    <SoonaButton
      element="a"
      :copy="linkLabel"
      :href="linkHref"
      data-cypress="button-try-free"
      @on-click="handleLinkClick"
    />
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.trending-free-pack {
  display: flex;
  flex: 0 0 13.75rem;
  flex-direction: column;
  padding: 1.5rem;
  text-align: center;

  &__title {
    padding-bottom: 1rem;
  }
}
</style>
