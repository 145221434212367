<script setup>
import { computed, nextTick, toRefs, watchEffect } from 'vue';
import { useTextareaAutosize } from '@vueuse/core';
import uniqueId from 'lodash/uniqueId';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { useMe } from '@/composables/user/useMe';
import SoonaUserAvatar from '@/components/ui_library/SoonaUserAvatar.vue';
import SoonaTooltip from '@/components/ui_library/SoonaTooltip.vue';

const props = defineProps({
  initialContent: {
    type: String,
    default: '',
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  priorityErrors: {
    type: Array,
    default: () => [],
  },
});

defineEmits(['submit']);

const { initialContent, isLoading, priorityErrors } = toRefs(props);

const { me } = useMe();

const id = uniqueId('note-composer-');
const textareaId = computed(() => `${id}-textarea`);
const errorId = computed(() => `${id}-error`);
const { textarea, input } = useTextareaAutosize();
const dirty = computed(() => input.value?.length > 0);

watchEffect(() => {
  input.value = initialContent.value;
  nextTick(() => {
    textarea.value?.focus();
  });
});

const submitDisabled = computed(
  () => input.value?.trim().length > 0 && isLoading.value
);

const showNewLineText = computed(() => input.value?.length > 2);

function textareaKeydown(e) {
  if (e.key === 'Enter' && !e.shiftKey) {
    e.preventDefault();
    const newEvent = new Event('submit', { cancelable: true });
    e.target.form.dispatchEvent(newEvent);
  }
}

function composerFocus() {
  if (textarea.value) {
    textarea.value.focus();
  }
}

function formReset() {
  input.value = '';
  nextTick(() => {
    textarea.value?.focus();
  });
}
</script>

<template>
  <div class="note-composer" tabindex="-1" @focus="composerFocus">
    <SoonaUserAvatar
      :src="me?.avatar_url"
      img-size="1.5rem"
      svg-size="1.5rem"
    />
    <form
      :aria-describedby="errorId"
      aria-label="comment"
      class="note-composer__form"
      @reset="formReset"
      @submit.prevent="$emit('submit', $event)"
    >
      <label :for="textareaId" class="u-visually-hidden">comment</label>
      <textarea
        :id="textareaId"
        ref="textarea"
        v-model="input"
        name="comment"
        placeholder="share a comment"
        class="note-composer__textarea"
        :data-dirty="dirty"
        required
        cols="4"
        :disabled="isLoading"
        @keydown="textareaKeydown"
      />

      <div class="note-composer__actions-row">
        <div>
          <Transition name="fade">
            <p
              v-show="showNewLineText"
              class="u-small--regular note-composer__new-line-help"
            >
              <span class="u-small--heavy"
                ><kbd>Shift</kbd> + <kbd>Return</kbd></span
              >
              to add a new line
            </p>
          </Transition>
        </div>
        <div class="note-composer__actions-row__right">
          <slot
            name="actions"
            :submit-disabled="submitDisabled"
            :is-loading="isLoading"
          >
            <SoonaTooltip>
              <template
                #default="{
                  setRef,
                  mouseenter,
                  focus,
                  mouseleave,
                  blur,
                  ariaDescribedby,
                }"
              >
                <SoonaButton
                  :ref="el => setRef(el)"
                  variation="icon-primary"
                  type="submit"
                  size="medium"
                  :disabled="submitDisabled"
                  :loading="isLoading"
                  :aria-describedby="ariaDescribedby"
                  @blur="blur"
                  @focus="focus"
                  @mouseenter="mouseenter"
                  @mouseleave="mouseleave"
                >
                  <SoonaIcon name="send-alt-1" />
                  <span class="u-visually-hidden">send comment</span>
                </SoonaButton>
              </template>
              <template #tooltip-content>send comment</template>
            </SoonaTooltip>
          </slot>
        </div>
      </div>
      <SoonaError
        v-if="priorityErrors"
        :id="errorId"
        :error="priorityErrors"
        no-margin
      />
    </form>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.note-composer {
  background: variables.$white-default;
  border-radius: 0.625rem;
  outline-offset: -0.125rem;
  border: 0.0625rem solid variables.$gray-30;
  display: flex;
  padding: 0.5rem;
  align-items: flex-start;
  gap: 0.5rem;
  align-self: stretch;

  &:hover {
    border-color: variables.$gray-60;
  }

  &__form {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 0.5rem;
  }

  &:focus-within {
    outline: 0.125rem solid variables.$periwink-blue-60;
  }

  &__textarea {
    -ms-overflow-style: none;
    scrollbar-width: none;
    resize: none;
    border: none;
    outline: none;
    padding: 0.1875rem 0.5rem;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__actions-row {
    display: none;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    flex-flow: row wrap;

    &__right {
      display: flex;
      gap: 0.75rem;
      flex-flow: row wrap;
      align-items: center;
    }
  }

  /* composer has focus within, or textarea has content */
  &:focus-within .note-composer__actions-row,
  &__textarea[data-dirty='true'] + .note-composer__actions-row {
    display: flex;
  }

  &__new-line-help {
    color: variables.$gray-60;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s ease-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
