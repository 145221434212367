<script setup>
import { ref } from 'vue';
import uniqueId from 'lodash/uniqueId';
import {
  collapseSection,
  expandSection,
} from 'src/composables/useDrawerToggle';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

const baseId = uniqueId();
const headerId = `soona-header-${baseId}`;
const panelId = `soona-panel-${baseId}`;

const isExpanded = ref(false);
</script>

<template>
  <div
    class="soona-expansion-panel"
    :class="{
      'soona-expansion-panel--open': isExpanded,
    }"
  >
    <h3
      class="soona-expansion-panel__header"
      :class="{
        'soona-expansion-panel__header--open': isExpanded,
      }"
    >
      <button
        :id="headerId"
        class="soona-expansion-panel__button u-button-reset"
        :class="{
          'soona-expansion-panel__button--open': isExpanded,
        }"
        :aria-controls="panelId"
        :aria-expanded="isExpanded"
        @click.prevent="
          isExpanded
            ? collapseSection($refs[panelId], () => (isExpanded = false))
            : ((isExpanded = true), expandSection($refs[panelId]))
        "
      >
        <span class="soona-expansion-panel__header-content">
          <slot name="header"></slot>
        </span>
        <SoonaIcon
          class="soona-expansion-panel__icon"
          name="chevron-down"
          aria-hidden="true"
        />
      </button>
    </h3>
    <div
      :id="panelId"
      :ref="panelId"
      :aria-labelledby="headerId"
      class="u-drawer soona-expansion-panel__panel-wrapper"
      :class="{ 'u-drawer--open': isExpanded }"
      role="region"
    >
      <div class="soona-expansion-panel__panel">
        <slot name="panel"></slot>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.soona-expansion-panel {
  border: 0.0625rem solid variables.$gray-30;
  border-radius: 0.625rem;

  &--open {
    border-color: variables.$black-default;
  }

  &__button {
    padding: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-radius: 0.625rem;

    &[aria-expanded='true'] {
      border-radius: 0.625rem 0.625rem 0 0;

      .soona-expansion-panel__icon {
        transform: rotate(180deg);
      }
    }

    &:hover {
      background-color: variables.$gray-10;
    }

    &:active {
      background-color: variables.$gray-20;
    }
  }

  &__header-content {
    width: 100%;
    text-align: left;
  }

  @media (prefers-reduced-motion: no-preference) {
    &__icon {
      transition: transform 0.25s;
    }
  }

  &__panel {
    padding: 1rem;
    border-top: 0.0625rem solid variables.$gray-30;
  }
}
</style>
