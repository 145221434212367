<script setup>
import { computed, ref } from 'vue';
import { useQueryClient } from '@tanstack/vue-query';
import { useRouter } from 'vue-router';
import { onKeyStroke } from '@vueuse/core';
import { queryKeys } from '@/queries/query-keys';
import { convertToDDMMYYHHMM12z } from '@/lib/date-formatters';
import { toCurrency } from '@/lib/currency';
import { useGetOrderAddOns } from '@/queries/orders/useGetOrderAddOns';
import { useBulkPendRejectedDigitalAssets } from '@/queries/orders/useBulkPendRejectedDigitalAssets';
import { useBulkRejectPendingDigitalAssets } from '@/queries/orders/useBulkRejectPendingDigitalAssets';
import { useEditsCollection } from '@/queries/edits_collection/useEditsCollection';
import { usePriorityError } from 'src/composables/usePriorityError';
import { useUpdateAccountsOrder } from 'src/queries/accounts-orders/useUpdateAccountsOrder';
import UploadDigitalAssetEdits from '@/components/crew/orders/UploadDigitalAssetEdits.vue';
import AutoEditsFeedback from '@/components/crew/AutoEditsFeedback.vue';
import DigitalAsset from './AddOnOrder/DigitalAsset.vue';
import PaginatorSmall from '@/components/PaginatorSmall.vue';
import ReservationNotes from '@/components/crew/orders/ReservationNotes.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaFlag from '@/components/ui_library/SoonaFlag.vue';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';
import TitleBar from '@/components/shared/TitleBar.vue';
import UnlinkedPendingEdits from '@/components/user/anytime/reservation/gallery/edits/UnlinkedPendingEdits.vue';

import {
  BlackDefault,
  FriendlyRed30,
  FriendlyRed60,
  Tangerine30,
  AvoToast60,
  AvoToast20,
  WhiteDefault,
  Gray20,
} from 'src/variables.module.scss';

const props = defineProps({
  orderId: {
    required: true,
    type: [Number, String],
  },
});

defineOptions({ inheritAttrs: false });

const orderId = computed(() => props.orderId);
const currentPage = ref(1);

const {
  data: orderAddOnsData,
  error: getOrderAddOnsError,
  isLoading: getOrderAddOnsIsLoading,
} = useGetOrderAddOns(orderId, { currentPage });
const order = computed(() => orderAddOnsData.value?.data);
const pagination = computed(() => orderAddOnsData.value?.pagination);
const accountId = computed(() => order.value?.account_id);
const { data: editsCollection } = useEditsCollection(accountId);

const queryClient = useQueryClient();

const router = useRouter();

const backToReservation = () => {
  router.push({
    name: 'crew',
    params: { reservationId: order.value.reservation_id },
  });
};

onKeyStroke('ArrowUp', e => {
  if (!e.altKey) return;

  backToReservation();
});

const bustCache = async () => {
  await queryClient.invalidateQueries({
    queryKey: queryKeys.orderAddOns(orderId),
  });
};

const {
  mutate: bulkPend,
  error: bulkPendError,
  isPending: bulkPendIsLoading,
} = useBulkPendRejectedDigitalAssets(order);
const {
  mutate: bulkReject,
  error: bulkRejectError,
  isPending: bulkRejectIsLoading,
} = useBulkRejectPendingDigitalAssets(order);
const {
  mutate: mutateAccountsOrders,
  error: updateAccountsOrdersError,
  isPending: updateAccountsOrdersIsLoading,
} = useUpdateAccountsOrder(accountId, {
  enabled: accountId.value,
});

const orderStatus = computed(() => {
  if (!order.value) return {};

  switch (order.value.status) {
    case 'confirmed':
      return {
        backgroundColor: Tangerine30,
        color: BlackDefault,
        isPaid: false,
      };
    case 'canceled':
      return {
        backgroundColor: FriendlyRed30,
        color: FriendlyRed60,
        isPaid: false,
      };
    case 'completed':
      return {
        backgroundColor: AvoToast60,
        color: AvoToast20,
        isPaid: false,
        isCompleted: true,
      };
    case 'paid':
      return {
        backgroundColor: Tangerine30,
        color: BlackDefault,
        isPaid: true,
      };
    case 'voided':
      return {
        backgroundColor: FriendlyRed30,
        color: FriendlyRed60,
        isPaid: false,
      };
    default:
      return {};
  }
});

const completeOrder = order => {
  const now = new Date();

  mutateAccountsOrders(
    { order_id: order.id, status: 'completed', end: now.toISOString() },
    { onSuccess: bustCache }
  );
};

const unCompleteOrder = order => {
  mutateAccountsOrders(
    { order_id: order.id, status: 'paid', end: null },
    { onSuccess: bustCache }
  );
};

const showFeedback = ref(false);
const showNotes = ref(false);

const confirmBulkReject = () => {
  showFeedback.value = false;
  bulkReject({}, { onSuccess: bustCache });
};

const error = usePriorityError(
  getOrderAddOnsError,
  updateAccountsOrdersError,
  bulkPendError,
  bulkRejectError
);

const stripeLink = computed(() => {
  const externalOrderId = order.value?.external_order_id;
  if (!externalOrderId) return;

  const stripeDashboard = import.meta.env.VITE_STRIPE_DASHBOARD_URL;

  if (order.value.external_order_id.startsWith('pi_')) {
    return `${stripeDashboard}/payments/${externalOrderId}`;
  } else {
    return `${stripeDashboard}/orders/${externalOrderId}`;
  }
});
</script>
<template>
  <AutoEditsFeedback
    v-if="showFeedback"
    category="auto_edits_post_production"
    subject-type="Order"
    :subject-id="orderId"
    :open="showFeedback"
    options-type="post"
    @close="() => (showFeedback = false)"
    @confirm="confirmBulkReject"
  />
  <SoonaError v-if="error">
    {{ error }}
  </SoonaError>
  <TitleBar
    v-if="order"
    :title="`add-on order #${orderId}`"
    :subtitle="convertToDDMMYYHHMM12z(order.created_at)"
    justify="left"
    :background-color="WhiteDefault"
    :border-color="WhiteDefault"
    :previous-page="backToReservation"
    :back-button-copy="`back to booking #${order.reservation_id} (opt/alt + up-arrow)`"
    override-u-container
  >
    <template #additional-info>
      <div class="header__info">
        <SoonaFlag
          class="flag"
          :background-color="orderStatus.backgroundColor"
          :text-color="orderStatus.color"
          :title="order.status"
        />
        <div class="payment-info">
          <SoonaFlag
            v-if="order.payment_provider"
            class="flag"
            :background-color="Gray20"
            :text-color="BlackDefault"
            :title="order.payment_provider"
          />
          <SoonaButton
            v-if="
              order.external_order_id && order.payment_provider === 'stripe'
            "
            copy="view on stripe"
            size="medium"
            element="a"
            variation="tertiary"
            :href="stripeLink"
            rel="noopener noreferrer"
          >
            <template #icon-right>
              <SoonaIcon
                name="arrow-up-right-from-square"
                size="small"
                style="vertical-align: middle"
              />
            </template>
          </SoonaButton>
          total: {{ toCurrency(order.amount_collected) }}
        </div>
      </div>
    </template>
  </TitleBar>

  <div v-if="order" class="order-wrapper">
    <div class="controls">
      <div class="controls__left">
        <SoonaButton
          v-if="order.add_ons.some(rf => rf.raw?.url)"
          copy="download order"
          element="a"
          :href="`/reservations/${order.reservation_id}/download_order_raw_files/${order.id}.zip`"
          size="medium"
        />
        <SoonaButton
          v-if="orderStatus.isPaid && order.has_rejected"
          :disabled="bulkPendIsLoading"
          :is-loading="bulkPendIsLoading"
          copy="make all pending"
          variation="secondary-black"
          size="medium"
          @click="bulkPend({}, { onSuccess: bustCache })"
        />
        <SoonaButton
          v-if="orderStatus.isPaid && order.has_pending"
          :disabled="bulkRejectIsLoading"
          :is-loading="bulkRejectIsLoading"
          copy="reject all"
          variation="secondary-black"
          size="medium"
          @click="() => (showFeedback = true)"
        />
      </div>
      <div class="controls__right">
        <SoonaButton
          v-if="orderStatus.isPaid"
          :disabled="updateAccountsOrdersIsLoading"
          :is-loading="updateAccountsOrdersIsLoading || getOrderAddOnsIsLoading"
          copy="complete order"
          variation="secondary-black"
          size="medium"
          @click="completeOrder(order)"
        />
        <SoonaButton
          v-else-if="orderStatus.isCompleted"
          :disabled="updateAccountsOrdersIsLoading"
          :is-loading="updateAccountsOrdersIsLoading || getOrderAddOnsIsLoading"
          copy="uncomplete order"
          variation="secondary-black"
          size="medium"
          @click="unCompleteOrder(order)"
        />
        <UploadDigitalAssetEdits
          v-if="orderStatus.isPaid"
          :account-id="order.account_id"
          :order-id="orderId"
          :reservation-id="order.reservation_id"
        />
      </div>
    </div>
    <UnlinkedPendingEdits
      v-if="editsCollection?.id"
      :account-id="accountId"
      :collection-id="editsCollection?.id"
      :reservation-id="order.reservation_id"
    />
    <div class="line-items">
      <template v-for="addOn in order.add_ons" :key="addOn.id">
        <DigitalAsset
          :add-on="addOn"
          :order-id="orderId"
          :enable-review="orderStatus.isPaid"
        />
      </template>
    </div>
    <PaginatorSmall
      class="competitor-picker__paginator"
      :page="pagination.currentPage"
      :pages="pagination.totalPages"
      @page-change="currentPage = $event"
    />
    <div v-if="showNotes">
      <SoonaButton
        copy="hide reservation notes"
        variation="secondary-black"
        size="medium"
        @on-click="showNotes = false"
      />
      <ReservationNotes
        :reservation-id="order.reservation_id"
        @close="showNotes = false"
      />
    </div>
    <div v-else>
      <SoonaButton
        class="notes-button"
        copy="show reservation notes"
        variation="secondary-black"
        size="medium"
        @on-click="showNotes = true"
      />
    </div>
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.header {
  margin: 1rem 0.25rem;

  &__title {
    @include variables_fonts.u-headline--heavy;

    display: flex;
    flex-direction: row;
  }
  &__subtitle {
    @include variables_fonts.u-subheader--regular;
  }

  &__info {
    display: flex;
    gap: 0.5rem;
    .payment-info {
      flex: 1 1 auto;
      display: flex;
      gap: 0.5rem;
      justify-content: flex-end;
      .flag {
        display: inline-block;
      }
    }
  }
}

.controls {
  display: flex;
  flex-flow: row wrap;
  gap: 0.5rem;
  align-items: left;
  justify-content: space-between;
  margin: 1rem 0.25rem;

  &__left,
  &__right {
    display: flex;
    flex-flow: row wrap;
    gap: 0.5rem;
  }
}

.section-title {
  @include variables_fonts.u-label--heavy;
}

.order-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.navigation-link {
  &__icon {
    vertical-align: middle;
  }
}
</style>
