<script setup>
import { computed, ref, toRefs } from 'vue';
import InfiniteGallery from '@/components/infinite_asset_gallery/InfiniteGallery.vue';
import DigitalAssetCard from '@/components/account_gallery/DigitalAssetCard.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import { usePriorityErrors } from '@/composables/usePriorityErrors';
import { useInfiniteDigitalAssets } from '@/composables/useInfiniteDigitalAssets';
import { useInfiniteGalleryWrapper } from '@/components/infinite_asset_gallery/useInfiniteGalleryWrapper';
import { refDebounced, useElementBounding } from '@vueuse/core';
import SortBy from '@/components/account_gallery/SortBy.vue';
import { useBulkSelection } from '@/composables/digital_assets/useBulkSelection';
import MultiSelectActionBar from '@/components/account_gallery/MultiSelectActionBar.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import { useGenerateEmbeddings } from '@/queries/digital_assets/useEmbeddings';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
  incompleteTraitsExist: {
    type: Boolean,
    default: false,
  },
});

const { accountId } = toRefs(props);

const wrapper = ref(null);

const { left, width } = useElementBounding(wrapper);

const { gutter, offsetTop, rowHeight, rowWidth } = useInfiniteGalleryWrapper({
  wrapperEl: wrapper,
  heightRem: 14,
  gapRem: 0.75,
});

const itemsPerPage = ref(25);
const searchText = ref('');
const searchDebounced = refDebounced(searchText, 200);
const similarityText = ref('');
const similarityTextDebounced = refDebounced(similarityText, 500);
const similarityThreshold = ref(0.2);
const selectedSort = ref('date modified');
const sortDirection = ref('desc');
const startPage = ref(1);

const filters = computed(() => ({
  origin: ['customer', 'soona'],
  ownership: 'customer',
  visibility: 'all',
}));

const { assetRows, fetchPage, pagination, error, rawData, isFetching } =
  useInfiniteDigitalAssets(accountId, {
    rowWidth,
    itemsPerPage,
    height: rowHeight,
    gutter,
    filters,
    searchQuery: searchDebounced,
    similarityText: similarityTextDebounced,
    similarityThreshold,
    sortBy: selectedSort,
    sortDirection: sortDirection,
  });

const changeSortDirection = () => {
  if (sortDirection.value === 'desc') sortDirection.value = 'asc';
  else sortDirection.value = 'desc';
};

const { onSelectionClick, resetSelection, isAssetSelected, selectedAssets } =
  useBulkSelection(rawData, da => da);

const { mutate: generateEmbeddings } = useGenerateEmbeddings(accountId);

const priorityErrors = usePriorityErrors(error);
</script>

<template>
  <div class="ai-search-test-gallery">
    <div class="ai-search-test-gallery__filters">
      <SoonaTextfield
        v-model="searchText"
        type="search"
        label="classic search"
        name="classic-search"
        placeholder="no AI functionality here"
      >
        <template #icon-left>
          <SoonaIcon name="search" size="small" />
        </template>
      </SoonaTextfield>
      <SoonaTextfield
        v-model="similarityText"
        type="search"
        label="embeddings search"
        name="embeddings search"
        placeholder="fancy new AI embeddings search"
      >
        <template #icon-left>
          <SoonaIcon name="search" size="small" />
        </template>
      </SoonaTextfield>
      <div class="ai-search-test-gallery__filters--section">
        <div class="ai-search-test-gallery__filters--similarity-threshold">
          <label for="similarityThreshold">similarity threshold</label>
          <input
            id="similarityThreshold"
            v-model="similarityThreshold"
            type="number"
            step="0.01"
            min="0"
            max="1"
          />
        </div>
        <SoonaButton
          variation="secondary-black"
          size="medium"
          @click="generateEmbeddings"
        >
          generate embeddings
        </SoonaButton>
      </div>
    </div>
    <div class="ai-search-test-gallery__sort-wrapper">
      <p class="ai-search-test-gallery__items">
        {{ isFetching ? 'fetching' : pagination.totalCount.toLocaleString() }}
        items
      </p>
      <SortBy
        v-model:selected-sort="selectedSort"
        :sort-direction="sortDirection"
        @change-sort-direction="changeSortDirection"
      />
    </div>
    <SoonaError v-if="priorityErrors" :priority-errors="priorityErrors" />
    <section ref="wrapper">
      <InfiniteGallery
        v-slot="{ data }"
        :rows="assetRows"
        :start-page="startPage"
        :height="rowHeight"
        :gap="gutter"
        :offset-top="offsetTop"
      >
        <DigitalAssetCard
          v-for="asset in data.assets"
          :key="asset.id"
          :asset="asset"
          :flex-grow="data.width / rowWidth > 0.6 ? 1 : 0"
          :selected="isAssetSelected(asset)"
          :to="{
            name: 'all-assets-media-view', // to do - create ai search media view
            params: {
              accountId: asset.account_id,
              digitalAssetId: asset.id,
            },
          }"
          @request-page="pageNumber => fetchPage(pageNumber)"
          @selection-click="onSelectionClick(asset, $event)"
        />
      </InfiniteGallery>
      <MultiSelectActionBar
        :account-id="accountId"
        :page-bounding-rect-left="left"
        :page-bounding-rect-width="width"
        :selected-assets="selectedAssets"
        @close="resetSelection"
      />
    </section>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.ai-search-test-gallery {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  &__filters {
    margin-bottom: 3rem;

    &--similarity-threshold {
      display: flex;
      flex-direction: column;
    }

    &--section {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__sort-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
  }

  &__items {
    color: variables.$gray-60;
  }
}
</style>
