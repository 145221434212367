<script setup>
import { computed, ref, watchEffect, watch } from 'vue';
import CommunicationForm from './CommunicationForm.vue';
import ModelProductSelector from './ModelProductSelector.vue';
import PaymentSelector from './PaymentSelector.vue';
import PhotoProductSelector from './PhotoProductSelector.vue';
import ProServiceProductSelector from './ProServiceProductSelector.vue';
import ShootOptionsSelector from './ShootOptionsSelector.vue';
import StopMotionAndGifProductSelector from './StopMotionAndGifProductSelector.vue';
import VideoProductSelector from './VideoProductSelector.vue';
import BonusContentSelector from './BonusContentSelector.vue';
import CustomerDetails from './CustomerDetails.vue';
import { useReservation } from 'src/queries/useReservation';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaLoading from 'src/components/SoonaLoading.vue';
import { useStore } from 'vuex';
import { useFlag } from 'src/composables/useFlag';
import { useQueryClient } from '@tanstack/vue-query';
import { useRouter } from 'vue-router';
import { queryKeys } from '@/queries/query-keys';

//props
const props = defineProps({
  reservationId: {
    type: String,
    default: null,
    required: true,
  },
  discountObj: {
    type: Object,
    required: false,
    default: null,
  },
});
const reservationId = computed(() => props.reservationId);
const discountObj = computed(() => props.discountObj);
const store = useStore();
const router = useRouter();
const queryClient = useQueryClient();

//vue query section
const { data: reservation } = useReservation(reservationId);

//products
const productsInCart = computed(
  () => store.getters['reservation/productsInCart']
);

function onSelectProduct(product) {
  store.dispatch('reservation/setAdditionalChargeItems', {
    [product.value]: 1,
  });
}

function onDeselectProduct(product) {
  store.dispatch('reservation/removeAdditionalChargeItem', product.value);
}

//computed ids
const accountId = computed(() => {
  return reservation.value ? reservation.value.account.id : null;
});
const locationId = computed(() => {
  return reservation.value ? reservation.value.location.id : null;
});

//email section
const email = ref({
  address: '',
  subject: 'thanks for your order!',
  notes: '',
});

watch(
  () => email.value.notes,
  () => {
    store.dispatch('reservation/setAdditionalChargeMemo', email.value.notes);
  }
);

const errorMessage = ref('');

watchEffect(() => {
  email.value.address = reservation.value?.account.email;
});

//payment section
const paymentsPaymentMethodsFlag = useFlag('payments_payment_methods');
const selectedPaymentType = ref('');
const orderProcessing = ref(false);

const defaultPayment = computed(() => {
  if (paymentsPaymentMethodsFlag) {
    const payment = store.getters['account/defaultPaymentMethod'];

    if (payment?.payment_method_type === 'card') {
      return payment;
    }
  } else {
    return store.state.payment.defaultPaymentInfo;
  }
  return null;
});

const chargeDisabled = computed(() => {
  return (
    email.value.notes === '' ||
    email.value.address === '' ||
    email.value.subject === '' ||
    productsInCart.value.length <= 0 ||
    selectedPaymentType.value === ''
  );
});

const paymentOptions = computed(() => {
  const options = [
    {
      value: 'send_invoice',
      label: 'send invoice',
      description: ['customer must complete invoice for credits to be active'],
    },
  ];
  if (defaultPayment.value && paymentsPaymentMethodsFlag) {
    options.unshift({
      value: 'card_on_file',
      label: 'charge card on file',
      subTitle:
        (defaultPayment.value?.payment_method_data?.brand ?? 'card brand') +
        ' ending in ' +
        (defaultPayment.value?.payment_method_data?.last4 ?? 'xxxx'),
      description: [],
      disabled: !defaultPayment.value,
    });
  } else if (defaultPayment.value) {
    options.unshift({
      value: 'card_on_file',
      label: 'charge card on file',
      subTitle:
        (defaultPayment.value?.payment_method_data?.brand ?? 'card brand') +
        ' ending in ' +
        (defaultPayment.value?.payment_method_data?.last4 ?? 'xxxx'),
      description: [],
      disabled: !defaultPayment.value,
    });
  }

  return options;
});

function onSelectPayment(option) {
  selectedPaymentType.value = option;
  if (selectedPaymentType.value === 'card_on_file') {
    store.dispatch('reservation/setAdditionalChargeImmediately', true);
    email.value.subject = 'thanks for your order!';
  } else {
    store.dispatch('reservation/setAdditionalChargeImmediately', false);
    email.value.subject =
      'order invoice - please complete and we’ll get started!';
  }
}

async function createOrder() {
  orderProcessing.value = true;

  if (discountObj.value?.external_discount_id) {
    await store.dispatch(
      'reservation/setAdditionalChargeCoupon',
      discountObj.value?.external_discount_id
    );
  }

  store
    .dispatch('reservation/createAdditionalChargeOrder', {
      stripe: defaultPayment.value?.external_payment_method_id,
    })
    .then(async ([, error]) => {
      if (error) {
        orderProcessing.value = false;
        errorMessage.value =
          error.status + ' ' + error.config.url + ' ' + error.data.message;
      } else {
        await queryClient.invalidateQueries({
          queryKey: queryKeys.accountCreditInfo(accountId.value),
        });
        orderProcessing.value = false;
        store.dispatch('reservation/clearAdditionalChargeItems');
        await router.push(`/reservation/${reservationId.value}/crew`);
      }
    });
}

watchEffect(() => {
  if (accountId.value !== null) {
    store.dispatch('payment/fetchDefaultPayment', accountId.value);
  }
});
</script>
<template>
  <div class="order-builder">
    <SoonaLoading
      :is-loading="orderProcessing"
      :is-dark="false"
      :loading-text="'order processing'"
    />
    <section class="order-builder__padding order-builder__selector-section">
      <h3 class="order-builder__selector-section__heading">customer</h3>
      <div class="order-builder__selector-section__selector">
        <CustomerDetails :account-id="accountId" />
      </div>
    </section>
    <section class="order-builder__padding order-builder__selector-section">
      <h3 class="order-builder__selector-section__heading">shoot options</h3>
      <div class="order-builder__selector-section__selector">
        <ShootOptionsSelector :location-id="locationId" :editable="false" />
      </div>
    </section>
    <section class="order-builder__padding order-builder__selector-section">
      <h3 class="order-builder__selector-section__heading">photo</h3>
      <div class="order-builder__selector-section__selector">
        <PhotoProductSelector
          @on-select-product="onSelectProduct"
          @on-deselect-product="onDeselectProduct"
        />
      </div>
    </section>
    <section class="order-builder__padding order-builder__selector-section">
      <h3 class="order-builder__selector-section__heading">video</h3>
      <div class="order-builder__selector-section__selector">
        <VideoProductSelector
          @on-select-product="onSelectProduct"
          @on-deselect-product="onDeselectProduct"
        />
      </div>
    </section>
    <section class="order-builder__padding order-builder__selector-section">
      <h3 class="order-builder__selector-section__heading">bonus content</h3>
      <div class="order-builder__selector-section__selector">
        <BonusContentSelector
          @on-select-product="onSelectProduct"
          @on-deselect-product="onDeselectProduct"
        />
      </div>
    </section>
    <section class="order-builder__padding order-builder__selector-section">
      <h3 class="order-builder__selector-section__heading">
        stop motion & gifs
      </h3>
      <div class="order-builder__selector-section__selector">
        <StopMotionAndGifProductSelector
          @on-select-product="onSelectProduct"
          @on-deselect-product="onDeselectProduct"
        />
      </div>
    </section>
    <section class="order-builder__padding order-builder__selector-section">
      <h3 class="order-builder__selector-section__heading">models</h3>
      <div class="order-builder__selector-section__selector">
        <ModelProductSelector
          :reservation="reservation"
          @on-select-product="onSelectProduct"
          @on-deselect-product="onDeselectProduct"
        />
      </div>
    </section>
    <section class="order-builder__padding order-builder__selector-section">
      <h3 class="order-builder__selector-section__heading">pro services</h3>
      <div class="order-builder__selector-section__selector">
        <ProServiceProductSelector
          :reservation="reservation"
          @on-select-product="onSelectProduct"
          @on-deselect-product="onDeselectProduct"
        />
      </div>
    </section>
    <section class="order-builder__padding order-builder__selector-section">
      <h3 class="order-builder__selector-section__heading">payment</h3>
      <div class="order-builder__selector-section__selector">
        <PaymentSelector
          :payment-options="paymentOptions"
          @select-payment="onSelectPayment"
        />
      </div>
    </section>
    <section class="order-builder__padding order-builder__selector-section">
      <h3 class="order-builder__selector-section__heading">communication</h3>
      <div class="order-builder__selector-section__selector">
        <CommunicationForm
          v-model:email="email.address"
          v-model:subject-line="email.subject"
          v-model:notes="email.notes"
          :editable="false"
        />
      </div>
    </section>
    <section
      class="order-builder__padding order-builder__selector-section__selector order-builder__charge-button-section"
    >
      <p v-if="errorMessage" class="soona-flash soona-error">
        there was a problem placing this order:
        <b>{{ errorMessage }}</b>
      </p>
      <SoonaButton
        :disabled="chargeDisabled"
        class="order-builder__charge-button-section__charge-button"
        copy="charge order"
        data-cypress="button-charge-additional-order"
        @click="createOrder"
      />
    </section>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.order-builder {
  margin: 1rem auto 0 auto;

  > * {
    max-width: 34.875rem;
    margin-left: auto;
    margin-right: auto;
  }

  &__padding {
    padding: 0 1rem;
  }

  &__selector-section {
    border-bottom: 0.0625rem solid variables.$gray-30;

    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }

    &__heading {
      @include variables_fonts.u-title--heavy;
      color: variables.$black-default;
      margin-bottom: 1.5rem;
    }

    &__selector {
      margin-bottom: 1.5rem;
    }
  }

  &__charge-button-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    &__charge-button {
      width: 100%;
    }
  }
}
</style>
