<script setup>
import { ref } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

defineProps({
  showLabelText: {
    type: Boolean,
    default: true,
  },
});

const showHelpDialog = ref(false);

const toggleShowHelpDialog = value => {
  showHelpDialog.value = value;
};

const scoreCategories = [
  {
    icon: 'chart-simple',
    header: 'soona score',
    body: 'the soona score score aggregates all your scores and ranks how well your visuals are performing in selling and promoting your product.',
  },
  {
    icon: 'aperture',
    header: 'image quality',
    body: 'the image quality score ranks the technical performance of your visuals.',
  },
  {
    icon: 'album',
    header: 'gallery count',
    body: 'the gallery count score ranks how your visuals stack up based on the best practices of your selling platform.',
  },
  {
    icon: 'stars',
    header: 'visual mix',
    body: 'the visual mix score analyzes the curation of your assets as compared to your selling platform’s best practices and identifies opportunities.',
  },
];
</script>

<template>
  <SoonaButton variation="tertiary" @click="toggleShowHelpDialog(true)">
    <template #icon-left>
      <SoonaIcon
        name="circle-question"
        size="small"
        style="vertical-align: middle"
      />
    </template>
    <span
      class="u-label--regular"
      :class="{ 'u-visually-hidden': !showLabelText }"
      >how scores work</span
    >
  </SoonaButton>
  <SoonaDialog
    v-if="showHelpDialog"
    class="listing-insights-help-dialog"
    @close="toggleShowHelpDialog(false)"
  >
    <template #heading>how scores work</template>
    <template #default>
      <div
        v-for="score in scoreCategories"
        :key="score.header"
        class="listing-insights-help-dialog__section"
      >
        <div class="listing-insights-help-dialog__section--header">
          <SoonaIcon
            :name="score.icon"
            size="small"
            style="vertical-align: middle"
          />
          <h3 class="u-body--heavy">
            {{ score.header }}
          </h3>
        </div>

        <p class="u-body--regular">
          {{ score.body }}
        </p>
      </div>
    </template>
    <template #footer="{ close }">
      <SoonaButton type="button" size="medium" @click="close">
        got it!
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>

<style lang="scss" scoped>
@use '@/variables_fonts';

.listing-insights-help-dialog {
  &__section {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    &--header {
      align-items: center;
      display: flex;
      gap: 0.25rem;
    }
  }
}
</style>
