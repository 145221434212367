<script setup>
import { computed, toRefs } from 'vue';
import { useRoute } from 'vue-router';

import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useCapability } from '@/composables/useCapability';
import SoonaDropdownMenu from '@/components/ui_library/SoonaDropdownMenu.vue';
import DownloadUploadedOrCreated from '@/components/user/anytime/gallery/media-editor/download/DownloadUploadedOrCreated.vue';
import DownloadCreatedWithPaywall from '@/components/user/anytime/gallery/media-editor/download/DownloadCreatedWithPaywall.vue';
import DownloadDeliveredAsset from '@/components/user/anytime/gallery/media-editor/download/DownloadDeliveredAsset.vue';
import SaveEdit from '@/components/user/anytime/gallery/media-editor/download/SaveEdit.vue';
import { useMediaEditor } from '@/components/user/anytime/gallery/media-editor/useMediaEditor';
import CrewDownloadUnpurchased from '@/components/user/anytime/gallery/media-editor/download/CrewDownloadUnpurchased.vue';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
  activeAction: {
    type: Symbol,
    default: undefined,
  },
  asset: {
    type: Object,
    default: undefined,
  },
  currentlyEditing: {
    type: Boolean,
    default: false,
  },
  subContext: {
    type: String,
    default: 'media editor header',
  },
});

const emits = defineEmits([
  'save-low-res-canvas',
  'show-paywall-dialog',
  'saveToGallery',
]);

// refs
const { accountId, activeAction, asset, currentlyEditing, subContext } =
  toRefs(props);
const buttonCopy = computed(() =>
  currentlyEditing.value ? 'save' : 'download'
);
const createdInEditor = computed(() => asset.value?.asset_type === 'platform');
const userUploaded = computed(() => asset.value?.user_uploaded);
const assetOwnedByCustomer = computed(
  () => asset.value?.ownership === 'customer'
);

// use
const { linkClicked } = useBaseEvents();
const route = useRoute();

const { isRemoveBackgroundActive } = useMediaEditor({ activeAction });

// capabilities
const { hasCapability: canAdjustBackgroundOnOwnAccount } = useCapability({
  capability: 'media_editor_adjust_bg_on_own_account',
  subjectType: 'account',
  subjectId: accountId,
});
const { hasCapability: staffCanAdjustBackgroundOnClientAccount } =
  useCapability({
    capability: 'media_editor_adjust_bg_on_subscription_accounts',
  });

const hasSubscriptionDownloadAccess = computed(() => {
  return (
    canAdjustBackgroundOnOwnAccount?.value ||
    staffCanAdjustBackgroundOnClientAccount?.value
  );
});

//click handlers
const downloadFile = ({ url, label }) => {
  linkClicked({
    context: route.meta.context,
    subContext: subContext,
    linkLabel: label,
    linkHref: null,
  });
  window.location.href = url;
};

const clickSoonaDropDownMenu = () => {
  linkClicked({
    context: route.meta.context,
    subContext: subContext,
    linkLabel: buttonCopy.value,
    linkHref: null,
  });
};

const handleSaveToGallery = () => {
  linkClicked({
    context: route.meta.context,
    subContext: subContext,
    linkLabel: 'save to gallery',
    linkHref: null,
  });

  emits('saveToGallery');
};

const handleSaveLowResPreview = () => {
  linkClicked({
    context: route.meta.context,
    subContext: subContext,
    linkLabel: 'save low res preview',
    linkHref: null,
  });

  if (isRemoveBackgroundActive.value) {
    window.location.href = asset.value?.foreground?.url;
  } else {
    emits('save-low-res-canvas');
  }
};

const showSave = computed(() => {
  return currentlyEditing.value && asset.value?.ownership === 'customer';
});
</script>

<template>
  <SoonaDropdownMenu
    :copy="buttonCopy"
    class="download-digital-asset-menu"
    @button-action="clickSoonaDropDownMenu"
  >
    <template #default="{ clickCapture, keydown, mouseover }">
      <SaveEdit
        v-if="showSave"
        :asset="asset"
        :click-capture="clickCapture"
        :keydown="keydown"
        :mouseover="mouseover"
        :save-gallery-access="hasSubscriptionDownloadAccess"
        @show-paywall-dialog="emits('show-paywall-dialog')"
        @save-to-gallery="handleSaveToGallery"
        @save-low-res-preview="handleSaveLowResPreview"
      />
      <DownloadUploadedOrCreated
        v-else-if="
          userUploaded || (createdInEditor && hasSubscriptionDownloadAccess)
        "
        :asset="asset"
        :click-capture="clickCapture"
        :keydown="keydown"
        :mouseover="mouseover"
        @click="downloadFile($event)"
      />
      <DownloadCreatedWithPaywall
        v-else-if="createdInEditor && !hasSubscriptionDownloadAccess"
        :asset="asset"
        :click-capture="clickCapture"
        :keydown="keydown"
        :mouseover="mouseover"
        @show-paywall-dialog="emits('show-paywall-dialog')"
        @click="downloadFile($event)"
      />
      <DownloadDeliveredAsset
        v-else-if="assetOwnedByCustomer"
        :asset="asset"
        :click-capture="clickCapture"
        :keydown="keydown"
        :mouseover="mouseover"
        @show-paywall-dialog="emits('show-paywall-dialog')"
        @click="downloadFile($event)"
      />
      <CrewDownloadUnpurchased
        v-else-if="staffCanAdjustBackgroundOnClientAccount"
        :asset="asset"
        :click-capture="clickCapture"
        :keydown="keydown"
        :mouseover="mouseover"
        @click="downloadFile($event)"
      />
    </template>
  </SoonaDropdownMenu>
</template>

<style lang="scss" scoped>
.download-digital-asset-menu {
  :deep(> [role='menu']) {
    padding-top: 0;
    padding-bottom: 0;

    > li:first-child > .download-option {
      border-radius: 0.3125rem 0.3125rem 0 0;
    }

    > li:last-child > .download-option {
      border-radius: 0 0 0.3125rem 0.3125rem;
    }
  }
}
</style>
