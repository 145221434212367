<script setup>
import { computed, inject, toRefs, toValue } from 'vue';
import { useRoute } from 'vue-router';
import { useMediaQuery } from '@vueuse/core';

import { useCapability } from '@/composables/useCapability';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import {
  MEDIA_POPOVER_BACK_KEY,
  MEDIA_POPOVER_BACK_TEXT,
} from '@/components/user/anytime/gallery/media-editor-routing-keys';
import { useSoonaToast } from '@/components/ui_library/soona_toast/useSoonaToast';
import { useReEditsCollections } from '@/queries/re_edits_collections/useReEditsCollections';
import { useMe } from '@/composables/user/useMe';
import { useReservationDigitalAsset } from '@/queries/reservation_digital_assets/useReservationDigitalAsset';

import MediaHeaderBase from './MediaHeaderBase.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import DeleteDigitalAsset from '@/components/user/anytime/gallery/media-editor/DeleteDigitalAsset.vue';
import DownloadDigitalAsset from '@/components/user/anytime/gallery/media-editor/download/DownloadDigitalAsset.vue';
import IntegrationsPublishButton from '@/components/user/anytime/products/IntegrationsPublishButton.vue';
import FavoriteDigitalAsset from '@/components/user/anytime/gallery/media-editor/FavoriteDigitalAsset.vue';
import ExpertPickDigitalAsset from '@/components/user/anytime/gallery/media-editor/ExpertPickDigitalAsset.vue';
import AddToBagDigitalAsset from '@/components/user/anytime/gallery/media-editor/AddToBagDigitalAsset.vue';
import QuestHelper from '@/components/quest/QuestHelper.vue';
import { toCurrency } from '@/lib/currency';
import SoonaHelperText from '@/components/ui_library/SoonaHelperText.vue';
import ReEditAlert from '@/components/user/anytime/ReEditAlert.vue';
import ReEditDigitalAsset from '@/components/user/anytime/ReEditDigitalAsset.vue';
import FileContentLabel from '@/components/user/anytime/gallery/FileContentLabel.vue';
import { GreenApple20 } from '@/variables.module.scss';
import AvailableMokkerCredits from '@/components/user/anytime/gallery/media-editor/AvailableMokkerCredits.vue';

const props = defineProps({
  asset: {
    type: Object,
    default: undefined,
  },
  isAiIframeOpen: {
    type: Boolean,
    default: false,
  },
  activeAction: {
    type: Symbol,
    default: undefined,
  },
  currentlyEditing: {
    type: Boolean,
    default: false,
  },
  isWide: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits([
  'close',
  'show-paywall-dialog',
  'save-to-gallery',
  'save-low-res-canvas',
]);

const { isWide, asset, activeAction, isAiIframeOpen, currentlyEditing } =
  toRefs(props);

const assetId = computed(() => asset.value?.id);
const isIndependentRawAsset = computed(
  () => !asset.value?.preview?.url && asset.value?.raw?.url
);
const assetAccountId = computed(() => asset.value?.account_id);
const rdaId = computed(() => asset.value?.reservation_digital_asset?.id);
const reservationId = computed(
  () => asset.value?.reservation_digital_asset?.reservation_id
);

const { currentAccountId } = useMe();
const { addToast } = useSoonaToast();

const { linkClicked } = useBaseEvents();
const route = useRoute();
const isWideScreen = useMediaQuery('(min-width: 48rem)');

const backUrl = inject(MEDIA_POPOVER_BACK_KEY);
const backText = inject(MEDIA_POPOVER_BACK_TEXT);

const backUrlCopy = computed(() => toValue(backText) || 'back');

const { hasCapability: isFtSoonaStaff } = useCapability({
  capability: 'ft_soona_staff',
});

const { hasCapability: isSoonaStaff } = useCapability({
  capability: 'soona_staff',
});

const assetBelongsCurrentAccount = computed(
  () => assetAccountId.value === currentAccountId.value
);

const isDeletable = computed(() => {
  const originatedByCustomer = asset.value?.origin === 'customer';

  const isSoonaCreatedInMediaEditor =
    asset.value?.origin === 'soona' && asset.value?.asset_type === 'platform';

  const AIStudioCreation = asset.value?.origin_source === 'mokker';

  const staffOrAccountMember =
    isFtSoonaStaff.value || currentAccountId.value === assetAccountId.value;

  return (
    (originatedByCustomer || isSoonaCreatedInMediaEditor || AIStudioCreation) &&
    staffOrAccountMember &&
    !isAiIframeOpen.value
  );
});

//rda
const { data: reservationDigitalAsset, isSuccess: rdaSuccess } =
  useReservationDigitalAsset(
    {
      reservationId: reservationId,
      rdaId: rdaId,
    },
    {
      enabled: computed(() => !!rdaId.value && !!reservationId.value),
    }
  );

// re-edits
const { data: reEditsDraftRequests, isSuccess: reEditsCollectionSuccess } =
  useReEditsCollections(currentAccountId, ['draft'], {
    enabled: computed(() => !!currentAccountId.value),
  });

const unsubmittedReEdits = computed(() => {
  return reEditsDraftRequests.value?.re_edits_collections || [];
});

const isInEditOrReEditCollection = computed(() => {
  return (
    reservationDigitalAsset.value?.digital_asset?.edits_collection_digital_asset
      ?.id ||
    reservationDigitalAsset.value?.digital_asset
      ?.re_edits_collection_digital_asset?.id
  );
});

const enableReEdits = computed(
  () =>
    rdaSuccess.value &&
    isInEditOrReEditCollection.value &&
    !!reservationDigitalAsset.value?.reservation_id &&
    reservationDigitalAsset.value.content_category !== 'behind_the_scenes' &&
    asset.value?.asset_type === 'purchased' &&
    !currentlyEditing.value &&
    (currentAccountId.value === assetAccountId.value || isSoonaStaff.value)
);

// publish
const addOnProduct = computed(
  () => reservationDigitalAsset.value?.add_on_product
);

const contentCategory = computed(() => {
  if (
    !addOnProduct.value?.content_category ||
    reservationDigitalAsset.value?.content_category === 'default'
  )
    return null;
  return reservationDigitalAsset.value?.content_category?.replace(/_/g, ' ');
});

const isBTS = computed(() => {
  return contentCategory.value === 'behind the scenes';
});

// publish
const ownedByCustomer = computed(() => asset.value?.ownership === 'customer');
const showPublish = computed(() => {
  const urlExist = asset.value?.media?.url || asset.value?.raw?.url;

  return (
    asset.value?.media_type === 'photo' &&
    ownedByCustomer.value &&
    urlExist &&
    !currentlyEditing.value &&
    asset.value?.account_id === currentAccountId.value &&
    !isAiIframeOpen.value
  );
});

const trackBackClick = () => {
  if (backUrl.value) {
    linkClicked({
      context: route.meta.context,
      subContext: 'media editor header',
      linklabel: 'back',
      linkHref: backUrl.value,
    });
  }
};
</script>

<template>
  <MediaHeaderBase :is-wide="isWide">
    <slot />
    <template #details-top>
      <DeleteDigitalAsset
        v-if="isDeletable"
        :account-id="assetAccountId"
        :asset-id="assetId"
        @file-deleted="() => emits('close')"
      />
      <FavoriteDigitalAsset
        v-if="asset && asset.reservation_digital_asset?.id"
        :asset="asset"
        class="header__favorite"
      />
      <ExpertPickDigitalAsset
        v-if="asset && asset.reservation_digital_asset?.id"
        :asset="asset"
        is-static
      />
    </template>
    <template #details-bottom>
      <SoonaButton
        size="medium"
        variation="tertiary"
        element="router-link"
        :to="backUrl"
        @on-click="trackBackClick"
      >
        <template #copy> {{ backUrlCopy }} </template>
      </SoonaButton>
      <FileContentLabel
        v-if="contentCategory"
        :title="contentCategory"
        emoji="🌟"
        :color="GreenApple20"
      />
    </template>
    <template
      v-if="
        rdaSuccess && addOnProduct && !ownedByCustomer && !isIndependentRawAsset
      "
      #price
    >
      {{ toCurrency(+addOnProduct?.rate, 'USD', 0) }}
    </template>
    <template
      v-if="
        rdaSuccess && addOnProduct && !ownedByCustomer && !isIndependentRawAsset
      "
      #price-addendum
    >
      or 1
      <template v-if="isBTS">BTS</template>
      <template v-else>{{ asset?.media_type }}</template>
      credit
      <SoonaHelperText title="applicable credits">
        applicable credits applied at checkout
      </SoonaHelperText>
    </template>
    <template #actions>
      <ReEditDigitalAsset
        v-if="enableReEdits"
        :key="{ id: assetId, note: asset?.notes }"
        :asset="asset"
        :reservation-digital-asset="reservationDigitalAsset"
        class="digital-asset__reedit"
      />
      <IntegrationsPublishButton
        v-if="showPublish"
        :file="asset"
        @asset-published="payload => addToast(payload.message, payload)"
      />
      <QuestHelper
        :hide="
          !currentlyEditing || asset?.ownership !== 'customer' || isAiIframeOpen
        "
        quest-task-slug="media_editor"
      >
        <DownloadDigitalAsset
          v-if="
            (asset?.ownership === 'customer' || isFtSoonaStaff) &&
            !isAiIframeOpen
          "
          :asset="asset"
          :active-action="activeAction"
          :account-id="currentAccountId"
          :currently-editing="currentlyEditing"
          @save-low-res-canvas="() => emits('save-low-res-canvas')"
          @save-to-gallery="() => emits('save-to-gallery')"
          @show-paywall-dialog="() => emits('show-paywall-dialog')"
        />
      </QuestHelper>
      <AddToBagDigitalAsset
        v-if="
          assetBelongsCurrentAccount &&
          asset &&
          asset.reservation_digital_asset?.id &&
          asset.ownership === 'soona' &&
          !isIndependentRawAsset
        "
        :asset="asset"
        :add-on-product="addOnProduct"
      />
      <AvailableMokkerCredits
        v-if="assetBelongsCurrentAccount && asset && isAiIframeOpen"
        :account-id="assetAccountId"
        @show-paywall-dialog="() => emits('show-paywall-dialog')"
      />
    </template>
    <template
      v-if="reEditsCollectionSuccess && unsubmittedReEdits.length > 0"
      #warning
    >
      <ReEditAlert
        :unsubmitted-re-edits="unsubmittedReEdits"
        :font-size="isWideScreen ? 'normal' : 'small'"
      />
    </template>
  </MediaHeaderBase>
</template>

<style lang="scss" scoped>
@use 'src/variables';

.header {
  &__favorite {
    position: relative;
    right: 0;
    flex: 0 0 2rem;
    height: 2rem;
    width: 2rem;
  }

  .digital-asset {
    &__reedit {
      display: flex;
      align-items: center;
    }
  }
}
</style>
