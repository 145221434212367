<script setup>
import { computed, watch, ref, provide, toRefs } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import {
  onKeyStroke,
  useMediaQuery,
  useTimeoutFn,
  useTitle,
} from '@vueuse/core';
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import MediaHeaderBase from '@/components/user/anytime/gallery/media-editor/action-bars/MediaHeaderBase.vue';
import MediaAsset from 'src/components/user/anytime/gallery/media-editor/MediaAsset.vue';
import { TELEPORT_TO } from '@/composables/useTeleportTo';
import { ROUTE_MAP } from '@/components/user/anytime/gallery/media-editor/mediaEditorActionKeys';
import { truncate } from '@/lib/text';
import { useDialogContext } from '@/composables/useDialog';
import uniqueId from 'lodash/uniqueId';
import DownloadDigitalAsset from '@/components/user/anytime/gallery/media-editor/download/DownloadDigitalAsset.vue';
import { useMe } from '@/composables/user/useMe';
import ActionBarBase from '@/components/user/anytime/gallery/media-editor/action-bars/ActionBarBase.vue';
import HideAssetButton from '@/components/user/anytime/gallery/media-editor/action-bars/staff-actions-bar/HideAssetButton.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import { useReservation } from '@/composables/useReservation';

const props = defineProps({
  context: {
    type: String,
    default: 'dam',
  },
  file: {
    type: Object,
    default: undefined,
  },
  reservationId: {
    type: [String, Number],
    default: undefined,
  },
  isFileLoading: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(['close', 'approve-image']);

const popoverRef = ref();
const id = uniqueId('media-editor-');

const { checkAllowEscapeClose } = useDialogContext({ id });

provide(TELEPORT_TO, popoverRef);

const route = useRoute();
const router = useRouter();
const urlTool = computed(() => route.query.tool);

const selectorData = ref({});

const { currentAccountId: currentAuthedAccountId } = useMe();

const activeAction = ref(undefined);

const mediaAsset = ref();

const { file, isFileLoading, reservationId } = toRefs(props);

const { nameTruncated } = useReservation(reservationId);
const fileId = computed(() => file.value?.id);
const isAcceptedHiddenEdit = computed(
  () => file.value?.edits_collection_digital_asset?.edit_status === 'accepted'
);

const { isPending: isClosing } = useTimeoutFn(
  e => {
    emits('close', e);
  },
  150,
  { immediate: false }
);

function closePopover(e) {
  emits('close', e);
}

const url = computed(() => {
  return file.value?.media?.url;
});
const matchMediaIsWide = useMediaQuery('(min-width: 60rem)');

useFocusTrap(popoverRef, { immediate: true });

const fileTitle = computed(() => {
  return truncate(file.value?.title, 50, true);
});

const tabTitle = computed(() => `${fileTitle.value ?? 'media editor'} | soona`);

useTitle(tabTitle);

const handleClose = () => {
  closePopover();
};

// lifecycle hooks
onKeyStroke('Escape', () => {
  if (checkAllowEscapeClose(popoverRef)) {
    handleClose();
  }
});

const { pageViewed } = useBaseEvents();

watch(
  () => fileId,
  () => {
    pageViewed();
  },
  { immediate: true }
);

watch(
  urlTool,
  () => {
    if (urlTool.value) {
      activeAction.value = ROUTE_MAP[urlTool.value] || null;
      const queryParams = { ...route.query };
      delete queryParams.tool;
      router.replace({ query: queryParams });
    }
  },
  { immediate: true }
);
</script>

<template>
  <Teleport to="body">
    <div
      ref="popoverRef"
      class="media-editor"
      :class="{
        'media-editor--closing': isClosing,
      }"
      role="dialog"
      aria-modal="true"
    >
      <MediaHeaderBase :is-wide="matchMediaIsWide">
        {{ fileTitle }}
        <template #details-bottom>
          <SoonaButton
            size="medium"
            variation="tertiary"
            element="router-link"
            :to="`/reservation/${reservationId}`"
          >
            <template #copy> {{ nameTruncated }}</template>
          </SoonaButton>
        </template>
        <template #actions>
          <DownloadDigitalAsset
            :asset="file"
            :active-action="activeAction"
            :account-id="currentAuthedAccountId"
          />
        </template>
      </MediaHeaderBase>
      <div class="media-editor__editing-panel">
        <div class="media-editor__middle-column">
          <ActionBarBase v-if="isAcceptedHiddenEdit">
            <template #actions-right>
              <HideAssetButton :asset="file" />
            </template>
          </ActionBarBase>
          <slot name="error" />
          <div class="media-editor__img-wrapper">
            <MediaAsset
              v-if="file"
              ref="mediaAsset"
              :file="file"
              :account-id="currentAuthedAccountId"
              :active-action="activeAction"
              :is-file-loading="isFileLoading"
              :popover-ref="popoverRef"
              :selector-data="selectorData"
              :src-url="url"
            />
          </div>
        </div>
      </div>
      <slot name="carousel" />
    </div>
  </Teleport>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.media-editor {
  background-color: variables.$gray-10;
  bottom: 0;
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  animation:
    0.25s ease-out both scale-up,
    0.15s ease-out both k-fade-in;

  @media (prefers-reduced-motion: reduce) {
    animation: 0.15s ease-out both k-fade-in;
  }

  &--closing {
    animation:
      0.15s ease-out both scale-down,
      0.15s ease-out both k-fade-out;

    @media (prefers-reduced-motion: reduce) {
      animation: 0.15s ease-out both k-fade-out;
    }
  }

  &__editing-panel {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
    position: relative;

    @media (min-width: variables.$screen-md-min) {
      flex-direction: row;
    }
  }

  &__middle-column {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    height: 100%;
    overflow-y: auto;
    transition: padding-right 0.3s cubic-bezier(0.22, 1, 0.36, 1);

    &--no-overflow {
      overflow-y: hidden;
    }
  }

  &__img-wrapper {
    align-items: center;
    display: grid;
    flex-grow: 1;
    grid-template-columns: 1fr auto;
    gap: 1rem;
    justify-items: center;
    min-height: 0;
    padding: 1rem 0 1rem 1rem;
    width: 100%;

    &--dense {
      gap: 0rem;
      padding: 0;
    }
  }
}

@keyframes scale-up {
  from {
    transform: scale(0.95);
  }
}

@keyframes scale-down {
  to {
    transform: scale(0.95);
  }
}
</style>
