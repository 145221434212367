<script setup>
import { computed } from 'vue';
import AssetImage from '@/components/checkout/AssetImage.vue';
import AssetHeading from '@/components/checkout/AssetHeading.vue';
import AssetAddOns from '@/components/checkout/AssetAddOns.vue';
import StandardEditCopy from '@/components/checkout/StandardEditCopy.vue';
import ChooseEdits from '@/components/checkout/ChooseEdits.vue';
import AssetNotes from '@/components/checkout/AssetNotes.vue';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import { useDeleteBagCollectionDigitalAsset } from '@/queries/bag_collection_digital_assets/useDeleteBagCollectionDigitalAsset';
import { useSoonaToast } from '@/components/ui_library/soona_toast/useSoonaToast';

const props = defineProps({
  accountId: {
    required: true,
    type: Number,
    default: null,
  },
  bagCollectionId: {
    type: [Number, String],
  },
  lineItem: {
    required: true,
    type: Object,
  },
  isSelected: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['selected']);

const accountId = computed(() => props.accountId);
const bagCollectionId = computed(() => props.bagCollectionId);
const lineItem = computed(() => props.lineItem);
const isSelected = computed(() => props.isSelected);
const bagCollectionDigitalAssetId = computed(
  () => lineItem.value.bag_collection_digital_asset?.id
);

const usingPreferred = lineItem => lineItem.credits?.some(c => c.preferred);

const checkboxModel = computed({
  get() {
    return isSelected.value;
  },
  set(value) {
    emit('selected', value, lineItem.value);
  },
});

const { addToast } = useSoonaToast();

const { mutate: removeFromBag, isPending: isRemovingFromBag } =
  useDeleteBagCollectionDigitalAsset(
    bagCollectionId,
    bagCollectionDigitalAssetId
  );

const removeAssetFromBag = () => {
  removeFromBag(
    {},
    {
      onError: () => {
        addToast(`error removing asset from the bag`, {
          variation: 'error',
          noDismissButton: true,
        });
      },
    }
  );
};

const isPending = computed(() => {
  return isRemovingFromBag.value;
});
</script>

<template>
  <div
    class="checkout-asset"
    :class="{ 'checkout-asset--selected': isSelected }"
    :data-cypress="`checkout-asset-${lineItem.id}`"
  >
    <div class="checkout-asset__top">
      <label v-if="lineItem.is_photo" class="checkout-asset__checkbox">
        <input
          v-model="checkboxModel"
          type="checkbox"
          :title="`toggle ${lineItem.title} for bulk selection`"
        />
      </label>
      <SoonaButton
        v-if="bagCollectionId"
        :disabled="isPending"
        size="medium"
        type="button"
        variation="icon-gray-outline"
        title="remove asset from checkout"
        :data-cypress="`button-remove-${lineItem.title
          .toLowerCase()
          .replace(/\s+/g, '-')}`"
        @on-click="removeAssetFromBag"
      >
        <SoonaIcon name="trash" size="medium" />
      </SoonaButton>
    </div>
    <AssetImage
      :content-type="lineItem.content_type"
      :watermark-url="lineItem.watermark_url || lineItem.watermark?.url"
      :image-url="lineItem.preview_url || lineItem.preview?.url"
      :is-video="lineItem.is_video"
      :is-gif="lineItem.is_gif"
      :title="lineItem.title"
    />
    <div class="checkout-asset__right">
      <AssetHeading
        :asset-id="lineItem.digital_asset_id"
        :reservation-id="lineItem.reservation_id"
        :title="lineItem.title"
        :total="lineItem.total"
        :using-preferred="usingPreferred(lineItem)"
      />
      <AssetAddOns
        v-if="lineItem.media_add_on_line_items.filter(a => !a._destroy).length"
        :account-id="accountId"
        :add-on-list="lineItem.media_add_on_line_items.filter(a => !a._destroy)"
        :is-photo="lineItem.is_photo"
        :reservation-id="lineItem.reservation_id"
        :using-preferred="usingPreferred(lineItem)"
      />
      <StandardEditCopy
        v-if="
          lineItem.media_add_on_line_items.filter(a => !a._destroy).length === 0
        "
      />
    </div>
    <div class="checkout-asset__bottom">
      <ChooseEdits
        :account-id="accountId"
        :add-on-list="lineItem.media_add_on_line_items"
        :asset-id="lineItem.id"
        :asset-title="lineItem.title"
        :asset-type="lineItem.asset_type"
        :is-photo="lineItem.is_photo"
        :is-gif="lineItem.asset_type === 'animated_collection'"
        :is-video="lineItem.is_video"
        :reservation-id="lineItem.reservation_id"
        :using-preferred="usingPreferred(lineItem)"
      />
      <AssetNotes :asset="lineItem" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.checkout-asset {
  display: grid;
  grid-template-columns: 6.25rem auto;
  grid-template-rows: auto min-content 1fr auto;
  border-radius: 0.625rem;
  background-color: variables.$white-default;
  border: 0.0625rem solid variables.$gray-30;
  overflow: hidden;
  padding: 1rem;
  transition:
    border-color 0.1s ease-out,
    background-color 0.1s ease-out;

  &--selected {
    border: 0.125rem solid variables.$periwink-blue-60;
    background-color: variables.$periwink-blue-10;
    padding: 0.9375rem;
  }

  &__top {
    display: flex;
    justify-content: flex-end;
    background-color: variables.$gray-10;
    padding: 0.25rem 0.75rem;
    grid-column: span 2;
    margin: -1rem -1rem 1rem -1rem;
    transition: background-color 0.1s ease-out;

    .checkout-asset--selected & {
      background-color: variables.$periwink-blue-20;
    }
  }

  &__checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: -0.25rem auto -0.25rem -0.75rem;
    width: 3.125rem;
    border-radius: 0.5rem;
    transition: background-color 0.1s ease-out;

    &:hover {
      background-color: variables.$gray-20;

      .checkout-asset--selected & {
        background-color: variables.$periwink-blue-30;
      }
    }

    input {
      width: 1.125rem;
      height: 1.125rem;
      cursor: pointer;
      accent-color: variables.$periwink-blue-60;
    }
  }

  &__right {
    grid-area: 2 / 2 / 2 / 2;
    margin-left: 0.75rem;
  }

  &__bottom {
    grid-area: 3 / 1 / 3 / 3;
  }

  @media (min-width: variables.$screen-md-min) {
    grid-template-columns: 10.25rem auto;

    &__bottom {
      grid-area: 3 / 2 / 3 / 2;
      margin-left: 0.75rem;
    }
  }
}
</style>
