<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import AutoEditsFeedback from '@/components/crew/AutoEditsFeedback.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaError from 'src/components/ui_library/SoonaError.vue';
import { useUpdateReservationFile } from '@/queries/reservation-files/useUpdateReservationFile';

const props = defineProps({
  reservationFile: Object,
});

const showFeedback = ref(false);
const reservationFile = computed(() => props.reservationFile);
const { mutate, isPending, error } = useUpdateReservationFile(reservationFile);

const store = useStore();

const reject = () => {
  showFeedback.value = false;
  mutate(
    { edit_status: 'rejected_edit' },
    {
      onSuccess: async () => {
        await store.dispatch(
          'reservation/loadOrders',
          reservationFile.value.reservation_id
        );
      },
    }
  );
};
</script>
<template>
  <AutoEditsFeedback
    v-if="showFeedback"
    category="auto_edits_post_production"
    subject-type="ReservationFile"
    :subject-id="reservationFile.id"
    :open="showFeedback"
    options-type="post"
    @close="() => (showFeedback = false)"
    @confirm="reject"
  />
  <SoonaError v-if="error">
    {{ error }}
  </SoonaError>
  <SoonaButton
    :copy="`hide ${reservationFile.title}`"
    size="medium"
    variation="tertiary"
    :disabled="isPending"
    :on-click="
      () => {
        showFeedback = true;
      }
    "
  >
    <template #icon-left>
      <SoonaIcon
        name="circle-xmark"
        size="small"
        style="vertical-align: middle"
      />
    </template>
  </SoonaButton>
</template>
<style lang="scss"></style>
