<script setup>
import { useInfiniteEditsCollectionDigitalAssets } from '@/queries/edits_collection_digital_assets/useInfiniteEditsCollectionDigitalAssets';
import { computed, ref } from 'vue';
import { useInfiniteDigitalAssets } from '@/composables/useInfiniteDigitalAssets';
import SmallInfiniteGallery from '@/components/infinite_asset_gallery/SmallInfiniteGallery.vue';
import { useInfiniteGalleryWrapper } from '@/components/infinite_asset_gallery/useInfiniteGalleryWrapper';
import HiddenEditsCollectionDigitalAsset from '@/components/user/anytime/reservation/gallery/edits/HiddenEditsCollectionDigitalAsset.vue';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
  collectionId: {
    type: [String, Number],
    required: true,
  },
  reservationId: {
    type: [String, Number],
    required: true,
  },
});

const collectionId = computed(() => props.collectionId);
const reservationId = computed(() => props.reservationId);

const wrapper = ref();
const itemsPerPage = ref(30);

const { gutter, rowHeight, rowWidth } = useInfiniteGalleryWrapper({
  wrapperEl: wrapper,
  heightRem: 12.5,
  gapRem: 0.75,
});

const filters = computed(() => ({
  reservation_id: reservationId.value,
  edit_status: ['accepted', 'customer_rejected', 'rejected'],
  visibility: 'crew',
}));

const {
  assetRows,
  fetchPage,
  isLoading: isLoadingAssets,
} = useInfiniteDigitalAssets(collectionId, {
  rowWidth,
  itemsPerPage,
  gutter,
  height: rowHeight,
  filters,
  query: useInfiniteEditsCollectionDigitalAssets,
});

function generateRouteLocation(editsCollectionDigitalAsset) {
  if (editsCollectionDigitalAsset.placeholder) return {};

  return {
    name: 'reservation-asset-hidden-edit-view',
    params: {
      reservationId: reservationId.value,
      digitalAssetId: editsCollectionDigitalAsset.digital_asset.id,
    },
  };
}
</script>

<template>
  <div v-if="!isLoadingAssets && assetRows.length > 0" ref="wrapper">
    <div class="hidden-edits__header">
      <h3 class="u-body--heavy">hidden edits and re-edits</h3>
    </div>
    <SmallInfiniteGallery
      v-slot="{ data }"
      :rows="assetRows"
      :initial-scroll-position="0"
      :height="rowHeight"
      :gap="gutter"
    >
      <HiddenEditsCollectionDigitalAsset
        v-for="ecda in data.assets"
        :key="ecda.id"
        :flex-grow="data.width / rowWidth > 0.6 ? 1 : 0"
        :edits-collection-digital-asset="ecda"
        :to="generateRouteLocation(ecda)"
        @request-page="pageNumber => fetchPage(pageNumber)"
      />
    </SmallInfiniteGallery>
  </div>
</template>

<style lang="scss" scoped>
.hidden-edits {
  margin-bottom: 1.5rem;

  &__header {
    margin-top: 1rem;
  }
}
</style>
