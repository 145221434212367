<script setup>
import { computed, ref, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useCollaborators } from '@/queries/collaborators/useCollaborators';
import { useSendGalleryInvitations } from 'src/queries/invitations/useSendGalleryInvitations';
import { useCopy } from '@/composables/useCopy';
import { useRegexHelper } from 'src/composables/useRegexHelper';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useRoute } from 'vue-router';
import SoonaAlert from '@/components/ui_library/SoonaAlert.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaItemCardSelectable from '@/components/ui_library/SoonaItemCardSelectable.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaTextfield from 'src/components/ui_library/SoonaTextfield.vue';
import SoonaForm from 'src/components/ui_library/SoonaForm.vue';
import { getSharedGalleryUrl } from '@/api/shared_gallery';
import { useSoonaToast } from 'src/components/ui_library/soona_toast/useSoonaToast';

const props = defineProps({
  accountId: [Number, String],
  reservationId: [Number, String],
});

const emit = defineEmits(['close']);
const { linkClicked } = useBaseEvents();
const route = useRoute();
const accountId = computed(() => props.accountId);
const reservationId = computed(() => props.reservationId);
const selectedCollaborators = ref([]);
const newCollaborators = ref([]);
const newCollaboratorInclude = ref();

const {
  data: collaboratorsData,
  isLoading,
  isSuccess,
} = useCollaborators(accountId);

const collaborators = computed(() => {
  return collaboratorsData.value?.collaborators;
});

const handleClose = () => emit('close');

const selectCollaborator = e => {
  if (e.target.checked) {
    selectedCollaborators.value?.push(parseInt(e.target.value));
  } else {
    selectedCollaborators.value = selectedCollaborators.value?.filter(
      c => c !== parseInt(e.target.value)
    );
  }
};

const showContributorsSection = computed(() => {
  return isSuccess.value && collaborators.value?.length > 0;
});

const handleAddNewEmail = () => {
  if (newCollaboratorInclude.value) {
    newCollaborators.value = [
      ...(newCollaborators.value || []),
      newCollaboratorInclude.value,
    ];
    newCollaboratorInclude.value = undefined;
  }
};

const handleRemoveEmail = index => {
  newCollaborators.value.splice(index, 1);
};

const { emailRegex } = useRegexHelper();

const newCollaboratorsHasInvalidEmail = computed(() => {
  return !!newCollaborators.value.filter(c => !emailRegex.test(c)).length;
});

const newCollaboratorIncludeIsNotEmail = computed(() => {
  return !emailRegex.test(newCollaboratorInclude.value);
});

const isSubmittable = computed(() => {
  if (newCollaboratorsHasInvalidEmail.value) {
    return false;
  }

  if (newCollaboratorInclude.value && newCollaboratorIncludeIsNotEmail.value) {
    return false;
  }

  if (
    !!newCollaborators.value?.length ||
    newCollaboratorInclude.value ||
    !!selectedCollaborators.value?.length
  ) {
    return true;
  }

  return false;
});

// copy link
const shareableLink = ref(null);

onMounted(() => {
  getSharedGalleryUrl(reservationId.value).then(response => {
    shareableLink.value = response.link;
  });
});

const { copyText, copySuccess } = useCopy();
const { addToast } = useSoonaToast();

watch(copySuccess, newVal => {
  if (!newVal) return;

  addToast('link copied to clipboard', {
    variation: 'success',
    noDismissButton: true,
  });
});

const handleCopyLink = () => {
  if (shareableLink.value) {
    copyText(shareableLink.value);
    linkClicked({
      context: route.meta.context,
      subContext: 'share gallery modal',
      linkLabel: 'copy link',
      linkHref: shareableLink.value,
    });
  } else {
    addToast('error copying link', {
      variation: 'error',
    });
  }
};

const store = useStore();
const currentAccountId = computed(() => store.state.currentUser?.accountId);
const {
  mutate: sendGalleryInvitations,
  isPending: isSendingGalleryInvitations,
} = useSendGalleryInvitations(currentAccountId);

const newInviteEmails = computed(() => {
  if (!newCollaboratorIncludeIsNotEmail.value) {
    return newCollaborators.value.concat(newCollaboratorInclude.value);
  }

  return newCollaborators.value;
});

const emailCount = computed(
  () => newInviteEmails.value?.length + selectedCollaborators.value?.length
);

const sendInvites = close => {
  if (!isSubmittable.value) return;

  let params = { reservationId: reservationId.value };

  if (newInviteEmails.value?.length) params.newInvites = newInviteEmails.value;

  if (selectedCollaborators.value?.length)
    params.existingCollaborators = selectedCollaborators.value;

  sendGalleryInvitations(params, {
    onSuccess: () => {
      addToast(`invitation email${emailCount.value !== 1 ? 's' : ''} sent!`, {
        subtext: `have your collaborator${
          emailCount.value !== 1 ? 's' : ''
        } check their inbox`,
        variation: 'success',
      });
      close();

      linkClicked({
        context: route.meta.context,
        subContext: 'share gallery modal',
        linkLabel: 'send invite',
        linkHref: '',
      });
    },
    onError: err => {
      addToast(
        `error sending invitation email${emailCount.value !== 1 ? 's' : ''}`,
        {
          subtext: err.message,
          variation: 'error',
        }
      );
    },
  });
};
</script>
<template>
  <SoonaDialog class="share-gallery-dialog" @close="handleClose">
    <template #heading>share gallery</template>
    <h3 class="u-label--heavy share-gallery-dialog__headline">
      share + add collaborator to account
    </h3>
    <div
      v-for="(_, index) in newCollaborators"
      :key="index"
      class="share-gallery-dialog__added-email"
    >
      <SoonaTextfield
        v-model:model-value.trim="newCollaborators[index]"
        required
        class="share-gallery-dialog__added-email-input"
        placeholder="email address"
        type="email"
        :rules="['required', 'email']"
      />
      <SoonaButton
        variation="icon-plain-gray"
        size="medium"
        type="button"
        class="share-gallery-dialog__remove-email"
        aria-label="remove this email"
        @click="() => handleRemoveEmail(index)"
      >
        <SoonaIcon name="xmark" />
      </SoonaButton>
    </div>
    <SoonaForm @submit.prevent="handleAddNewEmail">
      <SoonaTextfield
        v-model:model-value.trim="newCollaboratorInclude"
        required
        class="share-gallery-dialog__new-email"
        type="email"
        placeholder="email address"
      />
      <button
        type="submit"
        class="u-button-reset u-label--regular share-gallery-dialog__add-email"
      >
        <SoonaIcon name="plus" size="small" />
        add another email
      </button>
    </SoonaForm>
    <SoonaAlert class="share-gallery-dialog__alert">
      <div class="u-label--regular">
        account collaborators can mark their favorites. create and edit
        reservations. make purchases. you can change their role in your account
        profile.
      </div>
    </SoonaAlert>
    <hr v-if="showContributorsSection" class="share-gallery-dialog__divider" />
    <div v-if="showContributorsSection">
      <h3 class="u-label--heavy">share with current collaborators</h3>
      <div class="share-gallery-dialog__contributor-container">
        <div v-for="collaborator in collaborators" :key="collaborator.user_id">
          <SoonaItemCardSelectable
            :id="collaborator.id"
            :has-round-image="true"
            :image-url="collaborator.avatar_url"
            name="collaborators"
            :checked="selectedCollaborators.includes(collaborator.user_id)"
            :disabled="isLoading"
            :value="collaborator.user_id"
            @change="selectCollaborator"
          >
            <span class="share-gallery-dialog__contributor-content">
              <span class="u-body--regular">{{ collaborator.name }}</span>
            </span>
          </SoonaItemCardSelectable>
        </div>
      </div>
    </div>
    <template #footer="{ close }">
      <section class="share-gallery-dialog__view-link">
        <h2 class="u-a11y-only">view-only gallery link</h2>
        <SoonaIcon
          class="share-gallery-dialog__view-link-icon"
          name="globe"
          size="medium"
        />
        <ul class="u-small--regular">
          <li>
            <strong class="u-label--heavy">
              anyone with the link can view
            </strong>
          </li>
          <li>link will expire 7 days after creation</li>
        </ul>
        <SoonaButton
          v-if="reservationId"
          class="share-gallery-dialog__copy-link"
          type="button"
          variation="secondary-gray"
          size="medium"
          @click="handleCopyLink"
        >
          <SoonaIcon name="link" size="small" />
          copy link
        </SoonaButton>
      </section>
      <SoonaButton
        type="button"
        variation="tertiary"
        size="medium"
        @click="close"
      >
        cancel
      </SoonaButton>
      <SoonaButton
        type="button"
        size="medium"
        :loading="isSendingGalleryInvitations"
        :disabled="!isSubmittable"
        @click="sendInvites(close)"
      >
        send invite{{ emailCount > 1 ? 's' : '' }}
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>
<style scoped lang="scss">
@use '@/variables';

.share-gallery-dialog {
  &__headline {
    display: block;
    margin-bottom: 1.25rem;
  }

  &__added-email {
    position: relative;
  }

  &__added-email-input {
    padding-bottom: 0;

    &:deep(.soona-textfield__input) {
      padding-right: 2.25rem;
    }
  }

  &__remove-email {
    position: absolute;
    right: 0;
    top: 0.3125rem;
  }

  &__new-email {
    padding-bottom: 0;
  }

  &__add-email {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    color: variables.$black-default;
    margin-top: 0.75rem;

    svg {
      flex: 0 0 1rem;
    }
  }

  &__added-email {
    position: relative;
    margin-bottom: 0.75rem;
  }

  &__alert {
    margin: 1.25rem 0 0;
  }

  &__divider {
    height: 0.0625rem;
    background-color: variables.$gray-30;
    margin: 1.25rem 0;
  }

  &__contributor-container {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    margin-top: 1.25rem;
  }

  &__contributor-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding-right: 0.5rem;
  }

  &__view-link {
    flex: 1 0 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    background-color: variables.$gray-10;
    margin: -1.5rem -1.5rem 0.75rem;
    padding: 0.625rem 1.5rem;

    &-icon {
      flex: 0 0 1.25rem;
      color: variables.$gray-60;
    }
  }

  &__copy-link {
    flex-shrink: 0;
    margin-left: auto;
  }
}
</style>
