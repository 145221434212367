<script setup>
// todo: default tabs - done - gallery, upcoming - info
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { computed, ref, watchEffect } from 'vue';
import truncate from 'lodash/truncate';
import unescape from 'lodash/unescape';
import ReservationChat from '@/components/chat/ReservationChat.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';
import SoonaTab from '@/components/ui_library/SoonaTab.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { useReservation } from '@/composables/useReservation';
import { useDebounceFn, useEventListener } from '@vueuse/core';
import { useGetCrewAssignments } from '@/queries/useGetCrewAssignments';
import { useCapability } from '@/composables/useCapability';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useFlag } from '@/composables/useFlag';
import SoonaAlert from '@/components/ui_library/SoonaAlert.vue';
import ReservationError from '@/components/user/anytime/reservation/ReservationError.vue';
import SoonaUnsentReservationChatProvider from '@/components/chat/SoonaUnsentReservationChatProvider.vue';
import { useRegisterAnytimeToken } from '@/composables/useRegisterAnytimeToken';
import { useAccount } from '@/queries/account/useAccount';
import * as types from 'src/store/mutation-types';
import AccountInfoAuto from '@/components/user/anytime/dashboard/AccountInfoAuto.vue';
import { useReservationAuthorizedToViewClientDetails } from '@/composables/useReservationAuthorizedToViewClientDetails';

const props = defineProps({
  reservationId: {
    type: [Number, String],
    required: true,
  },
});
const store = useStore();
const route = useRoute();
const isChatOpen = ref(false);

const currentUser = computed(() => store.state.currentUser);
const reservationId = computed(() => props.reservationId);
const isAvailability = computed(
  () => route.name === 'reservation-availability'
);
const isCrewTab = computed(() => route.name === 'crew');
const isEditsTab = computed(() => route.name === 'crew-edits');
const isGalleryTab = computed(() => route.name === 'gallery');
const isInfoTab = computed(() => route.name === 'info');
const isMediaTab = computed(() => {
  return (
    route.name === 'media' ||
    route.name === 'reservation-media-view' ||
    route.name === 'reservation-asset-view'
  );
});
const isProServiceTab = computed(() => route.name === 'pro-service-traits');
const isShotlistTab = computed(() => route.name === 'shotlist');
const isEditHistory = computed(() => route.name === 'edit-history');

const pegasusAvailabilityCsrFlag = useFlag('pegasus_availability_csr');
const toasterCrewEditsTabFlag = useFlag('toaster_crew_edits_tab');

const {
  reservation: currentReservation,
  error,
  hasAddOnOrders,
  hasContentCreatorService,
  hasPickedProServicesPreferences,
  hasProServices,
  hasSchedulingPreference,
  isHeadshotsForAll,
  isLoading,
  isPack,
  isSuccess,
  isSurprise,
  reservationAccountId,
} = useReservation(reservationId);

const { data: account } = useAccount(reservationAccountId, {
  enabled: computed(() => !!reservationAccountId.value),
});

watchEffect(() => {
  store.commit(
    `reservation/${types.SET_CURRENT_RESERVATION}`,
    currentReservation.value || {}
  );
  if (currentReservation.value) {
    store.dispatch('anytime/setReservation', currentReservation.value);
    store.dispatch('shotList/resetShotlist', {
      reservationId: reservationId.value,
      accountId: currentReservation.value.account_id,
    });
  }
});

watchEffect(() => {
  if (account.value) {
    store.commit(`account/${types.SET_DESIRED_ACCOUNT_ID}`, account.value.id);
    store.commit(`account/${types.SET_ACCOUNT}`, account.value);
  }
});

const anytimeToken = computed(
  () => currentReservation.value?.anytime_token ?? null
);
useRegisterAnytimeToken(anytimeToken);

const transferPackages = computed(
  () => account.value?.transfer_package_requirement
);

const { data: crewAssignments } = useGetCrewAssignments(
  reservationId,
  {
    without_declined: true,
  },
  { enabled: computed(() => !!reservationId.value) }
);
// reservation
const truncatedResName = computed(() => {
  // todo: make this a function w/ options in composable?
  return truncate(unescape(currentReservation.value?.name), {
    length: 60,
    omission: '…',
  });
});
// pro services
const packWithoutProServices = computed(
  () => isPack.value && !hasProServices.value
);
const showProServicesTab = computed(() => {
  return (
    !isSurprise.value &&
    !isHeadshotsForAll.value &&
    (!packWithoutProServices.value || hasProServices.value)
  );
});
// packages
const activeTransferPackages = computed(() => {
  return transferPackages.value?.filter(
    p => p.status === 'pending' || p.status === 'initiated'
  );
});
const translatePackageTransferStatus = pkg => {
  if (pkg.status === 'pending') {
    return 'needed';
  }
  if (pkg.status === 'initiated') {
    return 'in progress';
  }
};
// end reservation
// chat
const currentUserIsProServiceUser = computed(() => {
  return currentUser.value?.employee_type_department === 'pro_services';
});
const reservationBelongsToCurrentUser = computed(() => {
  return currentUser.value?.accountId === currentReservation.value?.account_id;
});
const validChatPage = computed(() => {
  // Don't show chat on shipping pages
  return (
    isGalleryTab.value ||
    isInfoTab.value ||
    isShotlistTab.value ||
    isProServiceTab.value ||
    isCrewTab.value
  );
});

const isSameReservation = computed(() => {
  return Number(currentReservation.value?.id) === Number(reservationId.value);
});
const showChatV2 = computed(() => {
  // work around data logic bugs where the reservationId in the URL does not match the currentReservation
  // only show chat when the _right_ reservation is loaded
  return (
    currentUser.value?.userID &&
    isSameReservation.value &&
    !isMediaTab.value &&
    // ensure chat isn't mounted when a pro service user or non reservation account user
    (!currentUserIsProServiceUser.value ||
      reservationBelongsToCurrentUser.value)
  );
});
const reservationUserIds = computed(() => {
  if (isSameReservation.value) {
    const assignedCrew = crewAssignments.value?.map(c => c.user_id) ?? [];
    const reservationAccountUsers =
      currentReservation.value?.account?.users?.map(u => u.id) ?? [];
    const locationUserId = currentReservation.value?.location?.user_id;
    return [locationUserId, ...assignedCrew, ...reservationAccountUsers];
  } else {
    return [];
  }
});
// end chat
// scroll
const userHasScrolledPastBreakpoint = ref(false);
const handleScroll = () => {
  userHasScrolledPastBreakpoint.value = window.scrollY > 800;
};
const handleDebouncedScroll = useDebounceFn(() => handleScroll(), 100, {
  maxWait: 600,
});
useEventListener(window, 'scroll', handleDebouncedScroll, {
  capture: false,
  passive: true,
});

// end scroll

// capabilities
const { hasCapability: isSoonaStaff } = useCapability({
  capability: 'soona_staff',
});

const authorizedToViewClientDetails =
  useReservationAuthorizedToViewClientDetails(reservationId);
// end capabilities
// segment
const { linkClicked } = useBaseEvents();
const onRouterLinkClick = (linkLabel, linkHref) => {
  linkClicked({
    context: route.meta.context,
    subContext: 'reservation navigation',
    linkLabel: linkLabel,
    linkHref: linkHref,
  });
};
// end segment
</script>

<template>
  <div class="ReservationView">
    <SoonaUnsentReservationChatProvider>
      <div class="ReservationView__main-column">
        <h2
          v-if="currentReservation?.name"
          class="ReservationView__name"
          data-cypress="text-info-heading"
        >
          {{ truncatedResName }}
        </h2>

        <div
          v-if="currentReservation?.account_id"
          class="reservation-account-info"
        >
          <AccountInfoAuto
            :account-id="currentReservation.account_id"
            :link-to-profile="authorizedToViewClientDetails"
            :hide-pii="!authorizedToViewClientDetails"
          />
        </div>

        <nav
          v-if="!isMediaTab"
          class="link-section"
          data-cypress="reservation-links"
        >
          <SoonaTab
            v-if="authorizedToViewClientDetails"
            copy="GALLERY"
            :to="{ name: 'gallery' }"
            :is-selected="isGalleryTab"
            @on-click="
              onRouterLinkClick('gallery', '/user/anytime#/reservation')
            "
          />
          <SoonaTab
            data-cypress="tab-reservation-details"
            copy="DETAILS"
            :to="{ name: 'info' }"
            :is-selected="isInfoTab"
            @on-click="
              onRouterLinkClick('details', '/user/anytime#/reservation/info')
            "
          />
          <SoonaTab
            v-if="
              !isSurprise && !isHeadshotsForAll && !hasContentCreatorService
            "
            copy="SHOT LIST"
            :to="{ name: 'shotlist' }"
            :is-selected="isShotlistTab"
            @on-click="
              onRouterLinkClick(
                'shot list',
                '/user/anytime#/reservation/shotlist'
              )
            "
          />
          <SoonaTab
            v-if="showProServicesTab"
            data-cypress="tab-reservation-pro-services"
            :to="{ name: 'pro-service-traits' }"
            :is-selected="isProServiceTab"
            @on-click="
              onRouterLinkClick(
                'pro services',
                '/user/anytime#/reservation/pro-service-traits'
              )
            "
          >
            <template #copy>
              PRO&nbsp;SERVICES
              <span
                v-if="!hasPickedProServicesPreferences"
                class="action-bubble"
              >
                <SoonaIcon name="circle-exclamation-solid" size="small" />
              </span>
            </template>
          </SoonaTab>
          <SoonaTab
            v-if="isSoonaStaff && toasterCrewEditsTabFlag"
            copy="edits"
            :to="{ name: 'crew-edits' }"
            :is-selected="isEditsTab"
          />
          <SoonaTab
            v-if="isSoonaStaff"
            copy="CREW"
            :to="{ name: 'crew' }"
            :is-selected="isCrewTab"
          />
          <SoonaTab
            v-if="pegasusAvailabilityCsrFlag && hasSchedulingPreference"
            data-cypress="tab-reservation-availability"
            copy="AVAILABILITY"
            :to="{ name: 'reservation-availability' }"
            :is-selected="isAvailability"
            @on-click="
              onRouterLinkClick(
                'reservation-availability',
                '/user/anytime#/reservation/availability'
              )
            "
          />
          <!-- TODO: also include activeReEditsCollectionId? -->
          <SoonaTab
            v-if="hasAddOnOrders"
            copy="EDIT HISTORY"
            :to="{ name: 'edit-history' }"
            :is-selected="isEditHistory"
            @on-click="
              onRouterLinkClick(
                'edit-history',
                '/user/anytime#/reservation/edit-history'
              )
            "
          />
        </nav>
        <div :class="{ 'main-content--loading': isLoading }">
          <div v-if="isSoonaStaff && activeTransferPackages">
            <SoonaAlert v-for="pkg in activeTransferPackages" :key="pkg.id">
              package transfer
              {{ translatePackageTransferStatus(pkg) }} from
              {{ pkg.origin.short_name }} > {{ pkg.destination.short_name }}.
            </SoonaAlert>
          </div>
          <SoonaLoading
            v-if="isLoading"
            is-loading
            is-contained
            loading-text="loading…"
          />
          <template v-if="isSuccess">
            <router-view
              v-slot="{ Component }"
              :is-chat-open="isChatOpen"
              :user-has-scrolled-past-breakpoint="userHasScrolledPastBreakpoint"
            >
              <keep-alive include="Gallery" max="2">
                <component :is="Component" />
              </keep-alive>
            </router-view>
          </template>
          <!--        todo: fake error and check-->
          <ReservationError v-if="error" :error-code="error.response?.status" />
        </div>
      </div>
      <aside
        class="ReservationView__chat-column"
        :class="{
          'chat-enabled': validChatPage && showChatV2,
          'chat-open': isChatOpen,
        }"
      >
        <ReservationChat
          v-if="showChatV2"
          v-model:is-chat-open="isChatOpen"
          :current-user-id="currentUser.userID"
          :reservation-cal-uuid="currentReservation.cal_uuid"
          :reservation-id="reservationId"
          :reservation-status="currentReservation.status"
          :reservation-user-ids="reservationUserIds"
          :user-has-scrolled-past-breakpoint="userHasScrolledPastBreakpoint"
        />
      </aside>
    </SoonaUnsentReservationChatProvider>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.ReservationView {
  display: flex;
  margin: 0 auto;
  max-width: min(100%, 90rem);
  width: 100%;

  &__main-column {
    flex: 1 1 auto;
    /*
     * force this column to resize, otherwise things inside that have
     * horizontal overflow will force this larger instead of allowing
     * themselves to overflow
     */
    max-width: 100%;
    min-width: 0;
  }

  &__chat-column {
    display: none;
    flex: 0 0 auto;

    &.chat-enabled {
      display: flex;
    }

    &.chat-open {
      padding-left: 1rem;
      flex: 0 0 clamp(20rem, 30vw, 25rem);
    }
  }

  &__name {
    @include variables_fonts.u-subheader--heavy;

    grid-column: 1 / 3;
    color: variables.$black-default;
    margin-bottom: 1.5rem;

    @media (min-width: variables.$screen-sm-min) {
      @include variables_fonts.u-title--heavy;
    }
  }

  .reservation-account-info {
    &:empty {
      display: none;
    }
    &:not(:empty) {
      margin-bottom: 1.5rem;
    }
  }

  .link-section {
    display: flex;
    border-bottom: 0.0625rem solid variables.$gray-30;
    overflow-x: auto;
    margin-bottom: 2rem;

    :deep(.soona-tab:not([aria-current='true'])) {
      border-bottom: none;
      display: flex;
      gap: 0.25rem;
    }
  }

  .main-content--loading {
    position: relative;
    min-height: 8rem;
  }

  .action-bubble {
    color: variables.$friendly-red-50;
  }
}
</style>
