<script setup>
import { computed } from 'vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { PeriwinkBlue50 } from 'src/variables.module.scss';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import StepLayout from 'src/components/modal-payment-flows/preferred/StepLayout.vue';

const props = defineProps({
  context: {
    type: String,
  },
  showBackButton: {
    default: false,
    type: Boolean,
  },
  showLinks: {
    default: false,
    type: Boolean,
  },
  subContext: {
    type: String,
  },
});

const emits = defineEmits(['back', 'close', 'next']);

const { linkClicked } = useBaseEvents();

const showBackButton = computed(() => props.showBackButton);
const showLinks = computed(() => props.showLinks);

const handleDashboardBtnClick = () => {
  linkClicked({
    context: props.context,
    subContext: props.subContext,
    linkLabel: 'go to dashboard',
    linkHref: '/',
  });

  emits('close');
};

const handleBookShootBtnClick = () => {
  linkClicked({
    context: props.context,
    subContext: props.subContext,
    linkLabel: 'book shoot',
    linkHref: '/booking/new',
  });

  emits('close');
};
</script>

<template>
  <StepLayout
    class="congrats-step"
    :is-right-column-reversed="true"
    :right-column-bg-color="PeriwinkBlue50"
    :show-back-button="showBackButton"
    @back="emits('back')"
    @close="emits('close')"
    @next="emits('next')"
  >
    <template #heading>
      congratulations! you just unlocked some serious savings
    </template>
    <template #subheading>
      your photo credits & perks are available now. book a new shoot or order
      from previous shoots with your credits.
    </template>

    <div class="congrats-step__action-btns">
      <SoonaButton
        v-if="showLinks"
        copy="book shoot"
        element="router-link"
        to="/booking/new"
        @on-click="handleBookShootBtnClick"
      />
      <SoonaButton
        v-if="showLinks"
        copy="go to dashboard"
        element="router-link"
        to="/"
        variation="secondary-black"
        @on-click="handleDashboardBtnClick"
      />
      <SoonaButton v-else copy="continue" @on-click="emits('close')" />
    </div>
    <template #main-image>
      <img src="@images/bulk-credits/photo_collage@2x.png" alt="" />
    </template>
    <template #right-col-content>
      <div class="congrats-step__savings-img-container">
        <img
          class="congrats-step__savings-img"
          src="@images/bulk-credits/savings_unlocked_text@2x.png"
          alt=""
        />
      </div>
    </template>
  </StepLayout>
</template>

<style lang="scss" scoped>
@use '@/variables.scss';

.congrats-step {
  &__action-btns {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: auto;
    padding-top: 2rem;
  }

  &__savings-img-container {
    align-items: center;
    display: flex;
    height: 100%;
  }

  &__savings-img {
    max-height: 9.375rem;
  }
}
</style>
