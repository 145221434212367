<script setup>
import { onBeforeUnmount, onMounted, ref, nextTick } from 'vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import {
  onKeyStroke,
  useTimeoutFn,
  useResizeObserver,
  useDebounceFn,
} from '@vueuse/core';
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap';
import uniqueId from 'lodash/uniqueId';
import { useDialogContext } from '@/composables/useDialog';

defineProps({
  contentType: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  watermarkUrl: {
    type: String,
  },
  imageUrl: {
    required: true,
    type: String,
  },
  isGif: {
    default: false,
    type: Boolean,
  },
  isVideo: {
    required: true,
    type: Boolean,
  },
});

const emit = defineEmits(['close']);

const id = uniqueId('soona-reservation-file-dialog-');
const { checkAllowEscapeClose } = useDialogContext({ id });
const dialogBgTarget = ref();

const { start: beginClose, isPending: isClosing } = useTimeoutFn(
  () => {
    emit('close');
  },
  150,
  { immediate: false }
);

const close = () => {
  if (!isClosing.value) beginClose();
};

onKeyStroke('Escape', () => {
  if (checkAllowEscapeClose(dialogBgTarget)) {
    close();
  }
});
useFocusTrap(dialogBgTarget, { immediate: true });

const assetRef = ref();
const filenameRef = ref();
const assetWidth = ref('1000px');
const assetFilenameHeights = ref('1000px');
const filenameHeight = ref('50px');

const debouncedResize = useDebounceFn(async () => {
  assetWidth.value = assetFilenameHeights.value = '1000px';
  await nextTick();
  assetWidth.value = (assetRef.value?.clientWidth ?? 800) + 'px';
  assetFilenameHeights.value =
    (assetRef.value?.clientHeight ?? 800) +
    (filenameRef.value?.clientHeight ?? 800) +
    'px';
  filenameHeight.value = (filenameRef.value?.clientHeight ?? 800) + 'px';
}, 80);

useResizeObserver(assetRef, debouncedResize);

onMounted(() => {
  window.addEventListener('resize', debouncedResize);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', debouncedResize);
});
</script>
<template>
  <Teleport to="body">
    <div
      ref="dialogBgTarget"
      class="soona-reservation-file-dialog"
      :class="{ 'soona-reservation-file-dialog--closing': isClosing }"
      @click.self="close"
    >
      <div
        ref="imageModal"
        class="soona-reservation-file-dialog__container"
        aria-label="view asset larger"
        aria-modal="true"
        role="dialog"
        @click.self="close"
      >
        <SoonaButton
          class="soona-reservation-file-dialog__container-close"
          type="button"
          variation="icon-gray-outline"
          @on-click="close"
        >
          <SoonaIcon name="xmark" />
          <span class="u-a11y-only">close modal</span>
        </SoonaButton>
        <figure class="soona-reservation-file-dialog__asset-wrap">
          <img
            v-if="isGif"
            ref="assetRef"
            class="soona-reservation-file-dialog__img"
            :src="watermarkUrl"
            :alt="title"
          />
          <img
            v-else-if="!isVideo || (isVideo && !watermarkUrl)"
            ref="assetRef"
            class="soona-reservation-file-dialog__img"
            :src="imageUrl"
            :alt="title"
          />
          <video
            v-else
            ref="assetRef"
            class="soona-reservation-file-dialog__video"
            :poster="imageUrl"
            controls
            controlsList="nodownload"
          >
            <source
              :key="watermarkUrl"
              :src="watermarkUrl"
              :type="contentType"
            />
          </video>
          <figcaption
            ref="filenameRef"
            class="soona-reservation-file-dialog__filename"
          >
            {{ title }}
          </figcaption>
        </figure>
      </div>
    </div>
  </Teleport>
</template>
<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.soona-reservation-file-dialog {
  animation: 0.15s ease-out both k-fade-in;
  inset: 0;
  position: fixed;

  &--closing {
    animation: 0.15s ease-out both k-fade-out;
  }

  &__container {
    background-color: rgba(63, 67, 75, 0.7);
    display: flex;
    height: 100%;
    padding: 1.25rem;
    position: absolute;
    width: 100%;

    @media (min-width: variables.$screen-md-min) {
      padding: 3.125rem;
    }
  }

  &__container-close {
    position: absolute;
    right: 1.25rem;
    top: 1.25rem;

    @media (min-width: variables.$screen-md-min) {
      right: 2.1875rem;
      top: 2.1875rem;
    }
  }

  &__asset-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    height: 100%;
    max-width: min(100%, 62.5rem, v-bind('assetWidth'));
    max-height: min(100%, 62.5rem, v-bind('assetFilenameHeights'));
  }

  &__img,
  &__video {
    display: block;
    max-height: min(
      calc(100% - v-bind('filenameHeight')),
      calc(62.5rem - v-bind('filenameHeight'))
    );
    max-width: 100%;
    height: auto;
    width: auto;
  }

  &__filename {
    @include variables_fonts.u-body--heavy;

    background-color: variables.$white-default;
    padding: 0.75rem 1.25rem;
    width: 100%;
    word-break: break-word;
  }

  @media (min-width: variables.$screen-sm-min) and (min-height: variables.$screen-sm-min) {
    &__filename {
      @include variables_fonts.u-subheader--heavy;
    }
  }
}
</style>
