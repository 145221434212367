<script setup>
import { computed } from 'vue';
import SummaryPayment from '@/components/SummaryPayment.vue';
import { toCurrency } from '@/lib/currency';
import { useGetAccountsOrder } from '@/queries/accounts-orders/useGetAccountsOrder';
import AutoAppliedDiscountMiniBanner from '@/components/user/anytime/discounts/AutoAppliedDiscountMiniBanner.vue';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
  canViewPaymentInfo: {
    type: Boolean,
    default: true,
    required: false,
  },
  isCompedOrder: {
    required: true,
    type: Boolean,
  },
  isOrder: {
    required: true,
    type: Boolean,
  },
  products: {
    type: Object,
    default: null,
    required: true,
  },
  isCreditable: {
    type: Boolean,
    required: false,
    default: true,
  },
  orderId: {
    type: [String, Number],
    default: null,
    required: false,
  },
  reservationId: {
    default: null,
    required: false,
  },
  coupon: {
    type: Object,
    default: null,
    required: false,
  },
  preferred: {
    required: false,
    type: Object,
    default: null,
  },
});

const accountId = computed(() => props.accountId);
const coupon = computed(() => props.coupon);
const orderId = computed(() => props.orderId);
const products = computed(() => props.products);

const productRate = product => {
  return product.price_info?.rate;
};

const productDiscountedRate = product => {
  return product.price_info?.discounted_rate
    ? product.price_info?.discounted_rate
    : null;
};

const productSubtotalRate = product => {
  return productDiscountedRate(product) || productRate(product);
};

const subtotal = computed(() => {
  return products.value.reduce(
    (sum, product) => sum + productSubtotalRate(product) * product.quantity,
    0
  );
});

const creditsTotal = 0;

const { data: order } = useGetAccountsOrder(
  accountId,
  { orderId },
  {
    enabled: computed(() => !!accountId.value && !!orderId.value),
  }
);

const showCoupon = computed(() => {
  // if discount is product-specific, supress showing total discount amount
  // because the discounts are shown on the line item level
  if (
    order.value?.discount?.is_product_scoped ||
    coupon.value?.is_product_scoped
  )
    return false;

  if (order.value?.discount && !order.value?.expired) return true;
  if (coupon.value !== null) return true;
  return false;
});

const couponDiscountAmount = computed(() => {
  const discount = order.value?.discount ?? coupon.value;

  if (discount === null) return 0;

  if (discount.amount) return discount.amount;

  return 0;
});

const finalTotal = computed(() => {
  let total;
  if (!showCoupon.value) {
    total = subtotal.value - creditsTotal;
  } else {
    total = subtotal.value - creditsTotal - couponDiscountAmount.value;
  }
  if (total < 0) total = 0;
  return total;
});
</script>

<template>
  <SummaryPayment
    :total="finalTotal"
    :is-comped-order="isCompedOrder"
    :can-view-payment-info="canViewPaymentInfo"
  >
    <tbody class="summary-payment__itemized-body">
      <tr v-for="product in products" :key="product.id">
        <th scope="row">
          <div>{{ product.quantity }}x {{ product.name }}</div>
        </th>
        <td class="summary-payment__itemized-price">
          <div
            :class="{
              'summary-payment__itemized-price__old':
                productDiscountedRate(product),
            }"
          >
            {{ toCurrency(productRate(product) * product.quantity) }}
          </div>
          <div
            v-if="productDiscountedRate(product)"
            class="summary-payment__itemized-price__new"
          >
            {{ toCurrency(productDiscountedRate(product) * product.quantity) }}
          </div>
        </td>
      </tr>
      <tr class="summary-payment__itemized-subtotal">
        <th scope="row">subtotal</th>
        <td>{{ toCurrency(subtotal) }}</td>
      </tr>
      <tr v-if="!isOrder && isCreditable">
        <th scope="row">credits</th>
        <td>{{ toCurrency(creditsTotal) }}</td>
      </tr>
      <tr v-if="isOrder && isCompedOrder">
        <th scope="row">discounts</th>
        <td>-{{ toCurrency(subtotal) }}</td>
      </tr>
      <tr v-if="showCoupon" class="summary-payment__coupon-discount">
        <th scope="row">
          promo code:
          <b>{{ order?.discount?.code ?? coupon?.external_discount_id }}</b>
        </th>
        <td>
          <strong class="u-subheader--heavy">
            -{{ toCurrency(Number(couponDiscountAmount)) }}
          </strong>
        </td>
      </tr>
    </tbody>
    <template v-if="canViewPaymentInfo" #payment>
      <slot name="payment"></slot>
    </template>
    <template #discount_banner>
      <AutoAppliedDiscountMiniBanner
        class="summary-payment__auto-apply-banner"
        :account-id="accountId"
        :preferred="preferred"
        :reservation-id="reservationId"
        :tagline="coupon?.auto_apply_tagline"
      />
    </template>
  </SummaryPayment>
</template>

<style scoped lang="scss">
@use '@/variables';
@use '@/variables_fonts';

.summary-payment {
  &__itemized-price {
    display: flex;
    gap: 0.25rem;
    justify-content: flex-end;
  }

  &__itemized-price {
    display: flex;
    gap: 0.25rem;
    justify-content: flex-end;

    &__old {
      color: variables.$gray-60;
      text-decoration: line-through;
    }

    &__new {
      @include variables_fonts.u-body--heavy;
      color: variables.$friendly-red-70;
    }
  }

  &__coupon-discount {
    text-transform: lowercase;

    b {
      font-weight: 800;
    }

    strong {
      color: variables.$friendly-red-70;
    }
  }

  &__auto-apply-banner {
    @include variables_fonts.u-small--regular;

    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid variables.$gray-30;
    color: variables.$white-default;
    background-color: variables.$bingo-blue-dark;
    padding: 0.5rem 1rem;
    flex-direction: column;
    gap: 0.75rem;
    margin-bottom: 1rem;
    text-align: center;

    @media (min-width: variables.$screen-sm-min) {
      border-radius: 0.625rem 0.625rem 0 0;
      margin: -1.375rem -1.5625rem 1.25rem;
    }
  }
}
</style>
