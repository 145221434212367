<template>
  <div class="soona-select-wrapper">
    <v-select
      v-model:model-value="selected"
      class="soona-select-input"
      :data-cypress="cypressName"
      :class="{
        'soona-select-input__multi': multi,
        'soona-select-input--inline': inline,
      }"
      :placeholder="placeholder"
      :options="options"
      :multiple="multi"
      :clearable="clearable"
      :searchable="searchable"
      :reduce="reduce"
      :label="label"
      :loading="loading"
      :disabled="disabled"
      :selectable="selectable"
      :input-id="inputId"
      :deselect-from-dropdown="selectedOptionsCheckbox"
      append-to-body
      @option:selecting="$emit('option:selecting', $event)"
      @option:deselecting="$emit('option:deselecting', $event)"
    >
      <template #option="option">
        <span class="selection-item">
          <input
            v-if="selectedOptionsCheckbox"
            type="checkbox"
            :checked="isSelected(option)"
            class="selected-option-checkbox"
          />
          <img
            v-if="option.image"
            class="thumbnail-img"
            :src="option.image"
            alt=""
          />
          <span class="selection-item-label">
            <strong v-if="option.heading">{{ option.heading }}</strong>
            {{ label ? option[label] : option.label }}
          </span>
          <SoonaIcon
            v-if="!selectedOptionsCheckbox"
            name="check"
            class="selection-item__check"
          />
        </span>
      </template>
      <template #header>
        <div v-if="$slots.label" class="select-label-wrapper">
          <label v-if="$slots.label" :for="inputId">
            <slot name="label" />
          </label>
          <SoonaButton
            v-if="clearableBtn"
            class="select-clear-button"
            copy="reset"
            variation="tertiary"
            size="medium"
            @on-click="clearSelected"
          />
        </div>
        <p v-if="$slots.subtext" class="u-label--regular select-subtext">
          <slot name="subtext" />
        </p>
      </template>
    </v-select>
  </div>
</template>

<script>
import uniqueId from 'lodash/uniqueId';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

export default {
  name: 'SoonaSelect',
  components: {
    SoonaIcon,
    SoonaButton,
  },
  props: {
    placeholder: String,
    modelValue: null, // null will match any prop type - used if multi is false
    modelValues: Array, // used if multi is true
    multi: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: true,
    },
    options: Array,
    label: {
      type: String,
      required: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    clearableBtn: {
      type: Boolean,
      required: false,
      default: false,
    },
    clearable: {
      type: Boolean,
      required: false,
      default: false,
    },
    cypressName: {
      type: String,
      default: 'soona-select',
    },
    selectable: {
      type: Function,
      required: false,
    },
    selectedOptionsCheckbox: {
      type: Boolean,
      required: false,
      default: false,
    },
    reduce: {
      type: Function,
      required: false,
      default: ({ value }) => value,
    },
    inline: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'update:modelValue',
    'update:modelValues',
    'option:selecting',
    'option:deselecting',
  ],
  data() {
    return {
      inputId: uniqueId('soona-select-'),
    };
  },
  computed: {
    selected: {
      get() {
        return this.multi ? this.modelValues : this.modelValue;
      },
      set(val) {
        this.$emit(
          this.multi ? 'update:modelValues' : 'update:modelValue',
          val
        );
      },
    },
  },
  methods: {
    clearSelected() {
      const clearValue = this.multi ? [] : '';

      this.$emit(
        this.multi ? 'update:modelValues' : 'update:modelValue',
        clearValue
      );
    },
    isSelected(option) {
      return this.selected.includes(option.value);
    },
  },
};
</script>

<style lang="scss">
@use '@/variables';
@use '@/variables_fonts';

.vs__dropdown-menu {
  .vs__dropdown-option {
    white-space: normal;
    padding: 0.1875rem 0.75rem;

    &--highlight {
      background: variables.$gray-10;
      color: variables.$black-default;
    }

    &--selected {
      background: variables.$periwink-blue-20;
    }
  }
}

.selection-item {
  height: 100%;
  display: flex;
  align-items: center;

  .thumbnail-img {
    height: 2.8125rem;
    margin-right: 0.9375rem;
  }
  .selected-option-checkbox {
    margin-right: 0.625rem;
  }

  &__check {
    display: none;
    color: variables.$periwink-blue-60;
    height: 1.25rem;
    width: 1.25rem;
    flex-shrink: 0;
  }
}

.vs__dropdown-option--selected .selection-item__check {
  display: block;
}

.selection-item-label {
  display: flex;
  flex-direction: column;
}
.soona-select-wrapper {
  max-width: 100%;
}
.soona-select-wrapper {
  .v-select {
    .select-label-wrapper {
      @include variables_fonts.u-label--heavy;
      color: variables.$black-default;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-bottom: 0.4375rem;
      label {
        padding-top: 0.1875rem;
      }
      .select-clear-button {
        min-width: initial;
      }
    }

    .select-subtext {
      color: variables.$gray-60;
      padding-bottom: 0.5rem;
    }

    .vs__dropdown-toggle {
      border: 0.0625rem solid variables.$gray-30;
      border-radius: 0.3125rem;
      min-height: 2.5rem;

      &:hover {
        cursor: pointer;
      }
      input {
        cursor: pointer;
      }
    }
    .vs__search::placeholder {
      color: variables.$gray-50;
    }
    .vs__open-indicator {
      color: variables.$black-default;
    }
    .vs__dropdown-menu {
      @include variables_fonts.u-body--regular;
      li {
        padding: 0.5rem 0.75rem;
      }
    }
    // focused state -- when user is interacting with select box
    &.vs--open {
      .vs__dropdown-toggle {
        border: 0.0625rem solid variables.$black-default;
        outline: 0.125rem solid variables.$periwink-blue-60;
      }
    }

    // single select
    &.vs--single.vs--open,
    &.vs--single.vs--loading {
      .vs__selected {
        position: relative;
      }
    }

    &.soona-select-input--inline {
      display: flex;
      flex-flow: row wrap;
      gap: 0.5rem;
      align-items: center;

      .select-label-wrapper {
        padding: 0;
      }
    }

    // multi-select chips
    &.soona-select-input__multi {
      .vs__selected {
        border: none;
        border-radius: 999px;
        padding: 0.125rem 0.125rem 0.125rem 0.5rem;
        background-color: variables.$gray-20;
        color: variables.$black-default;
        @include variables_fonts.u-label--regular;

        // these are the default styles for the vue-select component.
        // these are here to override styles from _anytime.scss.
        // when we remove _anytime.scss stylings, this can be removed.
        .vs__deselect {
          display: inline-flex;
          appearance: none;
          margin-left: 0.25rem;
          padding: 0;
          border: 0;
          cursor: pointer;
          background: none;
          text-shadow: 0 0.0625rem 0 #fff;

          path {
            fill: variables.$gray-50;
          }

          &:hover path {
            fill: variables.$gray-60;
          }
        }
      }
    }

    &.vs--disabled {
      .vs__dropdown-toggle {
        &:hover {
          cursor: pointer;
        }
      }
    }

    &:not(.vs--disabled):not(.vs--open) .vs__dropdown-toggle:hover {
      border-color: variables.$gray-50;
    }
  }
}
</style>
