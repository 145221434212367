<script setup>
import { ref } from 'vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaChip from '@/components/ui_library/SoonaChip.vue';
import SceneSection from '@/components/user/anytime/scene_selector/SceneSection.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import AddPropsModal from '@/components/user/anytime/shotlist/AddPropsModal.vue';

defineProps({
  soonaPropTagCategory: {
    type: Object,
    required: true,
  },
  selectedSoonaPropTags: {
    type: Array,
    required: true,
  },
});
defineEmits(['removeSoonaProp', 'selectSoonaProps']);

const showAddPropsModal = ref(false);
</script>

<template>
  <SceneSection>
    <template #heading>soona props</template>
    <SoonaButton
      class="scene-builder-soona-props__button"
      size="medium"
      type="button"
      variation="secondary-black"
      @on-click="showAddPropsModal = true"
    >
      <SoonaIcon name="plus" />
      add soona props
    </SoonaButton>
    <ul class="scene-builder-soona-props__chips">
      <li v-for="option in selectedSoonaPropTags" :key="option.id">
        <SoonaChip
          :image-src="option.image_url"
          image-alt="prop preview"
          removable
          @remove="$emit('removeSoonaProp', option.id)"
        >
          {{ option.title }}
        </SoonaChip>
      </li>
    </ul>
  </SceneSection>

  <AddPropsModal
    v-if="showAddPropsModal"
    :tag-category="soonaPropTagCategory"
    :emit-selections="true"
    @close-dialog="() => (showAddPropsModal = false)"
    @select-props="$emit('selectSoonaProps', $event)"
  />
</template>

<style lang="scss" scoped>
.scene-builder-soona-props {
  &__button {
    align-self: flex-start;
    margin-bottom: 0.5rem;
  }

  &__chips {
    display: flex;
    flex-flow: row wrap;
    gap: 0.5rem;
    list-style: none;
  }
}
</style>
