<script setup>
import { ref } from 'vue';
import SoonaForm from '@/components/ui_library/SoonaForm.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';

const emit = defineEmits(['cancel', 'createAlbum']);

const newAlbumTitle = ref('');

const createAlbum = () => {
  emit('createAlbum', newAlbumTitle.value);
};
</script>

<template>
  <SoonaForm
    v-slot="{ hasErrors }"
    aria-labelledby="album-picker-new-form-heading"
    class="picker-create-album"
    @submit="createAlbum"
  >
    <h3 id="album-picker-new-form-heading" class="picker-create-album__heading">
      new album
    </h3>
    <SoonaTextfield
      v-model="newAlbumTitle"
      name="album-title"
      type="text"
      label="album name"
      data-cypress="input-new-album-name"
      required
      :rules="['required']"
    />

    <div class="picker-create-album__footer">
      <SoonaButton
        variation="tertiary"
        copy="cancel"
        type="button"
        @on-click="$emit('cancel')"
      />
      <SoonaButton
        variation="primary"
        copy="create"
        type="submit"
        data-cypress="button-create-new-album"
        :disabled="hasErrors"
      />
    </div>
  </SoonaForm>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';
.picker-create-album {
  padding: 1rem;
  border: 0.0625rem solid variables.$gray-30;
  border-radius: 0.625rem;
  background-color: variables.$periwink-blue-10;

  &__heading {
    @include variables_fonts.u-subheader--heavy;
    color: variables.$black-default;
    margin-bottom: 1rem;
  }

  &__footer {
    display: flex;
    flex-flow: row wrap;
    gap: 1rem;
    justify-content: flex-end;
  }
}
</style>
