<script setup>
import SoonaDropdownMenu from 'src/components/ui_library/SoonaDropdownMenu.vue';
import SoonaDropdownMenuItemCheckbox from 'src/components/ui_library/SoonaDropdownMenuItemCheckbox.vue';
import { useLocations } from 'src/queries/useLocation';
import { computed } from 'vue';

const locationIds = defineModel('locationIds', {
  type: Array,
  default: [],
  required: true,
});

const { data: locationsData } = useLocations([
  'studio',
  'coming_soon',
  'disabled',
]);

const locationOptions = computed(() => {
  if (!locationsData.value) {
    return [];
  }
  return locationsData.value.map(({ id, display_name }) => ({
    value: id,
    label: display_name.toLowerCase(),
  }));
});

function isSelected(location) {
  return !!locationIds.value.find(item => item === location.value);
}

function locationSelect(location) {
  let indexNumber = locationIds.value.findIndex(
    locationId => locationId === location.value
  );
  let proxy = [...locationIds.value];
  if (indexNumber > -1) {
    proxy.splice(indexNumber, 1);
  } else {
    proxy.push(location.value);
  }
  locationIds.value = proxy;
}

const dropdownCopy = computed(() => {
  if (
    !locationOptions.value ||
    locationIds.value.length < 1 ||
    locationIds.value.length >= locationOptions.value.length
  ) {
    return 'all locations';
  } else if (locationIds.value.length > 1) {
    return `${locationIds.value.length} locations`;
  } else {
    return locationOptions.value.find(
      location => location.value === locationIds.value[0]
    ).label;
  }
});
</script>
<template>
  <div class="location-dropdown-filter">
    <SoonaDropdownMenu
      variation="filter"
      :copy="dropdownCopy"
      class="select-choice-dropdown"
      :class="{
        ['select-choice-dropdown--active']: locationIds.length > 0,
      }"
    >
      <template #default="{ clickCapture, keydown, mouseover }">
        <SoonaDropdownMenuItemCheckbox
          v-for="location in locationOptions"
          :key="location.value"
          :checked="isSelected(location)"
          @click.capture="clickCapture"
          @click="locationSelect(location)"
          @keydown="keydown"
          @mouseover="mouseover"
        >
          {{ location.label }}
        </SoonaDropdownMenuItemCheckbox>
      </template>
    </SoonaDropdownMenu>
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables';
.location-dropdown-filter {
  .select-choice-dropdown {
    :deep(.soona-button--filter) {
      padding: 0.375rem 0.5rem 0.375rem 0.5rem;
    }
  }
  .select-choice-dropdown--active {
    :deep(.soona-button--filter) {
      background-color: variables.$friendly-red-20;
    }
  }
}
@media (min-width: variables.$screen-sm-min) {
  :deep(.soona-button--filter) {
    padding: 0.375rem 0.5rem 0.375rem 0.0625rem;
  }
}
</style>
