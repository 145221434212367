<script setup>
import SoonaDropdownMenu from 'src/components/ui_library/SoonaDropdownMenu.vue';
import SoonaDropdownMenuItemCheckbox from 'src/components/ui_library/SoonaDropdownMenuItemCheckbox.vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

const props = defineProps({
  tagCategory: {
    type: Object,
    required: true,
  },
  modelValue: {
    type: Array,
    required: true,
  },
  proServiceProviderType: {
    type: String,
    required: true,
  },
});
const emits = defineEmits(['update:modelValue']);
const route = useRoute();
const tagCategory = computed(() => props.tagCategory);
const modelValue = computed(() => props.modelValue);
const proServiceProviderType = computed(() => props.proServiceProviderType);

const { filterSelected } = useBaseEvents();

const filteredProServiceTags = computed(() => {
  let tags = tagCategory.value.tags.filter(t => t.title !== 'no preference');
  tags.sort((a, b) => a.secondary_order - b.secondary_order);
  return tags;
});
const tagsSelected = computed(() => {
  return modelValue.value.filter(tag =>
    tagCategory.value.tags.some(t => t.id === tag)
  );
});
function tagTitle(tag) {
  return (tag.icon ? tag.icon + ' ' : '') + tag.title;
}
const filterTitle = computed(() => {
  if (tagsSelected.value.length === 1) {
    return tagTitle(
      tagCategory.value.tags.find(t => t.id === tagsSelected.value[0])
    );
  }
  return tagCategory.value.title;
});
function isSelected(tag) {
  let isChecked = tagsSelected.value.find(item => item === tag.id);
  return typeof isChecked != 'undefined';
}
function traitSelect(item) {
  if (!isSelected(item)) {
    filterSelected({
      context: route.meta.context,
      subContext: `${proServiceProviderType.value}`,
      filterCategory: item.tag_category_title,
      filterSubCategory: '',
      filterLabel: item.title,
    });
  }
  emits('update:modelValue', item.id);
}
</script>
<template>
  <div class="pro-service-dropdown-filter">
    <SoonaDropdownMenu
      variation="filter"
      :copy="filterTitle"
      class="select-choice-dropdown"
      :class="{
        ['select-choice-dropdown-active']: tagsSelected.length > 0,
      }"
    >
      <template #default="{ clickCapture, keydown, mouseover }">
        <SoonaDropdownMenuItemCheckbox
          v-for="tag in filteredProServiceTags"
          :key="tag.id"
          :checked="isSelected(tag)"
          @click.capture="clickCapture"
          @click="traitSelect(tag)"
          @keydown="keydown"
          @mouseover="mouseover"
        >
          {{ tagTitle(tag) }}
        </SoonaDropdownMenuItemCheckbox>
      </template>
    </SoonaDropdownMenu>
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables';
.pro-service-dropdown-filter {
  .select-choice-dropdown {
    :deep(.soona-button--filter) {
      padding: 0.375rem 0.5rem 0.375rem 0.5rem;
    }
  }
  .select-choice-dropdown-active {
    :deep(.soona-button--filter) {
      background-color: variables.$friendly-red-20;
    }
  }
}
@media (min-width: variables.$screen-sm-min) {
  :deep(.soona-button--filter) {
    padding: 0.375rem 0.5rem 0.375rem 0.0625rem;
  }
}
</style>
