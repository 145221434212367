<script setup>
import { computed, ref } from 'vue';
import MissingContentCreativeOpportunities from './MissingContentCreativeOpportunities.vue';
import MissingContentListingsList from './MissingContentListingsList.vue';
import { useMissingContentOpportunities } from '@/queries/useListingActions';
import ActionViewTemplateHeader from '../ActionViewTemplateHeader.vue';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SubmitShootRequest from './SubmitShootRequest.vue';
import SoonaSuccess from 'src/components/ui_library/SoonaSuccess.vue';
import ShootRequestDialog from './ShootRequestDialog.vue';

const props = defineProps({
  accountId: { type: String, required: true },
  pageContent: { type: Object, required: true },
});

const accountId = computed(() => props.accountId);

const { data: opportunities } = useMissingContentOpportunities(accountId);

// filter module
const filterOptions = computed(() => {
  const result =
    opportunities.value?.map(x => {
      return {
        label: x.listing_rule_title,
        value: x.listing_rule_slug,
      };
    }) ?? [];
  result.unshift({ label: 'all', value: 'missing-content' });

  return result;
});

const filter = ref('missing-content');

const displayShootRequestDialog = ref(false);
const hasSubmittedShootRequest = ref(false);
</script>

<template>
  <div class="missing-content-view">
    <SoonaSuccess v-if="hasSubmittedShootRequest">
      <p class="u-subheader--heavy">your shoot request has been submitted!</p>
      <p>a member of the soona team will reach out.</p>
    </SoonaSuccess>
    <ActionViewTemplateHeader v-else :page-content="pageContent">
      <template #actions>
        <div>
          <SoonaButton @click="displayShootRequestDialog = true">
            submit shoot request
          </SoonaButton>
        </div>
      </template>
    </ActionViewTemplateHeader>

    <MissingContentCreativeOpportunities
      :account-id="accountId"
      :opportunities="opportunities"
      @select-listing-rule-slug="filter = $event"
    />
    <SubmitShootRequest
      v-if="!hasSubmittedShootRequest"
      :account-id="accountId"
      @submit-shoot-request="displayShootRequestDialog = true"
    />
    <MissingContentListingsList
      v-model:selected-filter="filter"
      :account-id="accountId"
      :filter-options="filterOptions"
    />
  </div>
  <ShootRequestDialog
    v-if="displayShootRequestDialog"
    @shoot-request-submitted="hasSubmittedShootRequest = true"
    @close="displayShootRequestDialog = false"
  />
</template>

<style lang="scss" scoped>
@use '@/variables';

.missing-content-view {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}
</style>
