<template>
  <SoonaDialog class="add-props-modal" @close="closeAddPropModal">
    <template #heading>add a soona prop</template>
    <div v-for="subcategory in soonaPropSubCategory" :key="subcategory">
      <p v-if="displaySubCategory(subcategory)" class="u-body--heavy">
        {{ subcategory.replace(/_/g, ' ') }}
      </p>
      <div
        v-if="displaySubCategory(subcategory)"
        class="add-props-modal__tag-container"
      >
        <button
          v-for="tag in soonaPropsTagsFilter(subcategory)"
          :key="`prop-${tag.id}`"
          class="add-props-modal__tag-btn"
          :aria-pressed="selectedPropTagIds.includes(tag.id)"
          :title="tag.title"
          type="button"
          @click="selectedPropList(tagCategory.id, tag)"
        >
          <img :src="tag.image_url" :alt="tag.title" />
        </button>
      </div>
    </div>
    <SoonaButton
      v-if="showLoadMore"
      class="add-props-modal__load-more-btn"
      copy="load more props"
      variation="tertiary"
      @on-click="loadFiles"
    />
    <template #footer>
      <div class="add-props-modal__action-btns">
        <SoonaButton
          copy="cancel"
          variation="secondary-gray"
          @on-click="closeAddPropModal"
        />
        <SoonaButton
          :copy="submitBtnCopy"
          :disabled="selectedPropTagIds.length === 0"
          data-cypress="soona-prop-submit-button"
          @on-click="submitPropTag()"
        />
      </div>
    </template>
  </SoonaDialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';

export default {
  name: 'AddPropsModal',
  components: {
    SoonaButton,
    SoonaDialog,
  },
  props: {
    tagCategory: Object,
    emitSelections: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['close-dialog', 'select-props'],
  data() {
    return {
      errors: [],
      createTagTagCategoryId: undefined,
      selectedPropTagIds: [],
      page: 1,
      soonaPropSubCategory: [
        'shapes',
        'naturals',
        'knick_knacks',
        'bathroom',
        'kitchen',
        'styled_glassware_options',
        'bedroom',
        'living_room',
        'office',
      ],
    };
  },
  computed: {
    ...mapGetters('shotList', [
      'savedSelectedSoonaPropTagsIds',
      'propsTCTags',
      'isPropsLastPage',
    ]),
    ...mapState({
      currentShot: state => state.shotList.currentShot,
    }),
    soonaPropsTags() {
      return this.propsTCTags
        ? this.propsTCTags.filter(
            tag =>
              !this.savedSelectedSoonaPropTagsIds(this.currentShot).includes(
                tag.id
              )
          )
        : [];
    },
    showLoadMore() {
      return !this.isPropsLastPage;
    },
    submitBtnCopy() {
      return `add ${this.selectedPropTagIds.length} prop${
        this.selectedPropTagIds.length !== 1 ? 's' : ''
      }`;
    },
  },
  beforeMount() {
    this.loadPropsTagCategory({ page: this.page });
  },
  methods: {
    ...mapActions('shotList', ['addPropTag', 'loadPropsTagCategory']),
    async submitPropTag() {
      //this.$track('Add Props Clicked', { current_shot: this.currentShot });

      let propTagList = this.propsTCTags.filter(tag =>
        this.selectedPropTagIds.includes(tag.id)
      );
      try {
        if (this.emitSelections) {
          this.$emit('select-props', propTagList);
        } else {
          await this.addPropTag(propTagList);
        }
        this.$emit('close-dialog');
        this.selectedPropTagIds = [];
      } catch (error) {
        this.customTagError = 'oops! we had a problem adding your soona props.';
      }
    },
    selectedPropList(tagCategoryId, tag) {
      this.errors = this.errors.filter(e => e.id != tagCategoryId);

      if (this.selectedPropTagIds.includes(tag.id)) {
        //this.$track(capitalizeWords(tag.title) + ' Deselected', {
        //   tag: tag,
        //   tag_category_id: tagCategoryId,
        // });
        this.selectedPropTagIds = this.selectedPropTagIds.filter(
          selectedTag => selectedTag != tag.id
        );
      } else {
        //this.$track(capitalizeWords(tag.title) + ' Selected', {
        //   tag: tag,
        //   tag_category_id: tagCategoryId,
        // });
        this.selectedPropTagIds = [...this.selectedPropTagIds, tag.id];
      }
    },
    closeAddPropModal() {
      this.$emit('close-dialog');
      this.selectedPropTagIds = [];
    },
    async loadFiles() {
      this.page += 1;
      await this.loadPropsTagCategory({
        page: this.page,
        append: true,
      });
    },
    soonaPropsTagsFilter(subcategory) {
      return this.soonaPropsTags.filter(tag => tag.subcategory === subcategory);
    },
    displaySubCategory(subcategory) {
      return this.soonaPropsTags.some(tag =>
        tag.subcategory.includes(subcategory)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';

.add-props-modal {
  &__tag-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min(8rem, 100%), 1fr));
    gap: 0.25rem;
    padding-bottom: 1.5rem;
  }

  &__tag-btn {
    border-radius: 0.5rem;
    border: variables.$white-default solid 0.125rem;
    background: variables.$white-default;
    cursor: pointer;
    overflow: hidden;
    padding: 0;

    &[aria-pressed='true'] {
      border-color: variables.$black-default;
    }
    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  &__load-more-btn {
    display: flex;
    margin: 0 auto;
  }

  &__action-btns {
    display: flex;
    gap: 0.75rem;
  }
}
</style>
