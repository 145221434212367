<script setup>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import accountImage from 'images/account-placeholder.svg';

const props = defineProps({
  reservation: {
    type: Object,
    required: true,
  },
  assignment: {
    type: Object,
    required: true,
  },
  showImage: {
    type: Boolean,
    required: false,
  },
});
const showDeclineModal = ref(false);
const store = useStore();

const acceptAssignment = ({ crewAssignmentId, reservationId }) =>
  store.dispatch('crewAssignment/acceptAssignment', {
    crewAssignmentId,
    reservationId,
  });

const declineAssignment = ({ crewAssignmentId, reservationId }) =>
  store.dispatch('crewAssignment/declineAssignment', {
    crewAssignmentId,
    reservationId,
  });

const assignmentStatus = computed(() => {
  if (props.assignment.status === 'sent') {
    return 'pending';
  } else {
    return props.assignment.status;
  }
});

const assignmentName = computed(() => {
  return props.assignment.pro_service_profile?.name
    ? props.assignment.pro_service_profile?.name
    : props.assignment.profile_full_name
      ? props.assignment.profile_full_name
      : props.assignment.user_name;
});

const formattedName = computed(() => {
  let nameArray = assignmentName.value.split(' ');
  return nameArray.length > 1
    ? nameArray[0] + ' ' + nameArray[1].slice(0, 1) + '.'
    : nameArray[0];
});

function handleAccept() {
  acceptAssignment({
    crewAssignmentId: props.assignment.id,
    reservationId: props.reservation.id,
  });
}

function handleDecline() {
  declineAssignment({
    crewAssignmentId: props.assignment.id,
    reservationId: props.reservation.id,
  });
}

function openDeclineModal() {
  showDeclineModal.value = true;
}

function closeDeclineModal() {
  showDeclineModal.value = false;
}
</script>

<template>
  <div class="assignment-item">
    <div class="provider-name-section section">
      <div class="section-header">
        {{ assignment.crew_role
        }}<span v-if="!showImage">{{ ' - ' + formattedName }}</span>
      </div>
      <div :class="assignmentStatus + ' banner'">
        {{ assignmentStatus }}
      </div>
    </div>
    <div class="assignment-details">
      <div v-if="showImage" class="assignment-img">
        <img
          v-if="props.assignment.main_image_url"
          :alt="assignment.crew_role + ' service thumbnail'"
          :src="props.assignment.main_image_url"
        />
        <img
          v-else
          class="user-info-image"
          :src="accountImage"
          alt="account profile"
        />
      </div>
      <div class="assignment-info">
        <div v-if="showImage" class="section-header">{{ formattedName }}</div>
        <div v-if="assignment.note" class="notes-section section">
          <div class="section-header">assignment notes:</div>
          <div>{{ assignment.note }}</div>
        </div>
        <div
          class="actions-section section"
          :class="{ 'with-image': showImage }"
        >
          <SoonaButton
            :variation="
              assignment.status === 'sent' ? 'secondary-black' : 'tertiary'
            "
            size="medium"
            :copy="assignment.status === 'accepted' ? 'cancel' : 'decline'"
            :on-click="openDeclineModal"
            :data-cypress="
              assignment.status === 'accepted'
                ? 'button-psp-cancel-assignment'
                : 'button-psp-decline-assignment'
            "
          />
          <SoonaButton
            v-if="assignment.status !== 'accepted'"
            variation="primary"
            size="medium"
            class="left-margin"
            copy="accept"
            :on-click="handleAccept"
            data-cypress="button-psp-accept-assignment"
          />
        </div>
      </div>
    </div>
  </div>
  <SoonaDialog v-if="showDeclineModal" @close="closeDeclineModal">
    <template #heading>
      decline {{ assignment.crew_role }} for {{ assignmentName }}?
    </template>
    <p>
      are you sure you want to decline this assignment and be removed from the
      crew on this booking? this cannot be undone and will notify the studio to
      replace this service on the booking.
    </p>
    <p>
      if you want to keep the booking and hope to change the time: please call
      the studio.
    </p>
    <template #footer="{ close }">
      <SoonaButton variation="tertiary" @on-click="close">
        nevermind
      </SoonaButton>
      <SoonaButton
        data-cypress="button-dialog-confirm"
        @on-click="handleDecline"
      >
        decline
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>

<style lang="scss" scoped>
@use '@/variables';
.assignment-item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  .section-header {
    color: variables.$black-default;
    font-weight: 900;
    font-size: 0.875rem;
  }
  .section {
    display: flex;
    width: 100%;
    padding: 0rem;
    margin-bottom: 0.75rem;
  }
  .actions-section {
    justify-content: flex-end;
    width: 100%;
    > * {
      margin-left: 0.5rem;
    }
    &.with-image {
      justify-content: flex-start;
      > * {
        margin-left: 0rem;
        margin-right: 0.5rem;
      }
      :deep(.soona-button--tertiary) {
        min-width: 0rem;
      }
    }
  }
  .provider-name-section {
    align-items: center;
    .section-header {
      letter-spacing: 0.1rem;
      text-transform: uppercase;
      margin-right: 0.25rem;
    }
  }
  .assignment-details {
    width: 100%;
    display: flex;
    .assignment-info {
      width: 100%;
      .notes-section {
        flex-direction: column;
        word-break: break-all;
        .section-header {
          text-transform: lowercase;
        }
      }
    }
    .assignment-img {
      margin: 0rem 1rem 0.5rem 0rem;
      img {
        min-width: 9.375rem;
        min-height: 9.375rem;
        max-width: 9.375rem;
        max-height: 9.375rem;
      }
    }
    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }
  }
  .banner {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    padding: 0.05rem 0.3rem;
    color: variables.$white-default;
    margin-right: 0.25rem;
    &.accepted {
      background-color: variables.$avo-toast-60;
    }
    &.pending {
      background-color: variables.$tangerine-70;
    }
    &.declined {
      background-color: variables.$roses-70;
    }
  }
}
</style>
