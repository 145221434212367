<script setup>
import { computed, ref, toRefs } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SelectSourceAsset from './SelectSourceAsset.vue';
import { usePlatformName } from './usePlatformName';
import useSubscriptionActionPermissions from '@/components/user/anytime/listing_insights/listing_actions/useSubscriptionActionPermissions';

const props = defineProps({
  accountId: { type: [String, Number], required: true },
  externalProductId: { type: String, default: null },
  integrationId: { type: String, default: null },
  listingAction: { type: Object, required: true },
});

// any update to listing action needs to happen in parent.
// all errors must be surfaced in parent.
// this component is only responsible for displaying buttons & dialogs.
const emit = defineEmits([
  'undoChanges',
  'handleUploadComplete',
  'handleLinkSourceAsset',
  'captureSelectSourceImageEvent',
  'handleUpgradeClick',
  'handleResetListingAction',
  'handlePublish',
]);

const { accountId, listingAction } = toRefs(props);

const catalogItemId = computed(() => {
  return listingAction.value.catalog_item_id;
});
const { platform } = usePlatformName(listingAction);

const {
  canTakeAction,
  optimizeButtonVariation,
  isBasicUserAndOutOfCredits,
  subscriptionActionPermissionsSuccess,
} = useSubscriptionActionPermissions(accountId);

// select source image module
const showSelectImageSourceDialog = ref(false);
const selectSourceImage = () => {
  if (canTakeAction.value) {
    showSelectImageSourceDialog.value = true;
  }
  // if canTakeAction is false, handle showing subx modal from parent.
  emit('captureSelectSourceImageEvent');
};
const handleAssetSelected = digitalAssetId => {
  showSelectImageSourceDialog.value = false;
  emit('handleLinkSourceAsset', digitalAssetId);
};
</script>

<template>
  <div class="listing-action-active__buttons">
    <template v-if="listingAction.status === 'active'">
      <SoonaButton
        v-if="isBasicUserAndOutOfCredits"
        variation="pizzazz"
        @click="$emit('handleUpgradeClick')"
      >
        upgrade for more
      </SoonaButton>
      <template v-else-if="subscriptionActionPermissionsSuccess">
        <SoonaButton
          :variation="optimizeButtonVariation"
          size="medium"
          @click="selectSourceImage"
        >
          optimize
        </SoonaButton>
      </template>
    </template>
    <template v-else-if="listingAction.status === 'failed_to_publish'">
      <SoonaButton
        variation="primary"
        size="medium"
        @click="$emit('handlePublish')"
      >
        retry
      </SoonaButton>
      <SoonaButton
        variation="tertiary"
        size="medium"
        @click="$emit('handleResetListingAction')"
      >
        cancel
      </SoonaButton>
    </template>
    <template v-else>
      <SoonaButton
        variation="solid-black"
        size="medium"
        @click="$emit('handlePublish')"
      >
        publish to {{ platform }}
      </SoonaButton>
      <SoonaButton
        variation="tertiary"
        size="medium"
        @click="$emit('undoChanges')"
      >
        undo changes
      </SoonaButton>
    </template>
  </div>
  <SelectSourceAsset
    v-if="showSelectImageSourceDialog"
    :platform="platform"
    :account-id="accountId"
    :catalog-item-id="catalogItemId"
    @asset-selected="handleAssetSelected"
    @close="showSelectImageSourceDialog = false"
  />
</template>

<style lang="scss" scoped>
.listing-action-active {
  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-end;
  }
}
</style>
