<script setup>
import { ref, computed, watch, onMounted, nextTick } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import { useCapability } from 'src/composables/useCapability';
import isObject from 'lodash/isObject';
import SoonaError from 'src/components/ui_library/SoonaError.vue';
import SoonaAlert from 'src/components/ui_library/SoonaAlert.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaCreditCard from '@/components/ui_library/SoonaCreditCard.vue';
import ShopifyIntegrationPrimarySelect from './user/anytime/products/shopify/ShopifyIntegrationPrimarySelect.vue';
import { track, userAndAccountTraits } from '@/lib/segment/segment';
import { usePriorityErrors } from 'src/composables/usePriorityErrors';

const props = defineProps({
  actionText: {
    default: 'Submit Payment',
    type: String,
    required: false,
  },
  isStripeDisclaimerBelowCheckoutButton: {
    default: false,
    type: Boolean,
  },
  onPaymentAction: {
    type: Function,
    required: true,
  },
  stripePaymentRequired: {
    type: Boolean,
    required: true,
  },
  hasWidePaymentButton: {
    type: Boolean,
    default: false,
  },
  showUsingCardOnFile: {
    // TODO: this is a temp solution for 2024-01-25 launch
    type: Boolean,
    default: false,
  },
  saveableCard: {
    type: Boolean,
    default: true,
  },
  isSubscriptionCharge: {
    type: Boolean,
    default: false,
  },
});

const store = useStore();
const route = useRoute();

let elementStyles = {
  base: {
    fontFamily: 'Lato, Helvetica Neue, Helvetica, Arial, sans-serif',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '1.5',
    textTransform: 'lowercase',
    color: '#000',
    backgroundColor: '#fff',
    border: '1px solid blue',
    '::placeholder': {
      color: '#70747B',
    },
  },
  invalid: {
    color: '#F03742',
    iconColor: '#F03742',
  },
};

var elementClasses = {
  focus: 'focused',
  empty: 'empty',
  invalid: 'invalid',
};

const stripe = ref(null);
const elements = ref(null);
const card = ref(null);
const showCardForm = ref(false);
const loading = ref(false);
const card_element = ref();
const stripeErrorText = ref(undefined);
const paymentErrors = ref(undefined);

const paymentInfo = computed(() => store.state.payment?.defaultPaymentInfo);
const account = computed(() => store.state.account);
const payment = computed(() => store.state.payment);
const currentUser = computed(() => store.state.currentUser);
const currentUserAccounts = computed(() => store.state.currentUser?.accounts);
const currentUserAccountId = computed(() => store.state.currentUser?.accountId);

const hasDefaultPayment = computed(() => {
  return currentUserAccounts.value.find(
    account => account.id === currentUserAccountId.value
  )?.saved_default_card;
});

const requireSavedPayment = computed(() => route.name === 'fast-pass');

const setSaveCard = shouldSaveCard => {
  store.dispatch('payment/setSaveCard', shouldSaveCard);
};

const resetErrors = () => {
  store.dispatch('order/resetErrors');
};

const saveCard = computed({
  get() {
    return requireSavedPayment.value ? true : payment.value.saveCard;
  },
  set(value) {
    requireSavedPayment.value ? setSaveCard(true) : setSaveCard(value);

    track('Stripe - Save Default Payment Changed', {
      save_default_card: value,
      ...userAndAccountTraits(currentUser.value),
    });
  },
});

const isSubscriber = computed(() => {
  if (requireSavedPayment.value) {
    return true;
  } else {
    return currentUserAccounts.value.find(
      account => account.id === currentUserAccountId.value
    )?.member;
  }
});

const { hasCapability: canManageDefaultPayment } = useCapability({
  capability: 'manage_default_payment',
  subjectType: 'account',
  subjectId: account.value?.id,
});

const stripePaymentRequired = computed(() => props.stripePaymentRequired);
const showUsingCardOnFile = computed(() => props.showUsingCardOnFile);

const mountStripeElement = () => {
  if (window.Stripe) {
    stripe.value = window.Stripe(import.meta.env.VITE_STRIPE_PUBLIC_API_KEY);
    elements.value = stripe.value.elements();
    card.value = elements.value.create('card', {
      style: elementStyles,
      classes: elementClasses,
    });
    card.value.mount(card_element.value);
    card.value.on('change', event => {
      resetErrors();

      // Sets immediate JS-caught Stripe validation errors
      if (event.error) {
        stripeErrorText.value = event.error.message;
      } else {
        stripeErrorText.value = undefined;
      }

      if (event.complete) {
        stripeErrorText.value = undefined;
      }
    });
  } else {
    console.error(
      'Stripe does not exist on the window. Could not initialize Stripe or elements.'
    );
  }
};

const fetchDefaultPayment = accountId => {
  store.dispatch('payment/fetchDefaultPayment', accountId);
};

const isSubscriptionCharge = computed(() => props.isSubscriptionCharge);

const setupPaymentForm = () => {
  if (isSubscriptionCharge.value && stripePaymentRequired.value) {
    if (showUsingCardOnFile.value) {
      showCardForm.value = false;
    } else {
      showCardForm.value = true;
      mountStripeElement();
    }
  } else if (
    !hasDefaultPayment.value &&
    stripePaymentRequired.value &&
    !showUsingCardOnFile.value
  ) {
    showCardForm.value = true;
    mountStripeElement();
  } else if (hasDefaultPayment.value && stripePaymentRequired.value) {
    fetchDefaultPayment(currentUserAccountId.value);
  }
};

watch(
  () => stripePaymentRequired.value,
  async () => {
    //stripe needs the dom to be rendered before mounting to it, next tick makes sure we wait until after render to mount stripe
    await nextTick(setupPaymentForm);
  }
);

const toggle = () => {
  mountStripeElement();
  showCardForm.value = true;
  track(
    'Stripe - Soona Payment Change Clicked',
    userAndAccountTraits(currentUser.value)
  );
  stripeErrorText.value = undefined;
  resetErrors();
};

// shopify payment store
const shopifyPaymentStoreDomain = ref(null);
const setShopifyPaymentStoreDomain = storeDomain => {
  shopifyPaymentStoreDomain.value = storeDomain;
};

const paymentAction = async () => {
  paymentErrors.value = undefined;
  loading.value = true;

  try {
    if (isObject(card.value)) {
      card.value.update({ disabled: true });
    }

    await props.onPaymentAction(
      stripe.value,
      card.value,
      saveCard.value,
      paymentInfo.value,
      shopifyPaymentStoreDomain.value
    );
  } catch (error) {
    paymentErrors.value = error;
    console.error(error);
  } finally {
    loading.value = false;

    if (isObject(card.value)) {
      card.value.update({ disabled: false });
    }
  }
};

onMounted(() => {
  setupPaymentForm();
});

const expMonth = computed(() => {
  //Add leading zero if single digit
  return ('0' + paymentInfo.value?.exp_month).slice(-2);
});

const expYear = computed(() => {
  //Only show last two year digits
  return paymentInfo.value?.exp_year % 100;
});

const paymentPriorityErrors = usePriorityErrors(paymentErrors);
</script>

<template>
  <div class="soona-payment">
    <div
      v-if="stripePaymentRequired && showUsingCardOnFile"
      class="u-label--heavy soona-payment__container"
    >
      <!-- TODO: this is a temp solution for 2024-01-25 launch -->
      card on file will be charged
      <small class="u-label--small soona-payment__edit-card">
        need to edit this credit card? go to your
        <a :href="`/#/account/${account?.id}/orders`">billing history</a> page.
      </small>
    </div>
    <div v-else-if="stripePaymentRequired" class="soona-payment__container">
      <div v-show="showCardForm && canManageDefaultPayment">
        <div ref="card_element" class="soona-payment__card" />
      </div>
      <template v-if="hasDefaultPayment && !showCardForm">
        <div class="soona-payment__default-payment">
          <div class="soona-payment__default-card">
            <SoonaCreditCard :brand="paymentInfo?.brand" />
          </div>
          <div v-if="paymentInfo" class="soona-payment__card-saved">
            <strong class="soona-payment__card-masked">
              <span class="soona-payment__card-brand">
                {{ paymentInfo.brand }}
              </span>
              <span>**** **** **** {{ paymentInfo.last4 }}</span>
            </strong>
            <small class="soona-payment__card-exp">
              exp: {{ expMonth }}/{{ expYear }}
            </small>
          </div>
          <button
            v-if="canManageDefaultPayment"
            type="button"
            class="u-button-reset soona-payment__change"
            @click="toggle"
          >
            change
          </button>
        </div>
      </template>
      <template
        v-if="
          (showCardForm && canManageDefaultPayment) ||
          (hasDefaultPayment && !showCardForm)
        "
      >
        <SoonaError v-if="stripeErrorText">
          {{ stripeErrorText }}
        </SoonaError>
        <div
          v-if="!isStripeDisclaimerBelowCheckoutButton"
          class="soona-payment__secure"
        >
          <SoonaIcon name="lock-alt" size="small" />
          secure payment processed by Stripe
        </div>
      </template>
      <template v-if="saveableCard && showCardForm && canManageDefaultPayment">
        <label v-if="canManageDefaultPayment" class="u-checkbox">
          <input
            v-if="!isSubscriber"
            v-model="saveCard"
            type="checkbox"
            @update:model-value="handleSaveCard"
          />
          <span> save my payment method for faster check out </span>
        </label>
        <label v-else class="u-checkbox">
          <span>see account admin</span>
        </label>
      </template>
      <SoonaAlert v-if="!canManageDefaultPayment && !hasDefaultPayment">
        contact your account admin to setup a payment method.
      </SoonaAlert>
    </div>
    <div v-else class="soona-payment__shopify-billing">
      <ShopifyIntegrationPrimarySelect
        :account-id="account?.id"
        label="billed Shopify shop"
        :is-subscription-charge="isSubscriptionCharge"
        @set-shopify-payment-store-domain="setShopifyPaymentStoreDomain"
      />
    </div>
    <slot name="action-block" />
    <SoonaError
      v-if="paymentPriorityErrors"
      :priority-errors="paymentPriorityErrors"
      no-margin
    />
    <SoonaButton
      type="button"
      :is-submit="true"
      data-cypress="payment-submit"
      variation="solid-black"
      :copy="actionText"
      :loading="loading"
      :on-click="paymentAction"
      :disabled="
        (showCardForm && stripeErrorText !== undefined) ||
        (!canManageDefaultPayment && !hasDefaultPayment)
      "
      class="soona-payment__submit"
      :class="{
        'soona-payment__submit--wide': hasWidePaymentButton,
      }"
    />
    <slot name="buttons" />
    <div
      v-if="isStripeDisclaimerBelowCheckoutButton"
      class="soona-payment__secure"
    >
      <SoonaIcon name="lock-alt" size="small" />
      secure payment processed by Stripe
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.soona-payment {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  flex-grow: 1;

  &__container {
    flex-grow: 1;
  }

  &__edit-card {
    display: block;
    margin-top: 0.5rem;

    > a {
      text-decoration: underline;
      color: variables.$black-default;

      &:hover,
      &:focus-visible {
        text-decoration: none;
      }
    }
  }

  &__card {
    border: 0.0625rem solid variables.$gray-30;
    padding: 0.5rem;
    background-color: variables.$white-default;
    border-radius: 0.3125rem;
    transition: border-color 0.1s ease-out;

    &:hover {
      border-color: variables.$gray-50;
    }
  }

  &__shopify-billing {
    display: flex;
    flex-grow: 1;
    align-items: flex-start;
  }

  &__shopify-integration-select {
    width: max-content;
  }

  &__secure {
    @include variables_fonts.u-label--regular;

    display: flex;
    gap: 0.25rem;
    align-items: center;
    color: variables.$black-default;
    margin: 1rem 0 0;

    > svg {
      display: block;
      flex: 0 0 1rem;
    }
  }

  &__submit {
    display: flex;
    width: 100%;
  }

  &__default-payment {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 1.25rem 0;
  }

  &__default-card {
    display: block;
    width: 1.6875rem;
    height: 1.5rem;
    margin: 0 0.75rem 0 0.25rem;
    flex: 0 0 1.6875rem;
  }

  &__card-saved {
    margin-right: auto;
  }

  &__card-masked {
    @include variables_fonts.u-body--heavy;

    display: flex;
    flex-wrap: wrap;
    text-transform: uppercase;
    color: variables.$black-default;
  }

  &__card-brand {
    margin-right: 0.25rem;
  }

  &__card-exp {
    @include variables_fonts.u-label--regular;

    display: block;
  }

  &__change {
    @include variables_fonts.u-label--regular;

    margin-left: 0.5rem;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  @media (min-width: variables.$screen-sm-min) {
    &__submit {
      margin: 0 auto;
      width: auto;
    }

    &__submit--wide {
      width: 100%;
    }
  }
}
</style>
