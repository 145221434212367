<script setup>
import { computed } from 'vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaDropdownMenuItem from '@/components/ui_library/SoonaDropdownMenuItem.vue';
import SoonaDropdownMenu from '@/components/ui_library/SoonaDropdownMenu.vue';
import { useIntegrations } from '@/composables/useIntegrations';

const props = defineProps({
  accountId: {
    type: String,
    required: true,
  },
  openManageAccounts: {
    type: Function,
    required: true,
  },
  selectedIntegrationId: {
    type: [String, Number, null],
    required: true,
  },
  variation: {
    type: String,
    default: 'secondary-gray',
  },
});

defineEmits(['select']);

const accountId = computed(() => props.accountId);
const selectedIntegrationId = computed(() => props.selectedIntegrationId);

const { actionableIntegrations } = useIntegrations(accountId);

const integrations = computed(() => actionableIntegrations.value ?? []);

function integrationLogo(type) {
  if (type === 'ShopifyIntegration') {
    return 'shopify-logo';
  } else if (type === 'AmazonIntegration') {
    return 'amazon-logo';
  }
}

const selectedIntegration = computed(() => {
  return integrations.value.find(
    integration =>
      Number(integration.id) === Number(selectedIntegrationId.value)
  );
});

function integrationName(integration) {
  if (integration.type === 'ShopifyIntegration') {
    let store = integration.external_provider_id;
    store = store.replaceAll('-', ' ');
    store = store.replaceAll('.myshopify.com', ' ');
    store = store.replaceAll('admin.shopify.com/store/', ' ');
    return store;
  } else if (integration.type === 'AmazonIntegration') {
    return 'Amazon';
  }
}
</script>

<template>
  <SoonaDropdownMenu
    title="choose integration"
    :variation="variation"
    size="large"
  >
    <template #trigger-content>
      <slot v-if="$slots['trigger-content']" name="trigger-content"></slot>
      <template v-else>
        <span v-if="!selectedIntegration">all stores</span>
        <template v-else>
          <SoonaIcon
            :name="integrationLogo(selectedIntegration.type)"
            size="medium"
          />
          <span class="integration-name">{{
            integrationName(selectedIntegration)
          }}</span>
        </template>
      </template>
    </template>
    <template #default="{ keydown, mouseover, clickCapture }">
      <SoonaDropdownMenuItem v-if="integrations.length > 1">
        <button
          class="integration-select-btn"
          role="menuitem"
          @click="() => $emit('select', null)"
          @click.capture="clickCapture"
          @keydown="keydown"
          @mouseover="mouseover"
        >
          <span>all stores</span>
          <SoonaIcon
            v-if="!selectedIntegrationId"
            class="integration-select-btn__icon"
            name="check"
            size="medium"
          />
        </button>
      </SoonaDropdownMenuItem>
      <SoonaDropdownMenuItem
        v-for="integration in integrations"
        :key="integration.id"
      >
        <button
          class="integration-select-btn"
          role="menuitem"
          @click="() => $emit('select', integration.id)"
          @click.capture="clickCapture"
          @keydown="keydown"
          @mouseover="mouseover"
        >
          <SoonaIcon :name="integrationLogo(integration.type)" size="medium" />
          <span class="integration-name">{{
            integrationName(integration)
          }}</span>
          <SoonaIcon
            v-if="integration.id === Number(selectedIntegrationId)"
            class="integration-select-btn__icon"
            name="check"
            size="medium"
          />
        </button>
      </SoonaDropdownMenuItem>
      <hr class="integration-select-hr" />
      <SoonaDropdownMenuItem>
        <button
          class="integration-select-btn integration-select-btn--bold"
          role="menuitem"
          @click="openManageAccounts"
          @click.capture="clickCapture"
          @keydown="keydown"
          @mouseover="mouseover"
        >
          <SoonaIcon name="integrations" size="medium" /> manage accounts
        </button>
      </SoonaDropdownMenuItem>
    </template>
  </SoonaDropdownMenu>
</template>

<style lang="scss" scoped>
@use '@/variables';

.integration-name {
  text-transform: capitalize;
}
.integration-select-hr {
  margin: 0;
  color: variables.$gray-30;
}
.integration-select-btn {
  display: flex;
  text-transform: none;
  gap: 0.5rem;
  align-items: center;

  &--bold {
    font-weight: 700;
  }

  &__icon {
    color: variables.$periwink-blue-60;
    margin-left: auto;
  }
}
</style>
