import { unref } from 'vue';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from '../query-keys';
import { applyCoupon } from '@/api/reservation';

/**
 *
 * @param {number | Ref<number>} reservationId
 */
export function useApplyCoupon(reservationId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ coupon, orderId }) =>
      applyCoupon(unref(reservationId), coupon, orderId),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.reservations(),
      });
    },
  });
}
