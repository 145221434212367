<script setup>
import { computed, ref } from 'vue';
import ProServiceSelectionFilter from './ProServiceSelectionFilter.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';
import { useGetTagFollowUpCategories } from '@/queries/useGetTagFollowUpCategories';

const props = defineProps({
  mainTag: Object,
  modelValue: Array,
  confirmButtonCopy: String,
});
const emits = defineEmits([
  'update:modelValue',
  'clearFilters',
  'confirmClicked',
  'cancelClicked',
]);
const mainTag = computed(() => props.mainTag);
const modelValue = computed(() => props.modelValue);
const confirmButtonCopy = computed(() => props.confirmButtonCopy);

const { data: tagFollowUpCategories } = useGetTagFollowUpCategories(
  computed(() => props.mainTag.id),
  {
    enabled: computed(() => !!props.mainTag),
  }
);
const isSizesOpen = ref(false);
const filteredSortedTagCategories = computed(() => {
  return tagFollowUpCategories.value?.filter(
    tc =>
      tc.title !== 'manicure' &&
      tc.title !== 'manicure color' &&
      tc.title !== 'pronoun'
  );
});
const basicInfoTagCategories = computed(() => {
  return filteredSortedTagCategories.value.filter(
    tc => tc.special_category === 'psp_trait_basic_info'
  );
});
const sizeTagCategories = computed(() => {
  return filteredSortedTagCategories.value.filter(
    tc => tc.special_category === 'psp_trait_size'
  );
});
const talentTagCategories = computed(() => {
  return filteredSortedTagCategories.value.filter(
    tc => tc.special_category === 'psp_trait_talent'
  );
});
function toggleSizesOpen() {
  isSizesOpen.value = !isSizesOpen.value;
}
function clearFilters(event) {
  event.subContext = `${mainTag.value.title} filters modal`;
  emits('clearFilters', event);
}
</script>
<template>
  <div class="filter-modal">
    <SoonaDialog
      aria-label="pro service provider details"
      @close="event => emits('cancelClicked', event)"
    >
      <template #heading> {{ mainTag.title }}</template>
      <template #default>
        <div
          v-for="tagCategory in basicInfoTagCategories"
          :key="tagCategory.id"
        >
          <div>
            <ProServiceSelectionFilter
              :model-value="modelValue"
              :pro-service-provider-type="mainTag.title"
              :tag-category="tagCategory"
              :type="tagCategory.options.filter_style"
              @update:model-value="emits('update:modelValue', $event)"
            />
          </div>
          <hr />
        </div>
        <div v-if="sizeTagCategories.length > 1">
          <button
            id="pro-service-filter-expand-sizes"
            class="sizes-header-button u-button-reset"
            :aria-expanded="isSizesOpen"
            aria-controls="pro-service-filter-expand-region-sizes"
            @click="toggleSizesOpen"
          >
            clothing sizes (top, bottom, shoe, bra)
            <SoonaIcon class="header-chevron" name="chevron-down" />
          </button>
          <hr />
          <div
            id="pro-service-filter-expand-region-sizes"
            class="sizes-container"
            aria-labelledby="pro-service-filter-expand-sizes"
            role="region"
          >
            <template v-if="isSizesOpen">
              <div
                v-for="tagCategory in sizeTagCategories"
                :key="tagCategory.id"
              >
                <div>
                  <ProServiceSelectionFilter
                    :model-value="modelValue"
                    :pro-service-provider-type="mainTag.title"
                    :tag-category="tagCategory"
                    :type="tagCategory.options.filter_style"
                    parent-category="clothing sizes (top, bottom, shoe, bra)"
                    @update:model-value="emits('update:modelValue', $event)"
                  />
                </div>
                <hr />
              </div>
            </template>
          </div>
        </div>
        <div v-else>
          <div v-for="tagCategory in sizeTagCategories" :key="tagCategory.id">
            <div>
              <ProServiceSelectionFilter
                :model-value="modelValue"
                :pro-service-provider-type="mainTag.title"
                :tag-category="tagCategory"
                :type="tagCategory.options.filter_style"
                @update:model-value="emits('update:modelValue', $event)"
              />
            </div>
            <hr />
          </div>
        </div>
        <div v-for="tagCategory in talentTagCategories" :key="tagCategory.id">
          <div>
            <ProServiceSelectionFilter
              :model-value="modelValue"
              :pro-service-provider-type="mainTag.title"
              :tag-category="tagCategory"
              :type="tagCategory.options.filter_style"
              @update:model-value="emits('update:modelValue', $event)"
            />
          </div>
          <hr />
        </div>
      </template>
      <template #footer>
        <SoonaButton
          variation="tertiary"
          copy="clear all"
          :on-click="clearFilters"
          size="medium"
        />
        <SoonaButton
          :copy="confirmButtonCopy"
          @on-click="event => emits('confirmClicked', event)"
        />
      </template>
    </SoonaDialog>
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables';

.filter-modal {
  :deep(.modal-background) {
    overflow: hidden;
  }

  hr {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    color: variables.$bingo-blue-light;
    height: 0.063rem;
    border: 0.031rem solid variables.$bingo-blue-light;
    max-width: 95%;
  }
}

.sizes-header-button {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: space-between;
  width: 95%;
  padding: 0.5rem 0;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 0.25rem;

  .header-chevron {
    margin-left: 5px;
    color: variables.$gray-90;
    transition: transform 300ms ease-out;
    cursor: pointer;
  }
}

.sizes-header-button[aria-expanded='true'] {
  .header-chevron {
    transform: rotate(180deg);
  }
}

.sizes-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 5%;
}

.sizes-header-button[aria-expanded='false'] ~ .sizes-container {
  display: none;
}
</style>
