<script setup>
import { computed, ref } from 'vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import basicImage from '@images/subscriptions/basic_graphic@2x.png';
import businessImage from '@images/subscriptions/business_graphic@2x.png';
import proImage from '@images/subscriptions/pro_graphic@2x.png';
import trialImage from '@images/subscriptions/mokkerinaction-ezgif.com-optimize.gif';
import FeatureList from '@/components/subscriptions/FeatureList.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';
import StepLayout from '@/components/modal-payment-flows/subscriptions/StepLayout.vue';

const props = defineProps({
  context: {
    default: null,
    type: String,
  },
  hasActiveSubscription: {
    default: false,
    type: Boolean,
  },
  headingId: {
    default: 'subscriptions-dialog-label',
    type: String,
  },
  isLoading: {
    default: false,
    type: Boolean,
  },
  selectedTier: {
    default: () => ({}),
    type: Object,
  },
  showBackButton: {
    default: false,
    type: Boolean,
  },
  subContext: {
    required: true,
    type: String,
  },
  offerTrial: {
    default: false,
    type: Boolean,
  },
});

const emits = defineEmits(['back', 'close', 'next']);

const { linkClicked } = useBaseEvents();

const imageMap = {
  'tier-one': basicImage,
  'tier-two': proImage,
  'tier-three': businessImage,
  trial: trialImage,
};

const trialDays = ref(30);
const isLoading = computed(() => props.isLoading);
const showBackButton = computed(() => props.showBackButton);
const tierFeatureList = computed(
  () => props.selectedTier?.product?.long_feature_list
);
const tierHeading = computed(() => props.selectedTier?.product?.heading);
const tierSubheading = computed(() => props.selectedTier?.product?.subheading);
const tierTitle = computed(() => props.selectedTier?.name);
const tierIcon = computed(() => props.selectedTier?.product.icon_name);
const tierImage = computed(
  () =>
    imageMap[
      props.offerTrial && props.selectedTier?.slug == 'tier-one'
        ? 'trial'
        : props.selectedTier?.slug
    ]
);

const tierRightColumnHeading = computed(
  () => props.selectedTier?.product?.right_column_heading
);
const tierRightColumnSubheading = computed(
  () => props.selectedTier?.product?.right_column_subheading
);
const tierSlug = computed(() => props.selectedTier?.slug);

const headingCopy = computed(() => {
  if (tierHeading.value) return tierHeading.value;
  return `upgrade to soona ${tierTitle.value}`;
});
const nextButtonCopy = computed(() => {
  if (props.offerTrial) return `try it free for ${trialDays.value} days`;
  return 'get started today';
});

const handleContinueBtnClick = () => {
  linkClicked({
    context: props.context,
    subContext: props.subContext,
    linkLabel: nextButtonCopy.value,
    linkHref: null,
  });

  if (props.hasActiveSubscription) emits('next', 'checkout');
  else emits('next', 'billing-cycle');
};

const handleAllPlansBtnClick = () => {
  linkClicked({
    context: props.context,
    subContext: props.subContext,
    linkLabel: 'see all plans',
    linkHref: null,
  });

  emits('next', 'pricing');
};
</script>

<template>
  <StepLayout
    class="landing-step"
    :heading-id="headingId"
    :show-back-button="showBackButton"
    :tier-icon="tierIcon"
    :tier-slug="tierSlug"
    :tier-title="tierTitle"
    @back="emits('back')"
    @close="emits('close')"
    @next="emits('next')"
  >
    <template #heading>{{ headingCopy }}</template>
    <template #subheading>{{ tierSubheading }}</template>
    <SoonaLoading v-if="isLoading" is-loading is-contained />
    <div class="landing-step__features">
      <FeatureList :feature-list="tierFeatureList" />
    </div>
    <div class="landing-step__action-btns">
      <SoonaButton
        :copy="nextButtonCopy"
        variation="solid-black"
        data-cypress="button-subscriptions-dialog-continue"
        @on-click="handleContinueBtnClick"
      />
      <SoonaButton
        v-if="!hasActiveSubscription"
        copy="see all plans"
        variation="secondary-black"
        @on-click="handleAllPlansBtnClick"
      />
    </div>
    <template #main-image>
      <img
        class="landing-step__star-top"
        src="@images/subscriptions/north_star.svg"
        alt=""
      />
      <img
        class="landing-step__star-bottom"
        src="@images/subscriptions/north_star.svg"
        alt=""
      />
      <img :class="offerTrial && 'round-borders'" :src="tierImage" alt="" />
    </template>
    <template #right-col-content>
      <div class="landing-step__right-column-content">
        <p class="u-display--heavy landing-step__right-column-heading">
          {{ tierRightColumnHeading }}
        </p>
        <p class="u-body--regular">{{ tierRightColumnSubheading }}</p>
      </div>
    </template>
  </StepLayout>
</template>

<style lang="scss" scoped>
@use '@/variables';

.landing-step {
  &__includes-heading {
    padding-bottom: 1rem;
  }

  &__features {
    flex-grow: 1;
  }

  &__action-btns {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: auto;
    padding-top: 2rem;
  }

  &__star-top {
    position: absolute;
    left: 1rem;
    top: 2rem;
    height: 4.9375rem !important;
    width: 4.9375rem !important;
  }

  &__star-bottom {
    position: absolute;
    right: 1.5rem;
    bottom: 3rem;
    height: 5.625rem !important;
    width: 5.625rem !important;
  }

  &__right-column-content {
    color: variables.$white-default;
  }

  &__right-column-heading {
    padding-bottom: 0.25rem;
  }

  & .round-borders {
    border-radius: 0.5rem;
  }
}
</style>
