<script setup>
import { computed, toRef } from 'vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import { useUpdateAccount } from '@/queries/account/useUpdateAccount';

const props = defineProps({
  accountId: {
    type: [Number, String],
    required: true,
  },
  enjoys: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(['close']);

const shopifyUserEnjoysApp = computed(() => props.enjoys);

const { mutate: updateAccount } = useUpdateAccount(toRef(props, 'accountId'));

const close = () => {
  emit('close');
};

const sendShopifyReview = () => {
  updateAccount(
    {
      shopify_review: { response: shopifyUserEnjoysApp.value ? 'yes' : 'no' },
    },
    {
      onSuccess: () => {
        const redirectUrl = shopifyUserEnjoysApp.value
          ? import.meta.env.VITE_SHOPIFY_POS_FEEDBACK_URL
          : import.meta.env.VITE_SHOPIFY_NEG_FEEDBACK_URL;
        window.open(redirectUrl, '_blank', 'noopener');
        close();
      },
    }
  );
};

const shopifyReviewModalHeader = computed(() => {
  return shopifyUserEnjoysApp.value
    ? 'thank you for your support'
    : 'share your thoughts!';
});

const shopifyReviewModalText = computed(() => {
  return shopifyUserEnjoysApp.value
    ? "YAY! we're glad the app is helping make your ecomm dreams come true. please spread the word by leaving a Shopify app review."
    : 'we want to learn from your experience. help us improve our app with your feedback.';
});

const shopifyReviewConfirmText = computed(() => {
  return shopifyUserEnjoysApp.value ? 'leave a review' : 'share feedback';
});
</script>
<template>
  <SoonaDialog class="shopify-review-dialog" @close="close">
    <template #heading>{{ shopifyReviewModalHeader }}</template>
    <template #default>
      <div class="shopify-review-dialog--inner">
        <SoonaIcon class="shopify-review-dialog--icon" name="heart-duotone" />
        <span>{{ shopifyReviewModalText }}</span>
      </div>
    </template>
    <template #footer>
      <SoonaButton
        type="button"
        variation="tertiary"
        size="medium"
        @click="close"
      >
        cancel
      </SoonaButton>
      <SoonaButton type="button" @click="sendShopifyReview">
        {{ shopifyReviewConfirmText }}
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>
<style lang="scss" scoped>
@use '@/variables';

.shopify-review-dialog {
  &--icon {
    color: variables.$friendly-red-50;
    width: 36px;
    height: 36px;
  }
  &--inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
}
</style>
