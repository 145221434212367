<script setup>
import uniqueId from 'lodash/uniqueId';

defineProps({
  heading: {
    type: String,
    required: true,
  },
  hideHeading: {
    type: Boolean,
    default: false,
  },
});
const id = uniqueId('pack-sub-section');
</script>

<template>
  <section :aria-labelledby="id" class="pack-sub-section">
    <h3
      :id="id"
      class="pack-sub-section__heading"
      :class="{ 'u-a11y-only': hideHeading }"
    >
      {{ heading }}
    </h3>

    <div class="pack-sub-section__content">
      <slot name="default" />
    </div>
  </section>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.pack-sub-section {
  border-bottom: 0.0625rem solid variables.$gray-30;
  padding: 1.5rem;

  &__heading {
    @include variables_fonts.u-title--heavy;

    margin-bottom: 1.5rem;
  }
}
</style>
