<script setup>
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

defineProps({
  closable: {
    type: Boolean,
    default: false,
  },
  // TODO: eventually move all notifications to noMargin and then eliminate the prop
  noMargin: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(['close-notification']);
</script>

<template>
  <div
    class="soona-notification"
    :class="{ 'soona-notification--no-margin': noMargin }"
  >
    <slot name="icon" />
    <p class="u-body--regular soona-notification__message">
      <slot />
    </p>
    <SoonaButton
      v-if="closable"
      class="soona-notification__close"
      size="medium"
      variation="icon-transparent"
      @click="emits('close-notification')"
    >
      <SoonaIcon name="xmark" />
      <span class="u-a11y-only">close notification message</span>
    </SoonaButton>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.soona-notification {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.5rem;
  border-radius: 0.3125rem;
  border: 0.0625rem solid variables.$gray-30;
  background-color: variables.$gray-10;
  margin: 1rem 0;
  padding: 0.625rem;
  opacity: 0;
  animation: 0.2s ease-out both k-fade-in;

  &--no-margin {
    margin: 0;
  }

  :slotted(> svg) {
    padding: 0.125rem 0;
    flex: 0 0 1.25rem;
    height: 1.5rem;
  }

  &__message {
    flex-grow: 1;

    :slotted(a) {
      color: inherit;
      text-decoration: underline;

      &:hover,
      &:focus-visible {
        color: inherit;
        text-decoration: none;
      }
    }
  }

  &__close {
    color: variables.$gray-60;
  }
}
</style>
