<script setup>
import { ref, computed, watch, watchEffect } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaTextfield from 'src/components/ui_library/SoonaTextfield.vue';
import SoonaSelect from 'src/components/ui_library/SoonaSelect.vue';
import InventoryCard from '../inventory/InventoryCard.vue';
import SoonaError from 'src/components/ui_library/SoonaError.vue';
import SoonaLoading from 'src/components/SoonaLoading.vue';
import { useInventory } from '@/composables/useInventory';
import { useAccount } from '@/composables/useAccount';
import { useCapability } from 'src/composables/useCapability';
import { useCheckForDuplicatePackages } from '@/queries/inventory/useInventoryPackages';
import { useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from 'src/queries/query-keys';

const props = defineProps({
  reservationId: Number,
});

const emit = defineEmits([
  'closeTrackingNumberModal',
  'openReturnAddressCloseTrackingNumberDialog',
]);

const router = useRouter();
const queryClient = useQueryClient();

// vuex
const store = useStore();
const getProviders = computed(() => store.getters['reservation/getProviders']);
const currentReservation = computed(
  () => store.getters['reservation/currentReservation']
);
const accountId = computed(() => store.state.account.id);

// capabilities
const { hasCapability: hasManageFastpassCapability } = useCapability({
  capability: 'manage_fastpass',
  subjectType: 'account',
  subjectId: accountId.value,
});

const { hasCapability: hasSoonaStaffCapability } = useCapability({
  capability: 'soona_staff',
});

// soona storage
const { hasSoonaStorage } = useAccount(accountId);

// modal functionality module
const error = ref(undefined);

// add package module
const trackingNumber = ref('');
const submittedTrackingNumber = ref(undefined);
const packageContents = ref(undefined);

// post shoot options module
const { postShootOptions } = useInventory();

const orderedPostShootOptions = computed(() => {
  const removeAlreadyStored = postShootOptions.value?.filter(
    pso => pso.value !== 'already_stored'
  );

  if (!hasSoonaStorage.value && !hasManageFastpassCapability.value) {
    return removeAlreadyStored.filter(pso => pso.value !== 'store_at_soona');
  } else {
    return removeAlreadyStored;
  }
});

const initialPostShootOption = computed(
  () =>
    orderedPostShootOptions.value.find(
      pso => pso.value === currentReservation.value.package_option
    ) ?? undefined
);

const selectedPostShootOption = ref(initialPostShootOption.value?.value);

const postShootOptionDescription = computed(() => {
  if (selectedPostShootOption.value === 'store_at_soona') {
    return 'soona will store this package.';
  }
  if (selectedPostShootOption.value === 'return_shipping') {
    return '$20 handling + cost of shipping label applies.';
  }
  if (selectedPostShootOption.value === 'discard') {
    return 'soona will safely discard your product in a way that protects your brand.';
  }
  return '';
});

// shipping provider module
const customProvider = ref(undefined);

const selectedProvider = ref(undefined);

const selectedProviderName = computed(() => {
  return (
    getProviders.value?.find(x => x.id === selectedProvider.value)?.name ?? ''
  );
});

const isDropOff = computed(() => {
  return selectedProviderName.value === 'drop off';
});

const providerOptions = computed(() =>
  getProviders.value.map(x => {
    return { value: x.id, label: x.name };
  })
);

watch(selectedProviderName, (newData, oldData) => {
  if (
    oldData === '' &&
    newData &&
    error.value === 'please select a shipping provider'
  ) {
    error.value = null;
  }
  if (newData === 'drop off' || oldData === 'drop off') {
    trackingNumber.value = undefined;
  }
  if (newData !== 'other') {
    customProvider.value = undefined;
  }
});

const clearModalContents = async () => {
  trackingNumber.value = undefined;
  submittedTrackingNumber.value = undefined;
  customProvider.value = undefined;
  selectedProvider.value = undefined;
  packageContents.value = undefined;
  error.value = undefined;
  await queryClient.invalidateQueries({
    queryKey: queryKeys.duplicatePackages(accountId.value),
  });
};

const closeModal = async () => {
  await clearModalContents();
  emit('closeTrackingNumberModal');
};

const {
  data: duplicatePackagesData,
  status,
  isFetching,
  error: errorCheckingDuplicate,
  refetch,
} = useCheckForDuplicatePackages({
  accountId: accountId,
  trackingNumber: submittedTrackingNumber,
});

const duplicate = computed(() => {
  if (duplicatePackagesData.value?.length > 0) {
    return duplicatePackagesData.value[0];
  } else {
    return null;
  }
});

const buttonText = computed(() => {
  if (
    (errorCheckingDuplicate.value ||
      isFetching.value ||
      !submittedTrackingNumber.value) &&
    !isDropOff.value
  ) {
    return 'continue';
  }
  if (isDropOff.value && hasSoonaStaffCapability.value) {
    return 'continue';
  }
  if (submittedTrackingNumber.value && duplicate.value) {
    return hasSoonaStaffCapability.value ? 'edit' : 'view inventory';
  }
  return 'save details';
});

const reservationId = computed(() => props.reservationId);

const shippingDetailAttributes = computed(() => {
  return {
    reservation_id: reservationId.value,
    shipping_provider: !customProvider.value
      ? selectedProviderName.value
      : customProvider.value,
    tracking_number: trackingNumber.value,
    shipping_direction: 'inbound',
  };
});

const createPackageAsStaff = async () => {
  const response = await store.dispatch('inventoryPackages/createPackage', {
    packageAttributes: {
      reservation_id: reservationId.value,
      shipping_details_attributes: [shippingDetailAttributes.value],
    },
  });
  const newPackage = response[0];
  return (window.location.href = `/user/anytime#/account/${accountId.value}/package/${newPackage.id}/edit?reservationId=${reservationId.value}`);
};

watchEffect(() => {
  if (
    hasSoonaStaffCapability.value &&
    duplicatePackagesData.value &&
    status.value === 'success' &&
    !duplicate.value
  ) {
    createPackageAsStaff();
  }
});

const addPackage = async () => {
  if (hasSoonaStaffCapability.value && isDropOff.value) {
    createPackageAsStaff();
    return;
  }
  if (errorCheckingDuplicate.value && !isDropOff.value) {
    refetch();
    return;
  }
  if (!selectedProvider.value) {
    return (error.value = 'please select a shipping provider');
  }
  if (!trackingNumber.value && !isDropOff.value) {
    return (error.value = 'please add a tracking number');
  }
  if (!isDropOff.value && status.value !== 'success') {
    error.value = null;
    submittedTrackingNumber.value = trackingNumber.value;
    return;
  }

  if (duplicate.value) {
    if (hasSoonaStaffCapability.value) {
      router.push({
        path: `/account/${accountId.value}/package/${duplicate.value.id}/edit?reservationId=${reservationId.value}`,
      });
    } else {
      router.push({
        path: `/account/${accountId.value}/products/inventory`,
      });
    }
  }

  const params = {
    reservation_id: reservationId.value,
    description: packageContents.value,
    post_shoot_option: selectedPostShootOption.value,
    shipping_details_attributes: [shippingDetailAttributes.value],
  };

  // regex to check if trackingNumber is valid
  const urlPattern = /^(http|https)/;
  if (urlPattern.test(trackingNumber.value)) {
    return (error.value = 'invalid tracking number');
  } else if (
    !hasSoonaStorage.value &&
    selectedPostShootOption.value === 'store_at_soona'
  ) {
    await store.commit('inventoryPackages/SET_CURRENT_PACKAGE', params);

    router.push({
      path: `/account/${accountId.value}/subscriptions/add-ons/fast-pass`,
      query: { reservation_id: reservationId.value, add_package: true },
    });
    return;
  } else if (
    isDropOff.value &&
    packageContents.value != undefined &&
    selectedPostShootOption.value != undefined
  ) {
    await store.dispatch('inventoryPackages/createPackage', {
      packageAttributes: params,
    });
    await queryClient.invalidateQueries({
      queryKey: queryKeys.reservationReadinessSteps(
        computed(() => props.reservationId)
      ),
    });
  } else if (
    trackingNumber.value != undefined &&
    trackingNumber.value.length > 0 &&
    packageContents.value != undefined &&
    (selectedProvider.value != undefined ||
      customProvider.value != undefined) &&
    selectedPostShootOption.value != undefined
  ) {
    await store.dispatch('inventoryPackages/createPackage', {
      packageAttributes: params,
    });
    await queryClient.invalidateQueries({
      queryKey: queryKeys.reservationReadinessSteps(
        computed(() => props.reservationId)
      ),
    });
  } else {
    if (!packageContents.value) {
      return (error.value = 'please enter package contents');
    } else if (!selectedPostShootOption.value) {
      return (error.value = 'please select a post shoot option');
    } else {
      return (error.value = 'please try again');
    }
  }

  if (selectedPostShootOption.value === 'return_shipping') {
    emit('openReturnAddressCloseTrackingNumberDialog');
  } else {
    closeModal();
  }
};
</script>

<template>
  <SoonaDialog @close="closeModal">
    <template #heading>package details</template>
    <div class="provider-tracking-wrapper">
      <SoonaLoading
        v-if="isFetching && !isDropOff"
        :is-contained="true"
        :is-loading="isFetching"
        loading-text="loading"
      />
      <div
        class="select-provider-wrapper"
        :class="isDropOff ? 'add-padding-bottom' : ''"
      >
        <SoonaSelect
          v-model:model-value="selectedProvider"
          placeholder="provider"
          :options="providerOptions"
          :disabled="!!submittedTrackingNumber && !errorCheckingDuplicate"
          data-cypress="select-delivery-method"
        >
          <template #label>delivery method</template>
        </SoonaSelect>
        <SoonaTextfield
          v-if="selectedProviderName === 'other'"
          v-model:model-value="customProvider"
          class="custom-provider-input"
          label="custom provider"
          type="text"
          placeholder="enter your provider"
        />
      </div>
      <div v-if="!isDropOff" class="tracking-wrapper">
        <SoonaTextfield
          v-model:model-value="trackingNumber"
          label="tracking number"
          type="text"
          :maxlength="60"
          :disabled="!!submittedTrackingNumber && !errorCheckingDuplicate"
          placeholder="e.g. 1233455"
          data-cypress="input-tracking-number"
        />
      </div>
    </div>
    <div
      v-if="
        ((status === 'success' && !duplicate) || isDropOff) &&
        !hasSoonaStaffCapability
      "
      class="package-details"
    >
      <SoonaTextfield
        v-model:model-value="packageContents"
        label="package contents"
        element="textarea"
        :maxlength="160"
        placeholder="e.g. perfume bottles, fake plants"
        data-cypress="input-package-contents"
      />
      <div>
        <SoonaSelect
          v-model:model-value="selectedPostShootOption"
          placeholder="choose one"
          :options="orderedPostShootOptions"
          label="addPackageTitle"
        >
          <template #label>post shoot option</template>
        </SoonaSelect>
        <span class="add-package-info">
          {{ postShootOptionDescription }}
        </span>
      </div>
    </div>
    <div v-if="duplicate">
      <SoonaError>
        there is already a package for that tracking number.
      </SoonaError>
      <InventoryCard
        :inventory-package="duplicate"
        :account-id="accountId"
        :show-return-select="false"
      />
    </div>
    <SoonaError v-if="errorCheckingDuplicate">
      oops, something went wrong on our end. please try again.
    </SoonaError>
    <span v-if="error" class="has-text-danger">
      {{ error }}
    </span>
    <template #footer="{ close }">
      <SoonaButton variation="tertiary" @on-click="close">cancel</SoonaButton>
      <SoonaButton data-cypress="button-dialog-confirm" @on-click="addPackage">
        {{ buttonText }}
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.provider-tracking-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 0.5rem;
  padding: 0;

  .select-provider-wrapper {
    flex: 1 1 30%;
    .custom-provider-input {
      padding-top: 3px;
    }
    :deep(.select-label-wrapper label) {
      padding-top: 0 !important;
      white-space: nowrap;
    }
  }

  .add-padding-bottom {
    padding-bottom: 1.9375rem;
  }
  .tracking-wrapper {
    flex: 2 1 70%;
    .tracking-number {
      border: none !important;
      padding: 0;
    }
  }
}

label {
  @include variables_fonts.u-label--heavy;
}

.add-package-info {
  font-size: 14px;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 20px;
}
</style>
