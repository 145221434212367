<script setup>
import { computed, toRef } from 'vue';
import SoonaExpansionPanel from '@/components/ui_library/SoonaExpansionPanel.vue';
import AccordionHeaderIcon from '@/components/user/anytime/listing_insights/listing_detail/accordions/AccordionHeaderIcon.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaBlogPostLink from '@/components/ui_library/SoonaBlogPostLink.vue';
import VisualMixRuleResult from './VisualMixRuleResult.vue';
import SoonaHelperText from '@/components/ui_library/SoonaHelperText.vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useRoute } from 'vue-router';
import { useListingDetailAccordion } from '@/components/user/anytime/listing_insights/listing_detail/accordions/useListingDetailAccordion';
import AiDisclaimer from '@/components/user/anytime/listing_insights/listing_detail/accordions/AiDisclaimer.vue';

const props = defineProps({
  accountId: { type: [String, Number], default: undefined }, // accountId is undefined when accessed from temporary listing insights
  actions: { type: Array, default: () => [] },
  data: { type: Object, required: true },
  assets: { type: Array, required: true },
  platform: {
    type: String,
    required: true,
    validator: function (value) {
      return ['Amazon', 'Shopify'].includes(value);
    },
  },
});

defineEmits(['showSubscriptionsDialog']);

const route = useRoute();

const { hasBrokenRules, linkTo, linkTitle, linkImage } =
  useListingDetailAccordion(
    toRef(() => props.data),
    toRef(() => props.platform)
  );

const { linkClicked } = useBaseEvents();

const trackLinkClicked = () => {
  linkClicked({
    context: route.meta.context,
    subContext: 'single listing overview - visual mix',
    linkLabel: `Learn More - ${linkTitle.value}`,
    linkHref: linkTo,
  });
};

const productOnWhiteCombinedRule = computed(() => {
  if (props.platform !== 'Amazon') {
    return null;
  }
  let result = {
    ...props.data?.rules_result.rules.find(
      rule => rule.rule === 'product on white'
    ),
  };
  result['main_image_product_on_white'] = props.data?.rules_result.rules.find(
    rule => rule.rule === 'main image product on white'
  );

  result['product_on_white_score'] = result.score;
  result['main_image_product_on_white_score'] =
    result.main_image_product_on_white?.score;

  result.score =
    result.main_image_product_on_white_score === 0 ? 0 : result.score;

  return result;
});

const visualMixRules = computed(() => {
  let result = props.data?.rules_result.rules.filter(
    rule =>
      rule.rule !== 'product on white' &&
      rule.rule !== 'main image product on white'
  );
  if (productOnWhiteCombinedRule.value) {
    result.unshift(productOnWhiteCombinedRule.value);
  }
  return result;
});

const passingRules = computed(() =>
  visualMixRules.value.filter(x => x.score === 1)
);

const failingRules = computed(() =>
  visualMixRules.value.filter(
    x => x.score < 1 && x.rule !== 'consistent color scheme'
  )
);

// listing actions
const actions = computed(() => props.actions ?? []);

const getListingAction = failingRule => {
  if (failingRule.main_image_product_on_white) {
    return actions.value.find(
      action =>
        failingRule.main_image_product_on_white.rule_slug ===
        action.listing_rule_slug
    );
  }
  return actions.value.find(
    action => failingRule.rule_slug === action.listing_rule_slug
  );
};
</script>

<template>
  <SoonaExpansionPanel>
    <template #header>
      <span class="visual-mix__header--wrapper">
        <AccordionHeaderIcon icon-name="stars" :status="data.score.label" />
        <span class="visual-mix__header-content">
          <span class="visual-mix__header-title">visual mix</span>
          <span class="visual-mix__header-actions">
            <span v-if="hasBrokenRules" class="visual-mix__header-action">
              <SoonaIcon
                name="status-dot-small"
                size="small"
                class="visual-mix__header-action-icon--fail"
              />
              <span class="visual-mix__header-action-text">
                {{ failingRules.length }}
                {{
                  failingRules.length === 1 ? 'opportunity' : 'opportunities'
                }}
              </span>
            </span>
            <span v-else class="visual-mix__header-action">
              <SoonaIcon
                name="circle-check-solid"
                size="small"
                class="visual-mix__header-action-icon--pass"
              />
              <span class="visual-mix__header-action-text">
                looking sharp!
              </span>
            </span>
          </span>
        </span>
      </span>
    </template>

    <template #panel>
      <div class="visual-mix__panel--wrapper">
        <div class="visual-mix__panel-images">
          <div
            v-for="image in assets"
            :key="image.digital_asset_id"
            class="visual-mix__panel-image"
          >
            <SoonaHelperText
              v-if="image.cv_labels && image.cv_labels.length > 0"
              class="visual-mix__panel-image-tooltip"
            >
              {{ image.cv_labels.join(', ') }}
            </SoonaHelperText>
            <img :src="image.digital_asset_url" alt="" />
          </div>
        </div>
        <div
          v-if="failingRules.length > 0"
          class="visual-mix__panel-section visual-mix__panel--fail"
        >
          <h3 class="u-subheader--heavy">opportunities</h3>
          <ul>
            <VisualMixRuleResult
              v-for="(failingRule, i) in failingRules"
              :key="i"
              :account-id="accountId"
              :listing-action="getListingAction(failingRule)"
              :rule="failingRule"
              @show-subscriptions-dialog="
                $emit('showSubscriptionsDialog', $event)
              "
            />
          </ul>
        </div>
        <div
          v-if="passingRules.length > 0"
          class="visual-mix__panel-section visual-mix__panel--pass"
        >
          <h3 class="u-subheader--heavy">strengths</h3>
          <ul>
            <VisualMixRuleResult
              v-for="(passingRule, i) in passingRules"
              :key="i"
              :account-id="accountId"
              :rule="passingRule"
              :pass="true"
            />
          </ul>
        </div>
        <AiDisclaimer />
        <div class="visual-mix__panel-blog-link">
          <p>learn more</p>
          <SoonaBlogPostLink
            :image-src="linkImage"
            :title="linkTitle"
            :to="linkTo"
            @link-clicked="trackLinkClicked"
          />
        </div>
      </div>
    </template>
  </SoonaExpansionPanel>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.visual-mix {
  &__header {
    &--wrapper {
      display: flex;
      align-items: center;
      gap: 1rem;
    }

    &-content {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
    }

    &-title {
      @include variables_fonts.u-badge--small;
      color: variables.$gray-60;
    }

    &-action {
      @include variables_fonts.u-body--heavy;

      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.25rem;

      &-icon {
        &--fail {
          color: variables.$roses-60;
        }
        &--pass {
          color: variables.$avo-toast-40;
        }
      }

      &-text {
        text-wrap: wrap;
      }
    }
  }

  &__panel {
    &--wrapper {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    &-images {
      display: flex;
      flex-flow: row wrap;
      gap: 0.5rem;
      padding-bottom: 1rem;
    }

    &-image {
      width: 5rem;
      height: 5rem;
      flex: 0 0 5rem;

      img {
        border-radius: 0.3125rem;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }

      &-tooltip {
        position: absolute;
      }
    }

    &-section {
      padding: 1rem 0;

      h3 {
        padding-bottom: 0.75rem;
      }

      ul {
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
      }
    }

    &-blog-link {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
      margin-top: 1rem;

      p {
        @include variables_fonts.u-badge--big;

        color: variables.$gray-60;
      }
    }
  }

  // desktop
  // screen-sm-min = 768px
  @media (min-width: variables.$screen-sm-min) {
    &__panel {
      &--wrapper {
        padding: 1rem;
      }
    }
  }
}
</style>
