<script setup>
import { onMounted, computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { onKeyStroke, useTimeoutFn, useMediaQuery } from '@vueuse/core';
import TitleBar from 'src/components/shared/TitleBar.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import AdditionalOrderSidecart from './AdditionalOrderSidecart.vue';
import AccountOrderBuilder from './AccountOrderBuilder.vue';

const props = defineProps({
  accountId: {
    type: String,
    required: false,
  },
});

onMounted(async () => {
  window.scrollTo({ top: 0 });
});

const store = useStore();
const router = useRouter();
const previousPage = () => {
  router.back();
};
const accountId = computed(() => props.accountId);
const productsInCart = computed(() => store.getters['account/productsInCart']);
const errorMessage = ref('');
const compedOrder = ref(false);
function setCompOrder(option) {
  compedOrder.value = option;
}
const sidecartIsAffixed = useMediaQuery('(max-width: 59.9375rem)');
const sidecartOpen = ref(false);
const sidecartClosing = ref(false);
const { start: closeSidecart, stop: stopCloseSidecart } = useTimeoutFn(
  () => {
    sidecartOpen.value = !sidecartOpen.value;
    sidecartClosing.value = false;
  },
  300,
  { immediate: false }
);
const toggleSidecart = () => {
  if (!sidecartOpen.value || sidecartClosing.value) {
    sidecartClosing.value = false;
    stopCloseSidecart();
    sidecartOpen.value = !sidecartOpen.value;
    return;
  }
  sidecartClosing.value = true;
  closeSidecart();
};
onKeyStroke('Escape', () => {
  if (sidecartOpen.value && !sidecartClosing.value) {
    toggleSidecart();
  }
});
function incrementProduct(product) {
  store.dispatch('account/setAdditionalChargeItems', {
    [product.id]: product.quantity + 1,
  });
}
function decrementProduct(product) {
  if (product.quantity === 1) {
    store.dispatch('account/removeAdditionalChargeItem', product.id);
  } else {
    store.dispatch('account/setAdditionalChargeItems', {
      [product.id]: product.quantity - 1,
    });
  }
}
function setProductCount(product, newVal) {
  if (newVal === 0) {
    store.dispatch('account/removeAdditionalChargeItem', product.id);
  } else {
    store.dispatch('account/setAdditionalChargeItems', {
      [product.id]: newVal,
    });
  }
}
</script>

<template>
  <div class="account-additional-order">
    <section
      class="account-additional-order__content"
      aria-labelledby="account-additional-order-edit-heading"
    >
      <TitleBar
        heading-id="account-additional-order-edit-heading"
        title="create new order"
        :previous-page="previousPage"
      />

      <div class="account-additional-order__content__body">
        <SoonaError v-if="errorMessage">
          {{ errorMessage }}
        </SoonaError>
        <AccountOrderBuilder
          :account-id="Number(accountId)"
          @comp-order="setCompOrder"
        />
      </div>
    </section>
    <AdditionalOrderSidecart
      id="account-additional-order-sidecart"
      :products="productsInCart"
      :affixed="sidecartIsAffixed"
      class="account-additional-order__sidecart"
      :class="{
        'account-additional-order__sidecart--open': sidecartOpen,
        'account-additional-order__sidecart--closing': sidecartClosing,
      }"
      :toggle-sidecart="toggleSidecart"
      :comped-order="compedOrder"
      @click.self="toggleSidecart"
      @increment-product="incrementProduct"
      @decrement-product="decrementProduct"
      @set-product-count="setProductCount"
    />
    <button
      type="button"
      class="u-button-reset account-additional-order__sidecart-toggle"
      aria-controls="account-additional-order-sidecart"
      :aria-expanded="sidecartOpen"
      @click="toggleSidecart"
    >
      <span class="u-a11y-only">toggle </span>Order Summary
    </button>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.account-additional-order {
  &__sidecart-toggle {
    @include variables_fonts.u-small--heavy;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    border: 0.0625rem solid variables.$gray-30;
    border-right: 0;
    text-transform: uppercase;
    padding: 0.5rem 1rem;
    letter-spacing: 0.09375rem;
    border-radius: 0.625rem 0 0 0;
    background-color: variables.$white-default;
    transition: background-color 0.1s ease-out;
    > svg {
      display: block;
      margin-left: 0.5rem;
    }
    &:hover,
    &:focus-visible {
      background-color: variables.$gray-20;
    }
  }
  &__sidecart {
    position: fixed;
    z-index: 4;
    top: var(--app-header-viewport-offset);
    bottom: 0;
    right: 0;
    background-color: rgba(63, 67, 75, 0.75);
    animation: 0.2s ease-out both fade-in-sidecart-bg;
    &--open {
      display: flex;
      overflow: hidden;
    }
    &--closing {
      animation: 0.3s ease-out both fade-out-sidecart-bg;
    }
  }
}

@media (min-width: 60rem) {
  .account-additional-order {
    display: grid;
    grid-template-areas: 'content sidecart';
    grid-template-columns: 1fr min(50%, 27.625rem);
    grid-template-rows: 1fr;
    &__content {
      grid-area: content;
    }
    &__sidecart-toggle {
      display: none;
    }
    &__sidecart {
      grid-area: sidecart;
      /* reset to a more standard layout for use in the grid */
      position: static;
      display: block;
      background-color: rgba(63, 67, 75, 0);
      animation: none;
    }
  }
}
</style>
