<script setup>
import { computed, onMounted, ref } from 'vue';
import { refDebounced, useElementBounding, useTitle } from '@vueuse/core';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useInfiniteDigitalAssets } from '@/composables/useInfiniteDigitalAssets.js';
import { useInfiniteGalleryWrapper } from '@/components/infinite_asset_gallery/useInfiniteGalleryWrapper';
import { useShopMore } from '@/queries/shop_more/useShopMore';
import { useInfiniteShopMore } from '@/queries/shop_more/useInfiniteShopMore';
import { usePriorityErrors } from '@/composables/usePriorityErrors';
import AccountGalleryBreadcrumbs from '@/components/account_gallery/AccountGalleryBreadcrumbs.vue';
import DigitalAssetCard from '@/components/account_gallery/DigitalAssetCard.vue';
import EmptyGallery from '@/components/user/anytime/reservation/gallery/EmptyGallery.vue';
import InfiniteGallery from '@/components/infinite_asset_gallery/InfiniteGallery.vue';
import MultiSelectActionBar from '@/components/account_gallery/MultiSelectActionBar.vue';
import AccountGalleryEmptyState from '@/components/account_gallery/AccountGalleryEmptyState.vue';
import SearchBar from '@/components/account_gallery/SearchBar.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SortBy from '@/components/account_gallery/SortBy.vue';
import AccountInfoAuto from '@/components/user/anytime/dashboard/AccountInfoAuto.vue';
import { useBulkSelection } from '@/composables/digital_assets/useBulkSelection';
import { useMe } from '@/composables/user/useMe';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaFilterSingle from '@/components/ui_library/SoonaFilterSingle.vue';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
});

useTitle('shop more | soona');

const { pageViewed } = useBaseEvents();

const { currentAccountId: currentAuthedAccountId } = useMe();
const accountId = computed(() => +props.accountId);

const wrapper = ref(null);

const itemsPerPage = ref(12);
const searchText = ref('');
const searchDebounced = refDebounced(searchText, 200);
const selectedFilter = ref('all');
const selectedSort = ref('date modified');
const sortDirection = ref('desc');
const startPage = ref(1);
const isMyAccount = computed(
  () => currentAuthedAccountId.value === accountId.value
);

const { left, width } = useElementBounding(wrapper);

const { gutter, offsetTop, rowHeight, rowWidth } = useInfiniteGalleryWrapper({
  wrapperEl: wrapper,
  heightRem: 14,
  gapRem: 0.75,
});

const queryFilters = computed(() => {
  switch (selectedFilter.value) {
    case 'expert picks':
      return { collection_type: 'StaffPicksCollection' };
    case 'favorites':
      return { collection_type: 'FavoritesCollection' };
    case 'in bag':
      return { collection_type: 'BagCollection' };
    default:
      return {};
  }
});

const {
  assetRows,
  fetchPage,
  isLoading: isLoadingAssetRows,
  error,
  rawData,
} = useInfiniteDigitalAssets(accountId, {
  rowWidth,
  itemsPerPage,
  filters: queryFilters,
  gutter,
  height: rowHeight,
  query: useInfiniteShopMore,
  searchQuery: searchDebounced,
  sortBy: selectedSort,
  sortDirection: sortDirection,
});

const {
  data: facetsData,
  error: facetsError,
  isLoading: isLoadingFacets,
} = useShopMore(accountId, {
  facets: true,
  searchQuery: searchDebounced,
  itemsPerPage: 1,
  currentPage: 1,
});

const filterOptions = computed(() => [
  {
    value: 'all',
    label: 'all',
    count: facetsData.value?.pagination?.totalCount ?? 0,
  },
  {
    value: 'expert picks',
    label: 'expert picks',
    count: facetsData.value?.pagination?.staffPicksCount ?? 0,
    iconName: 'trophy',
    selectedIconName: 'trophy-solid',
  },
  {
    value: 'favorites',
    label: 'favorites',
    count: facetsData.value?.pagination?.favoritesCount ?? 0,
    iconName: 'heart',
    selectedIconName: 'heart-solid',
  },
  {
    value: 'in bag',
    label: 'in bag',
    count: facetsData.value?.pagination?.bagCount ?? 0,
    iconName: 'soona-bag',
    selectedIconName: 'soona-bag-solid',
  },
]);

const priorityErrors = usePriorityErrors(error, facetsError);

const isLoading = computed(
  () => isLoadingAssetRows.value || isLoadingFacets.value
);

const changeSortDirection = () => {
  if (sortDirection.value === 'desc') sortDirection.value = 'asc';
  else sortDirection.value = 'desc';
};

const { onSelectionClick, resetSelection, isAssetSelected, selectedAssets } =
  useBulkSelection(rawData, da => da);

onMounted(() => {
  pageViewed();
});
</script>

<template>
  <div class="all-shop-more">
    <AccountInfoAuto :account-id="accountId" />
    <AccountGalleryBreadcrumbs />
    <div class="all-shop-more__heading">
      <h1 class="u-title--heavy">shop more</h1>
    </div>
    <SearchBar
      v-model:search-text="searchText"
      :disabled="isLoading"
      input-name="shop-more-search"
    />
    <div class="all-shop-more__sort-wrapper">
      <SoonaFilterSingle
        v-model="selectedFilter"
        :options="filterOptions"
        variation="friendly-red"
        size="large"
      >
        <template
          #option="{ count, iconName, label, selected, selectedIconName }"
        >
          <SoonaIcon
            v-if="iconName"
            size="medium"
            :name="selected && selectedIconName ? selectedIconName : iconName"
          />
          {{ label }}
          <span v-if="count" class="u-body--regular">({{ count }})</span>
        </template>
      </SoonaFilterSingle>
      <SortBy
        v-model:selected-sort="selectedSort"
        :sort-direction="sortDirection"
        @change-sort-direction="changeSortDirection"
      />
    </div>
    <SoonaError v-if="priorityErrors" :priority-errors="priorityErrors" />
    <AccountGalleryEmptyState
      v-else-if="!isLoading && !assetRows.length && selectedFilter === 'all'"
      :account-id="accountId"
      :show-call-to-action-buttons="isMyAccount"
      :show-upload-button="false"
    />
    <EmptyGallery
      v-else-if="!isLoading && !assetRows.length && selectedFilter !== 'all'"
      :filter-by="selectedFilter"
    />
    <section v-else ref="wrapper">
      <InfiniteGallery
        v-slot="{ data }"
        :rows="assetRows"
        :start-page="startPage"
        :height="rowHeight"
        :gap="gutter"
        :offset-top="offsetTop"
      >
        <DigitalAssetCard
          v-for="asset in data.assets"
          :key="asset.id"
          :asset="asset"
          :flex-grow="data.width / rowWidth > 0.6 ? 1 : 0"
          :selected="isAssetSelected(asset)"
          :max-row-width="rowWidth"
          :to="{
            name: 'shop-more-assets-media-view',
            params: {
              accountId: accountId,
              digitalAssetId: asset.id,
            },
            query: queryFilters,
          }"
          @request-page="pageNumber => fetchPage(pageNumber)"
          @selection-click="onSelectionClick(asset, $event)"
        />
      </InfiniteGallery>
      <MultiSelectActionBar
        :account-id="accountId"
        :page-bounding-rect-left="left"
        :page-bounding-rect-width="width"
        :selected-assets="selectedAssets"
        show-add-to-bag
        @close="resetSelection"
      />
    </section>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.all-shop-more {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  &__heading {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    justify-content: space-between;
  }

  &__sort-wrapper {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 0.5rem;
  }

  &__items {
    color: variables.$gray-60;
  }
}
</style>
