import * as types from 'src/store/mutation-types';

// const VIEW_MODE = 0;
// const EDIT_MODE = 1;

const state = {
  packConfiguration: {},
  allPackConfigurations: [],
};

const getters = {
  //
};

const mutations = {
  [types.SET_PACK_CONFIGURATION](state, packConfiguration) {
    state.packConfiguration = packConfiguration;
  },
};

const actions = {
  loadPackConfiguration({ commit }, packId) {
    return this.http
      .get(`pack_configurations/${packId}.json`)
      .then(response => {
        commit(types.SET_PACK_CONFIGURATION, response.data);
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
