import { toValue } from 'vue';
import { useQuery, useMutation, useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from 'src/queries/query-keys';
import { http } from '../config/vue-axios';
import { mapPaginationHeaders } from '@/lib/api-transforms';

/**
 *
 * @param {string | number} accountId
 * @param {Object} queryOptions
 * @returns
 */
export function useTopOpportunities(accountId, queryOptions) {
  return useQuery({
    queryKey: queryKeys.listingActionsTopOpportunities(accountId),
    queryFn: async ({ signal }) => {
      const response = await http.get(
        `/accounts/${toValue(accountId)}/listing_actions/top_opportunities.json`,
        {
          signal,
        }
      );

      return response.data;
    },
    ...queryOptions,
  });
}

export function useListingActions(
  accountId,
  { actionSlug, itemsPerPage, currentPage, status = null },
  queryOptions
) {
  return useQuery({
    queryKey: queryKeys.listingActions(accountId, {
      actionSlug,
      itemsPerPage,
      currentPage,
      status,
    }),
    queryFn: async ({ signal }) => {
      const params = {};
      if (itemsPerPage) params.items = toValue(itemsPerPage);
      if (currentPage) params.page = toValue(currentPage);
      if (actionSlug) params['action_slug'] = toValue(actionSlug);
      if (status) params.status = toValue(status);

      const response = await http.get(
        `/accounts/${toValue(accountId)}/listing_actions.json`,
        {
          params,
          signal,
        }
      );

      return {
        pagination: mapPaginationHeaders(response.headers),
        listing_actions: response.data.listing_actions,
        action_in_progress: response.data.action_in_progress,
      };
    },
    ...queryOptions,
  });
}

export function useListingAction(accountId, listingActionId, queryOptions) {
  return useQuery({
    queryKey: queryKeys.listingAction(accountId, listingActionId),
    queryFn: async ({ signal }) => {
      const response = await http.get(
        `/accounts/${toValue(accountId)}/listing_actions/${toValue(listingActionId)}.json`,
        {
          signal,
        }
      );

      return response.data;
    },
    ...queryOptions,
  });
}

/**
 * @param {string | number} accountId
 */
export function useUpdateListingAction(
  accountId,
  listingActionId,
  { externalProductId, integrationId } = {}
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async params => {
      const response = await http.put(
        `/accounts/${accountId.value}/listing_actions/${listingActionId.value}`,
        params
      );
      return response.data;
    },
    onSuccess: async () => {
      const promises = [
        queryClient.invalidateQueries({
          queryKey: queryKeys.listingActions(accountId),
        }),
      ];
      if (externalProductId?.value && integrationId?.value) {
        // used when accessing from listing detail page
        promises.push(
          queryClient.invalidateQueries({
            queryKey: queryKeys.activeListing(integrationId, externalProductId),
          })
        );
      }
      await Promise.all(promises);
    },
  });
}

export function useDeleteListingActionDigitalAssets(
  accountId,
  { externalProductId, integrationId } = {}
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async listingActionId => {
      await http.delete(
        `/accounts/${accountId.value}/listing_actions/${listingActionId}/destroy_all_digital_assets`
      );
    },
    onSuccess: async () => {
      const promises = [
        queryClient.invalidateQueries({
          queryKey: queryKeys.listingActions(accountId),
        }),
      ];
      if (externalProductId.value && integrationId.value) {
        // used when accessing from listing detail page
        promises.push(
          queryClient.invalidateQueries({
            queryKey: queryKeys.activeListing(integrationId, externalProductId),
          })
        );
      }
      await Promise.all(promises);
    },
  });
}

/**
 *
 * @param {string | number} accountId
 * @param {Object} queryOptions
 * @returns
 */
export function useMissingContentOpportunities(accountId, queryOptions) {
  return useQuery({
    queryKey: queryKeys.listingActionsMissingContentOpportunities(accountId),
    queryFn: async ({ signal }) => {
      const response = await http.get(
        `/accounts/${toValue(accountId)}/listing_actions/missing_content_opportunities.json`,
        {
          signal,
        }
      );

      return response.data;
    },
    ...queryOptions,
  });
}
