import { unref } from 'vue';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from '../query-keys';
import { removeCoupon } from '@/api/reservation';

/**
 *
 * @param {number | Ref<number>} reservationId
 */
export function useRemoveCoupon(reservationId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: orderId => removeCoupon(unref(reservationId), orderId),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.reservations(),
      });
    },
  });
}
