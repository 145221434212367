<script setup>
import { computed, onMounted, ref } from 'vue';
import { refDebounced, useTitle } from '@vueuse/core';
import { useAlbumCollections } from '@/queries/album_collections/useAlbumCollections';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { usePriorityErrors } from '@/composables/usePriorityErrors';
import { useRoute } from 'vue-router';
import AlbumCard from '@/components/account_gallery/AlbumCard.vue';
import AccountGalleryBreadcrumbs from '@/components/account_gallery/AccountGalleryBreadcrumbs.vue';
import headerImg from '@images/account-gallery/all_albums.svg';
import NewAlbumDialog from '@/components/account_gallery/NewAlbumDialog.vue';
import PaginatorFull from '@/components/directory/PaginatorFull.vue';
import SearchBar from '@/components/account_gallery/SearchBar.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';
import SoonaNoResults from '@/components/ui_library/SoonaNoResults.vue';
import SortBy from '@/components/account_gallery/SortBy.vue';
import AccountInfoAuto from '@/components/user/anytime/dashboard/AccountInfoAuto.vue';
import { useMe } from '@/composables/user/useMe';
import { useAccount } from '@/queries/account/useAccount';
import { useEditsCollection } from '@/queries/edits_collection/useEditsCollection';
import { useFavoritesCollection } from '@/queries/favorites_collection/useFavoritesCollection';
import { useStaffPicksCollection } from '@/queries/staff_picks_collection/useStaffPicksCollection';
import uploadsImage from 'images/uploads.jpg';
import editsImage from 'images/edits.png';
import favoritesImage from 'images/favorites.png';
import staffPicksImage from 'images/staff picks.png';
import { useDigitalAssets } from '@/queries/digital_assets/useDigitalAssets';

const props = defineProps({
  accountId: {
    required: true,
    type: [Number, String],
  },
});

useTitle('my albums | soona');

const { linkClicked, pageViewed } = useBaseEvents();
const route = useRoute();

const trackClick = (label, href = null) => {
  linkClicked({
    context: route.meta.context,
    subContext: 'header',
    linkLabel: label,
    linkHref: href,
  });
};

const accountId = computed(() => +props.accountId);
const { currentAccountId: authedAccountId } = useMe();
const isMyAccount = computed(() => authedAccountId.value === accountId.value);

const currentPage = ref(1);
const itemsPerPage = ref(12);
const searchText = ref('');
const searchDebounced = refDebounced(searchText, 200);
const showDialog = ref(false);
const selectedSort = ref('date modified');
const sortDirection = ref('desc');

const {
  data: account,
  isLoading: isLoadingAccount,
  error: errorAccount,
} = useAccount(accountId);

const headingCopy = computed(() => {
  if (isMyAccount.value) return 'my albums';
  return `${account.value?.name}’s albums`;
});

const { data: editsCollection, isPending: isEditsCollectionLoading } =
  useEditsCollection(accountId);
const { data: favoritesCollection, isPending: isFavoritesCollectionLoading } =
  useFavoritesCollection(accountId);
const { data: staffPicksCollection, isPending: isStaffPicksCollectionLoading } =
  useStaffPicksCollection(accountId);

// todo: change this once "UploadsCollection" is created
const { data: digitalAssets, isPending: isDigitalAssetsLoading } =
  useDigitalAssets({
    accountId,
    itemsPerPage: 1,
    filters: {
      origin: 'customer',
      ownership: 'customer',
      media_type: 'photo',
      visibility: 'all',
    },
  });
const uploadCollectionWithFallbackImage = computed(() => {
  const previewUrl =
    digitalAssets.value?.assets?.length > 0
      ? digitalAssets.value.assets[0].preview?.url
      : undefined;

  return {
    title: 'my uploads',
    type: 'UploadsCollection',
    preview: {
      url: previewUrl ?? uploadsImage,
    },
    total_assets: digitalAssets.value?.pagination?.totalCount ?? 0,
    routerTo: {
      name: 'uploads',
      params: { accountId: accountId.value },
    },
  };
});

const editsCollectionWithFallbackImage = computed(() => {
  return {
    ...editsCollection.value,
    preview: { url: editsCollection.value?.preview?.url || editsImage },
    routerTo: {
      name: 'edits-assets',
      params: { accountId: accountId.value },
    },
  };
});

const favoritesCollectionWithFallbackImage = computed(() => {
  return {
    ...favoritesCollection.value,
    preview: { url: favoritesCollection.value?.preview?.url || favoritesImage },
    routerTo: {
      name: 'favorites-assets',
      params: { accountId: accountId.value },
    },
  };
});

const staffPicksCollectionWithFallbackImage = computed(() => {
  return {
    ...staffPicksCollection.value,
    title: staffPicksCollection.value?.title?.replace(
      /^staff picks$/,
      'expert picks'
    ),
    preview: {
      url: staffPicksCollection.value?.preview?.url || staffPicksImage,
    },
    routerTo: {
      name: 'expert-picks-assets',
      params: { accountId: accountId.value },
    },
  };
});

const {
  data: albumCollections,
  isLoading: isAlbumCollectionsLoading,
  isPending: isAlbumCollectionsPending,
  error,
} = useAlbumCollections(accountId, {
  currentPage,
  itemsPerPage,
  searchQuery: searchDebounced,
  sortBy: selectedSort,
  sortDirection: sortDirection,
});

const formattedAlbumCollections = computed(
  () => albumCollections.value?.collections ?? []
);

const allCollections = computed(() => [
  editsCollectionWithFallbackImage.value || {},
  favoritesCollectionWithFallbackImage.value || {},
  staffPicksCollectionWithFallbackImage.value || {},
  uploadCollectionWithFallbackImage.value || {},
  ...formattedAlbumCollections.value,
]);

const albumCollectionsTotalCount = computed(
  () => (albumCollections.value?.pagination?.totalCount || 0) + 4
);

const albumCollectionsTotalPages = computed(
  () => albumCollections.value?.pagination?.totalPages || 0
);

const isPaginationLoading = computed(() => isAlbumCollectionsPending.value);
const hasAlbumCollections = computed(() => allCollections.value.length > 0);

const priorityErrors = usePriorityErrors(error, errorAccount);

const isLoading = computed(
  () =>
    isDigitalAssetsLoading.value ||
    isLoadingAccount.value ||
    isAlbumCollectionsLoading.value ||
    isEditsCollectionLoading.value ||
    isFavoritesCollectionLoading.value ||
    isStaffPicksCollectionLoading.value
);

const handleOpenDialog = () => {
  trackClick('new album');
  showDialog.value = true;
};

const handleCloseDialog = () => {
  trackClick('cancel');
  showDialog.value = false;
};

const changeSortDirection = () => {
  if (sortDirection.value === 'desc') sortDirection.value = 'asc';
  else sortDirection.value = 'desc';
};

onMounted(() => {
  pageViewed();
});
</script>

<template>
  <div class="all-albums">
    <AccountInfoAuto :account-id="accountId" />
    <SoonaLoading v-if="isLoading && !allCollections?.length" is-loading />
    <SoonaError
      v-else-if="priorityErrors && !albumCollections?.length"
      :priority-errors="priorityErrors"
    />
    <template v-else>
      <AccountGalleryBreadcrumbs
        :is-my-account="isMyAccount"
        :account-name="account?.name"
      />
      <div class="all-albums__heading">
        <h1 class="u-title--heavy">{{ headingCopy }}</h1>
        <SoonaButton
          v-if="hasAlbumCollections"
          copy="new album"
          variation="solid-black"
          @on-click="handleOpenDialog"
        />
      </div>
      <SearchBar
        v-if="hasAlbumCollections"
        v-model:search-text="searchText"
        input-name="my-albums-search"
      />
      <div v-if="hasAlbumCollections" class="all-albums__sort-wrapper">
        <p class="all-albums__items">
          {{ albumCollectionsTotalCount.toLocaleString() }} items
        </p>
        <SortBy
          v-model:selected-sort="selectedSort"
          :sort-direction="sortDirection"
          @change-sort-direction="changeSortDirection"
        />
      </div>
      <SoonaLoading v-if="isPaginationLoading" is-loading />
      <SoonaError
        v-else-if="priorityErrors"
        :priority-errors="priorityErrors"
      />
      <SoonaNoResults
        v-else-if="!hasAlbumCollections"
        :image-source="headerImg"
        mask="none"
      >
        <template #header>your albums will appear here!</template>
        <template #body>
          keep assets organized by adding them to custom albums. you can always
          add the same asset to more than one album.
        </template>
        <template #button>
          <SoonaButton
            element="router-link"
            copy="back to gallery"
            :to="{ name: 'account-gallery' }"
            variation="secondary-gray"
          />
          <SoonaButton
            copy="new album"
            variation="solid-black"
            @on-click="handleOpenDialog"
          />
        </template>
      </SoonaNoResults>
      <div v-else class="all-albums__grid">
        <AlbumCard
          v-for="album in allCollections"
          :key="album.id"
          :account-id="accountId"
          :album="album"
        />
      </div>
      <PaginatorFull
        v-if="albumCollectionsTotalPages"
        v-model:page="currentPage"
        v-model:records-per-page="itemsPerPage"
        class="product-catalog__paginator-top"
        :records-per-page-options="[12, 24, 36]"
        :total-pages="albumCollectionsTotalPages"
        :total-count="albumCollectionsTotalCount"
        :page-count="allCollections.length"
      />
      <NewAlbumDialog
        v-if="showDialog"
        :account-id="accountId"
        @close="handleCloseDialog"
      />
    </template>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.all-albums {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  &__heading {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    justify-content: space-between;
  }

  &__sort-wrapper {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    justify-content: space-between;
  }

  &__items {
    color: variables.$gray-60;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min(18rem, 100%), 1fr));
    gap: 1rem;
    padding-bottom: 1rem;
  }
}
</style>
