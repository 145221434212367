import { computed } from 'vue';
import { useCapability } from '@/composables/useCapability';

export function useReservationActionBar(options = {}) {
  const { accountId, reservationFile = {} } = options;

  const sortedAlbumCollections = computed(() => {
    if (reservationFile.value?.collection_items) {
      const uniqCollectionItems = [];

      reservationFile.value?.collection_items.forEach(collectionItem => {
        if (
          (collectionItem.collection?.type === 'AlbumCollection' ||
            collectionItem.collection?.type === 'FavoritesCollection') &&
          !uniqCollectionItems.some(
            ci => ci.collection.title === collectionItem.collection.title
          )
        ) {
          uniqCollectionItems.push(collectionItem);
        }
      });
      return uniqCollectionItems
        .slice()
        .sort((a, b) => a.collection.title.localeCompare(b.collection.title));
    }

    return [];
  });

  const formattedReservationFile = computed(() => {
    return {
      account_id: accountId.value,
      id: reservationFile.value?.id,
      collectionIds: reservationFile.value?.collection_items?.map(
        item => item.collection_id
      ),
    };
  });

  const { hasCapability: canAdjustBackgroundOnOwnAccount } = useCapability({
    capability: 'media_editor_adjust_bg_on_own_account',
    subjectType: 'account',
    subjectId: accountId,
  });

  const { hasCapability: staffCanAdjustBackgroundOnClientAccount } =
    useCapability({
      capability: 'media_editor_adjust_bg_on_subscription_accounts',
    });

  return {
    canAdjustBackgroundOnOwnAccount,
    formattedReservationFile,
    sortedAlbumCollections,
    staffCanAdjustBackgroundOnClientAccount,
  };
}
