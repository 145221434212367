<script setup>
import { ref, watch, computed } from 'vue';
import { useUploader } from 'src/composables/useUploader.js';
import { useSoonaToast } from '@/components/ui_library/soona_toast/useSoonaToast';
import SoonaUploadProgressModal from './SoonaUploadProgressModal.vue';

const props = defineProps({
  accept: {
    type: String,
    default: undefined,
  },
  fileSizeLimitMb: {
    type: Number,
    default: undefined,
  },
  isMultiple: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: '',
  },
  validate: {
    type: Function,
    default: undefined,
  },
});

const emits = defineEmits([
  'percentageComplete',
  'uploadComplete',
  'uploadError',
]);

const maxConcurrent = ref(5);
const uploadInterval = ref(100);
const fileInput = ref();

const { addToast } = useSoonaToast();

const {
  activeUploads,
  cancelUploads,
  handleUpload,
  percentComplete,
  startUpload,
  removeUploadFile,
} = useUploader(
  maxConcurrent,
  uploadInterval,
  computed(() => props.fileSizeLimitMb),
  computed(() => props.validate),
  msg => {
    emits('uploadError', msg);
  },
  blob => {
    emits('uploadComplete', blob);

    addToast('file successfully uploaded', {
      variation: 'success',
    });
  }
);

watch(percentComplete, () => {
  emits('percentageComplete', percentComplete);
});

const onUploadClick = e => {
  if (e.target.nodeName === 'A') e.preventDefault();
  fileInput.value?.click();
};
</script>

<template>
  <slot :on-upload-click="onUploadClick" />
  <input
    ref="fileInput"
    class="u-a11y-only"
    type="file"
    name="resume"
    :accept="accept"
    :multiple="isMultiple"
    @change="handleUpload"
  />
  <SoonaUploadProgressModal
    v-if="activeUploads?.length > 0"
    :active-uploads="activeUploads"
    :is-multiple="isMultiple"
    :label="label"
    @start-upload="startUpload"
    @cancel-uploads="cancelUploads"
    @remove-upload-file="removeUploadFile"
  />
</template>
