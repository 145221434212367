import { http } from '../config/vue-axios';
import { mapPaginationHeaders } from '../lib/api-transforms';

/**
 *
 * @param {string | number} accountId
 * @param {Object} params
 * @param {string | null} [params.query]
 * @param {string} [params.filter]
 * @param {string} [params.orderBy]
 * @param {string} [params.direction]
 * @param {boolean} [params.includeVariants]
 * @param {boolean} [params.showVariantCount]
 * @param {number} [params.itemsPerPage]
 * @param {number} [params.currentPage]
 * @param {number} [params.attachedToReservationId]
 * @param {AbortSignal} signal
 * @returns {Promise<any>}
 */
export async function getCatalogItems(
  accountId,
  {
    query = null,
    filter = null,
    orderBy = 'name',
    direction = 'asc',
    includeVariants,
    showBookingCount,
    showInventoryCount,
    showVariantCount,
    itemsPerPage,
    currentPage,
    attachedToDigitalAssetId,
    attachedToReservationId,
  },
  signal
) {
  const params = {};

  if (query) params['query'] = query;
  if (filter) params['filter'] = filter;
  if (orderBy) params['order_by'] = orderBy;
  if (direction) params['direction'] = direction;
  if (includeVariants) params['include_variants'] = includeVariants;
  if (showBookingCount) params['show_booking_count'] = showBookingCount;
  if (showInventoryCount) params['show_inventory_count'] = showInventoryCount;
  if (showVariantCount) params['show_variant_count'] = showVariantCount;
  if (itemsPerPage) params.items = itemsPerPage;
  if (currentPage) params.page = currentPage;
  if (attachedToDigitalAssetId) {
    params.attached_to_digital_asset_id = attachedToDigitalAssetId;
  }
  if (attachedToReservationId)
    params.attached_to_reservation_id = attachedToReservationId;

  const response = await http.get(`/accounts/${accountId}/catalog_items`, {
    params,
    signal,
  });

  return {
    pagination: mapPaginationHeaders(response.headers),
    catalogItems: response.data,
  };
}

/**
 *
 * @param {string | number} accountId
 * @param {Object} params
 * @param {string | null} [params.query]
 * @param {string} [params.filter]
 * @param {string} [params.orderBy]
 * @param {string} [params.direction]
 * @param {number} [params.itemsPerPage]
 * @param {number} [params.currentPage]
 * @param {number} [params.attachedToReservationFileId]
 * @param {number} [params.attachedToReservationId]
 * @param {AbortSignal} signal
 * @returns {Promise<any>}
 */
export async function getCatalogItemVariants(
  accountId,
  catalogItemId,
  {
    query = null,
    filter = null,
    orderBy = 'name',
    direction = 'asc',
    itemsPerPage,
    currentPage,
    attachedToReservationFileId,
    attachedToReservationId,
  },
  signal
) {
  const params = {};

  if (query) params['query'] = query;
  if (filter) params['filter'] = filter;
  if (orderBy) params['order_by'] = orderBy;
  if (direction) params['direction'] = direction;
  if (itemsPerPage) params.items = itemsPerPage;
  if (currentPage) params.page = currentPage;
  if (attachedToReservationFileId)
    params.attached_to_reservation_file_id = attachedToReservationFileId;
  if (attachedToReservationId)
    params.attached_to_reservation_id = attachedToReservationId;

  const response = await http.get(
    `/accounts/${accountId}/catalog_items/${catalogItemId}/variants`,
    {
      params,
      signal,
    }
  );

  return {
    pagination: mapPaginationHeaders(response.headers),
    catalogItemVariants: response.data,
  };
}

/**
 *
 * @param {string | number} accountId
 * @param {Object} catalog_item
 * @returns {Promise}
 */
export async function createCatalogItem(accountId, catalog_item) {
  const response = await http.post(`/accounts/${accountId}/catalog_items`, {
    catalog_item,
  });
  return response.data;
}

/**
 *
 * @param {string | number} accountId
 * @param {string | number} itemId
 * @param {AbortSignal} signal
 * @returns {Promise}
 */
export async function getCatalogItem(accountId, itemId, signal) {
  const response = await http.get(
    `/accounts/${accountId}/catalog_items/${itemId}`,
    { signal }
  );
  return response.data;
}

/**
 *
 * @param {string | number} accountId
 * @param {string | number} itemId
 * @param {Object} body
 * @param {Object} body.productDetails
 * @returns
 */
export async function updateCatalogItem(accountId, itemId, { productDetails }) {
  return http.patch(`/accounts/${accountId}/catalog_items/${itemId}`, {
    catalog_item: productDetails,
  });
}

/**
 *
 * @param {string | number} accountId
 * @param {string | number} itemId
 * @param {AbortSignal} [signal]
 * @returns {Promise}
 */
export async function getCatalogItemActiveListings(accountId, itemId, signal) {
  const response = await http.get(
    `/accounts/${accountId}/catalog_items/${itemId}/active_listings`,
    { signal }
  );

  return response.data;
}

/**
 *
 * @param {string | number} accountId
 * @param {string | number} itemId
 * @param {Object} [params]
 * @param {number} [params.currentPage]
 * @param {number} [params.itemsPerPage]
 * @param {AbortSignal} [signal]
 * @returns {Promise}
 */
export async function getCatalogItemReservations(
  accountId,
  itemId,
  { currentPage, itemsPerPage } = {},
  signal
) {
  const params = {};

  if (currentPage) params['page'] = currentPage;
  if (itemsPerPage) params['items'] = itemsPerPage;

  const response = await http.get(
    `/accounts/${accountId}/catalog_items/${itemId}/reservations`,
    { params, signal }
  );

  return {
    pagination: mapPaginationHeaders(response.headers),
    reservations: response.data,
  };
}

/**
 *
 * @param {string | number} accountId
 * @param {Object} [body]
 * @returns
 */
export async function archiveCatalogItems(accountId, { items } = {}) {
  return http.post(`/accounts/${accountId}/catalog_items/archive`, {
    items,
  });
}

/**
 *
 * @param {string | number} accountId
 * @param {string | number} itemId
 * @returns
 */
export async function getCatalogItemsAmazonListing(accountId, itemId, signal) {
  return http.get(
    `/accounts/${accountId}/catalog_items/${itemId}/amazon_listings`,
    { signal }
  );
}

/**
 *
 * @param {string | number} accountId
 * @param {string | number} itemId
 * @returns
 */
export async function getCatalogItemsShopifyProducts(
  accountId,
  itemId,
  signal
) {
  return http.get(
    `/accounts/${accountId}/catalog_items/${itemId}/shopify_products`,
    { signal }
  );
}

/**
 *
 * @param {string | number} accountId
 * @param {string | number} itemId
 * @param {Object} [body]
 * @returns
 */
export async function updateCatalogItemsAmazonListing(
  accountId,
  itemId,
  { images } = {}
) {
  return http.patch(
    `/accounts/${accountId}/catalog_items/${itemId}/amazon_listings`,
    {
      images,
    }
  );
}

/**
 *
 * @param {string | number} accountId
 * @param {string | number} itemId
 * @param {Object} [body]
 * @returns
 */
export async function updateCatalogItemShopifyProduct(
  accountId,
  itemId,
  { images } = {}
) {
  return http.put(
    `/accounts/${accountId}/catalog_items/${itemId}/shopify_products`,
    {
      images,
    }
  );
}

/**
 * @param {string | number} accountId
 * @param {string | number} assetId
 * @param {AbortSignal} signal
 * @returns {Promise}
 */
export async function getDigitalAssetCatalogItems(accountId, assetId, signal) {
  const response = await http.get(
    `/accounts/${accountId}/digital_assets/${assetId}/catalog_items`,
    {
      signal,
    }
  );
  return response.data;
}

/**
 * @param {string | number} accountId
 * @param {string | number} assetId
 * @param {Object} body
 * @returns {Promise}
 */
export async function createDigitalAssetCatalogItems(accountId, assetId, body) {
  const response = await http.post(
    `/accounts/${accountId}/digital_assets/${assetId}/catalog_items/batch_create`,
    body
  );
  return response.data;
}

/**
 * @param {string | number} accountId
 * @param {string | number} assetId
 * @param {string | number} catalogItemId
 * @returns {Promise}
 */
export async function deleteDigitalAssetCatalogItem(
  accountId,
  assetId,
  catalogItemId
) {
  const response = await http.delete(
    `/accounts/${accountId}/digital_assets/${assetId}/catalog_items/${catalogItemId}`
  );
  return response.data;
}
