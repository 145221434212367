<script setup>
import { computed, ref, watchEffect } from 'vue';
import { useTimeoutFn } from '@vueuse/core';
import { useStore } from 'vuex';
import * as Sentry from '@sentry/browser';
import IndustryInterstitial from './IndustryInterstitial.vue';
import BookingLayout from './BookingLayout.vue';
import SoonaHeading from 'src/components/ui_library/SoonaHeading.vue';
import SoonaLoading from 'src/components/SoonaLoading.vue';
import {
  interstitialHeadline,
  interstitialImages,
} from 'src/lib/booking/industryInterstitialImages';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useIndustries } from 'src/queries/useIndustries';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import { useFlag } from '@/composables/useFlag';
import SoonaSelect from '@/components/ui_library/SoonaSelect.vue';
import SoonaTelephoneField from '@/components/ui_library/SoonaTelephoneField.vue';
import { usePhoneHelper } from '@/composables/usePhoneHelper';
import { useUpdateUser } from 'src/queries/users/useUpdateUser';
import { useMe } from '@/composables/user/useMe';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import IndustrySideBanner from './IndustrySideBanner.vue';

const props = defineProps({
  nextButtonCopy: String,
  backButtonCopy: String,
  onNext: Function,
  onBack: Function,
  reservationId: String,
  stepName: String,
  transitionName: String,
});

const { itemSelected } = useBaseEvents();
const { data: industryOptions, isLoading } = useIndustries();
const industrySelectOptions = computed(
  () =>
    industryOptions.value?.map(i => ({
      label: `${i.icon} ${i.title}`,
      value: i,
    })) ?? []
);
const apolloNewIndustriesPage = useFlag('apollo_new_industries_page');

const selectedIndustry = ref(null);
const industryLeave = ref(false);
const showInterstitial = ref(false);
const error = ref(null);
const phoneNumber = ref('');
const phoneNumberError = ref(null);

const { me, currentUserId } = useMe();
const { isPhoneNumberValid } = usePhoneHelper();
const { mutate: updateUser } = useUpdateUser(currentUserId);

const store = useStore();
const account = computed(() => store.state.account);

const isStepValid = computed(
  () => selectedIndustry.value !== null && !phoneNumberError.value
);
const headline = computed(() => {
  if (selectedIndustry.value === null) {
    return '';
  }

  return interstitialHeadline(selectedIndustry.value.slug);
});
const nextImages = computed(() => {
  if (selectedIndustry.value === null) {
    return [];
  }

  return interstitialImages(selectedIndustry.value.slug);
});

const updateAccount = params => store.dispatch('account/updateAccount', params);

const { start: beginInterstitialWait, stop: stopInterstitialWait } =
  useTimeoutFn(() => props.onNext(), 3300, {
    immediate: false,
  });

const { start: beginInterstitialShow } = useTimeoutFn(
  () => {
    showInterstitial.value = true;
    beginInterstitialWait();
  },
  300,
  {
    immediate: false,
  }
);

function handleSkip() {
  stopInterstitialWait();
  props.onNext();
}

function completeStep() {
  industryLeave.value = true;
  beginInterstitialShow();
}

function preloadInterstitialImages(industrySlug) {
  for (let image of interstitialImages(industrySlug)) {
    if (window.devicePixelRatio > 1 && image[1]) {
      new Image().src = image[1];
    } else if (image[0]) {
      new Image().src = image[0];
    }
  }
}

function handleIndustryChange(industry) {
  selectedIndustry.value = industry;
  preloadInterstitialImages(selectedIndustry.value.slug);
  itemSelected({
    context: 'booking',
    subContext: 'industry type selection',
    itemLabel: industry.title,
    itemPrice: 0,
    itemQuantity: 1,
  });
}

async function checkPhoneNumber() {
  if (!phoneNumber.value) {
    phoneNumberError.value = null;
    return;
  }

  const isValid = await isPhoneNumberValid(phoneNumber.value);
  if (!isValid) {
    phoneNumberError.value = 'this phone number is invalid';
  } else {
    phoneNumberError.value = null;
  }
}

async function setIndustry() {
  error.value = null;
  const info = {
    account_type:
      selectedIndustry.value.slug === 'personal-brand' ? 'personal' : 'brand',
    industry_id: selectedIndustry.value.id,
    lets_get_the_basics: {
      data: {
        industry_type:
          account.value.profile_data.lets_get_the_basics?.data?.industry_type,
      },
      skip: false,
      complete: false,
    },
  };

  if (apolloNewIndustriesPage.value && phoneNumber.value !== '') {
    const isValid = await isPhoneNumberValid(phoneNumber.value);
    if (isValid) {
      updateUser({
        phone: phoneNumber.value,
      });
    }
  }

  updateAccount({ accountParams: info })
    .then(() => {
      completeStep();
    })
    .catch(err => {
      if (err.response.status !== 422) {
        error.value = 'updating account industry failed';
        Sentry.captureException(new Error('Updating account industry failed'), {
          extra: { err },
        });
      }
    });
}

watchEffect(() => {
  phoneNumber.value = me.value.phone ?? '';
});
</script>
<template>
  <transition :name="transitionName">
    <BookingLayout
      :next-button-copy="nextButtonCopy"
      :back-button-copy="backButtonCopy"
      :on-next-click="setIndustry"
      :on-back-click="() => onBack()"
      :show-next="!showInterstitial"
      :show-back="!showInterstitial"
      :hide-footer="showInterstitial"
      :is-step-valid="isStepValid"
      :reservation-id="reservationId"
      no-sidecart
      :left-aligned="apolloNewIndustriesPage"
    >
      <template v-if="error" #priority-error>
        {{ error }}
      </template>
      <div class="prebooking-transition__content">
        <SoonaLoading
          v-if="isLoading"
          :is-loading="isLoading"
          :is-dark="false"
          loading-text="loading"
        />
        <IndustryInterstitial
          v-if="showInterstitial"
          :headline="headline"
          :images="nextImages"
        />
        <div
          v-else
          class="industry"
          :class="{
            'industry--leave': industryLeave,
            'industry--side-banner-layout': apolloNewIndustriesPage,
          }"
        >
          <IndustrySideBanner
            v-if="apolloNewIndustriesPage"
            class="industry__side-banner"
            :selected-industry="selectedIndustry"
          />
          <section class="industry__content-section">
            <section class="industry__content-section__industry-section">
              <SoonaHeading data-cypress="which-industry-headline">
                <label for="industry-input">what is your industry?</label>
                <template #subtitle>
                  we make creative recommendations based on your industry. have
                  a business with more than one category? choose your main
                  industry.
                </template>
              </SoonaHeading>
              <div
                v-if="!apolloNewIndustriesPage"
                class="industry__content-section__industry-section__grid"
              >
                <div v-for="industry in industryOptions" :key="industry.slug">
                  <input
                    :id="'prebooking-industry-option-' + industry.slug"
                    name="industry"
                    type="radio"
                    :checked="
                      selectedIndustry &&
                      industry.slug === selectedIndustry.slug
                    "
                    class="u-a11y-only"
                    :data-cypress="`input-${industry.slug
                      .toLowerCase()
                      .replace(/\s/g, '-')}`"
                    @change="handleIndustryChange(industry)"
                  />
                  <label
                    :for="'prebooking-industry-option-' + industry.slug"
                    class="industry__content-section__industry-section__grid__item"
                    :data-cypress="`label-${industry.slug
                      .toLowerCase()
                      .replace(/\s/g, '-')}`"
                  >
                    <span
                      class="industry__content-section__industry-section__grid__emoji"
                      :class="{
                        'industry__content-section__industry-section__grid__emoji--other':
                          industry.slug === 'other',
                      }"
                      aria-hidden="true"
                    >
                      {{ industry.icon }}
                    </span>
                    {{ industry.title }}
                  </label>
                </div>
              </div>
              <SoonaSelect
                v-else
                id="industry-input"
                v-model="selectedIndustry"
                class="industry__content-section__industry-section__select"
                placeholder="your industry"
                :options="industrySelectOptions"
                @update:model-value="handleIndustryChange"
              />
            </section>
            <section
              v-if="apolloNewIndustriesPage"
              class="industry__content-section__phone-section"
            >
              <div
                class="industry__content-section__phone-section__copy-container"
              >
                <label for="phone-number-input">
                  <span class="u-body--heavy">what is your phone number?</span>
                  (optional)
                </label>
                <p>
                  please note that while we value your interest and may reach
                  out to help organize your shoot, your choice to engage with
                  our crew is entirely optional.
                </p>
              </div>
              <div>
                <SoonaTelephoneField
                  id="phone-number-input"
                  v-model="phoneNumber"
                  class="industry__content-section__industry-section__select"
                  hide-label
                  label="phone number"
                  @on-blur="checkPhoneNumber"
                />
                <SoonaError v-if="phoneNumberError">
                  {{ phoneNumberError }}
                </SoonaError>
              </div>
            </section>
          </section>
        </div>
        <SoonaButton
          v-if="industryLeave"
          class="industry__skip"
          copy="skip"
          :on-click="() => handleSkip()"
          variation="tertiary"
          type="button"
          data-cypress="booking-skip-industry"
        />
      </div>
    </BookingLayout>
  </transition>
</template>
<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.prebooking-transition__content {
  height: 100%;
  justify-content: flex-start;
}

.industry {
  display: flex;
  flex-direction: column;
  align-items: center;

  &--side-banner-layout {
    gap: 1rem;
    width: 100%;
    height: 100%;
    @media (min-width: variables.$screen-sm-min) {
      flex-direction: row;
    }
  }

  &__side-banner {
    display: none;
    @media (min-width: variables.$screen-sm-min) {
      display: flex;
      height: 100%;
      max-width: 35rem;
      width: 50%;
      justify-content: center;
    }
  }

  &__content-section {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 3rem;

    &__industry-section {
      display: flex;
      max-width: 43.75rem;
      flex-direction: column;
      align-items: center;
      padding: 0rem 2rem;

      &__grid {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        column-gap: 1.25rem;
        row-gap: 1.125rem;
        max-width: 52.3125rem;
        margin-left: -0.5625rem;
        margin-right: -0.5625rem;

        &__item {
          @include variables_fonts.u-label--regular;

          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          width: 6.1875rem;
          padding: 0.5rem;
          border: 1px solid transparent;
          border-radius: 0.625rem;
          transition:
            border-color 0.1s ease-out,
            box-shadow 0.1s ease-out;
          color: variables.$black-default;
          cursor: pointer;
          background-color: variables.$white-default;

          input:checked + & {
            border-color: variables.$black-default;
          }

          input:focus-visible + & {
            filter: drop-shadow(0px 0px 2px variables.$periwink-blue-60);
          }

          &:hover,
          &:focus-visible {
            color: variables.$black-default;
            box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
          }

          &:active {
            border-color: variables.$black-default;
          }
        }

        &__emoji {
          @include variables_fonts.u-display--regular;

          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          width: 5.1875rem;
          height: 5.1875rem;
          background-color: variables.$tangerine-20;
          margin: 0 0 0.375rem;

          &--other {
            background-color: variables.$periwink-blue-20;
          }
        }
      }

      @media (min-width: variables.$screen-sm-min) {
        &__grid {
          column-gap: 1.5rem;
          margin-left: 0;
          margin-right: 0;
        }
      }

      &__select {
        min-width: 15rem;
      }
    }

    &__phone-section {
      display: flex;
      max-width: 43.75rem;
      flex-direction: column;
      align-items: center;
      gap: 2rem;
      padding: 0rem 2rem;

      &__copy-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;
      }

      p {
        @include variables_fonts.u-body--regular;
        color: variables.$black-default;
        text-align: center;
      }
    }
  }

  &--leave {
    animation: prebooking-leave 0.3s both ease-out;

    @media (prefers-reduced-motion: reduce) {
      animation: none !important;
    }
  }

  &__skip {
    margin-top: 2.25rem;
  }
}
</style>
