<script setup>
import DownloadDigitalAssetMenuItem from '@/components/user/anytime/gallery/media-editor/download/DownloadDigitalAssetMenuItem.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaFlag from '@/components/ui_library/SoonaFlag.vue';
import { computed, toRefs } from 'vue';

const props = defineProps({
  asset: {
    type: Object,
    default: undefined,
  },
  clickCapture: {
    type: Function,
    required: true,
  },
  keydown: {
    type: Function,
    required: true,
  },
  mouseover: {
    type: Function,
    required: true,
  },
  saveGalleryAccess: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits([
  'save-low-res-preview',
  'show-paywall-dialog',
  'save-to-gallery',
]);

const { asset, clickCapture, keydown, mouseover, saveGalleryAccess } =
  toRefs(props);

const assetWidth = computed(() => asset.value?.width);
const assetHeight = computed(() => asset.value?.height);

const subtitle = computed(() => {
  if (assetWidth.value && assetHeight.value) {
    return `${assetWidth.value} x ${assetHeight.value}`;
  }
  return '';
});
</script>

<template>
  <DownloadDigitalAssetMenuItem
    v-if="saveGalleryAccess"
    :mouseover="mouseover"
    :keydown="keydown"
    :click-capture="clickCapture"
    title="save to gallery"
    subtitle="required before download"
    @click="emits('save-to-gallery')"
  />
  <template v-else>
    <DownloadDigitalAssetMenuItem
      :mouseover="mouseover"
      :keydown="keydown"
      :click-capture="clickCapture"
      title="save to gallery"
      subtitle="save low res preview to gallery"
      @click="emits('save-to-gallery')"
    />
    <DownloadDigitalAssetMenuItem
      :mouseover="mouseover"
      :keydown="keydown"
      :click-capture="clickCapture"
      title="low res preview"
      subtitle="with soona watermark"
      @click="emits('save-low-res-preview')"
    >
      <template #right-col>
        <span class="u-label--regular free">free</span>
      </template>
    </DownloadDigitalAssetMenuItem>
    <DownloadDigitalAssetMenuItem
      :mouseover="mouseover"
      :keydown="keydown"
      :click-capture="clickCapture"
      title="full resolution"
      :subtitle="subtitle"
      pizzazz-border
      @click="emits('show-paywall-dialog')"
    >
      <template #right-col>
        <SoonaFlag background-color="transparent" type="pill" is-pizzazz>
          <template #icon-left>
            <SoonaIcon name="crown" size="small" class="crown" />
          </template>
        </SoonaFlag>
      </template>
    </DownloadDigitalAssetMenuItem>
  </template>
</template>

<style scoped lang="scss">
@use '@/variables';

.free {
  text-transform: uppercase;
  color: variables.$gray-60;
}

.crown {
  color: variables.$white-default;
}
</style>
