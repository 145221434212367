<template>
  <div
    class="inventory-card"
    :class="markedForReturn && showReturnSelect ? 'add-border' : ''"
  >
    <div class="info-wrapper">
      <div class="details-top">
        <div class="u-label--heavy">
          <p>
            <span>
              {{ convertToMMDDYYYYlong(inventoryPackage.created_at) }}
              <span class="red"> | </span>
              {{ getLocationName }}
            </span>
            <br />
          </p>
        </div>
        <span v-if="canManagePackageInventory">
          <a
            v-if="hasOutboundLabel"
            :href="inventoryPackage.outbound_shipping_detail.label_url"
            target="_blank"
            rel="noopener noreferrer"
            title="Return Label"
          >
            <span class="has-label-icon"> 🏷 </span>
          </a>
          <SoonaButton
            v-if="canManagePackageInventory"
            element="router-link"
            :to="{
              path: `/account/${accountId}/package/${inventoryPackage.id}/edit`,
              query: { returnToInventory: true },
            }"
            size="medium"
            variation="secondary-gray"
          >
            edit package details
          </SoonaButton>
        </span>
        <SoonaTooltip v-if="showReturnSelect" placement="left">
          <template
            #default="{
              setRef,
              mouseenter,
              focus,
              mouseleave,
              blur,
              ariaDescribedby,
            }"
          >
            <checkbox
              :ref="el => setRef(el)"
              :handle-click="selectForReturnCheck"
              :item="inventoryPackage"
              :status="markedForReturn"
              :disabled-status="!availableForReturn"
              :aria-describedby="ariaDescribedby"
              @mouseenter="mouseenter"
              @focus="focus"
              @mouseleave="mouseleave"
              @blur="blur"
            />
          </template>
          <template v-if="!hasLatestReservation" #tooltip-content>
            <p>
              to start a return for this package please contact us at
              hey@soona.co
            </p>
          </template>
        </SoonaTooltip>
      </div>
      <div
        class="inventory-details"
        :class="{
          'inventory-details-staff': canManagePackageInventory,
        }"
      >
        <div v-if="canManagePackageInventory">
          <p>
            package ID:
            <span class="details">
              {{ inventoryPackage.id }}
            </span>
          </p>
          <p>
            storage location:
            <span class="details">
              {{ inventoryPackage.storage_location || 'unknown' }}
            </span>
          </p>
        </div>
        <p v-if="inventoryPackage.latest_reservation">
          recent booking:
          <a
            class="recentBooking"
            :href="`/user/anytime#/reservation/${inventoryPackage.latest_reservation.id}`"
          >
            {{ inventoryPackage.latest_reservation.name }}
            <span v-if="inventoryPackage.latest_reservation.start">
              &nbsp;on
              {{
                convertToMMDDYYYYlong(inventoryPackage.latest_reservation.start)
              }}
            </span>
          </a>
        </p>
        <p>
          package status:
          <span class="details">
            {{ getPackageStatuses[inventoryPackage.status] }}
          </span>
        </p>
        <p v-if="hasPackageDimensions">
          package dimensions:
          <span class="details">
            {{ inventoryPackage.length }}" L x {{ inventoryPackage.width }}" W x
            {{ inventoryPackage.height }}" H
          </span>
        </p>
        <p
          v-if="
            inventoryPackage.inventory_items &&
            inventoryPackage.inventory_items.length > 0
          "
        >
          package contents:
          <span class="details">
            {{ inventoryPackage.description }}
          </span>
        </p>
      </div>
      <div class="images">
        <div
          v-for="image in inventoryPackage.inventory_items"
          :key="image.id"
          class="inventory-image"
        >
          <SoonaTooltip placement="bottom">
            <template
              #default="{
                setRef,
                mouseenter,
                focus,
                mouseleave,
                blur,
                ariaDescribedby,
              }"
            >
              <span v-if="image.quantity > 1" class="imageQuantity">{{
                image.quantity
              }}</span>
              <viewer
                :ref="el => setRef(el)"
                :options="{
                  toolbar: false,
                  navbar: false,
                  backdrop: true,
                  minZoomRatio: 0.01,
                  maxZoomRatio: 2,
                  url: 'data-source',
                }"
                :aria-describedby="ariaDescribedby"
                @mouseenter="mouseenter"
                @focus="focus"
                @mouseleave="mouseleave"
                @blur="blur"
              >
                <img :src="image.image_url" :data-source="image.image_hq_url" />
              </viewer>
            </template>
            <template v-if="image.description?.length > 0" #tooltip-content>{{
              image.description
            }}</template>
          </SoonaTooltip>
        </div>
      </div>
      <cancellation-update-pso
        v-if="pageUse === 'cancelFastPass'"
        :inventory-package="inventoryPackage"
        :update-fast-pass-package="updateFastPassPackage"
      />
      <PostShootAndReturnAddress
        v-else
        :account-id="accountId"
        :inventory-package="inventoryPackage"
      />
    </div>
  </div>
</template>

<script>
import { useCapability } from '@/composables/useCapability';
import Checkbox from '../../../Checkbox.vue';
import CancellationUpdatePso from '../fast_pass/CancellationUpdatePSO.vue';
import dateTimeMixin from '@/mixins/dateTimeMixin';
import { packageStatuses } from 'src/lib/inventory/constants';
import PostShootAndReturnAddress from './PostShootAndReturnAddress.vue';
import { component as Viewer } from 'v-viewer';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaTooltip from 'src/components/ui_library/SoonaTooltip.vue';

export default {
  components: {
    CancellationUpdatePso,
    Checkbox,
    PostShootAndReturnAddress,
    SoonaButton,
    SoonaTooltip,
    Viewer,
  },
  mixins: [dateTimeMixin],
  props: {
    inventoryPackage: Object,
    accountId: Number,
    showReturnSelect: Boolean,
    selectForReturn: Function,
    markedForReturn: Boolean,
    pageUse: String,
    updateFastPassPackage: Function,
  },
  setup() {
    const { hasCapability: canManagePackageInventory } = useCapability({
      capability: 'manage_package_inventory',
    });

    return { canManagePackageInventory };
  },
  computed: {
    getPackageStatuses() {
      return packageStatuses;
    },
    hasOutboundLabel() {
      return this.inventoryPackage.outbound_shipping_detail &&
        this.inventoryPackage.outbound_shipping_detail.id
        ? true
        : false;
    },
    hasPackageDimensions() {
      return (
        !!this.inventoryPackage.length &&
        !!this.inventoryPackage.width &&
        !!this.inventoryPackage.height
      );
    },
    hasLatestReservation() {
      return this.inventoryPackage.latest_reservation ? true : false;
    },
    availableForReturn() {
      const packageIsStored = this.inventoryPackage.status === 'stored';
      const inStudioWithReturnPSO =
        this.inventoryPackage.status === 'in_studio' &&
        this.inventoryPackage.post_shoot_option === 'return_shipping';
      const hasUpcomingOrInProgressShoots =
        this.inventoryPackage.has_upcoming_or_in_progress_shoots;
      const intakeComplete = this.inventoryPackage.intake_complete;
      return (
        intakeComplete &&
        !this.hasOutboundLabel &&
        !hasUpcomingOrInProgressShoots &&
        this.hasLatestReservation &&
        (packageIsStored || inStudioWithReturnPSO)
      );
    },
    getLocationName() {
      if (!this.inventoryPackage.location) return;
      return this.inventoryPackage.location.display_name?.toUpperCase();
    },
  },
  methods: {
    selectForReturnCheck(_, inventoryPackage) {
      this.selectForReturn(inventoryPackage);
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.details-top {
  display: flex;
  justify-content: space-between;
  .has-label-icon {
    font-size: 1.75rem;
    position: relative;
    bottom: 0.375rem;
  }
}

.inventory-card {
  border-radius: 0;
  box-shadow: 0 0.25rem 0.375rem rgba(0, 0, 0, 0.1);
  display: flex;
  margin: 1rem 0.25rem;
  max-height: none;
  min-height: 12.5rem;
  padding-bottom: 1rem;
  position: relative;

  &.add-border {
    border: 0.125rem solid variables.$periwink-blue-50;
  }
}

.info-wrapper {
  padding: 1.25rem 1.25rem 0 1.25rem;
  width: 100%;
}

.red {
  color: variables.$friendly-red-50;
}

.inventory-details-staff {
  margin-top: 0 !important;
}

.inventory-details {
  @include variables_fonts.u-small--heavy;
  margin: 0.625rem 0 1.25rem;

  p {
    margin-bottom: 0.25rem;
  }
  .details {
    font-weight: 100;
  }
}

.images {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin: 1rem 0;

  .inventory-image {
    &:hover {
      cursor: pointer;

      img {
        transform: scale(1.05);
      }
    }
    img {
      height: 6.25rem;
      width: 6.25rem;
      object-fit: cover;
      border-radius: variables.$radius-large;
    }
  }
  .imageQuantity {
    background-color: variables.$white-default;
    box-shadow: variables.$elevation-1;
    border-radius: 50%;
    font-size: 0.75rem;
    height: 1.25rem;
    width: 1.25rem;
    display: inline-block;
    text-align: center;
    padding-left: 0.0625rem;
    position: absolute;
    margin-left: 5.4375rem;
    z-index: 1;
  }
}

.recentBooking {
  font-weight: 100;
  text-decoration: underline;
}
</style>
