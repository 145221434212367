<template>
  <SoonaSortable
    class="shot-list-draggable"
    :item-key="shot => shot.id"
    :list="shotList"
    :options="dragOptions"
    data-cypress="shot-list-card"
    @update="onDragUpdate"
  >
    <template #item="{ element: shot, index }">
      <div :key="shot.id" class="scene-card-wrapper">
        <div class="scene-card-header">
          <SoonaIcon class="handle" name="menu" />
          <p>
            <b class="scene-index">scene {{ index + 1 }}</b>
            <span class="divider" />
            <span
              v-if="shot.description != null"
              class="scene-card-description"
            >
              <b>DESCRIPTION: </b>
              <p
                class="scene-description"
                data-cypress="shot-list-scene-description"
              >
                {{ shot.description }}
              </p>
            </span>
          </p>
          <checkbox
            v-if="isFtSoonaStaff"
            :handle-click="statusClick"
            :item="shot"
            :status="shot.status === 'complete'"
          />
        </div>
        <div class="tags-list-wrapper">
          <span
            v-for="tagCategory in filterOutType(shot.tag_categories)"
            :key="'vtc' + tagCategory.id"
            class="tagCategory"
          >
            <template
              v-for="item in tagsForCategory(shot, tagCategory)"
              :key="item.id"
            >
              <shot-tag
                v-if="!hiddenEnvironment(item.title)"
                :tag-to-display="item"
                :is-selected="false"
                :tag-category="tagCategory"
              />
            </template>
          </span>
        </div>
        <div
          v-if="referenceTagImageUrls(shot).length != 0"
          class="inspo-img-wrapper"
        >
          <p class="scene-card-description">
            <b>REFERENCE IMAGES</b>
          </p>
          <div
            v-viewer="{
              toolbar: true,
              navbar: false,
              title: false,
              backdrop: true,
              zoomable: false,
              keyboard: true,
              scalable: false,
              fullscreen: false,
            }"
            class="reference-images"
          >
            <template v-for="tag in referenceTagImageUrls(shot)" :key="tag">
              <video
                v-if="tag.content_type?.startsWith('video/')"
                autoplay
                loop
                playsinline
                muted
                class="inspo-img mr-m"
              >
                <source :src="tag.url" :type="tag.content_type" />
              </video>
              <img
                v-else
                :src="tag.url"
                class="inspo-img mr-m"
                data-cypress="shot-list-reference-image"
              />
            </template>
          </div>
        </div>
        <div
          v-if="!submitted && !shootWrapped && currentUserIsAuthorized"
          class="shotbuilder-action-btns"
        >
          <button
            class="btn btn-sm btn-gray btn-icon-left"
            :class="{
              disabled: !canEditShotList,
              'pop-up-edit': isHoveringEdit,
            }"
            @click="canEditShotList ? editShot(shot) : null"
            @mouseover="!canEditShotList ? (isHoveringEdit = true) : null"
            @mouseleave="!canEditShotList ? (isHoveringEdit = false) : null"
          >
            <SoonaIcon name="pen-square" size="medium" />
            edit
          </button>
          <button
            class="btn btn-sm btn-gray btn-icon-left"
            :class="{
              disabled: !canEditShotList,
              'pop-up-duplicate': isHoveringDuplicate,
            }"
            @click="
              canEditShotList ? cloneShot(shot).then(scrollToLastShot) : null
            "
            @mouseover="!canEditShotList ? (isHoveringDuplicate = true) : null"
            @mouseleave="
              !canEditShotList ? (isHoveringDuplicate = false) : null
            "
          >
            <SoonaIcon name="duplicate" size="medium" />
            duplicate
          </button>
          <button
            class="btn btn-sm btn-gray btn-icon-left"
            :class="{
              disabled: !canEditShotList,
              'pop-up-delete': isHoveringDelete,
            }"
            @click="canEditShotList ? showDeleteModal(shot) : null"
            @mouseover="!canEditShotList ? (isHoveringDelete = true) : null"
            @mouseleave="!canEditShotList ? (isHoveringDelete = false) : null"
          >
            <SoonaIcon name="trash" size="medium" />
            delete
          </button>
        </div>
        <div v-if="isFtSoonaStaff" class="scene-card-footer">
          <p class="scene-last-updated-timestamp">
            last updated at
            {{ convertToMMDDYYYYlong(shot.updated_at) }}
            {{ convertToHHMM12(shot.updated_at) }}
          </p>
        </div>
      </div>
    </template>
  </SoonaSortable>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { useCapability } from '@/composables/useCapability';
import ShotTag from 'src/components/ShotTag.vue';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';
import Checkbox from '../../Checkbox.vue';
import dateTimeMixin from 'src/mixins/dateTimeMixin';
import SoonaSortable from '../../SoonaSortable.vue';

export default {
  name: 'SceneCard',
  components: {
    SoonaSortable,
    Checkbox,
    SoonaIcon,
    ShotTag,
  },
  mixins: [dateTimeMixin],
  props: {
    shotList: Array,
    showDeleteModal: Function,
    currentReservation: Object,
    submitted: Boolean,
    shootWrapped: Boolean,
    currentUserIsAuthorized: Boolean,
    canEditShotList: Boolean,
  },
  setup() {
    const { hasCapability: isFtSoonaStaff } = useCapability({
      capability: 'ft_soona_staff',
    });

    return { isFtSoonaStaff };
  },
  data() {
    return {
      isHoveringEdit: false,
      isHoveringDuplicate: false,
      isHoveringDelete: false,
    };
  },
  computed: {
    ...mapGetters('shotList', ['referenceTagImageUrls']),
    dragOptions() {
      let customerUserAndShootInProgress =
        this.currentReservation.status === 'in_progress' &&
        !this.isFtSoonaStaff;
      return {
        // selector to the wrapper element in order to bind events to
        draggable: '.scene-card-wrapper',
        handle: '.handle',
        animation: 150,
        disabled:
          customerUserAndShootInProgress ||
          this.shootWrapped ||
          !this.canEditShotList,
        ghostClass: 'ghost',
      };
    },
  },
  methods: {
    ...mapActions('shotList', [
      'editShot',
      'cloneShot',
      'updateShotStatus',
      'updateShotListOrder',
    ]),
    onDragUpdate(event) {
      const item = this.shotList[event.oldIndex];

      const newShotList = this.shotList.slice();
      newShotList.splice(event.oldIndex, 1);
      newShotList.splice(event.newIndex, 0, item);

      this.updateShotListOrder(newShotList);
    },
    statusClick(isChecked, shot) {
      this.updateShotStatus({ shot, isComplete: isChecked });
    },
    filterOutType(tagCategories) {
      return tagCategories.filter(tc =>
        [
          'Content',
          'Shot Subject',
          'Environment',
          'Backdrop Color',
          'Textures',
          'Props',
          'Custom Props',
          'Pro Service - Small',
          'Pro Service - Large',
        ].includes(tc.title)
      );
    },
    hiddenEnvironment(itemTitle) {
      let hiddenEnvironmentsList = ['Color Backdrop', 'Textured Surface'];
      let itemShouldBeHidden = hiddenEnvironmentsList.filter(
        item => item === itemTitle
      );
      return itemShouldBeHidden.length > 0;
    },
    tagsForCategory(shot, tagCategory) {
      return shot.shot_tags
        .filter(st => st.tag.tag_category_id == tagCategory.id)
        .map(st => st.tag);
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';

.shot-list-draggable {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ghost {
  background-color: variables.$bubbletape-pink-30 !important;
}
.ghost::after {
  border-bottom: 2px solid variables.$black-default;
  width: 110%;
  display: inline-block;
  content: ' ';
  position: relative;
  bottom: -35px;
  left: -50px;
}

.handle {
  align-self: flex-start;
  margin-right: 0.5rem;
  margin-top: 0.75rem;
  cursor: grab;
}

[draggable='true'] .handle {
  cursor: grabbing;
}

[data-sortable-disabled='true'] .handle {
  cursor: not-allowed;
}

.scene-card-wrapper {
  background-color: variables.$white-default;
  padding: 20px 40px;
  border: none !important;
  margin: 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.161);
  border-radius: 26px;
  width: 100%;
  .scene-card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    p {
      display: flex;
      width: 100%;
    }
    .scene-index {
      font-size: 30px;
    }
    .divider {
      border: 1px solid variables.$friendly-red-50;
      height: 20px;
      margin: 15px 10px 0;
    }
  }
  .scene-card-description {
    display: inline-block;
    width: 83%;
    margin-top: 13px;
    b {
      font-size: 14px;
      letter-spacing: 2.8px;
    }
    .scene-description {
      word-wrap: break-word;
      white-space: pre-line;
    }
  }
  .tags-list-wrapper {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  .tagCategory {
    display: flex;
    flex-wrap: wrap;
  }
  .inspo-img-wrapper {
    margin-top: 20px;
    width: 100%;
    .inspo-img {
      height: 200px;
      width: 200px;
      object-fit: cover;
      margin-top: 15px;
      cursor: pointer;
    }
  }
  .edit-shot-btn {
    background-color: variables.$periwink-blue-20;
    border-color: transparent;
    color: variables.$periwink-blue-60;
    font-size: 14px;
    margin: 0.5rem;
    padding: 5px 15px;
    &:hover {
      border-color: transparent;
      color: variables.$gray-90;
    }
  }
  .shotbuilder-action-btns {
    display: flex;
    flex-wrap: wrap;
    gap: 0.25rem;
    justify-content: flex-end;
    margin-top: 2rem;
  }
  .scene-card-footer {
    margin-top: 0.5rem;
    text-align: right;
  }
  .scene-last-updated-timestamp {
    color: gray;
    font-size: 0.75rem;
  }
}
.btn {
  align-items: center;
  background: variables.$white-default;
  border: 1px solid variables.$black-default;
  border-radius: 5px;
  display: inline-flex;
  font-weight: 800;
  justify-content: center;
  position: relative;

  &-sm {
    padding: 8px 16px;
    padding-top: 10px;
  }

  &-lg {
    padding: 10px 22px;
    padding-top: 12px;
  }

  &-gray {
    border-color: variables.$gray-30;
    font-size: 16px;
    text-transform: lowercase;

    &:hover,
    &:focus {
      background-color: variables.$gray-10;
      border-color: variables.$gray-40;
    }

    &:active {
      background-color: variables.$gray-20;
      border-color: variables.$black-default;
    }

    &.disabled {
      color: variables.$gray-40;
      cursor: default;
      border-color: variables.$gray-40;

      &:hover,
      &:focus {
        background-color: variables.$white-default;
        border-color: variables.$gray-40;
      }

      &:active {
        background-color: variables.$white-default;
        border-color: variables.$gray-40;
      }
    }
  }

  &-icon-left {
    svg {
      margin-bottom: 1px;
      margin-right: 6px;
    }
  }
}
.pop-up {
  &-edit,
  &-duplicate,
  &-delete {
    &:hover {
      &:before {
        background-color: variables.$black-default;
        border-radius: 5px;
        color: variables.$white-default;
        font-size: 14px;
        left: 50%;
        max-width: 219px;
        min-width: 204px;
        padding: 8px 10px;
        position: absolute;
        text-align: left;
        top: -65px;
        transform: translateX(-50%);
        z-index: 1;
      }

      &:after {
        border: 5px solid variables.$black-default;
        border-top-color: transparent;
        border-right-color: transparent;
        content: '';
        height: 5px;
        left: 50%;
        position: absolute;
        top: -22px;
        transform: rotate(-45deg) translateX(-50%);
        width: 5px;
      }
    }
  }
  &-edit {
    &:hover {
      &:before {
        content: "these shots are pre-defined and can't be edited.";
      }
    }
  }
  &-duplicate {
    &:hover {
      &:before {
        content: "these shots are pre-defined and can't be duplicated.";
      }
    }
  }
  &-delete {
    &:hover {
      &:before {
        content: "these shots are pre-defined and can't be deleted.";
      }
    }
  }
}
.hidden {
  display: none;
}
.divider {
  border-right: 0.09375rem solid variables.$primary;
  height: 6em;
}
</style>
