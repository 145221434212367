<script setup>
import { ref, computed, watch } from 'vue';
import SoonaToggle from 'src/components/ui_library/SoonaToggle.vue';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';
import TextInput from 'src/components/shared/TextInput.vue';
import { refDebounced } from '@vueuse/core';
import { useRoute } from 'vue-router';
import { useSearchTags } from 'src/queries/useSearchTags';
import { useBaseEvents } from 'src/composables/segment/useBaseEvents';

const props = defineProps({
  tagCategory: {
    type: Object,
    default: null,
    required: true,
  },
  modelValue: {
    type: Array,
    default: null,
    required: true,
  },
  type: {
    type: String,
    default: 'bubble',
    required: false,
  },
  proServiceProviderType: {
    type: String,
    required: true,
  },
  parentCategory: {
    type: String,
    default: undefined,
  },
});
const emits = defineEmits(['update:modelValue']);
const route = useRoute();
const tagCategory = computed(() => props.tagCategory);
const modelValue = computed(() => props.modelValue);
const type = computed(() => props.type);
const proServiceProviderType = computed(() => props.proServiceProviderType);
const parentCategory = computed(() => props.parentCategory);
const { buttonClicked, filterSelected } = useBaseEvents();
const tagsQuery = ref('');
const debouncedTagsQuery = refDebounced(tagsQuery, 200);
const { data: searchTags } = useSearchTags({
  tagCategoryId: computed(() => props.tagCategory.id),
  sortOrder: 'secondary_order',
  query: debouncedTagsQuery,
});
const searchedTags = computed(() => {
  return searchTags.value || [];
});
const { inputChanged } = useBaseEvents();
const textPlaceholderValue = computed(
  () => 'search by ' + tagCategory.value.title
);
const isFilterOpen = ref(false);
const showMore = ref(false);
const textInputType = 'selection-search';
const filteredProServiceTags = computed(() => {
  return searchedTags.value.filter(t => t.title !== 'no preference');
});
const secondSortedFilteredProServiceTags = computed(() => {
  let tags = [...filteredProServiceTags.value];
  tags.sort((a, b) => a.secondary_order - b.secondary_order);
  if (type.value === 'check' && tagCategory.value.options.top_choice_count) {
    tags = tags.slice(0, tagCategory.value.options.top_choice_count);
  }
  return tags;
});
const checkTagSets = computed(() => {
  let tagSets = [];
  for (let i = 0; i < secondSortedFilteredProServiceTags.value.length; i += 6) {
    tagSets.push(secondSortedFilteredProServiceTags.value.slice(i, i + 6));
  }
  return tagSets;
});
const showMoreButtonCopy = computed(() => {
  return showMore.value ? 'show fewer' : 'show more';
});
const showAllOptions = computed(() => {
  return showMore.value || tagsQuery.value?.length > 0;
});
watch(debouncedTagsQuery, () => {
  inputChanged({
    context: route.meta.context,
    subContext: 'pro service selection filter modal',
    inputLabel: textPlaceholderValue.value,
    inputType: textInputType,
    inputValue: null,
  });
});
function tagTitle(tag) {
  return (tag.icon ? tag.icon + ' ' : '') + tag.title;
}
function isSelected(tag) {
  let isChecked = modelValue.value.find(item => item === tag.id);
  return typeof isChecked != 'undefined';
}
function traitSelect(item) {
  if (!isSelected(item)) {
    let filterCategory = parentCategory.value
      ? parentCategory.value
      : tagCategory.value.title;
    let filterSubCategory = parentCategory.value ? tagCategory.value.title : '';
    filterSelected({
      context: route.meta.context,
      subContext: `${proServiceProviderType.value} filters modal`,
      filterCategory: filterCategory,
      filterSubCategory: filterSubCategory,
      filterLabel: item.title,
    });
  }
  emits('update:modelValue', item.id);
}
function toggleFilterOpen() {
  isFilterOpen.value = !isFilterOpen.value;
  const buttonAction = !isFilterOpen.value ? 'opens' : 'closes';
  buttonClicked({
    context: route.meta.context,
    subContext: `${proServiceProviderType.value} filters modal`,
    buttonLabel: tagCategory.value.title,
    buttonAction: `${buttonAction} filter option`,
  });
}
function toggleShowMore() {
  showMore.value = !showMore.value;
}
</script>
<template>
  <div class="pro-service-filter">
    <button
      :id="'pro-service-filter-expand-' + tagCategory.id"
      class="filter-header-button u-button-reset"
      :aria-expanded="isFilterOpen"
      :aria-controls="'pro-service-filter-expand-region-' + tagCategory.id"
      @click="toggleFilterOpen"
    >
      {{ tagCategory.title }}
      <SoonaIcon class="header-chevron" name="chevron-down" />
      <span class="u-a11y-only">toggle pro service filters</span>
    </button>
    <div
      :id="'pro-service-filter-expand-region-' + tagCategory.id"
      class="filter-container"
      :aria-labelledby="'pro-service-filter-expand-' + tagCategory.id"
      role="region"
    >
      <template v-if="isFilterOpen">
        <div v-if="tagCategory.options.searchable" class="search-bar">
          <TextInput
            v-model:model-value="tagsQuery"
            :type="textInputType"
            :placeholder="textPlaceholderValue"
          />
        </div>
        <span v-if="type === 'bubble'" class="bubble-filter">
          <SoonaButton
            v-for="tag in secondSortedFilteredProServiceTags"
            :key="tag.id"
            variation="filter"
            class="filter__btn"
            :aria-pressed="isSelected(tag)"
            :copy="tagTitle(tag)"
            :data-cypress="`filter-option-${tag.title
              .toLowerCase()
              .split(' ')
              .join('-')}`"
            size="medium"
            :on-click="() => traitSelect(tag)"
          />
        </span>
        <div v-else-if="type === 'check'" class="check-filter">
          <div v-if="!showAllOptions">
            <div
              v-for="(set, setIndex) in checkTagSets"
              :key="set[0].id"
              class="checkbox-set"
            >
              <SoonaToggle
                v-for="tag in set"
                :key="tag.id"
                class="checkbox-container"
                type="checkbox"
                :label="tag.title"
                :model-value="isSelected(tag)"
                @update:model-value="traitSelect(tag)"
              />
              <hr v-if="setIndex !== checkTagSets.length - 1" />
            </div>
          </div>
          <div v-else class="checkbox-set">
            <SoonaToggle
              v-for="tag in filteredProServiceTags"
              :key="tag.id"
              class="checkbox-container"
              type="checkbox"
              :label="tag.title"
              :model-value="isSelected(tag)"
              @update:model-value="traitSelect(tag)"
            />
          </div>
        </div>
        <SoonaButton
          v-if="tagCategory.options.expandable"
          variation="tertiary"
          :copy="showMoreButtonCopy"
          :on-click="toggleShowMore"
          size="medium"
        />
      </template>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables';

.pro-service-filter {
  width: 95%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;

  :deep(.filter__btn) {
    font-weight: 400;
  }

  .filter-header-button {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0.5rem 0;
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 0.25rem;

    .header-chevron {
      margin-left: 5px;
      color: variables.$gray-90;
      transition: transform 300ms ease-out;
      cursor: pointer;
    }
  }
  .filter-header-button[aria-expanded='true'] {
    .header-chevron {
      transform: rotate(180deg);
    }
  }

  .filter-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    .search-bar {
      width: 95%;
      margin-bottom: 0.75rem;
    }

    hr {
      margin-bottom: 0.5rem;
      margin-top: 0.5rem;
      color: variables.$bingo-blue-light;
      height: 0.063rem;
      border: 0.031rem solid variables.$bingo-blue-light;
      width: 95%;
    }

    .check-filter {
      display: flex;
      flex-direction: column;
      margin-left: 0.75rem;

      .checkbox-set {
        gap: 0.5rem;
        width: 100%;
        display: inline-flex;
        justify-content: left;
        flex-wrap: wrap;
        margin-bottom: 1rem;

        .checkbox-container {
          width: 30%;
          font-size: 1rem;

          :deep(input) {
            accent-color: variables.$friendly-red-50;
          }
        }
      }
    }

    .bubble-filter {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      .filter__btn {
        font-size: 1rem;
        margin: 0.25rem 0.1875rem 0.25rem 0.1875rem;
        padding: 0.5rem 0.813rem 0.563rem;

        &:last-child {
          margin-right: 0;
        }
      }

      .filter__btn[aria-pressed='true'] {
        background-color: variables.$friendly-red-20;
        border-color: variables.$friendly-red-40;
      }
    }
  }

  .filter-header-button[aria-expanded='false'] + .filter-container {
    display: none;
  }
}

@media only screen and (max-width: variables.$screen-xs-max) {
  .pro-service-filter {
    .filter-container {
      .bubble-filter {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}
</style>
