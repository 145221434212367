<script setup>
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

const inputValue = defineModel({ required: true });
</script>
<template>
  <div>
    <h3 class="u-badge--large">playback:</h3>
    <ul>
      <li>
        <input
          id="gif-playback--loop"
          v-model="inputValue"
          class="u-a11y-only"
          name="playback"
          type="radio"
          value="loop"
        />
        <label for="gif-playback--loop" class="u-body--heavy">
          <SoonaIcon name="refresh-cw" />
          1 loop
        </label>
      </li>
      <li>
        <input
          id="gif-playback--boomerang"
          v-model="inputValue"
          class="u-a11y-only"
          name="playback"
          type="radio"
          value="boomerang"
        />
        <label for="gif-playback--boomerang" class="u-body--heavy">
          <SoonaIcon name="infinity-sign" />
          boomerang
        </label>
      </li>
    </ul>
  </div>
</template>
<style scoped lang="scss">
@use '@/variables';

h3 {
  padding-bottom: 1rem;
}

ul {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  label {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 0.5rem;
  }
  input:focus-visible,
  label:focus-visible {
    outline: none;
  }

  input:focus-visible + label,
  input:checked + label {
    color: variables.$periwink-blue-50;
  }
}
</style>
