import { computed } from 'vue';
import PlaceholderImage from 'images/product-placeholder-periwink.jpg';

export const useCardContent = product => {
  const productName = computed(() => product.value.name);

  const imageSrc = computed(
    () => product.value.preview_url ?? PlaceholderImage
  );

  const sku = computed(
    () =>
      product.value.external_identifiers.find(
        x => x.external_identifier_type.slug === 'sku'
      )?.value
  );

  const asin = computed(
    () =>
      product.value.external_identifiers.find(
        x => x.external_identifier_type.slug === 'asin'
      )?.value
  );

  const inventoryPackage = computed(
    () => product.value.most_recent_in_studio_or_stored_package
  );

  const inventoryAtSoona = computed(
    () => product.value.inventory_at_soona_count
  );

  const mostRecentInventoryLocation = computed(() =>
    product.value.most_recent_in_studio_or_stored_package
      ?.location_short_name === 'DFS'
      ? 'MPLS'
      : product.value.most_recent_in_studio_or_stored_package
          ?.location_short_name
  );

  const totalLocationsWithInventory = computed(
    () => product.value.total_locations_with_inventory ?? 0
  );

  const location = computed(() => {
    if (totalLocationsWithInventory.value > 1) {
      return `${
        mostRecentInventoryLocation.value
      } (+${totalLocationsWithInventory.value - 1})`;
    } else {
      return mostRecentInventoryLocation.value;
    }
  });

  const inventoryCountMessage = computed(() => {
    return `${inventoryAtSoona.value?.toLocaleString() ?? 0} at soona`;
  });

  const bookingsCountMessage = computed(() => {
    return product.value.reservation_count === 1
      ? '1 booking'
      : `${product.value.reservation_count?.toLocaleString() ?? 0} bookings`;
  });

  return {
    asin,
    bookingsCountMessage,
    imageSrc,
    inventoryAtSoona,
    inventoryCountMessage,
    inventoryPackage,
    location,
    mostRecentInventoryLocation,
    productName,
    sku,
    totalLocationsWithInventory,
  };
};
