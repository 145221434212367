<script setup>
import { computed } from 'vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useRoute } from 'vue-router';
import DashboardDialog from '@/components/user/anytime/dashboard/DashboardDialog.vue';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';

const props = defineProps({
  contentData: {
    type: Object,
  },
});

const emits = defineEmits(['close']);

const { linkClicked } = useBaseEvents();
const route = useRoute();

const contentData = computed(() => props.contentData);
const bodyCopy = computed(() => contentData.value?.description?.body[0]);
const includesList = computed(() => contentData.value?.description?.includes);

const linkLabel = 'book this shoot';
const linkHref = `/book/pack/${contentData.value?.id}`;

const handleLinkClick = () => {
  linkClicked({
    context: route.meta.context,
    subContext: 'trending packs modal',
    linkLabel: linkLabel,
    linkHref: linkHref,
  });
};
</script>

<template>
  <DashboardDialog
    :content-data="contentData"
    sub-context="trending packs modal"
    @close="emits('close')"
  >
    <div class="trending-packs-dialog__action-btns">
      <SoonaButton
        element="a"
        :copy="linkLabel"
        :href="linkHref"
        data-cypress="button-dialog-book-pack"
        @on-click="handleLinkClick"
      />
    </div>
    <div class="trending-packs-dialog__details">
      <p class="trending-packs-dialog__body">{{ bodyCopy }}</p>
      <h4 class="u-label--heavy trending-packs-dialog__description-heading">
        includes
      </h4>
      <ul class="trending-packs-dialog__description-list">
        <li v-for="item in includesList" :key="item" class="u-label--regular">
          {{ item }}
        </li>
      </ul>
      <h4 class="u-label--heavy trending-packs-dialog__description-heading">
        pricing
      </h4>
      <ul class="trending-packs-dialog__description-list">
        <li class="u-label--regular">
          {{ contentData.pack_price }}
        </li>
      </ul>
    </div>
  </DashboardDialog>
</template>

<style lang="scss" scoped>
@use '@/variables';
.trending-packs-dialog {
  &__action-btns {
    display: flex;
    gap: 1rem;
    padding-bottom: 1rem;

    a {
      width: 100%;
    }
  }

  &__body {
    padding-bottom: 1.75rem;
  }

  &__description-heading {
    padding-bottom: 0.25rem;
    text-transform: uppercase;
  }

  &__description-list {
    list-style-type: disc;
    padding-bottom: 1.5rem;
    padding-left: 1.25rem;
  }
}
</style>
