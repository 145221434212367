<script setup>
import { ref, computed, onUnmounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';

import { useCreateGalleryFile } from '@/queries/media/useCreateGalleryFile';
import { useSaveDigitalAssetForeground } from '@/queries/digital_assets/useSaveDigitalAssetForeground';
import { useSoonaToast } from '@/components/ui_library/soona_toast/useSoonaToast';
import { usePriorityError } from '@/composables/usePriorityError';

import SoonaLoading from '@/components/SoonaLoading.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaSkeleton from '@/components/ui_library/SoonaSkeleton.vue';

const props = defineProps({
  file: {
    type: Object,
    default: undefined,
  },
  isMobileView: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(['show-foreground', 'close-editor', 'close-panel']);
const { addToast } = useSoonaToast();
const { linkClicked } = useBaseEvents();

const file = computed(() => props.file);
const fileId = computed(() => file.value?.id);
const filePreviewUrl = computed(
  () => file.value?.preview_url ?? file.value?.preview?.url
);
const fileForegroundUrl = computed(
  () => file.value?.foreground_url ?? file.value?.foreground?.url
);
const accountId = computed(() => file.value?.account_id);
const isMobileView = computed(() => props.isMobileView);
const isPhoto = computed(() => file.value?.media_type === 'photo');
const showingForeground = ref(false);
const panelLoading = ref(false);

const route = useRoute();

const fileSubjectType = computed(() => {
  if (!file.value) return '';
  switch (file.value?.subject_type) {
    case 'reservation_file':
      return 'ReservationFile';
    default:
      return 'DigitalAsset';
  }
});

const showForeground = () => {
  linkClicked({
    context: route.meta.context,
    subContext: 'media editor remove background menu',
    linkLabel: 'remove background',
    linkHref: null,
  });
  showingForeground.value = true;
  emits('show-foreground', showingForeground.value);
};

const resetImage = () => {
  showingForeground.value = false;
  emits('show-foreground', showingForeground.value);
};

// save to gallery
const {
  mutate: saveEditToGallery,
  isPending: createLoading,
  error: createError,
} = useCreateGalleryFile();

const {
  mutate: saveForegroundAsDigitalAsset,
  isPending: digitalAssetLoading,
  error: digitalAssetError,
} = useSaveDigitalAssetForeground(accountId, fileId);

const handleSuccess = (e = null) => {
  addToast(`we’re generating your image...`, {
    variation: 'success',
  });
  emits('close-editor', e);
};

const handleError = () => {
  addToast(`error saving edit`, {
    variation: 'error',
  });
};

const saveAsReservationFile = () => {
  saveEditToGallery(
    {
      file_id: fileId.value,
      type: 'replace_background',
      mode: 'transparent',
    },
    {
      onSuccess: handleSuccess,
      onError: handleError,
    }
  );
};

const saveAsDigitalAsset = () => {
  saveForegroundAsDigitalAsset(
    {},
    {
      onSuccess: e => handleSuccess(e),
      onError: handleError,
    }
  );
};

const handleSaveToGallery = () => {
  if (fileSubjectType.value === 'ReservationFile') {
    saveAsReservationFile();
  } else {
    saveAsDigitalAsset();
  }
};

defineExpose({ handleSaveToGallery, handleResetImage: resetImage });

const isLoading = computed(
  () => createLoading.value || digitalAssetLoading.value || panelLoading.value
);

const priorityError = usePriorityError(createError, digitalAssetError);

const previewImgLoaded = ref(false);
const foregroundImgLoaded = ref(false);
const imgAspectRatio = ref(1.5); //props open for SoonaSkeleton while images load

const loadingCopy = computed(() =>
  panelLoading.value ? 'loading…' : 'saving…'
);

watch(
  [fileId, fileForegroundUrl],
  fileVal => {
    if (!fileId.value) return;
    if (!isPhoto.value) {
      emits('close-panel');
    } else {
      panelLoading.value = true;
      if (fileVal && fileForegroundUrl.value) {
        previewImgLoaded.value = false;
        foregroundImgLoaded.value = false;
        imgAspectRatio.value = fileVal?.width / fileVal?.height;
        resetImage();
        panelLoading.value = false;
      }
    }
  },
  {
    immediate: true,
  }
);

onUnmounted(() => {
  resetImage();
});
</script>

<template>
  <SoonaError v-if="priorityError">
    {{ priorityError }}
  </SoonaError>
  <SoonaLoading
    v-if="isLoading"
    :is-contained="panelLoading"
    is-loading
    :loading-text="loadingCopy"
  />
  <div
    class="remove-background"
    :class="{ 'remove-background--mobile': isMobileView }"
  >
    <SoonaButton
      :disabled="showingForeground"
      variation="secondary-gray"
      class="remove-background__remove-bg"
      @on-click="showForeground"
    >
      <template #icon-left>
        <SoonaIcon name="scissors" size="medium" />
      </template>
      remove background
    </SoonaButton>
    <SoonaButton
      :disabled="!showingForeground"
      variation="secondary-gray"
      class="remove-background__reset-button"
      @on-click="resetImage"
    >
      reset adjustments
    </SoonaButton>
    <div
      v-if="isPhoto && !!filePreviewUrl && !!fileForegroundUrl && !isMobileView"
      class="remove-background__preview"
      :class="{
        'remove-background__preview--loaded':
          previewImgLoaded && foregroundImgLoaded,
      }"
    >
      <SoonaSkeleton
        v-if="!previewImgLoaded || !foregroundImgLoaded"
        class="remove-background__preview-loading"
      />
      <img
        :src="file.foreground_preview_url || fileForegroundUrl"
        alt=""
        @load="foregroundImgLoaded = true"
      />
      <div class="remove-background__preview-img">
        <img :src="filePreviewUrl" alt="" @load="previewImgLoaded = true" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.remove-background {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
  overflow: hidden;

  &--mobile {
    gap: 1.25rem;
    margin-top: 0;
    justify-content: flex-end;
  }

  &__preview {
    position: relative;
    order: -1;
    min-height: 5.75rem;
    --imgAspectRatio: v-bind('imgAspectRatio');
    aspect-ratio: var(--imgAspectRatio, auto);

    img {
      display: block;
      visibility: hidden;
    }

    &-loading {
      height: 100%;
    }

    &--loaded img {
      visibility: visible;
    }
  }

  &__preview-img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    overflow: hidden;
    clip-path: polygon(66.666% 0, 0 0, 0 100%, 33.333% 100%);
    will-change: clip-path;
    transition: width 0.5s cubic-bezier(0.22, 1, 0.36, 1) 0.08s;

    img {
      max-width: none;
      height: 100%;
    }

    @media (prefers-reduced-motion: no-preference) {
      .remove-background__preview--loaded & {
        animation: 0.8s cubic-bezier(0.22, 1, 0.36, 1) 0.2s both mask-over;
      }
    }
  }

  &__reset-button {
    margin-top: auto;

    .remove-background--mobile & {
      margin-top: 0;
    }
  }

  @media (prefers-reduced-motion: no-preference) and (hover: hover) {
    &__remove-bg:not(:disabled):hover,
    &__remove-bg:not(:disabled):focus-visible {
      + * + .remove-background__preview .remove-background__preview-img {
        width: 66.66%;
      }
    }

    &__reset-button:not(:disabled):hover,
    &__reset-button:not(:disabled):focus-visible {
      + .remove-background__preview .remove-background__preview-img {
        width: 133.333%;
      }
    }
  }
}

@keyframes mask-over {
  from {
    clip-path: polygon(100% 0, 0 0, 0 100%, 100% 100%);
  }
}
</style>
