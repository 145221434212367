<script setup>
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';

const props = defineProps({
  onClick: {
    type: Function,
    required: false,
  },
});
</script>

<template>
  <div class="shopify-sign-in">
    <SoonaButton
      class="shopify-sign-in__action-button"
      element="a"
      href="/shopify/login"
      copy="sign in with Shopify"
      variation="secondary-gray"
      size="medium"
      data-cypress="button-shopify"
      :on-click="props.onClick"
    >
      <template #icon-left>
        <img src="@images/shopify_glyph.svg" class="shopify-sign-in__icon" />
      </template>
    </SoonaButton>
  </div>
</template>
<style scoped lang="scss">
@use '@/variables';
@use '@/variables_fonts';

.shopify-sign-in {
  width: 100%;

  :deep(.soona-button) {
    @include variables_fonts.u-label--regular;
    width: 100%;
  }

  &__icon {
    height: 1.125rem;
  }
}
</style>
