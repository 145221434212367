<script setup>
import { computed } from 'vue';

const props = defineProps({
  isMostPopular: {
    default: false,
    required: true,
    type: Boolean,
  },
  isDisabled: {
    default: false,
    required: true,
    type: Boolean,
  },
  isSelected: {
    default: false,
    required: true,
    type: Boolean,
  },
  option: {
    default: () => {
      return {
        tag: {
          title: undefined,
          sub_title: undefined,
        },
      };
    },
    required: true,
    type: Object,
  },
});

const emits = defineEmits(['onSelectionChange']);

let durationClass = computed(() => {
  switch (props.option.tag.sub_title) {
    case 'up to 2 hour shoot':
      return 'photo-segment-card__duration--money';
    case 'up to 3 hour shoot':
      return 'photo-segment-card__duration--bubbletape';
    case 'up to 4 hour shoot':
      return 'photo-segment-card__duration--periwink';
    default:
      return undefined;
  }
});

function handleChange(option) {
  emits('onSelectionChange', option);
}
</script>

<template>
  <div>
    <input
      :id="`photo-segment-option-${option.tag.title}`"
      name="photo segment"
      type="radio"
      :checked="isSelected"
      class="u-a11y-only"
      :disabled="isDisabled"
      :data-cypress="`input-${option.tag.title.replace(/\s+/g, '')}`"
      @change="handleChange(option)"
    />
    <label
      v-bind="$attrs"
      class="photo-segment-card"
      :for="`photo-segment-option-${option.tag.title}`"
      :data-cypress="`label-${option.tag.title.replace(/\s+/g, '')}`"
    >
      <em v-if="isMostPopular" class="photo-segment-card__popular">
        most popular
      </em>
      <strong class="photo-segment-card__quantity">{{
        option.tag.title
      }}</strong>
      photos
      <small class="photo-segment-card__duration" :class="durationClass">
        {{ option.tag.sub_title }}
      </small>
    </label>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.photo-segment-card {
  @include variables_fonts.u-small--regular;

  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: variables.$white-default;
  border: 0.0625rem solid variables.$gray-30;
  border-radius: 0.625rem;
  color: variables.$black-default;
  cursor: pointer;
  padding: 1rem 0.5rem;
  position: relative;
  transition:
    box-shadow 0.1s ease-out,
    border-color 0.1s ease-out;
  flex: 1 1 8.5rem;

  input:checked + & {
    border-color: variables.$black-default;
  }

  input:disabled + & {
    opacity: 0.5;
    pointer-events: none;
  }

  input:focus-visible + & {
    filter: drop-shadow(0px 0px 2px variables.$periwink-blue-60);
  }

  &:hover,
  &:focus-visible {
    color: variables.$black-default;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
  }

  &:active {
    border-color: variables.$black-default;
  }

  &__popular {
    @include variables_fonts.u-badge--small;

    background-color: variables.$gray-20;
    border: 0.0625rem solid variables.$gray-30;
    border-radius: 0.3125rem;
    color: variables.$gray-60;
    padding: 0.125rem 0.375rem;
    position: absolute;
    top: -0.5625rem;
    left: 50%;
    transform: translateX(-50%);
    font-style: normal;
    width: max-content;
  }

  &__quantity {
    @include variables_fonts.u-headline--heavy;
  }

  &__duration {
    @include variables_fonts.u-badge--big;

    text-transform: lowercase;
    letter-spacing: 0.25px;
    margin-top: 0.75rem;
    background-color: variables.$tangerine-20;
    border-radius: 3.125rem;
    padding: 0.25rem 0.625rem;

    &--money {
      background-color: variables.$avo-toast-20;
    }

    &--bubbletape {
      background-color: variables.$bubbletape-pink-10;
    }

    &--periwink {
      background-color: variables.$periwink-blue-20;
    }
  }

  @media (min-width: variables.$screen-xxs-min) {
    min-width: 10.21875rem;
    flex: 0 1 auto;
  }

  @media (min-width: variables.$screen-sm-min) {
    min-width: 11.46875rem;
    padding: 1.25rem 0.5rem;
  }
}
</style>
