<script setup>
import { computed, useSlots } from 'vue';
import { useRoute } from 'vue-router';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useAddCollectionAssetsToBag } from '@/queries/bag_collection/useAddCollectionAssetsToBag';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
  collectionId: {
    type: [String, Number],
    required: true,
  },
  reservationId: {
    type: [String, Number],
    required: true,
  },
  linkLabel: {
    type: String,
    required: true,
  },
  subContext: {
    type: String,
    required: true,
  },
});

const emits = defineEmits(['toggleLoader']);

const slots = useSlots();

const route = useRoute();
const context = route.meta.context;
const { linkClicked } = useBaseEvents();

const accountId = computed(() => props.accountId);
const collectionId = computed(() => props.collectionId);
const linkLabel = computed(() => props.linkLabel);
const reservationId = computed(() => props.reservationId);
const subContext = computed(() => props.subContext);

const { mutate: bulkAddToBag, isPending } = useAddCollectionAssetsToBag(
  accountId,
  collectionId,
  reservationId
);

const addDefaultCollectionAssetsToBag = () => {
  emits('toggleLoader', true);
  bulkAddToBag(
    {},
    {
      onSettled: () => {
        emits('toggleLoader', false);
      },
    }
  );

  linkClicked({
    context: context,
    subContext: subContext.value,
    linkLabel: linkLabel.value,
    linkHref: '',
  });
};
</script>
<template>
  <div
    class="add-collection-container"
    data-cypress="add-collection-to-bag-div"
  >
    <h2 class="add-collection-container__title">
      <slot name="title" />
    </h2>
    <p v-if="slots['sub-title']">
      <slot name="sub-title" />
    </p>
    <SoonaButton
      class="add-collection-container-button"
      :disabled="isPending"
      @on-click="addDefaultCollectionAssetsToBag"
    >
      <template #icon-left>
        <SoonaIcon name="plus" />
      </template>
      <template #copy>
        <slot name="button-text" />
      </template>
    </SoonaButton>
  </div>
</template>
<style lang="scss" scoped>
@use 'src/variables';
@use 'src/variables_fonts';

.add-collection-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #ebedb8;
  padding: 1.25rem 0;
  margin: 0 0 1rem 0;

  &__title {
    @include variables_fonts.u-display--heavy;
    display: flex;
    align-items: center;
    gap: 0.5rem;

    &--icon {
      height: 2rem;
      width: 2rem;
    }
  }

  &-sub-title {
    margin-top: 8px;
  }

  &-button {
    margin-top: 16px;
  }
}
</style>
