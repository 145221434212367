<script setup>
import { ref, toRef, computed, watchEffect } from 'vue';
import { useReservation } from 'src/composables/useReservation';
import { useUpdateReservation } from 'src/queries/useUpdateReservation';
import { useCreatePackReservation } from '@/queries/useCreatePackReservation';
import { useProductsSearch } from 'src/queries/useProductsSearch';
import { useCapabilities } from 'src/composables/useCapabilities';
import {
  collapseSection,
  expandSection,
} from 'src/composables/useDrawerToggle';
import QuantityIncrementor from 'src/components/booking/QuantityIncrementor.vue';
import CaretViewDetails from 'src/components/svgs/CaretViewDetails.vue';
import SoonaHelperText from '@/components/ui_library/SoonaHelperText.vue';
import map from 'lodash/map';
import { usePriorityError } from 'src/composables/usePriorityError';
import SoonaError from 'src/components/ui_library/SoonaError.vue';
import { useBaseEvents } from 'src/composables/segment/useBaseEvents';
import TagRequirementDialog from '@/components/booking/v3/p2/TagRequirementDialog.vue';
import { toCurrency } from '@/lib/currency';
import { useApplyCoupon } from 'src/queries/reservations/useApplyCoupon';
import { useRemoveCoupon } from 'src/queries/reservations/useRemoveCoupon';
import { queryKeys } from 'src/queries/query-keys';
import { useQueryClient } from '@tanstack/vue-query';
import { useTiers } from '@/queries/tiers/useTiers';

const props = defineProps({
  affixed: {
    default: true,
    type: Boolean,
  },
  reservationId: String,
  reservationLineItems: {
    type: Array,
  },
});

const queryClient = useQueryClient();
const reservationLineItems = computed(() => props.reservationLineItems);

const { buttonClicked } = useBaseEvents();

const capabilitiesRef = ref([{ capability: 'ft_soona_staff' }]);
const capabilities = useCapabilities(capabilitiesRef);

const {
  reservation,
  requiredTagIds,
  isFree,
  isAnytime,
  isPack,
  estimatedPhotosNeeded,
  tagRequirements,
} = useReservation(toRef(props, 'reservationId'));

const { mutate, error: updateError } = useUpdateReservation(
  toRef(props, 'reservationId')
);

const { data: products, error: productsError } = useProductsSearch({
  locationId: computed(() => reservation.value?.location.id ?? null),
});

const {
  error: createPackResError,
  mutate: updatePack,
  isPending: isPackMutating,
} = useCreatePackReservation();

const { mutate: applyCoupon } = useApplyCoupon(toRef(props, 'reservationId'));
const { mutate: removeCoupon } = useRemoveCoupon(toRef(props, 'reservationId'));

const priorityError = usePriorityError(
  updateError,
  productsError,
  createPackResError
);

const { data: tiers } = useTiers();
const tier_info = computed(() => {
  return tiers.value?.find(
    tier => tier.slug === reservation.value.studio_access.level
  );
});

const rlisLoadingUpdate = ref([]);

const product = computed(() => {
  return productId => products.value?.find(p => p.id == productId);
});

const getProduct = productId => {
  return products.value?.find(p => p.id == productId);
};

const adjustable = product => {
  if (!reservation.value.isPickAPack) {
    return (
      product.product_type === 'reservation_model' ||
      (product.product_type === 'reservation_service' &&
        capabilities.value.ft_soona_staff.hasCapability)
    );
  } else {
    return product.product_type === 'studio_rental';
  }
};

const removable = (type, tag) => {
  const tagRemovable =
    requiredTagIds.value.indexOf(tag?.id) === -1 ||
    capabilities.value.ft_soona_staff.hasCapability;
  return (
    type !== 'studio_rental' &&
    type !== 'studio_fee' &&
    !reservation.value.isPickAPack &&
    tagRemovable
  );
};

const maxQuantityForLineItem = computed(() => {
  return product => {
    if (
      product.product_type === 'reservation_model' ||
      (product.product_type === 'reservation_service' &&
        capabilities.value.ft_soona_staff.hasCapability)
    ) {
      return 10;
    } else if (product.product_type === 'studio_rental') {
      return reservation.value.pack_configuration.max_quantity;
    }
  };
});

const rliLoading = computed(() => {
  return rliId => rlisLoadingUpdate.value.includes(rliId);
});

function removeFromIsLoading(lineItemId) {
  rlisLoadingUpdate.value = rlisLoadingUpdate.value.filter(
    id => lineItemId !== id
  );
}

const handleIncrement = (lineItem, maxQty) => {
  if (lineItem.quantity >= maxQty) return;
  rlisLoadingUpdate.value = [...rlisLoadingUpdate.value, lineItem.id];

  if (isPack.value) {
    updatePack(
      {
        reservationId: props.reservationId,
        packId: reservation.value.pack_configuration.id,
        quantity: lineItem.quantity + 1,
      },
      {
        onSettled: () => {
          removeFromIsLoading(lineItem.id);
        },
      }
    );
  } else {
    const dataToSubmit = {
      reservation_line_items: [
        {
          id: lineItem.id,
          quantity: lineItem.quantity + 1,
        },
      ],
    };

    mutate(dataToSubmit, {
      onSettled: () => {
        removeFromIsLoading(lineItem.id);
      },
    });
  }

  buttonClicked({
    context: 'booking',
    subContext: 'booking sidecart',
    buttonLabel: 'increment',
    buttonAction: 'handleIncrement',
  });
};

const handleDecrement = lineItem => {
  if (lineItem.quantity <= 1) return;

  rlisLoadingUpdate.value = [...rlisLoadingUpdate.value, lineItem.id];

  let rlis = [];
  if (isPack.value) {
    updatePack(
      {
        reservationId: props.reservationId,
        packId: reservation.value.pack_configuration.id,
        quantity: lineItem.quantity - 1,
      },
      {
        onSettled: () => {
          removeFromIsLoading(lineItem.id);
        },
      }
    );
  } else {
    rlis = [
      {
        id: lineItem.id,
        quantity: lineItem.quantity - 1,
      },
    ];

    const dataToSubmit = {
      reservation_line_items: rlis,
    };

    mutate(dataToSubmit, {
      onSettled: () => removeFromIsLoading(lineItem.id),
    });
  }

  buttonClicked({
    context: 'booking',
    subContext: 'booking sidecart',
    buttonLabel: 'decrement',
    buttonAction: 'handleDecrement',
  });
};
const lineItemDetailsOpen = ref({});

const isTagRequirementDialogOpen = ref(false);
const tagRequirement = ref({});
const currentRli = ref({});

const order = computed(() => {
  return reservation.value?.down_payment_order;
});

const removeRli = lineItem => {
  const dataToSubmit = {
    reservation_line_items: {
      id: lineItem.id,
      _destroy: true,
    },
  };

  mutate(dataToSubmit, {
    onSuccess: () => {
      delete lineItemDetailsOpen.value[lineItem.id];

      //re-applies discount to ensure that it is still valid after removing an RLI
      if (!order.value?.discount?.code) return;

      applyCoupon(
        {
          coupon: order.value?.discount?.external_discount_id,
          orderId: order.value?.id,
        },
        {
          onError: e => {
            if (e?.response?.data?.code !== 'invalid_coupon') return;
            removeCoupon(order.value?.id, {
              onSuccess: () => {
                queryClient.invalidateQueries({
                  queryKey: queryKeys.accountOrder(
                    reservation.value?.account_id,
                    {
                      orderId: order.value?.id,
                    }
                  ),
                });
              },
            });
          },
        }
      );
    },
    onSettled: () => removeFromIsLoading(lineItem.id),
  });

  buttonClicked({
    context: 'booking',
    subContext: 'booking sidecart',
    buttonLabel: 'remove',
    buttonAction: 'remove reservation line item',
  });
};

function handleOpenTagRequirementDialog(option, requirement) {
  tagRequirement.value = requirement;
  currentRli.value = option;
  isTagRequirementDialogOpen.value = true;
}

function handleAcceptTagRequirementDialog() {
  removeRli(currentRli.value);
  tagRequirement.value = {};
  currentRli.value = {};
  isTagRequirementDialogOpen.value = false;
}

function handleCancelTagRequirementDialog() {
  removeFromIsLoading(currentRli.value.id);
  tagRequirement.value = {};
  currentRli.value = {};
  isTagRequirementDialogOpen.value = false;
}

const handleRemoveRli = lineItem => {
  rlisLoadingUpdate.value = [...rlisLoadingUpdate.value, lineItem.id];

  const tagRequirement = tagRequirements.value.find(
    tr =>
      tr.required_tag_id === lineItem.tag.id &&
      tr.requirement_type === 'recommended'
  );
  if (tagRequirement) {
    handleOpenTagRequirementDialog(lineItem, tagRequirement);
  } else {
    removeRli(lineItem);
  }
};

const rliDescription = computed(() => {
  return productId =>
    products.value?.find(p => p.id == productId)?.description?.toLowerCase();
});

const rliDescriptionStudioRental = computed(() => {
  return productId =>
    products.value?.find(p => p.id == productId)?.description?.split(' - ')[1];
});

// TODO remove ugc code
const hasContentCreator = computed(() => {
  return reservation.value.tags?.some(tag => tag.title === 'content creator');
});

const membershipLineItemDetailsOpen = ref(false);
const toggleMembershipLineItemDetails = () => {
  membershipLineItemDetailsOpen.value = !membershipLineItemDetailsOpen.value;
};

watchEffect(() => {
  if (products.value) {
    map(reservationLineItems.value, rli => {
      if (
        getProduct(rli.product_id) &&
        lineItemDetailsOpen.value[rli.id] === undefined
      ) {
        if (
          reservation.value?.isPickAPack &&
          isAnytime.value &&
          getProduct(rli.product_id).product_type === 'studio_rental'
        ) {
          lineItemDetailsOpen.value[rli.id] = true;
        } else {
          lineItemDetailsOpen.value[rli.id] = false;
        }
      }
    });
  }
});
</script>

<template>
  <div class="booking-pricing" :class="{ 'booking-pricing--affixed': affixed }">
    <SoonaError v-if="priorityError">{{ priorityError }}</SoonaError>
    <h2 v-if="reservationLineItems?.length" class="booking-pricing__title">
      Pricing Info
    </h2>
    <TransitionGroup
      name="item-list"
      class="booking-pricing__itemized"
      tag="ul"
      :css="!isPackMutating"
      :aria-hidden="reservationLineItems?.length ? 'false' : 'true'"
    >
      <li
        v-for="rli in reservationLineItems"
        :key="rli.id"
        class="booking-pricing__item"
      >
        <img
          v-if="product(rli.product_id)"
          :src="product(rli.product_id).thumbnail_url"
          class="booking-pricing__item-img"
          alt=""
        />
        <div
          v-if="product(rli.product_id)"
          class="booking-pricing__item-content"
        >
          <div class="booking-pricing__item-title-price">
            <strong
              class="booking-pricing__item-title"
              :data-cypress="`sidecart-line-item-title-${product(
                rli.product_id
              ).name.replace(/\s+/g, '-')}`"
            >
              {{ product(rli.product_id).name }}
              <template
                v-if="
                  product(rli.product_id).name === 'Photo' ||
                  product(rli.product_id).name === 'Video'
                "
              >
                shoot down payment
                <SoonaHelperText
                  :title="
                    product(rli.product_id).name.toLowerCase() +
                    ' shoot down payment'
                  "
                >
                  down payment is non-refundable and will be applied to the
                  total cost of your shoot.
                </SoonaHelperText>
              </template>
            </strong>
            <em class="booking-pricing__item-price">
              <s
                v-if="rli.effective_percent_discount"
                class="u-body--regular booking-pricing__item-price-strike"
              >
                {{ toCurrency(product(rli.product_id).rate * rli.quantity) }}
              </s>
              <template v-if="!isFree">
                <strong
                  v-if="rli.effective_percent_discount"
                  class="u-body--heavy booking-pricing__item-price-discounted"
                >
                  {{
                    toCurrency(
                      product(rli.product_id).rate * rli.quantity -
                        rli.effective_percent_discount
                    )
                  }}
                </strong>
                <template v-else>
                  {{ toCurrency(product(rli.product_id).rate * rli.quantity) }}
                </template>
              </template>
              <template v-else> FREE</template>
            </em>
          </div>
          <div
            v-if="
              (adjustable(product(rli.product_id)) &&
                maxQuantityForLineItem(product(rli.product_id)) > 1) ||
              removable(product(rli.product_id).product_type, rli.tag)
            "
            class="booking-pricing__item-qty-remove"
          >
            <QuantityIncrementor
              v-if="
                adjustable(product(rli.product_id)) &&
                maxQuantityForLineItem(product(rli.product_id)) > 1
              "
              :disabled="rliLoading(rli.id)"
              :value="rli.quantity"
              :max="maxQuantityForLineItem(product(rli.product_id))"
              :min="1"
              @on-increment="
                () =>
                  handleIncrement(
                    rli,
                    maxQuantityForLineItem(product(rli.product_id))
                  )
              "
              @on-decrement="() => handleDecrement(rli)"
            />
            <button
              v-if="removable(product(rli.product_id).product_type, rli.tag)"
              :disabled="rliLoading(rli.id)"
              type="button"
              class="u-button-reset booking-pricing__item-remove"
              @click="handleRemoveRli(rli)"
            >
              remove
            </button>
          </div>
          <div
            class="booking-pricing__item-details"
            :class="{
              'booking-pricing__item-details--no-qty':
                !adjustable(product(rli.product_id)) ||
                maxQuantityForLineItem(product(rli.product_id)) <= 1,
            }"
          >
            <template
              v-if="
                product(rli.product_id).name === 'Photo' ||
                product(rli.product_id).name === 'Video'
              "
            >
              <p>
                <em class="u-lowercase">
                  this will cover your first {{ product(rli.product_id).name }}!
                  <br />
                  additional {{ product(rli.product_id).name }}s are ${{
                    (product(rli.product_id).rate * 100) / 100
                  }}
                  each.
                </em>
              </p>
            </template>
            <template v-else>
              <a
                :id="'bookingpricinglineitem-' + rli.id"
                :href="'#bookingpricinglineitemdetails' + rli.id"
                class="booking-pricing__view-details"
                :class="{
                  'booking-pricing__view-details--open':
                    lineItemDetailsOpen[rli.id],
                }"
                :aria-controls="'bookingpricinglineitemdetails-' + rli.id"
                :aria-expanded="lineItemDetailsOpen[rli.id] ? 'true' : 'false'"
                role="button"
                @click.prevent="
                  lineItemDetailsOpen[rli.id]
                    ? collapseSection(
                        $refs['lineItemDetails' + rli.id][0],
                        () => {
                          lineItemDetailsOpen[rli.id] = false;
                        }
                      )
                    : ((lineItemDetailsOpen[rli.id] = true),
                      expandSection($refs['lineItemDetails' + rli.id][0]),
                      buttonClicked({
                        context: 'booking',
                        subContext: 'booking sidecart',
                        buttonLabel: 'view details',
                        buttonAction: 'expand reservation line item details',
                      }))
                "
              >
                view details
                <CaretViewDetails />
              </a>
              <div
                v-if="product(rli.product_id)"
                :id="'bookingpricinglineitemdetails-' + rli.id"
                :ref="'lineItemDetails' + rli.id"
                class="u-drawer"
                :class="{ 'u-drawer--open': lineItemDetailsOpen[rli.id] }"
                :aria-labelledby="'bookingpricinglineitem-' + rli.id"
              >
                <p
                  v-if="
                    reservation.isPickAPack &&
                    isAnytime &&
                    product(rli.product_id).product_type === 'studio_rental' &&
                    !hasContentCreator
                  "
                >
                  1 virtual shoot you can join from your soona dashboard.
                </p>
                <p
                  v-if="
                    product(rli.product_id).product_type === 'studio_rental' &&
                    product(rli.product_id).description.indexOf(' - ') > -1
                  "
                  v-html="rliDescriptionStudioRental(rli.product_id)"
                />
                <template
                  v-else-if="
                    product(rli.product_id).product_type === 'studio_fee'
                  "
                >
                  <p>
                    studio fee to cover studio costs for your booking. switch to
                    a membership if you’d like to skip this fee.
                  </p>
                </template>
                <template v-else>
                  <p v-html="rliDescription(rli.product_id)" />
                  <p
                    v-if="
                      estimatedPhotosNeeded &&
                      (product(rli.product_id).product_type ===
                        'reservation_service' ||
                        product(rli.product_id).product_type ===
                          'reservation_model')
                    "
                  >
                    <i>
                      {{ product(rli.product_id).pricing_copy }}
                    </i>
                  </p>
                </template>
              </div>
            </template>
          </div>
        </div>
      </li>
    </TransitionGroup>

    <TagRequirementDialog
      v-if="isTagRequirementDialogOpen"
      :reservation-id="reservationId"
      :tag-requirement="tagRequirement"
      :adding-product="false"
      segment-context="booking"
      segment-sub-context="booking sidecart"
      @cancel-clicked="handleCancelTagRequirementDialog"
      @confirm-clicked="handleAcceptTagRequirementDialog"
    ></TagRequirementDialog>

    <!-- when studio fees are removed because customers opt into a subscription, we hack in the sub :( -->

    <TransitionGroup
      v-if="
        reservation?.studio_access?.level == 'tier-one' ||
        reservation?.studio_access?.level == 'tier-two'
      "
      name="item-list"
      class="booking-pricing__itemized"
      tag="ul"
      :css="!isPackMutating"
      :aria-hidden="reservationLineItems?.length ? 'false' : 'true'"
    >
      <li class="booking-pricing__item">
        <img
          v-if="true"
          :src="tier_info?.product?.thumbnail_url"
          class="booking-pricing__item-img"
          alt=""
        />
        <div class="booking-pricing__item-content">
          <div class="booking-pricing__item-title-price">
            <strong class="booking-pricing__item-title">
              {{ tier_info?.name }} membership
            </strong>
            <em class="booking-pricing__item-price">
              {{
                toCurrency(
                  tier_info?.product.prices.find(
                    p =>
                      p.recurring_interval ===
                      reservation.studio_access.billing_cycle
                  )?.unit_amount
                )
              }}
            </em>
          </div>
          <a
            class="booking-pricing__view-details"
            :class="{
              'booking-pricing__view-details--open':
                membershipLineItemDetailsOpen,
            }"
            role="button"
            @click.prevent="toggleMembershipLineItemDetails()"
          >
            view details
            <CaretViewDetails />
          </a>
          <div
            class="u-drawer"
            :class="{ 'u-drawer--open': membershipLineItemDetailsOpen }"
          >
            <p v-if="reservation?.studio_access?.level == 'tier-one'">
              {{
                reservation?.studio_access?.billing_cycle == 'month'
                  ? 'monthly'
                  : 'annual'
              }}
              fee for the basic membership. benefits include a wavied studio fee
              and basic access to soona’s AI tools.
            </p>
            <p v-else-if="reservation?.studio_access?.level == 'tier-two'">
              {{
                reservation?.studio_access?.billing_cycle == 'month'
                  ? 'monthly'
                  : 'annual'
              }}
              fee for the standard membership. benefits include a waived studio
              fee, free premium edits and 10% all models + upgrades, and full
              access to AI tools.
            </p>
          </div>
        </div>
      </li>
    </TransitionGroup>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.booking-pricing {
  margin: 1rem 0;

  &__title {
    @include variables_fonts.u-small--heavy;

    color: variables.$black-default;
    text-transform: uppercase;
    letter-spacing: 0.09375rem;
    margin-bottom: 1rem;
  }

  &__itemized {
    margin-top: 0.25rem;
  }

  &__item {
    padding: 1rem 0;
    border-top: 1px solid variables.$gray-30;

    &:last-child {
      padding-bottom: 0;
    }
  }

  &__item-img {
    display: none;
    flex: 0 0 3.875rem;
    max-width: 3.875rem;
    border-radius: 0.3125rem;
    margin-right: 0.75rem;
  }

  &__item-content {
    flex-grow: 1;
    padding: 0;
  }

  &__item-title-price {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 0.5rem;
    gap: 0.5rem;
  }

  &__item-title {
    display: flex;
    color: variables.$black-default;
    text-transform: lowercase;

    > .tooltip {
      align-self: center;
      padding-left: 0;
      margin-left: 0.5rem;
    }
  }

  &__item-price {
    display: flex;
    align-items: center;
    color: variables.$black-default;
    font-style: normal;
    text-align: right;
    gap: 0.25rem;

    &-strike {
      color: variables.$gray-60;
    }

    &-discounted {
      color: variables.$friendly-red-70;
    }
  }

  &__view-details {
    @include variables_fonts.u-body--regular;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: variables.$gray-60;
    margin-bottom: 0.5rem;

    > svg {
      display: block;
      margin-left: 0.25rem;
    }

    &--open {
      > svg {
        transform: rotate(180deg);
      }
    }
  }

  &__tags {
    margin-top: 0.5rem;

    dt {
      font-weight: 800;
    }

    dd {
      text-transform: lowercase;
    }
  }

  &__item-qty-remove {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    + .booking-pricing__item-details {
      margin-top: 0.5rem;

      &--no-qty {
        margin-top: -1.125rem;
      }
    }
  }

  &__item-remove {
    @include variables_fonts.u-label--regular;

    text-decoration: underline;
    margin-left: auto;

    &:hover,
    &:focus-visible {
      text-decoration: none;
    }
  }

  &__item-details {
    color: variables.$black-default;

    p + p {
      margin-top: 0.5rem;
    }
  }

  @media (min-width: 469px) and (max-width: 959px) {
    &__item {
      display: flex;
      align-items: flex-start;
    }

    &__item-img {
      display: block;
    }
  }

  @media (min-width: variables.$screen-sm-min) {
    &:not(.booking-pricing--affixed) {
      .booking-pricing__item {
        display: block;
      }

      .booking-pricing__item-img {
        display: none;
      }
    }
  }

  @media (min-width: variables.$screen-md-min) {
    &:not(.booking-pricing--affixed) {
      .booking-pricing__item {
        display: flex;
        align-items: flex-start;
      }

      .booking-pricing__item-img {
        display: block;
      }
    }
  }

  @media (min-width: variables.$screen-lg-min) {
    margin: 1.5rem 0;

    &__item {
      display: flex;
      align-items: flex-start;
    }

    &__item-img {
      display: block;
    }
  }
}

// Animating RLIs in/out
.item-list-enter-active,
.item-list-leave-active {
  transition:
    opacity 0.3s ease-out,
    transform 0.3s ease-out;
}

.item-list-enter-from {
  opacity: 0;
  transform: translateX(-1.25rem);
}

.item-list-leave-to {
  opacity: 0;
  transform: translateX(1.25rem);
}
</style>
