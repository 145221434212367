<script setup>
import SubscriptionPurchase from '@/components/platform-layout/sidebar/SubscriptionPurchase.vue';
import CreditPurchase from '@/components/platform-layout/sidebar/CreditPurchase.vue';
import UserButton from '@/components/platform-layout/sidebar/UserButton.vue';

const emits = defineEmits(['open-user-nav']);
</script>

<template>
  <div class="user-hub">
    <SubscriptionPurchase />
    <UserButton @click="emits('open-user-nav')" />
    <CreditPurchase />
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.user-hub {
  background: variables.$gradient-periwink-error;
  border-radius: 0.5rem;
}
</style>
