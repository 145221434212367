<script setup>
import { computed } from 'vue';
import { toCurrency } from '@/lib/currency';
import FeatureList from '@/components/subscriptions/FeatureList.vue';
import SoonaSubscriptionBadge from '@/components/ui_library/SoonaSubscriptionBadge.vue';

const props = defineProps({
  selectedBillingInterval: {
    default: 'year',
    type: String,
    validator: function (value) {
      return ['month', 'year'].includes(value);
    },
  },
  tier: {
    type: Object,
    required: true,
  },
  showBorder: {
    default: false,
    type: Boolean,
  },
});

const selectedBillingInterval = computed(() => props.selectedBillingInterval);

const price = computed(() =>
  selectedBillingInterval.value === 'year'
    ? props.tier.product.prices?.find(p => p.recurring_interval === 'year')
    : props.tier.product.prices?.find(p => p.recurring_interval === 'month')
);

const amount = computed(() => {
  if (!price.value) return 0;

  return price.value.recurring_interval === 'year'
    ? +price.value.unit_amount / 12
    : +price.value.unit_amount;
});
</script>

<template>
  <article class="tier-card" :class="{ 'tier-card--border': showBorder }">
    <SoonaSubscriptionBadge
      class="tier-card__badge"
      :icon-name="tier.product?.icon_name"
      icon-size="large"
      :tier-slug="tier.slug"
      :tier-title="tier.name"
    />
    <div class="tier-card__top" :data-tier-slug="tier.slug">
      <h3 class="u-headline--heavy">{{ tier.name }}</h3>
      <p class="u-body--heavy tier-card__price">
        {{ toCurrency(amount, price?.currency, 0) }}/month
      </p>
      <p class="u-label--regular tier-card__summary">
        {{ tier.product.tier_card_summary }}
      </p>
    </div>
    <div class="tier-card__bottom">
      <FeatureList
        class="tier-card__features"
        :feature-list="tier.product.short_feature_list"
        :has-no-gap="true"
        :show-tooltip="true"
      />
      <slot />
    </div>
  </article>
</template>

<style lang="scss" scoped>
@use '@/variables';

.tier-card {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  max-width: 28.75rem;
  margin-left: auto;
  margin-right: auto;

  &--border {
    border: 0.0625rem solid variables.$gray-30;
    border-radius: 0.625rem;

    .tier-card__top {
      border-top-left-radius: 0.5625rem;
      border-top-right-radius: 0.5625rem;
    }
  }

  &__badge {
    position: absolute;
    top: -1.25rem;
    left: 50%;
    transform: translateX(-50%);
  }

  &__top {
    border-top-left-radius: 0.625rem;
    border-top-right-radius: 0.625rem;
    padding: 1.25rem;
    padding-top: 2rem;
    text-align: center;

    &[data-tier-slug='tier-one'] {
      background-color: variables.$periwink-blue-20;
    }

    &[data-tier-slug='tier-two'] {
      background-color: variables.$green-apple-10;
    }

    &[data-tier-slug='tier-three'] {
      background-color: variables.$tangerine-20;
    }
  }

  &__price {
    margin-bottom: 0.75rem;
  }

  &__summary {
    text-wrap: balance;
  }

  &__bottom {
    background-color: variables.$white-default;
    border-bottom-left-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 1.25rem 1rem;
  }

  &__features {
    padding-bottom: 1.5rem;
  }

  &__btn {
    margin-top: auto;
  }
}
</style>
