<template>
  <div class="surprise-shoot-view">
    <h3>🎉 soona surprise 🎉</h3>
    <p class="has-text-centered">
      surprise! we have gifted you a shoot full of bonus content. shop away!
    </p>
    <lottie-player
      autoplay
      loop
      src="@images/anim/lottie-surprise-shoot.lott"
      style="width: 25vw; display: inline-block"
    />
    <SoonaButton
      element="router-link"
      copy="shop surprise content"
      :to="{ name: 'gallery' }"
    />
  </div>
</template>

<script>
import SoonaButton from '@/components/ui_library/SoonaButton.vue';

export default {
  name: 'SurpriseShootDescription',
  components: {
    SoonaButton,
  },
  props: {
    reservationId: Number,
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';

.surprise-shoot-view {
  border: 0.25rem solid variables.$friendly-red-30;
  margin: 1rem 0.5rem;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
