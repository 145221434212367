<script setup>
import { computed } from 'vue';
import {
  AvoToast20,
  AvoToast60,
  BlackDefault,
  FriendlyRed30,
  FriendlyRed60,
  Gray20,
  Tangerine30,
} from '@/variables.module.scss';
import AssetDetails from './AssetDetails.vue';
import AssetHeading from './AssetHeading.vue';

defineProps({
  addOn: {
    required: true,
    type: Object,
  },
  options: {
    required: true,
    type: Array,
  },
});

const asset = defineModel('asset', {
  default: null,
  type: Object,
});

const flagData = computed(() => {
  if (asset.value?.edit_status === 'accepted') {
    return {
      backgroundColor: AvoToast60,
      textColor: AvoToast20,
      title: 'edit delivered',
    };
  }

  if (asset.value?.edit_status === 'pending') {
    return {
      backgroundColor: Tangerine30,
      textColor: BlackDefault,
      title: 'edit pending',
    };
  }

  if (asset.value?.visibility !== 'all') {
    return {
      backgroundColor: Gray20,
      textColor: BlackDefault,
      title: 'hidden',
    };
  }

  return {
    backgroundColor: FriendlyRed30,
    textColor: FriendlyRed60,
    title: 'needs edit',
  };
});

const speed = computed(() => {
  if (!asset.value?.options?.speed) return '';

  const speedOption = asset.value.options.speed;

  return `${speedOption} ms, ${(1000 / speedOption).toFixed(1)} frames/s`;
});
</script>

<template>
  <div :key="asset?.file?.url || `comparison-item`" class="comparison-item">
    <AssetHeading
      v-model:asset="asset"
      :add-on="addOn"
      :flag-data="flagData"
      :options="options"
    />
    <div class="comparison-item__asset">
      <img
        v-if="asset && asset.file.is_image"
        :src="asset.file.url"
        :alt="`${asset.edit_status} uploaded at ${asset.created_at}`"
      />
      <video
        v-else-if="asset && asset.file.is_video"
        :key="asset.preview_url"
        :poster="asset.preview_url"
        controls
        controlsList="nodownload"
        class="preview-media"
      >
        <source :key="asset.file.url" :src="asset.file.url" type="video/mp4" />
      </video>
    </div>
    <AssetDetails
      v-if="asset"
      :add-ons="addOn.media_add_ons"
      :options="asset.options"
      :note="asset.note"
      :speed="speed"
    />
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.comparison-item {
  background-color: variables.$white-default;
  border: 0.0625rem solid variables.$gray-30;
  border-radius: 0.625rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1 1 50%;
  padding: 1rem 0;

  &__asset {
    align-items: center;
    background-color: variables.$gray-10;
    display: flex;
    flex-grow: 1;
    max-height: 40rem;
    justify-content: center;

    img,
    video {
      height: 100%;
      max-height: 100%;
      max-width: 100%;
      object-fit: contain;
      width: 100%;
    }
  }
}
</style>
