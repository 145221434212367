<script setup>
import { computed, ref } from 'vue';
import AutoEditsFeedback from '@/components/crew/AutoEditsFeedback.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaError from 'src/components/ui_library/SoonaError.vue';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaUserAvatar from '@/components/ui_library/SoonaUserAvatar.vue';
import { queryKeys } from '@/queries/query-keys';
import { useQueryClient } from '@tanstack/vue-query';
import { useUpdateReservationFile } from '@/queries/reservation-files/useUpdateReservationFile';
import { usePriorityError } from 'src/composables/usePriorityError';

const props = defineProps({
  orderId: {
    required: true,
    type: [Number, String],
  },
  originalReservationFileId: {
    required: true,
    type: [Number, String],
  },
  reservationFile: {
    required: true,
    type: Object,
  },
  addOnLineItemId: {
    required: false,
    type: [Number, String],
  },
  enableReview: {
    required: true,
    type: Boolean,
  },
});

const orderId = computed(() => props.orderId);
const originalReservationFileId = computed(
  () => props.originalReservationFileId
);
const reservationFile = computed(() => props.reservationFile);
const addOnLineItemId = computed(() => props.addOnLineItemId);

const showFeedback = ref(false);

const queryClient = useQueryClient();

const { mutate, isPending, error } = useUpdateReservationFile(reservationFile);

const priorityError = usePriorityError(error);

const bustCache = async () => {
  const promises = [
    queryClient.invalidateQueries({
      queryKey: queryKeys.orderReservationFile(
        orderId,
        originalReservationFileId
      ),
    }),
  ];

  if (props.addOnLineItemId) {
    promises.push(
      queryClient.invalidateQueries({
        queryKey: queryKeys.orderAddOns(orderId, addOnLineItemId),
      })
    );
  }

  await Promise.all(promises);
};

const pend = () => {
  mutate({ edit_status: 'pending_edit' }, { onSuccess: bustCache });
};

const confirmReject = () => {
  showFeedback.value = false;
  mutate({ edit_status: 'rejected_edit' }, { onSuccess: bustCache });
};

const reject = () => {
  if (reservationFile.value.auto_edited) showFeedback.value = true;
  else {
    confirmReject();
  }
};
</script>
<template>
  <AutoEditsFeedback
    v-if="showFeedback"
    category="auto_edits_post_production"
    subject-type="ReservationFile"
    :subject-id="reservationFile.id"
    :open="showFeedback"
    options-type="post"
    @close="() => (showFeedback = false)"
    @confirm="confirmReject"
  />
  <div class="asset-summary-wrapper">
    <SoonaError v-if="priorityError">
      {{ priorityError }}
    </SoonaError>
    <div class="asset-summary-wrapper__icon">
      <SoonaIcon
        v-if="reservationFile.auto_edited"
        name="wand-magic-sparkles-monochrome"
        size="small"
      />
      <SoonaUserAvatar v-else img-size="1.25rem" svg-size="1.25rem" />
    </div>
    <div class="asset-summary-wrapper__text">
      <time class="created-at">{{ reservationFile.created_at }}</time
      ><br />
      <span class="asset-summary-title">{{ reservationFile.title }}</span>
    </div>
    <div class="asset-summary-wrapper__actions">
      <SoonaButton
        v-if="enableReview && reservationFile.edit_status === 'pending_edit'"
        copy="hide"
        size="medium"
        variation="tertiary"
        :disabled="isPending"
        @on-click="reject"
      >
        <template #icon-left>
          <SoonaIcon
            name="circle-xmark"
            size="small"
            style="vertical-align: middle"
          />
        </template>
      </SoonaButton>
      <SoonaButton
        v-else-if="
          enableReview && reservationFile.edit_status === 'rejected_edit'
        "
        copy="show"
        size="medium"
        variation="tertiary"
        :disabled="isPending"
        @on-click="pend"
      >
        <template #icon-left>
          <SoonaIcon
            name="flip-backward"
            size="small"
            style="vertical-align: middle"
          />
        </template>
      </SoonaButton>
      <SoonaButton
        copy="download file"
        size="medium"
        element="a"
        variation="tertiary"
        :href="reservationFile.file.url"
        :download="reservationFile.file.filename"
      >
        <template #icon-left>
          <SoonaIcon
            name="arrow-down-to-bracket"
            size="small"
            style="vertical-align: middle"
          />
        </template>
      </SoonaButton>
      <SoonaButton
        v-if="reservationFile.raw"
        copy="download raw"
        size="medium"
        element="a"
        variation="tertiary"
        :href="reservationFile.raw.url"
        :download="reservationFile.raw.filename"
      >
        <template #icon-left>
          <SoonaIcon
            name="arrow-down-to-bracket"
            size="small"
            style="vertical-align: middle"
          />
        </template>
      </SoonaButton>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.asset-summary-wrapper {
  &__icon {
    display: inline-block;
    width: fit-content;
    vertical-align: top;
    padding-top: 0.25rem;
    padding-right: 0.25rem;
  }
  &__text {
    display: inline-block;
    width: auto;
  }
  &__actions {
    width: auto;
    float: right;
  }
}
.user-avatar {
  display: inline-block;
}
</style>
