<script setup>
import { computed } from 'vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

const props = defineProps({
  option: {
    required: true,
    type: Object,
  },
});

const selected = defineModel('selected', {
  type: String,
  required: true,
});

const radioId = computed(
  () => `radio-${props.option.title.replace(/\s+/g, '-')}`
);
</script>

<template>
  <div class="content-option">
    <input
      :id="radioId"
      v-model="selected"
      class="u-a11y-only"
      name="content-option"
      type="radio"
      :value="option.title"
    />
    <label :for="radioId" class="content-option__label">
      <span class="icon-wrapper">
        <SoonaIcon :name="option.icon_name" size="x-large" />
      </span>
      <span class="u-label--heavy content-option__title">
        {{ option.title }}
      </span>
    </label>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.content-option {
  flex-shrink: 0;
  width: 6rem;

  &:nth-child(2) {
    margin-left: auto;
  }

  &:last-child {
    margin-right: auto;
  }

  &__label {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    height: 100%;
    position: relative;

    input:checked + & .icon-wrapper {
      background-color: variables.$bubbletape-pink-40;
      border-color: variables.$bubbletape-pink-70;
      color: variables.$white-default;
    }

    input:focus-visible + & .icon-wrapper {
      outline: 0.125rem solid variables.$periwink-blue-50;
      outline-offset: -0.125rem;
    }
  }

  .icon-wrapper {
    align-items: center;
    background-color: variables.$white-default;
    border: 0.0625rem solid variables.$bubbletape-pink-20;
    border-radius: 0.625rem;
    box-shadow: variables.$elevation-0;
    color: v-bind('option.icon_color');
    cursor: pointer;
    display: flex;
    height: 4.75rem;
    justify-content: center;
    transition:
      background-color 0.1s,
      color 0.1s;
    width: 100%;

    &:hover {
      // to be determined
      background-color: variables.$white-default;
    }
  }

  &__title {
    align-items: center;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    text-align: center;
  }
}
</style>
