<template>
  <div class="carousel">
    <button
      v-if="images && images.length > 1"
      class="left-btn"
      @click="moveLeft()"
    >
      <SoonaIcon name="chevron-left" />
      <span class="u-a11y-only">previous image</span>
    </button>
    <div class="image-pips-wrapper">
      <Viewer
        :options="{
          toolbar: false,
          navbar: false,
          backdrop: true,
          minZoomRatio: 0.01,
          maxZoomRatio: 10,
          url: 'data-source',
          container: container,
        }"
        @inited="inited"
        @viewed="onView"
      >
        <img
          :src="currentImageSrc"
          :alt="currentImageAlt"
          :class="placeholderNeeded ? 'placeholder' : ''"
          :style="`height: ${height}; width: ${width}`"
          :data-source="currentImageSrc"
        />
      </Viewer>
      <ul v-if="imagePips" class="all-pips">
        <li
          v-for="(image, i) in images"
          :key="`pipId-${i}`"
          :class="pipClass(image)"
        />
      </ul>
    </div>
    <button
      v-if="images && images.length > 1"
      class="right-btn"
      @click="moveRight()"
    >
      <SoonaIcon name="chevron-right" />
      <span class="u-a11y-only">next image</span>
    </button>
  </div>
</template>

<script>
import { ref } from 'vue';
import uniqueId from 'lodash/uniqueId';
import accountImage from 'images/account-placeholder.svg';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { component as Viewer } from 'v-viewer';

export default {
  name: 'SoonaCarousel',
  components: {
    SoonaIcon,
    Viewer,
  },
  props: {
    images: Array,
    height: {
      type: String,
      default: '150px',
      required: false,
    },
    width: {
      type: String,
      default: '150px',
      required: false,
    },
    wrapAround: {
      type: Boolean,
      default: true,
      required: false,
    },
    imagePips: {
      type: Boolean,
      default: true,
      required: false,
    },
    container: {
      type: [String, null],
      default: null,
      required: false,
    },
  },
  setup(props) {
    const viewer = ref(null);

    function inited(newViewer) {
      viewer.value = newViewer;
    }

    function onView() {
      const image = viewer.value.image;
      const container = props.container
        ? document.querySelector(props.container)
        : null;
      const screenWidth = container ? container.clientWidth : window.innerWidth;
      const screenHeight = container
        ? container.clientHeight
        : window.innerHeight;

      const widthZoom = screenWidth / image.naturalWidth;
      const heightZoom = screenHeight / image.naturalHeight;

      const calculatedZoom = Math.min(widthZoom, heightZoom);
      const finalZoom = calculatedZoom / 1.5;

      let x = null;
      let y = null;
      if (props.container) {
        // Calculate the moveTo coordinates to center the image with the final zoom
        x = (container.clientWidth - image.naturalWidth * finalZoom) / 2;
        y = (container.clientHeight - image.naturalHeight * finalZoom) / 2;
      }

      viewer.value.zoomTo(finalZoom);
      if (props.container) viewer.value.moveTo(x, y);
    }

    return { inited, onView };
  },
  data() {
    const baseId = uniqueId();
    return {
      currentImageIndex: 0,
      pipId: `carousel-pip-${baseId}`,
    };
  },
  computed: {
    placeholderNeeded() {
      let currentImage = this.images
        ? this.images[this.currentImageIndex]
        : null;
      return !currentImage;
    },
    currentImageAlt() {
      if (!this.images) return '';
      return this.images[this.currentImageIndex]?.alt
        ? this.images[this.currentImageIndex]?.alt
        : '';
    },
    currentImageSrc() {
      return this.placeholderNeeded
        ? accountImage
        : this.images[this.currentImageIndex]?.src
          ? this.images[this.currentImageIndex]?.src
          : this.images[this.currentImageIndex];
    },
  },
  methods: {
    moveRight() {
      if (!this.images) {
        return;
      }
      if (this.currentImageIndex < this.images.length - 1) {
        this.currentImageIndex += 1;
      } else {
        this.currentImageIndex = 0;
      }
    },
    moveLeft() {
      if (!this.images) {
        return;
      }
      if (this.currentImageIndex > 0) {
        this.currentImageIndex -= 1;
      } else {
        this.currentImageIndex = this.images.length - 1;
      }
    },
    pipClass(image) {
      if (
        image == this.images[this.currentImageIndex] ||
        image.src === this.images[this.currentImageIndex]?.src
      ) {
        return 'image-pip active';
      }
      return 'image-pip';
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';
.carousel {
  display: flex !important;
  align-items: center;
  justify-content: center;
  .image-pips-wrapper {
    max-width: 100%;

    img {
      z-index: 1;
      object-fit: cover;
      &.placeholder {
        padding: 10px;
        object-fit: contain;
      }

      &:hover {
        cursor: zoom-in;
      }
    }
    .all-pips {
      display: flex;
      justify-content: center;
      z-index: 1;
      position: relative;
      bottom: 1.25rem;
      height: 0;
      .image-pip {
        opacity: 0.8;
        height: 0.375rem;
        width: 0.375rem;
        background-color: variables.$white-default;
        border-radius: 50%;
        margin: 0 0.1875rem;
        &.active {
          opacity: 1;
        }
      }
    }
  }

  .left-btn,
  .right-btn {
    align-items: center;
    background-color: variables.$white-default;
    border: none;
    border-radius: 50%;
    box-shadow: variables.$elevation-2;
    display: flex;
    height: 1.875rem;
    justify-content: center;
    opacity: 0.8;
    margin: -0.6875rem;
    transition: opacity 0.2s;
    visibility: hidden;
    width: 1.875rem;
    z-index: 1;

    &:hover {
      opacity: 1;
    }
  }

  &:hover {
    .left-btn,
    .right-btn {
      visibility: visible;
    }
  }
}
</style>
