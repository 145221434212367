import { track } from '@/lib/segment/segment';
import { useCurrentReservationAndUser } from '@/composables/segment/useCurrentReservationAndUser';

export function useShotListEvents() {
  const { reservationTraits, userTraits } = useCurrentReservationAndUser();

  /**
   * Scene Item Selected
   * @param {string} context what feature am I in?
   * @param {string} subContext where within a feature am I? be specific
   * @param {string} group which section of the card am I in?
   * @param {string} sceneItemLabel the label on the button
   * @param {boolean} sceneItemValue is this item selected or not?
   */
  const sceneItemSelected = ({
    context,
    subContext,
    group,
    sceneItemLabel,
    sceneItemValue,
  } = {}) => {
    const payload = {
      context,
      subContext,
      group,
      sceneItemLabel,
      sceneItemValue,
      ...userTraits.value,
    };
    track('Scene Item Selected', payload);
  };

  /**
   * Scene Item Selection Opened
   * @param {string} context what feature am I in?
   * @param {string} subContext where within a feature am I? be specific
   * @param {string} group which section of the card am I in?
   * @param {string} sceneItemLabel the label on the button
   * @param {string} sceneItemAction what this button does
   */
  const sceneItemSelectionOpened = ({
    context,
    subContext,
    group,
    sceneItemLabel,
    sceneItemAction,
  } = {}) => {
    const payload = {
      context,
      subContext,
      group,
      sceneItemLabel,
      sceneItemAction,
      ...userTraits.value,
    };
    track('Scene Item Selection Opened', payload);
  };

  /**
   * Scene Started
   * @param {string} context what feature am I in?
   * @param {string} subContext where within a feature am I? be specific
   */
  const sceneStarted = ({ context, subContext } = {}) => {
    const payload = {
      context,
      subContext,
      ...{ reservation: reservationTraits.value },
      ...userTraits.value,
    };
    track('Scene Started', payload);
  };

  /**
   * Shot Builder Started
   * @param {string} context what feature am I in?
   * @param {string} subContext where within a feature am I? be specific
   */
  const shotBuilderStarted = ({ context, subContext } = {}) => {
    const payload = {
      context,
      subContext,
      ...{ reservation: reservationTraits.value },
      ...userTraits.value,
    };
    track('Shot Builder Started', payload);
  };

  return {
    sceneItemSelected,
    sceneItemSelectionOpened,
    sceneStarted,
    shotBuilderStarted,
  };
}
