<template>
  <button
    class="shot-tag"
    :style="{ backgroundColor: tagBackgroundColor() }"
    :title="tagToDisplay.title"
    :class="{ 'btn-is-selected': isSelected }"
    :data-cypress="`shot-list-shot-tag-${tagToDisplay.title
      .replace(/\s+/g, '-')
      .toLowerCase()}`"
    @click="
      handleClick
        ? handleClick(tagCategory.id, tagToDisplay, tagCategory.title)
        : ''
    "
  >
    <img
      v-if="tagToDisplay.image_url && !customTagSubject"
      :src="tagToDisplay.image_url"
      class="tag-image"
      :class="{ 'img-is-selected': isSelected }"
    />
    <span
      v-else-if="customTagSubject"
      class="tag-image tag-icon"
      :class="{
        'img-is-selected': isSelected,
        'prop-tag-image': tagCategory.title === 'Props',
        'shopify-tag-image': tagToDisplay.description === 'Shopify',
      }"
    >
      {{ customTagSubject }}
    </span>
    <p
      class="is-lowercase u-body--heavy shot-tag-title"
      :class="whiteTextAvailable() ? 'white-text' : ''"
    >
      {{ tagToDisplay.title }}
    </p>
    <button
      v-if="removable"
      class="remove-subject-btn is-large"
      @click="clickRemove"
    >
      <SoonaIcon name="xmark" size="medium" />
    </button>
  </button>
</template>

<script>
import backgroundColorList from '@/backdrop-colors.module.scss';
import { useIndustries } from 'src/queries/useIndustries';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

export default {
  components: {
    SoonaIcon,
  },
  props: {
    tagToDisplay: Object,
    tagCategory: Object,
    isSelected: Boolean,
    handleClick: Function,
    removable: Boolean,
    clickRemove: Function,
  },
  setup() {
    const { data: industries } = useIndustries();

    return { industries };
  },
  computed: {
    customTagSubject() {
      if (this.tagCategory.title === 'Custom Props') {
        return '🎨';
      } else if (
        this.tagCategory.title === 'Shot Subject' &&
        this.industries !== undefined
      ) {
        if (this.tagToDisplay.description === 'Shopify') {
          return '🛍️';
        }
        let currentTagStar = this.industries.filter(
          industry => industry.slug === this.tagToDisplay.description
        );
        return currentTagStar[0] ? currentTagStar[0].icon : '✨';
      }
      return undefined;
    },
  },
  methods: {
    tagBackgroundColor() {
      let title = this.tagToDisplay.title.replace(/\s+/g, '').toLowerCase();
      return backgroundColorList[title];
    },
    whiteTextAvailable() {
      if (this.tagCategory.title === 'Backdrop Color') {
        return (
          this.tagToDisplay.title !== 'White' &&
          this.tagToDisplay.title !== 'Cream'
        );
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';

.shot-tag {
  min-width: 150px;
  max-width: 300px;
  height: 75px;
  border-radius: 7px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  font-weight: 800;
  background: variables.$white-default;
  margin: 5px;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0;
  border: 2px solid transparent;
  .remove-subject-btn {
    align-items: center;
    background: none;
    color: variables.$gray-50;
    display: flex;
    justify-content: center;
    height: 1.875rem;
    width: 1.875rem;
    border-radius: 50%;
    padding-left: 3.5px;
    margin-right: 10px;
    &:hover {
      color: variables.$gray-90;
      background: variables.$gray-30;
    }
  }
  .tag-image {
    height: 75px;
    width: 75px;
    min-width: 75px;
    object-fit: cover;
    border-bottom-left-radius: 7px;
    border-top-left-radius: 7px;
    margin-left: -2px;
    font-size: 40px;
  }
  .prop-tag-image {
    background-color: variables.$periwink-blue-20;
  }
  .shopify-tag-image {
    background-color: variables.$green-apple-20;
  }
  .tag-icon {
    padding-top: 11px;
  }
  .img-is-selected {
    border-top: 0.125rem solid variables.$gray-90;
    border-bottom: 0.125rem solid variables.$gray-90;
    border-left: 0.125rem solid variables.$gray-90;
  }
  p {
    margin-left: 25px;
    margin-right: 25px;
    font-size: 14pt !important;
  }
  .white-text {
    color: variables.$white-default;
  }
  .shot-tag-title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>
