<script setup>
import {
  computed,
  defineAsyncComponent,
  inject,
  onMounted,
  ref,
  watch,
} from 'vue';
import { useRouter } from 'vue-router';
import { useLeftRightArrowKeys } from '@/composables/useLeftRightArrowKeys';
import { component as Viewer } from 'v-viewer';
import 'viewerjs/dist/viewer.css';
import MediaMultiplierLoading from '@/components/user/anytime/gallery/media-editor/media-multiplier/MediaMultiplierLoading.vue';
import MediaMultiplierError from '@/components/user/anytime/gallery/media-editor/media-multiplier/MediaMultiplierError.vue';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';
import MediaAssetImg from './MediaAssetImg.vue';
import {
  MEDIA_POPOVER_NEXT_KEY,
  MEDIA_POPOVER_PREV_KEY,
} from '@/components/user/anytime/gallery/media-editor-routing-keys';
import { useMediaEditor } from '@/components/user/anytime/gallery/media-editor/useMediaEditor';
import { useCapability } from '@/composables/useCapability';
import WatermarkImage from 'images/watermark2x.png';
import SoonaSkeleton from '@/components/ui_library/SoonaSkeleton.vue';

const props = defineProps({
  file: {
    type: Object,
    required: true,
  },
  accountId: {
    type: [String, Number],
    required: true,
  },
  canvasData: {
    required: false,
    type: ImageData,
  },
  selectorData: {
    required: true,
    type: Object,
    default: () => ({}),
  },
  showForeground: {
    type: Boolean,
    default: false,
  },
  srcUrl: {
    type: String,
  },
  popoverRef: {
    type: Object,
    default: undefined,
  },
  isFileLoading: {
    type: Boolean,
    default: false,
  },
  activeAction: {
    type: Symbol,
    default: undefined,
  },
});

const emits = defineEmits(['update-selection']);

const accountId = computed(() => props.accountId);
const showForeground = computed(() => props.showForeground);
const srcUrl = computed(() => props.srcUrl);
const file = computed(() => props.file);
const fileId = computed(() => file.value?.id);
const fileTitle = computed(() => file.value?.title);
const activeAction = computed(() => props.activeAction);
const popoverRef = computed(() => props.popoverRef);
const isFileLoading = computed(() => props.isFileLoading);
const isVideo = computed(() => file.value?.media_type === 'video');
const isPhoto = computed(() => file.value?.media_type === 'photo');
const isAnimation = computed(() => file.value?.media_type === 'animation');

const editsCanvas = ref();
const resizerEl = ref(null);
const canvasCtx = computed(() => editsCanvas.value?.getContext('2d'));
const canvasData = computed(() => props.canvasData);

const mediaWidth = computed(
  () => file.value?.media?.width || file.value?.width
);
const mediaHeight = computed(
  () => file.value?.media?.height || file.value?.height
);

const { isImageResizerActive } = useMediaEditor({
  activeAction,
});

const AsyncCropper = defineAsyncComponent({
  loader: async () =>
    await import(
      '@/components/user/anytime/gallery/media-editor/media-multiplier/resizer/SoonaCropper.vue'
    ),
  loadingComponent: MediaMultiplierLoading,
  delay: 100,
  errorComponent: MediaMultiplierError,
});
const selectorData = computed(() => props.selectorData);

// capabilities
const { hasCapability: canAdjustBackgroundOnOwnAccount } = useCapability({
  capability: 'media_editor_adjust_bg_on_own_account',
  subjectType: 'account',
  subjectId: accountId,
});
const { hasCapability: staffCanAdjustBackgroundOnClientAccount } =
  useCapability({
    capability: 'media_editor_adjust_bg_on_subscription_accounts',
  });

const hasSubscriptionDownloadAccess = computed(() => {
  return (
    canAdjustBackgroundOnOwnAccount?.value ||
    staffCanAdjustBackgroundOnClientAccount?.value
  );
});
const hasFreemiumAccess = computed(
  () =>
    !canAdjustBackgroundOnOwnAccount?.value &&
    !staffCanAdjustBackgroundOnClientAccount?.value
);

const watermark = ref(null);
const virtualCanvas = computed(() => document.createElement('canvas'));
const virtualCtx = computed(() => virtualCanvas.value?.getContext('2d'));
const drawVirtualCanvas = imageData => {
  virtualCanvas.value.width = imageData.width ?? 1440;
  virtualCanvas.value.height = imageData.height ?? 960;
  virtualCtx.value.putImageData(imageData, 0, 0);
  if (!hasSubscriptionDownloadAccess.value) {
    const x = virtualCanvas.value.width - watermark.value.width;
    const y = virtualCanvas.value.height - watermark.value.height;
    virtualCtx.value.drawImage(watermark.value, x, y);
  }
};

const resizeFactor = (srcWidth, srcHeight, maxWidth, maxHeight) => {
  const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);

  return { width: srcWidth * ratio, height: srcHeight * ratio };
};

const drawCanvas = imageData => {
  const currentMaxSize = Math.max(imageData.width, imageData.height);
  const newMaxSize = hasSubscriptionDownloadAccess.value
    ? currentMaxSize
    : Math.min(currentMaxSize, 1280);

  const { width, height } = resizeFactor(
    imageData.width,
    imageData.height,
    newMaxSize,
    newMaxSize
  );

  drawVirtualCanvas(imageData);
  editsCanvas.value.width = width;
  editsCanvas.value.height = height;
  canvasCtx.value.drawImage(
    virtualCanvas.value,
    0,
    0,
    editsCanvas.value.width,
    editsCanvas.value.height
  );
};

const saveLowResCanvas = () => {
  const link = document.createElement('a');
  link.download = fileTitle.value;
  link.href = editsCanvas.value.toDataURL();
  link.click();
};
const downloadLowResCrop = () => {
  resizerEl.value.downloadLowResCrop();
};

const refreshCropper = () => {
  resizerEl.value.refreshCropper();
};

defineExpose({
  saveLowResCanvas,
  downloadLowResCrop,
  refreshCropper,
});

watch(
  () => canvasData.value,
  () => {
    if (!canvasData.value) return;
    drawCanvas(canvasData.value);
  }
);

const prevRoute = inject(MEDIA_POPOVER_PREV_KEY);
const nextRoute = inject(MEDIA_POPOVER_NEXT_KEY);

const router = useRouter();
useLeftRightArrowKeys({
  onLeftArrowKeyStroke: () => {
    if (prevRoute.value) router.push(prevRoute.value);
  },
  onRightArrowKeyStroke: () => {
    if (nextRoute.value) router.push(nextRoute.value);
  },
});

const previewUrl = computed(() => {
  if (showForeground.value) {
    return file.value?.foreground_preview_url || file.value?.preview?.url;
  }

  return file.value?.preview_url || file.value?.preview?.url;
});
const foregroundUrl = computed(
  () => file.value?.foreground_url ?? file.value?.foreground?.url
);

const imageLoaded = ref(false);
const viewerRef = ref();
const viewerTitle = ref('loading higher resolution image…');
const onHighResImageLoaded = () => {
  viewerTitle.value = null;
  viewerRef.value?.updateViewer();
};

watch(fileId, () => {
  viewerTitle.value = 'loading higher resolution image…';
});

onMounted(() => {
  // todo: load this async?
  watermark.value = new Image();
  watermark.value.src = WatermarkImage;
});

const updateSelection = data => emits('update-selection', data);
</script>

<template>
  <div class="media-asset">
    <SoonaButton
      v-if="prevRoute"
      class="media-asset__nav-btn media-asset__nav-btn--prev"
      variation="icon-gray-outline"
      element="router-link"
      :to="prevRoute"
    >
      <SoonaIcon name="arrow-left" />
      <span class="u-a11y-only">previous asset</span>
    </SoonaButton>
    <canvas v-show="canvasData" ref="editsCanvas" />
    <AsyncCropper
      v-if="isImageResizerActive"
      ref="resizerEl"
      :file="file"
      :selector-data="selectorData"
      :has-freemium-access="hasFreemiumAccess"
      @update-selection="updateSelection"
    ></AsyncCropper>
    <template v-else-if="isPhoto || isAnimation">
      <SoonaSkeleton
        v-if="!imageLoaded"
        class="media-asset__skeleton"
        randomize-timing
      />
      <viewer
        v-if="!canvasData && !!srcUrl"
        ref="viewerRef"
        class="media-asset__viewer"
        :class="{ 'media-asset__viewer--disabled': file?.expired }"
        :options="{
          toolbar: false,
          navbar: false,
          backdrop: true,
          minZoomRatio: 0.01,
          maxZoomRatio: 2,
          container: popoverRef ?? '.media-editor',
          title: () => viewerTitle,
        }"
      >
        <MediaAssetImg
          :url="showForeground ? foregroundUrl : srcUrl"
          :preview-url="previewUrl"
          :width="mediaWidth"
          :height="mediaHeight"
          :is-file-loading="isFileLoading"
          @on-preview-img-loaded="imageLoaded = true"
          @on-high-res-loaded="onHighResImageLoaded"
        />
      </viewer>
    </template>
    <template v-else-if="isVideo">
      <video
        :key="file.title"
        :poster="previewUrl"
        controls
        controlsList="nodownload"
        class="preview-media media-asset__video"
      >
        <source :key="srcUrl" :src="srcUrl" type="video/mp4" />
      </video>
    </template>
    <SoonaButton
      v-if="nextRoute"
      class="media-asset__nav-btn media-asset__nav-btn--next"
      variation="icon-gray-outline"
      element="router-link"
      :to="nextRoute"
    >
      <SoonaIcon name="arrow-right" />
      <span class="u-a11y-only">next asset</span>
    </SoonaButton>
  </div>
</template>

<style lang="scss">
@use '@/variables';

.viewer-container .viewer-footer .viewer-title {
  font-style: italic;
  color: variables.$white-default;
}
</style>

<style lang="scss" scoped>
@use '@/variables';

.media-asset {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  max-height: 64rem;
  max-width: 64rem;
  min-height: 8rem;
  min-width: 12.3125rem;
  position: relative;
  overflow: hidden;

  &__skeleton {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__video {
    align-self: center;
    max-height: 100%;
  }

  :deep(.media-asset__nav-btn) {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    // make sure to overlay the asset, particularly <video> elements
    z-index: 1;
  }

  :deep(.media-asset__nav-btn--next) {
    left: auto;
    right: 0;
  }

  &__viewer {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  > canvas {
    display: block;
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
  }
}
</style>
