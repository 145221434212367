<script setup>
import { computed, onUnmounted, ref, watchEffect } from 'vue';
import { onKeyStroke } from '@vueuse/core';
import { useDialogContext } from '@/composables/useDialog';
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap';
import { useMediaQuery, useTimeoutFn } from '@vueuse/core';
import { useTeleportTo } from '@/composables/useTeleportTo';
import {
  BubbletapePink40,
  FriendlyRed50,
  Tangerine40,
  PeriwinkBlue50,
  GreenApple30,
} from '@/variables.module.scss';
import confetti from 'canvas-confetti';
import uniqueId from 'lodash/uniqueId';
import desktopImg from '@images/quest/tour_images_desktop@2x.png';
import mobileImg from '@images/quest/tour_images_mobile@2x.png';
import QuestClose from '@/components/quest/QuestClose.vue';
import QuestProgress from '@/components/quest/QuestProgress.vue';
import QuestTask from '@/components/quest/QuestTask.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

const props = defineProps({
  dialogId: {
    required: true,
    type: String,
  },
  isExpanded: {
    required: true,
    type: Boolean,
  },
  percentComplete: {
    required: true,
    type: Number,
  },
  questDescription: {
    required: true,
    type: String,
  },
  questTasks: {
    required: true,
    type: Array,
  },
  questTitle: {
    required: true,
    type: String,
  },
});

const emits = defineEmits(['close']);

const matchMediaIsWide = useMediaQuery('(min-width: 60rem)');

// handle dialog stuff
const dialogRef = ref(null);
const id = uniqueId('quest-flyout-');
const { checkAllowEscapeClose } = useDialogContext({ id });
useFocusTrap(dialogRef, { immediate: true });
const teleportTo = useTeleportTo();
const prefersReducedMotion = useMediaQuery('(prefers-reduced-motion: reduce)');

const { start: beginClose, isPending: isClosing } = useTimeoutFn(
  () => {
    emits('close');
  },
  prefersReducedMotion.value ? 0 : 150,
  { immediate: false }
);

const close = () => {
  if (!isClosing.value) beginClose();
};

onKeyStroke('Escape', () => {
  if (checkAllowEscapeClose(dialogRef)) close();
});

const imgFile = computed(() => (matchMediaIsWide ? desktopImg : mobileImg));
const percentComplete = computed(() => props.percentComplete);
const isComplete = computed(() => percentComplete.value === 100);

const headingCopy = computed(() => {
  return isComplete.value ? '🥳 tour complete! 🎉' : props.questTitle;
});

const subheadingCopy = computed(() => {
  return isComplete.value
    ? 'you’ve completed the platform tour. we can’t wait to see what you make, manage, and measure with soona.'
    : props.questDescription;
});

// confetti stuff
const showConfetti = ref(false);

const frame = () => {
  const colors = [
    BubbletapePink40,
    FriendlyRed50,
    Tangerine40,
    PeriwinkBlue50,
    GreenApple30,
  ];
  const selectedColor = colors[Math.floor(Math.random() * colors.length)];

  confetti({
    angle: 270,
    colors: [selectedColor],
    disableForReducedMotion: true,
    drift: Math.random() * 4 - 2,
    origin: { x: Math.random(), y: -0.1 },
    particleCount: 1,
    scalar: 1.8,
    shapes: ['circle', 'square', 'square'],
    spread: 180,
    startVelocity: 0.8,
    ticks: 400,
  });

  if (showConfetti.value) {
    requestAnimationFrame(frame);
  }
};

watchEffect(() => {
  if (isComplete.value && !showConfetti.value) {
    showConfetti.value = true;
    frame();
  }
});

onUnmounted(() => {
  showConfetti.value = false;
});
</script>

<template>
  <Teleport :to="teleportTo">
    <div
      :id="dialogId"
      ref="dialogRef"
      class="quest-flyout"
      :class="{ 'quest-flyout--complete': isComplete }"
      :aria-expanded="isExpanded"
      @click.self="close"
    >
      <div
        class="quest-flyout__card"
        :class="{ 'quest-flyout__card--closing': isClosing }"
        role="dialog"
      >
        <div class="quest-flyout__top">
          <img class="quest-flyout__img" :src="imgFile" alt="" />
          <div class="quest-flyout__heading">
            <p class="u-headline--heavy" data-cypress="text-quest-heading">
              {{ headingCopy }}
            </p>
            <SoonaButton
              v-if="!matchMediaIsWide || (matchMediaIsWide && !isComplete)"
              variation="icon-plain-gray"
              @on-click="close"
            >
              <SoonaIcon name="xmark" />
              <span class="u-a11y-only">close tour</span>
            </SoonaButton>
          </div>
          <p class="u-label--regular quest-flyout__subheading">
            {{ subheadingCopy }}
          </p>
          <QuestProgress
            v-if="!isComplete"
            :percent-complete="percentComplete"
          />
        </div>
        <div v-if="!isComplete" class="quest-flyout__content">
          <QuestTask
            v-for="task in questTasks"
            :key="task.id"
            :task="task"
            @close="close"
          />
        </div>
      </div>
      <QuestClose
        v-if="matchMediaIsWide"
        :controls="id"
        :percent-complete="percentComplete"
        @close="close"
      />
    </div>
  </Teleport>
</template>

<style lang="scss" scoped>
@use '@/variables';

@keyframes fade-in-up {
  0% {
    opacity: 0;
    transform: translateY(1rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-out-down {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(1rem);
  }
}

.quest-flyout {
  display: none;

  &--complete {
    .quest-flyout__top {
      height: 100%;
    }
  }

  &[aria-expanded='true'] {
    background-color: rgba(0, 0, 0, 0.49);
    bottom: 0;
    display: block;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
  }

  &__card {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
  }

  &__top {
    background-color: variables.$green-apple-20;
    flex-shrink: 0;
    padding: 1.5rem;
  }

  &__img {
    display: none;
  }

  &__heading {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &__content {
    background-color: variables.$white-default;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
  }

  @media (min-width: variables.$screen-md-min) {
    &--complete {
      text-align: center;

      .quest-flyout__top {
        height: auto;
        padding-bottom: 4rem;
      }

      .quest-flyout__heading {
        justify-content: center;
        padding-bottom: 0.5rem;
        padding-top: 3rem;
      }

      .quest-flyout__subheading {
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }

    &[aria-expanded='true'] {
      bottom: 0;
      left: 0;
      position: fixed;
      right: 0;
      top: 0;
    }

    &__card {
      animation: 0.15s ease-out both fade-in-up;
      border-radius: 20rem 20rem 2rem 2rem;
      bottom: 8rem;
      height: max-content;
      left: initial;
      max-height: 100%;
      max-width: 24.25rem;
      position: fixed;
      right: 2rem;
      top: initial;
      width: max-content;

      &--closing {
        animation: 0.15s ease-in both fade-out-down;
      }
    }

    &__top {
      padding-top: 0;
    }

    &__img {
      display: block;
      max-height: 7.5rem;
      width: 100%;
      object-fit: cover;
    }

    &__content {
      height: max-content;
    }
  }
}
</style>
