<script setup>
import { computed } from 'vue';
import { useIndustries } from 'src/queries/useIndustries';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';
import StyleQuizLayout from '@/components/quiz/StyleQuizLayout.vue';

const router = useRouter();
const store = useStore();

const { data: industryOptions, isLoading } = useIndustries();

const currentQuiz = computed(() => store.state.styleQuiz.currentQuiz);

const hasStarSelected = computed(
  () => store.state.styleQuiz.currentQuiz.brandCategory !== null
);

const toggleStar = star =>
  store.dispatch('styleQuiz/toggleBrandCategory', star);
</script>

<template>
  <StyleQuizLayout>
    <template #left-column>
      <h1 class="u-display--heavy">choose the star of the show 🌟</h1>
      <p class="u-label--heavy">
        what do you sell? select your brand’s category.
      </p>
      <div class="next-btn-wrapper">
        <SoonaButton
          :disabled="!hasStarSelected"
          copy="next"
          size="medium"
          data-cypress="button-next"
          :on-click="() => router.push({ name: 'style-quiz-role-picker' })"
        />
      </div>
    </template>
    <template #right-column>
      <div class="flex-wrapper">
        <SoonaLoading v-if="isLoading" is-contained is-loading />
        <div v-for="n in 3" v-else :key="`star-col-${n}`" class="flex-column">
          <button
            v-for="(star, index) in industryOptions.filter(
              (val, index) => index % 3 === n - 1
            )"
            :key="index"
            class="u-button-reset star-btn"
            :data-cypress="`div-star-${n}-${index}`"
            @click="toggleStar(star)"
          >
            <span
              class="star-emoji"
              :class="{
                'star-emoji--selected': currentQuiz.brandCategory === star.id,
              }"
            >
              {{ star.icon }}
            </span>
            <span class="u-label--heavy">{{ star.title }}</span>
          </button>
        </div>
      </div>
    </template>
  </StyleQuizLayout>
</template>

<style lang="scss" scoped>
@use '@/variables';

.next-btn-wrapper {
  margin-left: auto;
}

.flex-wrapper {
  display: flex;
  gap: 1rem;
  margin: 0 auto;
  max-width: 40rem;
  position: relative;
}

.flex-column {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 33.3333%;
}

.star-btn {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  white-space: normal;
  max-width: max-content;
  width: 100%;
}

.star-emoji {
  align-items: center;
  background: variables.$bubbletape-pink-10;
  border-radius: 50%;
  display: flex;
  font-size: 2rem;
  height: 4.6875rem;
  justify-content: center;
  margin: 0 auto;
  width: 4.6875rem;

  &--selected {
    border: 0.0125rem solid variables.$black-default;
  }
}

@media (min-width: variables.$screen-md-min) {
  .flex-wrapper {
    padding-left: 2rem;
    padding-right: 5rem;
  }

  .star-btn:nth-child(even) {
    margin-left: -4rem;
  }

  .star-btn:nth-child(odd) {
    margin-left: 4rem;
  }
}
</style>
