<script setup>
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';

const emit = defineEmits(['close-dialog']);
</script>
<template>
  <SoonaDialog role="alertdialog" @close="emit('close-dialog')">
    <template #heading>the hour is late...</template>

    <p class="u-subheader--heavy subheading">
      additions to your shot list may not be possible in the scheduled shoot
      time.
    </p>
    <p class="u-body--regular">
      the soona creative team will make every effort to achieve all your hopes
      and dreams. but know that if you make major changes this late in the game,
      we may not be able to accomodate them.
    </p>
    <template #footer="{ close }">
      <SoonaButton copy="got it" @on-click="close" />
    </template>
  </SoonaDialog>
</template>

<style lang="scss" scoped>
.subheading {
  padding-bottom: 0.5rem;
}
</style>
