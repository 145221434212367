<script setup>
import { computed } from 'vue';
import { toCurrency } from '@/lib/currency';
import FeatureList from '@/components/subscriptions/FeatureList.vue';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';

const props = defineProps({
  selectedBillingInterval: {
    default: 'year',
    type: String,
    validator: function (value) {
      return ['month', 'year'].includes(value);
    },
  },
  tier: {
    type: Object,
    required: true,
  },
  showBorder: {
    default: false,
    type: Boolean,
  },
  bestOfferBorder: {
    default: false,
    type: Boolean,
  },
});

const selectedBillingInterval = computed(() => props.selectedBillingInterval);

const price = computed(() =>
  selectedBillingInterval.value === 'year'
    ? props.tier.product.prices?.find(p => p.recurring_interval === 'year')
    : props.tier.product.prices?.find(p => p.recurring_interval === 'month')
);

const amount = computed(() => {
  if (!price.value) return 0;

  return price.value.recurring_interval === 'year'
    ? +price.value.unit_amount / 12
    : +price.value.unit_amount;
});
</script>

<template>
  <article
    class="tier-card tier-card--clean"
    :class="{
      'tier-card--border': showBorder,
      'best-offer': bestOfferBorder,
    }"
  >
    <div v-if="bestOfferBorder" class="best-offer--border u-button--small-caps">
      most popular!
    </div>
    <div class="tier-card__top" :data-tier-slug="tier.slug">
      <div class="tier-card__basic-info">
        <h3 class="u-display--heavy">{{ tier.name }}</h3>
        <p class="u-subheader--heavy tier-card__price">
          {{ toCurrency(amount, price?.currency, 0) }}/month
          <span v-if="selectedBillingInterval === 'year'"
            >(billed annually)</span
          >
        </p>
      </div>
      <SoonaIcon
        class="tier-card__badge"
        :name="tier.product?.icon_name"
        size="large"
      />
    </div>
    <div class="tier-card__bottom">
      <FeatureList
        class="tier-card__features"
        :feature-list="tier.product.card_feature_list"
        :has-no-gap="true"
        :show-tooltip="true"
      />
      <slot />
    </div>
  </article>
</template>

<style lang="scss" scoped>
@use '@/variables';

.tier-card {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  z-index: 0;
  border: 0.1875rem solid transparent;

  p span {
    font-weight: normal;
  }

  &.best-offer {
    position: relative;
    border: 0.1875rem solid variables.$periwink-blue-70;
    border-radius: 1.375rem;

    & .best-offer--border {
      position: absolute;
      width: calc(100% + 0.375rem);
      padding: 0.625rem 0 2rem;
      top: -2.0625rem;
      left: -0.1875rem;
      background: variables.$periwink-blue-70;
      color: variables.$white-default;
      text-align: center;
      text-transform: uppercase;
      border-radius: 1rem 1rem 0 0;
      z-index: -1;
    }
  }

  &--border {
    border: 0.0625rem solid variables.$gray-30;
    border-radius: 1.25rem;

    .tier-card__top {
      border-top-left-radius: 1.5625rem;
      border-top-right-radius: 1.5625rem;
    }
  }

  &__top {
    display: flex;
    flex-wrap: wrap;
    border-top-left-radius: 1.25rem;
    border-top-right-radius: 1.25rem;
    padding: 1.25rem;
    padding-top: 2rem;
    text-align: center;
    background-color: variables.$white-default;

    &[data-tier-slug='tier-one'] .tier-card__badge {
      background-color: variables.$periwink-blue-60;
    }

    &[data-tier-slug='tier-two'] .tier-card__badge {
      background-color: variables.$green-apple-40;
    }

    & .tier-card__badge {
      border-radius: 50%;
      color: variables.$white-default;
      display: flex;
      justify-content: center;
      height: 2.5rem;
      width: 2.5rem;
    }
  }

  &__basic-info {
    flex-grow: 1;
    text-align: left;
    width: calc(100% - 3rem);
  }

  &__summary {
    text-align: left;
  }

  &__bottom {
    background-color: variables.$white-default;
    border-bottom-left-radius: 1.25rem;
    border-bottom-right-radius: 1.25rem;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 0 1rem 1.25rem 1rem;
  }

  &__features {
    padding-bottom: 1.5rem;
  }
}
</style>
