<script setup>
import { computed } from 'vue';
import useSubscriptionAndIntegration from '@/composables/subscription/useSubscriptionAndIntegration';
import { useAccount } from '@/composables/useAccount';
import { useSuggestedSubscription } from '@/composables/subscription/useSuggestedSubscription';
import UndersubscribedBanner from '@/components/shared/UndersubscribedBanner.vue';
import { useFlag } from '@/composables/useFlag';

const props = defineProps({
  accountId: { type: String, required: true },
});

const apolloTrialForEveryoneFlag = useFlag('apollo_trial_for_everyone');

const accountId = computed(() => props.accountId);
const { account, eligibleForPlatformSubscriptionTrial } = useAccount(accountId);

const { userCanUpgrade } = useSubscriptionAndIntegration(accountId);

const { suggestedCompetitorsTier, competitorQuotaReached } =
  useSuggestedSubscription(accountId);

const offerTrial = computed(
  () =>
    apolloTrialForEveryoneFlag.value &&
    eligibleForPlatformSubscriptionTrial.value
);
</script>

<template>
  <template v-if="userCanUpgrade && competitorQuotaReached">
    <UndersubscribedBanner
      :tier="suggestedCompetitorsTier"
      :cta-copy="offerTrial ? 'try it free for 30 days' : 'upgrade'"
    >
      <template #supertitle>
        {{ account?.total_competitors_count }}/{{
          account?.max_competitors_count
        }}
        {{ account?.max_competitors_count === 1 ? 'free' : '' }}
        competitors added
      </template>
      <template #headline>
        expand your watchlist with a {{ suggestedCompetitorsTier.name }} plan
      </template>
    </UndersubscribedBanner>
  </template>
</template>
