<script setup>
import SoonaTab from '@/components/ui_library/SoonaTab.vue';
import { useRoute, useRouter } from 'vue-router';
import SoonaBackButton from '@/components/ui_library/SoonaBackButton.vue';
import DefaultPaymentCards from 'src/components/user/anytime/billing_and_orders/DefaultPaymentCards.vue';
import IntegrationBillingSection from 'src/components/user/anytime/billing_and_orders/IntegrationBillingSection.vue';
import { useTitle } from '@vueuse/core';
import { computed, onMounted } from 'vue';
import { useCapability } from '@/composables/useCapability';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useIntegrations } from '@/composables/useIntegrations';

const props = defineProps({
  accountId: {
    type: String,
    required: true,
  },
});

const { pageViewed } = useBaseEvents();

onMounted(() => {
  pageViewed();
});

const accountId = computed(() => props.accountId);
const route = useRoute();
const router = useRouter();
useTitle('billing & order history | soona');

// matching logic for viewing the account subscriptions route
const { hasCapability: canVewAccountSubscription } = useCapability({
  capability: 'view_account_subscription',
  subjectType: 'account',
  subjectId: accountId,
});
const { hasCapability: canCrewManageSubscription } = useCapability({
  capability: 'crew_manage_subscription',
});

const backLink = computed(
  () =>
    // fixme: update logic to not use this API
    // eslint-disable-next-line no-restricted-properties
    router.options?.history?.state?.back ??
    `/account/${accountId.value}/profile`
);

const backCopy = computed(() => {
  if (backLink.value?.match(/account\/[0-9]+\/subscriptions/))
    return 'manage plan';

  if (backLink.value?.match(/account\/[0-9]+\/profile/)) return 'profile';

  return 'back';
});

const canViewSubscriptions = computed(() => {
  return canVewAccountSubscription.value || canCrewManageSubscription.value;
});

const { hasShopifyIntegration } = useIntegrations(accountId);
</script>

<template>
  <SoonaBackButton
    element="router-link"
    :to="backLink"
    :button-text="backCopy"
  />
  <h1 class="u-headline--heavy orders-heading">billing & order history</h1>
  <DefaultPaymentCards v-if="!hasShopifyIntegration" :account-id="accountId" />
  <IntegrationBillingSection :account-id="accountId" />
  <nav class="order-invoices-tabs">
    <SoonaTab
      :to="{ path: `/account/${accountId}/orders` }"
      :is-selected="route.path === `/account/${accountId}/orders`"
    >
      <template #copy>studio payments</template>
    </SoonaTab>
    <SoonaTab
      v-if="canViewSubscriptions"
      :to="{ path: `/account/${accountId}/orders/subscription-invoices` }"
      :is-selected="
        route.path === `/account/${accountId}/orders/subscription-invoices`
      "
    >
      <template #copy>subscription payments</template>
    </SoonaTab>
  </nav>
  <router-view />
</template>

<style lang="scss" scoped>
.orders-heading {
  margin: 0.5rem 0 2rem;
}

.order-invoices-tabs {
  display: flex;
  overflow-x: auto;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}
</style>
