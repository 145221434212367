// import segmentMixin from 'src/mixins/segmentMixin';
// import { capitalizeWords } from 'src/store/api_helpers/helper-functions';
import * as types from 'src/store/mutation-types';
import isUndefined from 'lodash/isUndefined';
// eslint-disable-next-line no-restricted-imports
import { queryClient } from 'src/queries/query-client';
import { queryKeys } from 'src/queries/query-keys';

const VIEW_MODE = 0;
const EDIT_MODE = 1;

function visibleTagCategories(state) {
  return state.allTagCategories.filter(
    tc =>
      tc.default == true ||
      state.currentShot.shot_tags.find(st =>
        st.tag.tag_follow_up_categories.find(
          tfuc => tfuc.tag_category_id == tc.id
        )
      )
  );
}

const state = {
  mode: VIEW_MODE,
  loading: true,
  currentShotList: [],
  shotListStatus: undefined,
  currentReservationId: undefined,
  tagAccountId: undefined,
  currentShot: {
    id: undefined,
    description: undefined,
    shot_tags: [],
    tag_categories: [],
    created_by_user_id: undefined,
  },
  allTagCategories: [],
  referenceImageTCAccountTags: [],
  referenceImageLastPage: false,
  propsTCTags: [],
  propsLastPage: false,
};

const getters = {
  visibleTagCategories: state => {
    return visibleTagCategories(state);
  },
  canEdit: state => {
    return state.shotListStatus == 'draft';
  },
  buildingIt: state => {
    return state.shotListStatus == 'build_it_for_me';
  },
  buildingItComplete: state => {
    return (
      state.shotListStatus == 'build_it_for_me_complete' ||
      state.shotListStatus === 'pack_reviewed'
    );
  },
  submitted: state => {
    return state.shotListStatus == 'submitted';
  },
  isLoading: state => {
    return state.loading;
  },
  isViewMode: state => {
    return state.mode == VIEW_MODE;
  },
  isCreateMode: state => {
    return state.currentShot.id == null;
  },
  isEditMode: state => {
    return state.mode == EDIT_MODE;
  },
  isTagSelected: state => tag_id => {
    return state.currentShot.shot_tags.some(st => st.tag_id == tag_id);
  },
  referenceTagImageUrls: () => shot => {
    let refImgTC = visibleTagCategories(state).find(
      vtc => vtc.is_reference_image
    );
    let shot_tags = shot.shot_tags.filter(
      st => st.tag.tag_category_id === refImgTC?.id
    );
    return shot_tags
      .map(st => {
        if (isUndefined(st.tag.image_url) && !isUndefined(st.tag.gif_url)) {
          return { id: st.tag_id, url: st.tag.gif_url };
        }
        return {
          id: st.tag_id,
          url: st.tag.image_url,
          content_type: st.tag.image_content_type,
        };
      })
      .reverse();
  },
  savedSelectedReferenceImgIds: () => shot => {
    let refImgTC = visibleTagCategories(state).find(
      vtc => vtc.is_reference_image
    );
    let shot_tags = shot.shot_tags.filter(
      st => st.tag.tag_category_id === refImgTC?.id
    );
    return shot_tags.map(st => st.tag_id);
  },
  referenceImageTagCategoryId: state => {
    let refImgTC = visibleTagCategories(state).find(
      vtc => vtc.is_reference_image
    );
    return refImgTC.id;
  },
  referenceImageTCAccountTags: state => {
    return state.referenceImageTCAccountTags;
  },
  isReferenceImageLastPage: state => {
    return state.referenceImageLastPage;
  },
  propsTCTags: state => {
    return state.propsTCTags;
  },
  propsTagCategoryId: state => {
    let propsTC = visibleTagCategories(state).find(vtc => vtc.is_props);
    return propsTC.id;
  },
  isPropsLastPage: state => {
    return state.propsLastPage;
  },
  savedSelectedSoonaPropTagsIds: () => shot => {
    let propsTC = visibleTagCategories(state).find(vtc => vtc.is_props);
    let shot_tags = shot.shot_tags.filter(
      st => st.tag.tag_category_id === propsTC.id
    );
    return shot_tags.map(st => st.tag_id);
  },
};

const mutations = {
  [types.SELECT_TAG](state, tag) {
    let tagCategory = state.allTagCategories.find(
      tc => tc.id == tag.tag_category_id
    );
    if (tagCategory.select_type == 'single') {
      //remove shot tags for the category that's being selected
      state.currentShot.shot_tags = state.currentShot.shot_tags.filter(
        st => st.tag.tag_category_id != tagCategory.id
      );
      //add new tag
      state.currentShot.shot_tags.push({ tag_id: tag.id, tag: tag });
      //remove any tags that shouldn't be selected based on the category no longer being a valid follow up category
      do {
        //only shot tags where the tag's category is visible
        state.currentShot.shot_tags = state.currentShot.shot_tags.filter(st =>
          visibleTagCategories(state).find(
            tc => tc.id == st.tag.tag_category_id
          )
        );
        //loop in case we set off a chain by removing something at the top
      } while (
        state.currentShot.shot_tags.find(
          st =>
            !visibleTagCategories(state).find(
              tc => tc.id == st.tag.tag_category_id
            )
        )
      );
    } else {
      //add new tag
      state.currentShot.shot_tags.push({ tag_id: tag.id, tag: tag });
    }
  },
  [types.DESELECT_TAG](state, tag) {
    let index = state.currentShot.shot_tags.findIndex(
      st => st.tag_id == tag.id
    );
    if (index >= 0) {
      state.currentShot.shot_tags.splice(index, 1);
    }
    //remove any tags that shouldn't be selected based on the category no longer being a valid follow up category
    do {
      //only shot tags where the tag's category is visible
      state.currentShot.shot_tags = state.currentShot.shot_tags.filter(st =>
        visibleTagCategories(state).find(tc => tc.id == st.tag.tag_category_id)
      );
      //loop in case we set off a chain by removing something at the top
    } while (
      state.currentShot.shot_tags.find(
        st =>
          !visibleTagCategories(state).find(
            tc => tc.id == st.tag.tag_category_id
          )
      )
    );
  },
  [types.SET_ALL_TAG_CATEGORIES](state, sortedCategories) {
    state.allTagCategories = sortedCategories;
  },
  [types.SET_SHOT_LIST](state, { shotList, reservationId, accountId }) {
    state.currentShotList = shotList.shots;
    state.shotListStatus = shotList.shotlist_status;
    state.currentReservationId = reservationId;
    state.tagAccountId = accountId;
  },
  [types.SET_SHOT_ORDER](state, orderNum) {
    state.currentShot.order = orderNum;
    state.currentShot.number = orderNum;
  },
  [types.SET_CURRENT_SHOT](state, shot) {
    state.currentShot = shot;
  },
  [types.RESET_CURRENT_SHOT](state) {
    state.currentShot = {
      id: undefined,
      description: undefined,
      shot_tags: [],
      tag_categories: [],
    };
  },
  [types.SET_SHOT_LIST_MODE_VIEW](state) {
    state.mode = VIEW_MODE;
  },
  [types.SET_SHOT_LIST_MODE_EDIT](state) {
    state.mode = EDIT_MODE;
  },
  [types.SET_SHOT_DESCRIPTION](state, description) {
    state.currentShot.description = description;
  },
  [types.SET_BUSY](state) {
    state.loading = true;
  },
  [types.SET_READY](state) {
    state.loading = false;
  },
  [types.RESET](state) {
    state.mode = VIEW_MODE;
    state.loading = true;
    state.currentShotList = [];
    state.shotListStatus = undefined;
    state.currentReservationId = undefined;
    state.tagAccountId = undefined;
    state.currentShot = {
      id: undefined,
      description: undefined,
      shot_tags: [],
      tag_categories: [],
      created_by_user_id: undefined,
      status: undefined,
      order: undefined,
      number: undefined,
    };
    state.allTagCategories = [];
  },
  [types.SET_SHOT_CREATOR](state, currentUserId) {
    state.currentShot.created_by_user_id = currentUserId;
  },
  [types.UPDATE_SHOT_STATUS](state, isComplete) {
    state.currentShot.status = isComplete ? 'complete' : 'incomplete';
  },
  [types.RESET_REFERENCE_IMAGE_ACCOUNT_TAGS](state, accountTags) {
    state.referenceImageTCAccountTags = accountTags;
  },
  [types.ADD_REFERENCE_IMAGE_ACCOUNT_TAGS](state, referenceImageTCAccountTags) {
    state.referenceImageTCAccountTags = [
      ...state.referenceImageTCAccountTags,
      ...referenceImageTCAccountTags,
    ];
  },
  [types.SET_REFERENCE_IMAGE_ACCOUNT_TAGS_LAST_PAGE](state, lastPage) {
    state.referenceImageLastPage = lastPage;
  },
  [types.ADD_PROP_TAGS](state, propsTCTags) {
    state.propsTCTags = [...state.propsTCTags, ...propsTCTags];
  },
  [types.RESET_PROP_TAGS](state, propTags) {
    state.propsTCTags = propTags;
  },
  [types.SET_PROPS_TAGS_LAST_PAGE](state, lastPage) {
    state.propsLastPage = lastPage;
  },
};

const actions = {
  resetShotlist({ commit, dispatch }, { reservationId, accountId }) {
    commit(types.RESET);
    if (reservationId) {
      dispatch('loadTagCategories', accountId).then(() => {
        dispatch('loadShotList', { reservationId, accountId });
      });
    }
  },
  loadShotList({ commit }, { reservationId, accountId }) {
    return new Promise((resolve, reject) => {
      this.http.get(`reservations/${reservationId}/shots.json`).then(
        response => {
          commit(types.SET_SHOT_LIST, {
            shotList: response.data,
            reservationId: reservationId,
            accountId: accountId,
          });
          commit(types.SET_READY);
          resolve();
        },
        error => {
          console.error('error', error);
          reject(error);
        }
      );
    });
  },
  editShot({ commit }, shot) {
    // segmentMixin.methods.$track('Edit Clicked', { shot: shot });
    commit(types.SET_CURRENT_SHOT, shot);
    commit(types.SET_SHOT_LIST_MODE_EDIT);
  },
  newShot({ commit }) {
    // segmentMixin.methods.$track('Add New Shot Clicked', {
    //   reservation: reservation,
    // });
    commit(types.RESET_CURRENT_SHOT);
    commit(types.SET_SHOT_LIST_MODE_EDIT);
  },
  cloneShot({ commit, dispatch }, shot) {
    // segmentMixin.methods.$track('Duplicate Clicked', { shot: shot });
    return new Promise((resolve, reject) => {
      let newShot = JSON.parse(JSON.stringify(shot));
      newShot.id = null;
      newShot.order = null;
      newShot.number = null;
      newShot.shot_tags.forEach(st => (st.id = null));
      commit(types.SET_CURRENT_SHOT, newShot);
      dispatch('createShot')
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  saveShot({ dispatch, state }) {
    if (state.currentShot.id) {
      dispatch('updateShot');
    } else {
      dispatch('createShot');
    }
  },
  createShot({ dispatch, state, commit }) {
    return new Promise((resolve, reject) => {
      commit(types.SET_BUSY);
      this.http
        .post(`reservations/${state.currentReservationId}/shots.json`, {
          shot: state.currentShot,
        })
        .then(
          () => {
            dispatch('loadShotList', {
              reservationId: state.currentReservationId,
              accountId: state.tagAccountId,
            })
              .then(() => {
                commit(types.SET_SHOT_LIST_MODE_VIEW);
              })
              .then(() =>
                queryClient.invalidateQueries({
                  queryKey: queryKeys.reservationShotList(
                    state.currentReservationId
                  ),
                })
              )
              .then(() => resolve());
          },
          error => {
            console.error('error', error);
            reject(error);
          }
        );
    });
  },
  updateShotListOrder({ commit, dispatch }, shotList) {
    let orderNum = 0;
    shotList.forEach(shot => {
      orderNum++;
      if (shot.order !== null || shot.order !== orderNum) {
        commit(types.SET_CURRENT_SHOT, shot);
        commit(types.SET_SHOT_ORDER, orderNum);
        dispatch('updateShot');
      }
    });
  },
  updateShotStatus({ commit, dispatch }, { shot, isComplete }) {
    commit(types.SET_CURRENT_SHOT, shot);
    commit(types.UPDATE_SHOT_STATUS, isComplete);
    dispatch('updateShot');
  },
  updateShot({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      commit(types.SET_BUSY);
      this.http
        .put(
          `reservations/${state.currentReservationId}/shots/${state.currentShot.id}.json`,
          { shot: state.currentShot }
        )
        .then(
          () => {
            dispatch('loadShotList', {
              reservationId: state.currentReservationId,
              accountId: state.tagAccountId,
            })
              .then(() => {
                commit(types.SET_SHOT_LIST_MODE_VIEW);
              })
              .then(() =>
                queryClient.invalidateQueries({
                  queryKey: queryKeys.reservationShotList(
                    state.currentReservationId
                  ),
                })
              )
              .then(() => resolve());
          },
          error => {
            console.error('error', error);
            reject(error);
          }
        );
    });
  },
  hideShot({ commit, state, dispatch }, shot) {
    return new Promise((resolve, reject) => {
      commit(types.SET_BUSY);
      this.http
        .put(
          `reservations/${state.currentReservationId}/shots/${shot.id}.json`,
          {
            shot: {
              id: shot.id,
              visible: false,
              description: shot.description,
              shot_tags: shot.shot_tags,
            },
          }
        )
        .then(
          () => {
            dispatch('loadShotList', {
              reservationId: state.currentReservationId,
              accountId: state.tagAccountId,
            }).then(() => {
              commit(types.SET_SHOT_LIST_MODE_VIEW);
              resolve();
            });
          },
          error => {
            console.error('error', error);
            reject(error);
          }
        );
    });
  },
  loadTagCategories({ state, commit }, accountId) {
    return new Promise((resolve, reject) => {
      accountId = accountId || state.tagAccountId;
      this.http
        .get(
          `tag_categories.json?type=1${
            accountId ? '&account_id=' + accountId : ''
          }`
        )
        .then(
          response => {
            let tagCategories = response.data;
            let sortedCategories = tagCategories.sort(
              (a, b) => a.order - b.order
            );
            commit(types.SET_ALL_TAG_CATEGORIES, sortedCategories);
            resolve();
          },
          error => {
            console.error('error', error);
            reject(error);
          }
        );
    });
  },
  toggleTag({ state, commit }, tag) {
    if (state.currentShot.shot_tags.find(st => st.tag_id == tag.id)) {
      // segmentMixin.methods.$track(capitalizeWords(tag.title) + ' Deselected', {
      //   tag: tag,
      // });
      commit(types.DESELECT_TAG, tag);
    } else {
      // segmentMixin.methods.$track(capitalizeWords(tag.title) + ' Selected', {
      //   tag: tag,
      // });
      commit(types.SELECT_TAG, tag);
    }
  },
  addReferenceImgTag({ commit }, tagList) {
    tagList.forEach(tag => commit(types.SELECT_TAG, tag));
  },
  removeReferenceImgTag({ commit }, tag) {
    if (state.currentShot.shot_tags.find(st => st.tag_id == tag.id)) {
      // segmentMixin.methods.$track(capitalizeWords(tag.title) + ' Deselected', {
      //   tag: tag,
      // });
      commit(types.DESELECT_TAG, tag);
    }
  },
  addPropTag({ commit }, propTagList) {
    propTagList.forEach(tag => commit(types.SELECT_TAG, tag));
  },
  removePropTCTag({ commit }, tag) {
    if (state.currentShot.shot_tags.find(st => st.tag_id == tag.id)) {
      // segmentMixin.methods.$track(capitalizeWords(tag.title) + ' Deselected', {
      //   tag: tag,
      // });
      commit(types.DESELECT_TAG, tag);
    }
  },
  createTag(
    { state, commit, dispatch },
    { tagCategoryId, title, description }
  ) {
    return new Promise((resolve, reject) => {
      commit(types.SET_BUSY);
      this.http
        .post(`tag_categories/${tagCategoryId}/tags.json`, {
          tag: {
            title: title,
            account_id: state.tagAccountId,
            description: description,
          },
        })
        .then(
          response => {
            dispatch('loadTagCategories').then(() => {
              commit(types.SELECT_TAG, response.data);
              commit(types.SET_READY);
              resolve();
            });
          },
          error => {
            commit(types.SET_READY);
            console.error('error', error);
            reject(error);
          }
        );
    });
  },
  createReferenceImageTag(
    { state, commit, dispatch },
    { tagCategoryId, title, image_blob }
  ) {
    return new Promise((resolve, reject) => {
      commit(types.SET_BUSY);
      this.http
        .post(`tag_categories/${tagCategoryId}/tags.json`, {
          tag: {
            title: title,
            account_id: state.tagAccountId,
            image: image_blob.signed_id,
          },
        })
        .then(
          () => {
            dispatch('loadTagCategories').then(() => {
              commit(types.SET_READY);
              resolve();
            });
          },
          error => {
            console.error('error', error);
            reject(error);
          }
        );
    });
  },
  submitShotList({ dispatch, commit }, reservationId) {
    const shotListReservationId = state.currentReservationId || reservationId;
    return new Promise((resolve, reject) => {
      commit(types.SET_BUSY);
      this.http
        .put(`reservations/${shotListReservationId}/submit_shotlist.json`)
        .then(
          () => {
            dispatch('reservation/loadReservation', shotListReservationId, {
              root: true,
            });
            dispatch('loadShotList', {
              reservationId: shotListReservationId,
              accountId: state.tagAccountId,
            }).then(() => {
              resolve();
            });
          },
          error => {
            console.error('error', error);
            reject(error);
          }
        );
    });
  },
  draftShotList({ dispatch, commit }) {
    return new Promise((resolve, reject) => {
      commit(types.SET_BUSY);
      this.http
        .put(`reservations/${state.currentReservationId}/draft_shotlist.json`)
        .then(
          () => {
            dispatch(
              'reservation/loadReservation',
              state.currentReservationId,
              { root: true }
            );
            dispatch('loadShotList', {
              reservationId: state.currentReservationId,
              accountId: state.tagAccountId,
            }).then(() => {
              resolve();
            });
          },
          error => {
            console.error('error', error);
            reject(error);
          }
        );
    });
  },

  loadReferenceImageTagCategory({ commit, state, getters }, { page, append }) {
    return new Promise((resolve, reject) => {
      this.http
        .get(
          `tag_categories/${
            getters.referenceImageTagCategoryId
          }.json?page=${page}${
            state.tagAccountId ? '&account_id=' + state.tagAccountId : ''
          }`
        )
        .then(
          response => {
            let commitType = append
              ? types.ADD_REFERENCE_IMAGE_ACCOUNT_TAGS
              : types.RESET_REFERENCE_IMAGE_ACCOUNT_TAGS;
            commit(commitType, response.data.tags);
            commit(
              types.SET_REFERENCE_IMAGE_ACCOUNT_TAGS_LAST_PAGE,
              response.data.last_page
            );
            resolve();
          },
          error => {
            console.error('error', error);
            reject(error);
          }
        );
    });
  },
  loadPropsTagCategory({ commit, getters }, { page, append }) {
    return new Promise((resolve, reject) => {
      this.http
        .get(`tag_categories/${getters.propsTagCategoryId}.json?page=${page}`)
        .then(
          response => {
            let commitType = append
              ? types.ADD_PROP_TAGS
              : types.RESET_PROP_TAGS;
            commit(commitType, response.data.tags);
            commit(
              types.SET_PROPS_TAGS_LAST_PAGE,
              response.data.props_last_page
            );
            resolve();
          },
          error => {
            console.error('error', error);
            reject(error);
          }
        );
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
