<script setup>
import { computed, ref } from 'vue';
import SoonaBackButton from 'src/components/ui_library/SoonaBackButton.vue';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';

const props = defineProps({
  isRightColumnReversed: {
    default: false,
    type: Boolean,
  },
  isTwoColumn: {
    default: true,
    type: Boolean,
  },
  rightColumnBgColor: {
    type: String,
  },
  showBackButton: {
    default: true,
    type: Boolean,
  },
  showMonthlyToggle: {
    default: false,
    type: Boolean,
  },
  showRightColumnBorder: {
    default: false,
    type: Boolean,
  },
});

const emits = defineEmits(['back', 'close', 'next']);

const showBackButton = computed(() => props.showBackButton);
const isTwoColumn = computed(() => props.isTwoColumn);
const showMonthlyYearlyToggle = computed(() => props.showMonthlyYearlyToggle);

const selectedFrequency = ref(showMonthlyYearlyToggle.value ? 'monthly' : null);

const handlefrequencyChange = val => (selectedFrequency.value = val);
</script>

<template>
  <div class="step-layout">
    <div
      class="step-layout__left"
      :class="{ 'step-layout__left--single-col': !isTwoColumn }"
    >
      <div
        class="step-layout__nav"
        :class="{ 'step-layout__nav--first-step': !showBackButton }"
      >
        <SoonaBackButton v-if="showBackButton" @click="emits('back')" />
        <SoonaButton
          class="step-layout__close-btn"
          :class="{ 'step-layout__close-btn--two-column': isTwoColumn }"
          variation="icon-gray-outline"
          data-cypress="button-close-bulk-credits-modal"
          @on-click="emits('close')"
        >
          <SoonaIcon name="xmark" /><span class="u-a11y-only">close modal</span>
        </SoonaButton>
      </div>
      <div
        class="step-layout__content"
        :class="{ 'step-layout__content--first-step': !showBackButton }"
      >
        <div class="step-layout__heading-container">
          <div class="step-layout__heading-left">
            <h3 class="step-layout__heading">
              <slot name="heading" />
            </h3>
            <p v-if="$slots['subheading']" class="step-layout__subheading">
              <slot name="subheading" />
            </p>
          </div>
          <div
            v-if="showMonthlyYearlyToggle"
            class="step-layout__toggle-container"
          >
            <input
              id="radio-monthly"
              class="u-a11y-only"
              :checked="selectedFrequency === 'monthly'"
              name="frequency"
              type="radio"
              value="monthly"
              @change="handlefrequencyChange('monthly')"
            />
            <label
              for="radio-monthly"
              class="u-badge--small step-layout__toggle-item"
            >
              monthly
            </label>
            <input
              id="radio-yearly"
              class="u-a11y-only"
              :checked="selectedFrequency === 'yearly'"
              name="frequency"
              type="radio"
              value="yearly"
              @change="handlefrequencyChange('yearly')"
            />
            <label
              for="radio-yearly"
              class="u-badge--small step-layout__toggle-item"
            >
              yearly - save 10%
            </label>
          </div>
        </div>
        <slot />
      </div>
    </div>
    <div v-if="isTwoColumn" class="step-layout__right">
      <div
        class="step-layout__right-inner"
        :class="{
          'step-layout__right-inner--full': !$slots['right-col-content'],
          'step-layout__right-inner--border': showRightColumnBorder,
          'step-layout__right-inner--reverse': isRightColumnReversed,
        }"
      >
        <div class="step-layout__right-top">
          <slot name="main-image" />
        </div>
        <div
          v-if="$slots['right-col-content']"
          class="step-layout__right-bottom"
        >
          <slot name="right-col-content" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.step-layout {
  --background-color: v-bind('rightColumnBgColor');

  display: flex;
  flex-direction: column-reverse;
  height: 100%;

  @media (min-width: variables.$screen-md-min) {
    flex-direction: row;
  }

  &__left {
    display: flex;
    flex-direction: column;
    height: 100%;

    @media (min-width: variables.$screen-md-min) {
      width: 50%;
    }

    &--single-col {
      width: 100%;
    }
  }

  &__nav {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 1rem 1rem;
    padding-bottom: 0.5rem;

    @media (min-width: variables.$screen-md-min) {
      padding-left: 2rem;
      padding-right: 2rem;
    }

    &--first-step {
      @media (min-width: variables.$screen-md-min) {
        padding: 0;
      }
    }
  }

  :deep(.soona-back-button) {
    margin: 0;
  }

  &__close-btn {
    margin-left: auto;

    @media (min-width: variables.$screen-md-min) {
      position: absolute;
      right: -3.75rem;
      top: 0;
    }
  }

  &__content {
    background:
      linear-gradient(variables.$white-default 30%, rgba(255, 255, 255, 0))
        center top,
      linear-gradient(rgba(255, 255, 255, 0), variables.$white-default 70%)
        center bottom,
      linear-gradient(rgba(0, 0, 0, 0.12), rgba(255, 255, 255, 0)) center top,
      linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.12)) center bottom;
    background-repeat: no-repeat;
    background-size:
      100% 2.5rem,
      100% 2.5rem,
      100% 0.375rem,
      100% 0.375rem;
    background-attachment: local, local, scroll, scroll;
    border-bottom-left-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
    border-top-left-radius: 0.625rem;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
    padding: 1rem;
    padding-top: 0;

    @media (min-width: variables.$screen-md-min) {
      padding: 1.75rem 2rem;
      padding-top: 0.75rem;
    }

    &--first-step {
      @media (min-width: variables.$screen-md-min) {
        padding-top: 1.75rem;
      }
    }
  }

  &__heading-container {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    padding-bottom: 1.75rem;
  }

  &__heading {
    @include variables_fonts.u-headline--heavy;

    padding-bottom: 0.25rem;

    @media (min-width: variables.$screen-md-min) {
      @include variables_fonts.u-display--heavy;
    }
  }

  &__subheading {
    @media (min-width: variables.$screen-md-min) {
      @include variables_fonts.u-subheader--regular;
    }
  }

  &__toggle-container {
    background-color: variables.$gray-30;
    border-radius: 0.625rem;
    display: flex;
    padding: 0.25rem;
    width: max-content;
  }

  &__toggle-item {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 0.5rem 2rem;
    width: max-content;

    input:checked + & {
      background-color: variables.$black-default;
      border-radius: 0.625rem;
      color: variables.$white-default;
    }
  }

  &__right {
    display: none;

    @media (min-width: variables.$screen-md-min) {
      background-color: var(--background-color);
      border-bottom-right-radius: 0.625rem;
      border-top-right-radius: 0.625rem;
      display: block;
      line-height: 0;
      overflow-y: auto;
      width: 50%;
    }
  }

  &__right-inner {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: min-content;
    justify-content: space-between;
    min-height: 100%;
    position: relative;

    &--border {
      &::before {
        border: 0.75rem solid variables.$white-default;
        content: '';
        height: calc(100% - 4.5rem);
        left: 2rem;
        position: absolute;
        top: 2.25rem;
        width: calc(100% - 4rem);
      }

      .step-layout__right-bottom {
        padding-bottom: 4rem;
        padding-left: 4rem;
        padding-right: 4rem;
      }
    }

    &--full {
      display: block;
      height: 100%;

      .step-layout__right-top {
        height: 100%;
      }
    }

    &--reverse {
      flex-direction: column-reverse;
    }
  }

  &__right-top {
    :deep(img) {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  &__right-bottom {
    margin: auto;
    padding: 1.5rem 2.5rem;
  }
}
</style>
