<script setup>
import { computed, inject } from 'vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

const props = defineProps({
  failingImage: { type: Object, required: true },
});

const listingAssets = inject('listingAssets');

const failingImageAsset = computed(() => {
  return listingAssets.value.find(
    asset => asset.digital_asset_id === props.failingImage.digital_asset_id
  );
});

const ruleTitle = rule => {
  if (rule.rule === 'low resolution replace') {
    return 'low resolution';
  }
  return rule.rule;
};

const subtext = rule => {
  if (rule.rule === 'inconsistent aspect ratio') {
    return failingImageAsset.value?.aspect_ratio;
  }
  if (['low resolution', 'low resolution replace'].includes(rule.rule)) {
    return failingImageAsset.value?.resolution;
  }
  return null;
};
</script>

<template>
  <div class="rule-result__wrapper">
    <div class="rule-result__image-icon">
      <SoonaIcon name="circle-exclamation-solid" size="small" />
    </div>
    <div class="rule-result__image">
      <img :src="failingImage.image_url" alt="" />
    </div>

    <div class="rule-result__content">
      <div
        v-for="(rule, i) in failingImage.rules"
        :key="i"
        class="rule-result__broken-rule"
      >
        <div class="rule-result__icon">
          <SoonaIcon :name="rule.icon" />
        </div>
        <div class="rule-result__broken-rule--content">
          <p class="rule-result__title">
            {{ ruleTitle(rule) }}
            <span class="rule-result__title--subtext u-body--regular">{{
              subtext(rule)
            }}</span>
          </p>
          <p class="rule-result__text">
            {{ rule.description }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.rule-result {
  &__wrapper {
    display: flex;
    flex-flow: column;
    gap: 1rem;
    padding: 1rem 0;
    position: relative;

    &:not(:last-child) {
      border-bottom: 0.0625rem solid variables.$gray-30;
    }
  }

  &__image-icon {
    color: variables.$friendly-red-50;
    position: absolute;
    top: 0.375rem;
    left: -0.5625rem;
  }

  &__image {
    width: 9.25rem;
    height: 9.25rem;
    flex: 0 0 9.25rem;

    img {
      border-radius: 0.3125rem;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__broken-rule {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;

    &--content {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
    }
  }

  &__title {
    @include variables_fonts.u-body--heavy;
  }

  // desktop
  // screen-sm-min = 768px
  @media (min-width: variables.$screen-sm-min) {
    &__wrapper {
      flex-direction: row;
    }
  }
}
</style>
