const SoonaSignIn = () => import('@/components/authentication/SoonaSignIn.vue');
const SoonaSignUp = () => import('@/components/authentication/SoonaSignUp.vue');

import {
  prefetchReservation,
  isDraft,
  findIncompleteStep,
} from '@/lib/booking/validateStep';

import { signInBeforeEnter } from '../route-utils';

export const bookingPaths = [
  // redirect links from marketing site to first booking step
  { path: '/book', redirect: '/booking/new' },
  {
    path: '/booking/sign-in',
    name: 'signInPartTwo',
    component: SoonaSignIn,
    beforeEnter: signInBeforeEnter,
    meta: {
      context: 'booking sign in',
      routeLayoutOptions: {
        noContainer: true,
      },
    },
    props: {
      basePath: '/booking',
      redirectToPath: '/booking/new',
      showAccountPicker: true,
    },
  },
  {
    path: '/booking/sign-up',
    name: 'signUpPartTwo',
    component: SoonaSignUp,
    beforeEnter: signInBeforeEnter,
    meta: {
      context: 'booking sign up',
      routeLayoutOptions: {
        noContainer: true,
      },
    },
    props: {
      redirectToPath: '/booking/new',
      basePath: '/booking',
    },
  },
  {
    path: '/booking/free-headshot-received',
    name: 'invalidHeadshotsForAll',
    component: () =>
      import('src/components/booking/v3/p2/InvalidHeadshotsForAll.vue'),
  },
  {
    path: '/booking/new',
    name: 'createReservation',
    component: () =>
      import('src/components/booking/v3/p2/CreateReservation.vue'),
    props: route => ({
      discount: route.query.discount,
      reservationType: route.query.type,
      packConfigurationId: route.query.pack,
      flow: route.query.flow,
      page: route.query.page,
      proService: route.query.pro_service,
      mediaType: route.query.media_type,
    }),
  },
  {
    path: '/booking/:reservationId',
    name: 'newBook',
    component: () => import('src/components/booking/v3/p2/Process.vue'),
    meta: {
      context: 'booking',

      routeLayoutOptions: {
        noContainer: true,
        sidebarCollapsed: true,
      },
    },
    props: route => ({
      reservationId: route.params.reservationId,
      stepName: route.meta.validate_step,
    }),
    beforeEnter: async to => {
      const toReservation = await prefetchReservation(to.params.reservationId);
      if (toReservation && !isDraft(toReservation)) {
        window.location.href = `/user/anytime#/reservation/${to.params.reservationId}/info`;
        return false;
      }

      const incompleteStep = findIncompleteStep({
        desiredSlug: to.meta.validate_step,
        reservation: toReservation,
      });
      if (incompleteStep) {
        return {
          path: `/booking/${to.params.reservationId}/${incompleteStep}`,
        };
      }
    },
    children: [
      {
        path: 'packs',
        name: 'allPacks',
        component: () => import('src/components/booking/v3/p2/AllPacks.vue'),
        meta: {
          page_title: 'packs',
          validate_step: 'type',
        },
      },
      {
        path: 'packs/:packId',
        name: 'packDetails',
        component: () => import('src/components/booking/v3/p2/PackDetails.vue'),
        meta: {
          page_title: 'pack details',
          validate_step: 'type',
        },
        props: route => ({
          packId: route.params.packId,
        }),
      },
      {
        path: 'location',
        name: 'bookingLocation',
        component: () =>
          import('src/components/booking/v3/p2/location/BookingLocation.vue'),
        meta: {
          page_title: 'location',
          validate_step: 'location',
        },
      },
      {
        path: 'studios',
        name: 'bookingStudios',
        component: () =>
          import(
            'src/components/booking/v3/p2/booking-studios/BookingStudios.vue'
          ),
        meta: {
          page_title: 'studios',
          validate_step: 'studios',
        },
      },
      {
        path: 'type',
        name: 'bookingType',
        component: () => import('src/components/booking/v3/p2/BookingType.vue'),
        meta: {
          page_title: 'type',
          validate_step: 'type',
        },
      },
      {
        path: 'models',
        name: 'addModels',
        component: () => import('src/components/booking/v3/p2/AddModels.vue'),
        meta: {
          page_title: 'models',
          validate_step: 'models',
        },
      },
      {
        path: 'upgrades',
        name: 'bookingUpgrades',
        component: () =>
          import('src/components/booking/v3/p2/BookingUpgrades.vue'),
        meta: {
          page_title: 'upgrades',
          validate_step: 'upgrades',
        },
      },
      {
        path: 'purpose',
        name: 'contentPurposePartTwo',
        component: () =>
          import('src/components/booking/v3/p2/ContentPurpose.vue'),
        meta: {
          page_title: 'purpose',
          validate_step: 'purpose',
        },
      },
      {
        path: 'media-type',
        name: 'contentType',
        component: () =>
          import('src/components/booking/v3/p2/content-type/ContentType.vue'),
        meta: {
          page_title: 'media type',
          validate_step: 'media-type',
        },
      },
      {
        path: 'details',
        name: 'bookingDetails',
        component: () =>
          import(
            'src/components/booking/v3/p2/shoot-details/BookingDetails.vue'
          ),
        meta: {
          page_title: 'details',
          validate_step: 'details',
        },
      },
      {
        path: 'down-payment',
        name: 'downpaymentPartTwo',
        component: () => import('src/components/booking/v3/p2/DownPayment.vue'),
        meta: {
          context: 'booking',
          page_title: 'down payment',
          validate_step: 'down-payment',
        },
      },
      {
        path: 'industry',
        name: 'industryPartTwo',
        component: () => import('src/components/booking/v3/p2/Industry.vue'),
        meta: {
          page_title: 'industry',
          validate_step: 'industry',
        },
      },
      {
        path: 'photo-quantity',
        name: 'numberOfPhotos',
        component: () =>
          import(
            'src/components/booking/v3/p2/number-of-photos/NumberOfPhotos.vue'
          ),
        meta: {
          page_title: 'photo quantity',
          validate_step: 'photo-quantity',
        },
      },
      {
        path: 'video-type',
        name: 'videoType',
        component: () =>
          import(
            'src/components/booking/v3/p2/video-type/BookingVideoType.vue'
          ),
        meta: {
          page_title: 'video type',
          validate_step: 'video-type',
        },
      },
      {
        path: 'photo-scenes',
        name: 'photo-scenes',
        component: () =>
          import('src/components/booking/v3/p2/photo-scenes/PhotoScenes.vue'),
        meta: {
          page_title: 'photo scenes',
          validate_step: 'photo-scenes',
        },
      },
      {
        path: 'video-scenes',
        name: 'VideoScenes',
        component: () =>
          import('src/components/booking/v3/p2/video-scenes/VideoScenes.vue'),
        meta: {
          page_title: 'video scenes',
          validate_step: 'video-scenes',
        },
      },
      {
        path: 'schedule',
        name: 'Scheduling',
        component: () =>
          import(
            'src/components/booking/v3/p2/in-studio-scheduling/InStudioScheduling.vue'
          ),
        meta: {
          page_title: 'schedule',
          validate_step: 'schedule',
        },
      },
      {
        path: 'audio',
        name: 'studioBuyOut',
        component: () =>
          import(
            'src/components/booking/v3/p2/studio-buy-out/StudioBuyOut.vue'
          ),
        meta: {
          page_title: 'audio',
          validate_step: 'audio',
        },
      },
      {
        path: 'video-trend-sets',
        name: 'videoTrendSets',
        component: () =>
          import(
            'src/components/booking/v3/p2/video-trend-sets/VideoTrendSets.vue'
          ),
        meta: {
          page_title: 'video trend sets',
          validate_step: 'video-trend-sets',
        },
      },
      {
        path: ':pathmatch(.*)*',
        name: 'notFound',
        redirect: to => {
          return { path: `/booking/${to.params.reservationId}/` };
        },
      },
    ],
  },
];
