import { computed, ref, watch } from 'vue';
import { refDebounced } from '@vueuse/core';
export const useListDataQueryParams = () => {
  const searchText = ref('');

  const searchDebounced = refDebounced(searchText, 200);

  const selectedFilter = ref('all packs');

  const sortBy = ref({
    orderBy: 'name',
    direction: 'asc',
  });

  const orderBy = computed(() => sortBy.value.orderBy);

  const direction = computed(() => sortBy.value.direction);

  // pagination
  const currentPage = ref(1);

  const itemsPerPage = ref(24);

  watch(
    [itemsPerPage, searchDebounced, orderBy, direction, selectedFilter],
    () => {
      currentPage.value = 1;
    }
  );

  function resetFilters() {
    searchText.value = '';
    selectedFilter.value = 'all packs';
  }

  return {
    searchText,
    searchDebounced,
    selectedFilter,
    sortBy,
    orderBy,
    direction,
    currentPage,
    itemsPerPage,
    resetFilters,
  };
};
