<script setup>
import { computed, ref } from 'vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useRoute } from 'vue-router';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaFlag from '@/components/ui_library/SoonaFlag.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaSkeleton from '@/components/ui_library/SoonaSkeleton.vue';
import SubscriptionProductTierCard from '@/components/subscriptions/SubscriptionProductTierCard.vue';
import SubscriptionProductTierCardV2 from '@/components/subscriptions/SubscriptionProductTierCardV2.vue';
import SubscriptionProductTierCardEmpty from '@/components/subscriptions/SubscriptionProductTierCardEmpty.vue';
import { useAccount } from '@/composables/useAccount';
import { useCapability } from '@/composables/useCapability';
import CancellationDialog from '@/components/subscriptions/CancellationDialog.vue';
import { useTiers } from '@/queries/tiers/useTiers';
import { convertToMMDDYYYY, convertToDateLong } from '@/lib/date-formatters';
import { PeriwinkBlue20, PeriwinkBlue80 } from 'src/variables.module.scss';
import { useFlag } from '@/composables/useFlag';

const props = defineProps({
  accountId: {
    type: String,
    required: true,
  },
});

const emits = defineEmits(['show-paywall-dialog', 'set-subscription-tier']);

const route = useRoute();
const { linkClicked } = useBaseEvents();

const apolloTrialForEveryoneFlag = useFlag('apollo_trial_for_everyone');
const apolloV2CardsFlag = useFlag('apollo_subscription_tier_cards_v2');
const pegasusTrialSubscriptionsFlag = useFlag(
  'pegasus_accept_trial_subscriptions'
);

const { data: tiers, isLoading } = useTiers();

const context = computed(() => route.meta?.context);
const accountId = computed(() => props.accountId);

const { account, isBusinessSubscriber, eligibleForPlatformSubscriptionTrial } =
  useAccount(accountId);

const linkHref = 'https://soona.co/contact-sales';

const trackChatClick = () => {
  linkClicked({
    context: context,
    subContext: 'manage plan page',
    linkLabel: 'chat with us',
    linkHref: linkHref,
  });
};

const offerTrial = computed(
  () =>
    apolloTrialForEveryoneFlag.value &&
    eligibleForPlatformSubscriptionTrial.value
);

const currentTier = computed(() => {
  if (!account.value?.subscription || !tiers.value) return null;
  if (account.value?.subscription?.tier?.slug === 'tier-three')
    return account.value?.subscription.tier;

  return tiers.value.find(
    tier => tier.slug === account.value?.subscription.tier.slug
  );
});

const currentRecurringInterval = computed(() => {
  if (!account.value?.subscription) {
    return 'year';
  }

  return (
    account.value?.subscription.subscription_items.find(
      x => x.subscription_item_type === 'base'
    )?.recurring_interval ?? 'month'
  );
});

const isPendingCancellation = computed(() => {
  if (!account.value?.subscription) return null;

  return account.value?.subscription.is_pending_cancelation;
});

const isDowngrading = computed(() => {
  return account.value?.subscription?.downgrade_tier_id ? true : false;
});

const isTrialing = computed(() => account.value?.is_trialing);

const hasNotAcceptedTrial = computed(() => {
  return account.value.subscription.subscription_items.some(item => {
    return (
      item.subscription_item_type === 'base' && item.accepted_terms_at === null
    );
  });
});

const baseSubscriptionEndDate = computed(() => {
  const baseSubscriptionItem =
    account.value?.subscription.subscription_items.find(
      x => x.subscription_item_type === 'base'
    );

  if (baseSubscriptionItem && isTrialing.value) {
    return account.value?.subscription?.trial_end;
  } else {
    return (
      account.value?.subscription.subscription_items.find(
        x => x.subscription_item_type === 'base'
      )?.ended_at ?? null
    );
  }
});

const subscriptionEndCopy = computed(() => {
  if (!account.value?.subscription) return null;

  let planInfo = {};
  if (
    isTrialing.value &&
    (isDowngrading.value || !isPendingCancellation.value)
  ) {
    // below is for the old non-auto-enrolling Preferred tier 2 sub trial promos
    if (hasNotAcceptedTrial.value) {
      planInfo.buttonCopy = 'free trial';
      planInfo.endDateCopy = 'expires';
      planInfo.endDate = account.value?.subscription.trial_end;
      // below is what we want to keep once the Preferred trial promos are deprecated
    } else {
      planInfo.buttonCopy = 'free trial';
      planInfo.endDateCopy = `your trial will end ${
        isDowngrading.value
          ? ''
          : 'and your subscription payment method will be charged'
      } on`;
      planInfo.endDate = account.value?.subscription.trial_end;
    }
  } else if (isPendingCancellation.value) {
    planInfo.buttonCopy = 'pending cancellation';
    planInfo.endDateCopy = 'ends';
    planInfo.endDate = baseSubscriptionEndDate.value;
  } else {
    planInfo.buttonCopy = 'current plan';
    planInfo.endDateCopy = 'renews';
    planInfo.endDate = account.value?.subscription?.current_period_end;
  }

  return planInfo;
});

const downgradeCopy = computed(() => {
  if (!account.value?.subscription) return null;
  let planInfo = {};
  let date = convertToMMDDYYYY(account.value.subscription.trial_end);

  planInfo.buttonCopy = `starts ${date}`;
  planInfo.endDateCopy = `your subscription payment method will be charged on ${date}`;

  return planInfo;
});

// cancel
const showCancellationModal = ref(false);

const cancellationClosed = () => {
  showCancellationModal.value = false;
  window.location.reload();
};

const subscriptionId = computed(() => {
  if (!account.value?.subscription) return null;

  return account.value?.subscription.id;
});

const subscriptionBaseItemPriceId = computed(() => {
  if (!account.value?.subscription) {
    return null;
  }
  return account.value?.subscription.subscription_items.find(
    x => x.subscription_item_type === 'base'
  )?.price_id;
});

const subscriptionBaseItemId = computed(() => {
  if (!account.value?.subscription) {
    return null;
  }

  return account.value?.subscription.subscription_items.find(
    x => x.subscription_item_type === 'base'
  )?.id;
});

const subscriptionPendingActivationItemId = computed(() => {
  if (!account.value?.subscription?.pending_activation_subscription_item) {
    return null;
  }

  return account.value.subscription.pending_activation_subscription_item.id;
});

const currentSubscriptionEnd = computed(() => {
  if (!account.value?.subscription) {
    return null;
  }
  return convertToDateLong(account.value?.subscription?.current_period_end);
});

const cancelSubscriptionBaseId = ref(null);

const cancelSubscription = mode => {
  if (mode === 'downgrade') {
    cancelSubscriptionBaseId.value = subscriptionPendingActivationItemId.value;
  } else {
    cancelSubscriptionBaseId.value = subscriptionBaseItemId.value;
  }
  showCancellationModal.value = true;
};

const { hasCapability: canManageAccountSubscription } = useCapability({
  capability: 'manage_account_subscription',
  subjectType: 'account',
  subjectId: accountId,
});

const { hasCapability: canCrewManageSubscription } = useCapability({
  capability: 'crew_manage_subscription',
});

// const priorityError = usePriorityError(cancelSubscriptionError);

const handleSubscriptionDialogOpen = slug => {
  emits('show-paywall-dialog');
  emits('set-subscription-tier', slug);
};

const canSubscribeToTier = tier => {
  // the user cannot subscribe if they cannot manage subscriptions
  if (!canManageAccountSubscription || !canCrewManageSubscription) {
    return false;
  }

  // the user cannot subscribe if they're already a non-trialing subscriber
  if (currentTier.value && !isTrialing.value) {
    return false;
  }

  // the user cannot subscribe if they have a subscription that is pending cancellation
  if (isPendingCancellation.value) {
    return false;
  }

  // the user cannot subscribe if already subscribed to the given tier
  if (tier.slug === currentTier.value?.slug) {
    return false;
  }

  return true;
};
</script>

<template>
  <div class="subscription-product-tiers">
    <div class="subscription-product-tiers--inner">
      <template v-if="isLoading">
        <SoonaSkeleton
          v-for="n in 3"
          :key="n"
          class="subscription-product-tiers__skeleton"
        />
      </template>
      <template v-else>
        <template v-if="apolloV2CardsFlag">
          <template v-if="isBusinessSubscriber">
            <SubscriptionProductTierCardEmpty
              :key="currentTier.name"
              :tier="currentTier"
              :show-border="true"
            >
              <span
                v-if="canManageAccountSubscription || canCrewManageSubscription"
                class="subscription-product-tiers__current-tier--link-container"
              >
                <SoonaButton
                  element="a"
                  :href="linkHref"
                  target="_blank"
                  copy="chat with us"
                  variation="solid-black"
                  @on-click="trackChatClick"
                >
                  <SoonaIcon name="phone" />
                </SoonaButton>
              </span>
            </SubscriptionProductTierCardEmpty>
          </template>
          <template v-else>
            <SubscriptionProductTierCardV2
              v-for="tier in tiers"
              :key="tier.name"
              :tier="tier"
              :selected-billing-interval="currentRecurringInterval"
              :show-border="true"
              :best-offer-border="tier.slug === 'tier-two'"
            >
              <div
                v-if="tier.slug === currentTier?.slug"
                class="subscription-product-tiers__current-tier"
              >
                <SoonaFlag
                  :background-color="PeriwinkBlue20"
                  :text-color="PeriwinkBlue80"
                  :title="subscriptionEndCopy.buttonCopy"
                />
                <p
                  class="subscription-product-tiers__current-tier--renewal-date u-label--small"
                >
                  {{ subscriptionEndCopy.endDateCopy }}
                  {{
                    convertToMMDDYYYY(
                      subscriptionEndCopy.endDate ||
                        convertToMMDDYYYY(subscriptionEndCopy.trial_end)
                    )
                  }}
                </p>
                <span
                  v-if="
                    canManageAccountSubscription || canCrewManageSubscription
                  "
                  class="subscription-product-tiers__current-tier--cancel-container"
                >
                  <SoonaButton
                    v-if="
                      (!isPendingCancellation && !isTrialing) ||
                      (!hasNotAcceptedTrial && !isPendingCancellation)
                    "
                    class="subscription-product-tiers__current-tier--cancel"
                    variation="tertiary"
                    copy="cancel plan"
                    @on-click="cancelSubscription"
                  />
                  <SoonaButton
                    v-else-if="
                      isTrialing &&
                      pegasusTrialSubscriptionsFlag &&
                      hasNotAcceptedTrial &&
                      !isDowngrading &&
                      !isPendingCancellation
                    "
                    class="subscription-product-tiers__current-tier--cancel"
                    copy="keep this plan"
                    variation="solid-black"
                    @on-click="handleSubscriptionDialogOpen(tier?.slug)"
                  />
                </span>
              </div>

              <div
                v-else-if="tier.id === account?.subscription?.downgrade_tier_id"
                class="subscription-product-tiers__current-tier"
              >
                <SoonaFlag
                  :background-color="PeriwinkBlue20"
                  :text-color="PeriwinkBlue80"
                  :title="downgradeCopy.buttonCopy"
                />
                <p
                  class="subscription-product-tiers__current-tier--renewal-date u-label--small"
                >
                  {{ downgradeCopy.endDateCopy }}
                </p>
                <span
                  v-if="
                    canManageAccountSubscription || canCrewManageSubscription
                  "
                  class="subscription-product-tiers__current-tier--cancel-container"
                >
                  <SoonaButton
                    class="subscription-product-tiers__current-tier--cancel"
                    copy="cancel plan"
                    variation="tertiary"
                    @on-click="cancelSubscription('downgrade')"
                  />
                </span>
              </div>
              <SoonaButton
                v-if="canSubscribeToTier(tier)"
                :copy="offerTrial ? 'try it free for 30 days' : 'choose plan'"
                variation="solid-black"
                @on-click="handleSubscriptionDialogOpen(tier.slug)"
              />
            </SubscriptionProductTierCardV2>
          </template>
        </template>
        <template v-else>
          <SubscriptionProductTierCard
            v-for="tier in tiers"
            :key="tier.name"
            :tier="tier"
            :selected-billing-interval="currentRecurringInterval"
            show-border
            class="subscription-product-tiers__tier"
          >
            <div
              v-if="tier.slug === currentTier?.slug"
              class="subscription-product-tiers__current-tier"
            >
              <SoonaFlag
                :background-color="PeriwinkBlue20"
                :text-color="PeriwinkBlue80"
                :title="subscriptionEndCopy.buttonCopy"
              />
              <p
                class="subscription-product-tiers__current-tier--renewal-date u-label--small"
              >
                {{ subscriptionEndCopy.endDateCopy }}
                {{
                  convertToMMDDYYYY(
                    subscriptionEndCopy.endDate ||
                      convertToMMDDYYYY(subscriptionEndCopy.trial_end)
                  )
                }}
              </p>
              <span
                v-if="canManageAccountSubscription || canCrewManageSubscription"
                class="subscription-product-tiers__current-tier--cancel-container"
              >
                <SoonaButton
                  v-if="
                    (!isPendingCancellation && !isTrialing) ||
                    (!hasNotAcceptedTrial && !isPendingCancellation)
                  "
                  class="subscription-product-tiers__current-tier--cancel"
                  copy="cancel plan"
                  variation="tertiary"
                  @on-click="cancelSubscription"
                />
                <SoonaButton
                  v-else-if="
                    isTrialing &&
                    pegasusTrialSubscriptionsFlag &&
                    hasNotAcceptedTrial &&
                    !isDowngrading &&
                    !isPendingCancellation
                  "
                  class="subscription-product-tiers__current-tier--cancel"
                  copy="keep this plan"
                  variation="solid-black"
                  @on-click="handleSubscriptionDialogOpen(tier?.slug)"
                />
              </span>
            </div>

            <div
              v-else-if="tier.id === account?.subscription?.downgrade_tier_id"
              class="subscription-product-tiers__current-tier"
            >
              <SoonaFlag
                :background-color="PeriwinkBlue20"
                :text-color="PeriwinkBlue80"
                :title="downgradeCopy.buttonCopy"
              />
              <p
                class="subscription-product-tiers__current-tier--renewal-date u-label--small"
              >
                {{ downgradeCopy.endDateCopy }}
              </p>
              <span
                v-if="canManageAccountSubscription || canCrewManageSubscription"
                class="subscription-product-tiers__current-tier--cancel-container"
              >
                <SoonaButton
                  class="subscription-product-tiers__current-tier--cancel"
                  copy="cancel plan"
                  variation="tertiary"
                  @on-click="cancelSubscription('downgrade')"
                />
              </span>
            </div>
            <SoonaButton
              v-if="canSubscribeToTier(tier)"
              class="subscription-product-tiers__current-tier--cancel"
              :copy="offerTrial ? 'try it free for 30 days' : 'choose plan'"
              variation="solid-black"
              @on-click="handleSubscriptionDialogOpen(tier.slug)"
            />
          </SubscriptionProductTierCard>
        </template>
        <CancellationDialog
          v-if="showCancellationModal"
          :account-id="accountId"
          :subscription-id="subscriptionId"
          :subscription-item-price-id="subscriptionBaseItemPriceId"
          :subscription-item-id="cancelSubscriptionBaseId"
          subscription-item-type="base"
          :subscription-current-period-end="currentSubscriptionEnd"
          @close="cancellationClosed()"
        />
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.subscription-product-tiers {
  container: tiers-wrapper / inline-size;

  &--inner {
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;

    @container tiers-wrapper (min-width: 40rem) {
      flex-wrap: nowrap;
    }

    @media (min-width: variables.$screen-sm-min) {
      gap: 2rem;
    }
  }

  &__skeleton {
    height: 16rem;
    border-radius: 0.625rem;
  }

  &__current-tier {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    padding: 1rem 0;
    padding-bottom: 0.1rem;

    &--cancel-container,
    &--link-container {
      width: 100%;

      a {
        width: 100%;
      }
    }

    &--cancel {
      width: 100%;
    }

    &--renewal-date {
      color: variables.$gray-60;
      margin-bottom: 1rem;

      &.u-label--small {
        width: 90%;
        font-size: 0.875rem;
        text-align: center;
        margin-top: 0.5rem;
      }
    }
  }
}
</style>
