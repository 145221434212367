<template>
  <div class="profile-traits-page">
    <div
      v-if="canManageAnyProServiceProfile"
      class="profile-notes-section drop-shadow-container"
    >
      <h3>profile notes</h3>
      <p>
        add notes about the pro service provider. these notes are not visible to
        the provider
      </p>
      <span v-if="editProfileNotes">
        <span class="number-inputs">
          <span>
            <label class="trait-title">arrived late</label>
            <v-select
              v-model:model-value="lateCount"
              class="select-number"
              :get-option-label="number => `${number}`"
              :options="numbers"
              :reduce="numbers => numbers"
              :placeholder="`select number`"
            />
          </span>
          <span>
            <label class="trait-title">late cancel</label>
            <v-select
              v-model:model-value="cancelCount"
              class="select-number"
              :get-option-label="number => `${number}`"
              :options="numbers"
              :reduce="numbers => numbers"
              :placeholder="`select number`"
            />
          </span>
        </span>
        <span class="profile-notes">
          <SoonaTextfield
            v-model:model-value="providerNote"
            type="text"
            label="profile notes"
          />
        </span>
      </span>
      <span v-if="!editProfileNotes" class="profile-notes-display">
        <p>
          <strong>arrived late: </strong
          >{{ proServiceProvider.arrivedLateCount }}
        </p>
        <p>
          <strong>late cancel: </strong>{{ proServiceProvider.lateCancelCount }}
        </p>
        <p>
          <strong>profile notes: </strong>{{ proServiceProvider.providerNote }}
        </p>
      </span>
      <SoonaButton
        v-if="!editProfileNotes"
        class="edit-btn"
        variation="tertiary"
        @click="toggleEditProfileNotes(true)"
      >
        edit
      </SoonaButton>
      <span v-if="editProfileNotes" class="action-btns">
        <SoonaButton
          size="medium"
          variation="tertiary"
          @click="toggleEditProfileNotes(false)"
        >
          cancel
        </SoonaButton>
        <SoonaButton size="medium" @click="addNotes">save notes</SoonaButton>
      </span>
    </div>
    <div class="trait-entry-section drop-shadow-container">
      <span class="traits-header">
        <h3>services & traits</h3>
        <span v-if="currentProfileServices.length > 0" class="traits-toggle">
          <SoonaToggle
            v-model:model-value="toggleService"
            type="switch"
            label="all services"
            align="right"
            :disabled="disableToggle"
          />
        </span>
      </span>
      <p>
        add the service and traits for the service this provider offers. each
        service requires a new set of traits
      </p>
      <div class="profile-details-section">
        <hr />
        <div class="profile-details-section-info-block">
          <div
            v-if="profileInfoIncompleteTraits"
            class="incomplete-traits-section"
          >
            <span>
              <svg
                width="17"
                height="17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 8.5a8.5 8.5 0 1 1 17 0 8.5 8.5 0 0 1-17 0Z"
                  fill="#F0562E"
                />
                <path
                  d="M9.28 4.294v3.432c0 .364-.02.724-.06 1.08-.037.356-.089.726-.157 1.11H7.947a17.722 17.722 0 0 1-.162-1.11 10.728 10.728 0 0 1-.054-1.08V4.294H9.28ZM7.51 12.13a.926.926 0 0 1 .282-.678.97.97 0 0 1 .696-.282c.135 0 .261.026.377.078.12.048.224.116.312.204a.926.926 0 0 1 .21.306c.052.116.078.24.078.372a.913.913 0 0 1-.078.378 1.017 1.017 0 0 1-.21.306.966.966 0 0 1-.312.198.979.979 0 0 1-.378.072.983.983 0 0 1-.696-.27 1.017 1.017 0 0 1-.21-.306.979.979 0 0 1-.072-.378Z"
                  fill="#fff"
                />
              </svg>
              {{ profileInfoIncompleteTraits }} incomplete trait<template
                v-if="profileInfoIncompleteTraits !== 1"
                >s</template
              >
            </span>
          </div>
          <div v-if="editServicesTraits" class="profile-details-section-input">
            <SoonaTextfield
              v-model:model-value="newProfileFirstName"
              class="details-text-input"
              type="text"
              label="first name - used in our pro service catalog"
              placeholder=""
            />
            <SoonaTextfield
              v-model:model-value="newProfileLastName"
              class="details-text-input"
              type="text"
              label="last name - used in our pro service catalog"
              placeholder=""
            />
            <SoonaTextfield
              v-model:model-value="newProfileDetails"
              class="details-text-input"
              type="text"
              label="other info or details our crew should know about you"
              placeholder=""
            />
            <SoonaTextfield
              v-model:model-value="newProfileAllergens"
              class="details-text-input"
              type="text"
              label="allergens"
              placeholder=""
            />
          </div>
          <div v-else class="profile-details-section-text">
            <span class="details-text">
              <p>first name:</p>
              {{ userProfileFirstName }}
            </span>
            <span class="details-text">
              <p>last name:</p>
              {{ userProfileLastName }}
            </span>
            <span class="details-text">
              <p>other notes:</p>
              {{ userProfileDetails }}
            </span>
            <span class="details-text">
              <p>allergens:</p>
              {{ userAllergens }}
            </span>
          </div>
        </div>
        <div class="right-align-btn">
          <SoonaButton
            v-if="!editServicesTraits"
            variation="tertiary"
            copy="edit"
            :on-click="toggleEditServicesTraits"
          />
          <span v-if="editServicesTraits" class="action-btns">
            <SoonaButton
              class="cancel"
              size="medium"
              variation="tertiary"
              copy="cancel"
              :on-click="toggleEditServicesTraits"
            />
            <SoonaButton
              :disabled="profileInfoIncompleteTraits > 0"
              size="medium"
              :is-submit="true"
              copy="save"
              :on-click="addServicesTraits"
            />
          </span>
        </div>
      </div>
      <span v-if="currentProfileServices.length > 0">
        <ProServiceProfileCard
          v-for="item in currentProfileServices"
          :key="item.id"
          :service="item"
        />
      </span>
      <span v-if="addingService" class="trait-entry">
        <ProServiceProfileCard @update-adding-service="updateAddingService" />
      </span>
      <hr />
      <SoonaButton
        v-if="canManageAnyProServiceProfile"
        class="add-submit-btn"
        data-cypress="psp-add-service-button"
        @click="addService"
      >
        add service
      </SoonaButton>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { useCapability } from '@/composables/useCapability';
import ProServiceProfileCard from './ProServiceProfileCard.vue';
import SoonaToggle from 'src/components/ui_library/SoonaToggle.vue';
import SoonaTextfield from 'src/components/ui_library/SoonaTextfield.vue';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';

export default {
  name: 'ProServiceProfile',
  components: {
    ProServiceProfileCard,
    SoonaToggle,
    SoonaTextfield,
    SoonaButton,
  },
  setup() {
    const { hasCapability: canManageAnyProServiceProfile } = useCapability({
      capability: 'manage_any_pro_service_profile',
    });

    return { canManageAnyProServiceProfile };
  },
  data() {
    return {
      numbers: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      addingService: false,
      editProfileNotes: false,
      editServicesTraits: false,
      lateCount: 0,
      cancelCount: 0,
      providerNote: '',
      disableToggle: false,
      newProfileFirstName: '',
      newProfileLastName: '',
      newProfileAllergens: '',
      newProfileDetails: '',
    };
  },
  computed: {
    ...mapState({
      proServices: state => state.proService.proServices,
      proServiceProvider: state => state.proService.proServiceProvider,
      currentTagCategories: state => state.proService.currentTagCategories,
      currentProfileServices: state => state.proService.currentProfileServices,
      userId: state => state.account.account_userId,
      userAllergens: state => state.user.allergens,
      userProfileDetails: state => state.user.pro_service_provider_details,
      userProfileFirstName: state => state.user.profile_first_name,
      userProfileLastName: state => state.user.profile_last_name,
    }),
    proServiceTypes() {
      let proServiceItem = this.currentTagCategories.find(
        item => item.title === 'pro services'
      );
      return proServiceItem.tags.filter(
        item => item.tag_category_id === proServiceItem.id
      );
    },
    profileInfoIncompleteTraits() {
      let traits = 2;
      if (this.editServicesTraits) {
        if (this.newProfileFirstName) {
          traits--;
        }
        if (this.newProfileLastName) {
          traits--;
        }
      } else {
        if (this.userProfileFirstName) {
          traits--;
        }
        if (this.userProfileLastName) {
          traits--;
        }
      }
      return traits;
    },
    toggleService: {
      get() {
        return this.currentProfileServices.find(x => x.is_active === true)
          ? true
          : false;
      },
      set(bool) {
        this.disableToggle = true;
        this.batchUpdateProfiles(bool);
        setTimeout(() => (this.disableToggle = false), 1500);
      },
    },
  },
  async mounted() {
    await this.loadProServiceProvider();
    this.lateCount = this.proServiceProvider.arrivedLateCount;
    this.cancelCount = this.proServiceProvider.lateCancelCount;
    this.providerNote = this.proServiceProvider.providerNote;
    this.updateLocalUserDetails();
  },
  methods: {
    ...mapActions('proService', [
      'loadProServices',
      'loadProServiceProvider',
      'updateProServiceProfile',
      'batchUpdateProServiceProfiles',
    ]),
    ...mapActions('user', ['updateUser']),
    async addService() {
      await this.loadProServices();
      this.addingService = true;
    },
    addNotes() {
      this.updateProServiceProfile({
        arrivedLateCount: this.lateCount,
        lateCancelCount: this.cancelCount,
        note: this.providerNote,
      });
      this.editProfileNotes = false;
    },
    addServicesTraits() {
      if (this.newProfileFirstName === '') {
        this.newProfileFirstName = null;
      }
      if (this.newProfileLastName === '') {
        this.newProfileLastName = null;
      }
      if (this.newProfileDetails === '') {
        this.newProfileDetails = null;
      }
      if (this.newProfileAllergens === '') {
        this.newProfileAllergens = null;
      }
      this.updateUser({
        userId: this.userId,
        userParams: {
          profile_first_name: this.newProfileFirstName,
          profile_last_name: this.newProfileLastName,
          pro_service_provider_details: this.newProfileDetails,
          allergens: this.newProfileAllergens,
        },
      });
      this.toggleEditServicesTraits();
    },
    toggleEditProfileNotes(value) {
      this.editProfileNotes = value;
    },
    toggleEditServicesTraits() {
      this.updateLocalUserDetails();
      this.editServicesTraits = !this.editServicesTraits;
    },
    updateAddingService() {
      this.addingService = false;
    },
    updateLocalUserDetails() {
      this.newProfileFirstName = this.userProfileFirstName;
      this.newProfileLastName = this.userProfileLastName;
      this.newProfileAllergens = this.userAllergens;
      this.newProfileDetails = this.userProfileDetails;
    },
    batchUpdateProfiles(value) {
      this.batchUpdateProServiceProfiles({ isActive: value });
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.profile-traits-page {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.edit-btn {
  margin-left: auto;
}

.action-btns {
  align-items: center;
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
}

label {
  @include variables_fonts.u-label--heavy;

  color: variables.$black-default;
  text-align: left;
  margin-bottom: 0;
  padding-bottom: 0.4375rem;
  display: block;
}

.profile-traits-page {
  h3 {
    font-weight: 800;
  }
  .trait-title {
    font-size: 14px;
    margin: -3px 20px 0 0;
  }
  .profile-details-section {
    display: flex;
    flex-direction: column;
    .profile-details-section-info-block {
      .incomplete-traits-section {
        margin-bottom: 1.25rem;
        @include variables_fonts.u-label--heavy;

        svg {
          margin-right: 0.625rem;
          position: relative;
          top: 3px;
        }
      }
      .profile-details-section-input {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .details-text-input {
          width: 30rem;
          margin-right: 2rem;
        }
      }
      .profile-details-section-text {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        flex-direction: column;
        .details-text {
          margin-bottom: 0.25rem;
          p {
            display: inline;
            font-weight: bold;
          }
        }
      }
    }
    .right-align-btn {
      align-self: flex-end;
    }
  }
  .profile-notes-section {
    display: flex;
    flex-direction: column;
    p {
      margin-bottom: 10px;
    }
    .number-inputs {
      max-width: 450px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 20px;
      .select-number {
        width: 200px;
      }
    }
    .profile-notes {
      div {
        max-width: 450px;
        margin-bottom: 30px;
      }
    }
    .right-align-btn {
      align-self: flex-end;
    }
    .profile-notes-display {
      p {
        margin-bottom: 0;
      }
    }
  }
  .trait-entry-section {
    display: flex;
    flex-direction: column;
    .traits-header {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      .traits-toggle {
        display: flex;
        flex: 0 0 auto;
        align-items: center;
        .toggle-label {
          font-size: 12px;
          margin-right: 5px;
        }
      }
    }
    h3 {
      margin-bottom: 10px;
    }
    hr {
      margin: 20px 0;
    }
    .trait-entry {
      margin-bottom: 30px;
      display: flex;
      flex-direction: column;
    }
  }
  .add-submit-btn {
    align-self: flex-end;
  }
}
</style>
