import { computed, reactive } from 'vue';
import { computedEager } from '@vueuse/core';
import { useSoonaToast } from '@/components/ui_library/soona_toast/useSoonaToast';
import { usePackConfiguration } from '@/queries/pack-configurations/usePackConfiguration';
import { useUpdatePackConfiguration } from '@/queries/pack-configurations/useUpdatePackConfiguration';
import { stableStringify } from './utils';

export function usePackConfigurationState(packConfigurationId) {
  const {
    data: packData,
    error,
    isSuccess,
  } = usePackConfiguration({
    packId: packConfigurationId,
  });

  const {
    mutate: updatePackConfiguration,
    error: mutateError,
    isPending: isMutating,
  } = useUpdatePackConfiguration();

  const initialDraft = () => ({
    description: {},
    pack_product: {},
  });

  /*
   * define all top-level empty object structures that we are going to allow to
   * be set.
   *
   * a reactive doesn't support replacing the object, but can track deep
   * changes, so we instead store it all at one property, so we can reset later.
   */
  const draft = reactive({ data: initialDraft() });

  function computedForProperty(propertyName, nestedProperty = null) {
    if (nestedProperty) {
      return computed({
        get: () =>
          draft.data[propertyName]?.[nestedProperty] ??
          packData.value?.[propertyName]?.[nestedProperty],
        set: newVal => {
          draft.data[propertyName][nestedProperty] = newVal;
        },
      });
    }
    return computed({
      get: () => draft.data[propertyName] ?? packData.value?.[propertyName],
      set: newVal => {
        draft.data[propertyName] = newVal;
      },
    });
  }

  const packConfigurationTagsAttributes = computed(() => {
    const originalState = packData.value?.photo_segment ?? [];
    let result = originalState.map(x => {
      if (!draft.data.photo_segment) {
        return { ...x, _destroy: 0 };
      }
      if (draft.data.photo_segment?.find(y => y.tag_id === x.tag_id)) {
        return { ...x, _destroy: 0 };
      }
      return { ...x, _destroy: 1 };
    });
    draft.data.photo_segment?.forEach(x => {
      if (!result.find(y => y.tag_id === x.tag_id)) {
        result.push({ tag_id: x.tag_id });
      }
    });

    return result;
  });

  const mergedPack = reactive({
    id: computed(() => packData.value.id),
    account_id: computedForProperty('account_id'),
    category: computed(() => packData.value.category),
    default_recommended_pack: computedForProperty('default_recommended_pack'),
    description: {
      body: computedForProperty('description', 'body'),
      includes: computedForProperty('description', 'includes'),
      shotlist_header: computedForProperty('description', 'shotlist_header'),
    },
    display_discount: computedForProperty('display_discount'),
    hide_in_booking: computed(() => packData.value.hide_in_booking),
    isPackVideo: computedForProperty('isPackVideo'),
    isStopMotion: computedForProperty('isStopMotion'),
    max_quantity: computedForProperty('max_quantity'),
    merchandising_tags: computedForProperty('merchandising_tags'),
    name: computedForProperty('name'),
    original_price: computedForProperty('original_price'),
    original_price_description: computedForProperty(
      'original_price_description'
    ),
    pack_configuration_tags_attributes: packConfigurationTagsAttributes,
    pack_discount: computedForProperty('pack_discount'),
    pack_main_image: computedForProperty('pack_main_image'),
    pack_main_image_url: computedForProperty('pack_main_image_url'),
    pack_price: computedForProperty('pack_price'),
    pack_product: {
      description: computedForProperty('pack_product', 'description'),
      id: computedForProperty('pack_product', 'id'),
      included_photos: computedForProperty('pack_product', 'included_photos'),
      included_gifs: computedForProperty('pack_product', 'included_gifs'),
      included_videos: computedForProperty('pack_product', 'included_videos'),
      name: computedForProperty('pack_product', 'name'),
      product_ids: computedForProperty('pack_product', 'product_ids'),
      rate: computedForProperty('pack_product', 'rate'),
      thumbnail: computedForProperty('pack_product', 'thumbnail'),
      thumbnail_url: computedForProperty('pack_product', 'thumbnail_url'),
    },
    packTagId: computedForProperty('packTagId'),
    photo_segment: computedForProperty('photo_segment'),
    preview_vimeo_id: computedForProperty('preview_vimeo_id'),
    products_attributes: computed(() => {
      return packData.value.pack_product?.product_ids?.map(p => ({
        ...mergedPack.pack_product,
        id: p,
      }));
    }),
    pro_service_products: computedForProperty('pro_service_products'),
    reference_images: computedForProperty('reference_images'),
    status: computedForProperty('status'),
    shotTemplates: computedForProperty('shotTemplates'),
  });

  /*
   * this returns a boolean, and probably won't change very often, so we can
   * use this helper to cache the boolean and only return a new reg when it
   * changes
   */
  const hasUnsavedChanges = computedEager(() => {
    if (!packData.value) return false;

    // remove product attributes from comparison
    let {
      products_attributes, // eslint-disable-line no-unused-vars
      pack_configuration_tags_attributes, // eslint-disable-line no-unused-vars
      ...adjustedPack
    } = mergedPack;

    // only compare the properties we allow for editing and display
    const serverData = Object.keys(adjustedPack).reduce((result, key) => {
      result[key] = packData.value[key];

      return result;
    }, {});

    const stableMerged = stableStringify(adjustedPack);
    const stableServerData = stableStringify(serverData);

    return stableMerged !== stableServerData;
  });

  const hasUnsavedProServiceChanges = computedEager(() => {
    if (!packData.value?.pro_service_products) return false;

    let { pro_service_products } = mergedPack;

    const stableMerged = stableStringify(pro_service_products);
    const stableServerData = stableStringify(
      packData.value.pro_service_products
    );

    return stableMerged !== stableServerData;
  });

  const { addToast } = useSoonaToast();

  // custom error message outside of priority error
  const errorMessage = computed(() => {
    if (error.value) {
      return `an error has occured loading pack #${packConfigurationId.value}`;
    } else if (mutateError.value?.response?.data?.errors) {
      return mutateError.value.response.data.errors?.[0]?.message;
    } else return 'an error has occured';
  });
  function save() {
    updatePackConfiguration(mergedPack, {
      onSuccess: () => {
        addToast('pack configurations have been saved', {
          variation: 'success',
        });
      },
      onError: () => {
        addToast(errorMessage, {
          variation: 'error',
        });
      },
    });
  }

  function savePackName(onSuccess) {
    let products = packData.value.pack_product?.product_ids?.map(p => ({
      id: p,
      name: mergedPack.name,
    }));

    updatePackConfiguration(
      {
        id: packData.value.id,
        name: mergedPack.name,
        products_attributes: products,
      },
      { onSuccess }
    );
  }

  function discardChanges() {
    draft.data = initialDraft();
  }

  return {
    discardChanges,
    hasUnsavedChanges,
    hasUnsavedProServiceChanges,
    isMutating,
    isSuccess,
    pack: mergedPack,
    save,
    savePackName,
  };
}
