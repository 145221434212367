<script setup>
import { computed } from 'vue';
import { convertToMMDDYYYYlong } from 'src/lib/date-formatters';
import SoonaFlag from 'src/components/ui_library/SoonaFlag.vue';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaTooltip from 'src/components/ui_library/SoonaTooltip.vue';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';
import {
  BlackDefault,
  FriendlyRed50,
  Gray30,
  AvoToast20,
  WhiteDefault,
} from '@/variables.module.scss';

const props = defineProps({
  order: {
    type: Object,
    required: true,
  },
  accountId: {
    type: String,
    required: true,
  },
  isFtSoonaStaff: {
    type: Boolean,
    default: false,
  },
});
const emits = defineEmits(['cancelOrder']);
const orderId = computed(() => props.order.id);
const paymentMethodPresent = computed(() => !!props.order.payment_method);
const paymentMethod = computed(() => props.order.payment_method);
const statusBadge = computed(() => {
  const text = props.order.status;

  switch (props.order.status) {
    case 'canceled':
      return { text, bg: FriendlyRed50, c: WhiteDefault };
    case 'created':
      return { text, bg: Gray30, c: BlackDefault };
    case 'confirmed':
      return { text, bg: Gray30, c: BlackDefault };
    case 'paid':
      return { text, bg: AvoToast20, c: BlackDefault };
    default:
      return { text, bg: BlackDefault, c: WhiteDefault };
  }
});
const showFlag = computed(() => {
  if (
    props.order.status === 'canceled' ||
    props.order.status === 'created' ||
    props.order.status === 'confirmed' ||
    props.order.status === 'paid' ||
    props.order.status === 'completed'
  ) {
    return true;
  }
  return false;
});

const styledAmountCollected = computed(() => {
  let amt = 0;

  if (props.order.amount_collected) {
    amt = props.order.amount_collected;
  } else {
    amt = props.order.reservation_line_items.reduce(
      (sum, lineItem) =>
        sum +
        Number(lineItem.total) -
        Number(lineItem.effective_percent_discount),
      0
    );
  }

  return Number(amt).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
});

function paymentMethodDisplayType(value) {
  switch (value.type) {
    case 'card':
      return `${value.data.brand} credit card`;
    case 'us_bank_account':
      return 'bank account';
    default:
      return value.type;
  }
}

function cancelOrder() {
  emits('cancelOrder', orderId.value);
}
</script>
<template>
  <article class="order">
    <div class="order__item1">
      <SoonaFlag
        v-if="showFlag"
        class="order__status-flag"
        :background-color="statusBadge.bg"
        :text-color="statusBadge.c"
        :title="statusBadge.text"
      />
      <h2 class="order__date u-label--heavy">
        {{ convertToMMDDYYYYlong(order.created_at) }}
      </h2>
      <div class="order__number text-s">
        <p>order#: {{ orderId }}</p>
        <p v-if="order.reservation_id">booking#: {{ order.reservation_id }}</p>
      </div>
    </div>
    <div class="order__item2">
      <div class="order__price-info">
        <p class="order__amount">
          {{ styledAmountCollected }}
        </p>
        <div v-if="paymentMethodPresent && paymentMethod" class="bank-info">
          <p>{{ paymentMethodDisplayType(paymentMethod) }}</p>
          <p>.... .... .... {{ paymentMethod.data.last4 }}</p>
        </div>
      </div>
      <div class="order__links">
        <SoonaTooltip placement="top">
          <template
            #default="{
              setRef,
              mouseenter,
              focus,
              mouseleave,
              blur,
              ariaDescribedby,
            }"
          >
            <a
              :ref="el => setRef(el)"
              @mouseenter="mouseenter"
              @focus="focus"
              @mouseleave="mouseleave"
              @blur="blur"
            >
              <router-link :to="`/account/${accountId}/orders/${orderId}`">
                <SoonaButton
                  element="div"
                  variation="secondary-gray"
                  class="links__link-button"
                  size="large"
                  :aria-describedby="ariaDescribedby"
                >
                  <SoonaIcon name="arrow-up-right-from-square" />
                </SoonaButton>
              </router-link>
            </a>
          </template>
          <template #tooltip-content>view invoice</template>
        </SoonaTooltip>
        <SoonaButton
          v-if="isFtSoonaStaff && order.status === 'created'"
          copy="cancel order"
          class="cancel-order"
          variation="tertiary"
          size="medium"
          :on-click="cancelOrder"
        />
      </div>
    </div>
  </article>
</template>
<style lang="scss" scoped>
@use '@/variables';

.order {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 0.5rem;
  border: 0.0625rem solid variables.$gray-40;
  border-radius: 0.375rem;
  padding: 0.5rem 1rem;

  &__status-flag {
    margin-bottom: 0.375rem;
  }
  &__links {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  &__item1 {
    display: flex;
    flex-direction: column;
    align-self: center;
  }
  &__item2 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.5rem 1rem;
    align-items: center;
  }
  &__date {
    text-transform: uppercase;
  }
  &__amount {
    font-size: 1.5rem;
    font-weight: 800;
  }
  &__price-info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .bank-info {
    text-align: center;
    p {
      margin: 0;
    }
  }
}

.links__link-button {
  width: 3rem;
  height: 3rem;
  border-radius: 2rem;
}
</style>
