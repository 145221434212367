<script setup>
import { computed, onMounted } from 'vue';
import { useAccount } from '@/queries/account/useAccount';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useDigitalAssets } from '@/queries/digital_assets/useDigitalAssets';
import { useMe } from '@/composables/user/useMe';
import { usePriorityErrors } from '@/composables/usePriorityErrors';
import { useTitle } from '@vueuse/core';
import AccountAssets from '@/components/account_gallery/AccountAssets.vue';
import AccountAlbums from '@/components/account_gallery/AccountAlbums.vue';
import PastBookings from '@/components/account_gallery/PastBookings.vue';
import AccountGalleryEmptyState from '@/components/account_gallery/AccountGalleryEmptyState.vue';
import ShopMore from '@/components/account_gallery/ShopMore.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';
import AccountInfoAuto from '@/components/user/anytime/dashboard/AccountInfoAuto.vue';

const props = defineProps({
  accountId: {
    required: true,
    type: [Number, String],
  },
});

useTitle('gallery | soona');

const { pageViewed } = useBaseEvents();

const { currentAccountId } = useMe();
const accountId = computed(() => +props.accountId);

const {
  data: account,
  isLoading: isLoadingAccount,
  error: errorAccount,
} = useAccount(accountId);

const isMyAccount = computed(() => currentAccountId.value === accountId.value);

const headingCopy = computed(() => {
  if (isMyAccount.value) return 'gallery';
  return `${account.value?.name}’s gallery`;
});

const {
  data: digitalAssets,
  isLoading: isLoadingDigitalAssets,
  error: errorDigitalAssets,
} = useDigitalAssets({
  accountId,
  itemsPerPage: 1,
  filters: {
    origin: ['customer', 'soona'],
    ownership: ['customer', 'soona'],
    media_type: ['photo', 'video'],
    visibility: 'all',
  },
});

const priorityErrors = usePriorityErrors(errorAccount, errorDigitalAssets);

const hasAssets = computed(() => digitalAssets.value?.assets?.length > 0);
const isLoading = computed(
  () => isLoadingAccount.value || isLoadingDigitalAssets.value
);

onMounted(() => {
  pageViewed();
});
</script>

<template>
  <section class="gallery" data-cypress="account-gallery">
    <AccountInfoAuto :account-id="accountId" />
    <SoonaLoading v-if="isLoading" is-loading />
    <SoonaError v-else-if="priorityErrors" :priority-errors="priorityErrors" />
    <template v-else>
      <h1 class="u-headline--heavy">{{ headingCopy }}</h1>
      <AccountGalleryEmptyState
        v-if="!hasAssets"
        :account-id="accountId"
        :show-call-to-action-buttons="isMyAccount"
      />
      <div v-else class="gallery__flex">
        <AccountAssets :account-id="accountId" />
        <AccountAlbums :account-id="accountId" />
        <PastBookings :account-id="accountId" />
        <ShopMore :account-id="accountId" />
      </div>
    </template>
  </section>
</template>

<style lang="scss" scoped>
.gallery {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  h1 {
    margin-bottom: 0.5rem;
  }

  &__flex {
    display: flex;
    flex-direction: column;
    gap: 3.5rem;
  }
}
</style>
