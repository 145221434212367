<template>
  <div class="AdditionalChargeOrder">
    <div
      v-for="rli in reservationLineItems"
      :key="rli.id"
      class="reservation-line-item columns"
    >
      <img
        :src="rli.product.thumbnail_url"
        alt=""
        class="reservation-line-item-thumbnail column is-one-quarter"
      />
      <div class="reservation-line-item-add-charge column is-three-quarter">
        <p class="additional-charge-name u-body--heavy">
          {{ rli.product.name.toLowerCase() }}
        </p>
        <p
          v-if="rli.product.description"
          class="additional-charge-name u-body--regular"
        >
          {{ rli.product.description.toLowerCase() }}
        </p>
        <p
          v-if="rli.effective_percent_discount"
          class="u-body--heavy is-size-7-mobile"
        >
          <s class="old-price">{{ toCurrency(+rli.product.rate) }}</s>
          <span class="new-price">
            {{
              toCurrency(+rli.product.rate - +rli.effective_percent_discount)
            }}
          </span>
          x {{ rli.quantity }}
        </p>
        <p v-else class="u-body--heavy is-size-7-mobile">
          {{ toCurrency(+rli.product.rate) }} x {{ rli.quantity }}
        </p>
      </div>
    </div>
    <hr />
    <section v-if="order.memo">
      <div class="memo-details-view columns">
        <div class="column is-one-quarter is-flex">
          <div class="memo-avatar">
            <img
              v-if="order.creator_avatar"
              :src="order.creator_avatar"
              alt="user avatar"
              class="memo-avatar-details"
            />
            <img
              v-else
              src="@images/account-placeholder.svg"
              alt="user avatar"
              class="memo-avatar-details"
            />
            <p v-if="order.creator">
              {{ order.creator.name }}
            </p>
            <p v-else class="memo-user">soona staff</p>
          </div>
        </div>
        <div class="column is-three-quarters">
          <div class="memo-details-container">
            <span class="memo-create">
              {{ convertToMMDDYYYYlong(order.created_at) }}
              {{ convertToHHMM12(order.created_at) }}</span
            >
            <br />
            <p v-if="order.status === 'paid'" class="memo-subject">
              thanks for your order! 😊
            </p>
            <p v-else class="memo-subject">
              order invoice - please complete and we'll get started! 😊
            </p>
            <p>Hello {{ currentReservation.account.name }}!</p>
            <p class="memo-content">
              {{ order.memo }}
            </p>
          </div>
        </div>
      </div>
      <hr />
    </section>

    <section class="totals-container">
      <p class="u-headline--heavy is-size-5-mobile">total:</p>
      <p class="u-headline--heavy is-size-5-mobile">
        {{
          orderTotal.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })
        }}
      </p>
      <div v-if="order.discount_code" class="promo-code-container">
        <p class="u-body--regular is-size-7-mobile">
          promo code: {{ order.discount_code }}
        </p>
      </div>
    </section>
    <section class="cancel-container">
      <p class="error">
        {{ cancelingError || resendingError }}
      </p>
      <button
        v-if="order.status === 'created' && canCancelAdditionalChargeOrder"
        class="button is-small is-employee mr-s u-small--regular"
        :disabled="canceling"
        @click="handleCancel"
      >
        <beat-loader v-if="canceling" color="#FFFFFF" size="10px" />
        {{ canceling ? null : 'cancel order' }}
      </button>
      <button
        v-if="order.status === 'created' && canResendAdditionalChargeOrder"
        class="button is-small is-employee ml-s u-small--regular"
        @click="handleResend"
      >
        <beat-loader v-if="resending" color="#FFFFFF" size="10px" />
        {{ resending ? null : 'resend invoice' }}
      </button>
    </section>

    <SoonaDialog v-if="showResendConfirmModal" @close="closeModal">
      <template #heading>succesfully resent additional charge</template>
      <span>
        📧 succesfully resent invoice to
        <strong>{{ currentReservation.account.email }}</strong>
      </span>
      <template #footer="{ close }">
        <SoonaButton data-cypress="button-dialog-confirm" @on-click="close">
          got it
        </SoonaButton>
      </template>
    </SoonaDialog>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import { toCurrency } from '@/lib/currency';
import { useCapability } from '@/composables/useCapability';
import * as Sentry from '@sentry/vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import dateTimeMixin from 'src/mixins/dateTimeMixin';

export default {
  name: 'AdditionalChargeOrder',
  components: { SoonaButton, SoonaDialog },
  mixins: [dateTimeMixin],
  props: {
    order: Object,
  },
  setup() {
    const { hasCapability: canCancelAdditionalChargeOrder } = useCapability({
      capability: 'cancel_additional_charge_order',
    });

    const { hasCapability: canResendAdditionalChargeOrder } = useCapability({
      capability: 'resend_additional_charge_order',
    });

    return { canCancelAdditionalChargeOrder, canResendAdditionalChargeOrder };
  },
  data() {
    return {
      canceling: false,
      cancelingError: '',
      resending: false,
      resendingError: '',
      showResendConfirmModal: false,
    };
  },
  computed: {
    ...mapState({
      currentReservation: state => state.reservation.currentReservation,
    }),
    reservationLineItems() {
      return this.order.reservation_line_items;
    },
    orderTotal() {
      if (this.order.amount_collected) {
        return Number(this.order.amount_collected);
      } else {
        return this.order.reservation_line_items.reduce(
          (sum, lineItem) => sum + lineItem.product.rate * lineItem.quantity,
          0
        );
      }
    },
  },
  methods: {
    ...mapActions('reservation', ['cancelOrder', 'resendOrder']),
    toCurrency,
    handleCancel() {
      this.canceling = true;
      this.cancelingError = '';
      this.cancelOrder(this.order.id)
        .then(([, error]) => {
          if (error) {
            this.cancelingError = error.message;
          }
          this.canceling = false;
        })
        .catch(error => {
          this.canceling = false;
          this.cancelingError = error.message;
          Sentry.captureException(new Error('failed to cancel order'), {
            extra: error,
          });
        });
    },
    handleResend() {
      this.resending = true;
      this.resendingError = '';
      this.resendOrder(this.order.id)
        .then(([, error]) => {
          if (error) {
            this.resendingError = error.message;
          }
          this.resending = false;
          this.showResendConfirmModal = true;
        })
        .catch(error => {
          this.resending = false;
          this.resendingError = error.message;
          Sentry.captureException(new Error('failed to resend order'), {
            extra: error,
          });
        });
    },
    closeModal() {
      this.showResendConfirmModal = false;
    },
  },
};
</script>
<style lang="scss">
@use '@/variables';

.AdditionalChargeOrder {
  width: 100%;

  .reservation-line-item {
    padding: 1.5rem;
    @media only screen and (min-width: 768px) {
      display: flex;
      align-items: center;
    }

    .reservation-line-item-thumbnail,
    .reservation-line-item-add-charge,
    .order-memo {
      @media only screen and (min-width: 768px) {
        margin-right: 1.5rem;
      }
    }
    .memo-title {
      font-weight: 800;
      letter-spacing: 0.1rem;
      text-transform: uppercase;
    }
    .memo-text {
      font-style: italic;
    }
  }

  .totals-container {
    padding: 1.5rem;
    display: flex;
    justify-content: space-between;
  }

  .cancel-container {
    display: flex;
    align-items: center;
    padding-left: 1rem;
    height: 2.5rem;

    .error {
      flex: 1;
    }

    .cancel-button {
      margin: 0;
      margin-left: 1rem;
    }
  }
  .memo-details-view {
    margin: 0.5em;
    padding: 1em;
    display: flex;
    position: relative;
  }
  .memo-avatar-details {
    width: 50px;
    height: 50px;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 290486px;
  }
  .memo-create,
  .memo-user {
    font-weight: 800;
    font-size: 12px;
  }
  .memo-subject {
    font-weight: 800;
  }
  .memo-user {
    text-align: center;
  }
  .memo-details-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .memo-content {
    white-space: pre-line;
    word-wrap: break-word;
  }

  .old-price {
    color: variables.$gray-50;
    font-weight: 400;
    padding-right: 0.5rem;
  }

  .new-price {
    color: variables.$friendly-red-60;
  }

  .promo-code-container {
    display: flex;
    justify-content: flex-start;
    font-weight: 800;
  }
}
</style>
