<script setup>
import { computed, watch, ref, unref, provide, toRefs } from 'vue';
import {
  onBeforeRouteLeave,
  onBeforeRouteUpdate,
  useRoute,
  useRouter,
} from 'vue-router';
import {
  onKeyStroke,
  useMediaQuery,
  useTimeoutFn,
  useTitle,
} from '@vueuse/core';
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useCapability } from 'src/composables/useCapability';
import { useDialogSoonaSelect } from '@/composables/useDialogSoonaSelect';
import BottomActionBar from '@/components/user/anytime/gallery/media-editor/action-bars/BottomActionBar.vue';
import LeftActionBar from '@/components/user/anytime/gallery/media-editor/action-bars/LeftActionBar.vue';
import MediaAsset from 'src/components/user/anytime/gallery/media-editor/MediaAsset.vue';
import MediaChat from 'src/components/user/anytime/gallery/media-editor/MediaChat.vue';
import TopActionBar from '@/components/user/anytime/gallery/media-editor/action-bars/TopActionBar.vue';
import RightActionBar from '@/components/user/anytime/gallery/media-editor/action-bars/RightActionBar.vue';
import SoonaAlert from '@/components/ui_library/SoonaAlert.vue';
import UnsavedChangesWarning from '@/components/user/anytime/gallery/media-editor/media-multiplier/UnsavedChangesWarning.vue';
import { TELEPORT_TO } from '@/composables/useTeleportTo';
import ShareFeedbackDialog from '@/components/user/anytime/gallery/media-editor/ShareFeedbackDialog.vue';
import RotateDigitalAsset from '@/components/user/anytime/gallery/media-editor/RotateDigitalAsset.vue';
import { useStore } from 'vuex';
import { ROUTE_MAP } from '@/components/user/anytime/gallery/media-editor/mediaEditorActionKeys';
import SubscriptionsDialog from 'src/components/modal-payment-flows/subscriptions/SubscriptionsDialog.vue';
import { useDialogContext } from '@/composables/useDialog';
import uniqueId from 'lodash/uniqueId';
import StaffActionBar from '@/components/user/anytime/gallery/media-editor/action-bars/staff-actions-bar/StaffActionBar.vue';
import TempGifActionBar from '@/components/user/anytime/gallery/media-editor/action-bars/staff-actions-bar/TempGifActionBar.vue';
import AIEditorIframe from '@/components/user/anytime/gallery/media-editor/media-multiplier/ai/AIEditorIframe.vue';
import { useMediaEditor } from '@/components/user/anytime/gallery/media-editor/useMediaEditor';
import { useMediaEditorDigitalAsset } from '@/composables/digital_assets/useMediaEditorDigitalAsset';

const props = defineProps({
  context: {
    type: String,
    default: 'dam',
  },
  asset: {
    type: Object,
    default: undefined,
  },
  reservationId: {
    type: [String, Number],
    default: undefined,
  },
  isAssetLoading: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(['close', 'approve-image']);

const popoverRef = ref();
const id = uniqueId('media-editor-');

const { checkAllowEscapeClose } = useDialogContext({ id });

provide(TELEPORT_TO, popoverRef);

const route = useRoute();
const router = useRouter();
const store = useStore();
const authedAccountId = computed(() => store.state.currentUser?.accountId);
const urlTool = computed(() => route.query.tool);

const activeAction = ref(undefined);

const showPaywallDialog = ref(false);

const { hasCapability: isFtSoonaStaff } = useCapability({
  capability: 'ft_soona_staff',
});
const actionBar = ref();
const mediaAsset = ref();

const { asset, reservationId, isAssetLoading } = toRefs(props);

const assetId = computed(() => asset.value?.id);
const assetAccountId = computed(() => asset.value?.account_id);

const {
  rdaId,
  isPhoto,
  isGif,
  isIndependentRawAsset,
  ownedByCustomer,
  ownedBySoona,
  mediaUrl,
  title,
  truncatedTitle,
} = useMediaEditorDigitalAsset(assetAccountId, assetId);

const { isAIPropsActive, isAIScenesActive, isImageResizerActive } =
  useMediaEditor({ activeAction });

const { start: beginClose, isPending: isClosing } = useTimeoutFn(
  e => {
    emits('close', e);
  },
  150,
  { immediate: false }
);

function closePopover(e) {
  if (!isClosing.value && !e?.delivered_asset_id) {
    beginClose(e);
  } else {
    emits('close', e);
  }
}

const selectedVariation = ref(undefined);
const selectVariation = variation => {
  selectedVariation.value = variation;
};
const variations = computed(() => {
  const result = [];

  const digitalAsset = unref(asset);

  if (digitalAsset?.media?.url) {
    result.push({
      label: 'original',
      value: digitalAsset.media.url,
    });
  }

  if (digitalAsset?.auto_edit?.url) {
    result.push({
      label: 'auto edit',
      value: digitalAsset.auto_edit.url,
    });
  }

  // customer watermarked view
  if (digitalAsset?.watermark?.url) {
    result.push({
      label: 'customer view',
      value: digitalAsset.watermark?.url,
    });
  }

  return result;
});

const url = computed(() => {
  return (
    variations.value?.find(v => v.value === selectedVariation.value)?.value ??
    mediaUrl.value
  );
});

const canvasData = ref();
const iframeUrl = ref();
const selectorData = ref({});
const selectionData = ref({});

const isViewingForeground = ref(false);
const matchMediaIsWide = useMediaQuery('(min-width: 60rem)');
const showWarning = ref(false);
const warningContinueAction = ref(null);
const warningContinueRoute = ref(null);
const isNotesDirty = ref(false);
const showShareFeedback = ref(false);

useFocusTrap(popoverRef, { immediate: true });

const tabTitle = computed(
  () => `${truncatedTitle.value ?? 'media editor'} | soona`
);
useTitle(tabTitle);

const showWarningOnReset = computed(
  () => ownedByCustomer.value && !iframeUrl.value
);

const setAction = action => {
  activeAction.value = action === activeAction.value ? undefined : action;
  if (!activeAction.value) {
    iframeUrl.value = null;
    selectorData.value = {};
  } else if (!isAIPropsActive.value && !isAIScenesActive.value) {
    iframeUrl.value = null;
  }
};
const updateActiveAction = action => {
  if ((canvasData.value && showWarningOnReset.value) || isNotesDirty.value) {
    showWarning.value = true;
    warningContinueAction.value = action;
  } else {
    canvasData.value = null;
    setAction(action);
  }
};

const isIframeOpen = computed(() => !!iframeUrl.value);
const setIframeUrl = url => {
  iframeUrl.value = url;
};

const updateSelector = data => (selectorData.value = data);
const updateSelection = data => (selectionData.value = data);

const showPreviewOnlyAlert = computed(() => {
  return (
    ownedBySoona.value &&
    (Object.keys(selectorData.value).length > 0 ||
      canvasData.value ||
      isViewingForeground.value)
  );
});

const drawCanvas = imageData => {
  canvasData.value = imageData;
};

const activeActionIsSymbol = computed(() => {
  return typeof activeAction.value === 'symbol';
});

const drawingCanvas = computed(
  () => !!canvasData.value && activeActionIsSymbol.value
);

const currentlyEditing = computed(
  () =>
    drawingCanvas.value ||
    isImageResizerActive.value ||
    isViewingForeground.value ||
    isIframeOpen.value
);

const handleSaveToGallery = () => {
  actionBar.value.handleSaveToGallery();
};

const saveLowResCanvas = () => {
  if (isImageResizerActive.value) return mediaAsset.value.downloadLowResCrop();

  mediaAsset.value.saveLowResCanvas();
};

const refreshCropper = () => {
  mediaAsset.value.refreshCropper();
};

const handleClose = () => {
  if (drawingCanvas.value && showWarningOnReset.value) {
    warningContinueRoute.value = 'close';
    showWarning.value = true;
  } else {
    closePopover();
  }
};

const handleWarningCancel = () => {
  showWarning.value = false;
  warningContinueAction.value = null;
};

const handleWarningContinue = () => {
  canvasData.value = null;
  isNotesDirty.value = false;
  iframeUrl.value = null;

  if (warningContinueRoute.value) {
    router.push(warningContinueRoute.value);
    warningContinueRoute.value = null;
  } else {
    setAction(warningContinueAction.value);
  }
  showWarning.value = false;
  warningContinueAction.value = null;
};

const isChatOpen = ref(false);

const hideChat = computed(() => {
  return (
    (!!activeAction.value && !matchMediaIsWide.value) || isIframeOpen.value
  );
});

// lifecycle hooks

onKeyStroke('Escape', () => {
  if (checkAllowEscapeClose(popoverRef)) {
    handleClose();
  }
});

const checkForUnsavedChanges = () => {
  if ((drawingCanvas.value && showWarningOnReset.value) || isNotesDirty.value) {
    showWarning.value = true;
    warningContinueAction.value = 'close';
    return false;
  }
  return true;
};

const { pageViewed } = useBaseEvents();

watch(
  () => assetId,
  () => {
    pageViewed();
  },
  { immediate: true }
);

useDialogSoonaSelect(popoverRef);

onBeforeRouteLeave(async to => {
  warningContinueRoute.value = to;
  if ((drawingCanvas.value && showWarningOnReset.value) || isNotesDirty.value) {
    warningContinueRoute.value = to;
    return checkForUnsavedChanges();
  }
  if (isIframeOpen.value) {
    setIframeUrl(null);
  }
  return true;
});

onBeforeRouteUpdate(async to => {
  if ((drawingCanvas.value && showWarningOnReset.value) || isNotesDirty.value) {
    warningContinueRoute.value = to;
    return checkForUnsavedChanges();
  }
  if (isIframeOpen.value) {
    setIframeUrl(null);
  }
  return true;
});

watch(
  urlTool,
  () => {
    if (urlTool.value) {
      activeAction.value = ROUTE_MAP[urlTool.value] || null;
      const queryParams = { ...route.query };
      delete queryParams.tool;
      router.replace({ query: queryParams });
    }
  },
  { immediate: true }
);
</script>

<template>
  <Teleport to="body">
    <div
      ref="popoverRef"
      class="media-editor"
      :class="{
        'media-editor--closing': isClosing,
      }"
      role="dialog"
      aria-modal="true"
    >
      <TopActionBar
        v-if="matchMediaIsWide || !iframeUrl"
        :asset="asset"
        :active-action="activeAction"
        :is-wide="matchMediaIsWide"
        :currently-editing="currentlyEditing"
        :is-ai-iframe-open="isIframeOpen"
        @save-to-gallery="() => handleSaveToGallery()"
        @save-low-res-canvas="() => saveLowResCanvas()"
        @show-paywall-dialog="() => (showPaywallDialog = true)"
        @close="closePopover"
      >
        {{ title }}
      </TopActionBar>
      <div class="media-editor__editing-panel">
        <LeftActionBar
          v-if="matchMediaIsWide && !isIndependentRawAsset"
          ref="actionBar"
          class="media-editor__left-action-bar"
          :active-action="activeAction"
          :asset="asset"
          :reservation-id="reservationId"
          :selection-data="selectionData"
          :iframe-url="iframeUrl"
          @update-action="updateActiveAction"
          @update-selector="updateSelector"
          @draw-canvas="drawCanvas($event)"
          @show-foreground="isViewingForeground = $event"
          @close-editor="closePopover($event)"
          @show-paywall-dialog="() => (showPaywallDialog = true)"
          @is-notes-dirty="isNotesDirty = $event"
          @show-feedback-dialog="() => (showShareFeedback = true)"
          @set-iframe-url="setIframeUrl"
        />
        <div
          class="media-editor__middle-column"
          :class="{
            'media-editor__middle-column--no-overflow': iframeUrl,
            'media-editor__middle-column--chat-open': isChatOpen && !hideChat,
          }"
        >
          <StaffActionBar
            v-if="isFtSoonaStaff && asset && rdaId && !isIframeOpen"
            :asset="asset"
            :selected-variation="url"
            :variations="variations"
            @select-variation="selectVariation"
          />
          <TempGifActionBar
            v-else-if="isGif && ownedBySoona"
            :asset="asset"
            :reservation-id="reservationId"
          />
          <SoonaAlert v-if="showPreviewOnlyAlert" class="media-editor__alert">
            <span class="media-editor__alert-content">
              <b>edits are preview-only</b> on unpurchased photos
            </span>
          </SoonaAlert>
          <slot name="error" />
          <div
            class="media-editor__img-wrapper"
            :class="{ 'media-editor__img-wrapper--dense': iframeUrl }"
          >
            <MediaAsset
              v-if="asset && !isIndependentRawAsset && !iframeUrl"
              ref="mediaAsset"
              :file="asset"
              :account-id="authedAccountId"
              :active-action="activeAction"
              :src-url="url"
              :canvas-data="canvasData"
              :show-foreground="isViewingForeground"
              :popover-ref="popoverRef"
              :is-file-loading="isAssetLoading"
              :selector-data="selectorData"
              @update-selection="updateSelection"
            />
            <AIEditorIframe
              v-else-if="asset && isIframeOpen"
              :iframe-url="iframeUrl"
              @show-paywall-dialog="showPaywallDialog = true"
              @update-action="updateActiveAction"
              @close-iframe="setIframeUrl(null)"
              @approve-image="$emit('approve-image', $event)"
            />
            <RotateDigitalAsset
              v-if="isPhoto && matchMediaIsWide && !iframeUrl"
              :disabled="!!activeAction"
              :asset="asset"
              class="media-editor__img-rotate"
            />
          </div>
          <MediaChat
            v-if="!!reservationId"
            v-model:is-chat-open="isChatOpen"
            :reservation-id="reservationId"
            :hide-chat="hideChat"
          />
        </div>
        <RightActionBar
          v-if="matchMediaIsWide && !isIframeOpen"
          :active-action="activeAction"
          :asset="asset"
          :reservation-id="reservationId"
          @update-action="updateActiveAction"
          @is-notes-dirty="isNotesDirty = $event"
        />
      </div>
      <BottomActionBar
        v-if="!matchMediaIsWide && !isIndependentRawAsset"
        ref="actionBar"
        :active-action="activeAction"
        :asset="asset"
        :selection-data="selectionData"
        :iframe-url="iframeUrl"
        @close-editor="closePopover"
        @update-action="updateActiveAction"
        @update-selector="updateSelector"
        @draw-canvas="drawCanvas($event)"
        @refresh-cropper="refreshCropper"
        @show-foreground="isViewingForeground = $event"
        @show-paywall-dialog="() => (showPaywallDialog = true)"
        @is-notes-dirty="isNotesDirty = $event"
        @set-iframe-url="setIframeUrl"
      />
      <slot v-if="!isIframeOpen" name="carousel" />
    </div>
    <ShareFeedbackDialog
      v-if="assetId"
      :open="showShareFeedback"
      :subject-id="assetId"
      @close="() => (showShareFeedback = false)"
    />
    <UnsavedChangesWarning
      :open="showWarning"
      :message="
        isNotesDirty
          ? 'your notes won’t be saved if you close the editor now. close anyway?'
          : undefined
      "
      @cancel="handleWarningCancel"
      @continue="handleWarningContinue"
    />
    <SubscriptionsDialog
      v-if="showPaywallDialog"
      class="media-editor__paywall-dialog"
      selected-tier-slug="tier-one"
      @close="() => (showPaywallDialog = false)"
    />
  </Teleport>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.media-editor {
  background-color: variables.$gray-10;
  bottom: 0;
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  animation:
    0.25s ease-out both scale-up,
    0.15s ease-out both k-fade-in;

  @media (prefers-reduced-motion: reduce) {
    animation: 0.15s ease-out both k-fade-in;
  }

  &--closing {
    animation:
      0.15s ease-out both scale-down,
      0.15s ease-out both k-fade-out;

    @media (prefers-reduced-motion: reduce) {
      animation: 0.15s ease-out both k-fade-out;
    }
  }

  &__editing-panel {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
    position: relative;

    @media (min-width: variables.$screen-md-min) {
      flex-direction: row;
    }
  }

  &__middle-column {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    height: 100%;
    overflow-y: auto;
    transition: padding-right 0.3s cubic-bezier(0.22, 1, 0.36, 1);

    &--no-overflow {
      overflow-y: hidden;
    }
  }

  &__alert {
    margin: 1rem 0 0;
    max-width: 90rem;
  }

  &__alert-content {
    @include variables_fonts.u-label--regular;
  }

  &__img-wrapper {
    align-items: center;
    display: grid;
    flex-grow: 1;
    grid-template-columns: 1fr auto;
    gap: 1rem;
    justify-items: center;
    min-height: 0;
    padding: 1rem 0 1rem 1rem;
    width: 100%;

    &--dense {
      gap: 0rem;
      padding: 0;
    }
  }

  &__img-rotate {
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
  }

  :deep(.subscriptions-dialog) {
    z-index: 16;
  }

  :deep(.soona-dialog) {
    z-index: 16;
  }

  @media (min-width: 45.0625rem) {
    &__middle-column--chat-open {
      padding-right: 18.75rem;
    }
  }

  @media (min-width: variables.$screen-md-min) {
    &__middle-column--chat-open {
      // matches clamp values in MediaChat.vue
      padding-right: clamp(14.25rem, 23.2vw, 23.4375rem);
    }

    &__alert-content {
      @include variables_fonts.u-body--regular;
    }
  }
}

@keyframes scale-up {
  from {
    transform: scale(0.95);
  }
}

@keyframes scale-down {
  to {
    transform: scale(0.95);
  }
}
</style>
