<script setup>
import { toRefs } from 'vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaFlag from '@/components/ui_library/SoonaFlag.vue';

const props = defineProps({
  enabled: {
    type: Boolean,
    default: true,
  },
  thumbImage: {
    type: String,
    required: true,
  },
  isPaywalled: {
    default: false,
    required: false,
    type: Boolean,
  },
  title: {
    type: String,
    default: '',
  },
  subtitle: {
    type: String,
    default: '',
  },
  description: {
    type: String,
    default: '',
  },
});

const emits = defineEmits(['on-click']);

const { enabled, isPaywalled, thumbImage, title, subtitle, description } =
  toRefs(props);

const handleClick = () => {
  if (enabled.value) {
    emits('on-click');
  }
};
</script>

<template>
  <div class="secondary-button">
    <button
      class="u-button-reset secondary-button__container"
      :class="!enabled ? 'disabled' : null"
      @click="handleClick"
    >
      <img
        v-if="thumbImage"
        class="secondary-button__img"
        :src="thumbImage"
        alt=""
      />
      <span class="secondary-button__content">
        <SoonaFlag
          v-if="isPaywalled"
          background-color="transparent"
          type="pill"
          is-pizzazz
          class="secondary-button__paywalled"
        >
          <template #icon-left>
            <SoonaIcon name="crown" size="x-small" class="crown" />
          </template>
        </SoonaFlag>
        <span class="u-label--heavy secondary-button__title">
          {{ title }}
        </span>
        <span class="u-small--heavy secondary-button__subtitle">
          {{ subtitle }}
        </span>
        <span class="u-small--regular secondary-button__description">
          {{ description }}
        </span>
      </span>
    </button>
  </div>
</template>

<style scoped lang="scss">
@use 'src/variables';

.secondary-button {
  position: relative;
  height: 4.125rem;

  .disabled {
    cursor: not-allowed;
    opacity: 0.5;
    &:hover {
      background: none;
    }
  }

  &__container {
    display: flex;
    padding: 0 0.25rem 0 0;
    align-items: center;
    gap: 0.5rem;
    border-radius: 0.625rem;
    white-space: normal;

    &.active {
      outline-offset: -0.125rem;
      outline: 0.125rem solid variables.$periwink-blue-70;
      background: variables.$gray-10;
      border-radius: 0.625rem;
    }

    &:hover {
      background: variables.$gray-10;
    }
  }

  &__paywalled {
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
  }

  &__content {
    display: flex;
    flex-direction: column;
    text-align: left;
    font-size: 0.75rem;
    color: variables.$gray-60;
  }

  &__img {
    min-height: 3rem;
    width: 4.5rem;
    flex: 0 0 4.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.625rem;
  }

  &__title {
    padding-bottom: 0.25rem;
    color: variables.$black-default;
  }
}
.crown {
  color: variables.$white-default;
}
</style>
