<script setup>
import { computed } from 'vue';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaSkeleton from '@/components/ui_library/SoonaSkeleton.vue';
import { useRoute } from 'vue-router';

defineProps({
  accountId: {
    type: [Number, String],
    required: true,
  },
  productError: {
    type: String,
    required: false,
  },
  isLoading: {
    type: Boolean,
    required: false,
  },
  product: {
    type: Object,
    required: false,
  },
});

const emit = defineEmits(['openProductPicker']);

const openPicker = () => emit('openProductPicker');

const route = useRoute();

const queryObj = computed(() => {
  return {
    ...route.query,
    returnToInventory: true,
  };
});
</script>

<template>
  <div class="inventory-item-product">
    <div
      v-if="isLoading || productError"
      class="inventory-item-product__loading"
    >
      <SoonaSkeleton class="skeleton-title" />
      <SoonaSkeleton class="skeleton-name" />
    </div>

    <div v-else-if="!product" class="inventory-item-product__identify">
      <SoonaButton
        copy="identify"
        size="medium"
        variation="secondary-black"
        :on-click="openPicker"
      />
      <SoonaIcon
        class="inventory-item-product__identify-icon"
        name="circle-exclamation-solid"
      />
    </div>
    <div v-else class="inventory-item-product__name-outer-wrapper">
      <h5 class="inventory-item-product__name-label">product name</h5>
      <router-link
        class="inventory-item-product__name product-link"
        :to="{
          path: `/account/${accountId}/products/${product.id}`,
          query: queryObj,
        }"
      >
        <span>
          {{ product.name }}
        </span>
        <SoonaIcon
          class="inventory-item-product__name-icon"
          name="chevron-right"
        />
      </router-link>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.inventory-item-product {
  display: flex;
  flex-direction: row;
  align-items: center;

  &__loading {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    min-width: 0;
    gap: 0.5rem;
  }

  &__name-outer-wrapper {
    flex: 1 1 auto;
    min-width: 0;
  }

  &__name-label {
    @include variables_fonts.u-label--small;
  }

  &__name {
    @include variables_fonts.u-label--heavy;

    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 0;
    flex: 1 1 auto;

    & > span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 10rem;
    }

    @media (min-width: variables.$screen-sm-min) {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-icon {
      display: inline;
      flex: 0 0 1.5rem;
    }
  }

  &__identify {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5625rem;
    padding: 0.25rem 0;
    flex: 1 0 auto;

    &-icon {
      color: variables.$roses-60;
      flex: 0 0 1rem;
    }
  }
}

.product-link {
  &:hover {
    text-decoration: underline;
  }
}

.skeleton {
  &-title {
    flex: 1 1 auto;
    height: 0.875rem;
    max-width: 5rem;
  }

  &-name {
    flex: 2 1 auto;
    height: 1rem;
    max-width: 10rem;
  }
}
</style>
