<template>
  <CompleteProfileCard
    class="get-basics"
    :disabled="disabled"
    :show-skip="false"
    @next="saveBasicsInfo"
  >
    <template #heading>let’s get the basics</template>
    <fieldset class="field-wrapper">
      <legend class="u-a11y-only">company information</legend>
      <SoonaTextfield
        v-model="company_name"
        class="basics-input"
        label="company name"
        name="company name"
        placeholder="my company"
      />
      <SoonaTextfield
        v-model="website"
        class="basics-input"
        label="website"
        name="website"
        placeholder="https://mycompany.com"
      />
      <SoonaSelect v-model="industry" :options="industryOptions">
        <template #label>industry type</template>
      </SoonaSelect>
    </fieldset>
  </CompleteProfileCard>
</template>

<script>
import { computed, ref, watchEffect } from 'vue';
import { mapState, mapActions, useStore } from 'vuex';
import * as Sentry from '@sentry/vue';
import { useIndustries } from 'src/queries/useIndustries';
import CompleteProfileCard from './CompleteProfileCard.vue';
import SoonaSelect from '@/components/ui_library/SoonaSelect.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';

export default {
  name: 'LetsGetTheBasics',
  components: { CompleteProfileCard, SoonaSelect, SoonaTextfield },
  setup() {
    const store = useStore();
    const { data: industries } = useIndustries();
    const formattedIndustries = computed(() =>
      industries.value.map(i => ({
        id: i.id,
        label: i.title,
        value: i.slug,
      }))
    );
    const industry = ref(undefined);
    const account = computed(() => store.state.account);
    const accountSelectedIndustry = computed(
      () => account.value?.industry?.slug
    );

    watchEffect(() => (industry.value = account.value?.industry?.title));

    const industryOptions = computed(() => {
      if (!industries.value) return [];

      if (
        industries.value.findIndex(
          i => i.slug === accountSelectedIndustry.value
        ) > -1
      ) {
        return formattedIndustries.value;
      }

      const deprecatedIndustry = {
        ...account.value.industry,
        active: false,
      };

      return [...formattedIndustries.value, deprecatedIndustry];
    });

    return { industry, industryOptions };
  },
  data() {
    return {
      website: '',
      company_name: '',
    };
  },
  computed: {
    ...mapState({
      account: state => state.account,
    }),
    disabled() {
      return this.company_name === '';
    },
  },
  watch: {
    account: function () {
      this.prefillExistingData();
    },
  },
  mounted() {
    this.prefillExistingData();
  },
  methods: {
    ...mapActions('account', ['updateAccount']),
    prefillExistingData() {
      this.website =
        this.account.profile_data.lets_get_the_basics?.data?.website;
      this.company_name = this.account.name;
    },
    async saveBasicsInfo() {
      const info = {
        industry_id: this.industryOptions.find(i => i.value === this.industry)
          ?.id,
        lets_get_the_basics: {
          data: {
            website: this.website,
            industry_type:
              this.account.profile_data.lets_get_the_basics?.data
                ?.industry_type,
          },
          complete: true,
        },
        name: this.company_name,
      };
      try {
        await this.updateAccount({ accountParams: info });
      } catch (error) {
        if (error.response.status !== 422) {
          Sentry.captureException(new Error('Updating account basics failed'), {
            extra: { error },
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.get-basics {
  .field-wrapper {
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
  }

  .basics-input {
    padding-bottom: 0.5rem;
  }
}
</style>
