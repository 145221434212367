<script setup>
import { computed, onBeforeUnmount, ref } from 'vue';
import { convertToHHmmss } from '@/lib/date-formatters';
import { useBaseEvents } from 'src/composables/segment/useBaseEvents';
import { useRoute } from 'vue-router';
import dayjs from 'dayjs';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

const props = defineProps({
  reservationId: {
    required: true,
    type: [Number, String],
  },
  checkoutDeadline: {
    required: true,
    type: String,
  },
});

const { linkClicked } = useBaseEvents();
const route = useRoute();

const checkoutDeadline = computed(() => props.checkoutDeadline);
const convertedCheckoutDeadline = computed(() => dayjs(checkoutDeadline.value));
const isGallery = computed(() => route.name === 'gallery');
const reservationId = computed(() => props.reservationId);

const currentTime = ref(dayjs());
const linkLabel = 'shop your shoot';
const linkHref = `/reservation/${reservationId.value}`;

const updateCurrentTime = () => {
  currentTime.value = dayjs();
};

const checkoutTimeLeft = computed(() => {
  const deadline = convertedCheckoutDeadline.value?.diff(currentTime.value);

  if (deadline > 0) return convertToHHmmss(deadline, 'UTC');
  return '00:00:00';
});

const isIncentiveAvailable = computed(() => {
  const hoursLeft = Math.floor(
    convertedCheckoutDeadline.value.diff(currentTime.value) / (1000 * 60 * 60)
  );

  return hoursLeft < 24 && checkoutTimeLeft.value !== '00:00:00';
});

const updateTimeInterval = setInterval(updateCurrentTime, 1000);

onBeforeUnmount(() => {
  clearInterval(updateTimeInterval);
});

const handleLinkClick = () => {
  linkClicked({
    context: route.meta.context,
    subContext: 'fast checkout banner',
    linkLabel: linkLabel,
    linkHref: linkHref,
  });
};
</script>
<template>
  <div v-if="isIncentiveAvailable" class="speedy-checkout-banner">
    <div class="speedy-checkout-banner__inner">
      <div class="speedy-checkout-banner__left">
        <img src="@images/egg-timer@2x.jpg" alt="" />
      </div>
      <div class="speedy-checkout-banner__center">
        <p class="u-title--regular speedy-checkout-banner__content">
          <span>
            order before the clock expires & get a discount on your next
            booking!
          </span>
          <strong>{{ checkoutTimeLeft }} remaining</strong>
        </p>
      </div>
      <div class="speedy-checkout-banner__right">
        <SoonaButton
          v-if="!isGallery"
          :copy="linkLabel"
          element="router-link"
          :to="{ name: 'gallery', params: { reservationId: reservationId } }"
          variation="secondary-black"
          @on-click="handleLinkClick"
        >
          <template #icon-right>
            <SoonaIcon name="arrow-right" />
          </template>
        </SoonaButton>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables';

.speedy-checkout-banner {
  border: 0.0625rem solid variables.$gray-30;
  border-radius: 0.75rem;
  container: speedy-checkout-banner / inline-size;
  margin-bottom: 1rem;
  overflow: hidden;

  &__inner {
    align-items: stretch;
    background: variables.$gradient-pink-periwink;
    display: flex;
    flex-direction: column;

    @container speedy-checkout-banner (min-width: 40rem) {
      flex-direction: row;
    }
  }

  &__left {
    display: none;

    @container speedy-checkout-banner (min-width: 55rem) {
      align-items: center;
      background-color: variables.$white-default;
      display: flex;
      justify-content: center;
      padding: 1.5rem 1.75rem;
      width: max-content;
      flex-shrink: 0;

      img {
        width: 4.6875rem;
      }
    }
  }

  &__center {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: space-between;
    padding: 1.75rem;
    padding-bottom: 0;
    padding-right: 1.5rem;

    @container speedy-checkout-banner (min-width: 40rem) {
      padding-bottom: 1rem;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    strong {
      font-variant-numeric: tabular-nums;
    }
  }

  &__right {
    align-items: flex-end;
    display: flex;
    padding: 1rem 1.5rem;

    :deep(.soona-button) {
      width: max-content;
    }
  }
}
</style>
