<script setup>
import { computed } from 'vue';
import SoonaItemCard from '@/components/ui_library/SoonaItemCard.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaDonutChart from '@/components/ui_library/SoonaDonutChart.vue';
import { useGetIntegrations } from '@/queries/useGetIntegrations';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import PlaceholderImage from 'images/product-placeholder-periwink.jpg';
import ProductDisconnectedListingCard from './ProductDisconnectedListingCard.vue';

const props = defineProps({
  accountId: {
    type: String,
    required: true,
  },
  listing: {
    type: Object,
    required: true,
  },
});

const accountId = computed(() => props.accountId);
const listing = computed(() => props.listing);

const listingData = computed(() => {
  return [
    { label: listing.value.category, value: listing.value.percent_value },
  ];
});

const badgeIconName = computed(() => {
  if (listing.value.integration_type === 'AmazonIntegration') {
    return 'amazon-logo';
  }
  if (listing.value.integration_type === 'ShopifyIntegration') {
    return 'shopify-logo';
  }
  return null;
});

const {
  data: integrationsData,
  isSuccess: integrationsLoaded,
  error,
} = useGetIntegrations(accountId);

const integrations = computed(() => integrationsData.value);

const currentIntegrationId = computed(() => {
  return integrations.value?.find(
    integration => integration.id == listing.value.integration_id
  )?.id;
});

const isDisconnected = computed(() => {
  return !currentIntegrationId.value;
});
</script>

<template>
  <template v-if="integrationsLoaded">
    <ProductDisconnectedListingCard
      v-if="isDisconnected"
      :account-id="accountId"
      :listing="listing"
    />
    <SoonaItemCard
      v-else
      :aria-labelledby="`connected-listing-${listing.id}-heading`"
      :image-url="listing.main_image_url ?? PlaceholderImage"
      class="connected-listing-card"
    >
      <template #image-badge>
        <SoonaIcon
          v-if="badgeIconName"
          :name="badgeIconName"
          class="connected-listing-card__badge"
          size="large"
        />
      </template>
      <template #content>
        <div class="connected-listing-card__content">
          <div class="connected-listing-card__content--left">
            <router-link
              :id="`connected-listing-${listing.id}-heading`"
              :to="{
                name: 'listing-optimize',
                params: {
                  accountId: accountId,
                  integrationId: currentIntegrationId,
                  externalProductId: listing.external_product_id,
                },
              }"
              class="connected-listing-card__name u-body--heavy connected-listing-card__clickable-area"
            >
              {{ listing.name }}
            </router-link>
            <div class="connected-listing-card__photo-count u-label--regular">
              <SoonaIcon
                v-if="listing.image_count < 6"
                name="status-dot-small"
                size="small"
                class="connected-listing-card__photo-count-icon"
              />
              {{ listing.image_count }} photo{{
                listing.image_count > 1 || listing.image_count === 0 ? 's' : ''
              }}
              <span v-if="listing.integration_type === 'ShopifyIntegration'">
                · {{ listing.video_count }} video{{
                  listing.video_count > 1 || listing.video_count === 0
                    ? 's'
                    : ''
                }}
              </span>
            </div>
          </div>

          <div class="connected-listing-card__score">
            <SoonaDonutChart
              accessible-title="listing score"
              :data="listingData"
              :display-value="listing.display_value"
              size="small"
              label-position="none"
            />
          </div>
        </div>
      </template>
    </SoonaItemCard>
  </template>
  <SoonaError v-if="error">{{ error.message }}</SoonaError>
</template>

<style lang="scss" scoped>
@use '@/variables';

.connected-listing-card {
  position: relative;
  width: 100%;

  &:hover {
    background-color: variables.$gray-10;
  }

  &__badge {
    background-color: variables.$white-default;
    border-radius: 50%;
    padding: 0.125rem;
  }

  &__content {
    padding: 0.75rem 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &--left {
      flex: 3;
    }
  }

  &__name {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &__clickable-area {
    &::before {
      content: '';
      position: absolute;
      inset: 0;
      z-index: 1;
    }
  }

  &__photo-count {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-wrap: nowrap;
    gap: 0.25rem;
    color: variables.$gray-60;

    &-icon {
      color: variables.$friendly-red-50;
    }
  }

  &__score {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    flex: 1;
  }
}
</style>
