<script setup>
import { computed, ref } from 'vue';
import SummaryPayment from '@/components/SummaryPayment.vue';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';
import SubscriptionPromoBanner from '@/components/shared/SubscriptionPromoBanner.vue';

import {
  collapseSection,
  expandSection,
} from 'src/composables/useDrawerToggle';
import { useMediaQuery } from '@vueuse/core';

const props = defineProps({
  cartTotals: {
    type: Object,
    required: false,
  },
  cartSummary: {
    type: Object,
    required: false,
  },
  canViewTrialOptInBanner: {
    type: Boolean,
    default: false,
  },
  preferredCredits: {
    type: Number,
    default: 0,
  },
});

const emit = defineEmits(['update:activePromo']);

const matchMediaIsWide = useMediaQuery('(min-width: 48rem)');

const assetsTotal = computed(
  () =>
    props.cartSummary?.photo_total +
    props.cartSummary?.video_total +
    props.cartSummary?.gif_total
);

const totals = computed(() => props.cartTotals);
const assetsBreakdownOpen = ref(false);

const toCurrency = (value, currency = 'USD') => {
  return value?.toLocaleString('en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
  });
};
</script>

<template>
  <SummaryPayment
    :total="totals?.total"
    :show-preferred-ad="!canViewTrialOptInBanner"
    :cart-totals="cartSummary"
    :preferred-credits="preferredCredits"
  >
    <tbody class="summary-payment__itemized-body">
      <tr>
        <th scope="row">
          <div>
            <button
              id="assets-title"
              type="button"
              class="u-button-reset summary-payment__asset-toggle"
              aria-controls="itemized-assets"
              aria-label="toggle itemized assets"
              :aria-expanded="assetsBreakdownOpen ? 'true' : 'false'"
              @click="
                assetsBreakdownOpen
                  ? collapseSection($refs.itemizedAssets, () => {
                      assetsBreakdownOpen = false;
                    })
                  : ((assetsBreakdownOpen = true),
                    expandSection($refs.itemizedAssets))
              "
            >
              {{ cartSummary.asset_count }} asset{{
                cartSummary.asset_count === 1 ? '' : 's'
              }}
              <SoonaIcon
                :name="assetsBreakdownOpen ? 'chevron-up' : 'chevron-down'"
                :size="matchMediaIsWide ? 'large' : 'medium'"
              />
            </button>
            <ul
              id="itemized-assets"
              ref="itemizedAssets"
              class="u-drawer summary-payment__asset-breakdown"
              :class="{ 'u-drawer--open': assetsBreakdownOpen }"
              aria-title="itemized assets"
              aria-labelledby="assets-title"
            >
              <li v-if="cartSummary.photo_count">
                photos ({{ cartSummary.photo_count }}):
                <strong>{{ toCurrency(cartSummary.photo_total) }}</strong>
              </li>
              <li v-if="cartSummary.gif_count">
                gifs ({{ cartSummary.gif_count }}):
                <strong>{{ toCurrency(cartSummary.gif_total) }}</strong>
              </li>
              <li v-if="cartSummary.video_count">
                video clips ({{ cartSummary.video_count }}):
                <strong>{{ toCurrency(cartSummary.video_total) }}</strong>
              </li>
            </ul>
          </div>
        </th>
        <td>{{ toCurrency(assetsTotal) }}</td>
      </tr>
      <tr v-if="cartSummary.premium_edits_count">
        <th scope="row">
          {{ cartSummary.premium_edits_count }} premium edit{{
            cartSummary.premium_edits_count === 1 ? '' : 's'
          }}
        </th>
        <td data-cypress="summary-payment-edit-total">
          {{ toCurrency(cartSummary.premium_edits_total) }}
        </td>
      </tr>
      <tr class="summary-payment__itemized-subtotal">
        <th scope="row">subtotal</th>
        <td data-cypress="summary-payment-subtotal">
          {{ toCurrency(totals?.subTotal) }}
        </td>
      </tr>
      <tr v-if="totals?.discountTotal > 0">
        <th scope="row">credits & discounts</th>
        <td data-cypress="summary-payment-discount-total">
          - {{ toCurrency(totals?.discountTotal) }}
        </td>
      </tr>
    </tbody>
    <template v-if="canViewTrialOptInBanner" #opt_in_banner>
      <SubscriptionPromoBanner
        @update:active-promo="value => emit('update:activePromo', value)"
      />
    </template>
    <template #payment>
      <slot name="payment"></slot>
    </template>
  </SummaryPayment>
</template>

<style scoped lang="scss">
@use '@/variables';
@use '@/variables_fonts';

.summary-payment {
  &__asset-toggle {
    @include variables_fonts.u-label--regular;

    display: inline-flex;
    align-items: center;
    gap: 0.5rem;

    svg {
      color: variables.$gray-60;
      transition: color 0.1s ease-out;
    }

    &:hover,
    &:focus-visible {
      svg {
        color: variables.$gray-80;
      }
    }
  }

  &__asset-breakdown {
    @include variables_fonts.u-label--regular;

    display: block;
    list-style: none;
    color: variables.$gray-60;

    li {
      display: flex;
      gap: 0.5rem;
      padding-left: 1.25rem;

      &:first-child {
        padding-top: 0.5rem;
      }

      &:not(:first-child) {
        margin-top: 0.25rem;
      }

      strong {
        font-weight: 400;
      }
    }
  }
  @media (min-width: variables.$screen-sm-min) {
    &__asset-toggle {
      @include variables_fonts.u-body--regular;
    }
  }
}
</style>
