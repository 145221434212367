<script setup>
import SoonaLink from '@/components/ui_library/SoonaLink.vue';
import { computed } from 'vue';
import { useAccountLinkDisplayLogic } from '@/components/user/anytime/dashboard/useAccountLinkDisplayLogic';

const props = defineProps({
  account: {
    type: Object,
    required: true,
  },
  hidePii: {
    type: Boolean,
    default: false,
  },
});

const {
  viewUserLinks,
  viewCrewLinks,
  showGalleryLink,
  showAssignmentsLink,
  showCrewTools,
  showProServiceProfile,
  showAvailability,
  showInventory,
} = useAccountLinkDisplayLogic(computed(() => props.account));
</script>

<template>
  <nav :aria-label="account.name">
    <ul class="account-info-links-list">
      <template v-if="viewUserLinks">
        <li>
          <SoonaLink
            :to="{ path: `/account/${account.id}/bookings` }"
            size="medium"
            variation="black"
            data-cypress="account-link-bookings"
          >
            bookings
          </SoonaLink>
        </li>
        <li>
          <SoonaLink
            :to="{ path: `/account/${account.id}/products` }"
            size="medium"
            variation="black"
          >
            products
          </SoonaLink>
        </li>
        <li>
          <SoonaLink
            :to="`/account/${account.id}/listing-insights`"
            size="medium"
            variation="black"
          >
            listing insights
          </SoonaLink>
        </li>
      </template>
      <li v-if="!hidePii">
        <SoonaLink
          :to="{ path: `/account/${account.id}/profile` }"
          size="medium"
          variation="black"
        >
          account profile
        </SoonaLink>
      </li>
      <li v-if="showGalleryLink">
        <SoonaLink
          :to="`/account/${account.id}/gallery`"
          size="medium"
          variation="black"
        >
          gallery
        </SoonaLink>
      </li>
      <template v-if="viewCrewLinks">
        <li v-if="showAssignmentsLink">
          <SoonaLink
            :to="`/account/${account.id}/assignments`"
            size="medium"
            data-cypress="account-link-assignments"
          >
            assignments
          </SoonaLink>
        </li>
        <li v-if="showCrewTools">
          <SoonaLink
            :to="`/account/${account.id}/crew-tools`"
            size="medium"
            data-cypress="account-link-crew-tools"
          >
            crew tools
          </SoonaLink>
        </li>
        <li v-if="showAvailability">
          <SoonaLink :to="`/account/${account.id}/availability`" size="medium">
            availability
          </SoonaLink>
        </li>
        <li v-if="showInventory">
          <SoonaLink :to="`/account/${account.id}/inventory`" size="medium">
            inventory
          </SoonaLink>
        </li>
        <li v-if="showProServiceProfile">
          <SoonaLink
            :to="`/account/${account.id}/pro-service-profile`"
            size="medium"
          >
            pro service profile
          </SoonaLink>
        </li>
      </template>
    </ul>
  </nav>
</template>

<style lang="scss" scoped>
.account-info-links-list {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
}
</style>
