<script setup>
import { computed, ref, watchEffect } from 'vue';
import { convertToHHMM12, convertToMMDDYY } from '@/lib/date-formatters';
import { useCapability } from '@/composables/useCapability';
import ComparisonItem from './ComparisonItem.vue';

const props = defineProps({
  addOn: {
    required: true,
    type: Object,
  },
});

const digitalAsset = computed(() => props.addOn?.digital_asset);

const { hasCapability: isSoonaStaff } = useCapability({
  capability: 'soona_staff',
});

const left = ref(null);
const right = ref(null);

const formatOption = da => {
  let editStatus = 'unknown';
  switch (da.edit_status) {
    case 'original':
      editStatus = 'studio original';
      break;
    case 'accepted':
      editStatus = 'pro edit';
      break;
    case 'pending':
      editStatus = 'pending';
      break;
    case 'rejected':
      editStatus = 'hidden';
      break;
  }
  return {
    label: `${editStatus} · ${convertToMMDDYY(da.created_at)} ${convertToHHMM12(da.created_at)}`,
    value: da,
  };
};

const choices = computed(() => {
  if (!digitalAsset.value) return [];

  const opts = [formatOption(digitalAsset.value)];
  digitalAsset.value.derivatives.forEach(derivative =>
    opts.push(formatOption(derivative))
  );
  return opts;
});

const pendingCount = computed(
  () =>
    digitalAsset.value?.derivatives.filter(d => d.edit_status === 'pending')
      .length
);

const rejectedCount = computed(
  () =>
    digitalAsset.value?.derivatives.filter(d => d.edit_status === 'rejected')
      .length
);

const firstPending = choices => {
  return choices.value.find(opt => opt.value?.edit_status === 'pending')?.value;
};

watchEffect(() => {
  const existingLeft = choices.value.find(c => c.value.id === left.value?.id);
  const existingRight = choices.value.find(c => c.value.id === right.value?.id);

  left.value = existingLeft?.value ?? choices.value.at(0)?.value;

  if (choices.value.length > 1) {
    right.value =
      existingRight?.value ??
      firstPending(choices) ??
      choices.value.at(-1)?.value;
  } else {
    right.value = null;
  }
});
</script>

<template>
  <div v-if="isSoonaStaff && pendingCount">
    {{ pendingCount }} pending edit{{ pendingCount > 1 ? 's' : '' }} will be
    delivered when the order is completed
  </div>
  <div v-if="isSoonaStaff && rejectedCount">
    {{ rejectedCount }} rejected edit{{ rejectedCount > 1 ? 's' : '' }} will not
    be delivered
  </div>
  <div class="comparison-tool">
    <ComparisonItem v-model:asset="left" :add-on="addOn" :options="choices" />
    <ComparisonItem v-model:asset="right" :add-on="addOn" :options="choices" />
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.comparison-tool {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;

  @media (min-width: variables.$screen-xs-min) {
    flex-wrap: nowrap;
  }
}
</style>
