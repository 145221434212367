<script setup>
import { computed, ref } from 'vue';
import QuantityIncrementor from 'src/components/booking/QuantityIncrementor.vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useFlag } from '@/composables/useFlag';
import PackReviews from './PackReviews.vue';

const props = defineProps({
  pack: {
    type: Object,
    required: true,
  },
  onBack: {
    type: Function,
    required: false,
  },
  isPreview: {
    type: Boolean,
    required: false,
    default: false,
  },
  packQuantity: {
    type: Number,
    required: false,
    default: 1,
  },
});

const emit = defineEmits(['onIncrement', 'onDecrement']);

const packReviewFlag = useFlag('pack_review');

const previewPackQuantity = ref(1);

const quantity = computed(() =>
  props.isPreview ? previewPackQuantity.value : props.packQuantity
);

const onIncrement = () => {
  if (props.isPreview) {
    if (previewPackQuantity.value < props.pack.max_quantity) {
      return previewPackQuantity.value++;
    }
    return;
  }
  emit('onIncrement');
};

const onDecrement = () => {
  if (props.isPreview) {
    if (previewPackQuantity.value > 1) {
      return previewPackQuantity.value--;
    }
    return;
  }
  emit('onDecrement');
};

const { linkClicked } = useBaseEvents();

function handleSeeAllClick() {
  linkClicked({
    context: 'booking',
    subContext: 'pack details',
    linkLabel: 'see all packs',
    linkHref: '/booking/packs',
  });

  props.onBack('packs');
}

const videoOrStopMotion = computed(() => {
  return props.pack.category === 'video' || props.pack.category === 'animation';
});

const price = computed(() => props.pack.pack_price ?? '$');

const description = computed(
  () => props.pack.description?.body ?? ['[description paragraph]']
);

const includes = computed(
  () => props.pack.description?.includes ?? ['[includes]']
);
</script>

<template>
  <div class="pack-details__content">
    <a v-if="isPreview" to="/">see all packs</a>
    <router-link v-else to="./" @click.prevent="handleSeeAllClick">
      see all packs
    </router-link>
    <header class="pack-details__header">
      <h1 v-if="pack.name" class="pack-details__title">
        {{ pack.name }}
      </h1>
      <strong class="pack-details__price" aria-title="price">
        {{ price }}
      </strong>
    </header>
    <p
      v-for="(paragraph, index) in description"
      :key="index"
      class="pack-details__description"
    >
      {{ paragraph }}
    </p>
    <h2 class="pack-details__subtitle">Shoot Details</h2>
    <ul class="pack-details__details">
      <li v-for="(detail, index) in includes" :key="index">
        {{ detail }}
      </li>
    </ul>
    <template v-if="pack.max_quantity > 1">
      <p class="pack-details__text">
        have more than 1 product? add 1 pack per product:
      </p>
      <div class="pack-details__qty">
        <QuantityIncrementor
          :value="quantity"
          :max="pack.max_quantity"
          :min="1"
          @on-increment="onIncrement"
          @on-decrement="onDecrement"
        />
      </div>
    </template>
    <p v-if="!videoOrStopMotion" class="pack-details__text">
      includes access to all
      <a v-if="isPreview" to="/">in-studio props</a>
      <a
        v-else
        href="https://soona.co/creative/props"
        target="_blank"
        rel="noreferrer noopener"
        @click="
          linkClicked({
            context: 'booking',
            subContext: 'pack details',
            linkLabel: 'in-studio props',
            linkHref: 'https://soona.co/creative/props',
          })
        "
      >
        in-studio props</a
      >. clients may send additional props.
    </p>
    <PackReviews
      v-if="packReviewFlag && !!pack.pack_reviews"
      :items="pack.pack_reviews"
    />
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

a {
  text-decoration: underline;
  text-decoration-color: currentcolor;

  &:hover,
  &:focus-visible {
    text-decoration: none;
  }
}
.pack-details {
  &__header {
    margin: 1rem 0;
  }

  &__title {
    @include variables_fonts.u-headline--heavy;

    margin-bottom: 0.5rem;
    color: variables.$black-default;
  }

  &__price {
    @include variables_fonts.u-headline--regular;

    display: block;
    margin-bottom: 0.25rem;
    color: variables.$black-default;
  }

  &__description {
    color: variables.$black-default;
    margin-bottom: 1rem;
  }

  &__text {
    display: block;
    color: variables.$gray-60;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    a {
      color: variables.$gray-60;
    }
  }

  &__qty {
    margin-bottom: 1.5rem;
  }

  &__subtitle {
    @include variables_fonts.u-button--large-caps;

    color: variables.$black-default;
    margin-bottom: 0.25rem;
  }

  &__details {
    display: block;
    margin-bottom: 1rem;
    list-style: disc outside;

    li {
      color: variables.$black-default;
      margin-left: 1em;
    }
  }

  @media (min-width: variables.$screen-sm-min) {
    &__content {
      order: 1;
      flex: 1 1 56%;
    }

    &__header {
      margin: 1.5rem 0;
    }

    &__description {
      margin-bottom: 1.5rem;
    }

    &__details {
      margin-bottom: 1.5rem;
    }

    &__photos {
      margin-top: 0;
      flex: 1 1 44%;
      max-width: 25rem;
    }
  }

  @media (min-width: 864px) {
    &__title {
      @include variables_fonts.u-display--heavy;
    }

    &__details {
      columns: 2 10.9375rem;

      li {
        break-inside: avoid-column;
      }
    }
  }
}
</style>
