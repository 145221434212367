/**
 *
 * @param {import('axios').AxiosResponseHeaders} headers
 * @returns {{itemsPerPage: number, totalPages: number, totalCount: number, currentPage: number}}
 */
export function mapPaginationHeaders(headers) {
  return {
    totalCount: Number(headers['total-count']),
    currentPage: Number(headers['current-page']),
    totalPages: Number(headers['total-pages']),
    itemsPerPage: Number(headers['page-items']),
  };
}

/**
 *
 * @param {import('axios').AxiosResponseHeaders} headers
 * @returns {{itemsPerPage: number, totalPages: number, totalCount: number, bagCount: number, favoritesCount: number, staffPicksCount: number, currentPage: number}}
 */
export function mapReservationPaginationHeaders(headers) {
  return {
    ...mapPaginationHeaders(headers),
    bagCount: Number(headers['bag-count']),
    favoritesCount: Number(headers['favorites-count']),
    staffPicksCount: Number(headers['staff-picks-count']),
  };
}
