<script setup>
import { toRefs } from 'vue';
import { useCreateNote } from '@/queries/notes/useCreateNote';
import { usePriorityErrors } from '@/composables/usePriorityErrors';
import NoteComposer from './NoteComposer.vue';

const props = defineProps({
  subjectType: {
    type: String,
    required: true,
  },
  subjectId: {
    type: [String, Number],
    required: true,
  },
});

const { subjectType, subjectId } = toRefs(props);

const {
  mutate: createNote,
  isPending: isCreatingNote,
  error: createNoteError,
} = useCreateNote(subjectType, subjectId);

const priorityErrors = usePriorityErrors(createNoteError);

function submitNote(e) {
  const form = e.target;
  const data = new FormData(form);
  const comment = data.get('comment')?.trim();

  if (!comment) {
    return;
  }

  createNote(
    {
      content: comment,
    },
    {
      onSuccess: () => {
        form?.reset();
      },
    }
  );
}
</script>

<template>
  <NoteComposer
    :is-loading="isCreatingNote"
    :priority-errors="priorityErrors"
    @submit="submitNote"
  />
</template>
