<script setup>
import { computed } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaActionBar from '@/components/ui_library/SoonaActionBar.vue';

const props = defineProps({
  buttonCopy: {
    type: String,
    required: true,
  },
  buttonIcon: {
    type: String,
    required: true,
  },
  error: {
    type: String,
    default: null,
  },
  isUpdating: {
    type: Boolean,
    required: true,
  },
  selectedProducts: {
    type: Array,
    required: true,
  },
  pageBoundingRectLeft: {
    type: Number,
    required: true,
  },
  pageBoundingRectWidth: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['cancelMultiSelect', 'actionSelected']);

const numberOfProductsSelected = computed(() => props.selectedProducts.length);

const cancel = () => {
  emit('cancelMultiSelect');
};

const actionSelected = () => {
  emit('actionSelected');
};
</script>

<template>
  <SoonaActionBar
    :display="numberOfProductsSelected > 0"
    :page-bounding-rect-left="pageBoundingRectLeft"
    :page-bounding-rect-width="pageBoundingRectWidth"
  >
    <template #content>
      <p v-if="!error" class="multiselect-action-bar__text u-label--heavy">
        {{ numberOfProductsSelected }} product{{
          numberOfProductsSelected > 1 ? 's' : ''
        }}
        selected
      </p>
      <SoonaError v-if="error" class="multiselect-action-bar__error">
        {{ error }}
      </SoonaError>
    </template>
    <template #actions>
      <SoonaButton
        copy="cancel"
        size="medium"
        variation="secondary-transparent"
        @on-click="cancel"
      />
      <SoonaButton
        v-if="!error"
        :copy="buttonCopy"
        size="medium"
        variation="secondary-gray"
        :disabled="isUpdating"
        @on-click="actionSelected"
      >
        <template #icon-left>
          <SoonaIcon :name="buttonIcon" size="small" />
        </template>
      </SoonaButton>
    </template>
  </SoonaActionBar>
</template>
