<script setup>
import { computed, ref, watchEffect } from 'vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useGetQuest } from '@/queries/quest/useGetQuest';
import { useRoute } from 'vue-router';
import QuestButton from '@/components/quest/QuestButton.vue';
import QuestFlyout from '@/components/quest/QuestFlyout.vue';
import { useUpdateQuest } from '@/queries/quest/useUpdateQuest';

const props = defineProps({
  questId: {
    required: true,
    type: Number,
  },
});

const { linkClicked } = useBaseEvents();
const route = useRoute();
const context = computed(() => route.meta.context);

const isExpanded = ref(false);
const showQuest = ref(null);

const { data: quest } = useGetQuest(computed(() => props.questId));
const questTasks = computed(() => quest.value?.quest_tasks ?? []);
const questTitle = computed(() => quest.value?.title);
const questDescription = computed(() => quest.value?.description);
const questViewed = computed(() => quest.value?.viewed);
const percentComplete = computed(() => quest.value?.percent_complete);

// on quest completion, keeps the quest elements on-screen until the user dismisses them
// does not render the quest elements if the quest is complete on page load
watchEffect(() => {
  if (showQuest.value === null && percentComplete.value < 100) {
    showQuest.value = true;
  } else if (showQuest.value === null && percentComplete.value === 100) {
    showQuest.value = false;
  }

  if (percentComplete.value === 100) isExpanded.value = true;
});

const hideQuestIfComplete = () => {
  if (percentComplete.value === 100) showQuest.value = false;
};

const handleCloseClick = () => {
  isExpanded.value = false;
  hideQuestIfComplete();

  linkClicked({
    context: context.value,
    subContext: 'onboarding quest',
    linkLabel: 'close tour',
    linkHref: null,
  });
};

const { mutate: updateQuestMutation } = useUpdateQuest(
  computed(() => props.questId)
);

const handleOpenClick = () => {
  isExpanded.value = true;

  if (!questViewed.value) {
    updateQuestMutation({ viewed: true });
  }

  linkClicked({
    context: context.value,
    subContext: 'onboarding quest',
    linkLabel: quest.value?.title,
    linkHref: null,
  });
};
</script>

<template>
  <aside v-if="questId && showQuest">
    <QuestButton
      v-if="questTitle"
      controls="onboarding-tasks"
      :has-opened="questViewed"
      :is-expanded="isExpanded"
      :percent-complete="percentComplete"
      :title="questTitle"
      @open="handleOpenClick"
    />
    <QuestFlyout
      v-if="isExpanded"
      dialog-id="onboarding-tasks"
      :is-expanded="isExpanded"
      :percent-complete="percentComplete"
      :quest-description="questDescription"
      :quest-tasks="questTasks"
      :quest-title="questTitle"
      @close="handleCloseClick"
    />
  </aside>
</template>
