import { unref } from 'vue';
import { useStore } from 'vuex';
import { useMutation } from '@tanstack/vue-query';
import { http } from '@/config/vue-axios';

/**
 *
 * @param {number | Ref<Object>} order
 */
export function useBulkPendReservationFiles(order) {
  const store = useStore();

  return useMutation({
    mutationFn: async reservationFileIds => {
      const response = await http.post(
        `/orders/${unref(order).id}/reservation_files/bulk_pend.json`,
        {
          reservation_file_ids: reservationFileIds,
        }
      );

      return response.data;
    },
    onSuccess: async () => {
      await store.dispatch(
        'reservation/loadOrders',
        unref(order).reservation_id
      );
    },
  });
}
