<script setup>
import { computed } from 'vue';
import ListingInsightsBanner from './ListingInsightsBanner.vue';
import AIStudioBanner from './AIStudioBanner.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaMediumImageBanner from '@/components/ui_library/SoonaMediumImageBanner.vue';
import { useRoute } from 'vue-router';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { AvoToast20 } from 'src/variables.module.scss';
import { useFlag } from '@/composables/useFlag';

const props = defineProps({
  accountId: { type: [Number, String], required: true },
});

const accountId = computed(() => props.accountId);

const route = useRoute();
const { linkClicked } = useBaseEvents();

function openLink(linkLabel, linkHref, linkLocation) {
  linkClicked({
    context: route.meta.context,
    subContext: linkLocation,
    linkLabel: linkLabel,
    linkHref: linkHref,
  });
}

const apolloAIStudioFlag = useFlag('apollo_ai_studio_banner');
</script>
<template>
  <div class="shortcuts">
    <AIStudioBanner v-if="apolloAIStudioFlag" :account-id="accountId" />
    <ListingInsightsBanner v-else :account-id="accountId" />

    <SoonaMediumImageBanner :background-color="AvoToast20">
      <template #title>plan with the pros</template>
      <template #content>
        get an expert recommendation about scaling your content & UGC.
      </template>
      <template #image>
        <img src="@images/plan_with_the_pros@2x.jpg" alt="" />
        <span class="badge">free</span>
        <span class="description">creative lead</span>
      </template>
      <template #button>
        <SoonaButton
          variation="secondary-black"
          element="a"
          target="_blank"
          href="https://soona.co/contact-sales"
          @click="
            openLink(
              'chat with us',
              'https://soona.co/contact-sales',
              'plan with the pros'
            )
          "
        >
          chat with us
        </SoonaButton>
      </template>
    </SoonaMediumImageBanner>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.shortcuts {
  display: flex;
  gap: 1.5rem;
  flex-wrap: wrap;

  & > * {
    flex: 1 1 20rem;
  }
}

.badge,
.description {
  position: absolute;
  text-transform: uppercase;
  color: variables.$white-default;
}

.badge {
  @include variables_fonts.u-badge--big;

  top: 0.5rem;
  right: -1.25rem;
  transform: rotateZ(41deg);
  background: green;
  padding: 0.25rem 1.75rem;
}

.description {
  @include variables_fonts.u-badge--small;

  bottom: 0;
  right: 0;
  padding: 0.75rem 1rem;
}
</style>
