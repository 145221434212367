<script setup>
import { computed, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import FloppyDiskIcon from 'src/components/svgs/FloppyDiskIcon.vue';
import { useUpdateReservation } from 'src/queries/useUpdateReservation';
import { useBaseEvents } from 'src/composables/segment/useBaseEvents';

const props = defineProps({
  reservation: {
    type: Object,
    required: true,
  },
  isAuthenticated: {
    default: false,
    type: Boolean,
  },
});

const emit = defineEmits(['toggleAlert']);

const route = useRoute();
const router = useRouter();
const { linkClicked } = useBaseEvents();

const { mutate: updateReservation } = useUpdateReservation(
  computed(() => props.reservation.id)
);

const saveDraft = () => {
  if (!props.isAuthenticated) {
    const redirect = `/booking/${props.reservation.id}/${route.meta.validate_step}`;
    linkClicked({
      context: route.meta.context,
      subContext: route.meta.page_title,
      linkLabel: 'save draft',
      linkHref: redirect,
    });

    return router.push({
      name: 'signUpPartTwo',
      query: { redirect, saveDraft: true },
    });
  } else {
    linkClicked({
      context: route.meta.context,
      subContext: route.meta.page_title,
      linkLabel: 'save draft',
      linkHref: '#saveDraft',
    });
  }

  updateReservation(
    { draft_type: 'client_draft' },
    {
      onSuccess: () => {
        emit('toggleAlert');
      },
    }
  );
};

watchEffect(() => {
  if (
    props.reservation.account_id &&
    props.isAuthenticated &&
    route.query.saveDraft
  ) {
    const newQueryParams = { ...route.query };
    delete newQueryParams.saveDraft;
    router.replace({ query: newQueryParams });
    saveDraft();
  }
});
</script>
<template>
  <div class="booking-save-draft">
    <SoonaButton
      copy="save draft"
      variation="secondary-black"
      :on-click="saveDraft"
    >
      <template #icon-left> <FloppyDiskIcon /> </template
    ></SoonaButton>
  </div>
</template>
<style lang="scss" scoped>
.booking-save-draft {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 1rem;
}
</style>
