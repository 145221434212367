<script setup>
import { computed, ref } from 'vue';
import {
  isAmazonIntegration,
  isActiveShopifyIntegration,
} from 'src/components/user/anytime/products/integration-utils.js';
import SoonaDropdownMenu from 'src/components/ui_library/SoonaDropdownMenu.vue';
import SoonaDropdownMenuItem from 'src/components/ui_library/SoonaDropdownMenuItem.vue';
import AmazonPublishDialog from '@/components/user/anytime/products/AmazonPublishDialog.vue';
import ShopifyPublishDialog from '@/components/user/anytime/products/shopify/ShopifyPublishDialog.vue';
import {
  amazonAuthorizationRedirectUrl,
  shopifyAppUrl,
} from '@/lib/integrations/constants';
import { useGetIntegrations } from '@/queries/useGetIntegrations';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useRoute } from 'vue-router';
import ReconnectAmazonDialog from '@/components/user/anytime/products/ReconnectAmazonDialog.vue';

const props = defineProps({
  file: {
    type: Object,
    required: true,
  },
  size: {
    default: 'medium',
    type: String,
    validator: function (value) {
      return ['large', 'medium'].includes(value);
    },
  },
});

const emit = defineEmits(['asset-published']);
const file = computed(() => props.file);
const fileId = computed(() => file.value?.id);

const showAmazonPublishDialog = ref(false);
const showReconnectAmazonDialog = ref(false);
const showShopifyPublishDialog = ref(false);

const { linkClicked } = useBaseEvents();

const route = useRoute();

const accountId = computed(() => file.value?.account_id);

const size = computed(() => props.size);

const amazonAuthRedirectUrl = computed(() => {
  // todo not sure if this is how soona_redirect_to works
  if (file.value?.subject_type === 'reservation_file') {
    return (
      amazonAuthorizationRedirectUrl + `?reservation_file_id=${fileId.value}`
    );
  }
  return amazonAuthorizationRedirectUrl + `?soona_redirect_to=${route.path}`;
});

const { data: integrationsData } = useGetIntegrations(accountId);

const integrations = computed(() => integrationsData.value ?? []);

const hasAmazonIntegration = computed(() =>
  integrations.value.some(isAmazonIntegration)
);

const amazonIntegration = computed(() =>
  integrations.value.find(isAmazonIntegration)
);

const hasShopifyIntegration = computed(() =>
  integrations.value.some(isActiveShopifyIntegration)
);

const duration = computed(() => file.value.duration);

const videoShopifyCompatible = computed(
  () => duration.value !== null && duration.value < 60
);

const canPublishToShopify = computed(
  () =>
    hasShopifyIntegration.value &&
    (file.value.media_type === 'photo' || videoShopifyCompatible)
);

function publishToShopifyIntegration() {
  let linkHref;
  if (hasShopifyIntegration.value) {
    linkHref = '#shopify-publish-dialog';
    showShopifyPublishDialog.value = true;
  } else {
    linkHref = shopifyAppUrl;
  }
  if (route.meta.context) {
    linkClicked({
      context: route.meta.context,
      subContext: 'media asset action',
      linkLabel: 'publish to Shopify',
      linkHref: linkHref,
    });
  }
}

function publishToAmazon() {
  let linkHref;
  if (hasAmazonIntegration.value) {
    if (amazonIntegration.value?.status === 'invalid_state') {
      linkHref = '#reconnect-amazon-dialog';
      showReconnectAmazonDialog.value = true;
    } else {
      linkHref = '#amazon-publish-dialog';
      showAmazonPublishDialog.value = true;
    }
  } else {
    linkHref = '/api/integrations/amazon/oauth_redirect';
  }
  if (route.meta.context) {
    linkClicked({
      context: route.meta.context,
      subContext: 'media asset action',
      linkLabel: 'publish to Amazon',
      linkHref: linkHref,
    });
  }
}

function assetPublished(payload) {
  showAmazonPublishDialog.value = false;
  showShopifyPublishDialog.value = false;
  emit('asset-published', payload);
}
</script>
<template>
  <div class="integrations-publish-button">
    <SoonaDropdownMenu copy="publish" :size="size" variation="secondary-black">
      <template #default="{ clickCapture, keydown, mouseover }">
        <SoonaDropdownMenuItem>
          <component
            :is="hasAmazonIntegration ? 'button' : 'a'"
            :href="hasAmazonIntegration ? null : amazonAuthRedirectUrl"
            role="menuitem"
            class="publish-menu-item"
            @click="() => publishToAmazon()"
            @click.capture="clickCapture"
            @keydown="keydown"
            @mouseover="mouseover"
          >
            publish to Amazon
          </component>
        </SoonaDropdownMenuItem>
        <SoonaDropdownMenuItem>
          <component
            :is="canPublishToShopify ? 'button' : 'a'"
            :href="canPublishToShopify ? null : shopifyAppUrl"
            role="menuitem"
            class="publish-menu-item"
            @click="() => publishToShopifyIntegration()"
            @click.capture="clickCapture"
            @keydown="keydown"
            @mouseover="mouseover"
          >
            publish to Shopify
          </component>
        </SoonaDropdownMenuItem>
      </template>
    </SoonaDropdownMenu>
    <AmazonPublishDialog
      v-if="showAmazonPublishDialog"
      :account-id="accountId"
      :file="file"
      @close="showAmazonPublishDialog = false"
      @asset-published="assetPublished"
    />
    <ShopifyPublishDialog
      v-if="showShopifyPublishDialog"
      :account-id="accountId"
      :file="file"
      @close="showShopifyPublishDialog = false"
      @asset-published="assetPublished"
    />
    <ReconnectAmazonDialog
      v-if="showReconnectAmazonDialog"
      @close="showReconnectAmazonDialog = false"
    />
  </div>
</template>
<style lang="scss" scoped>
.integrations-publish-button {
  .publish-menu-item {
    text-transform: none;
  }
}
</style>
