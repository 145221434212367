<script setup>
import { useResetPassword } from '@/composables/useResetPassword';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaForm from 'src/components/ui_library/SoonaForm.vue';
import SoonaTextfield from 'src/components/ui_library/SoonaTextfield.vue';

const { username, errorMessages, resetPassword } = useResetPassword();

function resetPasswordClicked() {
  resetPassword();
}
</script>
<template>
  <div class="forgot-password columns is-centered">
    <div class="column is-6 is-centered">
      <h2 class="has-text-centered u-headline--heavy">forgot password</h2>
      <SoonaForm v-slot="{ hasErrors }" @submit="resetPasswordClicked">
        <div class="columns is-centered">
          <div class="column is-half">
            <SoonaTextfield
              v-model:model-value.trim="username"
              label="email"
              type="email"
              name="email"
              placeholder="email"
              autocomplete="email"
              :rules="['required', 'email']"
              required
            />
            <SoonaError
              v-for="(error, i) in errorMessages"
              :key="i"
              :no-margin="true"
            >
              {{ error.message }}
            </SoonaError>
          </div>
        </div>
        <div class="buttons is-centered">
          <SoonaButton
            type="submit"
            :disabled="hasErrors"
            copy="reset password"
          />
        </div>
        <div class="action-btns">
          <SoonaButton
            element="a"
            copy="create account"
            href="/#/sign-up"
            variation="tertiary"
          />
          <SoonaButton
            element="a"
            copy="sign in"
            href="/#/sign-in"
            variation="tertiary"
          />
        </div>
      </SoonaForm>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.forgot-password {
  padding: 75px 36px 25px;

  .action-btns {
    display: flex;
    gap: 2rem;
    justify-content: center;
    padding-top: 1rem;
    width: 100%;
  }
}
</style>
