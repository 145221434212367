<script setup>
import { ref, computed } from 'vue';
import ProServiceFilter from 'src/components/directory/ProServiceFilter.vue';
import CalendarRangeFilter from 'src/components/directory/CalendarRangeFilter.vue';
import SoonaSelect from 'src/components/ui_library/SoonaSelect.vue';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaTextfield from 'src/components/ui_library/SoonaTextfield.vue';
import SoonaToggle from 'src/components/ui_library/SoonaToggle.vue';
import SoonaHelperText from '@/components/ui_library/SoonaHelperText.vue';
import { useLocations } from 'src/queries/useLocation';
import { useTagCategories } from 'src/queries/useTagCategories';
import { useGetTagFollowUpCategories } from '@/queries/useGetTagFollowUpCategories';
import { useCapability } from 'src/composables/useCapability';
import { useFlag } from 'src/composables/useFlag';

const props = defineProps({
  queryText: {
    type: String,
  },
  filters: {
    type: Array,
  },
  proServiceType: {
    type: String,
  },
  availableOnly: {
    type: Boolean,
  },
  locationIds: {
    type: Array,
  },
  proServiceTypeId: {
    type: Number,
  },
  proServiceCategoryId: {
    type: Number,
  },
  availabilityRangeStart: {
    type: String,
  },
  availabilityRangeEnd: {
    type: String,
  },
});

const emits = defineEmits([
  'update:queryText',
  'update:filters',
  'update:proServiceType',
  'update:locationIds',
  'update:proServiceCategoryId',
  'update:proServiceTypeId',
  'update:availableOnly',
  'update:availabilityRangeStart',
  'update:availabilityRangeEnd',
]);

const { hasCapability: isFtSoonaStaff } = useCapability({
  capability: 'ft_soona_staff',
});

const availabilityFlag = useFlag('apollo_availability_sort');

const queryText = computed(() => props.queryText);
const filters = computed(() => props.filters);
const proServiceType = computed(() => props.proServiceType);
const availableOnly = computed(() => props.availableOnly);
const locationIds = computed(() => props.locationIds);
const proServiceTypeId = computed(() => props.proServiceTypeId);

const proServiceTypeOptions = computed(() => {
  if (isFtSoonaStaff.value) {
    return [
      {
        value: '',
        label: 'all providers',
      },
      {
        value: 'hand model',
        label: '💅 hand model',
      },
      {
        value: 'foot model',
        label: '👟 foot model',
      },
      {
        value: 'full body model',
        label: '💁🏽 full body model',
      },
      {
        value: 'hair and makeup',
        label: '💄 hair and makeup',
      },
      {
        value: 'styling',
        label: '🎨 styling',
      },
      {
        value: 'pet model',
        label: '🐕 pet model',
      },
      {
        value: 'grocery shopping',
        label: '🛒 grocery shopper',
      },
      {
        value: 'mannequin styling',
        label: '🩳 mannequin styling',
      },
      {
        value: 'steaming',
        label: '💨 steaming',
      },
    ];
  }
  return [
    {
      value: '',
      label: 'all providers',
    },
    {
      value: 'hand model',
      label: '💅 hand model',
    },
    {
      value: 'foot model',
      label: '👟 foot model',
    },
    {
      value: 'full body model',
      label: '💁🏽 full body model',
    },
    {
      value: 'pet model',
      label: '🐕 pet model',
    },
  ];
});

const showFilters = ref(true);

let locationTypes = [];
if (!isFtSoonaStaff.value) {
  locationTypes = ['studio', 'remote', 'coming_soon', 'disabled'];
}
const { data: locationsData } = useLocations(locationTypes);
const { data: allProServiceOptions } = useTagCategories({ type: 2 });
const { data: tagFollowUpCategories } = useGetTagFollowUpCategories(
  proServiceTypeId,
  {
    enabled: computed(() => !!proServiceType.value),
  }
);

const locationOptions = computed(() => {
  if (!locationsData.value) {
    return [];
  }
  return locationsData.value.map(({ id, name, display_name }) => ({
    value: id,
    label: isFtSoonaStaff.value ? name : display_name,
  }));
});

const selectedLocation = computed(() => {
  if (locationIds.value.length === 1) {
    return locationsData.value?.find(
      location => location.id === locationIds.value[0]
    );
  } else {
    return null;
  }
});

const proServiceTypes = computed(() =>
  allProServiceOptions.value?.find(
    category => category.title === 'pro services'
  )
);

function getProServiceTypeDetails(proServiceType) {
  return proServiceTypes.value?.tags.find(tag => tag.title === proServiceType);
}

const filteredProServiceCategories = computed(() => {
  return tagFollowUpCategories.value?.filter(
    tc => tc.title !== 'manicure' && tc.title !== 'manicure color'
  );
});

function toggleOpen() {
  showFilters.value = !showFilters.value;
}

function handleProServiceTypeChange(value) {
  if (value !== proServiceType.value) {
    let detail = getProServiceTypeDetails(value);
    const proServiceType = value;
    let proServiceCategoryId = null;
    let proServiceTypeId = null;
    if (detail) {
      proServiceCategoryId = detail.tag_category_id;
      proServiceTypeId = detail.id;
    }
    emits('update:proServiceType', proServiceType);
    emits('update:proServiceCategoryId', proServiceCategoryId);
    emits('update:proServiceTypeId', proServiceTypeId);
  }
}
</script>
<template>
  <div class="sidebar">
    <SoonaTextfield
      v-if="isFtSoonaStaff"
      :model-value="queryText"
      type="search"
      placeholder="search"
      @update:model-value="emits('update:queryText', $event)"
    >
      <template #icon-left>
        <SoonaIcon name="search" size="medium" />
      </template>
    </SoonaTextfield>
    <SoonaSelect
      clearable-btn
      :model-value="proServiceType"
      :options="proServiceTypeOptions"
      @update:model-value="handleProServiceTypeChange($event)"
    >
      <template #label>pro service type</template>
    </SoonaSelect>
    <SoonaSelect
      v-if="locationOptions.length > 0"
      placeholder="all locations"
      clearable-btn
      :multi="true"
      :model-values="locationIds"
      :options="locationOptions"
      @update:model-values="emits('update:locationIds', $event)"
    >
      <template #label>location</template>
    </SoonaSelect>
    <CalendarRangeFilter
      v-if="isFtSoonaStaff && availabilityFlag"
      class="availability-range"
      :range-start="availabilityRangeStart"
      :range-end="availabilityRangeEnd"
      :location="selectedLocation"
      label="availability range"
      @update:range-start="emits('update:availabilityRangeStart', $event)"
      @update:range-end="emits('update:availabilityRangeEnd', $event)"
    />
    <div v-else-if="availabilityFlag" class="availability-toggle">
      <SoonaToggle
        type="checkbox"
        label="available"
        :model-value="availableOnly"
        @update:model-value="emits('update:availableOnly', $event)"
      />
      <SoonaHelperText>
        only show service providers who are available in the next two weeks
      </SoonaHelperText>
    </div>
    <template v-if="filteredProServiceCategories?.length > 0">
      <div class="button-row">
        <SoonaButton
          id="pro-service-filters-trigger"
          :aria-expanded="showFilters"
          :aria-controls="`pro-service-filters-region`"
          variation="secondary-gray"
          size="medium"
          copy="show filters"
          @click="toggleOpen"
        >
          <template #icon-right>
            <SoonaIcon
              :class="['header-chevron', { showFilters }]"
              name="chevron-down"
              size="medium"
            />
          </template>
        </SoonaButton>
      </div>
      <div
        id="pro-service-filters-region"
        role="region"
        class="filter-container"
        aria-labelledby="pro-service-filters-trigger"
        :hidden="!showFilters"
      >
        <ProServiceFilter
          v-for="(traitCategory, index) in filteredProServiceCategories"
          :key="traitCategory.id"
          :model-value="filters"
          :pro-service-trait-category="traitCategory"
          :class="{ last: index === filteredProServiceCategories.length - 1 }"
          @update:model-value="emits('update:filters', $event)"
        />
      </div>
    </template>
  </div>
</template>
<style lang="scss" scoped>
.sidebar {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  height: 100%;
  padding: 1rem;

  .availability-toggle {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .availability-range {
    padding: 0.5rem 0rem 0.5rem 0rem;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }

  .button-row {
    display: flex;
    justify-content: flex-end;

    .header-chevron {
      transition: transform 300ms ease-out;

      &.showFilters {
        transform: rotate(180deg);
      }
    }
  }

  .filter-container {
    margin-top: 1rem;
    &[hidden] {
      display: none;
    }
  }
}
</style>
