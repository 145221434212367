import { unref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { getAccountsOrder } from '@/api/accountsOrders';

/**
 *
 * @param {Ref<string | number>} accountId
 * @param {Object} [params]
 * @param {Ref<Array<string>> | Array<string>} [params.orderStatus]
 * @param {Ref<Array<string>> | Array<string>} [params.orderType]
 * @param {Object} [queryOptions]
 */
export function useGetAccountsOrder(accountId, { orderId } = {}, queryOptions) {
  return useQuery({
    queryKey: queryKeys.accountOrder(accountId, { orderId }),
    queryFn: ({ signal }) =>
      getAccountsOrder(unref(accountId), { orderId: unref(orderId) }, signal),
    ...queryOptions,
  });
}
