import { http } from '../config/vue-axios';

/**
 *
 * @param {Object} [params]
 */
export async function createReservation(params = {}) {
  const response = await http.post(`/reservations`, params);
  return response.data;
}

/**
 *
 * @param {number} reservationId
 * @param {AbortSignal} [signal]
 */
export async function getReservation(reservationId, signal) {
  const response = await http.get(`/reservations/${reservationId}`, { signal });

  return response.data;
}

/**
 *
 * @param {number} reservationId
 * @param {Object} [body]
 */
export async function updateReservation(reservationId, body = {}) {
  const response = await http.put(`/reservations/${reservationId}`, {
    reservation: body,
  });

  return response.data;
}

/**
 *
 * @param {number} reservationId
 * @param {Object} [body]
 * @param {number} [body.locationId]
 * @param {boolean} [body.transferInventory]
 * @param {string} [body.reservationType]

 */
export async function updateReservationLocation(
  reservationId,
  { locationId, transferInventory, reservationType, unscheduleReservation } = {}
) {
  let params = {
    location_id: locationId,
    ...(!!reservationType && { reservation_type: reservationType }),
    ...(!!unscheduleReservation && {
      unschedule_reservation: unscheduleReservation,
    }),
    ...(!!transferInventory && {
      initiate_package_transfer: transferInventory,
    }),
  };

  const response = await http.put(
    `reservations/${reservationId}/update_location.json`,
    params
  );

  return response.data;
}

/**
 *
 * @param {number} reservationId
 * */
export async function updateReservationToAnytime(reservationId) {
  const response = await http.put(
    `reservations/${reservationId}/update_to_anytime.json`
  );

  return response.data;
}

/**
 *
 * @param {number} reservationId
 * @param {AbortSignal} [signal]
 */
export async function getReservationAvailabilityErrors(reservationId, signal) {
  const response = await http.get(
    `/reservations/${reservationId}/check_availability`,
    { signal }
  );

  return response.data;
}

/**
 *
 * @param {number} reservationId
 */
export async function triggerBookingCheckoutStartedEvent(reservationId) {
  const response = await http.get(
    `/reservations/${reservationId}/booking_checkout_started`
  );
  return response.data;
}

/**
 *
 * @param {number} reservationId
 */
export async function assignAccountToReservationAndOrder(reservationId) {
  const response = await http.put(
    `/reservations/${reservationId}/assign_account`
  );
  return response.data;
}

/**
 *
 * @param {number} reservationId
 */
export async function confirmComped(reservationId) {
  const response = await http.post(
    `/reservations/${reservationId}/confirm_comped`
  );

  return response.data;
}

/**
 *
 * @param {number} reservationId
 */
export async function confirmSuggested(reservationId) {
  const response = await http.put(
    `/reservations/${reservationId}/confirm_suggested`
  );

  return response.data;
}

/**
 *
 * @param {number} reservationId
 */
export async function updateStudioAccess(
  reservationId,
  { level, billingCycle } = {}
) {
  const params = {
    studio_access: {
      level: level,
      billing_cycle: billingCycle,
    },
  };

  const response = await http.post(
    `/reservations/${reservationId}/update_studio_access`,
    params
  );

  return response.data;
}

/**
 *
 * @param {number} reservationId
 * @param {string} coupon
 * @param {string} orderId
 */
export async function applyCoupon(reservationId, coupon, orderId) {
  const response = await http.post(
    `/reservations/${reservationId}/apply_coupon`,
    {
      coupon: {
        coupon: coupon.replace('#', '').trim().toLowerCase(),
        order_id: orderId,
      },
    }
  );

  return response.data;
}

/**
 *
 * @param {number} reservationId
 */
export async function removeCoupon(reservationId, orderId) {
  const response = await http.put(
    `/reservations/${reservationId}/remove_coupon`,
    {
      order_id: orderId,
    }
  );

  return response.data;
}

/**
 *
 * @param {number} reservationId
 * @param {string} coupon
 * @param {object} products
 * @param {string} orderType
 * @param {string} currentDiscountId
 */
export async function validateCoupon(
  reservationId,
  coupon,
  products,
  orderType,
  currentDiscountId
) {
  const response = await http.post(
    `/reservations/${reservationId}/validate_coupon`,
    {
      coupon: {
        external_discount_id: coupon?.replace('#', '').trim().toLowerCase(),
        products: products,
        order_type: orderType,
        current_discount_id: currentDiscountId,
      },
    }
  );

  return response.data;
}

/**
 *
 * @param {number} reservationId
 * @param {AbortSignal} [signal]
 */
export async function getBestAutoApplyDiscount(
  reservationId,
  { products, orderType } = {},
  signal
) {
  const response = await http.get(
    `/reservations/${reservationId}/best_auto_apply_discount`,
    {
      params: {
        coupon: {
          products,
          order_type: orderType,
        },
      },
      signal,
    }
  );

  return response.data;
}

/**
 *
 * @param {number} reservationId
 */
export async function updateCustomerShippingStatus(reservationId) {
  const url = `/reservations/${reservationId}/customer_confirmed_packages_sent`;
  const response = await http.put(url);

  return response.data;
}

/**
 *
 * @param {string | number} reservationId
 */
export async function getAvailableDownPaymentCredits(reservationId) {
  const response = await http.get(
    `/reservations/${reservationId}/available_down_payment_credits`
  );

  return {
    photo_count: response.data?.filter(c => c.credit_type === 'photo').length,
    video_count: response.data?.filter(c => c.credit_type === 'video').length,
    gif_count: response.data?.filter(c => c.credit_type === 'animation').length,
    bts_count: response.data?.filter(c => c.credit_type === 'behind_the_scenes')
      .length,
  };
}
