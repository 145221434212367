<script setup>
import { computed, ref, watch } from 'vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useTimeoutFn } from '@vueuse/core';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

const props = defineProps({
  itemsInBag: {
    type: Number,
  },
});

const itemsInBag = computed(() => props.itemsInBag);
const itemsInBagWhileIndicatorActive = ref(0);
const isBagIndicatorActive = ref(false);
const { linkClicked } = useBaseEvents();

const { start: hideBagIndicator, stop: stopHideBagIndicator } = useTimeoutFn(
  () => {
    isBagIndicatorActive.value = false;
    itemsInBagWhileIndicatorActive.value = 0;
  },
  4000,
  { immediate: false }
);

const resetState = () => {
  isBagIndicatorActive.value = false;
  itemsInBagWhileIndicatorActive.value = 0;
};

watch(itemsInBag, (newVal, oldVal) => {
  if (
    itemsInBagWhileIndicatorActive.value + newVal - oldVal <= 0 ||
    oldVal === null
  ) {
    resetState();
  } else if (newVal - oldVal > 0 || isBagIndicatorActive.value) {
    itemsInBagWhileIndicatorActive.value += newVal - oldVal;
    isBagIndicatorActive.value = true;
    stopHideBagIndicator();
    hideBagIndicator();
  } else {
    resetState();
  }
});

const handleCheckOutClick = () => {
  linkClicked({
    context: 'site header navigation',
    subContext: 'bag indicator',
    linkLabel: 'check out',
    linkHref: '/bag',
  });
  resetState();
};
</script>

<template>
  <Transition name="fade">
    <div
      v-if="isBagIndicatorActive"
      class="u-container bag-indicator"
      v-bind="$attrs"
    >
      <div class="bag-indicator__container" role="alert">
        <div class="bag-indicator__content">
          <button
            type="button"
            class="u-button-reset bag-indicator__close"
            aria-label="Close Modal"
            @click="() => (isBagIndicatorActive = false)"
          >
            <SoonaIcon name="xmark" />
          </button>
          <div class="bag-indicator__checkmark">
            <div class="bag-indicator__checkmark-inner">
              <SoonaIcon name="circle-check-solid" size="small" />
            </div>
          </div>
          <div class="bag-indicator__copy">
            <h3 class="bag-indicator__heading">
              <span
                v-if="itemsInBagWhileIndicatorActive > 1"
                class="bag-indicator__quantity"
              >
                {{ itemsInBagWhileIndicatorActive }}
              </span>
              added to bag!
            </h3>
            <p>we’ll hold on to it for you.</p>
          </div>
        </div>
        <div class="bag-indicator__footer">
          <SoonaButton
            copy="check out"
            element="router-link"
            to="/bag"
            data-cypress="button-bag-indicator-checkout"
            @on-click="handleCheckOutClick"
          />
        </div>
      </div>
    </div>
  </Transition>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.bag-indicator {
  position: fixed;
  z-index: 6;

  @media (min-width: variables.$screen-md-min) {
    position: relative;
  }

  &__container {
    position: absolute;
    top: 0;
    right: 0.5rem;
    background-color: variables.$white-default;
    border-radius: 0.625rem;
    box-shadow: variables.$elevation-4;
    padding: 1rem;
    padding-top: 2.25rem;
    min-width: min(19rem, 90vw);
    min-width: min(19rem, 90dvw);
  }

  &__content {
    align-items: flex-start;
    display: flex;
    gap: 0.875rem;
  }

  &__close {
    align-items: center;
    border-radius: 50%;
    color: variables.$gray-60;
    display: flex;
    height: 1.5rem;
    justify-content: center;
    padding: 0.25rem;
    position: absolute;
    right: 1rem;
    top: 0.875rem;
    transition: 0.2s;
    width: 1.5rem;

    &:hover {
      background-color: variables.$gray-10;
    }

    &:active {
      background-color: variables.$gray-20;
    }

    svg {
      display: block;
    }
  }

  &__checkmark {
    align-items: center;
    display: flex;
    height: 24px;

    svg {
      color: variables.$avo-toast-40;
      transform: scale(1.2);
      transform-origin: center;
    }
  }

  &__checkmark-inner {
    align-items: center;
    animation: pulse 1s alternate infinite;
    box-shadow: 0 0 0 variables.$avo-toast-20;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    transform: scale(0.9);
    transform-origin: center;

    @media (prefers-reduced-motion: reduce) {
      animation: none;
      box-shadow: none;
    }
  }

  &__heading {
    @include variables_fonts.u-body--heavy;
  }

  &__footer {
    padding-top: 1rem;

    :deep(a) {
      width: 100%;
    }
  }

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(variables.$avo-toast-20, 0);
    }

    100% {
      box-shadow: 0 0 0 5px rgba(variables.$avo-toast-20, 1);
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
