<script setup>
import { useRoute } from 'vue-router';
import { ref, toRefs, computed, inject, watch } from 'vue';
import { useFlag } from '@/composables/useFlag';
import SoonaLoading from '@/components/SoonaLoading.vue';
import { useCapability } from '@/composables/useCapability';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import SoonaSkeleton from '@/components/ui_library/SoonaSkeleton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaDropdownMenu from '@/components/ui_library/SoonaDropdownMenu.vue';
import { useMokkerDigitalAsset } from '@/queries/mokker/useMokkerDigitalAsset';
import { useMediaEditorDigitalAsset } from '@/composables/digital_assets/useMediaEditorDigitalAsset';
import { MEDIA_POPOVER_PREV_KEY } from '@/components/user/anytime/gallery/media-editor-routing-keys';
import DownloadDigitalAssetMenuItem from '@/components/user/anytime/gallery/media-editor/download/DownloadDigitalAssetMenuItem.vue';
import DownloadCreatedWithPaywall from '@/components/user/anytime/gallery/media-editor/download/DownloadCreatedWithPaywall.vue';

const props = defineProps({
  previewPayload: {
    type: Object,
    required: true,
  },
  iframeRef: {
    type: Object,
    required: true,
  },
});

const emits = defineEmits([
  'close-preview',
  'show-paywall-dialog',
  'close-iframe',
  'recreate-scene',
]);

const aiTemplatesFlag = useFlag('toaster_ai_templates');

const { previewPayload, iframeRef } = toRefs(props);

const previewImgUuid = ref(previewPayload.value.uuid);

const previewingAsset = computed(() =>
  previewPayload.value.renders.find(
    render => render.uuid === previewImgUuid.value
  )
);
const isUpscaled = computed(() => previewingAsset.value.is_upscaled);

const renderJobId = computed(() => previewingAsset.value.render_job_id);
const renders = computed(() => previewPayload.value.renders);

const imageLoaded = ref(false);
const { data: mokkerDigitalAsset } = useMokkerDigitalAsset(previewImgUuid);

const assetAccountId = computed(() => mokkerDigitalAsset.value?.account_id);
const assetId = computed(() => mokkerDigitalAsset.value?.id);

const { asset, mediaUrl, webUrl, createdInEditor, title } =
  useMediaEditorDigitalAsset(assetAccountId, assetId);

const mokkerIframeDomain = import.meta.env.VITE_MOKKER_IFRAME_DOMAIN || '*';

const isUpscaling = ref(false);

const multipleRenders = computed(() => renders.value.length > 1);

const handleNavigateRenders = step => {
  const currentIndex = renders.value.findIndex(
    render => render.uuid === previewImgUuid.value
  );
  const newRender =
    renders.value[
      (currentIndex + step + renders.value.length) % renders.value.length
    ];

  if (newRender) {
    previewImgUuid.value = newRender.uuid;
    imageLoaded.value = false;
  }
};

const handleRecreateScene = () => {
  iframeRef.value.contentWindow.postMessage(
    {
      action: 'mokker:recreate-scene',
      payload: { render_job_id: renderJobId.value },
    },
    mokkerIframeDomain
  );
  emits('close-preview');
};

const handleCreateTemplate = () => {
  iframeRef.value.contentWindow.postMessage(
    {
      action: 'mokker:create-template',
      payload: { render_id: previewingAsset.value.uuid },
    },
    mokkerIframeDomain
  );
  emits('close-preview');
};

const injectedPrevRoute = inject(MEDIA_POPOVER_PREV_KEY);
const openInEditorRoute = computed(() => {
  return {
    ...injectedPrevRoute.value,
    params: {
      assetId: assetId.value,
    },
    query: {
      ...injectedPrevRoute.value?.query,
      tool: 'none',
    },
  };
});

// subscription
const { hasCapability: canAdjustBackgroundOnOwnAccount } = useCapability({
  capability: 'media_editor_adjust_bg_on_own_account',
  subjectType: 'account',
  subjectId: assetAccountId,
});
const { hasCapability: staffCanAdjustBackgroundOnClientAccount } =
  useCapability({
    capability: 'media_editor_adjust_bg_on_subscription_accounts',
  });

const hasSubscriptionDownloadAccess = computed(() => {
  return (
    canAdjustBackgroundOnOwnAccount?.value ||
    staffCanAdjustBackgroundOnClientAccount?.value
  );
});

// download dropdown menu

const { linkClicked } = useBaseEvents();
const route = useRoute();

const handleUpscale = () => {
  iframeRef.value.contentWindow.postMessage(
    {
      action: 'mokker:upscale-image',
      payload: { id: previewImgUuid.value, title: title.value },
    },
    mokkerIframeDomain
  );
  isUpscaling.value = true;

  linkClicked({
    context: route.meta.context,
    subContext: 'ai generation preview',
    linkLabel: 'upscale and download',
    linkHref: null,
  });
};

const downloadFile = ({ url, label }) => {
  linkClicked({
    context: route.meta.context,
    subContext: 'ai generation preview',
    linkLabel: label,
    linkHref: null,
  });
  window.location.href = url;
};
const clickDownloadDropDownMenu = () => {
  linkClicked({
    context: route.meta.context,
    subContext: 'ai generation preview',
    linkLabel: 'download',
    linkHref: null,
  });
};

const handleClose = () => {
  if (!isUpscaling.value) {
    emits('close-preview');
  }
};

watch(isUpscaled, () => {
  if (isUpscaled.value) {
    isUpscaling.value = false;
  }
});
</script>

<template>
  <SoonaDialog max-width="65rem" @close="handleClose">
    <SoonaLoading v-if="isUpscaling" is-loading is-contained />
    <div class="ai-asset-preview__dialog">
      <SoonaButton
        v-if="multipleRenders"
        variation="icon-gray-outline"
        @click="handleNavigateRenders(-1)"
      >
        <SoonaIcon name="arrow-left" />
      </SoonaButton>
      <div class="ai-asset-preview">
        <SoonaSkeleton v-if="!imageLoaded" class="ai-asset-preview__skeleton" />
        <img
          v-show="imageLoaded"
          :src="mediaUrl"
          :alt="mokkerDigitalAsset?.preview?.alt_text"
          @load="imageLoaded = true"
        />
      </div>
      <SoonaButton
        v-if="multipleRenders"
        variation="icon-gray-outline"
        @click="handleNavigateRenders(1)"
      >
        <SoonaIcon name="arrow-right" />
      </SoonaButton>
    </div>
    <template #footer>
      <div class="ai-asset-preview__dialog__footer">
        <SoonaButton
          v-if="aiTemplatesFlag"
          size="small"
          variation="secondary-black"
          @click="handleCreateTemplate()"
        >
          save as template
        </SoonaButton>
        <SoonaButton
          v-if="!!renderJobId"
          size="small"
          variation="secondary-black"
          @click="handleRecreateScene()"
        >
          recreate scene
        </SoonaButton>
        <SoonaButton
          size="small"
          element="router-link"
          variation="secondary-black"
          :to="openInEditorRoute"
          @click="emits('close-iframe')"
        >
          open in editor
        </SoonaButton>

        <SoonaDropdownMenu
          copy="download"
          class="download-digital-asset-menu"
          @button-action="clickDownloadDropDownMenu"
        >
          <template #default="{ clickCapture, keydown, mouseover }">
            <DownloadCreatedWithPaywall
              v-if="createdInEditor && !hasSubscriptionDownloadAccess"
              :asset="asset"
              :click-capture="clickCapture"
              :keydown="keydown"
              :mouseover="mouseover"
              @show-paywall-dialog="emits('show-paywall-dialog')"
              @click="downloadFile($event)"
            />
            <template v-else-if="isUpscaled">
              <DownloadDigitalAssetMenuItem
                :mouseover="mouseover"
                :keydown="keydown"
                :click-capture="clickCapture"
                title="download web resolution"
                @click="downloadFile({ url: webUrl, label: 'download' })"
              />
              <DownloadDigitalAssetMenuItem
                :mouseover="mouseover"
                :keydown="keydown"
                :click-capture="clickCapture"
                title="download full resolution"
                @click="downloadFile({ url: mediaUrl, label: 'download' })"
              />
            </template>
            <template v-else>
              <DownloadDigitalAssetMenuItem
                :mouseover="mouseover"
                :keydown="keydown"
                :click-capture="clickCapture"
                title="download"
                @click="downloadFile({ url: mediaUrl, label: 'download' })"
              />
              <DownloadDigitalAssetMenuItem
                :mouseover="mouseover"
                :keydown="keydown"
                :click-capture="clickCapture"
                title="upscale and download"
                subtitle="upscaling uses one generation"
                @click="handleUpscale"
              />
            </template>
          </template>
        </SoonaDropdownMenu>
      </div>
    </template>
  </SoonaDialog>
</template>

<style scoped lang="scss">
@use '@/variables';

.ai-asset-preview {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  &__skeleton {
    display: flex;
    flex: 0 0 12.75rem;
    flex-direction: column;
    gap: 0.5rem;
    padding-bottom: 30rem;
    width: 100%;
    height: 100%;
    min-height: 36rem;
  }

  &__dialog {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    background-color: transparent;
    gap: 2rem;

    img {
      max-height: 50vh;
      width: auto;
    }

    @media (min-width: variables.$screen-lg-min) {
      img {
        max-height: 100%;
        width: auto;
      }
    }

    &__footer {
      gap: 1rem;
      bottom: 0;
      display: flex;
      position: relative;
      justify-content: right;

      @media (max-width: 40rem) {
        gap: 0.5rem;
        align-items: center;
      }
    }
  }
}

.download-digital-asset-menu {
  :deep(> [role='menu']) {
    padding-top: 0;
    padding-bottom: 0;

    > li:first-child > .download-option {
      border-radius: 0.3125rem 0.3125rem 0 0;
    }

    > li:last-child > .download-option {
      border-radius: 0 0 0.3125rem 0.3125rem;
    }
  }
}
</style>
