<script setup>
import { computed, onMounted, ref, watch, provide } from 'vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useIntegrations } from '@/composables/useIntegrations';
import { useActiveListing, useRefreshListing } from '@/queries/useListings';
import { usePriorityError } from 'src/composables/usePriorityError';
import { useTitle } from '@vueuse/core';
import ListingDetailSummary from './ListingDetailSummary.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import AnalyzingYourListingsBanner from '../AnalyzingYourListingsBanner.vue';
import SoonaBackButton from '@/components/ui_library/SoonaBackButton.vue';
import PageContainerOffset from '@/components/platform-layout/PageContainerOffset.vue';
import PageContainerOffsetTop from '@/components/platform-layout/PageContainerOffsetTop.vue';
import { useListingsListQueryParams } from '../useListingsListQueryParams';

const props = defineProps({
  accountId: { type: String, required: true },
  externalProductId: { type: String, required: true },
  integrationId: { type: String, required: true },
});

const accountId = computed(() => props.accountId);
const externalProductId = computed(() => props.externalProductId);
const integrationId = computed(() => props.integrationId);

const { pageViewed } = useBaseEvents();

onMounted(() => {
  pageViewed();
  window.scrollTo(0, 0);
});

// listing data
const { integrationsSuccess, integrationsError, currentPlatformName } =
  useIntegrations(accountId, integrationId);

const pageTitle = computed(() => `${currentPlatformName.value} listing`);

useTitle(`${pageTitle.value} | soona`);

const {
  data: listingData,
  error: listingError,
  isSuccess,
} = useActiveListing(integrationId, externalProductId, {
  enabled: integrationsSuccess,
  refetchInterval: query => {
    if (query.state.error?.response?.status === 403) {
      return false;
    }
    return query.state.data?.scoring_in_progress ||
      !query.state.data?.last_scored
      ? 5000
      : false;
  },
});

// provide digital assets
const listingAssets = computed(() => listingData.value?.assets ?? null);
provide('listingAssets', listingAssets);

const lastScoredTimestamp = computed(() => listingData.value?.last_scored);

const { mutate } = useRefreshListing(integrationId, externalProductId);

const refreshListingActivated = ref(false);

const refreshListing = () => {
  refreshListingActivated.value = true;
  mutate(null, {
    onSuccess: () => {
      refreshListingActivated.value = false;
    },
  });
};

watch([lastScoredTimestamp, integrationId, externalProductId], () => {
  if (lastScoredTimestamp.value) {
    refreshListingActivated.value = false;
  }
});

const priorityError = usePriorityError(integrationsError, listingError);

const listingIsCalculating = computed(() => {
  return listingData.value?.scoring_in_progress ?? false;
});

const { backToStoreList } = useListingsListQueryParams();

const listingIsLoading = computed(
  () => listingIsCalculating.value || refreshListingActivated.value
);
</script>

<template>
  <SoonaError v-if="priorityError">
    {{ priorityError }}
  </SoonaError>
  <div v-if="listingIsLoading" class="listing-detail__loading">
    <SoonaBackButton @click="backToStoreList" />
    <AnalyzingYourListingsBanner />
  </div>
  <PageContainerOffsetTop v-if="!listingIsLoading && isSuccess">
    <PageContainerOffset class="listing-detail__summary">
      <ListingDetailSummary
        :listing="listingData"
        :account-id="accountId"
        :integration-id="integrationId"
        :external-product-id="externalProductId"
        :platform="currentPlatformName"
        @refresh-listing="refreshListing"
      />
    </PageContainerOffset>

    <router-view v-slot="{ Component }">
      <component
        :is="Component"
        :listing-data="listingData"
        :platform="currentPlatformName"
        @refresh-listing="refreshListing"
      />
    </router-view>
  </PageContainerOffsetTop>
</template>

<style lang="scss" scoped>
@use '@/variables';

.listing-detail {
  &__loading {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__summary {
    margin-bottom: 1rem;
  }

  &__link-section {
    margin-bottom: 1rem;
    display: flex;
    border-bottom: 0.0625rem solid variables.$gray-30;
    overflow-x: auto;

    :deep(.soona-tab:not([aria-current='true'])) {
      border-bottom: none;
      display: flex;
      gap: 0.25rem;
    }
  }
}
</style>
