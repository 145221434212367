<script setup>
import { computed, ref, watchEffect } from 'vue';
import { onKeyStroke } from '@vueuse/core';
import { useRouter } from 'vue-router';
import { useGetOrderReservationFile } from '@/queries/orders/useGetOrderReservationFile';
import { usePriorityError } from 'src/composables/usePriorityError';
import { convertToDDMMYYHHMM12z } from '@/lib/date-formatters';
import SoonaError from 'src/components/ui_library/SoonaError.vue';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaSelect from 'src/components/ui_library/SoonaSelect.vue';
import AssetSummary from './AddOnLineItem/AssetSummary.vue';

const props = defineProps({
  orderId: {
    required: true,
    type: [Number, String],
  },
  reservationFileId: {
    required: true,
    type: [Number, String],
  },
});

defineOptions({ inheritAttrs: false });

const router = useRouter();

const orderId = computed(() => props.orderId);
const reservationFileId = computed(() => props.reservationFileId);

const { data, error } = useGetOrderReservationFile({
  orderId,
  reservationFileId,
});

const priorityError = usePriorityError(error);

const left = ref();
const right = ref();

const formatOption = reservationFile => {
  return {
    label: `${reservationFile.edit_status} - ${convertToDDMMYYHHMM12z(
      reservationFile.created_at
    )}`,
    value: reservationFile,
  };
};

const choices = computed(() => {
  if (!data.value) return [];

  const opts = [formatOption(data.value)];
  data.value.derivatives.forEach(derivative =>
    opts.push(formatOption(derivative))
  );
  return opts;
});

watchEffect(() => {
  if (!choices.value) return;

  const existingLeft = choices.value.find(c => c.value.id === left.value?.id);
  const existingRight = choices.value.find(c => c.value.id === right.value?.id);

  left.value = existingLeft?.value ?? choices.value?.at(0)?.value;
  right.value = existingRight?.value ?? choices.value?.at(-1)?.value;
});

onKeyStroke('ArrowLeft', () => {
  if (!data.value.previous) return;

  router.push({
    name: 'crew-order-reservation-file-review',
    params: {
      orderId: orderId.value,
      reservationFileId: data.value.previous,
    },
  });
});

onKeyStroke('ArrowRight', () => {
  if (!data.value.next) return;

  router.push({
    name: 'crew-order-reservation-file-review',
    params: { orderId: orderId.value, reservationFileId: data.value.next },
  });
});
</script>
<template>
  <SoonaError v-if="priorityError">{{ priorityError }}</SoonaError>
  <div v-if="data" class="review-wrapper">
    <router-link
      class="navigation-link"
      :to="{
        name: 'crew',
        params: { reservationId: data.order.reservation_id },
      }"
    >
      <SoonaIcon name="arrow-left" class="navigation-link__icon" />
      back
    </router-link>
    <h1 class="title">{{ data.title }}</h1>
    <div class="columns-wrapper">
      <div v-if="left" :key="left.file.url" class="review-column">
        <img
          :src="left.file.url"
          :alt="`${left.edit_status} uploaded at ${left.created_at}`"
        />
        <div class="derivative-selector-wrapper">
          <AssetSummary
            :reservation-file="left"
            :order-id="orderId"
            :original-reservation-file-id="reservationFileId"
            :enable-review="true"
          />
          <SoonaSelect v-model="left" :options="choices">
            <template #label>left</template>
          </SoonaSelect>
        </div>
      </div>
      <div v-if="right" :key="right.file.url" class="review-column">
        <img
          :src="right.file.url"
          :alt="`${right.edit_status} uploaded at ${right.created_at}`"
        />
        <div class="derivative-selector-wrapper">
          <AssetSummary
            :reservation-file="right"
            :order-id="orderId"
            :original-reservation-file-id="reservationFileId"
            :enable-review="true"
          />
          <SoonaSelect v-model="right" :options="choices">
            <template #label>right</template>
          </SoonaSelect>
        </div>
      </div>
    </div>
    <div class="notes-wrapper">
      <h2 class="section-title">notes</h2>
      <p>{{ data.note }}</p>
    </div>
    <div class="add-ons-wrapper">
      <h2 class="section-title">add-ons</h2>
      <ul class="add-on-items">
        <li
          v-for="addOn in data.media_add_ons"
          :key="addOn.id"
          class="add-on-item is-size-7"
        >
          {{ addOn.name }} ({{
            Number(addOn.total).toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })
          }})
        </li>
      </ul>
    </div>
    <div class="navigation-wrapper">
      <router-link
        v-if="data.previous"
        class="naviagtion-link"
        :to="{
          name: 'crew-order-reservation-file-review',
          params: { orderId: orderId, reservationFileId: data.previous },
        }"
      >
        <SoonaIcon name="arrow-left" class="navigation-link__icon" />
        previous
      </router-link>
      <router-link
        v-if="data.next"
        class="navigation-link"
        :to="{
          name: 'crew-order-reservation-file-review',
          params: { orderId: orderId, reservationFileId: data.next },
        }"
      >
        next
        <SoonaIcon name="arrow-right" class="navigation-link__icon" />
      </router-link>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.title {
  @include variables_fonts.u-headline--heavy;
}

.section-title {
  @include variables_fonts.u-label--heavy;
}

.notes-wrapper {
  margin: 0.25rem;
}

.add-ons-wrapper {
  margin: 0.25rem;
}

.review-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.navigation-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.navigation-link {
  &__icon {
    vertical-align: middle;
  }
}

.columns-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.review-column {
  flex: 1 1 30rem;
  min-width: 30rem;
}

.derivative-selector-wrapper {
  width: 100%;
  background: variables.$gray-10;
  border: 0.0625rem solid variables.$gray-30;
  border-radius: variables.$control-radius;
  padding: 0.75rem;
  margin-top: 0.25rem;
}
</style>
