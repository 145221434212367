<template>
  <div class="PostShootDetails">
    <p v-if="currentReservation.post_shoot_status">
      <span v-if="willNotOrder">❌</span>
      {{ currentReservation.post_shoot_status }}
    </p>

    <SoonaButton
      v-if="!willNotOrder"
      copy="flag as will not purchase"
      size="medium"
      variation="tertiary"
      @on-click="showModal = true"
    />
    <SoonaButton
      v-else
      copy="remove flag"
      size="medium"
      variation="tertiary"
      @on-click="removeFlag"
    />

    <SoonaDialog v-if="showModal" @close="closeModal">
      <template #heading>flag as will not purchase</template>
      <div class="dialog-flex">
        <p>are you sure you want to flag this booking “will not purchase"?</p>
        <SoonaSelect
          name="reason"
          placeholder="select reason"
          :options="options"
          :model-value="selectedReason"
          @update:model-value="val => handleSelect(val)"
        >
          <template #label>will not purchase reason:</template>
        </SoonaSelect>
        <SoonaTextfield
          v-model="note"
          label="notes"
          name="note"
          autocomplete="off"
          placeholder="details on will not purchase reason"
        />
      </div>
      <template #footer="{ close }">
        <SoonaButton variation="tertiary" @on-click="close">
          cancel
        </SoonaButton>
        <SoonaButton
          :disabled="!readyToSave"
          data-cypress="button-dialog-confirm"
          @on-click="confirm"
        >
          save
        </SoonaButton>
      </template>
    </SoonaDialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaSelect from 'src/components/ui_library/SoonaSelect.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';

export default {
  name: 'PostShootDetails',
  components: { SoonaButton, SoonaDialog, SoonaSelect, SoonaTextfield },
  data() {
    return {
      showModal: false,
      options: [
        { value: 'unsatisfied', label: 'did not meet expectations' },
        { value: 'unneeded', label: 'content no longer needed' },
        { value: 'unknown', label: 'reason unknown' },
        { value: 'other', label: 'other' },
      ],
      selectedReason: '',
      note: '',
    };
  },
  computed: {
    ...mapState({
      currentReservation: state => state.reservation.currentReservation,
    }),
    willNotOrder() {
      return this.currentReservation.post_shoot_status === 'will not order';
    },
    readyToSave() {
      return this.selectedReason.length > 0;
    },
  },
  methods: {
    ...mapActions('reservation', [
      'createShootException',
      'loadReservation',
      'deleteShootException',
      'getShootExceptions',
    ]),
    handleClickOutsideWindow() {
      this.showModal = false;
    },
    closeModal() {
      this.showModal = false;
    },
    async confirm() {
      await this.createShootException({
        reservationId: this.currentReservation.id,
        reason: this.selectedReason,
        exceptionType: 1,
        note: this.note,
      });
      this.closeModal();
      await this.loadReservation(this.currentReservation.id);
    },
    handleSelect(value) {
      this.selectedReason = value;
    },
    async removeFlag() {
      await this.handleDelete();
      await this.loadReservation(this.currentReservation.id);
      this.selectedReason = '';
      this.note = '';
    },
    async handleDelete() {
      const exception_id = this.currentReservation.shoot_exceptions.find(
        x => x.exception_type === 'will_not_order'
      ).id;
      try {
        await this.deleteShootException({
          reservationId: this.currentReservation.id,
          shootExceptionId: exception_id,
        });
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-flex {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
</style>
