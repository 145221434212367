<script setup>
import { computed, ref } from 'vue';

import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import WarningIcon from '@/components/svgs/WarningIcon.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';
import { useSoonaToast } from '@/components/ui_library/soona_toast/useSoonaToast';
import { useDeleteDigitalAsset } from '@/queries/digital_assets/useDeleteDigitalAsset';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
  assetId: {
    type: [String, Number],
    required: true,
  },
  message: {
    type: String,
    default: 'are you sure you want to delete this file?',
  },
});

const emits = defineEmits(['file-deleted']);
const accountId = computed(() => props.accountId);
const assetId = computed(() => props.assetId);
const message = computed(() => props.message);

const { addToast } = useSoonaToast();

const { mutate: deleteFile, isPending: isDeleting } = useDeleteDigitalAsset({
  accountId,
  assetId,
});

const showConfirmation = ref(false);

const handleDeleteFile = () => {
  showConfirmation.value = false;
  deleteFile(
    {},
    {
      onError: () => {
        addToast(`error deleting file`, {
          variation: 'error',
          noDismissButton: true,
        });
      },
      onSuccess: () => {
        emits('file-deleted');
      },
    }
  );
};
</script>

<template>
  <SoonaLoading v-if="isDeleting" is-loading loading-text="deleting..." />
  <SoonaButton
    aria-label="delete file"
    variation="icon-gray-outline"
    size="medium"
    data-cypress="delete-digital-asset-button"
    @click="showConfirmation = true"
  >
    <SoonaIcon class="favorite-toggle__icon" name="trash" size="medium" />
  </SoonaButton>
  <SoonaDialog
    v-if="showConfirmation"
    data-cypress="delete-digital-asset-dialog"
    @close="showConfirmation = false"
  >
    <template #heading>
      <div class="close-warning__header">
        <WarningIcon class="close-warning__icon" />
        delete file
      </div>
    </template>
    {{ message }}
    <template #footer>
      <SoonaButton
        type="button"
        variation="tertiary"
        data-cypress="delete-digital-asset-dialog-cancel"
        @click="showConfirmation = false"
      >
        cancel
      </SoonaButton>
      <SoonaButton type="button" variation="primary" @click="handleDeleteFile">
        delete
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>

<style scoped lang="scss">
@use '@/variables';
.close-warning {
  &__header {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
  }

  &__icon {
    padding-top: 0.25rem;
  }
}
</style>
