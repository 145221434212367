<script setup>
import { computed } from 'vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useRoute } from 'vue-router';
import { useUpdateTask } from '@/queries/quest/useUpdateTask';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

const props = defineProps({
  task: {
    required: true,
    type: Object,
  },
});

const emits = defineEmits(['close']);

const { linkClicked } = useBaseEvents();
const route = useRoute();
const context = computed(() => route.meta.context);

const { mutate: updateTaskMutation, isPending: isUpdatingTask } = useUpdateTask(
  computed(() => props.task.id)
);

const handleDismiss = () => {
  updateTaskMutation({ dismissed: true });

  linkClicked({
    context: context.value,
    subContext: 'onboarding quest',
    linkLabel: 'skip step',
    linkHref: null,
  });
};

const handleLinkClick = () => {
  emits('close');

  linkClicked({
    context: context.value,
    subContext: 'onboarding quest',
    linkLabel: 'start',
    linkHref: props.task.cta_url,
  });
};
</script>

<template>
  <div
    class="quest-task"
    :class="{ 'quest-task--complete': task.complete || task.dismissed }"
  >
    <div class="quest-task__left">
      <SoonaIcon :name="task.icon_name" />
      <div>
        <p class="u-label--heavy quest-task__title">{{ task.title }}</p>
        <p class="u-label--regular">
          {{ task.description
          }}<span
            v-if="task.optional && !task.dismissed"
            class="quest-task__optional-margin"
            >.</span
          >
          <SoonaButton
            v-if="task.optional && !task.dismissed && !task.complete"
            copy="skip step"
            :disabled="isUpdatingTask"
            size="medium"
            variation="tertiary"
            :data-cypress="`button-quest-task-skip`"
            :data-task-slug="task.slug"
            @on-click="handleDismiss"
          />
        </p>
      </div>
    </div>
    <div
      v-if="task.complete || task.dismissed"
      class="quest-task__complete-wrapper"
    >
      <p
        class="u-button--small-caps"
        :data-cypress="`text-quest-task-done`"
        :data-task-slug="task.slug"
      >
        done
      </p>
      <SoonaIcon class="quest-task__check" name="circle-check-solid" />
    </div>
    <SoonaButton
      v-else
      element="a"
      copy="start"
      :href="task.cta_url"
      :loading="isUpdatingTask"
      size="medium"
      variation="solid-black"
      :data-cypress="`button-quest-task-start`"
      :data-task-slug="task.slug"
      @on-click="handleLinkClick"
    />
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.quest-task {
  align-items: center;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  padding: 1.25rem;

  &:not(:last-child) {
    border-bottom: 0.125rem solid variables.$gray-30;
  }

  &--complete {
    color: variables.$gray-60;
  }

  &__left {
    align-items: center;
    display: flex;
    gap: 0.75rem;
  }

  &__title {
    padding-bottom: 0.125rem;
  }

  &__optional-margin {
    padding-right: 0.25rem;
  }

  &__complete-wrapper {
    align-items: center;
    color: variables.$black-default;
    display: flex;
    gap: 0.5rem;
  }

  &__check {
    color: variables.$avo-toast-60;
  }
}
</style>
