<script setup>
import { toRefs, ref, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useUploader } from '@/composables/useUploader';
import { useCreateDigitalAsset } from '@/queries/digital_assets/useCreateDigitalAsset';
import { useStorageUsage } from '@/queries/account/useStorageUsage';
import { usePriorityError } from '@/composables/usePriorityError';
import { useSoonaToast } from '@/components/ui_library/soona_toast/useSoonaToast';
import { toMimeTypes } from '@/lib/mime-type-mapping';
import SoonaUploadForm from '@/components/uploader/SoonaUploadForm.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaAlert from '@/components/ui_library/SoonaAlert.vue';
import SoonaProgress from '@/components/ui_library/SoonaProgress.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import BeatLoader from '@/components/shared/BeatLoader.vue';
import UploadProgressDialog from './UploadProgressDialog.vue';
import EditorTools from './EditorTools.vue';
import QuestHelper from '@/components/quest/QuestHelper.vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
});

const { accountId } = toRefs(props);

// use
const { linkClicked } = useBaseEvents();
const route = useRoute();
const router = useRouter();
const { addToast } = useSoonaToast();

const { data: accountStorage, error: availStorageFetchError } =
  useStorageUsage(accountId);

const { mutate: createDigitalAsset, error: createError } =
  useCreateDigitalAsset(accountId);

// refs
const maxConcurrent = ref(3);
const uploadInterval = ref(100);
const fileSizeLimit = ref(50);
const activeUploadsLocal = ref([]);
const cancelUploadsLocal = ref(() => {});
const isDragover = ref(false);
const alertMessage = ref('');
const errorMessage = ref('');
const acceptedFileTypes = ref('.png,.jpg,.jpeg');
const initialTool = ref('');
const initialToolCaption = ref(null);
const isRedirecting = ref(false);

const acceptArray = computed(() =>
  acceptedFileTypes.value.split(',').map(item => item.trim())
);

function validateNotCR2(file) {
  if (file.name.toLowerCase().endsWith('.cr2'))
    return 'cannot upload RAW (CR2) files here';
}

function redirectToMediaEditor(assetId) {
  isRedirecting.value = true;

  const routerDestination = {
    name: 'uploads-assets-media-view',
    params: {
      accountId: accountId.value,
      digitalAssetId: assetId,
    },
    query: {
      ...route.query,
    },
  };

  if (initialTool.value) {
    routerDestination.query = { tool: initialTool.value };
  }
  router.push(routerDestination);
}

const showAlertOrError = (type = 'alert', message) => {
  if (initialTool.value) {
    // alert/error is from tool specific uploading, so use toast
    addToast(message, {
      variation: type,
    });
    return;
  }

  if (type === 'error') {
    errorMessage.value = message;
    return;
  }

  alertMessage.value = message;
};

const handleUploadClick = () => {
  linkClicked({
    context: 'media editor',
    subContext: 'landing page',
    linkLabel:
      initialToolCaption.value !== ''
        ? initialToolCaption.value
        : 'upload media',
    linkHref: null,
  });
};

const handleUploadError = errMsg => {
  cancelUploadsLocal.value();
  showAlertOrError('error', errMsg);
};

const handleUploadComplete = blob => {
  const digitalAsset = {
    asset_type: 'original',
    file: blob.signed_id,
    title: blob.filename,
    media_type: blob.content_type.startsWith('image') ? 'photo' : 'video',
    ownership: 'customer',
    origin: 'customer',
    origin_source: 'manual_upload',
    visibility: 'all',
  };

  createDigitalAsset(digitalAsset, {
    onSuccess: data => {
      if (activeUploadsLocal.value.length === 0 && data.id) {
        handleUploadClick();
        redirectToMediaEditor(data.id);
      }
    },
  });
};

const remainingStorageBytes = computed(() => {
  if (accountStorage.value) {
    return (
      accountStorage.value?.total_available_storage -
      accountStorage.value?.total_used_storage
    );
  }

  return 0;
});

const {
  handleUpload,
  handleDrop,
  activeUploads,
  percentComplete: totalPercentComplete,
  cancelUploads,
} = useUploader(
  maxConcurrent,
  uploadInterval,
  fileSizeLimit,
  validateNotCR2,
  handleUploadError,
  handleUploadComplete,
  remainingStorageBytes
);

activeUploadsLocal.value = activeUploads.value;
cancelUploadsLocal.value = cancelUploads;

const completedUploads = computed(() =>
  activeUploads.value.filter(upload => upload.completed)
);

const isEditorTransition = computed(
  () =>
    (totalPercentComplete.value === 100 || isRedirecting.value) &&
    !createError.value
);

const handleDroppedFiles = (e, tool = '', toolCaption = '') => {
  // don’t allow drag-and-drop upload if available storage check is still loading
  if (!accountStorage.value) return;

  isDragover.value = false;
  alertMessage.value = '';
  errorMessage.value = '';
  initialTool.value = tool;
  initialToolCaption.value = toolCaption;

  // only check validity for tool uploads, since SoonaUploadForm checks in the general upload section up top
  if (tool !== '') {
    const hasInvalidFileType = [...e.dataTransfer.files].some(file => {
      if (!toMimeTypes(acceptArray.value).includes(file.type)) {
        showAlertOrError(
          'alert',
          `only ${acceptArray.value.join(', ')} files allowed`
        );
        return true;
      }
    });

    if (hasInvalidFileType) return;
  }

  handleDrop(e);
};

const priorityError = usePriorityError(
  createError,
  availStorageFetchError,
  errorMessage
);

const isUploading = computed(() => activeUploads.value.length > 0);

const handleCancelUploads = () => {
  if (completedUploads.value.length > 0) {
    cancelUploads();
  } else {
    cancelUploads();
    addToast('upload canceled', {
      variation: 'info',
      iconName: 'xmark',
    });
  }
};
</script>

<template>
  <QuestHelper quest-task-slug="media_editor">
    <SoonaUploadForm
      :accept="acceptedFileTypes"
      :active-uploads="activeUploads"
      is-multiple
      :prevent-dragover-animation="!accountStorage"
      :suppress-default-content="!accountStorage || !!availStorageFetchError"
      @drop="handleDroppedFiles"
      @upload="
        $event => {
          alertMessage = '';
          errorMessage = '';
          initialTool = '';
          initialToolCaption = '';
          handleUpload($event);
        }
      "
    >
      <template #content-top>
        <UploadProgressDialog v-if="isUploading || isEditorTransition">
          <div class="da-upload__progresses">
            <em class="u-subheader--heavy da-upload__progresses-title">
              {{
                isEditorTransition
                  ? `done! 🎉 opening ${
                      initialTool === 'ai-studio' ? 'AI studio' : 'media editor'
                    }…`
                  : 'uploading…'
              }}
            </em>
            <SoonaProgress
              v-for="(upload, index) in activeUploads"
              :key="index"
              class="da-upload__progress"
              :error="upload.error"
              :is-waiting="!upload.activated"
              :value="
                (upload.tracker?.loaded / upload.tracker?.total ?? 0) * 100
              "
            />
          </div>
          <SoonaButton
            v-if="isUploading"
            variation="tertiary"
            size="medium"
            @on-click="handleCancelUploads"
          >
            <template
              v-if="completedUploads.length > 0 && activeUploads.length > 1"
            >
              cancel incomplete upload(s)
            </template>
            <template v-else>cancel upload</template>
          </SoonaButton>
        </UploadProgressDialog>
        <BeatLoader
          v-if="!accountStorage && !availStorageFetchError"
          size="1rem"
        />
      </template>
      <template #content-bottom>
        <SoonaAlert
          v-if="alertMessage && !isEditorTransition"
          class="da-upload__notification"
        >
          {{ alertMessage }}
        </SoonaAlert>
        <SoonaError
          v-if="errorMessage || priorityError"
          class="da-upload__notification"
        >
          {{ errorMessage || priorityError }}
        </SoonaError>
      </template>
    </SoonaUploadForm>
  </QuestHelper>
  <EditorTools
    :is-loading="!accountStorage"
    @tool-file-browse-upload="
      (e, tool, toolCaption) => {
        alertMessage = '';
        errorMessage = '';
        initialTool = tool;
        initialToolCaption = toolCaption;
        handleUpload(e);
      }
    "
    @tool-file-drop-upload="
      (e, tool, toolCaption) => {
        handleDroppedFiles(e, tool, toolCaption);
      }
    "
  />
</template>

<style lang="scss" scoped>
.da-upload {
  &__progresses {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0.75rem;
    min-width: 17.75rem;

    &-title {
      font-style: normal;
    }
  }

  &__progress {
    width: 16.25rem;
  }

  &__notification {
    margin: 1rem 0 0;
  }
}
</style>
