<script setup>
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useRoute } from 'vue-router';
import { computed } from 'vue';
import SoonaDropdownMenu from '@/components/ui_library/SoonaDropdownMenu.vue';
import SoonaDropdownMenuItem from '@/components/ui_library/SoonaDropdownMenuItem.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

const props = defineProps({
  reservationFile: {
    type: Object,
    required: true,
  },
  variation: {
    default: 'primary',
    type: String,
  },
  size: {
    default: 'medium',
    type: String,
    validator: function (value) {
      return ['large', 'medium'].includes(value);
    },
  },
  saveToGallery: {
    default: false,
    type: Boolean,
  },
});

const emits = defineEmits(['saveToGallery']);
const route = useRoute();

const reservationFile = computed(() => props.reservationFile);
const variation = computed(() => props.variation);
const size = computed(() => props.size);
const saveToGallery = computed(() => props.saveToGallery);
const { linkClicked } = useBaseEvents();

const showWebDownload = computed(() => {
  return reservationFile.value.web_url && !reservationFile.value.is_gif;
});

const buttonCopy = computed(() => (saveToGallery.value ? 'save' : 'download'));

const saveToGalleryDisabled = computed(() => !saveToGallery.value);

const saveToComputerDisabled = computed(() => saveToGallery.value);

const handleSaveToGallery = () => {
  linkClicked({
    context: route.meta.context,
    subContext: 'media editor header',
    linkLabel: 'save to gallery',
    linkHref: null,
  });

  emits('saveToGallery');
};

const clickSoonaDropDownMenu = () => {
  linkClicked({
    context: route.meta.context,
    subContext: 'media editor header',
    linkLabel: buttonCopy.value,
    linkHref: null,
  });
};

const downloadFile = url => {
  window.location.href = url;
};

const downloadAndTrackClick = (url, buttonText) => {
  linkClicked({
    context: route.meta.context,
    subContext: 'media editor header',
    linkLabel: buttonText,
    linkHref: null,
  });

  window.location.href = url;
};
</script>

<template>
  <SoonaDropdownMenu
    :copy="buttonCopy"
    :size="size"
    :variation="variation"
    @button-action="clickSoonaDropDownMenu"
  >
    <template #default="{ clickCapture, keydown, mouseover }">
      <SoonaDropdownMenuItem>
        <button
          role="menuitem"
          :disabled="saveToGalleryDisabled"
          @click.capture="clickCapture"
          @click="handleSaveToGallery"
          @keydown="keydown"
          @mouseover="mouseover"
        >
          <div class="download-option">
            <SoonaIcon name="duplicate" size="small" />
            save to gallery
          </div>
        </button>
      </SoonaDropdownMenuItem>
      <SoonaDropdownMenuItem v-if="showWebDownload">
        <button
          role="menuitem"
          :disabled="saveToComputerDisabled"
          @click.capture="clickCapture"
          @click="downloadAndTrackClick(reservationFile.web_url, 'web')"
          @keydown="keydown"
          @mouseover="mouseover"
        >
          <div class="download-option">
            <SoonaIcon name="arrow-down-to-bracket" size="small" />
            web
          </div>
        </button>
      </SoonaDropdownMenuItem>
      <SoonaDropdownMenuItem v-if="reservationFile.media_url">
        <button
          role="menuitem"
          :disabled="saveToComputerDisabled"
          @click.capture="clickCapture"
          @click="downloadAndTrackClick(reservationFile.media_url, 'high res')"
          @keydown="keydown"
          @mouseover="mouseover"
        >
          <div class="download-option">
            <SoonaIcon name="arrow-down-to-bracket" size="small" />
            high res
          </div>
        </button>
      </SoonaDropdownMenuItem>
      <SoonaDropdownMenuItem v-if="reservationFile.raw_url">
        <button
          role="menuitem"
          :disabled="saveToComputerDisabled"
          @click.capture="clickCapture"
          @click="downloadAndTrackClick(reservationFile.raw_url, 'raw file')"
          @keydown="keydown"
          @mouseover="mouseover"
        >
          <div class="download-option">
            <SoonaIcon name="arrow-down-to-bracket" size="small" />
            raw file
          </div>
        </button>
      </SoonaDropdownMenuItem>
      <SoonaDropdownMenuItem v-if="reservationFile.color_card_url">
        <button
          role="menuitem"
          :disabled="saveToComputerDisabled"
          @click.capture="clickCapture"
          @click="downloadFile(reservationFile.color_card_url)"
          @keydown="keydown"
          @mouseover="mouseover"
        >
          <div class="download-option">
            <SoonaIcon name="arrow-down-to-bracket" size="small" />
            color card
          </div>
        </button>
      </SoonaDropdownMenuItem>
      <SoonaDropdownMenuItem
        v-for="derivative in reservationFile.derivatives"
        :key="derivative.media_url"
      >
        <button
          role="menuitem"
          :disabled="saveToComputerDisabled"
          @click.capture="clickCapture"
          @click="downloadFile(derivative.media_url)"
          @keydown="keydown"
          @mouseover="mouseover"
        >
          <div class="download-option">
            <SoonaIcon name="arrow-down-to-bracket" size="small" />
            {{ derivative.title }}
          </div>
        </button>
      </SoonaDropdownMenuItem>
    </template>
  </SoonaDropdownMenu>
</template>

<style lang="scss" scoped>
@use '@/variables';

.download-option {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
</style>
