<script setup>
import { computed, ref } from 'vue';
import GettingStartedDialog from './GettingStartedDialog.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaFlag from '@/components/ui_library/SoonaFlag.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';
import SoonaNotification from '@/components/ui_library/SoonaNotification.vue';
import SoonaTooltip from '@/components/ui_library/SoonaTooltip.vue';
import { usePriorityError } from '@/composables/usePriorityError';
import { useReservation } from '@/composables/useReservation';
import { useSoonaToast } from '@/components/ui_library/soona_toast/useSoonaToast';
import { useReOpenShotList } from '@/queries/shot_list/useReOpenShotList';
import { useSumbitShotList } from '@/queries/shot_list/useSubmitShotList';
import {
  AvoToast20,
  AvoToast80,
  Tangerine30,
  Tangerine80,
} from '@/variables.module.scss';
import { convertToMMDDYY, convertToHHMM12 } from '@/lib/date-formatters';

const props = defineProps({
  hasAttachments: {
    type: Boolean,
    required: true,
  },
  hasScenes: {
    type: Boolean,
    required: true,
  },
  isEditable: {
    type: Boolean,
    default: false,
  },
  isPack: {
    type: Boolean,
    default: false,
  },
  reservationId: {
    type: [Number, String],
    required: true,
  },
  reSubmittedAt: {
    type: String,
    default: null,
  },
  sceneBuilderMode: {
    type: Boolean,
    default: false,
  },
  status: {
    type: String,
    default: null,
  },
  submittedAt: {
    type: String,
    default: null,
  },
});

const emit = defineEmits(['open-dialog', 'show-scenes']);

const { addToast } = useSoonaToast();

const showGettingStartedDialog = ref(false);

const reservationId = computed(() => props.reservationId);
const shotListSubmitted = computed(() => props.status === 'submitted');
const sceneBuilderMode = computed(() => props.sceneBuilderMode);

const { isInProgress, isPendingShotlist, isPreShoot, isWrapped } =
  useReservation(reservationId);

const submittedDate = computed(() => {
  return convertToMMDDYY(props.submittedAt);
});
const submittedTime = computed(() => {
  return convertToHHMM12(props.submittedAt);
});

const reSubmittedDate = computed(() => {
  return convertToMMDDYY(props.reSubmittedAt);
});
const reSubmittedTime = computed(() => {
  return convertToHHMM12(props.reSubmittedAt);
});

const showSubmissionConfirmationDialog = ref(false);

const showSubmissionDialog = () => {
  showSubmissionConfirmationDialog.value = true;
};

const {
  mutate: submitShotList,
  error: submitError,
  isPending: submitIsPending,
} = useSumbitShotList(reservationId);

const submit = () => {
  submitShotList(
    {},
    {
      onSuccess: () => {
        addToast('shot list submitted', {
          variation: 'success',
        });

        showSubmissionConfirmationDialog.value = false;
      },
    }
  );
};

const {
  mutate: reOpenShotList,
  error: reOpenError,
  isPending: reOpenIsPending,
} = useReOpenShotList(reservationId);

const reOpen = () => {
  reOpenShotList(
    {},
    {
      onSuccess: () => {
        addToast('shot list re-opened', {
          variation: 'alert',
          subtext: 'make sure to submit when you are finished editing.',
        });
      },
    }
  );
};

const priorityError = usePriorityError(submitError, reOpenError);
</script>

<template>
  <div class="shot-list-header">
    <SoonaLoading v-if="submitIsPending || reOpenIsPending" is-loading />
    <div v-if="isPack" class="shot-list-header__heading--title">
      <h2 class="u-headline--heavy">your shot list</h2>
    </div>
    <template v-else>
      <div class="shot-list-header__heading">
        <div class="shot-list-header__heading--title">
          <h2 class="u-headline--heavy">your shot list</h2>
        </div>
        <div v-if="isWrapped" class="shot-list-header__heading--wrapped">
          <SoonaIcon name="circle-check-solid" size="small" />
          <p class="u-body--heavy">shoot wrapped</p>
        </div>
        <div
          v-else-if="isInProgress"
          class="shot-list-header__heading--in-progress"
        >
          <SoonaIcon name="camera-solid" size="small" />
          <p class="u-body--heavy">shoot in progress</p>
        </div>
        <div
          v-else-if="shotListSubmitted"
          class="shot-list-header__submitted--re-open"
        >
          <SoonaTooltip>
            <template
              #default="{ setRef, mouseenter, focus, mouseleave, blur }"
            >
              <SoonaIcon
                :ref="el => setRef(el)"
                name="circle-question"
                size="medium"
                @mouseenter="mouseenter"
                @focus="focus"
                @mouseleave="mouseleave"
                @blur="blur"
              />
            </template>
            <template #tooltip-content>
              please submit your final shot list at least 48 hours before your
              shoot. anything added later than that may require a new booking.
            </template>
          </SoonaTooltip>
          <SoonaButton variation="secondary-black" @on-click="reOpen">
            re-open shot list
          </SoonaButton>
        </div>

        <div v-else class="shot-list-header__heading--button-group">
          <SoonaButton
            v-if="(sceneBuilderMode && hasScenes) || !hasAttachments"
            variation="tertiary"
            @on-click="emit('open-dialog', true)"
          >
            <template #icon-left>
              <SoonaIcon name="plus" />
            </template>
            attach files
          </SoonaButton>

          <SoonaButton
            v-else-if="hasAttachments && !hasScenes && !sceneBuilderMode"
            variation="tertiary"
            @on-click="emit('show-scenes')"
          >
            make a shot list
          </SoonaButton>
          <SoonaButton
            variation="primary"
            data-cypress="button-add-new-shot"
            :disabled="!hasAttachments && !hasScenes"
            @on-click="showSubmissionDialog"
          >
            submit
          </SoonaButton>
        </div>
      </div>
      <div v-if="shotListSubmitted" class="shot-list-header__status">
        <SoonaFlag
          :background-color="AvoToast20"
          :text-color="AvoToast80"
          title="submitted"
          type="pill"
        >
          <template #icon-left>
            <SoonaIcon name="circle-check-solid" size="small" />
          </template>
        </SoonaFlag>
        <p v-if="submittedDate" class="shot-list-header__submitted">
          {{ submittedDate }} at {{ submittedTime }}
        </p>
      </div>
      <div v-if="!!reSubmittedDate" class="shot-list-header__status">
        <SoonaFlag
          v-if="shotListSubmitted && !!reSubmittedDate"
          :background-color="Tangerine30"
          :text-color="Tangerine80"
          title="edited"
          type="pill"
        />
        <p class="shot-list-header__submitted">
          re-submitted {{ reSubmittedDate }} at {{ reSubmittedTime }}
        </p>
      </div>
    </template>
    <SoonaError v-if="priorityError">
      {{ priorityError }}
    </SoonaError>
    <template v-if="!isPack">
      <SoonaNotification
        v-if="(isPreShoot || isPendingShotlist) && !shotListSubmitted"
        class="shot-list-header__heading--notification"
      >
        <p>
          must be submitted at least
          <strong>2 business days before your shoot.</strong> anything added
          after that time may require a new booking.
        </p>
      </SoonaNotification>
      <div
        v-if="isEditable && (isPreShoot || isPendingShotlist)"
        class="shot-list-header__heading--help"
      >
        <SoonaButton
          variation="tertiary"
          @on-click="showGettingStartedDialog = true"
        >
          <template #icon-left>
            <SoonaIcon name="circle-question" />
          </template>
          help getting started
        </SoonaButton>
      </div>
    </template>

    <GettingStartedDialog
      v-if="showGettingStartedDialog"
      @close-dialog="showGettingStartedDialog = false"
    />
    <SoonaDialog
      v-if="showSubmissionConfirmationDialog"
      role="alertdialog"
      @close="showSubmissionConfirmationDialog = false"
    >
      <template #heading>ready to submit?</template>
      <div class="shot-list-header__confirmation">
        <p>
          our crew will review your shot list to make sure we have everything to
          achieve your dreams!
        </p>
        <p>
          if you need to make revisions, please submit them at least
          <strong>2 business days before your shoot</strong>. anything added
          after that may require a new booking!
        </p>
      </div>
      <template #footer="{ close }">
        <SoonaButton
          variation="tertiary"
          :disabled="submitIsPending"
          @on-click="close"
        >
          cancel
        </SoonaButton>
        <SoonaButton :loading="submitIsPending" @on-click="submit">
          submit shot list
        </SoonaButton>
      </template>
    </SoonaDialog>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';
.shot-list-header {
  &__status {
    display: flex;
    gap: 0.5rem;
    margin: 0.5rem 0;
  }

  &__heading {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1.5rem;

    &--title {
      align-items: center;
      display: flex;
      gap: 1rem;
    }

    &--wrapped,
    &--in-progress {
      align-items: center;
      display: flex;
      gap: 0.5rem;
    }

    &--in-progress {
      > svg {
        color: variables.$friendly-red-50;
      }
    }

    &--wrapped {
      > svg {
        color: variables.$avo-toast-60;
      }
    }

    &--button-group {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
    }

    &--notification {
      align-items: center;
      border-color: variables.$periwink-blue-30;
      background-color: variables.$periwink-blue-20;
      display: flex;
      margin-bottom: 0.5rem;
    }

    &--help {
      display: flex;
      justify-content: flex-end;
    }
  }

  &__submitted {
    @include variables_fonts.u-body--regular;
    color: variables.$gray-70;

    &--re-open {
      display: flex;
      gap: 1rem;
      align-items: center;
    }
  }

  &__confirmation {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}
</style>
