<script setup>
import { computed } from 'vue';
import BaseInfiniteGalleryCard from '@/components/infinite_asset_gallery/BaseInfiniteGalleryCard.vue';
import OverlayButtonToggle from '@/components/infinite_asset_gallery/OverlayButtonToggle.vue';
import { useCapability } from '@/composables/useCapability';
import DigitalAssetStatusBadge from '@/components/infinite_asset_gallery/DigitalAssetStatusBadge.vue';

const props = defineProps({
  alwaysShowTitles: {
    type: Boolean,
    default: false,
  },
  editsCollectionDigitalAsset: {
    type: Object,
    required: true,
  },
  flexGrow: {
    type: Number,
    default: 0,
  },
  to: {
    type: [String, Object],
    required: true,
  },
});

defineEmits(['request-page', 'open-assign-original-dialog']);

const editsCollectionDigitalAsset = computed(
  () => props.editsCollectionDigitalAsset
);
const asset = computed(() => props.editsCollectionDigitalAsset.digital_asset);

const { hasCapability: isSoonaStaff } = useCapability({
  capability: 'soona_staff',
});

const hasOriginal = computed(() => !!asset.value?.original_id);
const showUnlinkedStatus = computed(
  () => isSoonaStaff.value && !hasOriginal.value
);
</script>
<template>
  <BaseInfiniteGalleryCard
    :digital-asset="asset"
    :paged-asset="editsCollectionDigitalAsset"
    :flex-grow="flexGrow"
    :to="to"
    :always-show-titles="alwaysShowTitles"
    @request-page="page => $emit('request-page', page)"
  >
    <template #action-buttons>
      <OverlayButtonToggle
        v-if="!hasOriginal && isSoonaStaff"
        visually-hidden-text="assign edit to original"
        icon-name="broken-link"
        @click="$emit('open-assign-original-dialog')"
      >
        <template #tooltip-content>assign original</template>
      </OverlayButtonToggle>
    </template>
    <template #statuses>
      <DigitalAssetStatusBadge v-if="showUnlinkedStatus">
        unlinked
      </DigitalAssetStatusBadge>
    </template>
  </BaseInfiniteGalleryCard>
</template>
