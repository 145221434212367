<script setup>
import { ref } from 'vue';
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap';
import uniqueId from 'lodash/uniqueId';
import { useDialogContext } from '@/composables/useDialog';

const dialogBgTarget = ref();

const id = uniqueId('upload-progress-dialog-');
useDialogContext({ id });

useFocusTrap(dialogBgTarget, { immediate: true });
</script>

<template>
  <Teleport to="body">
    <div ref="dialogBgTarget" class="upload-progress">
      <button type="button" class="u-button-reset u-a11y-only" />
      <slot />
    </div>
  </Teleport>
</template>

<style scoped lang="scss">
@keyframes fade-in-bg {
  0% {
    background-color: rgba(255, 255, 255, 0);
  }

  100% {
    background-color: rgba(255, 255, 255, 0.92);
  }
}

.upload-progress {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background-color: rgba(255, 255, 255, 0.92);
  animation: 0.1s ease-out both fade-in-bg;
}
</style>
