<script setup>
import { computed } from 'vue';
import BaseInfiniteGalleryCard from '@/components/infinite_asset_gallery/BaseInfiniteGalleryCard.vue';
import DigitalAssetStatusBadge from '@/components/infinite_asset_gallery/DigitalAssetStatusBadge.vue';

const props = defineProps({
  editsCollectionDigitalAsset: {
    type: Object,
    required: true,
  },
  flexGrow: {
    type: Number,
    default: 0,
  },
  to: {
    type: [String, Object],
    required: true,
  },
});

defineEmits(['request-page']);

const editsCollectionDigitalAsset = computed(
  () => props.editsCollectionDigitalAsset
);
const asset = computed(() => props.editsCollectionDigitalAsset.digital_asset);

const isAccepted = computed(
  () => editsCollectionDigitalAsset.value.edit_status === 'accepted'
);

const isCustomerRejected = computed(
  () => editsCollectionDigitalAsset.value.edit_status === 'customer_rejected'
);

const isEditorRejected = computed(
  () => editsCollectionDigitalAsset.value.edit_status === 'rejected'
);
</script>
<template>
  <BaseInfiniteGalleryCard
    :digital-asset="asset"
    :paged-asset="editsCollectionDigitalAsset"
    :flex-grow="flexGrow"
    :to="to"
    @request-page="page => $emit('request-page', page)"
  >
    <template #statuses>
      <DigitalAssetStatusBadge v-if="isCustomerRejected">
        re-edit
      </DigitalAssetStatusBadge>
      <DigitalAssetStatusBadge v-else-if="isEditorRejected">
        editor rejected
      </DigitalAssetStatusBadge>
      <DigitalAssetStatusBadge v-else-if="isAccepted">
        hidden edit
      </DigitalAssetStatusBadge>
    </template>
  </BaseInfiniteGalleryCard>
</template>
