<template>
  <div>
    <div class="field is-horizontal update-reservation-field">
      <div class="field-label is-normal">
        <label class="label schedule-status-label">schedule status</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control is-expanded">
            <v-select
              v-model:model-value="scheduleStatus"
              :get-option-label="status => `${status}`"
              :options="scheduleStatuses"
              :reduce="scheduleStatuses => scheduleStatuses"
              :clearable="false"
              :searchable="false"
              :disabled="isCompleted"
              :selectable="
                scheduleStatuses =>
                  !scheduleStatuses.includes('en route') &&
                  !scheduleStatuses.includes('ready to go') &&
                  !scheduleStatuses.includes('pre ship')
              "
            ></v-select>
          </div>
        </div>
      </div>
    </div>

    <div class="field is-horizontal update-reservation-field">
      <div class="field-label">
        <label class="label">bay assignment</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control is-expanded">
            <v-select
              v-model:model-value="bookableSpace"
              :get-option-label="
                bs => `${bs.name} -
                ${bs.description}`
              "
              :options="activeBookableSpaces"
              :reduce="activeBookableSpaces => activeBookableSpaces.id"
              :clearable="false"
              :searchable="false"
              :disabled="isCompleted"
            ></v-select>
          </div>
        </div>
      </div>
    </div>

    <div class="soona-datepicker field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">date</label>
      </div>
      <div class="field-body">
        <div
          v-if="currentLocation.name"
          class="field update-reservation-datepicker"
          data-cypress="date-picker"
        >
          <FlatPickr
            ref="datepicker"
            v-model:model-value="startDate"
            placeholder="Date"
            :config="datepickerConfig"
            :disabled="isCompleted"
          />
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">duration</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control is-expanded">
            <v-select
              v-model:model-value="duration"
              :get-option-label="
                duration => `${formatSecondsToHours(duration)}`
              "
              :options="durationOptions"
              :reduce="seconds => seconds"
              :clearable="false"
              :searchable="false"
              :disabled="isCompleted"
            ></v-select>
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal"></div>
      <div class="field-body">
        <div class="field-toggle">
          <SoonaToggle
            v-model:model-value="toggleReschedule"
            :disabled="rescheduleDisabled"
            type="switch"
            label="reschedule option"
          />
          <p v-if="isReschedulable" class="text-s reschedule-url-copy">
            <button class="u-button-reset" @click="copyText(rescheduleUrl)">
              <SoonaIcon name="duplicate" size="medium" />
              <span class="u-a11y-only">copy url</span>
            </button>
            <b v-show="copySuccess" class="copied text-s">copied!</b>
          </p>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">start time</label>
      </div>
      <div class="field-body">
        <div class="time-options">
          <SoonaButton
            v-for="(slot, index) in availableSlots"
            :key="index"
            class="time-slot"
            :on-click="() => slotClicked(slot.slot)"
            :disabled="isCompleted || isSlotUnavailable(slot)"
            variation="secondary-black"
            :copy="slot.slot"
            size="medium"
            :aria-selected="isSlotSelected(slot.slot)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { mapMutations, mapState, mapActions, mapGetters } from 'vuex';
import * as types from 'src/store/mutation-types';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaToggle from 'src/components/ui_library/SoonaToggle.vue';
import { useCopy } from 'src/composables/useCopy';
import FlatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

dayjs.extend(utc);
dayjs.extend(timezone);

export default {
  components: {
    FlatPickr,
    SoonaButton,
    SoonaIcon,
    SoonaToggle,
  },
  setup() {
    const { copyText, copySuccess } = useCopy();

    return {
      copyText,
      copySuccess,
    };
  },
  data() {
    return {
      datepickerConfig: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: 'F j, Y',
        altInput: true,
        dateFormat: 'Y-m-d',
        disable: [
          date => {
            return this.hasDatePassed(date) && !this.isDateSelected(date);
          },
        ],
      },
      scheduleStatuses: [
        'en route',
        'duplicate',
        'needs pre pro',
        'no response',
        'on hold',
        'pre ship',
        'pro service',
        'ready to go',
        'waiting on product',
      ],
      manualScheduleView: false,
      selectedStartTime: null,
    };
  },
  computed: {
    ...mapState({
      selectedStartDate: state => state.reservations.startDate,
      reservation: state => state.reservations.reservation,
      currentLocation: state => state.locations.currentLocation,
      locations: state => state.locations.locations,
      selectedScheduleStatus: state => state.reservations.scheduleStatus,
      bookableSpaces: state => state.locations.currentLocationSpaces,
      calendarStart: state => state.schedule.calendarStart,
      calendarEnd: state => state.schedule.calendarEnd,
      suggestedSlots: state => state.reservations.suggestedSlots,
    }),
    ...mapGetters([
      'isAnySlotSelected',
      'isSlotSelected',
      'isSlotUnavailable',
      'isDateSelected',
      'isBaySelected',
      'isReschedulable',
      'rescheduleUrl',
    ]),
    ...mapGetters({
      reservationDuration: 'duration',
      reservationBookableSpaceIds: 'bookableSpaceIds',
    }),
    activeBookableSpaces() {
      return this.bookableSpaces.filter(space => space.status === 'active');
    },
    toggleReschedule: {
      get() {
        return this.reservation.reschedulable;
      },
      set(bool) {
        this.setIsReschedulable(bool);
      },
    },
    rescheduleDisabled() {
      return this.bookingSpaceUnAssigned || !this.startDate;
    },
    bookingSpaceUnAssigned() {
      const selectedBookableSpaceId = this.bookableSpace[0]; // bookableSpace is an array, so we're checking to see if the first member is the unassigned bay
      return (
        selectedBookableSpaceId ===
        this.activeBookableSpaces.find(bs => bs.name === 'unassigned')?.id
      );
    },
    startDate: {
      get() {
        return this.selectedStartDate;
      },
      set(value) {
        this.setStartDateAndLoadAvailability(value);
      },
    },
    duration: {
      get() {
        return this.reservationDuration;
      },
      set(value) {
        this.setDurationAndLoadAvailability(value);
      },
    },
    scheduleStatus: {
      get() {
        return this.selectedScheduleStatus
          ? this.selectedScheduleStatus.replace(/[_-]/g, ' ')
          : this.selectedScheduleStatus;
      },
      set(value) {
        this.SET_SCHEDULE_STATUS(value.replace(/ /g, '_'));
      },
    },
    bookableSpace: {
      get() {
        return this.reservationBookableSpaceIds[0] !== null
          ? this.reservationBookableSpaceIds
          : this.activeBookableSpaces.find(bs => bs.name === 'unassigned')?.id;
      },
      set(value) {
        this.SET_BOOKABLE_SPACE_ID(value);
      },
    },
    availableSlots() {
      var slots = [];
      for (var i = 7; i <= 21; i++) {
        slots.push({
          available: true,
          slot: `${i % 12 || 12}:00 ${i > 11 ? 'pm' : 'am'}`,
        });
        slots.push({
          available: true,
          slot: `${i % 12 || 12}:30 ${i > 11 ? 'pm' : 'am'}`,
        });
      }
      return slots;
    },
    durationOptions() {
      return [
        1800, 3600, 5400, 7200, 9000, 10800, 12600, 14400, 16200, 18000, 21600,
        25200, 28800, 32400, 36000, 39600, 43200,
      ];
    },
    isCompleted() {
      return this.reservation.order_status === 'completed';
    },
    shootReadiness() {
      return this.reservation.shoot_readiness;
    },
  },
  methods: {
    ...mapMutations([
      types.SET_START_TIME,
      types.SET_SCHEDULE_STATUS,
      types.RESET_START_TIME,
      types.RESET_END_TIME,
      types.SET_BOOKABLE_SPACE_ID,
    ]),
    ...mapActions([
      'setStartDateAndLoadAvailability',
      'setDurationAndLoadAvailability',
      'setIsReschedulable',
    ]),
    slotClicked(slot) {
      if (slot && this.selectedStartDate) {
        this.selectedStartTime = dayjs(
          `${this.selectedStartDate} ${slot}`,
          'YYYY-MM-DD h:mm A'
        ).tz(this.currentLocation.timezone);
      } else if (slot) {
        this.selectedStartTime = slot;
      } else {
        this.selectedStartTime = null;
      }

      this.SET_START_TIME({
        startTime: slot,
        timezone: this.currentLocation.timezone,
      });
    },
    hasDatePassed(date) {
      return dayjs(date).isBefore(dayjs(), 'day');
    },
    formatSecondsToHours(seconds) {
      let hours = seconds / 60 / 60;
      let units = hours == 1 ? 'hour' : 'hours';
      return hours + ' ' + units;
    },
  },
};
</script>
<style lang="scss">
@use '@/variables';

.field-body {
  .field-toggle {
    display: inline-flex;
    align-items: center;
  }
}

.reschedule {
  margin-bottom: 15px;
}

.reschedule-url-copy {
  white-space: nowrap;
  font-size: 16px;

  button {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 0 0.5rem;
  }
}

.copy {
  color: variables.$gray-20;
  cursor: pointer;
  margin-left: 0.25rem;
}

.copied {
  background-color: variables.$white-default;
  border-color: transparent;
  color: variables.$periwink-blue-60;
  border-radius: 2px;
  font-size: 0.75rem;
  border-width: 1px;
  justify-content: center;
  padding-bottom: calc(0.375em - 1px);
  padding-left: 0.75em;
  padding-right: 0.75em;
  padding-top: calc(0.375em - 1px);
  text-align: center;
  white-space: nowrap;
}

.update-reservation-datepicker .soona-textfield {
  padding-bottom: 0;
}

.flatpickr-calendar {
  .flatpickr-day {
    border-radius: variables.$control-radius;
    width: 50%;

    &:hover {
      background: variables.$tangerine-20;
      border-color: variables.$tangerine-20;
      color: variables.$gray-90;
    }

    &.selected {
      background: variables.$tangerine-20;
      border-color: variables.$tangerine-20;
      color: variables.$gray-90;

      &:hover {
        background: variables.$tangerine-20;
        border-color: variables.$tangerine-20;
        color: variables.$gray-90;
      }
    }

    &.flatpickr-disabled:hover {
      background: variables.$gray-10;
      border-color: variables.$gray-10;
      color: variables.$gray-40;
    }
  }
}
</style>

<style lang="scss" scoped>
@use '@/variables';

.time-options {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
}

.time-slot {
  width: 5rem;
  white-space: nowrap;
  /* SoonaButton uses 2px, which is too wide here, so this adds some, but not all */
  letter-spacing: 1px !important;

  &[aria-selected='true'] {
    border: none;
    color: variables.$white-default;
    background-color: variables.$friendly-red-30;
  }

  &[aria-selected='true']:disabled {
    background-color: variables.$white-default;
    border: none;
  }
}
</style>
