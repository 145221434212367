<script setup>
import { computed, onBeforeMount, onMounted } from 'vue';
import { ShareNetwork } from 'vue-social-sharing';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { useTitle } from '@vueuse/core';

import SoonaBackButton from '@/components/ui_library/SoonaBackButton.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import cantBeBoxed from "@images/social/baby-can't-be-boxed-mobile.png";
import colorfulModernist from '@images/social/colorful-modernist-mobile.png';
import creamyAndDreamy from '@images/social/creamy-and-dreamy-mobile.png';
import darkAndStormy from '@images/social/dark-and-stormy-mobile.png';
import naturallyClassic from '@images/social/naturally-classic-mobile.png';
import pixelPerfect from '@images/social/pixel-perfect-mobile.png';
import smoothOperator from '@images/social/smooth-operator-mobile.png';
import timelesslyTextured from '@images/social/timelessly-textured-mobile.png';
import trendsetMindset from '@images/social/trendset-mindset-mobile.png';

const route = useRoute();
const store = useStore();

useTitle('style quiz | soona');

const quizStyleMobilePhotoMap = {
  'colorful modernist': colorfulModernist,
  'creamy and dreamy': creamyAndDreamy,
  'naturally classic': naturallyClassic,
  'pixel perfect': pixelPerfect,
  'smooth operator': smoothOperator,
  'timelessly textured': timelesslyTextured,
  'trendset mindset': trendsetMindset,
  'dark and stormy': darkAndStormy,
  "baby can't be boxed": cantBeBoxed,
};

const account = computed(() => store.state.account);
const quizStyle = computed(() => store.state.styleQuiz.quizStyle || {});
const quizStyleMobilePhoto = computed(
  () => quizStyleMobilePhotoMap[quizStyle.value?.title]
);

const quizPhotos = (columns, column) => {
  if (quizStyle.value?.quiz_photos) {
    return quizStyle.value.quiz_photos.filter(
      (val, i) => i % columns === column
    );
  }

  return [];
};

// lifecycle
onBeforeMount(() => {
  store.dispatch('styleQuiz/loadQuizStyle', route.params.quizStyleId);
});

onMounted(() => {
  window.scrollTo(0, 0);
});
</script>

<template>
  <div class="quiz-results">
    <section class="results-wrapper">
      <p class="results-icon">
        {{ quizStyle.icon }}
      </p>
      <div>
        <h1 class="u-body--heavy" data-cypress="text-results-title">
          {{ quizStyle.title }}
        </h1>
        <p>{{ quizStyle.description }}</p>
      </div>
    </section>
    <section>
      <h3 class="share-heading">share your results:</h3>
      <div v-if="quizStyle.title" class="social-container">
        <strong>share to</strong>
        <ShareNetwork
          class="social-share"
          network="facebook"
          :url="`https://book.soona.co/style_quiz/${quizStyle.id}`"
          :quote="`I took the soona style quiz and my brand style is in. I got ${quizStyle.title}! You can uncover your brand’s visual style too!`"
          :title="`Check out my style quiz results!`"
        >
          <SoonaIcon name="facebook-logo" />
        </ShareNetwork>
        <ShareNetwork
          class="social-share"
          network="twitter"
          :url="`https://book.soona.co/style_quiz/${quizStyle.id}`"
          :title="`I took the soona style quiz and my brand style is in. I got ${quizStyle.title}! You can uncover your brand’s visual style too!`"
          twitter-user="soonastudios"
        >
          <SoonaIcon name="x-logo" />
        </ShareNetwork>
        <strong>download for</strong>
        <a class="social-share" target="_blank" :href="quizStyleMobilePhoto">
          <SoonaIcon name="instagram-logo" />
        </a>
      </div>
    </section>
    <section class="images-section" data-cypress="section-images">
      <div v-if="quizPhotos(1, 0).length <= 4" class="flex-wrapper">
        <div v-for="n in 2" :key="`photo-col-${n}`" class="flex-column">
          <img
            v-for="photo in quizPhotos(2, n - 1)"
            :key="photo.id"
            class="result-image"
            :style="`width: ${Math.floor(
              Math.random() * 25 + (photo.width > photo.height ? 75 : 50)
            )}%;`"
            :src="photo.image_url"
          />
        </div>
      </div>
      <div v-else class="flex-wrapper">
        <div class="flex-column">
          <img
            v-for="photo in quizPhotos(3, 0)"
            :key="photo.id"
            class="result-image"
            :style="`width: ${Math.floor(
              Math.random() * 25 + (photo.width > photo.height ? 75 : 50)
            )}%;`"
            :src="photo.image_url"
          />
        </div>
        <div class="flex-column">
          <img
            v-for="photo in quizPhotos(3, 1)"
            :key="photo.id"
            class="result-image"
            style="width: calc(100% - 1em)"
            :src="photo.image_url"
          />
        </div>
        <div class="flex-column">
          <img
            v-for="photo in quizPhotos(3, 2)"
            :key="photo.id"
            class="result-image"
            :style="`width: ${Math.floor(
              Math.random() * 25 + (photo.width > photo.height ? 75 : 50)
            )}%;`"
            :src="photo.image_url"
          />
        </div>
      </div>
    </section>
    <div class="quiz-buttons">
      <SoonaButton
        element="router-link"
        copy="take the quiz again!"
        variation="secondary-black"
        :to="{ name: 'style-quiz' }"
        data-cypress="link-retake-style-quiz"
      />
      <SoonaButton element="router-link" copy="create now" to="/booking/new" />
    </div>
    <div v-if="account?.id" class="back-to-profile-container">
      <SoonaBackButton
        element="router-link"
        :to="`/account/${account.id}/profile`"
        button-text="profile"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.quiz-results {
  margin: 0 auto;
  max-width: 62.5rem;
  padding: 1rem;
}

.results-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 56.25rem;
  padding-bottom: 3rem;
}

.results-icon {
  align-items: center;
  background: variables.$bubbletape-pink-10;
  border-radius: 50%;
  display: flex;
  flex-shrink: 0;
  font-size: 4rem;
  height: 9.375rem;
  justify-content: center;
  padding: 0.5rem;
  width: 9.375rem;
}

.share-heading {
  @include variables_fonts.u-body--all-caps-black;

  padding-bottom: 0.75rem;
  text-align: center;
  text-decoration: underline;
}

.social-container {
  align-items: center;
  display: flex;
  gap: 0.75rem;
  justify-content: center;
  padding-bottom: 0.75rem;
}

.social-share {
  align-items: center;
  display: flex;
  justify-content: center;
}

.flex-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  margin: 0 auto;
}

.flex-column {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
}

.result-image {
  border-radius: 0.25rem;
}

.quiz-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.5rem;
  margin: 2rem 0;
}

.back-to-profile-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media (min-width: variables.$screen-sm-min) {
  .results-wrapper {
    flex-direction: row;
  }

  .flex-wrapper {
    flex-direction: row;
  }

  .flex-column {
    align-items: flex-start;

    &:first-child {
      align-items: flex-end;
    }
  }
}
</style>
