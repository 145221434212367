<script setup>
import { computed, onMounted, ref, watchEffect } from 'vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useRoute, useRouter } from 'vue-router';
import { useTitle } from '@vueuse/core';
import { useInventorySection } from '@/components/user/anytime/products/inventory/useInventorySection';
import ProductInventoryList from '@/components/user/anytime/products/inventory/ProductInventoryList.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';
import SoonaNoResults from '@/components/ui_library/SoonaNoResults.vue';
import productDisplayImg from 'images/empty-states/product-display.jpg';
import SoonaFilterSingle from '@/components/ui_library/SoonaFilterSingle.vue';

const props = defineProps({
  accountId: { type: String, required: true },
});
const emit = defineEmits(['inventoryQueryParams']);

useTitle('account inventory | soona');

const accountId = computed(() => props.accountId);

const router = useRouter();
const route = useRoute();

const filterOptions = ['all', 'pending', 'stored', 'returned', 'discarded'];
const filteredStatus = ref(
  filterOptions.includes(route.query.filter) ? route.query.filter : 'all'
);

function clearFilters() {
  filteredStatus.value = 'all';
}

watchEffect(() => {
  const queryObj = {
    filter: filteredStatus.value,
  };

  router.replace({
    query: queryObj,
  });

  emit('inventoryQueryParams', queryObj);
});

const { pageViewed } = useBaseEvents();

onMounted(() => {
  pageViewed();
});

// pending
const {
  currentPage: pendingCurrentPage,
  isLoading: pendingIsLoading,
  isShown: pendingIsShown,
  itemsPerPage: pendingItemsPerPage,
  packages: pendingPackages,
  packagesPagination: pendingPackagesPagination,
  priorityError: pendingPriorityError,
} = useInventorySection({
  accountId,
  filteredStatus: 'pending',
  enabled: computed(
    () => filteredStatus.value === 'all' || filteredStatus.value === 'pending'
  ),
});

// stored
const {
  currentPage: storedCurrentPage,
  isLoading: storedIsLoading,
  isShown: storedIsShown,
  itemsPerPage: storedItemsPerPage,
  packages: storedPackages,
  packagesPagination: storedPackagesPagination,
  priorityError: storedPriorityError,
} = useInventorySection({
  accountId,
  filteredStatus: 'fast_pass',
  enabled: computed(
    () => filteredStatus.value === 'all' || filteredStatus.value === 'stored'
  ),
});

// returned
const {
  currentPage: returnedCurrentPage,
  isLoading: returnedIsLoading,
  isShown: returnedIsShown,
  itemsPerPage: returnedItemsPerPage,
  packages: returnedPackages,
  packagesPagination: returnedPackagesPagination,
  priorityError: returnedPriorityError,
} = useInventorySection({
  accountId,
  filteredStatus: 'returned',
  enabled: computed(
    () => filteredStatus.value === 'all' || filteredStatus.value === 'returned'
  ),
});

// discarded
const {
  currentPage: discardedCurrentPage,
  isLoading: discardedIsLoading,
  isShown: discardedIsShown,
  itemsPerPage: discardedItemsPerPage,
  packages: discardedPackages,
  packagesPagination: discardedPackagesPagination,
  priorityError: discardedPriorityError,
} = useInventorySection({
  accountId,
  filteredStatus: 'discarded',
  enabled: computed(
    () => filteredStatus.value === 'all' || filteredStatus.value === 'discarded'
  ),
});

// aggregated display logic
const isLoading = computed(
  () =>
    pendingIsLoading.value ||
    storedIsLoading.value ||
    returnedIsLoading.value ||
    discardedIsLoading.value
);

const showNoData = computed(() => {
  return (
    !pendingIsShown.value &&
    !storedIsShown.value &&
    !returnedIsShown.value &&
    !discardedIsShown.value
  );
});
</script>

<template>
  <div class="product-inventory">
    <div class="product-inventory__filter-section">
      <SoonaFilterSingle v-model="filteredStatus" :options="filterOptions" />
    </div>

    <div v-if="isLoading" class="product-inventory__loading-wrapper">
      <SoonaLoading is-loading loading-text="loading" is-contained />
    </div>
    <SoonaNoResults
      v-else-if="filteredStatus === 'all' && showNoData"
      mask="blob-empty"
      :image-source="productDisplayImg"
    >
      <template #header>we’ve got room at the studio!!</template>
      <template #body> items you send to soona will be shown here. </template>
      <template #button>
        <SoonaButton
          element="a"
          href="/#/booking/new"
          copy="book now"
          variation="primary"
        />
      </template>
    </SoonaNoResults>
    <SoonaNoResults v-else-if="showNoData">
      <template #header>oops, we couldn’t find a match!</template>
      <template #body> try a different search or filter combination </template>
      <template #button>
        <SoonaButton
          copy="reset filters"
          variation="secondary-black"
          :on-click="clearFilters"
        />
      </template>
    </SoonaNoResults>
    <template v-else>
      <ProductInventoryList
        v-if="pendingIsShown"
        v-model:current-page="pendingCurrentPage"
        v-model:items-per-page="pendingItemsPerPage"
        heading="pending"
        :total-pages="pendingPackagesPagination.totalPages"
        :total-count="pendingPackagesPagination.totalCount"
        :packages="pendingPackages"
        :priority-error="pendingPriorityError"
      />
      <ProductInventoryList
        v-if="storedIsShown"
        v-model:current-page="storedCurrentPage"
        v-model:items-per-page="storedItemsPerPage"
        heading="stored"
        :total-pages="storedPackagesPagination.totalPages"
        :total-count="storedPackagesPagination.totalCount"
        :packages="storedPackages"
        :priority-error="storedPriorityError"
      />
      <ProductInventoryList
        v-if="returnedIsShown"
        v-model:current-page="returnedCurrentPage"
        v-model:items-per-page="returnedItemsPerPage"
        heading="returned"
        :total-pages="returnedPackagesPagination.totalPages"
        :total-count="returnedPackagesPagination.totalCount"
        :packages="returnedPackages"
        :priority-error="returnedPriorityError"
      />
      <ProductInventoryList
        v-if="discardedIsShown"
        v-model:current-page="discardedCurrentPage"
        v-model:items-per-page="discardedItemsPerPage"
        heading="discarded"
        :total-pages="discardedPackagesPagination.totalPages"
        :total-count="discardedPackagesPagination.totalCount"
        :packages="discardedPackages"
        :priority-error="discardedPriorityError"
      />
    </template>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.product-inventory {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  color: variables.$black-default;

  &__filter-section {
    background-color: variables.$periwink-blue-10;
    border: 0.0625rem solid variables.$gray-30;
    border-radius: 0 0 0.625rem 0.625rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__loading-wrapper {
    position: relative;
    min-height: 10rem;
    color: variables.$black-default;
  }
}

@media (min-width: variables.$screen-lg-min) {
  .product-inventory {
    gap: 2.5rem;
  }
}
</style>
