<script setup>
import { computed, ref } from 'vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { toCurrency } from '@/lib/currency';
import basicImage from '@images/subscriptions/basic_graphic@2x.png';
import businessImage from '@images/subscriptions/business_graphic@2x.png';
import proImage from '@images/subscriptions/pro_graphic@2x.png';
import BillingCycleOption from '@/components/modal-payment-flows/subscriptions/BillingCycleOption.vue';
import FeatureList from '@/components/subscriptions/FeatureList.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';
import StepLayout from '@/components/modal-payment-flows/subscriptions/StepLayout.vue';
import TrialTimeline from '@/components/modal-payment-flows/subscriptions/TrialTimeline.vue';
import { AvoToast60 } from 'src/variables.module.scss';

const props = defineProps({
  context: {
    default: null,
    type: String,
  },
  isLoading: {
    default: false,
    type: Boolean,
  },
  price: {
    required: true,
    type: Object,
  },
  selectedTier: {
    required: true,
    type: Object,
  },
  showBackButton: {
    default: false,
    type: Boolean,
  },
  subContext: {
    required: true,
    type: String,
  },
  offerTrial: {
    default: false,
    type: Boolean,
  },
});

const emits = defineEmits(['back', 'close', 'next', 'update:price']);

const { inputChanged, linkClicked } = useBaseEvents();

const imageMap = {
  'tier-one': basicImage,
  'tier-two': proImage,
  'tier-three': businessImage,
};

const trialDays = ref(30);

const selectedTier = computed(() => props.selectedTier);
const showBackButton = computed(() => props.showBackButton);
const tierTitle = computed(() => selectedTier.value?.name);
const tierIcon = computed(() => selectedTier.value?.product.icon_name);
const tierImage = computed(() => imageMap[selectedTier.value?.slug]);
const tierRightColumnHeading = computed(
  () => selectedTier.value?.product?.right_column_heading
);
const tierRightColumnSubheading = computed(
  () => selectedTier.value?.product?.right_column_subheading
);
const tierSlug = computed(() => props.selectedTier?.slug);

const trialFeatureList = computed(() => [
  {
    details: `free ${trialDays.value} day trial`,
    icon: 'check',
    iconColor: AvoToast60,
  },
  {
    details: `we'll remind you before your trial ends`,
    icon: 'check',
    iconColor: AvoToast60,
  },
]);

const handleNextBtnClick = () => {
  linkClicked({
    context: props.context,
    subContext: props.subContext,
    linkLabel: 'next',
    linkHref: null,
  });
  emits('next');
};

const handleChange = price => {
  emits('update:price', price);

  inputChanged({
    context: props.context,
    subContext: props.subContext,
    inputLabel: `${price.recurring_interval}ly`,
    inputType: 'radio',
    inputValue: price,
  });
};

const headingCopy = computed(() => {
  if (props.offerTrial) return `try soona ${tierTitle.value} for free`;

  return `soona ${tierTitle.value} subscription`;
});
</script>

<template>
  <StepLayout
    class="billing-cycle-step"
    :show-back-button="showBackButton"
    :tier-icon="tierIcon"
    :tier-slug="tierSlug"
    :tier-title="tierTitle"
    :right-content-alignment="offerTrial ? 'flex-start' : 'center'"
    :right-content-justification="offerTrial ? 'flex-start' : 'center'"
    :right-content-padding="offerTrial ? '7rem 4rem' : '4rem'"
    @back="emits('back')"
    @close="emits('close')"
    @next="emits('next')"
  >
    <template #heading>{{ headingCopy }}</template>
    <template v-if="!offerTrial" #subheading>
      choose your billing cycle
    </template>
    <SoonaLoading v-if="isLoading" is-loading is-contained />
    <FeatureList
      v-if="offerTrial"
      :feature-list="trialFeatureList"
      :has-no-gap="true"
      :has-no-grow="true"
    />
    <div class="billing-cycle-step__option-container">
      <BillingCycleOption
        v-for="priceOption in selectedTier?.product?.prices"
        :key="priceOption.id"
        :price="priceOption"
        :selected-billing-interval="price.recurring_interval"
        @change="handleChange"
      />
    </div>
    <FeatureList
      v-if="!offerTrial"
      :feature-list="selectedTier?.product?.short_feature_list"
    />
    <div class="billing-cycle-step__action-btns">
      <p v-if="offerTrial" class="billing-cycle-step__total">
        <span class="u-subheader--heavy">
          due today
          <span v-if="offerTrial" class="trial-days-free">
            ({{ trialDays }} days free)
          </span>
        </span>
        <span
          class="u-headline--heavy"
          data-cypress="text-subscriptions-dialog-total"
        >
          {{ toCurrency(offerTrial ? 0 : price?.unit_amount, 'USD', 0) }}
        </span>
      </p>
      <SoonaButton
        copy="next"
        variation="solid-black"
        data-cypress="button-subscriptions-dialog-continue"
        @on-click="handleNextBtnClick"
      />
    </div>
    <template v-if="offerTrial" #main-image>
      <TrialTimeline />
    </template>
    <template v-else #main-image>
      <img
        class="billing-cycle-step__star-top"
        src="@images/subscriptions/north_star.svg"
        alt=""
      />
      <img
        class="billing-cycle-step__star-bottom"
        src="@images/subscriptions/north_star.svg"
        alt=""
      />
      <img :src="tierImage" alt="" />
    </template>
    <template v-if="!offerTrial" #right-col-content>
      <div class="billing-cycle-step__right-column-content">
        <p class="u-display--heavy billing-cycle-step__right-column-heading">
          {{ tierRightColumnHeading }}
        </p>
        <p class="u-body--regular">{{ tierRightColumnSubheading }}</p>
      </div>
    </template>
  </StepLayout>
</template>

<style lang="scss" scoped>
@use '@/variables';

.billing-cycle-step {
  &__option-container {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    padding-bottom: 2rem;
  }

  &__total {
    display: flex;
    justify-content: space-between;

    .trial-days-free {
      color: variables.$avo-toast-60;
    }
  }

  &__action-btns {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: auto;
  }

  &__star-top {
    position: absolute;
    left: 1rem;
    top: 2rem;
    height: 4.9375rem !important;
    width: 4.9375rem !important;
  }

  &__star-bottom {
    position: absolute;
    right: 1.5rem;
    bottom: 3rem;
    height: 5.625rem !important;
    width: 5.625rem !important;
  }

  &__right-column-content {
    color: variables.$white-default;
  }

  &__right-column-heading {
    padding-bottom: 0.25rem;
  }
}
</style>
