<script setup>
import { computed } from 'vue';
import uniqueId from 'lodash/uniqueId';

const props = defineProps({
  background: {
    type: String,
    required: false,
  },
  min: {
    type: Number,
    default: 1,
  },
  max: {
    type: Number,
    default: 100,
  },
  value: {
    type: Number,
    required: true,
  },
  step: {
    type: Number,
    default: 1,
  },
  label: {
    type: String,
    default: undefined,
  },
  hideLabel: {
    type: Boolean,
    default: true,
  },
  trackHeightRem: {
    type: Number,
    default: 1,
  },
  thumbSizeRem: {
    type: Number,
    default: 1.5,
  },
});

const emits = defineEmits(['update:value']);

const id = uniqueId('soona-range-');

const background = computed(() => props.background || '');

const percentComplete = computed(() => {
  return `${((props.value - props.min) * 100) / (props.max - props.min)}%`;
});

function updateValue(event) {
  emits('update:value', Number(event.target.value));
}

const trackHeight = computed(() => props.trackHeightRem + 'rem');
const trackBorderRadius = computed(() => props.trackHeightRem / 2 + 'rem');
const thumbSizeRem = computed(() => props.thumbSizeRem + 'rem');
</script>
<template>
  <div class="soona-range">
    <label v-if="label" :class="{ 'u-a11y-only': hideLabel }" :for="id">
      {{ label }}
    </label>
    <input
      :id="id"
      type="range"
      :min="min"
      :max="max"
      :step="step"
      :value="value"
      :style="{ background }"
      @input="updateValue"
    />
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables';

.soona-range {
  width: 100%;

  input[type='range'] {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: v-bind('trackHeight');
    background: variables.$gray-20;
    border-radius: v-bind('trackBorderRadius');
    background-image: linear-gradient(
      variables.$periwink-blue-50,
      variables.$periwink-blue-50
    );
    background-repeat: no-repeat;
    background-size: v-bind('percentComplete') 100%;
    position: relative;

    @mixin thumb {
      box-sizing: border-box;
      -webkit-appearance: none;
      appearance: none;
      height: v-bind('thumbSizeRem');
      width: v-bind('thumbSizeRem');
      border-radius: 50%;
      background: variables.$white-default;
      border: 0.125rem solid variables.$gray-30;
      cursor: ew-resize;
      position: relative;
      z-index: 1;
    }

    &::-webkit-slider-thumb {
      @include thumb;
    }
    &::-moz-range-thumb {
      @include thumb;
    }
    &::-ms-thumb {
      @include thumb;
    }

    @mixin track {
      -webkit-appearance: none;
      appearance: none;
      box-shadow: none;
      border: none;
      background: transparent;
    }

    &::-webkit-slider-runnable-track {
      @include track;
    }
    &::-moz-range-track {
      @include track;
    }
    &::-ms-track {
      @include track;
    }

    &:focus-visible {
      outline: 0.125rem variables.$black-default solid;
    }
  }
}
</style>
