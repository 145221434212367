<script setup>
import { toRefs, onMounted, provide, ref } from 'vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import AIEditorIframe from '@/components/user/anytime/gallery/media-editor/media-multiplier/ai/AIEditorIframe.vue';
import { TELEPORT_TO } from '@/composables/useTeleportTo';
import { onKeyStroke } from '@vueuse/core';
import MediaHeaderBase from '@/components/user/anytime/gallery/media-editor/action-bars/MediaHeaderBase.vue';
import AvailableMokkerCredits from '@/components/user/anytime/gallery/media-editor/AvailableMokkerCredits.vue';
import SubscriptionsDialog from '@/components/modal-payment-flows/subscriptions/SubscriptionsDialog.vue';
import { MEDIA_POPOVER_BACK_KEY } from '@/components/user/anytime/gallery/media-editor-routing-keys';
import { useRouter } from 'vue-router';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
  iframeUrl: {
    type: String,
    required: true,
  },
  backUrl: {
    type: Object,
    required: true,
  },
});

const { accountId, iframeUrl, backUrl } = toRefs(props);

provide(MEDIA_POPOVER_BACK_KEY, backUrl);

const popoverRef = ref();
provide(TELEPORT_TO, popoverRef);

const iframeLoaded = ref(false);

const router = useRouter();
onKeyStroke('Escape', () => {
  router.push(backUrl);
});

const showPaywallDialog = ref(false);

const { pageViewed } = useBaseEvents();
onMounted(() => {
  pageViewed();
});
</script>

<template>
  <Teleport to="body">
    <div
      ref="popoverRef"
      class="ai-studio-iframe-dialog"
      role="dialog"
      aria-modal="true"
    >
      <div class="ai-studio-iframe-container">
        <MediaHeaderBase is-wide>
          AI studio
          <template #actions>
            <AvailableMokkerCredits
              v-if="iframeLoaded"
              :account-id="accountId"
              @show-paywall-dialog="() => (showPaywallDialog = true)"
            />
          </template>
        </MediaHeaderBase>

        <div class="ai-studio-iframe-container__iframe-wrapper">
          <AIEditorIframe
            :is-contained="true"
            :iframe-url="iframeUrl"
            @close-iframe="setIframeUrl(null)"
            @iframe-loaded="iframeLoaded = true"
            @show-paywall-dialog="showPaywallDialog = true"
          />
        </div>
      </div>
    </div>
    <SubscriptionsDialog
      v-if="showPaywallDialog"
      class="ai-studio-templates__paywall-dialog"
      selected-tier-slug="tier-one"
      @close="() => (showPaywallDialog = false)"
    />
  </Teleport>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.ai-studio-iframe-dialog {
  background-color: variables.$gray-10;
  display: flex;
  flex-direction: column;
  position: fixed;
  inset: 0;
  animation:
    0.25s ease-out both scale-up,
    0.15s ease-out both k-fade-in;

  @media (prefers-reduced-motion: reduce) {
    animation: 0.15s ease-out both k-fade-in;
  }

  &--closing {
    animation:
      0.15s ease-out both scale-down,
      0.15s ease-out both k-fade-out;

    @media (prefers-reduced-motion: reduce) {
      animation: 0.15s ease-out both k-fade-out;
    }
  }

  :deep(.subscriptions-dialog) {
    z-index: 20;
  }

  :deep(.soona-dialog) {
    z-index: 20;
  }
}

.ai-studio-iframe-container {
  flex: 1 1 0;
  display: flex;
  flex-direction: column;

  &__iframe-wrapper {
    flex: 1;
    position: relative;
  }
}
</style>
