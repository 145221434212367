<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaError from 'src/components/ui_library/SoonaError.vue';
import { useUpdateReservationFile } from '@/queries/reservation-files/useUpdateReservationFile';

const props = defineProps({
  reservationFile: Object,
});

const reservationFile = computed(() => props.reservationFile);
const { mutate, isLoading, error } = useUpdateReservationFile(reservationFile);

const store = useStore();

const restore = () => {
  mutate(
    { edit_status: 'pending_edit' },
    {
      onSuccess: async () => {
        await store.dispatch(
          'reservation/loadOrders',
          reservationFile.value.reservation_id
        );
      },
    }
  );
};
</script>
<template>
  <SoonaError v-if="error">
    {{ error }}
  </SoonaError>
  <SoonaButton
    copy="show hidden edit"
    size="medium"
    variation="tertiary"
    :disabled="isLoading"
    :on-click="restore"
  >
    <template #icon-left>
      <SoonaIcon
        name="flip-backward"
        size="small"
        style="vertical-align: middle"
      />
    </template>
  </SoonaButton>
</template>
<style lang="scss"></style>
