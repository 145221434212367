import { computed } from 'vue';

export const useInvalidAssetsForGifSelected =
  selectedReservationDigitalAssets => {
    const invalidAssetsForGifSelected = computed(() => {
      return selectedReservationDigitalAssets.value.some(
        rda =>
          rda.digital_asset.media_type !== 'photo' ||
          rda.digital_asset.visibility !== 'all'
      );
    });

    const moreThanMaxFramesSelected = computed(() => {
      return selectedReservationDigitalAssets.value.length > 4;
    });
    return { invalidAssetsForGifSelected, moreThanMaxFramesSelected };
  };
