import { computed } from 'vue';
import { useGetIntegrations } from '@/queries/useGetIntegrations';
import {
  isActiveAmazonIntegration,
  isActiveShopifyIntegration,
  isAmazonIntegration,
  isShopifyIntegration,
} from 'src/components/user/anytime/products/integration-utils.js';

export const useIntegrations = (accountId, currentIntegrationId = null) => {
  const {
    data: integrationsData,
    isLoading: integrationsLoading,
    isSuccess: integrationsSuccess,
    error: integrationsError,
  } = useGetIntegrations(accountId, {
    enabled: computed(() => !!accountId.value),
  });

  const platformByType = type => {
    switch (type) {
      case 'AmazonIntegration':
        return 'Amazon';
      case 'ShopifyIntegration':
        return 'Shopify';
      default:
        return '';
    }
  };

  const integrations = computed(() => integrationsData.value ?? []);

  const activeIntegrations = computed(() =>
    integrations.value.filter(integration => integration.status === 'active')
  );

  const hasShopifyIntegration = computed(() =>
    integrations.value.some(isActiveShopifyIntegration)
  );

  const shopifyIntegrations = computed(() =>
    integrations.value.filter(isShopifyIntegration)
  );

  const activeShopifyIntegrations = computed(() =>
    integrations.value.filter(isActiveShopifyIntegration)
  );

  const hasActiveShopifyIntegration = computed(
    () => activeShopifyIntegrations.value.length > 0
  );

  const hasAmazonIntegration = computed(() =>
    integrations.value.some(isActiveAmazonIntegration)
  );

  const amazonIntegrations = computed(() =>
    integrations.value.filter(isActiveAmazonIntegration)
  );

  const activeAmazonIntegrations = computed(() =>
    integrations.value.filter(isActiveAmazonIntegration)
  );

  const hasActiveAmazonIntegration = computed(
    () => activeAmazonIntegrations.value.length > 0
  );

  const actionableIntegrations = computed(() =>
    integrations.value.filter(integration => {
      if (integration.type === 'ShopifyIntegration') {
        return integration.status === 'active';
      } else if (integration.type === 'AmazonIntegration') {
        return ['active', 'invalid_state'].includes(integration.status);
      }
    })
  );

  const hasActionableIntegrations = computed(
    () => actionableIntegrations.value.length > 0
  );

  const hasActionableAmazonIntegration = computed(() =>
    actionableIntegrations.value.some(isAmazonIntegration)
  );

  const primaryIntegration = computed(
    () =>
      integrations.value.find(integration => integration.primary) ??
      integrations.value[0]
  );

  const primaryPlatformName = computed(() => {
    if (!primaryIntegration.value) return '';

    return platformByType(primaryIntegration.value.type);
  });

  const currentIntegrationType = computed(() => {
    if (!currentIntegrationId?.value) {
      return null;
    }
    return integrations.value.find(
      integration => integration.id == currentIntegrationId.value
    )?.type;
  });

  const currentPlatformName = computed(() => {
    if (!currentIntegrationType.value) {
      return '';
    }

    return platformByType(currentIntegrationType.value);
  });

  const hasActiveAmazonOrShopifyIntegration = computed(
    () => hasActiveAmazonIntegration.value || hasActiveShopifyIntegration.value
  );

  return {
    integrations,
    activeIntegrations,
    integrationsLoading,
    integrationsSuccess,
    integrationsError,
    hasShopifyIntegration,
    shopifyIntegrations,
    activeShopifyIntegrations,
    hasAmazonIntegration,
    amazonIntegrations,
    activeAmazonIntegrations,
    hasActiveAmazonIntegration,
    hasActiveAmazonOrShopifyIntegration,
    currentIntegrationType,
    currentPlatformName,
    primaryIntegration,
    primaryPlatformName,
    actionableIntegrations,
    hasActionableIntegrations,
    hasActionableAmazonIntegration,
    hasActiveShopifyIntegration,
  };
};
