import { unref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from './query-keys';
import { getProServiceProfiles } from '../api/proServiceProfiles';

/**
 *
 * @param {Object} [params]
 * @param {string | number} [params.proServiceTypeId]
 * @param {Object} [params.availability]
 * @param {string | number} [params.ProServiceCategoryId]
 * @param {Array<number>} [params.locationIds]
 * @param {string} [params.query]
 * @param {Array<object>} [params.filters]
 * @param {boolean} [params.isActive]
 * @param {string} [params.sort]
 * @param {number} [params.currentPage]
 * @param {number} [params.itemsPerPage]
 * @param {AbortSignal} [signal]
 * @param {UseQueryOptions} queryOptions
 */
export function useGetProServiceProfiles(
  {
    proServiceTypeId,
    availability,
    proServiceCategoryId,
    locationIds,
    query,
    filters,
    isActive,
    sort,
    currentPage,
    itemsPerPage,
  } = {},
  queryOptions
) {
  return useQuery({
    queryKey: queryKeys.proServiceProfiles({
      proServiceTypeId,
      availability,
      proServiceCategoryId,
      locationIds,
      query,
      filters,
      isActive,
      sort,
      currentPage,
      itemsPerPage,
    }),
    queryFn: ({ signal }) =>
      getProServiceProfiles(
        {
          proServiceTypeId: unref(proServiceTypeId),
          availability: unref(availability),
          proServiceCategoryId: unref(proServiceCategoryId),
          locationIds: unref(locationIds),
          query: unref(query),
          filters: unref(filters),
          isActive: unref(isActive),
          sort: unref(sort),
          currentPage: unref(currentPage),
          itemsPerPage: unref(itemsPerPage),
        },
        signal
      ),
    ...queryOptions,
  });
}
