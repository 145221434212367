<script setup>
import { computed } from 'vue';

const props = defineProps({
  addOn: {
    required: true,
    type: Object,
  },
});

const collection = computed(() => props.addOn.collection);

const speed = computed(() => {
  const speed = collection.value.options.speed;

  if (!speed) return;

  return `${speed} ms, ${(1000 / speed).toFixed(1)} frames/s`;
});
</script>
<template>
  <div class="columns-wrapper">
    <img
      :src="collection.preview_url"
      :alt="`gif created at ${collection.created_at}`"
    />
  </div>
  <div class="notes-wrapper">
    <h2 class="section-title">notes</h2>
    <p>{{ collection.note }}</p>
  </div>
  <div class="add-ons-wrapper">
    <h2 class="section-title">add-ons</h2>
    <ul class="add-on-items">
      <li
        v-for="mediaAddOn in addOn.media_add_ons"
        :key="mediaAddOn.id"
        class="add-on-item is-size-7"
      >
        {{ mediaAddOn.name }} ({{
          Number(mediaAddOn.total).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })
        }})
      </li>
    </ul>
    <h2 class="section-title">options</h2>
    <dl class="gif-options">
      <dt>dimensions:</dt>
      <dd>{{ collection.options.dimensions }}</dd>
      <dt>speed:</dt>
      <dd>{{ speed }}</dd>
      <dt>format(s):</dt>
      <dd>
        {{ collection.options.format.join('/') }} [{{
          collection.options.direction
        }}]
      </dd>
    </dl>
    <router-link
      :to="{
        name: 'collection-gallery',
        params: {
          collectionId: collection.id,
        },
      }"
    >
      view in gallery
    </router-link>
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables_fonts';

.section-title {
  @include variables_fonts.u-label--heavy;
}

.notes-wrapper {
  margin: 0.25rem;
}

.add-ons-wrapper {
  margin: 0.25rem;
}

.columns-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

dl.gif-options {
  dt {
    @include variables_fonts.u-label--heavy;
  }

  dd {
    margin-bottom: 0.25rem;
  }
}
</style>
