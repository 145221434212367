import { unref, toRef } from 'vue';
import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { updateReservationFile } from '@/api/reservationFiles';

/**
 *
 * @param {object | Ref<object>} reservationFile
 */
export function useUpdateReservationFile(reservationFile) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: body =>
      updateReservationFile({
        reservationId: unref(reservationFile).reservation_id,
        reservationFileId: unref(reservationFile).id,
        body,
      }),
    onSuccess: async () => {
      const promises = [
        queryClient.invalidateQueries({
          queryKey: queryKeys.reservationFiles(),
        }),
        queryClient.invalidateQueries({
          queryKey: queryKeys.reservationFile(
            toRef(unref(reservationFile).reservation_id),
            toRef(unref(reservationFile).id)
          ),
        }),
      ];
      if (unref(reservationFile).in_bag) {
        promises.push(
          queryClient.invalidateQueries({ queryKey: queryKeys.bag() })
        );
      }

      await Promise.all(promises);
    },
  });
}
