import { unref } from 'vue';
import { useQuery, useMutation, useQueryClient } from '@tanstack/vue-query';
import {
  getListings,
  getListing,
  getListingInsights,
  getActiveListing,
  refreshListing,
} from 'src/api/listings';
import { queryKeys } from 'src/queries/query-keys';

/**
 *
 * @param {string | number} accountId
 * @param {Object} params
 * @param {string | number} [params.integrationId]
 * @param {string | null} [params.query]
 * @param {string | null} [params.scoreCategory]
 * @param {string} [params.orderBy]
 * @param {string} [params.direction]
 * @param {number} [params.itemsPerPage]
 * @param {number} [params.currentPage]
 * @param {UseQueryOptions} queryOptions
 */
export function useListings(
  accountId,
  {
    integrationId,
    query,
    scoreCategory,
    orderBy,
    direction,
    itemsPerPage,
    currentPage,
    listingActionSlug,
    listingRuleSlug,
    listingRuleId,
  } = {},
  queryOptions
) {
  return useQuery({
    queryKey: queryKeys.listings(accountId, {
      integrationId,
      query,
      scoreCategory,
      orderBy,
      direction,
      itemsPerPage,
      currentPage,
      listingActionSlug,
      listingRuleSlug,
      listingRuleId,
    }),
    queryFn: ({ signal }) =>
      getListings(
        unref(accountId),
        {
          integrationId: unref(integrationId),
          query: unref(query),
          scoreCategory: unref(scoreCategory),
          orderBy: unref(orderBy),
          direction: unref(direction),
          itemsPerPage: unref(itemsPerPage),
          currentPage: unref(currentPage),
          listingActionSlug: unref(listingActionSlug),
          listingRuleSlug: unref(listingRuleSlug),
          listingRuleId: unref(listingRuleId),
        },
        signal
      ),
    ...queryOptions,
  });
}

/**
 *
 * @param {string | number} accountId
 * @param {string | number} listingId
 */
export function useListing(accountId, listingId, queryOptions = null) {
  return useQuery({
    queryKey: queryKeys.listing(accountId, listingId),
    queryFn: ({ signal }) =>
      getListing(unref(accountId), unref(listingId), signal),
    ...queryOptions,
  });
}

/**
 *
 * @param {string | number} integrationId
 * @param {string | number} externalProductId
 */
export function useActiveListing(
  integrationId,
  externalProductId,
  queryOptions = null
) {
  return useQuery({
    queryKey: queryKeys.activeListing(integrationId, externalProductId),
    queryFn: ({ signal }) =>
      getActiveListing(unref(integrationId), unref(externalProductId), signal),
    ...queryOptions,
  });
}

/**
 *
 * @param {string | number} accountId
 * @param {string | number} integrationId
 * @param {Object} [queryOptions]
 */
export function useGetListingInsights(accountId, integrationId, queryOptions) {
  return useQuery({
    queryKey: queryKeys.listingInsights(accountId, integrationId),
    queryFn: ({ signal }) =>
      getListingInsights(unref(accountId), unref(integrationId), signal),
    ...queryOptions,
  });
}

export function useRefreshListing(integrationId, externalProductId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () =>
      refreshListing(unref(integrationId), unref(externalProductId)),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.activeListing(integrationId, externalProductId),
      });
    },
  });
}
