<script setup>
import { ref, onBeforeUnmount, watchEffect, computed } from 'vue';
import SoonaTextfield from '../ui_library/SoonaTextfield.vue';
const props = defineProps({
  value: Number,
  validationPosition: {
    type: String,
    required: false,
    default: 'left',
  },
  validationWraps: {
    default: false,
    required: false,
    type: Boolean,
  },
  max: {
    type: Number,
    required: false,
  },
  min: {
    type: Number,
    required: false,
  },
  step: {
    type: Number,
    required: false,
    default: 1,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  typeable: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emits = defineEmits(['onIncrement', 'onDecrement', 'handleInputValue']);
const valueCopy = ref(0);
const decErrorTimer = ref(undefined);
const decShowErrorTimer = ref(undefined);
const error = ref(undefined);
const incErrorTimer = ref(undefined);
const incShowErrorTimer = ref(undefined);
const showError = ref(false);
const value = computed(() => props.value);

function clearAllTimeouts() {
  clearTimeout(decErrorTimer);
  clearTimeout(decShowErrorTimer);
  clearTimeout(incErrorTimer);
  clearTimeout(incShowErrorTimer);
}

function handleIncrement() {
  if (valueCopy.value >= props.max) {
    clearAllTimeouts();
    error.value = `max quantity is ${props.max}`;
    showError.value = true;
    incShowErrorTimer.value = setTimeout(() => (showError.value = false), 2000);
    incErrorTimer.value = setTimeout(() => (error.value = undefined), 2300);
  } else if (props.disabled) {
    clearAllTimeouts();
    showError.value = false;
  } else {
    clearAllTimeouts();
    emits('onIncrement');
    showError.value = false;
    error.value = undefined;
  }
}

function handleDecrement() {
  if (valueCopy.value <= props.min) {
    clearAllTimeouts();
    error.value = `minimum quantity is ${props.min}`;
    showError.value = true;
    decShowErrorTimer.value = setTimeout(() => (showError.value = false), 2000);
    decErrorTimer.value = setTimeout(() => (error.value = undefined), 2300);
  } else if (props.disabled) {
    clearAllTimeouts();
    showError.value = false;
  } else {
    clearAllTimeouts();
    emits('onDecrement');
    showError.value = false;
    error.value = undefined;
  }
}

function handleInputValue() {
  valueCopy.value = Number(valueCopy.value);
  valueCopy.value = Math.min(
    Math.max(Math.trunc(valueCopy.value / props.step) * props.step, props.min),
    props.max
  );
  emits('handleInputValue', valueCopy.value);
}

onBeforeUnmount(() => {
  clearAllTimeouts();
});

watchEffect(() => {
  valueCopy.value = value.value;
});
</script>
<template>
  <div
    class="quantity-incrementor"
    :class="{
      'quantity-incrementor--hasError': error,
      'quantity-incrementor--hasError-wrap': error && validationWraps,
    }"
    v-bind="$attrs"
  >
    <button
      class="button"
      data-cypress="quantity-decrement"
      :class="{ disabled: disabled || value <= min }"
      @click.prevent="handleDecrement"
    >
      <span aria-hidden="true">&minus;</span>
      <span class="u-a11y-only">decrement value</span>
    </button>
    <SoonaTextfield
      v-if="typeable"
      v-model="valueCopy"
      type="number"
      class="typeable-quantity"
      name="quantity-incrementor-typing-input"
      label="quantity"
      cypress-name="input-quantity"
      hide-label
      :disabled="disabled"
      @on-blur="handleInputValue"
    />
    <p v-else data-cypress="current-quantity" class="current-quantity">
      {{ value }}
    </p>
    <button
      class="button"
      data-cypress="quantity-increment"
      :class="{ disabled: disabled || value >= max }"
      @click.prevent="handleIncrement"
    >
      <span aria-hidden="true">&plus;</span>
      <span class="u-a11y-only">increment value</span>
    </button>
    <span
      class="validation"
      :class="{
        'validation-left': validationPosition === 'left',
        'validation-center': validationPosition === 'center',
        'validation--wrap': validationWraps,
        'validation--visible': showError,
      }"
    >
      <svg
        v-if="error"
        width="16"
        height="13"
        viewBox="0 0 16 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
      >
        <path
          class="icon"
          d="M0.666748 13H15.3334L8.00008 0.333374L0.666748 13ZM8.66675 11H7.33341V9.66671H8.66675V11ZM8.66675 8.33337H7.33341V5.66671H8.66675V8.33337Z"
        />
      </svg>
      {{ error }}
    </span>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.quantity-incrementor {
  display: inline-flex;
  align-items: center;
  border-radius: 999px;
  border: solid 0.0625rem variables.$gray-30;
  background-color: variables.$white-default;
  text-align: center;
  position: relative;

  &--hasError {
    margin-bottom: 1rem;

    &-wrap {
      margin-bottom: 2rem;
    }
  }

  .typeable-quantity {
    padding-bottom: 0;
    border: none;
    :deep(.soona-textfield__input) {
      border-bottom: none;
      border-top: none;
      width: 4rem;
    }
    :deep(input::-webkit-outer-spin-button),
    :deep(input::-webkit-inner-spin-button) {
      display: none;
    }
    :deep(input[type='number']) {
      -moz-appearance: textfield;
      appearance: textfield;
    }
  }

  .button {
    margin: 0;
    padding: 0 0.75rem;
    background: none;
    border: none;
    font-weight: 800;

    &:focus,
    &:active {
      outline: none;
      box-shadow: none;
    }

    &:focus-visible {
      outline: 0.125rem variables.$black-default solid;
    }

    &.disabled {
      color: variables.$gray-40;
      cursor: not-allowed;
    }
  }

  .current-quantity {
    min-width: 1.25rem;
    font-weight: 800;
  }

  .validation {
    color: variables.$gray-60;
    font-size: 0.875rem;
    opacity: 0;
    position: absolute;
    text-align: left;
    top: 100%;
    transition: 0.3s;
    width: max-content;

    &--wrap {
      width: auto;
    }
    &--visible {
      opacity: 1;
    }

    &.validation-left {
      left: 0;
    }

    &.validation-center {
      left: 50%;
      transform: translateX(-50%);
    }

    .icon {
      fill: variables.$tangerine-40;
    }
  }
}
</style>
