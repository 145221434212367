<script setup>
import { computed, ref } from 'vue';
import AutoEditsFeedback from '@/components/crew/AutoEditsFeedback.vue';
import MoreOptionsDropdown from './MoreOptionsDropdown.vue';
import OverlayDownloadAsset from '@/components/account_gallery/OverlayDownloadAsset.vue';

const props = defineProps({
  accountId: {
    required: true,
    type: [Number, String],
  },
  addOn: {
    required: true,
    type: Object,
  },
  orderId: {
    required: true,
    type: [Number, String],
  },
  digitalAsset: {
    required: true,
    type: Object,
  },
  enableReview: {
    required: true,
    type: Boolean,
  },
});

const accountId = computed(() => props.accountId);
const orderId = computed(() => props.orderId);
const digitalAsset = computed(() => props.digitalAsset);

const showFeedback = ref(false);
</script>

<template>
  <div>
    <AutoEditsFeedback
      v-if="showFeedback"
      category="auto_edits_post_production"
      subject-type="DigitalAsset"
      :subject-id="digitalAsset.id"
      :open="showFeedback"
      options-type="post"
      @close="() => (showFeedback = false)"
      @confirm="confirmReject"
    />
    <div class="asset-actions">
      <OverlayDownloadAsset
        :file-url="digitalAsset.file?.url"
        :raw-url="digitalAsset.raw?.url"
        :web-url="digitalAsset.web?.url"
      />
      <MoreOptionsDropdown
        :account-id="accountId"
        :add-on="addOn"
        :asset="digitalAsset"
        :enable-review="enableReview"
        :order-id="orderId"
        @hide-feedback="() => (showFeedback = false)"
        @show-feedback="() => (showFeedback = true)"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.asset-actions {
  display: flex;
  gap: 0.5rem;
}
</style>
