<script setup>
import DownloadDigitalAssetMenuItem from '@/components/user/anytime/gallery/media-editor/download/DownloadDigitalAssetMenuItem.vue';
import { computed, toRefs } from 'vue';

const props = defineProps({
  asset: {
    type: Object,
    default: undefined,
  },
  clickCapture: {
    type: Function,
    required: true,
  },
  keydown: {
    type: Function,
    required: true,
  },
  mouseover: {
    type: Function,
    required: true,
  },
});

const emits = defineEmits(['click', 'show-paywall-dialog']);

const { asset, clickCapture, keydown, mouseover } = toRefs(props);

const assetWidth = computed(() => asset.value?.width);
const assetHeight = computed(() => asset.value?.height);

const webUrl = computed(() => asset.value?.web?.url || asset.value?.web_url);
const fileUrl = computed(
  () =>
    asset.value?.file?.url ||
    asset.value?.file_url ||
    asset.value?.media?.url ||
    asset.value?.media_url
);
const rawUrl = computed(() => asset.value?.raw?.url || asset.value?.raw_url);
const isAnimation = computed(() => asset.value?.media_type === 'animation');

// maths
const calculateDimensionsWithAspectRatio = maxDimension => {
  const ratio = Math.min(
    maxDimension / assetWidth.value,
    maxDimension / assetHeight.value
  );
  if (ratio > 1) {
    return { width: assetWidth.value, height: assetHeight.value };
  }
  return {
    width: parseInt(assetWidth.value * ratio),
    height: parseInt(assetHeight.value * ratio),
  };
};

const calculatedWidth = maxDimension => {
  if (maxDimension) {
    return calculateDimensionsWithAspectRatio(maxDimension).width;
  }
  return assetWidth.value;
};

const subtitle = computed(() => {
  if (assetWidth.value && assetHeight.value) {
    return `${assetWidth.value} x ${assetHeight.value}`;
  }
  return '';
});

const calculatedHeight = maxDimension => {
  if (maxDimension) {
    return calculateDimensionsWithAspectRatio(maxDimension).height;
  }
  return assetHeight.value;
};

const webResolutionCopy = computed(() => {
  const width = calculatedWidth(2048);
  const height = calculatedHeight(2048);

  if (width && height) {
    return `${width} x ${height}`;
  }
  return '';
});
</script>

<template>
  <DownloadDigitalAssetMenuItem
    v-if="webUrl"
    :mouseover="mouseover"
    :keydown="keydown"
    :click-capture="clickCapture"
    title="download web resolution"
    :subtitle="webResolutionCopy"
    @click="emits('click', { url: webUrl, label: 'download' })"
  />
  <DownloadDigitalAssetMenuItem
    v-if="fileUrl && !isAnimation"
    :mouseover="mouseover"
    :keydown="keydown"
    :click-capture="clickCapture"
    title="download full resolution"
    :subtitle="subtitle"
    @click="emits('click', { url: fileUrl, label: 'download' })"
  />
  <DownloadDigitalAssetMenuItem
    v-if="rawUrl"
    :mouseover="mouseover"
    :keydown="keydown"
    :click-capture="clickCapture"
    title="download raw"
    @click="emits('click', { url: rawUrl, label: 'download' })"
  />
</template>
