<script setup>
import { computed } from 'vue';
import { convertToMMDDYYYY } from '@/lib/date-formatters';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';

const props = defineProps({
  order: {
    type: Object,
    required: false,
  },
});
defineEmits(['close-modal']);

const order = computed(() => props.order);

const isComped = computed(() => {
  return order.value.payment_provider === 'comp';
});

const thumbnailUrl = computed(() => {
  return order.value.reservation_line_items.slice(-1)[0]?.product.thumbnail_url;
});

const orderTotal = computed(() => {
  if (order.value.reservation_line_items) {
    return order.value.reservation_line_items.reduce((sum, lineItem) => {
      const rate = lineItem.product.discounted_rate ?? lineItem.product.rate;
      return sum + rate * lineItem.quantity;
    }, 0);
  } else {
    return order.value.amount_collected;
  }
});

function toCurrency(value, currency = 'USD') {
  return value?.toLocaleString('en-US', {
    style: 'currency',
    currency,
  });
}
</script>
<template>
  <div class="NotificationModalOrderItem">
    <div class="NotificationModalOrderItem__content">
      <img class="NotificationModalOrderItem__image" :src="thumbnailUrl" />
      <div class="NotificationModalOrderItem__copy_container">
        <p class="NotificationModalOrderItem__total">
          <span>invoice total: </span>
          <template v-if="isComped">$0</template>
          <template v-else>{{ toCurrency(orderTotal) }}</template>
        </p>
        <p class="NotificationModalOrderItem__created_at">
          created on: {{ convertToMMDDYYYY(order.created_at) }}
        </p>
      </div>
    </div>
    <div class="NotificationModalOrderItem__footer">
      <SoonaButton
        element="router-link"
        :to="{
          name: 'checkout',
          params: { orderId: order.id },
        }"
        copy="view & pay"
        variation="secondary-black"
        size="medium"
        @on-click="() => $emit('close-modal')"
      />
    </div>
  </div>
</template>
<style lang="scss" scoped>
.NotificationModalOrderItem {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  box-shadow: 0px 3px 6px #00000029;
  padding: 1rem;
  border-radius: 0.625rem;
  margin-bottom: 1rem;
  width: 100%;

  .NotificationModalOrderItem__content {
    display: flex;
    gap: 1rem;

    .NotificationModalOrderItem__image {
      height: 5rem;
      width: 5rem;
      border-radius: 0.3125rem;
      object-fit: cover;
    }

    .NotificationModalOrderItem__copy_container {
      display: flex;
      flex-direction: column;
      margin-top: auto;
      margin-right: 3rem;
      text-align: left;

      .NotificationModalOrderItem__total {
        font-size: 1.05rem;

        span {
          font-weight: 800;
        }
      }

      .NotificationModalOrderItem__created_at {
        font-size: 0.75rem;
      }
    }
  }
  .NotificationModalOrderItem__footer {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
