<script setup>
import { computed, ref } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { useBulkRemoveFromBag } from '@/queries/bag/useBulkRemoveFromBag';
import SoonaActionBar from '@/components/ui_library/SoonaActionBar.vue';
import BulkChooseEdits from '@/components/checkout/BulkChooseEdits.vue';
import { useMediaQuery } from '@vueuse/core';
import SoonaLoading from '@/components/SoonaLoading.vue';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
  selectedAssets: {
    type: Array,
    required: true,
  },
  pageBoundingRectLeft: {
    type: Number,
    required: true,
  },
  pageBoundingRectWidth: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['cancelMultiSelect', 'addOnsSaved']);

const matchMediaIsWide = useMediaQuery('(min-width: 48rem)');

const accountId = computed(() => props.accountId);

const { mutate: bulkRemoveFromBag, isPending } =
  useBulkRemoveFromBag(accountId);

const isUpdating = computed(() => isPending.value);

const assetIds = computed(() => props.selectedAssets);

const numberOfProductsSelected = computed(() => props.selectedAssets.length);

const selectedAssetsText = computed(() =>
  numberOfProductsSelected.value === 1
    ? '1 selected'
    : `${numberOfProductsSelected.value} selected`
);

const removeButtonCopy = computed(() =>
  matchMediaIsWide.value ? 'remove from cart' : 'remove'
);

const isModalOpen = ref(false);

const closeModal = () => {
  isModalOpen.value = false;
};

const openModal = () => {
  isModalOpen.value = true;
};

const handleAddOnsSaved = () => {
  isModalOpen.value = false;
  emit('addOnsSaved');
};

const isLoading = ref(false);

const handleBulkRemove = () => {
  isLoading.value = true;
  bulkRemoveFromBag(
    { assetType: 'digital_asset', assetIds: assetIds.value },
    {
      onSuccess: () => {
        isLoading.value = false;
      },
    }
  );
};
</script>
<template>
  <Teleport v-if="isLoading" to="body">
    <SoonaLoading v-if="isLoading" :is-loading="true" loading-text="removing" />
  </Teleport>
  <SoonaActionBar
    :display="selectedAssets.length > 1"
    :page-bounding-rect-left="pageBoundingRectLeft"
    :page-bounding-rect-width="pageBoundingRectWidth"
  >
    <template #content>
      <p class="u-label--heavy">{{ selectedAssetsText }}</p>
    </template>
    <template #actions>
      <SoonaButton
        size="medium"
        variation="secondary-transparent"
        @on-click="$emit('cancelMultiSelect')"
      >
        cancel
      </SoonaButton>
      <SoonaButton
        size="medium"
        variation="secondary-gray"
        :disabled="isUpdating"
        @click.prevent="handleBulkRemove"
      >
        <SoonaIcon class="friendly-red" name="trash" size="small" />
        {{ removeButtonCopy }}
      </SoonaButton>
      <SoonaButton
        size="medium"
        variation="secondary-gray"
        :disabled="isUpdating"
        @click="openModal"
      >
        <SoonaIcon name="pen-swirl" size="small" />
        premium edits
      </SoonaButton>
    </template>
  </SoonaActionBar>
  <BulkChooseEdits
    v-if="isModalOpen && selectedAssets.length > 0"
    :asset-ids="selectedAssets"
    :account-id="accountId"
    :open="isModalOpen"
    @close="closeModal"
    @add-ons-saved="handleAddOnsSaved()"
  ></BulkChooseEdits>
</template>

<style lang="scss" scoped>
@use '@/variables';

.action-bar {
  @media (max-width: variables.$screen-xs-max) {
    bottom: 3.75rem;

    @media (max-height: variables.$screen-xxs-min) {
      gap: 0.75rem;
      padding-top: 0.75rem;
      padding-bottom: 1.625rem;
    }
  }
}

.friendly-red {
  color: variables.$friendly-red-50;
}
</style>
