import LogRocket from 'logrocket';
import { getEnvHostname } from '../lib/user/globalUser';

export const initLogRocket = appId => {
  let logRocketConfig = {
    network: {
      requestSanitizer: request => {
        try {
          const parsedBody = JSON.parse(request.body);

          if (parsedBody?.user) {
            Object.keys(parsedBody.user).forEach(key => {
              if (key.toLowerCase().indexOf('password') > -1) {
                delete parsedBody.user[key];
              }
            });
          }

          request.body = parsedBody;
        } catch {
          return request;
        }

        return request;
      },
    },
  };

  //set rootHostname so that LogRocket sessions can span soona.co subdomains
  if (getEnvHostname() !== 'local') {
    logRocketConfig.rootHostname = 'soona.co';
  }

  LogRocket.init(appId, logRocketConfig);
};

export const identifyUser = ({ email, id }) =>
  LogRocket.identify(id, {
    email: email,
  });
