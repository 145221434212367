<script setup>
import { computed } from 'vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { usePackConfigurations } from '@/queries/pack-configurations/usePackConfigurations';
import { useRoute } from 'vue-router';
import SoonaItemCard from '@/components/ui_library/SoonaItemCard.vue';
import SoonaHelperText from '@/components/ui_library/SoonaHelperText.vue';
import BuildYourShootImage from 'images/listing-insights/soona-internal-small.jpg';
import MediaEditorImage from 'images/listing-insights/media-editor-small.png';

const props = defineProps({
  accountId: {
    type: [Number, String],
    required: true,
  },
  platform: {
    type: String,
    required: true,
  },
});

const accountId = computed(() => props.accountId);
const platform = computed(() => props.platform);

const { data: recommendedPacks, isSuccess: packsLoaded } =
  usePackConfigurations({
    query: platform,
  });

const recommendedPack = computed(() => {
  if (platform.value === 'Amazon') {
    return (
      recommendedPacks.value?.data.find(
        x => x.name === 'Amazon listing starter pack'
      ) ?? recommendedPacks.value?.data[0]
    );
  }
  return (
    recommendedPacks.value?.data.find(x => x.name === 'Shopify plus pack') ??
    recommendedPacks.value?.data[0]
  );
});

const route = useRoute();

const { linkClicked } = useBaseEvents();

const handleCardClick = (linkLabel, linkHref) => {
  linkClicked({
    context: route.meta.context,
    subContext: 'single listing overview',
    linkLabel: linkLabel,
    linkHref: linkHref,
  });
};
</script>

<template>
  <div class="next-steps">
    <h3 class="u-subheader--heavy">improve your score</h3>
    <div v-if="packsLoaded" class="next-steps__section">
      <div class="next-steps__section--heading">
        <h4 class="u-badge--large">recommended</h4>
        <SoonaHelperText title="improve your score">
          our {{ platform }}-optimized packs include all the assets you need to
          make any listing shine.
        </SoonaHelperText>
      </div>

      <SoonaItemCard
        aria-labelledby="recommended-pack"
        :image-url="recommendedPack.pack_main_image_url"
        class="next-steps__section--card"
      >
        <template #content>
          <div class="next-steps__section--card__content">
            <a
              :href="`/book/pack/${recommendedPack.id}`"
              class="next-steps__section--card__name u-body--heavy"
              @click="
                handleCardClick(
                  recommendedPack.name,
                  `/book/pack/${recommendedPack.id}`
                )
              "
            >
              {{ recommendedPack.name }}
            </a>
            <p class="u-body--regular">{{ recommendedPack.pack_price }}</p>
          </div>
        </template>
      </SoonaItemCard>
    </div>

    <div class="next-steps__section">
      <div class="next-steps__section--heading">
        <h4 class="next-steps__section--heading u-badge--large">more tools</h4>
      </div>

      <SoonaItemCard
        aria-labelledby="build-your-shoot"
        :image-url="BuildYourShootImage"
        class="next-steps__section--card"
      >
        <template #content>
          <div class="next-steps__section--card__content">
            <a
              href="/#/book"
              class="next-steps__section--card__name u-body--heavy"
              @click="handleCardClick('build your own shoot', '/#/book')"
            >
              build your own shoot
            </a>
            <p class="u-label--regular">custom photo or video</p>
          </div>
        </template>
      </SoonaItemCard>
      <SoonaItemCard
        aria-labelledby="media-editor"
        :image-url="MediaEditorImage"
        class="next-steps__section--card"
      >
        <template #content>
          <div class="next-steps__section--card__content">
            <router-link
              :to="`/account/${accountId}/media-editor`"
              class="next-steps__section--card__name u-body--heavy"
              @click="handleCardClick('media editor', '/account/media-editor')"
            >
              media editor
            </router-link>
            <p class="u-label--regular">update your existing photos</p>
          </div>
        </template>
      </SoonaItemCard>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.next-steps {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  background-color: variables.$periwink-blue-20;
  border-radius: 0.625rem;
  padding: 1rem;

  &__section {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    &--heading {
      display: flex;
      justify-content: space-between;
      color: variables.$gray-60;
    }

    &--card {
      position: relative;
      width: 100%;

      &:hover {
        background-color: variables.$gray-10;
      }

      &__content {
        padding: 0.75rem 1rem;
      }

      &__name {
        &::before {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 1;
        }
      }
    }
  }
}
</style>
