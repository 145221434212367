<script setup>
import { computed } from 'vue';
import ProductLineItemCard from '../add_on_order/ProductLineItemCard.vue';
import { useReservation } from 'src/queries/useReservation';
import MenuX from 'src/components/svgs/MenuX.vue';
import unescape from 'lodash/unescape';
import AutoAppliedDiscountMiniBanner from '@/components/user/anytime/discounts/AutoAppliedDiscountMiniBanner.vue';
import { toCurrency } from '@/lib/currency';

const props = defineProps({
  products: {
    type: Array,
    required: true,
    default: null,
  },
  showProductLevel: {
    type: Boolean,
    default: true,
  },
  reservationId: {
    type: String,
    required: false,
    default: null,
  },
  affixed: {
    type: Boolean,
    required: false,
    default: true,
  },
  toggleSidecart: {
    required: false,
    type: Function,
  },
  compedOrder: {
    required: false,
    type: Boolean,
    default: false,
  },
  preferred: {
    required: false,
    type: Object,
    default: null,
  },
  discountObj: {
    type: Object,
    required: false,
    default: null,
  },
});

const emits = defineEmits([
  'incrementProduct',
  'decrementProduct',
  'setProductCount',
]);

const toggle = () => {
  if (props.toggleSidecart) {
    props.toggleSidecart();
  }
};
const orderComped = computed(() => props.compedOrder);
const products = computed(() => props.products);
const discountObj = computed(() => props.discountObj);

const reservationId = computed(() => props.reservationId);
const { data: reservation } = useReservation(reservationId.value, {
  enabled: computed(() => !!reservationId.value),
});

const reservationName = computed(() =>
  reservation && reservation.value ? unescape(reservation.value.name) : null
);

function incrementProduct(product) {
  emits('incrementProduct', product);
}
function decrementProduct(product) {
  emits('decrementProduct', product);
}
function setProductCount(product, newVal) {
  emits('setProductCount', product, newVal);
}

const discountAmount = computed(() => {
  if (!discountObj.value) return 0;
  if (discountObj.value.amount) return discountObj.value.amount;
  return 0;
});

const showCoupon = computed(() => {
  // if discount is product-specific, supress showing total discount amount
  // because the discounts are shown on the line item level
  if (discountObj.value?.is_product_scoped) return false;

  return !!discountObj.value;
});

const totalPrice = computed(() => {
  if (orderComped.value) {
    return toCurrency(0);
  } else {
    const val = products.value
      ? products.value.reduce((accumulator, product) => {
          const rate =
            product.price_info.discounted_rate || product.price_info.rate;
          return accumulator + product.quantity * Number(rate);
        }, 0)
      : 0;

    if (!showCoupon.value) return toCurrency(val);
    return toCurrency(val - discountAmount.value);
  }
});
</script>

<template>
  <aside
    class="additional-order-sidecart"
    :class="{ 'additional-order-sidecart--affixed': affixed }"
    aria-labelledby="additional-order-sidecart-heading"
  >
    <div class="additional-order-sidecart__inner">
      <header
        v-if="reservationId != null"
        class="additional-order-sidecart__header"
      >
        <h2
          id="additional-order-sidecart-heading"
          class="additional-order-sidecart__header-title"
        >
          <p>SHOOT SUMMARY</p>
          <button
            v-if="affixed && reservationId"
            type="button"
            class="u-button-reset additional-order-sidecart__close"
            @click="toggle"
          >
            <MenuX />
          </button>
        </h2>

        <p class="additional-order-sidecart__header-content">
          {{ reservationName }}
        </p>
      </header>
      <AutoAppliedDiscountMiniBanner
        :account-id="reservation?.account_id"
        :preferred="preferred"
        :reservation-id="reservationId"
        :tagline="discountObj?.auto_apply_tagline"
      />
      <div
        v-if="!reservationId || products.length > 0"
        class="additional-order-sidecart__header"
      >
        <h2
          id="additional-order-sidecart-heading"
          class="additional-order-sidecart__header-title"
        >
          <p>PRICING INFO</p>
          <button
            v-if="affixed && !reservationId"
            type="button"
            class="u-button-reset additional-order-sidecart__close"
            @click="toggle"
          >
            <MenuX />
          </button>
        </h2>
      </div>
      <div class="additional-order-sidecart__content">
        <ProductLineItemCard
          v-for="product in products"
          :key="product.id"
          :product="product"
          :show-level="showProductLevel"
          @increment-product="incrementProduct"
          @decrement-product="decrementProduct"
          @set-product-count="setProductCount"
        />
      </div>
      <footer class="additional-order-sidecart__footer">
        <p v-if="showCoupon" class="additional-order-sidecart__discount">
          <span>
            promo code: <b>{{ discountObj?.external_discount_id }}</b>
          </span>
          <strong class="u-subheader--heavy">
            -{{ toCurrency(Number(discountAmount)) }}
          </strong>
        </p>
        <p class="u-button--large-caps additional-order-sidecart__amount-due">
          amount due today:
          <strong class="u-subheader--heavy"> {{ totalPrice }} </strong>
        </p>
        <slot name="checkout-button"></slot>
      </footer>
    </div>
  </aside>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';
@keyframes slide-left {
  0% {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes slide-right {
  to {
    transform: translateX(100%);
  }
}
.additional-order-sidecart {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  border-left: 0.0625rem solid variables.$gray-30;
  min-height: 20%;
  header {
    background-color: variables.$periwink-blue-10;
  }
  &__inner {
    display: flex;
    flex-direction: column;
    background-color: variables.$white-default;
    width: 100%;
  }
  &--affixed {
    display: none;
  }
  &--affixed .additional-order-sidecart__inner {
    max-width: 27.625rem;
    animation: 0.3s ease-out both slide-left;
  }
  &--affixed[class*='closing'] .additional-order-sidecart__inner {
    animation: 0.3s ease-out both slide-right;
  }

  &__close {
    svg {
      display: block;
      width: 1.375rem;
      height: 1.375rem;

      &:deep(g) {
        transition: fill 0.1s ease-out;
        fill: variables.$gray-60;
      }
    }

    &:hover,
    &:focus-visible {
      &:deep(g) {
        fill: variables.$black-default;
      }
    }
  }
  &__header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem 1rem 0 0.875rem;
    position: relative;
  }
  &__header-title {
    @include variables_fonts.u-small--heavy;
    display: flex;
    width: 100%;
    padding-bottom: 0.625rem;
    border-bottom: 0.0625rem solid variables.$gray-30;
    color: variables.$black-default;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    margin-bottom: 1rem;
    justify-content: space-between;
  }
  &__header-content {
    @include variables_fonts.u-body--heavy;
    color: variables.$black-default;
    margin-bottom: 1.5rem;
  }

  &__content {
    flex-grow: 1;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding: 0 1rem;
  }

  &__footer {
    border-top: 1px solid variables.$gray-30;
    padding-top: 0.5rem;
    margin: 0 1rem 1rem;
  }

  &__discount,
  &__amount-due {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__discount {
    text-transform: lowercase;

    b {
      font-weight: 800;
    }

    strong {
      color: variables.$friendly-red-70;
    }
  }

  @media (min-width: 48rem) {
    &:not(.additional-order-sidecart--affixed) .additional-order-sidecart {
      &__header {
        padding-top: 1.5rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;

        &::after {
          left: 1.5rem;
          right: 1.5rem;
        }
      }
      &__footer {
        margin-bottom: 1.5rem;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
      }

      &__content {
        padding: 0 1.5rem;
      }
    }
  }

  @media (min-width: 60rem) {
    &--affixed {
      display: flex;
    }

    &--affixed .additional-order-sidecart__inner {
      width: 100%;
      animation: none !important;
    }

    &--affixed .additional-order-sidecart__header::after {
      left: 1rem;
      right: 1rem;
    }
  }

  @media (min-width: 29.3125rem) and (max-width: 59.9375rem) {
    &--affixed .additional-order-sidecart {
      &__header {
        padding-top: 1.5rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      }
      &__content {
        padding: 0 1.5rem;
      }
      &__footer {
        margin-left: 1.5rem;
        margin-right: 1.5rem;
      }
    }
  }

  @media (min-width: 75rem) {
    &--affixed .additional-order-sidecart {
      height: fit-content;
      &__header {
        padding-top: 1.5rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;

        &::after {
          left: 1.5rem;
          right: 1.5rem;
        }
      }
      &__footer {
        margin-bottom: 1.5rem;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
      }
      &__content {
        padding: 0 1.5rem;
      }
    }
  }
}
</style>
