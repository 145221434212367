<script setup>
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import { computed } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import WarningIcon from '@/components/svgs/WarningIcon.vue';
import { onKeyStroke } from '@vueuse/core';

const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
  message: {
    type: String,
    default:
      'your changes won’t be saved if you close the editor now. close anyway?',
  },
  confirmMessage: {
    type: String,
    default: 'leave & discard',
  },
});

const emits = defineEmits(['continue', 'cancel']);

onKeyStroke('Escape', () => {
  emits('cancel');
});

const open = computed(() => props.open);
const message = computed(() => props.message);
const confirmMessage = computed(() => props.confirmMessage);
</script>

<template>
  <SoonaDialog v-if="open" @close="() => emits('cancel')">
    <template #heading>
      <div class="close-warning__header">
        <WarningIcon class="close-warning__icon" /> unsaved changes
      </div>
    </template>
    {{ message }}
    <template #footer>
      <SoonaButton
        type="button"
        variation="tertiary"
        @click="() => emits('cancel')"
      >
        cancel
      </SoonaButton>
      <SoonaButton
        type="button"
        variation="primary"
        @click="() => emits('continue')"
      >
        {{ confirmMessage }}
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>

<style scoped lang="scss">
@use '@/variables';
.close-warning {
  &__header {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
  }

  &__icon {
    padding-top: 0.25rem;
  }
}
</style>
