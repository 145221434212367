<template>
  <div class="soona-bottom-banner">
    <div class="soona-bottom-banner__inner">
      <div class="soona-bottom-banner__top">
        <slot name="icon" />
        <h3 class="soona-bottom-banner__title">
          <slot name="heading" />
        </h3>
      </div>
      <div class="soona-bottom-banner__bottom">
        <slot name="bottom" />
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.soona-bottom-banner {
  width: 100%;
  background-color: variables.$white-default;

  &__inner {
    background-color: variables.$tangerine-05;
  }

  &__top {
    border-top: 1px solid variables.$black-default;
    border-bottom: 1px solid variables.$black-default;
    padding: 1.25rem;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    @media (min-width: variables.$screen-sm-min) {
      padding-bottom: 1.125rem;
    }
  }

  &__title {
    @include variables_fonts.u-body--all-caps-black;
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 1.25rem;
  }
}
</style>
