<script setup>
import DownloadDigitalAssetMenuItem from '@/components/user/anytime/gallery/media-editor/download/DownloadDigitalAssetMenuItem.vue';
import { computed, toRefs } from 'vue';

const props = defineProps({
  asset: {
    type: Object,
    default: undefined,
  },
  clickCapture: {
    type: Function,
    required: true,
  },
  keydown: {
    type: Function,
    required: true,
  },
  mouseover: {
    type: Function,
    required: true,
  },
});

const emits = defineEmits(['click']);

const { asset, clickCapture, keydown, mouseover } = toRefs(props);

const assetWidth = computed(() => asset.value?.width);
const assetHeight = computed(() => asset.value?.height);

const mediaUrl = computed(
  () =>
    asset.value?.file?.url ||
    asset.value?.file_url ||
    asset.value?.media?.url ||
    asset.value?.media_url
);

const subtitle = computed(() => {
  if (assetWidth.value && assetHeight.value) {
    return `${assetWidth.value} x ${assetHeight.value}`;
  }
  return '';
});
</script>

<template>
  <DownloadDigitalAssetMenuItem
    :mouseover="mouseover"
    :keydown="keydown"
    :click-capture="clickCapture"
    title="download"
    :subtitle="subtitle"
    @click="emits('click', { url: mediaUrl, label: 'download' })"
  />
</template>
