<script setup>
import { ref } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SubscriptionsDialog from 'src/components/modal-payment-flows/subscriptions/SubscriptionsDialog.vue';

const showTrialDialog = ref(false);
</script>
<template>
  <div class="subscription-trial-message">
    <img src="@images/color_cards.png" alt="" />
    <span class="u-body--heavy">special offer for you</span>
    <p class="u-subheader--heavy">
      change the background color of any photo and resize it to fit any platform
    </p>
    <SoonaButton
      variation="solid-black"
      copy="try it free!"
      @click="showTrialDialog = true"
    >
      <SoonaIcon name="arrow-right" />
    </SoonaButton>
  </div>
  <SubscriptionsDialog
    v-if="showTrialDialog"
    flow="trialInfo"
    @close="() => (showTrialDialog = false)"
  />
</template>
<style scoped lang="scss">
@use '@/variables';

.subscription-trial-message {
  margin: 2.375rem 0;
  border: 0.0625rem solid variables.$gray-30;
  padding: 1.3125rem 0.75rem;
  border-radius: 0.5rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    max-width: 6.375rem;
  }

  span {
    padding: 0.5rem 0;
    color: variables.$periwink-blue-60;
  }

  p {
    padding-bottom: 1.25rem;
  }
}
</style>
