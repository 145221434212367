<script setup>
import { computed, toRef } from 'vue';
import BookingLayout from './BookingLayout.vue';
import SoonaHeading from 'src/components/ui_library/SoonaHeading.vue';
import SoonaLoading from 'src/components/SoonaLoading.vue';
import { useReservationTags } from 'src/queries/reservation-tags/useReservationTags';
import { useAddReservationTag } from 'src/queries/reservation-tags/useAddReservationTag';
import { useRemoveReservationTag } from 'src/queries/reservation-tags/useRemoveReservationTag';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { usePriorityError } from 'src/composables/usePriorityError';

// props and data
const props = defineProps({
  nextButtonCopy: String,
  backButtonCopy: String,
  onNext: Function,
  onBack: Function,
  reservationId: String,
  stepName: String,
  transitionName: String,
});

const { itemSelected } = useBaseEvents();

// api calls
const {
  data: contentPurposeData,
  status,
  isLoading,
  error: resTagsError,
} = useReservationTags(toRef(props, 'reservationId'), toRef(props, 'stepName'));

const contentPurposeOptions = computed(() => {
  return contentPurposeData.value?.options;
});
const {
  mutate: addReservationTag,
  isPending: isAdding,
  error: addError,
} = useAddReservationTag(toRef(props, 'reservationId'));

const {
  mutate: removeReservationTag,
  isPending: isRemoving,
  error: removeError,
} = useRemoveReservationTag(toRef(props, 'reservationId'));

const priorityError = usePriorityError(addError, removeError, resTagsError);

// computed
const isBusy = computed(() => {
  return isLoading.value || isAdding.value || isRemoving.value;
});

const isStepValid = computed(() => {
  return (
    !isBusy.value && contentPurposeOptions.value?.some(cpo => cpo.selected)
  );
});

// methods
function onOptionChange(option) {
  const beingSelected = !option.selected;

  if (beingSelected) {
    addReservationTag(
      { tagId: option.tag.id },
      {
        onSuccess: () => {
          itemSelected({
            context: 'booking',
            subContext: 'purpose',
            itemLabel: option?.tag?.title,
            itemPrice: 0,
            itemQuantity: 1,
          });
        },
      }
    );
  } else {
    removeReservationTag({
      reservationTagId: option.reservation_tag.id,
      deleteObsoleted: true,
    });
  }
}

function onBackClick() {
  props.onBack();
}

function onNextClick() {
  props.onNext();
}
</script>

<template>
  <transition :name="transitionName">
    <BookingLayout
      :next-button-copy="nextButtonCopy"
      :back-button-copy="backButtonCopy"
      :on-next-click="onNextClick"
      :on-back-click="onBackClick"
      :is-loading="isBusy"
      :is-step-valid="isStepValid"
      :reservation-id="reservationId"
      no-sidecart
    >
      <template v-if="priorityError" #priority-error>
        {{ priorityError }}
      </template>
      <div class="booking-content-for prebooking-transition__content">
        <SoonaLoading
          v-if="!contentPurposeOptions"
          :is-loading="!contentPurposeOptions"
          :is-dark="false"
          loading-text="loading"
        />
        <SoonaHeading data-cypress="booking-content-for-header">
          what do you need content for?
          <template #subtitle>choose all that apply!</template>
        </SoonaHeading>
        <div v-if="status === 'success'" class="booking-content-for__options">
          <template
            v-for="option in contentPurposeOptions"
            :key="option.tag.title"
          >
            <input
              :id="'booking-content-option-' + option.tag.title"
              type="checkbox"
              :checked="option.selected"
              class="u-a11y-only"
              :disabled="isBusy"
              :data-cypress="`input-${option.tag.title}`"
              @change="onOptionChange(option)"
            />
            <label
              class="multiselect-option"
              :for="'booking-content-option-' + option.tag.title"
              :data-cypress="`label-${option.tag.title}`"
            >
              <span
                class="multiselect-option__header"
                :class="{
                  'multiselect-option__header--other':
                    option.tag.title === 'other',
                }"
              >
                <span class="multiselect-option__emoji" aria-hidden="true">
                  {{ option.tag.icon }}
                </span>
                {{ option.tag.title }}
              </span>
              <span class="multiselect-option__description">
                {{ option.tag.description }}
              </span>
            </label>
          </template>
        </div>
      </div>
    </BookingLayout>
  </transition>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.booking-content-for {
  &__skip {
    margin-top: 2.25rem;
  }
  @media (min-width: variables.$screen-sm-min) {
    &__title {
      @include variables_fonts.u-display--heavy;

      margin-bottom: 0.25rem;
    }

    &__options {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 1.25rem;
      row-gap: 1.25rem;
      max-width: 53rem;
    }
  }
}

.multiselect-option {
  position: relative;
  display: block;
  border-radius: 0.625rem;
  border: 1px solid variables.$gray-30;
  background-color: variables.$white-default;
  cursor: pointer;
  transition:
    border-color 0.1s ease-out,
    box-shadow 0.1s ease-out;
  color: variables.$black-default;
  margin-bottom: 1rem;
  padding-left: 5rem;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    box-shadow: variables.$elevation-2;
  }

  &:active,
  input:checked + & {
    border-color: variables.$black-default !important;
  }

  input:focus-visible + & {
    border: 1px solid variables.$periwink-blue-60;
    filter: drop-shadow(0px 0px 2px variables.$periwink-blue-60);
  }

  &__header {
    @include variables_fonts.u-title--heavy;

    display: block;
    padding: 1rem 1rem 0.25rem;
  }

  &__emoji {
    @include variables_fonts.u-display--regular;

    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: variables.$bubbletape-pink-10;
    border-radius: 0.625rem 0 0 0.625rem;

    .multiselect-option__header--other & {
      background-color: variables.$periwink-blue-20;
    }
  }

  &__logo_container {
    display: flex;
    align-items: center;
    padding: 0 1rem 1rem;
  }

  &__logo {
    min-width: 1.5rem;
    min-height: 1.5rem;
    margin-right: 1rem;
  }

  &__description {
    @include variables_fonts.u-body--regular;

    display: block;
    padding: 0 1rem 1rem;
  }

  @media (min-width: variables.$screen-sm-min) {
    position: static;
    margin-bottom: 0;
    min-height: 9.6875rem;
    padding-left: 0;

    &__header {
      @include variables_fonts.u-headline--heavy;

      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0.625rem 1.5rem;
      border-radius: 0.625rem 0.625rem 0 0;
      background-color: variables.$bubbletape-pink-10;

      &--other {
        background-color: variables.$periwink-blue-20;
      }
    }

    &__emoji {
      @include variables_fonts.u-headline--heavy;

      position: static;
      width: auto;
      background-color: transparent;
      flex-shrink: 0;
      margin-right: 0.625rem;
      font-weight: 400;

      .multiselect-option__header--other & {
        background-color: transparent;
      }
    }

    &__description {
      padding: 0.875rem 1.5rem;
    }

    &__logo_container {
      padding: 0.875rem 1.5rem;
    }
  }
}
</style>
