<script setup>
import DownloadDigitalAssetMenuItem from '@/components/user/anytime/gallery/media-editor/download/DownloadDigitalAssetMenuItem.vue';
import { computed, toRefs } from 'vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaFlag from '@/components/ui_library/SoonaFlag.vue';

const props = defineProps({
  asset: {
    type: Object,
    default: undefined,
  },
  clickCapture: {
    type: Function,
    required: true,
  },
  keydown: {
    type: Function,
    required: true,
  },
  mouseover: {
    type: Function,
    required: true,
  },
});

const emits = defineEmits(['click', 'show-paywall-dialog']);

const { asset, clickCapture, keydown, mouseover } = toRefs(props);

const assetWidth = computed(() => asset.value?.width);
const assetHeight = computed(() => asset.value?.height);

const previewUrl = computed(
  () => asset.value?.preview_url || asset.value?.preview?.url
);

const subtitle = computed(() => {
  if (assetWidth.value && assetHeight.value) {
    return `${assetWidth.value} x ${assetHeight.value}`;
  }
  return '';
});
</script>

<template>
  <DownloadDigitalAssetMenuItem
    :mouseover="mouseover"
    :keydown="keydown"
    :click-capture="clickCapture"
    title="low res preview"
    subtitle="with soona watermark"
    @click="emits('click', { url: previewUrl, label: 'low res preview' })"
  >
    <template #right-col>
      <span class="u-label--regular free">free</span>
    </template>
  </DownloadDigitalAssetMenuItem>
  <DownloadDigitalAssetMenuItem
    :mouseover="mouseover"
    :keydown="keydown"
    :click-capture="clickCapture"
    title="full resolution"
    :subtitle="subtitle"
    pizzazz-border
    @click="emits('show-paywall-dialog')"
  >
    <template #right-col>
      <SoonaFlag background-color="transparent" type="pill" is-pizzazz>
        <template #icon-left>
          <SoonaIcon name="crown" size="small" class="crown" />
        </template>
      </SoonaFlag>
    </template>
  </DownloadDigitalAssetMenuItem>
</template>

<style scoped lang="scss">
@use '@/variables';

.free {
  text-transform: uppercase;
  color: variables.$gray-60;
}

.crown {
  color: variables.$white-default;
}
</style>
