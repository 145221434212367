<script setup>
import { computed } from 'vue';
import { useUpdateDigitalAsset } from '@/queries/digital_assets/useUpdateDigitalAsset';
import { useSoonaToast } from '@/components/ui_library/soona_toast/useSoonaToast';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaTooltip from '@/components/ui_library/SoonaTooltip.vue';

const props = defineProps({
  asset: {
    type: Object,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const asset = computed(() => props.asset);
const disabled = computed(() => props.disabled);
const accountId = computed(() => asset.value?.account_id);
const assetRotation = computed(() => asset.value?.rotation);

const { addToast } = useSoonaToast();

const { mutate: updateDigitalAsset, isPending: isRotating } =
  useUpdateDigitalAsset(accountId);

const rotate = async rot => {
  const newRotation = assetRotation.value + rot;
  const normalizedRotation = ((newRotation % 360) + 360) % 360;
  updateDigitalAsset(
    {
      assetId: asset.value?.id,
      body: {
        rotation: normalizedRotation,
      },
    },
    {
      onError: () => {
        addToast(`error rotating asset`, {
          variation: 'error',
          noDismissButton: true,
        });
      },
    }
  );
};
</script>

<template>
  <div class="rotate-digital-asset">
    <SoonaTooltip placement="top">
      <template
        #default="{
          setRef,
          mouseenter,
          focus,
          mouseleave,
          blur,
          ariaDescribedby,
        }"
      >
        <SoonaButton
          :ref="el => setRef(el)"
          :disabled="disabled"
          variation="icon-gray-outline"
          :loading="isRotating"
          size="small"
          class="rotate-digital-asset__button rotate-digital-asset__button--left"
          :aria-describedby="ariaDescribedby"
          data-cypress="rotate-left"
          @click="rotate(-90)"
          @mouseenter="mouseenter"
          @focus="focus"
          @mouseleave="mouseleave"
          @blur="blur"
        >
          <SoonaIcon name="rotate-left" />
        </SoonaButton>
      </template>
      <template #tooltip-content>rotate left</template>
    </SoonaTooltip>
    <SoonaTooltip placement="top">
      <template
        #default="{
          setRef,
          mouseenter,
          focus,
          mouseleave,
          blur,
          ariaDescribedby,
        }"
      >
        <SoonaButton
          :ref="el => setRef(el)"
          :disabled="disabled"
          variation="icon-gray-outline"
          :loading="isRotating"
          size="small"
          class="rotate-digital-asset__button rotate-digital-asset__button--right"
          :aria-describedby="ariaDescribedby"
          data-cypress="rotate-right"
          @click="rotate(90)"
          @mouseenter="mouseenter"
          @focus="focus"
          @mouseleave="mouseleave"
          @blur="blur"
        >
          <SoonaIcon name="rotate-right" />
        </SoonaButton>
      </template>
      <template #tooltip-content>rotate right</template>
    </SoonaTooltip>
  </div>
</template>

<style scoped lang="scss">
.rotate-digital-asset {
  display: flex;
  gap: 0.5rem;

  &__button {
    transition: transform 0.3s cubic-bezier(0, 0.55, 0.45, 1);

    &--left {
      &:hover,
      &:focus-visible {
        transform: rotate(-90deg);
      }
    }

    &--right {
      &:hover,
      &:focus-visible {
        transform: rotate(90deg);
      }
    }
  }
}
</style>
