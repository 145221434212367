<template>
  <div>
    <div class="field is-horizontal update-reservation-field">
      <div class="field-label is-normal">
        <label class="label schedule-status-label">schedule status</label>
      </div>
      <div class="field-body">
        <div class="field soona-genie-schedule-section">
          <div class="control is-expanded soona-genie-vselect">
            <v-select
              v-model:model-value="scheduleStatus"
              :get-option-label="status => `${status}`"
              :options="scheduleStatuses"
              :reduce="scheduleStatuses => scheduleStatuses"
              :clearable="false"
              :searchable="false"
              :disabled="isCompleted"
              :selectable="
                scheduleStatuses =>
                  !scheduleStatuses.includes('en route') &&
                  !scheduleStatuses.includes('ready to go') &&
                  !scheduleStatuses.includes('pre ship')
              "
            ></v-select>
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">duration</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control is-expanded soona-genie-vselect">
            <v-select
              v-model:model-value="duration"
              :get-option-label="
                duration => `${formatSecondsToHours(duration)}`
              "
              :options="durationOptions"
              :reduce="seconds => seconds"
              :clearable="false"
              :searchable="false"
              :disabled="isCompleted"
            ></v-select>
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">date</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control is-expanded">
            <ul class="genie-btn-flex-wrapper">
              <li v-for="(date, index) in allDateOptions" :key="index">
                <SoonaButton
                  :class="{ 'genie-btn--selected': date.selected }"
                  :copy="date.formatted"
                  :disabled="date.disabled"
                  size="medium"
                  variation="secondary-gray"
                  @on-click="
                    date.selected ? dateSelected(null) : dateSelected(date)
                  "
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">start time</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control is-expanded">
            <ul class="genie-btn-flex-wrapper">
              <li v-for="(time, index) in allTimeOptions" :key="index">
                <SoonaButton
                  :class="{ 'genie-btn--selected': time.selected }"
                  :copy="time.time"
                  :disabled="time.disabled"
                  size="medium"
                  variation="secondary-gray"
                  @on-click="
                    time.selected ? timeSelected(null) : timeSelected(time)
                  "
                />
              </li>
            </ul>
            <ul>
              <i v-if="accountPreferredTimeZone" class="start-time-timezone">
                {{ selectedTimeConvertedtoAccountTimeZone }}
              </i>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">bay</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control is-expanded">
            <ul class="genie-btn-flex-wrapper">
              <li v-for="bay in allBayOptions" :key="bay.id">
                <SoonaButton
                  :class="{ 'genie-btn--selected': bay.selected }"
                  :copy="bay.name"
                  :disabled="bay.disabled"
                  size="medium"
                  variation="secondary-gray"
                  @on-click="
                    bay.selected ? baySelected(null) : baySelected(bay)
                  "
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">creative</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control is-expanded">
            <ul class="genie-btn-flex-wrapper">
              <li
                v-for="crew in allPhotographerOptions"
                :key="crew.photographer.id"
              >
                <button
                  class="soona-genie-button crew-button"
                  :class="{
                    'genie-btn--selected': crew.selected,
                    'preferred-bay':
                      selectedBay && crew.photographer.preferredBay,
                  }"
                  :disabled="crew.disabled"
                  @click="
                    crew.selected
                      ? crewSelected(null)
                      : crewSelected(crew.photographer)
                  "
                >
                  <span class="crew-section">
                    <img
                      :src="crew.photographer.avatar || fallbackAccountImage"
                      alt="crew avatar"
                      class="crew-avatar"
                    />
                    <span class="crew-text-section">
                      <span class="crew-text crew-name">
                        {{ crew.photographer.name }}
                      </span>
                      <span class="crew-text crew-role">
                        photographer
                        {{
                          !crew.disabled && selectedDate
                            ? ` booked ${crew.photographer.hoursWorked}hrs / 6hrs`
                            : ''
                        }}
                      </span>
                    </span>
                  </span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal"></div>
      <div class="field-body">
        <div class="field-toggle">
          <SoonaToggle
            v-model:model-value="toggleReschedule"
            :disabled="rescheduleDisabled"
            type="switch"
            label="reschedule option"
          />
          <p v-if="isReschedulable" class="text-s reschedule-url-copy">
            <SoonaButton
              variation="icon-plain-gray"
              @on-click="copyText(rescheduleUrl)"
            >
              <SoonaIcon name="duplicate" size="medium" />
              <span class="u-a11y-only">copy url</span>
            </SoonaButton>
            <strong v-show="copySuccess" class="u-small--heavy copied"
              >copied!</strong
            >
          </p>
        </div>
      </div>
    </div>
    <hr class="soona-genie-hr" />
  </div>
</template>

<script>
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { mapMutations, mapState, mapActions, mapGetters } from 'vuex';
import * as types from 'src/store/mutation-types';
import uniqBy from 'lodash/uniqBy';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaToggle from 'src/components/ui_library/SoonaToggle.vue';
import { useCopy } from 'src/composables/useCopy';
import fallbackAccountImage from 'images/account-placeholder.svg';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

export default {
  components: {
    SoonaButton,
    SoonaIcon,
    SoonaToggle,
  },
  setup() {
    const { copyText, copySuccess } = useCopy();

    return { copyText, copySuccess, fallbackAccountImage };
  },
  data() {
    return {
      scheduleStatuses: [
        'en route',
        'duplicate',
        'needs pre pro',
        'no response',
        'on hold',
        'pre ship',
        'pro service',
        'ready to go',
        'waiting on product',
      ],
    };
  },
  computed: {
    ...mapState({
      reservation: state => state.reservations.reservation,
      currentLocation: state => state.locations.currentLocation,
      selectedScheduleStatus: state => state.reservations.scheduleStatus,
      suggestedSlots: state => state.soonaGenie.suggestedSlots,
      selectedPhotographer: state => state.soonaGenie.selectedPhotographer,
      selectedDate: state => state.soonaGenie.selectedDate,
      selectedTime: state => state.soonaGenie.selectedTime,
      selectedBay: state => state.soonaGenie.selectedBay,
    }),
    ...mapGetters(['isReschedulable', 'rescheduleUrl']),
    ...mapGetters('soonaGenie', ['isBaySelected']),
    ...mapGetters({
      reservationDuration: 'duration',
      reservationBookableSpaceIds: 'bookableSpaceIds',
    }),
    ...mapGetters('soonaGenie', ['remainingValidSlots']),
    allBayOptions() {
      let bayOptions = this.bayOptions(this.suggestedSlots);
      return bayOptions;
    },
    allPhotographerOptions() {
      return this.photographerOptions(this.suggestedSlots);
    },
    allTimeOptions() {
      return this.timeOptions(this.suggestedSlots);
    },
    allDateOptions() {
      return this.dateOptions(this.suggestedSlots);
    },
    toggleReschedule: {
      get() {
        return this.reservation.reschedulable;
      },
      set(bool) {
        this.setIsReschedulable(bool);
      },
    },
    rescheduleDisabled() {
      return !this.selectedBay || !this.selectedDate;
    },
    duration: {
      get() {
        return this.reservationDuration;
      },
      set(value) {
        this.setDurationAndLoadAvailability(value);
        this.resetSoonaGenie();
        this.loadSuggestedSlots(this.reservation.id);
      },
    },
    scheduleStatus: {
      get() {
        return this.selectedScheduleStatus
          ? this.selectedScheduleStatus.replace(/[_-]/g, ' ')
          : this.selectedScheduleStatus;
      },
      set(value) {
        this.SET_SCHEDULE_STATUS(value.replace(/ /g, '_'));
      },
    },
    durationOptions() {
      return [1800, 3600, 7200, 10800, 14400, 18000, 21600];
    },
    isCompleted() {
      return this.reservation.order_status === 'completed';
    },
    selectedTimeConvertedtoAccountTimeZone() {
      if (this.selectedTime?.slotStart) {
        let convertedTime = dayjs(this.selectedTime.slotStart)
          .tz(this.accountPreferredTimeZone)
          .format('h:mma z');
        return `${convertedTime} for client`;
      } else {
        return '';
      }
    },
    accountPreferredTimeZone() {
      return this.reservation.account.preferred_timezone
        ? this.reservation.account.preferred_timezone
        : false;
    },
  },
  watch: {
    duration(newValue, oldValue) {
      if (!!oldValue && newValue !== oldValue) {
        this.dateSelected(null);
        this.timeSelected(null);
        this.baySelected(null);
        this.crewSelected(null);
      }
    },
  },
  mounted() {
    this.resetSoonaGenie();
    if (this.reservation.id) {
      this.loadSuggestedSlots(this.reservation.id);
    }
  },
  methods: {
    ...mapMutations([types.SET_SCHEDULE_STATUS]),
    ...mapActions(['setDurationAndLoadAvailability', 'setIsReschedulable']),
    ...mapActions('soonaGenie', [
      'selectDate',
      'selectTime',
      'selectBay',
      'selectPhotographer',
      'loadSuggestedSlots',
      'resetSoonaGenie',
    ]),
    displayDate(date) {
      return dayjs(date)
        .tz(this.reservation.location.timezone)
        .format('ddd M/D');
    },
    dateValue(date) {
      return dayjs(date)
        .tz(this.reservation.location.timezone)
        .format('YYYY-M-D');
    },
    dateOptions(slots) {
      let uniqueDates = slots
        .map(slot => slot.start)
        .reduce((acc, cur) => {
          return uniqBy(
            [
              ...acc,
              {
                date: this.dateValue(cur),
                formatted: this.displayDate(cur),
                timezone: this.reservation.location.timezone,
              },
            ],
            'date'
          );
        }, [])
        .map(date => ({
          ...date,
          disabled: !this.remainingValidSlots.find(
            slot => this.dateValue(slot.start) === date.date
          ),
          selected: date.date === this.selectedDate?.date,
        }));
      return uniqueDates;
    },
    timeValue(dateTime) {
      return dayjs(dateTime)
        .tz(this.reservation.location.timezone)
        .format('h:mm a');
    },
    sortableTimeValue(date) {
      return dayjs(date).format('HH:mm');
    },
    timeOptions(slots) {
      let times = slots
        .map(slot => slot.start)
        .reduce((acc, cur) => {
          return uniqBy(
            [
              ...acc,
              {
                slotStart: cur,
                time: this.timeValue(cur),
                sortableTime: this.sortableTimeValue(cur),
                timezone: this.reservation.location.timezone,
              },
            ],
            'time'
          );
        }, []);
      let sortedTimes = times.sort((a, b) =>
        a.sortableTime > b.sortableTime ? 1 : -1
      );
      let uniqueTimes = sortedTimes.map(time => ({
        ...time,
        disabled: !this.remainingValidSlots.find(slot => {
          return this.timeValue(slot.start) === time.time;
        }),
        selected: time.time === this.selectedTime?.time,
      }));
      return uniqueTimes;
    },
    bayOptions(slots) {
      return slots
        .reduce((acc, cur) => {
          return uniqBy([...acc, cur.bay], 'id');
        }, [])
        .map(bay => ({
          ...bay,
          disabled: !this.remainingValidSlots.find(
            slot => slot.bay.id === bay.id
          ),
          selected: bay.id === this.selectedBay?.id,
        }));
    },
    photographerOptions(slots) {
      let suggestedPhotographersObjects = slots.map(slot => slot.photographer);

      let uniquePhotographerObjects = uniqBy(
        suggestedPhotographersObjects,
        'id'
      );

      let selectedPhotographerObjects = uniquePhotographerObjects.map(
        photographer => ({
          photographer,
          disabled: !this.remainingValidSlots.find(
            slot => slot.photographer.id === photographer.id
          ),
          selected: photographer.id === this.selectedPhotographer?.id,
        })
      );

      return selectedPhotographerObjects;
    },
    timeSelected(time) {
      this.selectTime(time);
    },
    formatSecondsToHours(seconds) {
      let hours = seconds / 60 / 60;
      let units = hours < 2 ? 'hour' : 'hours';
      return hours + ' ' + units;
    },
    dateSelected(date) {
      this.selectDate(date);
    },
    baySelected(bay) {
      this.selectBay(bay);
    },
    crewSelected(photographer) {
      this.selectPhotographer(photographer);
    },
  },
};
</script>
<style lang="scss">
@use '@/variables';

.update-reservation-modal {
  .soona-genie-hr {
    margin-top: 0;
  }

  .start-time-timezone {
    font-size: 12px;
  }

  .soona-genie-vselect {
    .vs__dropdown-toggle,
    .vs__dropdown-menu {
      width: 220px;
    }
  }

  .crew-button {
    height: 100%;
    position: relative;

    &.preferred-bay {
      &::before {
        content: 'bay match';
        position: absolute;
        top: 0;
        transform: translateY(-50%);
        left: 0.5rem;
        font-size: 0.5rem;
        background-color: variables.$periwink-blue-40;
        border-radius: 999px;
        color: variables.$white-default;
        padding: 0.125rem 0.3rem;
      }
    }

    .crew-section {
      display: flex;
      align-items: center;
      padding: 0.3rem 0;

      .crew-avatar {
        width: 25px;
        height: 25px;
        -o-object-fit: cover;
        object-fit: cover;
        border-radius: 290486px;
      }

      .crew-text-section {
        display: flex;
        flex-direction: column;
        margin-left: 15px;

        .crew-text {
          align-self: flex-start;
          font-weight: 500;
        }

        .crew-name {
          font-size: 12px;
        }

        .crew-role {
          font-size: 10px;
          text-align: left;
          margin-bottom: 4px;
        }
      }
    }
  }

  .soona-genie-schedule-section {
    display: flex;
    flex-direction: row;
  }
}

.reschedule {
  margin-bottom: 15px;
}

.reschedule-url-copy {
  align-items: center;
  display: flex;
  justify-content: center;
  white-space: nowrap;
  font-size: 16px;
}

.copy {
  color: variables.$gray-20;
  cursor: pointer;
  margin-left: 0.25rem;
}

.copied {
  align-items: center;
  background-color: variables.$white-default;
  border-radius: 0.125rem;
  color: variables.$periwink-blue-50;
  display: inline-flex;
  justify-content: center;
}

.genie-btn-flex-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.genie-btn--selected {
  border-color: variables.$periwink-blue-70 !important;
}
</style>
