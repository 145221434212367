<script setup>
import { computed, ref } from 'vue';

import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaUploadDragAndDropModal from '@/components/uploader/SoonaUploadDragAndDropModal.vue';
import SoonaUploadProgressModal from '@/components/uploader/SoonaUploadProgressModal.vue';

import { useUploader } from '@/composables/useUploader.js';
import { useUpdateDigitalAsset } from '@/queries/digital_assets/useUpdateDigitalAsset';

const props = defineProps({
  digitalAsset: {
    type: Object,
    required: true,
  },
  variation: {
    type: String,
    default: 'secondary-black',
  },
});

const accountId = computed(() => props.digitalAsset.account_id);
const { mutate: updateDigitalAsset, isPending } =
  useUpdateDigitalAsset(accountId);

const attachRaw = async blob => {
  updateDigitalAsset({
    assetId: props.digitalAsset?.id,
    body: {
      raw: blob.signed_id,
    },
  });
};

const dragAndDropModalVisible = ref(false);
const maxConcurrent = ref(1);
const uploadInterval = ref(100);
const fileSizeLimit = ref('');
const label = ref('');
const uploadType = ref('');
const handleUploadFunc = ref(null);
const startUploadFunc = ref(null);
const handleDropFunc = ref(null);
const handleCancelFunc = ref(null);
const activeUploadsLocal = ref([]);

function hideDragAndDropModal() {
  dragAndDropModalVisible.value = false;
}

function onUploadComplete(blob) {
  attachRaw(blob);
}

function showDragAndDropModalEdit() {
  label.value = 'raw';
  fileSizeLimit.value = '';
  const {
    handleUpload,
    handleDrop,
    activeUploads,
    cancelUploads,
    startUpload,
  } = useUploader(
    maxConcurrent,
    uploadInterval,
    fileSizeLimit,
    null,
    null,
    onUploadComplete
  );
  startUploadFunc.value = startUpload;
  handleUploadFunc.value = handleUpload;
  handleDropFunc.value = handleDrop;
  handleCancelFunc.value = cancelUploads;
  activeUploadsLocal.value = activeUploads.value;
  uploadType.value = 'edit';
  dragAndDropModalVisible.value = true;
}
</script>
<template>
  <SoonaButton
    :variation="variation"
    :copy="`${!!digitalAsset?.raw?.url ? 'replace' : 'upload'} raw`"
    :disable="isPending"
    :loading="isPending"
    @click="showDragAndDropModalEdit()"
  />
  <SoonaUploadDragAndDropModal
    v-if="dragAndDropModalVisible"
    accept=""
    :active-uploads="activeUploadsLocal"
    :is-multiple="false"
    :label="label"
    @close="hideDragAndDropModal"
    @handle-drop="handleDropFunc"
    @handle-upload="handleUploadFunc"
  />
  <SoonaUploadProgressModal
    v-if="activeUploadsLocal.length > 0"
    :is-multiple="true"
    :active-uploads="activeUploadsLocal"
    :label="label"
    :hide-label="false"
    @cancel-uploads="handleCancelFunc"
    @start-upload="startUploadFunc"
  />
</template>
