<script setup>
import { computed, ref } from 'vue';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';
import ReturnAddressModal from 'src/components/user/anytime/inventory/return-address/ReturnAddressModal.vue';
import { useAddresses } from 'src/queries/useAddresses';

const props = defineProps({
  accountId: {
    type: [Number, String],
    required: true,
  },
});
const accountId = computed(() => props.accountId);

const { data: addressData, isLoading } = useAddresses(accountId);

const defaultAddress = computed(() => addressData.value?.default || undefined);

const headerText = computed(() => {
  if (defaultAddress.value) {
    return `we’ll be returning your products soon`;
  } else {
    return 'we need your return address!';
  }
});

const bodyText = computed(() => {
  if (defaultAddress.value) {
    return `you can review and update the return address for each package.`;
  } else {
    return `we’ll send back items from your recent shoot as soon as we have an address.`;
  }
});

const newAddressModalOpen = ref(false);

function closeModal() {
  newAddressModalOpen.value = false;
}
function openModal() {
  newAddressModalOpen.value = true;
}
</script>

<template>
  <ReturnAddressModal
    v-if="newAddressModalOpen"
    :account-id="accountId"
    :is-forced-default="!defaultAddress"
    @cancel="closeModal"
    @success="closeModal"
  />
  <div v-if="!isLoading" class="post-shoot-prompt">
    <div class="post-shoot-prompt__emoji">📬</div>
    <div class="post-shoot-prompt__body-wrapper">
      <div class="post-shoot-prompt__header">
        {{ headerText }}
      </div>
      <div class="post-shoot-prompt__body">
        {{ bodyText }}
        <br />
        if you prefer to have it discarded or stored, you can change handling
        options for any item in your inventory now.
      </div>
      <div class="post-shoot-prompt__actions">
        <SoonaButton
          v-if="!defaultAddress"
          copy="add return address"
          @click="openModal"
        >
          <template #icon-left>
            <SoonaIcon name="plus" />
          </template>
        </SoonaButton>
        <SoonaButton
          element="a"
          copy="manage inventory"
          variation="secondary-black"
          :href="`/user/anytime#/account/${props.accountId}/products/inventory`"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';
.post-shoot-prompt {
  background: variables.$periwink-blue-20;
  border: 0.0625rem solid variables.$periwink-blue-30;
  border-radius: 0.3125rem;
  padding: 1rem;
  display: flex;
  flex-direction: row;

  &__body-wrapper {
    margin-left: 0.625rem;
  }

  &__header {
    @include variables_fonts.u-subheader--heavy;
  }

  &__actions {
    margin-top: 1.25rem;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 1rem;
  }
}
</style>
