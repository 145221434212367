<script setup>
import { computed, ref, watch } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaFilterSingle from '@/components/ui_library/SoonaFilterSingle.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';
import SoonaNotification from '@/components/ui_library/SoonaNotification.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';
import SoonaTooltip from '@/components/ui_library/SoonaTooltip.vue';
import SoonaUploadForm from '@/components/uploader/SoonaUploadForm.vue';
import SoonaUploadProgressModal from '@/components/uploader/SoonaUploadProgressModal.vue';
import { usePriorityError } from '@/composables/usePriorityError';
import { useSoonaToast } from '@/components/ui_library/soona_toast/useSoonaToast';
import { useUploader } from '@/composables/useUploader.js';
import { useUpdateShotList } from '@/queries/shot_list/useUpdateShotList';
import { useRegexHelper } from '@/composables/useRegexHelper';

const props = defineProps({
  reservationId: {
    type: [Number, String],
    required: true,
  },
  shotListDocuments: {
    type: Object,
    required: false,
    default: () => ({}),
  },
});

const emit = defineEmits(['close-dialog']);

const selectedFilter = ref('pdf-upload');
const filterOptions = [
  { value: 'pdf-upload', label: 'PDF upload', iconName: 'cloud-up-arrow' },
  { value: 'share-link', label: 'share link', iconName: 'link' },
];

const reservationId = computed(() => props.reservationId);

const { addToast } = useSoonaToast();

// uploads
const maxConcurrent = ref(1);
const uploadInterval = ref(100);
const fileSizeLimit = ref(50);
const activeUploadsLocal = ref([]);
const cancelUploadsLocal = ref(() => {});
const errorMessage = ref({});
const acceptedFileTypes = ref('.pdf');
const files = ref([]);
const attachmentComplete = ref(false);

const existingShotListDocumentIds = computed(() =>
  props.shotListDocuments?.documents?.map(doc => doc.signed_id)
);

const removeUploadedFile = id => {
  files.value = files.value.filter(file => file.id !== id);

  if (files.value.length === 0) {
    attachmentComplete.value = false;
  }
};

const handleUploadError = errMsg => {
  cancelUploadsLocal.value();
  errorMessage.value.message = errMsg;
};

const handleUploadComplete = blob => {
  if (files.value.includes(blob.id)) return;

  files.value.push({
    id: blob.id,
    filename: blob.filename,
    byte_size: blob.byte_size,
    signed_id: blob.signed_id,
  });

  attachmentComplete.value = true;
};

const { handleUpload, handleDrop, activeUploads, startUpload, cancelUploads } =
  useUploader(
    maxConcurrent,
    uploadInterval,
    fileSizeLimit,
    null,
    handleUploadError,
    handleUploadComplete,
    null
  );

activeUploadsLocal.value = activeUploads.value;
cancelUploadsLocal.value = cancelUploads;

const completedUploads = computed(() =>
  activeUploads.value.filter(upload => upload.completed)
);

const handleCancelUploads = () => {
  cancelUploads();
  attachmentComplete.value = false;

  if (completedUploads.value.length === 0) {
    addToast('upload canceled', {
      variation: 'info',
      iconName: 'xmark',
    });
  }
};

const isUploading = computed(() => activeUploads.value.length > 0);

// link
const shotListLink = ref('');
const validLink = ref(false);

watch(shotListLink, () => {
  errorMessage.value = {};

  if (shotListLink.value) {
    attachmentComplete.value = true;
  } else {
    attachmentComplete.value = false;
  }
});

const existsingShotListLinks = computed(
  () => props.shotListDocuments?.urls || []
);

const { httpsRegex } = useRegexHelper();

const validateLink = url => {
  const isValid = httpsRegex.test(url);
  if (isValid) {
    validLink.value = true;
    return;
  } else {
    errorMessage.value.message = 'link must include https://';
    attachmentComplete.value = false;
    validLink.value = false;
    return;
  }
};

// save shot list attachments
const shotListId = computed(() => props.shotListDocuments?.id);

const {
  mutate: updateShotList,
  error: updateError,
  isPending: updatePending,
} = useUpdateShotList(reservationId, shotListId);

const handleSubmit = () => {
  let params = {};
  let type = '';

  if (selectedFilter.value === 'pdf-upload') {
    type = files.value.length > 1 ? 'PDFs' : 'PDF';

    let file_ids = files.value.map(file => file.signed_id);
    params = {
      documents: file_ids.concat(existingShotListDocumentIds.value),
    };
  } else {
    validateLink(shotListLink.value);
    type = 'link';

    if (!validLink.value) {
      return;
    } else {
      params = {
        urls: [...existsingShotListLinks.value, shotListLink.value],
      };
    }
  }

  updateShotList(params, {
    onSuccess: () => {
      addToast(`successfully added ${type}`, {
        variation: 'success',
      });

      emit('close-dialog');
    },
  });
};

const isLoading = computed(() => updatePending.value);
const priorityError = usePriorityError(updateError, errorMessage);
</script>
<template>
  <SoonaDialog
    class="upload-shot-list"
    max-width="33rem"
    @close="emit('close-dialog')"
  >
    <template #heading>upload your own shot list</template>

    <div class="upload-shot-list__content">
      <SoonaLoading v-if="isLoading" is-loading is-contained />
      <SoonaFilterSingle
        v-model="selectedFilter"
        class="upload-shot-list__options"
        :options="filterOptions"
      >
        <template #option="{ iconName, label }">
          <SoonaIcon v-if="iconName" size="medium" :name="iconName" />
          {{ label }}
        </template>
      </SoonaFilterSingle>

      <div
        v-if="selectedFilter === 'pdf-upload'"
        class="upload-shot-list__uploader"
      >
        <h3 class="u-body--heavy">PDF upload</h3>
        <div
          v-if="attachmentComplete"
          class="upload-shot-list__completed-uploads"
        >
          <div
            v-for="file in files"
            :key="file.id"
            class="upload-shot-list__uploaded"
          >
            <div class="upload-shot-list__uploaded--details">
              <SoonaIcon name="file" />
              <p class="u-body--heavy upload-shot-list__filename">
                {{ file.filename }}
              </p>
              <p class="upload-shot-list__uploaded--size">
                {{ Math.floor(+file.byte_size / 1000) }}KB
              </p>
              <SoonaTooltip>
                <template
                  #default="{ setRef, mouseenter, focus, mouseleave, blur }"
                >
                  <SoonaButton
                    :ref="el => setRef(el)"
                    variation="icon-plain-gray"
                    size="small"
                    @on-click="removeUploadedFile(file.id)"
                    @mouseenter="mouseenter"
                    @focus="focus"
                    @mouseleave="mouseleave"
                    @blur="blur"
                  >
                    <SoonaIcon name="xmark" />
                  </SoonaButton>
                </template>
                <template #tooltip-content>remove this file</template>
              </SoonaTooltip>
            </div>
          </div>
        </div>

        <SoonaUploadForm
          v-else
          :accept="acceptedFileTypes"
          :active-uploads="activeUploads"
          is-multiple
          @drop="
            $event => {
              errorMessage = {};
              handleDrop($event);
            }
          "
          @upload="
            $event => {
              errorMessage = {};
              handleUpload($event);
            }
          "
        />
        <SoonaUploadProgressModal
          v-if="isUploading"
          :active-uploads="activeUploadsLocal"
          :is-multiple="false"
          label="pdf"
          @cancel-uploads="handleCancelUploads"
          @start-upload="startUpload"
        />
        <SoonaError v-if="priorityError" class="">
          {{ priorityError }}
        </SoonaError>
      </div>
      <div v-else>
        <SoonaNotification class="upload-shot-list__link--notification">
          <template #icon>
            <SoonaIcon
              name="circle-information"
              size="small"
              class="upload-shot-list__link--icon"
            />
          </template>
          <p>
            your link’s sharing permissions must be set to “public” so anyone on
            the soona team can access it.
          </p>
        </SoonaNotification>
        <div class="upload-shot-list__link">
          <h3 class="u-body--heavy">link URL</h3>
          <SoonaTextfield
            v-model="shotListLink"
            type="text"
            placeholder="https://yourfile.com"
          />
          <SoonaError
            v-if="priorityError"
            class="upload-shot-list__link--error"
          >
            {{ priorityError }}
          </SoonaError>
        </div>
      </div>
    </div>

    <template #footer="{ close }">
      <SoonaButton copy="cancel" variation="tertiary" @on-click="close" />
      <SoonaButton
        copy="save"
        :disabled="isLoading || !attachmentComplete"
        @on-click="handleSubmit"
      />
    </template>
  </SoonaDialog>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.upload-shot-list {
  &__options {
    margin-bottom: 2rem;
  }

  &__uploader {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }

  &__completed-uploads {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__uploaded {
    align-items: center;
    border: 0.0625rem solid variables.$gray-30;
    border-radius: 0.3125rem;
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    padding: 0.75rem;

    &--details {
      align-items: center;
      display: flex;
      gap: 0.5rem;
      width: 100%;
    }

    &--size {
      @include variables_fonts.u-body--regular;

      color: variables.$gray-60;
    }

    svg {
      flex-shrink: 0;
    }
  }

  &__filename {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

  &__link {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    &--notification {
      align-items: center;
      border-color: variables.$periwink-blue-30;
      background-color: variables.$periwink-blue-20;
      display: flex;
      margin-bottom: 2rem;
    }

    &--icon {
      color: variables.$periwink-blue-60;
    }

    &--error {
      margin: 0;
    }
  }
}
</style>
