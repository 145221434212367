import { Extension } from '@tiptap/core';
import { Plugin, PluginKey } from 'prosemirror-state';

export const PreventNewLines = Extension.create({
  name: 'preventNewLines',

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('eventHandler'),
        props: {
          handleKeyDown(view, event) {
            if (event.key === 'Enter') {
              // returning 'true' tells ProseMirror to execute preventDefault
              return true;
            }
          },
          attributes: {
            // tells screen readers whether the input is multiline or not
            'aria-multiline': this.options.enabled,
          },
        },
      }),
    ];
  },
});
