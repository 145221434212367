<template>
  <div class="SuggestedBookingCreated">
    <hr class="order-divider" />
    <div class="suggested-booking-header">
      <p class="header-text u-body--all-caps-black">
        {{ header }}
      </p>
      <button class="u-button-reset" @click="toggleOpen">
        <SoonaIcon :class="['message-chevron', { open }]" name="chevron-down" />
        <span class="u-a11y-only">view complete suggested booking note</span>
      </button>
    </div>
    <div
      ref="collapsible"
      class="suggested-booking-note-container"
      :style="{
        height: open ? '100%' : '0px',
        padding: open ? '16px' : '0rem',
      }"
    >
      <div class="suggested-booking-details">
        <p>created by: {{ creator }}</p>
        <p>created at: {{ formatOrderDate }} {{ formatOrderTime }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';

export default {
  name: 'SuggestedBookingCreated',
  components: {
    SoonaIcon,
  },
  props: {
    suggestedBooking: Object,
    creator: String,
  },
  data() {
    return {
      open: false,
    };
  },
  computed: {
    orderDate() {
      return new Date(this.suggestedBooking.created_at);
    },
    formatOrderDate() {
      return this.orderDate.toLocaleDateString();
    },
    formatOrderTime() {
      return this.orderDate.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
        timeZoneName: 'short',
      });
    },
    header() {
      return `suggested booking created by ${this.creator} ${this.formatOrderDate} ${this.formatOrderTime}`;
    },
  },
  methods: {
    toggleOpen() {
      return (this.open = !this.open);
    },
    collapsibleHeight() {
      return this.$refs.collapsible
        ? this.$refs.collapsible.scrollHeight
        : 1000;
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';

.SuggestedBookingCreated {
  margin-bottom: 1.5rem;

  .order-divider {
    height: 4px;
    margin: 0 0 1.5rem;
    background-color: variables.$gray-30;
  }
  .suggested-booking-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    width: 100%;
    background-color: variables.$green-apple-10;
  }

  .header-text {
    color: variables.$gray-90;
  }

  .message-chevron {
    color: variables.$gray-90;
    transition: transform 300ms ease-out;
    cursor: pointer;

    &.open {
      transform: rotate(180deg);
    }
  }
  .suggested-booking-note-container {
    transition: max-height 300ms ease-out;
    overflow: hidden;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
}
</style>
