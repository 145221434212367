<script setup>
import { ref } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

defineProps({
  pageTitle: {
    type: String,
    required: true,
  },
  image: {
    type: String,
    required: true,
  },
  dialogContent: {
    type: Object,
    required: true,
  },
});

const showHelpDialog = ref(false);

const toggleShowHelpDialog = value => {
  showHelpDialog.value = value;
};
</script>

<template>
  <div>
    <SoonaButton variation="tertiary" @click="toggleShowHelpDialog(true)">
      <template #icon-left>
        <SoonaIcon
          name="circle-question"
          size="small"
          style="vertical-align: middle"
        />
      </template>
      <span class="u-label--regular">{{
        dialogContent.tooltipText ?? 'how it works'
      }}</span>
    </SoonaButton>
    <SoonaDialog
      v-if="showHelpDialog"
      class="action-template-header-help-dialog"
      @close="toggleShowHelpDialog(false)"
    >
      <template #heading>{{ pageTitle }}</template>
      <template #default>
        <div class="action-template-header-help-dialog__image">
          <img :src="image" alt="" />
        </div>

        <div
          v-for="(bullet, i) in dialogContent.bullets"
          :key="i"
          class="action-template-header-help-dialog__section"
        >
          <div class="action-template-header-help-dialog__section--icon">
            <SoonaIcon
              :name="bullet.icon"
              size="medium"
              style="vertical-align: middle"
            />
          </div>
          <div class="action-template-header-help-dialog__section--text">
            <h3 class="u-body--heavy">
              {{ bullet.title }}
            </h3>
            <span class="u-body--regular" v-html="bullet.description" />
          </div>
        </div>
      </template>
      <template #footer="{ close }">
        <SoonaButton type="button" size="medium" @click="close">
          got it
        </SoonaButton>
      </template>
    </SoonaDialog>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables_fonts';

.action-template-header-help-dialog {
  &__image {
    width: 16.875rem;
    height: 11.25rem;
    border-radius: 0.5rem;
    overflow: hidden;
    margin-bottom: 1.5rem;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__section {
    display: flex;
    flex-direction: row;
    gap: 1rem;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    &--text {
      span {
        :deep(ul) {
          padding-left: 2rem;
          list-style: disc;
        }
      }
    }
  }
}
</style>
