<script setup>
import { computed, provide, ref } from 'vue';
import { useMediaQuery } from '@vueuse/core';
import { useRouter } from 'vue-router';
import { usePriorityError } from '@/composables/usePriorityError';
import {
  MEDIA_POPOVER_BACK_KEY,
  MEDIA_POPOVER_BACK_TEXT,
  MEDIA_POPOVER_NEXT_KEY,
  MEDIA_POPOVER_PREV_KEY,
} from '@/components/user/anytime/gallery/media-editor-routing-keys';
import MediaEditor from '@/components/user/anytime/gallery/media-editor/MediaEditor.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import { useReservation } from '@/composables/useReservation';
import BookingAssetCarousel from '@/components/user/anytime/reservation/editor/BookingAssetCarousel.vue';
import { useReservationDigitalAssetByDigitalAssetId } from '@/queries/reservation_digital_assets/useReservationDigitalAssetByDigitalAssetId';
import { useUnPurchasedBaseFilters } from '@/components/user/anytime/reservation/editor/useUnpurchasedBaseFilters';

const props = defineProps({
  isChatOpen: {
    type: Boolean,
  },
  userHasScrolledPastBreakpoint: {
    type: Boolean,
  },
  reservationId: {
    type: [String, Number],
    required: true,
  },
  digitalAssetId: {
    type: [String, Number],
    required: true,
  },
  filter: {
    type: String,
    default: null,
  },
});

const router = useRouter();

const reservationId = computed(() => props.reservationId);
const digitalAssetId = computed(() => props.digitalAssetId);

const matchMediaIsWide = useMediaQuery('(min-width: 60rem)');

const filter = computed(() => props.filter);
const { baseFilters } = useUnPurchasedBaseFilters(filter);
const {
  data: reservationDigitalAsset,
  isLoading: isFileLoading,
  error,
} = useReservationDigitalAssetByDigitalAssetId({
  reservationId: reservationId,
  digitalAssetId: digitalAssetId,
  filters: baseFilters,
});

const { nameTruncated } = useReservation(reservationId);

const priorityError = usePriorityError(error);

// routes
const createRouteLocation = id => {
  if (!id) return null;

  return {
    name: 'reservation-asset-view',
    params: {
      digitalAssetId: id,
      reservationId: reservationId.value,
    },
    query: {
      filter: props.filter,
    },
  };
};

const backUrl = computed(() => {
  return `/reservation/${reservationId.value}`;
});

const previousRoute = computed(() => {
  return createRouteLocation(
    reservationDigitalAsset.value?.previous?.digital_asset_id
  );
});

const nextRoute = computed(() => {
  return createRouteLocation(
    reservationDigitalAsset.value?.next?.digital_asset_id
  );
});

provide(MEDIA_POPOVER_BACK_KEY, backUrl);
provide(MEDIA_POPOVER_PREV_KEY, previousRoute);
provide(MEDIA_POPOVER_NEXT_KEY, nextRoute);
provide(
  MEDIA_POPOVER_BACK_TEXT,
  computed(() => `in ${nameTruncated.value}`)
);

// redirect back to gallery
const isRedirecting = ref(false);
const goToGallery = () => {
  isRedirecting.value = true;
  router.push(backUrl.value);
};
</script>
<template>
  <MediaEditor
    v-if="!isRedirecting"
    context="booking_gallery"
    :asset="reservationDigitalAsset?.digital_asset"
    :is-asset-loading="isFileLoading"
    :reservation-id="reservationId"
    @close="goToGallery"
  >
    <template v-if="priorityError" #error>
      <SoonaError>
        {{ priorityError }}
      </SoonaError>
    </template>
    <template #carousel>
      <BookingAssetCarousel
        v-if="matchMediaIsWide"
        :reservation-id="reservationId"
        :active-asset-id="reservationDigitalAsset?.id"
        :filter="filter"
      />
    </template>
  </MediaEditor>
</template>
