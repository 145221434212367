<script setup>
import { computed, ref } from 'vue';
import { usePriorityErrors } from '@/composables/usePriorityErrors';
import { useUpdatePassword } from '@/queries/users/useUpdatePassword';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaForm from '@/components/ui_library/SoonaForm.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';

const props = defineProps({
  resetPasswordToken: {
    type: String,
  },
});

const { mutate, error: updateError } = useUpdatePassword();
const errors = usePriorityErrors(updateError);

const password = ref(null);
const passwordConfirmation = ref(null);

const formFilled = computed(() => password.value && passwordConfirmation.value);

const changePasswordClicked = () => {
  mutate(
    {
      password: password.value,
      passwordConfirmation: passwordConfirmation.value,
      resetPasswordToken: props.resetPasswordToken,
    },
    {
      onSuccess: () => {
        window.location.href = '/';
      },
    }
  );
};
</script>

<template>
  <div class="update-password soona-container">
    <h2 class="has-text-centered u-headline--heavy">Change your password</h2>

    <div class="columns is-centered">
      <div class="column is-half">
        <SoonaForm v-slot="{ hasErrors }" @submit="changePasswordClicked">
          <SoonaTextfield
            v-model:model-value="password"
            label="new password"
            name="password"
            type="password"
            autocomplete="new-password"
            placeholder="********"
            :rules="['required', 'minlength']"
            :minlength="8"
            required
          />
          <SoonaTextfield
            v-model:model-value="passwordConfirmation"
            label="confirm new password"
            name="password"
            type="password"
            autocomplete="new-password"
            placeholder="********"
            :password="password"
            :rules="['match']"
          />

          <SoonaError v-for="(error, i) in errors" :key="i" :no-margin="true">
            {{ error.message }}
          </SoonaError>

          <div class="main-btn">
            <SoonaButton
              :disabled="hasErrors || !formFilled"
              copy="change password"
              type="submit"
            />
          </div>

          <div class="action-btns">
            <SoonaButton
              element="a"
              copy="create account"
              href="/#/sign-up"
              variation="tertiary"
            />
            <SoonaButton
              element="a"
              copy="sign in"
              href="/#/sign-in"
              variation="tertiary"
            />
          </div>
        </SoonaForm>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.main-btn {
  display: flex;
  justify-content: center;
  padding-top: 2rem;
}

.action-btns {
  display: flex;
  gap: 2rem;
  justify-content: center;
  padding-top: 2rem;
  width: 100%;
}
</style>
