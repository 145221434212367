<script setup>
import { ref, computed } from 'vue';
import ListingInsightsHelpDialog from '../ListingInsightsHelpDialog.vue';
import SoonaBackButton from '@/components/ui_library/SoonaBackButton.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDonutChart from '@/components/ui_library/SoonaDonutChart.vue';
import SoonaFlag from '@/components/ui_library/SoonaFlag.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaMeter from '@/components/ui_library/SoonaMeter.vue';
import { Gray20 } from '@/variables.module.scss';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useListingsListQueryParams } from '../useListingsListQueryParams';
import PlaceholderImage from 'images/product-placeholder-periwink.jpg';
import { useGetIntegrations } from '@/queries/useGetIntegrations';
import { isAmazonIntegration } from '@/components/user/anytime/products/integration-utils';
import ReconnectAmazonDialog from '@/components/user/anytime/products/ReconnectAmazonDialog.vue';

const props = defineProps({
  accountId: { type: String, required: true },
  externalProductId: { type: String, required: true },
  integrationId: { type: String, required: true },
  listing: { type: Object, required: true },
  platform: { type: String, required: true },
});

const emits = defineEmits(['refreshListing']);

const showReconnectAmazonDialog = ref(false);
const accountId = computed(() => props.accountId);

const { data: integrationsData } = useGetIntegrations(accountId);

const integrations = computed(() => integrationsData.value ?? []);

const amazonIntegration = computed(() =>
  integrations.value.find(isAmazonIntegration)
);

dayjs.extend(relativeTime);

const { backToStoreList } = useListingsListQueryParams();
const previousPage = () => {
  backToStoreList();
};

const listing = computed(() => props.listing);
const platform = computed(() => props.platform);
const integrationLogo = {
  Amazon: 'amazon-logo',
  Shopify: 'shopify-logo',
};

// visual average
const formattedMeterData = computed(() => {
  return {
    value: props.listing.visual_average.score.value,
    label: props.listing.visual_average.score.label,
    minValue: 0,
    maxValue: 1,
    low: 0.33,
    high: 0.66,
  };
});

// score averages
const iconLookup = {
  'gallery count': 'album',
  'image quality': 'aperture',
  'visual mix': 'stars',
};

const visualAverageScore = computed(
  () => props.listing?.visual_average.score.label
);
const classNames = computed(() => {
  let classes = `listing-details-summary listing-details-summary__background--${visualAverageScore.value}`;

  return classes;
});

const scoreBreakdown = computed(() => {
  return listing.value.score_breakdown.filter(x => x.rule !== 'video');
});

// video score
const calculateLabel = score => {
  if (score < 0.33) {
    return 'poor';
  } else if (score < 0.66) {
    return 'okay';
  } else {
    return 'great';
  }
};

const videoScore = computed(() => {
  if (platform.value === 'Amazon') {
    return null;
  }
  let videoRuleResult = listing.value?.rule_results?.find(
    x => x.rule_group === 'video count'
  )?.rules[0];
  if (!videoRuleResult) {
    return null;
  }
  return {
    display_value: videoRuleResult.score * 100,
    rule: 'video count',
    score: {
      label: calculateLabel(videoRuleResult.score),
      value: videoRuleResult.score === 0 ? 0.01 : videoRuleResult.score,
    },
  };
});

// refresh listing module
const displayRefreshButton = computed(() =>
  ['Amazon', 'Shopify'].includes(platform.value)
);

const refreshListing = () => {
  if (
    platform.value === 'Amazon' &&
    amazonIntegration.value?.status === 'invalid_state'
  ) {
    showReconnectAmazonDialog.value = true;
    return;
  }

  emits('refreshListing');
};

const listingUrl = computed(
  () => `https://www.amazon.com/dp/${listing.value.catalog_item_info?.asin}`
);
</script>

<template>
  <div v-if="listing" :class="classNames">
    <header class="listing-details-summary__header">
      <div class="listing-details-summary__actions">
        <SoonaBackButton @click="previousPage" />
        <SoonaButton
          class="soona-back-button"
          element="router-link"
          variation="tertiary"
          :to="{
            path: `/account/${accountId}/products/${listing.catalog_item_id}`,
          }"
        >
          product details
        </SoonaButton>
      </div>
      <div class="listing-details-summary__description">
        <img
          :src="listing.main_image_url ?? PlaceholderImage"
          alt="listing main image"
        />
        <div class="listing-details-summary__integration">
          <div class="listing-details-summary__integration--icon">
            <a
              v-if="platform === 'Amazon'"
              :href="listingUrl"
              target="_blank"
              class="listing-details-summary__link"
            >
              <SoonaIcon :name="integrationLogo[platform]" size="small" />
              {{ platform }}
              <SoonaIcon
                class="listing-details-summary__link--icon"
                name="arrow-up-right-from-square"
                size="small"
              />
            </a>
            <span v-else>
              <SoonaIcon :name="integrationLogo[platform]" size="small" />
              {{ platform }}
            </span>
          </div>
          <h1 class="u-title--heavy">{{ listing.name }}</h1>
          <p>
            {{ listing.image_count }} photo{{
              listing.image_count > 1 || listing.image_count === 0 ? 's' : ''
            }}
            <span v-if="platform === 'Shopify'">
              · {{ listing.video_count }} video{{
                listing.video_count > 1 || listing.video_count === 0 ? 's' : ''
              }}
            </span>
          </p>
        </div>
      </div>
    </header>
    <div class="listing-details-summary__scores">
      <div class="listing-details-summary__scores--heading">
        <h2 class="listing-details-summary__scores--title u-body--heavy">
          soona score
        </h2>
      </div>
      <div class="listing-details-summary__visual-performance">
        <div class="listing-details-summary__visual-performance--overall-score">
          {{ listing.visual_average.display_value }}
          <span
            class="listing-details-summary__visual-performance--denominator u-display--regular"
            >/ 100</span
          >
        </div>
      </div>

      <SoonaMeter :data="formattedMeterData" accessible-title="overall score" />

      <div class="listing-details-summary__score-averages">
        <div
          v-for="category in scoreBreakdown"
          :key="category.rule"
          class="listing-details-summary__score-average"
        >
          <SoonaDonutChart
            :accessible-title="category.rule"
            :data="[category.score]"
            :display-value="category.display_value"
            label-position="right"
            size="medium"
          >
            <template #label>
              <SoonaIcon
                class="soona-donut-chart__description--icon"
                :name="iconLookup[category.rule]"
                size="medium"
              />
              <p class="u-body--regular">{{ category.rule }}</p>
            </template>
          </SoonaDonutChart>
        </div>
        <div class="listing-details-summary__score-average">
          <div class="listing-details-summary__video u-title--regular">
            <div class="listing-details-summary__video--description">
              <SoonaIcon
                class="soona-donut-chart__description--icon"
                name="video-file-play"
                size="medium"
              />
              <p class="u-body--regular">video</p>
            </div>
            <div
              v-if="videoScore"
              class="listing-details-summary__video--score"
            >
              <SoonaDonutChart
                accessible-title="video score"
                :data="[videoScore.score]"
                :display-value="videoScore.display_value"
                label-position="right"
                size="medium"
              />
            </div>
            <div v-else class="listing-details-summary__video--flag">
              <SoonaFlag
                :background-color="Gray20"
                title="coming soon"
                type="pill"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="listing-details-summary__meta">
        <ListingInsightsHelpDialog />
        <ReconnectAmazonDialog
          v-if="showReconnectAmazonDialog"
          @close="showReconnectAmazonDialog = false"
        />
        <div class="listing-details-summary__updated-at">
          <p class="u-small--regular">
            updated {{ dayjs().to(dayjs(listing.updated_at)) }}
          </p>
          <SoonaButton
            v-if="displayRefreshButton"
            variation="icon-plain-gray"
            size="small"
            title="refresh listing"
            @click="refreshListing"
          >
            <SoonaIcon name="refresh-cw-alt" />
          </SoonaButton>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.listing-details-summary {
  padding: 2rem 1rem;

  &__background {
    &--poor {
      background-color: variables.$roses-20;
    }

    &--okay {
      background-color: variables.$tangerine-10;
    }

    &--great {
      background-color: variables.$avo-toast-20;
    }
  }

  &__header {
    margin-bottom: 1rem;
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__description {
    display: flex;
    flex-direction: column-reverse;
    gap: 2rem;

    img {
      border-radius: 0.3125rem;
      object-fit: cover;
      width: 9.75rem;
      height: 9.75rem;
    }
  }

  &__integration {
    &--icon {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  &__scores {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  &__visual-performance {
    align-items: flex-end;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    &--overall-score {
      font-size: 4.5rem;
      font-weight: 800;
      line-height: 5rem;
      letter-spacing: 0.015625rem;
    }

    &--denominator {
      color: variables.$gray-60;
      margin-left: -0.5rem;
    }
  }

  &__score-averages {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
  }

  &__score-average {
    color: variables.$black-default;
    flex: 1 0 0;
    background-color: variables.$white-default;
    border-radius: 0.625rem;
    box-shadow: variables.$elevation-0;
    min-width: 15rem;
    padding: 1rem 0;
  }

  &__video {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 1rem;

    gap: 0.5rem;

    &--description {
      display: flex;
      align-items: center;
      gap: 0.25rem;
    }

    &--flag {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 4rem;
      padding-right: 1rem;
    }
  }

  &__meta {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 2rem;
  }

  &__updated-at {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 0.5rem;

    p {
      color: variables.$gray-60;
    }
  }

  // desktop
  // screen-sm-min = 768px
  @media (min-width: variables.$screen-sm-min) {
    padding: 1.5rem 2rem;

    &__description {
      flex-flow: row nowrap;
    }
  }
}
</style>
