<script setup>
import { computed, provide, ref } from 'vue';
import { useMediaQuery } from '@vueuse/core';
import { useRouter } from 'vue-router';
import { usePriorityError } from '@/composables/usePriorityError';
import {
  MEDIA_POPOVER_BACK_KEY,
  MEDIA_POPOVER_BACK_TEXT,
  MEDIA_POPOVER_NEXT_KEY,
  MEDIA_POPOVER_PREV_KEY,
} from '@/components/user/anytime/gallery/media-editor-routing-keys';
import MediaEditor from '@/components/user/anytime/gallery/media-editor/MediaEditor.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import { useReservation } from '@/composables/useReservation';
import { useEditsCollection } from '@/queries/edits_collection/useEditsCollection';
import BookingEditsCarousel from '@/components/user/anytime/reservation/editor/BookingEditsCarousel.vue';
import { useEditsCollectionDigitalAssetByDigitalAssetId } from '@/queries/edits_collection_digital_assets/useEditsCollectionDigitalAssetByDigitalAssetId';

const props = defineProps({
  isChatOpen: {
    type: Boolean,
  },
  userHasScrolledPastBreakpoint: {
    type: Boolean,
  },
  assetContext: {
    type: String,
  },
  reservationId: {
    type: [String, Number],
    required: true,
  },
  digitalAssetId: {
    type: [String, Number],
    required: true,
  },
});

const router = useRouter();

const reservationId = computed(() => props.reservationId);
const digitalAssetId = computed(() => props.digitalAssetId);

const matchMediaIsWide = useMediaQuery('(min-width: 60rem)');

const { nameTruncated, reservationAccountId } = useReservation(reservationId);
const { data: editsCollection } = useEditsCollection(reservationAccountId, {
  enabled: computed(() => !!reservationAccountId.value),
});
const editsCollectionId = computed(() => editsCollection.value?.id);
const filters = computed(() => ({
  reservation_id: reservationId.value,
  origin: 'soona',
  ownership: 'customer',
  visibility: 'all',
  edit_status: 'accepted',
}));

const {
  data: editsCollectionDigitalAsset,
  isLoading: isFileLoading,
  error,
} = useEditsCollectionDigitalAssetByDigitalAssetId(
  editsCollectionId,
  digitalAssetId,
  { filters },
  {
    enabled: computed(() => !!editsCollectionId.value),
  }
);

const priorityError = usePriorityError(error);

// routes
const createRouteLocation = id => {
  if (!id) return null;

  return {
    name: 'reservation-asset-edit-view',
    params: {
      digitalAssetId: id,
      reservationId: reservationId.value,
    },
  };
};

const backUrl = computed(() => {
  return `/reservation/${reservationId.value}`;
});

const previousRoute = computed(() => {
  return createRouteLocation(
    editsCollectionDigitalAsset.value?.previous?.digital_asset_id
  );
});

const nextRoute = computed(() => {
  return createRouteLocation(
    editsCollectionDigitalAsset.value?.next?.digital_asset_id
  );
});

provide(MEDIA_POPOVER_BACK_KEY, backUrl);
provide(MEDIA_POPOVER_PREV_KEY, previousRoute);
provide(MEDIA_POPOVER_NEXT_KEY, nextRoute);
// nameTruncated will never change b/c it's not computed
provide(MEDIA_POPOVER_BACK_TEXT, `in ${nameTruncated.value}`);

// redirect back to gallery
const isRedirecting = ref(false);
const goToGallery = () => {
  isRedirecting.value = true;
  router.push(backUrl.value);
};
</script>
<template>
  <MediaEditor
    v-if="!isRedirecting"
    context="booking_gallery"
    :asset="editsCollectionDigitalAsset?.digital_asset"
    :is-asset-loading="isFileLoading"
    :reservation-id="reservationId"
    @close="goToGallery"
  >
    <template v-if="priorityError" #error>
      <SoonaError>
        {{ priorityError }}
      </SoonaError>
    </template>
    <template #carousel>
      <BookingEditsCarousel
        v-if="matchMediaIsWide && editsCollection?.id"
        :edits-collection-id="editsCollection?.id"
        :reservation-id="reservationId"
        :active-asset-id="editsCollectionDigitalAsset?.digital_asset?.id"
        title="reservation edits"
      />
    </template>
  </MediaEditor>
</template>
