<script setup>
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';

// You can generate a deep link here: https://www.canva.dev/docs/apps/deep-linking/#generate-a-deep-link
const canvaDeepLinkUrl = import.meta.env.VITE_CANVA_DEEP_LINK_URL;
</script>
<template>
  <SoonaButton
    class="canva-btn"
    element="a"
    :href="canvaDeepLinkUrl"
    target="_blank"
    size="medium"
    variation="secondary-gray"
  >
    <img src="@images/canva.svg" alt="canva logo" />
    open in Canva
  </SoonaButton>
</template>
<style lang="scss" scoped>
.canva-btn {
  img {
    height: 1rem;
  }
}
</style>
