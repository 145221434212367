<script setup>
import { computed, toRefs } from 'vue';
import SoonaLoading from '@/components/SoonaLoading.vue';
import PremiumEditsAddOn from '@/components/user/anytime/gallery/media-editor/PremiumEditsAddOn.vue';
import AssetNotes from '@/components/checkout/AssetNotes.vue';
import { usePriorityError } from '@/composables/usePriorityError';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaInfo from '@/components/ui_library/SoonaInfo.vue';
import { useGetReservationDigitalAssetMediaAddOns } from '@/queries/reservation_digital_assets/useGetReservationDigitalAssetMediaAddOns';
import { useCapability } from '@/composables/useCapability';
import { useMediaEditorDigitalAsset } from '@/composables/digital_assets/useMediaEditorDigitalAsset';

const props = defineProps({
  asset: {
    type: Object,
    default: undefined,
  },
  isMobileView: {
    type: Boolean,
    default: false,
  },
  selectedFilter: {
    type: Object,
    default: undefined,
  },
});

const emits = defineEmits(['is-notes-dirty']);

const { asset } = toRefs(props);
const assetId = computed(() => asset.value?.id);
const accountId = computed(() => asset.value?.account_id);

const isMobileView = computed(() => props.isMobileView);
const selectedFilter = computed(() => props.selectedFilter);

const { reservationId, rdaId, inBag, mediaType } = useMediaEditorDigitalAsset(
  accountId,
  assetId
);

// rda
const enableQueries = computed(() => !!reservationId.value && !!rdaId.value);

const {
  data: mediaAddOns,
  isLoading: mediaAddOnsLoading,
  error: mediaAddOnsError,
} = useGetReservationDigitalAssetMediaAddOns(reservationId, rdaId, {
  enabled: enableQueries,
});

const { hasCapability: canAddToBag } = useCapability({
  capability: 'add_to_bag',
  subjectType: 'account',
  subjectId: accountId,
});

const isLoading = computed(() => mediaAddOnsLoading.value);

const priorityError = usePriorityError(mediaAddOnsError);

const inputsDisabled = computed(() => !inBag.value);

const mediaAddOnLineItems = computed(
  () => mediaAddOns.value?.media_add_on_line_items ?? []
);
</script>

<template>
  <SoonaError v-if="priorityError">
    {{ priorityError }}
  </SoonaError>

  <div
    class="premium-edits"
    :class="{ 'premium-edits--filtered': isMobileView }"
  >
    <SoonaLoading
      v-if="isLoading"
      loading-text="loading…"
      is-loading
      is-contained
    />
    <template v-else>
      <SoonaInfo v-if="inputsDisabled" class="premium-edits__disabled-message">
        add this {{ mediaType }} to your bag to select these edits.
      </SoonaInfo>
      <ul class="premium-edits__list">
        <li
          v-for="(addOn, i) of mediaAddOnLineItems"
          v-show="
            !isMobileView ||
            (inBag && selectedFilter?.label === addOn.product.name)
          "
          :key="`${addOn.product.name.replace(/\s/g, '-')}-${i}`"
          class="premium-edits__add-on"
        >
          <PremiumEditsAddOn
            :reservation-id="reservationId"
            :add-on="addOn"
            asset-type="digital_asset"
            :file="asset"
            :input-disabled="inputsDisabled || !canAddToBag"
          />
        </li>
      </ul>
    </template>
    <AssetNotes
      v-if="
        asset &&
        inBag &&
        (!isMobileView || selectedFilter?.label === 'add editing notes')
      "
      :class="{
        'premium-edits__notes--unfiltered': !isMobileView,
      }"
      :asset="asset"
      :is-autosave="false"
      @is-notes-dirty="emits('is-notes-dirty', $event)"
    />
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.premium-edits {
  &__add-on {
    position: relative;

    input {
      cursor: pointer;
    }
  }

  &--filtered .premium-edits__disabled-message {
    margin: 0;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    margin-top: 0.75rem;

    .premium-edits--filtered & {
      margin-top: 0;
    }
  }

  &__notes--unfiltered {
    margin-top: 0.75rem;
  }
}
</style>
