<template>
  <div class="shotlist-view is-centered">
    <ShotlistView v-if="isViewMode" />
    <ShotlistEdit
      v-if="isEditMode"
      :reservation-id="reservationId"
      :account-id="currentReservation.account_id"
    />
    <SoonaLoading
      :is-loading="isLoading"
      :is-dark="false"
      :loading-text="'please wait'"
    />
  </div>
</template>

<script>
import { computed, watch } from 'vue';
import { mapState, mapActions, mapGetters, useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { useTitle } from '@vueuse/core';
import unescape from 'lodash/unescape';
import { useBaseEvents } from 'src/composables/segment/useBaseEvents';
import { useShotListEvents } from '@/composables/segment/useShotListEvents';
import ShotlistView from 'src/components/user/anytime/ShotlistView.vue';
import ShotlistEdit from 'src/components/user/anytime/ShotlistEdit.vue';
import SoonaLoading from 'src/components/SoonaLoading.vue';

export default {
  name: 'Shotlist',
  components: { ShotlistView, ShotlistEdit, SoonaLoading },
  setup() {
    const { pageViewed } = useBaseEvents();
    const { shotBuilderStarted } = useShotListEvents();
    const route = useRoute();
    const store = useStore();
    const title = useTitle();
    const currentReservation = computed(
      () => store.state.reservation.currentReservation
    );
    const shotListNotSubmitted = computed(() => {
      return (
        currentReservation.value?.shotlist_status === 'draft' ||
        currentReservation.value?.shotlist_status === 'build_it_for_me'
      );
    });

    watch(
      () => currentReservation.value?.id,
      () => {
        title.value = `${route.meta.page_title} ${unescape(
          currentReservation.value?.name
        )} | soona`;

        pageViewed();

        if (shotListNotSubmitted.value) {
          shotBuilderStarted({
            context: route.meta.context,
            subContext: 'shot list not submitted',
          });
        }

        if (currentReservation.value) {
          store.dispatch('shotList/resetShotlist', {
            reservationId: currentReservation.value.id,
            accountId: currentReservation.value.account_id,
          });
        }
      },
      { immediate: true }
    );
  },
  computed: {
    ...mapGetters('shotList', ['isViewMode', 'isEditMode', 'isLoading']),
    shootWrapped: function () {
      return this.currentReservation
        ? this.currentReservation.status == 'completed' ||
            this.currentReservation.status == 'pending_selects' ||
            this.currentReservation == 'edits_required'
        : true;
    },
    ...mapState({
      shotList: state => state.shotList.currentShotList,
      currentShot: state => state.shotList.currentShot,
      allTagCategories: state => state.shotList.allTagCategories,
      currentReservation: state => state.reservation.currentReservation,
    }),
    reservationId: function () {
      return Number(this.$route.params.reservationId);
    },
  },
  methods: {
    ...mapActions('reservation', ['loadReservation']),
  },
};
</script>
