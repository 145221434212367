<template>
  <span>
    <!-- delete button -->
    <button
      v-if="isAnytime || isFtSoonaStaff"
      class="remove-package__button"
      @click="deleteOrRemove"
    >
      <SoonaIcon name="xmark" />
      <span class="u-a11y-only"
        >{{ canDelete() ? 'delete' : 'remove' }} package</span
      >
    </button>

    <!-- delete tracking details confirmation modal -->
    <SoonaDialog v-if="showConfirmDeleteModal" @close="closeConfirmDeleteModal">
      <template #heading>delete package</template>
      <p>are you sure you want to delete this package?</p>
      <p>this cannot be undone.</p>
      <template #footer="{ close }">
        <SoonaButton
          variation="tertiary"
          data-cypress="button-dialog-cancel"
          @on-click="close"
        >
          cancel
        </SoonaButton>
        <SoonaButton
          data-cypress="button-dialog-confirm"
          @on-click="deleteThisPackage"
        >
          yes, delete package
        </SoonaButton>
      </template>
    </SoonaDialog>

    <!-- remove package from reservation confirmation modal -->
    <SoonaDialog v-if="showConfirmRemoveModal" @close="closeConfirmRemoveModal">
      <template #heading>remove package</template>
      <p>are you sure you want to remove the package from this booking?</p>
      <template #footer="{ close }">
        <SoonaButton
          variation="tertiary"
          data-cypress="button-dialog-cancel"
          @on-click="close"
        >
          cancel
        </SoonaButton>
        <SoonaButton
          data-cypress="button-dialog-confirm"
          @on-click="removeThisPackage"
        >
          yes, remove package
        </SoonaButton>
      </template>
    </SoonaDialog>
  </span>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { useCapability } from '@/composables/useCapability';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';

export default {
  name: 'RemovePackage',
  components: {
    SoonaButton,
    SoonaDialog,
    SoonaIcon,
  },
  props: {
    inventoryPackage: Object,
    reservationId: Number,
  },
  setup() {
    const { hasCapability: isFtSoonaStaff } = useCapability({
      capability: 'ft_soona_staff',
    });
    return { isFtSoonaStaff };
  },
  data() {
    return {
      showConfirmDeleteModal: false,
      showConfirmRemoveModal: false,
    };
  },
  computed: {
    ...mapGetters('reservation', ['isAnytime']),
  },
  methods: {
    ...mapActions('inventoryPackages', ['deletePackage', 'removePackage']),
    deleteOrRemove() {
      if (this.canDelete()) {
        this.showConfirmDeleteModal = true;
      } else {
        this.showConfirmRemoveModal = true;
      }
    },
    canDelete() {
      return (
        this.inventoryPackage.status === 'on_the_way' &&
        !this.inventoryPackage.isShippo
      );
    },
    deleteThisPackage() {
      let packageAttributes = {
        reservationId: this.reservationId,
        inventoryPackageId: this.inventoryPackage.id,
      };
      this.deletePackage({ packageAttributes });
      this.closeConfirmDeleteModal();
    },
    removeThisPackage() {
      let params = {
        reservationId: this.reservationId,
        inventoryPackageId: this.inventoryPackage.id,
      };
      this.removePackage(params);
      this.closeConfirmDeleteModal();
    },
    closeConfirmDeleteModal() {
      this.showConfirmDeleteModal = false;
    },
    closeConfirmRemoveModal() {
      this.showConfirmRemoveModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';

.remove-package {
  &__button {
    align-items: center;
    background: none;
    border: none;
    color: variables.$friendly-red-50;
    display: flex;
    justify-content: center;
    margin-left: 0.5rem;
    padding: 0.7rem 0.8rem;
  }
}
</style>
