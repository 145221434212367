<script setup>
import { onMounted, onBeforeUnmount, computed, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { onKeyStroke, useTimeoutFn, useMediaQuery } from '@vueuse/core';
import TitleBar from 'src/components/shared/TitleBar.vue';
import ReservationOrderBuilder from './add_on_order/ReservationOrderBuilder.vue';
import AdditionalOrderSidecart from './billing_and_orders/AdditionalOrderSidecart.vue';
import { useGetBestAutoApplyCoupon } from '@/queries/reservations/useGetBestAutoApplyCoupon';

const props = defineProps({
  reservationId: {
    type: String,
    default: null,
    required: true,
  },
});

const router = useRouter();

const reservationId = computed(() => props.reservationId);

const discountObj = ref(null);

const store = useStore();
async function loadReservation() {
  await store.dispatch('reservation/loadReservation', reservationId.value);
}
function loadAdditionalChargeTagCategoryProducts(shootType) {
  store.dispatch('reservation/loadAdditionalChargeTagCategoryProducts', {
    shootType,
    discountId: discountObj.value?.id,
  });
}

const shootType = computed(() => store.getters['reservation/shootType']);

onMounted(async () => {
  window.scrollTo({ top: 0 });
  await loadReservation();
  loadAdditionalChargeTagCategoryProducts(shootType.value);
});

onBeforeUnmount(() => {
  store.dispatch('reservation/clearAdditionalChargeItems');
});

function incrementProduct(product) {
  store.dispatch('reservation/setAdditionalChargeItems', {
    [product.id]: product.quantity + 1,
  });
}

function decrementProduct(product) {
  if (product.quantity === 1) {
    store.dispatch('reservation/removeAdditionalChargeItem', product.id);
  } else {
    store.dispatch('reservation/setAdditionalChargeItems', {
      [product.id]: product.quantity - 1,
    });
  }
}

function setProductCount(product, newVal) {
  if (newVal === 0) {
    store.dispatch('reservation/removeAdditionalChargeItem', product.id);
  } else {
    store.dispatch('reservation/setAdditionalChargeItems', {
      [product.id]: newVal,
    });
  }
}
const productsInCart = computed(
  () => store.getters['reservation/productsInCart']
);
const previousPage = () => {
  router.back();
};

/* sidecart */
const sidecartIsAffixed = useMediaQuery('(max-width: 59.9375rem)');
const sidecartOpen = ref(false);
const sidecartClosing = ref(false);
const { start: closeSidecart, stop: stopCloseSidecart } = useTimeoutFn(
  () => {
    sidecartOpen.value = !sidecartOpen.value;
    sidecartClosing.value = false;
  },
  300,
  { immediate: false }
);

const toggleSidecart = () => {
  if (!sidecartOpen.value || sidecartClosing.value) {
    sidecartClosing.value = false;
    stopCloseSidecart();
    sidecartOpen.value = !sidecartOpen.value;
    return;
  }

  sidecartClosing.value = true;
  closeSidecart();
};

onKeyStroke('Escape', () => {
  if (sidecartOpen.value && !sidecartClosing.value) {
    toggleSidecart();
  }
});

const { data: bestAutoApplyDiscount } = useGetBestAutoApplyCoupon(
  reservationId,
  { products: productsInCart, orderType: 'additional_charge' }
);

watch(
  bestAutoApplyDiscount,
  newVal => {
    if (!newVal) return;
    if (discountObj.value && discountObj.value?.auto_apply === false) return;

    if (newVal.external_discount_id) {
      discountObj.value = bestAutoApplyDiscount.value;
    } else {
      discountObj.value = null;
    }

    loadAdditionalChargeTagCategoryProducts(shootType.value);
  },
  {
    immediate: true,
  }
);
</script>

<template>
  <div class="add-on-order">
    <section
      class="add-on-order__content"
      aria-labelledby="add-on-order-edit-heading"
    >
      <TitleBar
        :reservation-id="reservationId"
        heading-id="add-on-order-edit-heading"
        title="create new order"
        :previous-page="previousPage"
      />
      <ReservationOrderBuilder
        :reservation-id="reservationId"
        :discount-obj="discountObj"
      />
    </section>
    <AdditionalOrderSidecart
      id="add-on-order-sidecart"
      :products="productsInCart"
      :reservation-id="reservationId"
      :affixed="sidecartIsAffixed"
      :discount-obj="discountObj"
      class="add-on-order__sidecart"
      :class="{
        'add-on-order__sidecart--open': sidecartOpen,
        'add-on-order__sidecart--closing': sidecartClosing,
      }"
      :toggle-sidecart="toggleSidecart"
      @click.self="toggleSidecart"
      @increment-product="incrementProduct"
      @decrement-product="decrementProduct"
      @set-product-count="setProductCount"
    />
    <button
      type="button"
      class="u-button-reset add-on-order__sidecart-toggle"
      aria-controls="add-on-order-sidecart"
      :aria-expanded="sidecartOpen"
      @click="toggleSidecart"
    >
      <span class="u-a11y-only">toggle </span>Order Summary
    </button>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.add-on-order {
  flex: 1 0 auto;

  &__sidecart-toggle {
    @include variables_fonts.u-small--heavy;

    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    border: 0.0625rem solid variables.$gray-30;
    border-right: 0;
    text-transform: uppercase;
    padding: 0.5rem 1rem;
    letter-spacing: 0.09375rem;
    border-radius: 0.625rem 0 0 0;
    background-color: variables.$white-default;
    transition: background-color 0.1s ease-out;

    > svg {
      display: block;
      margin-left: 0.5rem;
    }

    &:hover,
    &:focus-visible {
      background-color: variables.$gray-20;
    }
  }

  &__sidecart {
    position: fixed;
    z-index: 4;
    top: var(--app-header-viewport-offset);
    bottom: 0;
    right: 0;
    background-color: rgba(63, 67, 75, 0.75);
    animation: 0.2s ease-out both fade-in-sidecart-bg;

    &--open {
      display: flex;
      overflow: hidden;
    }

    &--closing {
      animation: 0.3s ease-out both fade-out-sidecart-bg;
    }
  }
}

@media (min-width: variables.$screen-md-min) {
  .add-on-order {
    display: grid;
    grid-template-areas: 'content sidecart';
    grid-template-columns: 1fr 27.625rem;
    grid-template-rows: 1fr;

    &__content {
      grid-area: content;
    }
    &__sidecart-toggle {
      display: none;
    }

    &__sidecart {
      grid-area: sidecart;
      /* reset to a more standard layout for use in the grid */
      position: static;
      display: block;
      background-color: rgba(63, 67, 75, 0);
      animation: none;
    }
  }
}
</style>
