<script setup>
import { computed } from 'vue';
import SoonaDropdownMenu from '@/components/ui_library/SoonaDropdownMenu.vue';
import SoonaDropdownMenuItem from '@/components/ui_library/SoonaDropdownMenuItem.vue';
import SoonaHelperText from '@/components/ui_library/SoonaHelperText.vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';

const props = defineProps({
  accountId: {
    type: [Number, String],
    required: true,
  },
  copy: {
    type: String,
    default: 'download all',
  },
  reservationId: {
    type: [Number, String],
    required: true,
  },
  showWeb: {
    type: Boolean,
    required: true,
    default: false,
  },
  size: {
    type: String,
    default: 'medium',
  },
  variation: {
    type: String,
    default: 'secondary-gray',
  },
});

const { buttonClicked } = useBaseEvents();

const accountId = computed(() => props.accountId);
const reservationId = computed(() => props.reservationId);
const copy = computed(() => props.copy);
const clickedBulkDownload = res => {
  buttonClicked({
    context: 'gallery',
    subContext: 'download gallery',
    buttonLabel: `${copy.value} - ${res}`,
    buttonAction: `download's gallery ${res} edits`,
  });
};

const params = computed(() => {
  return `reservation_id=${reservationId.value}&origin=soona&ownership=customer&collection_type=EditsCollection&visibility=all`;
});

const webUrl = computed(() => {
  return `/api/accounts/${accountId.value}/digital_assets_downloads.zip?resolution=web&${params.value}`;
});

const hiResUrl = computed(() => {
  return `/api/accounts/${accountId.value}/digital_assets_downloads.zip?resolution=hi_res&${params.value}`;
});
</script>
<template>
  <div class="download-all">
    <SoonaDropdownMenu :copy="copy" :size="size" :variation="variation">
      <template #default="{ keydown, mouseover }">
        <SoonaDropdownMenuItem v-if="showWeb">
          <a
            role="menuitem"
            :href="webUrl"
            @click.capture="clickedBulkDownload('web')"
            @keydown="keydown"
            @mouseover="mouseover"
          >
            web
          </a>
        </SoonaDropdownMenuItem>
        <SoonaDropdownMenuItem>
          <a
            role="menuitem"
            :href="hiResUrl"
            @keydown="keydown"
            @mouseover="mouseover"
            @click.capture="clickedBulkDownload('hi-res')"
          >
            hi-res
          </a>
        </SoonaDropdownMenuItem>
      </template>
    </SoonaDropdownMenu>
    <SoonaHelperText :title="`${copy} info`" class="download-all__helper">
      <p class="download-all__tooltip-copy">
        web: small file size. best for digital uses like Amazon. Shopify. social
        media.
      </p>
      <p class="download-all__tooltip-copy">
        hi-res: large file size. best for printed materials and cropping.
      </p>
      <p class="download-all__tooltip-copy">
        note: web does not download .mp4 videos.
      </p>
    </SoonaHelperText>
  </div>
</template>
<style lang="scss" scoped>
@use 'src/variables';

.download-all {
  display: flex;
  gap: 0.5625rem;

  &__tooltip-copy:not(:last-child) {
    padding-bottom: 0.75rem;
  }

  &__helper {
    z-index: 6;
  }
}
</style>
