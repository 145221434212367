<script setup>
import { useMokkerAvailableCredits } from '@/queries/mokker/useMokkerAvailableCredits';
import { computed, toRefs } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
});

const emits = defineEmits(['show-paywall-dialog']);

const { accountId } = toRefs(props);

// use
const { data: availableCreditsResponse, isLoading: isAvailableCreditsLoading } =
  useMokkerAvailableCredits(accountId);

const availableCredits = computed(
  () => availableCreditsResponse.value?.available_credits
);
const mokkerSubscriptionTier = computed(
  () => availableCreditsResponse.value?.mokker_subscription_tier
);

const showUpgradeButton = computed(
  () => mokkerSubscriptionTier.value === 'free'
);
const showCredits = computed(() => mokkerSubscriptionTier.value !== 'team');
</script>

<template>
  <div v-if="!isAvailableCreditsLoading" class="available-credits">
    <span v-if="showCredits" class="available-credits__text">
      {{ availableCredits }} generation{{ availableCredits === 1 ? '' : 's' }}
      available
    </span>

    <SoonaButton
      v-if="showUpgradeButton"
      size="medium"
      variation="pizzazz"
      @click="() => emits('show-paywall-dialog')"
    >
      upgrade for more
    </SoonaButton>
  </div>
</template>

<style scoped lang="scss">
@use '@/variables';

.available-credits {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &__text {
    color: variables.$gray-60;
  }
}
</style>
