<script setup>
import { computed } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';

const props = defineProps({
  asset: {
    type: Object,
    required: true,
  },
  reservationId: {
    type: [Number, String],
    default: null,
  },
});

const asset = computed(() => props.asset);
const accountId = computed(() => asset.value?.account_id);
const gifId = computed(() => props.asset?.animated_collection?.id);
const isSoonaOwned = computed(() => asset.value?.ownership === 'soona');

const animatedCollectionUrl = computed(() => {
  const queries = props.reservationId
    ? `?reservation_id=${props.reservationId}`
    : '';

  return `/account/${accountId.value}/animation/${gifId.value}${queries}`;
});
</script>

<template>
  <div v-if="isSoonaOwned" class="staff-action-bar">
    <div class="staff-action-bar__left"></div>
    <div class="staff-action-bar__right">
      <!-- edit gif -->
      <SoonaButton
        element="router-link"
        :to="animatedCollectionUrl"
        variation="secondary-gray"
        size="large"
      >
        <template #copy>edit gif</template>
      </SoonaButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.staff-action-bar {
  background-color: variables.$periwink-blue-10;
  border-bottom: 0.625rem solid variables.$gray-30;
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  height: max-content;
  justify-content: space-between;
  padding: 0.75rem 1rem;
  width: 100%;

  &__left {
    display: flex;
    gap: 0.25rem;

    .staff-action-bar__left-btn:first-child {
      border-top-left-radius: 0.3125rem;
      border-bottom-left-radius: 0.3125rem;
    }

    .staff-action-bar__left-btn:last-child {
      border-top-right-radius: 0.3125rem;
      border-bottom-right-radius: 0.3125rem;
    }
  }

  &__left-btn {
    background-color: variables.$gray-20;
    border: 0.625rem solid variables.$gray-20;
    height: 2.5rem;
    padding-left: 1rem;
    padding-right: 1rem;

    &:hover,
    &:focus-visible {
      background-color: variables.$gray-30;
    }

    &:active {
      background-color: variables.$gray-20;
    }
  }

  &__right {
    display: flex;
    flex-wrap: wrap;
    gap: 0.25rem;
  }
}

.rotate-left {
  align-self: center;
  top: 0.125rem;
  position: relative;
  transition: transform 0.25s ease-in-out;

  &:not(:disabled):hover {
    transform: rotate(-30deg);
  }
}

.rotate-right {
  align-self: center;
  top: 0.125rem;
  position: relative;
  transition: transform 0.25s ease-in-out;

  &:not(:disabled):hover {
    transform: rotate(30deg);
  }
}

.variation-picker {
  margin-bottom: 0;
}
</style>
