<script setup>
import { computed, toRefs } from 'vue';

const props = defineProps({
  name: {
    type: String,
    default: 'fade',
    validator: function (value) {
      return [
        'fade',
        'fade-up',
        'fade-down',
        'fade-left',
        'fade-right',
      ].includes(value);
    },
  },
  mode: {
    type: String,
    default: 'default',
    validator: function (value) {
      return ['default', 'in-out', 'out-in'].includes(value);
    },
  },
  appear: {
    type: Boolean,
    default: false,
  },
  appearDelay: {
    type: Number,
    default: 0,
  },
  enterDelay: {
    type: Number,
    default: 0,
  },
  exitDelay: {
    type: Number,
    default: 0,
  },
});

const { name, appearDelay, enterDelay, exitDelay } = toRefs(props);
const transitionName = computed(() => `soona-${name.value}`);

function onBeforeAppear(el) {
  el.style.transitionDelay = appearDelay.value + 'ms';
}
function onBeforeEnter(el) {
  el.style.transitionDelay = enterDelay.value + 'ms';
}
function onBeforeLeave(el) {
  el.style.transitionDelay = exitDelay.value + 'ms';
}
</script>

<template>
  <Transition
    :mode="mode"
    :appear="appear"
    :name="transitionName"
    @before-appear="onBeforeAppear"
    @before-enter="onBeforeEnter"
    @before-leave="onBeforeLeave"
  >
    <slot />
  </Transition>
</template>

<style lang="scss">
//fade-*
.soona-fade-enter-active,
.soona-fade-up-enter-active,
.soona-fade-down-enter-active,
.soona-fade-left-enter-active,
.soona-fade-right-enter-active {
  transition:
    opacity 0.25s cubic-bezier(0.16, 1, 0.3, 1),
    transform 0.25s cubic-bezier(0.16, 1, 0.3, 1);
}

//fade-*
.soona-fade-leave-active,
.soona-fade-up-leave-active,
.soona-fade-down-leave-active,
.soona-fade-left-leave-active,
.soona-fade-right-leave-active {
  transition:
    opacity 0.2s cubic-bezier(0.16, 1, 0.3, 1),
    transform 0.2s cubic-bezier(0.16, 1, 0.3, 1);
}

//fade
.soona-fade-enter-from,
.soona-fade-leave-to {
  opacity: 0;
}

//fade-up
.soona-fade-up-enter-from,
.soona-fade-up-leave-to {
  opacity: 0;
  transform: translateY(1.25rem);

  @media (prefers-reduced-motion: reduce) {
    transform: none;
  }
}

//fade-down
.soona-fade-down-enter-from,
.soona-fade-down-leave-to {
  opacity: 0;
  transform: translateY(-1.25rem);

  @media (prefers-reduced-motion: reduce) {
    transform: none;
  }
}

//fade-left
.soona-fade-left-enter-from,
.soona-fade-left-leave-to {
  opacity: 0;
  transform: translateX(1.25rem);

  @media (prefers-reduced-motion: reduce) {
    transform: none;
  }
}

//fade-right
.soona-fade-right-enter-from,
.soona-fade-right-leave-to {
  opacity: 0;
  transform: translateX(-1.25rem);

  @media (prefers-reduced-motion: reduce) {
    transform: none;
  }
}
</style>
