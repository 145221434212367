// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/PluralRules/PluralRules#using_options
const pluralRules = new Intl.PluralRules('en-US', { type: 'ordinal' });
const pluralSuffixes = new Map([
  ['zero', 'th'],
  ['one', 'st'],
  ['two', 'nd'],
  ['few', 'rd'],
  ['many', 'th'],
  ['other', 'th'],
]);
export function ordinalPluralization(num) {
  const rule = pluralRules.select(Number(num));
  return pluralSuffixes.get(rule);
}

const MMDDYYYYFormat = new Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
});
export function convertToMMDDYYYY(date_param) {
  return date_param ? MMDDYYYYFormat.format(new Date(date_param)) : '';
}

const MMDDYYFormat = new Intl.DateTimeFormat('en-US', {
  year: '2-digit',
  month: '2-digit',
  day: '2-digit',
});
export function convertToMMDDYY(date_param) {
  return date_param ? MMDDYYFormat.format(new Date(date_param)) : '';
}

// this helper function replaces moment.format('YYYY-MM-DD')
export function convertToYYYYMMDD(date_param) {
  if (!date_param) {
    return '';
  }

  const date = new Date(date_param);
  const year = date.getFullYear().toString().padStart(4, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');

  return `${year}-${month}-${day}`;
}

export function convertToYYYYMMDDWithUTCTimeZone(date_param) {
  return convertToYYYYMMDD(
    new Date(date_param).toLocaleDateString('en-US', {
      hourCycle: 'h23', // See https://tc39.es/proposal-intl-datetime-style/#sec-properties-of-intl-datetimeformat-instances for more information. Chrome defaults to h24
      timeZone: 'UTC',
    })
  );
}

const MMDDYYYYmonthAbrFormat = new Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: 'short',
  day: '2-digit',
});
export function convertToMMDDYYYYmonthAbr(date_param) {
  return date_param ? MMDDYYYYmonthAbrFormat.format(new Date(date_param)) : '';
}

const weekdayMMDDYYYYmonthAbrFormat = new Intl.DateTimeFormat('en-US', {
  weekday: 'short',
  year: 'numeric',
  month: 'short',
  day: '2-digit',
});
export function convertToWeekdayMMDDYYYYmonthAbr(date_param) {
  return date_param
    ? weekdayMMDDYYYYmonthAbrFormat.format(new Date(date_param))
    : '';
}

export function convertTodddDDMMMYYY(date_param, timeZone = '') {
  // the locale is en-GB for the date formatting
  const options = {
    weekday: 'short',
    day: '2-digit',
    month: 'long',
    year: 'numeric',
    hourCycle: 'h23',
  };

  if (timeZone && timeZone.length) {
    options.timeZone = timeZone;
  }

  return date_param
    ? new Date(date_param).toLocaleDateString('en-GB', options)
    : '';
}

const dateLongFormatter = new Intl.DateTimeFormat(undefined, {
  dateStyle: 'long',
});
export function convertToDateLong(date) {
  if (!date) {
    return '';
  }
  return dateLongFormatter.format(new Date(date));
}

export function convertToHHmmss(date_param, timeZone) {
  return date_param
    ? new Date(date_param).toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hourCycle: 'h23', // See https://tc39.es/proposal-intl-datetime-style/#sec-properties-of-intl-datetimeformat-instances for more information. Chrome defaults to h24
        timeZone: timeZone,
      })
    : '';
}

const MMDDFormat = new Intl.DateTimeFormat('en-US', {
  month: 'long',
  day: 'numeric',
});

const yearFormat = new Intl.DateTimeFormat('en-US', {
  year: 'numeric',
});
export function getYear(date_param) {
  return date_param ? yearFormat.format(new Date(date_param)) : '';
}

export function convertToMMDDYYYYlong(date_param) {
  if (!date_param) {
    return '';
  }
  const date = new Date(date_param);
  const newDate = MMDDFormat.format(date);
  return (
    newDate + ordinalPluralization(date.getDate()) + ', ' + getYear(date_param)
  );
}

export function convertToHHMM12(date_param, timezone) {
  if (!date_param) {
    return '';
  }

  const newTime = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZone: timezone,
  }).format(new Date(date_param));
  return date_param ? newTime.toLowerCase().replace(/ /g, '') : '';
}

export function convertToMMDD(date_param) {
  if (!date_param) {
    return '';
  }

  const date = new Date(date_param);
  const newDate = MMDDFormat.format(date);
  return newDate + ordinalPluralization(date.getDate());
}

const weekdayLongFormat = new Intl.DateTimeFormat('en-US', {
  weekday: 'long',
});
export function convertToDayLong(date_param) {
  return date_param ? weekdayLongFormat.format(new Date(date_param)) : '';
}

export function dateToISO(date, hour) {
  if (!date) {
    return '';
  }

  const newdate = hour
    ? new Date(date).setHours(hour, 0, 0, 0)
    : new Date(date);
  const getTime = newdate.getTime();
  const timeZoneOffset = newdate.getTimezoneOffset() * 60000;
  return new Date(getTime - timeZoneOffset).toISOString();
}

export function timeZoneAbbr(timeZoneParam) {
  const localDate = new Date().toLocaleString('en-US', {
    timeZone: timeZoneParam,
    timeZoneName: 'short',
  });
  return localDate.slice(localDate.length - 3, localDate.length);
}

const timezoneFormat = new Intl.DateTimeFormat('en-US', {
  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  timeZoneName: 'short',
  hour: 'numeric',
  minute: 'numeric',
});
export function browserTimeZone() {
  return timezoneFormat
    .formatToParts(new Date())
    .filter(option => option.type === 'timeZoneName')[0].value;
}

export function workingDaysBetweenDates(startDate, endDate) {
  let count = 0;
  const curDate = new Date(startDate.getTime());
  while (curDate <= endDate) {
    const dayOfWeek = curDate.getDay();
    if (dayOfWeek !== 0 && dayOfWeek !== 6) count++;
    curDate.setDate(curDate.getDate() + 1);
  }
  return count;
}

const DDMMYYHHMM12zFormat = new Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  timeZoneName: 'short',
});

export function convertToDDMMYYHHMM12z(date_param) {
  return DDMMYYHHMM12zFormat.format(new Date(date_param));
}
