<template>
  <div class="admin-create-trend-set-button">
    <button
      class="btn btn-lg btn-primary"
      data-cypress="button-create-trend-set"
      @click="openModal"
    >
      + add a new trend set
    </button>
    <AdminCreateTrendSetModal
      v-if="showModal"
      class="admin-create-trend-set-button__modal"
      :close-modal="closeModal"
      data-cypress="modal-create-trend-set"
    />
  </div>
</template>
<script>
import AdminCreateTrendSetModal from './AdminCreateTrendSetModal.vue';
export default {
  name: 'AdminCreateTrendSetButton',
  components: {
    AdminCreateTrendSetModal,
  },
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/buttons';

.admin-create-trend-set-button {
  &__modal {
    opacity: 1;
    transition: 0.3s;
    visibility: visible;
  }
}
</style>
