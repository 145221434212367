<template>
  <div
    class="soona-filter"
    :class="{
      [`soona-filter--left-align`]: isLeftAligned,
    }"
  >
    <template v-for="(option, i) in options" :key="i">
      <button
        v-if="option.label"
        class="btn soona-filter__btn"
        :class="{
          [`soona-filter__btn--${option.label.replace(/\s/g, '-')}`]:
            selectedFilter !== option.label,
          [`soona-filter__btn--${option.label.replace(/\s/g, '-')}-active`]:
            selectedFilter === option.label,
        }"
        :aria-pressed="selectedFilter === option.label"
        :data-cypress="`filter-option-${option.label
          .toLowerCase()
          .split(' ')
          .join('-')}`"
        :disabled="option.disabled"
        @click="onChange(option.label, $event.currentTarget)"
      >
        <span class="soona-filter__btn-container">
          <SoonaIcon
            v-if="option.selectedIconName && selectedFilter === option.label"
            class="soona-filter__svg-icon"
            :name="option.selectedIconName"
            size="small"
          />
          <SoonaIcon
            v-else-if="option.iconName"
            class="soona-filter__svg-icon"
            :name="option.iconName"
            size="small"
          />
          <img
            v-else-if="option.imageSrc"
            :src="option.imageSrc"
            alt="filter icon"
            class="soona-filter__icon"
          /><span
            class="soona-filter__btn-label"
            :class="{
              [`soona-filter__btn-label--bold`]: hasBoldLabel,
              [`soona-filter__btn-label--condense`]:
                condenseOnMobile && option.label !== 'all',
              'soona-filter__btn-label--has-count': option.count !== undefined,
            }"
            >{{
              option.count === undefined ? option.label : `${option.label}:`
            }}</span
          >
          {{ option.count === undefined ? undefined : option.count }}</span
        >
        <div
          v-if="option.paywalled && showPaywall"
          class="soona-filter__paywall-crown"
        >
          <SoonaFlag
            background-color="transparent"
            text-color="#FFFFFF"
            type="pill"
            is-pizzazz
          >
            <template #icon-left>
              <SoonaIcon name="crown" size="x-small" class="crown" />
            </template>
          </SoonaFlag>
        </div>
      </button>
      <button
        v-else-if="option.main"
        class="btn soona-filter__btn soona-filter__dropdown"
        :class="{
          [`soona-filter__btn--${option.main.replace(/\s/g, '-')}-active`]:
            !!option.subfilter &&
            option.subfilter.some &&
            option.subfilter.some(o => o.includes(selectedFilter)),
        }"
        :aria-expanded="isOpen[i]"
        :aria-pressed="
          !!option.subfilter &&
          option.subfilter.some &&
          option.subfilter.some(o => o.includes(selectedFilter))
        "
        :data-cypress="`filter-option-${option.main
          .toLowerCase()
          .split(' ')
          .join('-')}`"
        @click="() => toggleSubfilterVisibility(i)"
        @keydown="e => handleKeydown(e, i)"
      >
        {{ option.main
        }}<span
          v-if="
            !!option.subfilter &&
            option.subfilter.some &&
            option.subfilter.some(
              o => o.includes(selectedFilter) && !o.includes(option.main)
            )
          "
          >: {{ selectedFilter }}</span
        >
        <NavCaret class="soona-filter__caret" />
        <span class="soona-filter__dropdown-menu" :aria-hidden="!isOpen[i]">
          <button
            v-for="(subOption, j) in option.subfilter"
            :key="j"
            class="soona-filter__dropdown-option"
            :class="{
              [`soona-filter__dropdown-option--${subOption.replace(
                /\s/g,
                '-'
              )}`]:
                selectedFilter !== subOption ||
                `all ${selectedFilter}` === subOption,
              [`soona-filter__dropdown-option--${subOption.replace(
                /\s/g,
                '-'
              )}-active`]:
                selectedFilter === subOption ||
                `all ${selectedFilter}` === subOption,
            }"
            @click="onChange(subOption, $event.currentTarget)"
          >
            {{ subOption }}
          </button>
        </span>
      </button>
      <button
        v-else-if="Object.keys(logoFilters()).includes(option)"
        class="btn soona-filter__btn soona-filter__btn-img"
        :class="{
          [`soona-filter__btn--${option.replace(/\s/g, '-')}`]:
            selectedFilter !== option,
          [`soona-filter__btn--${option.replace(/\s/g, '-')}-active`]:
            selectedFilter === option,
        }"
        :aria-pressed="selectedFilter === option"
        :data-cypress="`filter-option-${option
          .toLowerCase()
          .split(' ')
          .join('-')}`"
        @click="onChange(option, $event.currentTarget)"
      >
        <img :alt="option" :src="logoFilter(option)" />
      </button>
      <button
        v-else-if="option.title"
        class="btn soona-filter__btn"
        :class="{
          [`soona-filter__btn--${option.title.replace(/\s/g, '-')}`]:
            selectedFilter !== option.title,
          [`soona-filter__btn--${option.title.replace(/\s/g, '-')}-active`]:
            selectedFilter === option.title,
        }"
        :aria-pressed="selectedFilter === option.title"
        :data-cypress="`filter-option-${option.title
          .toLowerCase()
          .split(' ')
          .join('-')}`"
        @click="onChange(option.title, $event.currentTarget)"
      >
        <template v-if="option.animation">
          <lottie-player
            class="soona-filter__btn__media"
            autoplay
            loop
            :src="option.animation"
          />
        </template>
        <SoonaIcon
          v-else-if="option.iconName"
          class="soona-filter__svg-icon"
          :name="option.iconName"
          size="small"
        />
        {{ option.title }}
      </button>
      <button
        v-else
        class="btn soona-filter__btn"
        :class="{
          [`soona-filter__btn--${option.replace(/\s/g, '-')}`]:
            selectedFilter !== option,
          [`soona-filter__btn--${option.replace(/\s/g, '-')}-active`]:
            selectedFilter === option,
        }"
        :aria-pressed="selectedFilter === option"
        :data-cypress="`filter-option-${option
          .toLowerCase()
          .split(' ')
          .join('-')}`"
        @click="onChange(option, $event.currentTarget)"
      >
        {{ option }}
      </button>
    </template>
  </div>
</template>
<script>
import NavCaret from 'src/components/svgs/NavCaret.vue';
import IconAmazon from '@images/btn-amazon.svg';
import IconShopify from '@images/btn-shopify.svg';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaFlag from '@/components/ui_library/SoonaFlag.vue';

const logoFilters = { shopify: IconShopify, amazon: IconAmazon };

/**
 * @deprecated - use SoonaFilterSingle for new single select filters
 */
export default {
  name: 'SoonaFilter',
  components: {
    SoonaFlag,
    NavCaret,
    SoonaIcon,
  },
  props: {
    condenseOnMobile: {
      default: false,
      type: Boolean,
    },
    hasBoldLabel: {
      default: true,
      type: Boolean,
    },
    isLeftAligned: {
      default: false,
      type: Boolean,
    },
    showPaywall: {
      default: false,
      type: Boolean,
    },
    view: String, // what view is this filter being used
    options: Array, // filter options
    selectedFilter: String,
    onChange: Function,
  },
  data() {
    return {
      isOpen: this.options?.map(() => {
        return false;
      }),
    };
  },
  methods: {
    handleKeydown(e, i) {
      if (e.key === 'Escape') this.isOpen[i] = false;
    },
    toggleSubfilterVisibility(i) {
      if (this.isOpen[i]) this.isOpen[i] = false;
      else this.isOpen[i] = true;
    },
    logoFilters() {
      return logoFilters;
    },
    logoFilter(option) {
      return logoFilters[option];
    },
  },
};
</script>
<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';
@import '@/buttons';

.soona-filter {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.5rem;

  &--left-align {
    justify-content: flex-start;
  }

  &__btn {
    @include variables_fonts.u-body--heavy;

    /* need to set so iPads don't use blue text */
    color: variables.$black-default;

    background-color: variables.$white-default;
    border-radius: 3.125rem !important; // override anytime.scss
    border: 0.0625rem solid variables.$gray-30 !important; // override anytime.scss
    min-width: max-content;
    padding: 0.5rem 0.8125rem 0.5625rem;
    transition: 0.1s ease-out;

    &:disabled {
      background-color: variables.$gray-20;
      opacity: 0.4;
      cursor: not-allowed;
    }

    @media (hover: hover) {
      &:not(:disabled):hover {
        background-color: variables.$friendly-red-20;
        border-color: variables.$friendly-red-20 !important;

        .soona-filter__svg-icon {
          color: variables.$friendly-red-50;
        }
      }
    }

    &[class$='-active'] {
      background-color: variables.$friendly-red-20;
      border-color: variables.$friendly-red-40 !important;

      .soona-filter__svg-icon {
        // TODO: figure this bit out, maybe swap svgs? maybe ignore this UI stuff?
        color: variables.$friendly-red-50;
      }
    }

    &-img {
      padding: 0rem 0.8125rem;
      max-height: 2.6875rem;

      & > img {
        height: 100%;
        max-width: 4.125rem;
      }
    }

    &--amazon,
    &--amazon-active {
      padding: 0.4375rem 0.8125rem 0;

      & > img {
        max-width: 3.625rem;
      }
    }

    &__media {
      width: 1.25rem;
      height: 1.25rem;
    }
  }

  &__btn-container {
    display: flex;
    align-items: center;
  }

  &__svg-icon {
    margin-right: 0.5rem;
    color: variables.$gray-60;
    transition: 0.2s;
    height: 1rem;
    width: 1rem;
  }

  &__icon {
    height: 1.25rem;
    width: 1.25rem;
    margin-right: 0.3125rem;
    object-fit: contain;
    vertical-align: middle;
  }

  &__btn-label {
    @include variables_fonts.u-body--regular;

    &--has-count {
      padding-right: 0.25rem;
    }

    &--bold {
      @include variables_fonts.u-body--heavy;
    }

    &--condense {
      @container (max-width: 55rem) {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }
    }
  }

  &__dropdown {
    position: relative;

    &:hover,
    &[aria-expanded='true'] {
      background-color: variables.$friendly-red-20;
      border-color: variables.$friendly-red-20 !important;

      .soona-filter__caret {
        transform: rotatex(180deg);

        :deep(path) {
          stroke: variables.$black-default;
        }
      }

      .soona-filter__dropdown-menu {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__caret {
    margin-left: 0.8125rem;

    :deep(path) {
      stroke: variables.$gray-60;
      transition: 0.1s;
    }
  }

  &__dropdown-menu {
    border-radius: 0.3125rem;
    box-shadow: variables.$elevation-3;
    display: flex;
    flex-direction: column;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 100%;
    transition: 0.2s;
    visibility: hidden;
    width: 100%;
  }

  &__dropdown-option {
    @include variables_fonts.u-body--regular;

    background-color: variables.$white-default;
    padding: 0.5rem 0.75rem;
    text-align: left;

    &:hover,
    &[class$='-active'] {
      background-color: variables.$friendly-red-10;
    }
  }

  &__paywall-crown {
    padding-left: 0.25rem;
  }
}
</style>
