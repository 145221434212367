import { computed } from 'vue';

export const useProductIntegrationDetails = product => {
  const isAmazonProduct = computed(() =>
    product.value.integrations?.includes('AmazonProduct')
  );
  const isShopifyProduct = computed(() =>
    ['ShopifyProduct', 'ShopifyVariant'].some(type =>
      (product.value.integrations || []).includes(type)
    )
  );
  const platformName = computed(() => {
    if (isAmazonProduct.value && isShopifyProduct.value) {
      return 'Amazon or Shopify';
    } else if (isAmazonProduct.value) {
      return 'Amazon';
    } else if (isShopifyProduct.value) {
      return 'Shopify';
    }
    return null;
  });

  return {
    isAmazonProduct,
    isShopifyProduct,
    platformName,
  };
};
