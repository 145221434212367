<template>
  <div
    class="handling-option"
    :class="{
      'handling-option--disabled': optionDisabled,
      'handling-option--read-only': readOnly,
    }"
  >
    <component :is="readOnly ? 'div' : 'label'" class="handling-option__label">
      <input
        v-if="!readOnly"
        v-model="computedValue"
        class="handling-option__input"
        type="radio"
        name="package-option"
        :data-cypress="`post-shoot-option-${option.value}`"
        :value="option.value"
        :disabled="optionDisabled"
        :tabindex="parentDrawerClosed ? '-1' : null"
      />
      <span class="handling-option__emoji" aria-hidden="true">
        {{ option.emoji }}
      </span>
      <span>
        <strong>{{ option.firstInfo }}</strong> {{ option.secondInfo }}
      </span>
    </component>
    <div
      v-if="
        (option.price &&
          (option.value !== 'already_stored' || hasSoonaStorage)) ||
        option.tooltipContent
      "
      class="handling-option__price"
      :class="{
        disabled: optionDisabled,
      }"
    >
      <strong
        v-if="
          option.price && (option.value !== 'already_stored' || hasSoonaStorage)
        "
        >{{ option.price
        }}<small v-if="option.priceNote">{{ option.priceNote }}</small></strong
      >
      <SoonaHelperText
        v-if="option.tooltipContent"
        :title="option.tooltipTitleMobile"
        :no-tab-index="parentDrawerClosed"
        :tooltip-position="option.tooltipPosition"
      >
        {{ option.tooltipContent }}
      </SoonaHelperText>
    </div>
  </div>
</template>

<script>
import SoonaHelperText from '@/components/ui_library/SoonaHelperText.vue';

export default {
  name: 'PackageHandlingOption',
  components: { SoonaHelperText },
  props: {
    option: Object,
    modelValue: {
      default: '',
      required: false,
      type: String,
    },
    readOnly: Boolean,
    parentDrawerClosed: {
      default: false,
      type: Boolean,
    },
    editable: {
      default: true,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    hasSoonaStorage: {
      default: false,
      required: true,
      type: Boolean,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      // Fast-pass member, initial selection
      fastPastMemberText: {
        value: '',
        addPackageTitle: 'store this package',
        emoji: '📦',
        price: '',
        priceNote: '',
        firstInfo: 'soona will store items from this shoot',
      },
      newValue: this.modelValue,
    };
  },
  computed: {
    computedValue: {
      get() {
        return this.newValue;
      },
      set(value) {
        this.newValue = value;
        this.$emit('update:modelValue', value);
      },
    },
    optionDisabled() {
      return (
        this.disabled ||
        !this.editable ||
        (this.option.value === 'already_stored' && !this.hasSoonaStorage)
      );
    },
  },
  watch: {
    /**
     * when v-model changes, set the internal value
     */
    modelValue(value) {
      this.newValue = value;
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.handling-option {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1.25rem 0;
  margin: 0 0.75rem;
  color: variables.$black-default;
  border-top: 1px solid variables.$gray-30;

  &__label {
    display: flex;
    align-items: flex-start;
    cursor: pointer;

    strong {
      color: variables.$black-default;
    }

    .handling-option--disabled & {
      cursor: not-allowed;
      color: variables.$gray-50;

      strong {
        color: variables.$gray-50;
      }
    }

    .handling-option--read-only & {
      cursor: default;
    }
  }

  &__input {
    flex: 0 0 1.5rem;
    margin-right: 8px;
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
    accent-color: variables.$periwink-blue-60;

    .handling-option--disabled & {
      cursor: not-allowed;
    }
  }

  &__label:hover {
    .handling-option__input {
      accent-color: variables.$periwink-blue-70;
    }
  }

  &__emoji {
    margin-right: 0.5rem;

    .handling-option--disabled & {
      opacity: 0.3;
    }
  }

  &__price {
    display: flex;
    align-items: center;
    margin-left: auto;
    padding-left: 0.5rem;

    > strong {
      color: variables.$black-default;

      small {
        @include variables_fonts.u-label--regular;
      }
    }

    > .tooltip {
      margin-left: 0.375rem;
      flex: 0 0 0.375rem;
    }

    &.disabled {
      cursor: not-allowed;
      strong {
        color: variables.$gray-50;
      }
    }
  }
}

@media (min-width: variables.$screen-sm-min) {
  .handling-option {
    flex-wrap: nowrap;
    padding: 1.5rem 0;
    margin: 0 1.5rem;

    &__price {
      @include variables_fonts.u-subheader--heavy;
    }
  }
}
</style>
