<script setup>
import { ref, computed } from 'vue';
import CompetitorPreviewCard from '@/components/user/anytime/competitive_analysis/CompetitorPreviewCard.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { useCompetitorWatchListQueryParams } from '@/components/user/anytime/competitive_analysis/useCompetitorWatchListQueryParams';
import {
  useCompetitors,
  useRefreshCompetitors,
} from '@/queries/useCompetitors';
import { keepPreviousData } from '@tanstack/vue-query';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import AddCompetitor from '@/components/user/anytime/competitive_analysis/AddCompetitor.vue';
import PaginatorFull from '@/components/directory/PaginatorFull.vue';
import { usePriorityError } from '@/composables/usePriorityError';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import SoonaNoResults from '@/components/ui_library/SoonaNoResults.vue';
import CompetitorsWatchlistFilter from './CompetitorsWatchlistFilter.vue';
import ReconnectAmazonDialog from '@/components/user/anytime/products/ReconnectAmazonDialog.vue';

const props = defineProps({
  accountId: { type: String, required: true },
  amazonIntegration: { type: Object, required: false },
  hasAmazonIntegration: { type: Boolean, required: true },
  hasLiveSubscription: { type: Boolean, required: true },
  totalCompetitorsCount: { type: Number, required: false },
  maxCompetitorsCount: { type: Number, required: false },
});

const { buttonClicked } = useBaseEvents();

const showReconnectAmazonDialog = ref(false);
const accountId = computed(() => props.accountId);
const amazonIntegration = computed(() => props.amazonIntegration);
const hasAmazonIntegration = computed(() => props.hasAmazonIntegration);
const hasLiveSubscription = computed(() => props.hasLiveSubscription);
const totalCompetitorsCount = computed(() => props.totalCompetitorsCount);
const maxCompetitorsCount = computed(() => props.maxCompetitorsCount);

const {
  searchText,
  searchDebounced,
  filter,
  sortBy,
  orderBy,
  direction,
  currentPage,
  itemsPerPage,
  resetFilters,
} = useCompetitorWatchListQueryParams('my watchlist');

const { mutate: refreshCompetitors } = useRefreshCompetitors(accountId);

const refresh = () => {
  if (amazonIntegration.value?.status === 'invalid_state') {
    showReconnectAmazonDialog.value = true;
    return;
  }

  resetFilters();
  searchText.value = '';
  refreshCompetitors();
  buttonClicked({
    context: 'competitive analysis',
    subContext: 'watchlist',
    buttonLabel: 'refresh',
    buttonAction: 'refresh',
  });
};

const {
  data: competitorsData,
  error,
  isSuccess,
  isLoading,
} = useCompetitors(
  accountId,
  {
    query: searchDebounced,
    filter,
    sortBy,
    orderBy,
    direction,
    currentPage,
    itemsPerPage,
  },
  {
    placeholderData: keepPreviousData,
  }
);

const priorityError = usePriorityError(error);

const competitors = computed(() => {
  return competitorsData.value?.competitors ?? [];
});

const scoringInProgress = computed(
  () => competitorsData.value?.scoring_in_progress
);

const competitorsPagination = computed(() =>
  competitorsData.value
    ? competitorsData.value.pagination
    : {
        totalCount: 0,
        currentPage: 0,
        totalPages: 0,
        itemsPerPage: 0,
      }
);
</script>

<template>
  <div class="competitors-card-list">
    <div class="competitors-card-list__header">
      <div>
        <h2 class="u-subheader--heavy">my watchlist</h2>
        <p
          v-if="hasAmazonIntegration && !hasLiveSubscription"
          class="competitors-card-list__title--competitors-count u-body--regular"
        >
          {{ totalCompetitorsCount }}/{{ maxCompetitorsCount }}
          added
        </p>
      </div>
      <div class="competitors-card-list__header--actions">
        <SoonaButton
          v-if="hasAmazonIntegration && competitors.length > 0"
          class="competitors-card-list__refresh-button"
          variation="secondary-gray"
          size="medium"
          :loading="scoringInProgress"
          @click="refresh"
        >
          <SoonaIcon name="refresh-cw-alt" />
          refresh
        </SoonaButton>
        <AddCompetitor :account-id="accountId" />
      </div>
    </div>
    <SoonaError v-if="priorityError">
      {{ priorityError }}
    </SoonaError>
    <CompetitorsWatchlistFilter
      v-if="hasLiveSubscription"
      v-model:search-text="searchText"
      v-model:sort-by="sortBy"
      :is-loading="isLoading"
    >
      <template #content-left>
        <div>
          <p
            class="competitors-card-list__title--competitors-count u-body--regular"
          >
            {{ totalCompetitorsCount }}/{{ maxCompetitorsCount }}
            added
          </p>
        </div>
      </template>
    </CompetitorsWatchlistFilter>
    <template v-if="isLoading">
      <CompetitorPreviewCard v-for="i in 3" :key="i" :is-loading="true" />
    </template>
    <TransitionGroup
      name="competitor"
      tag="div"
      class="competitors-card-list__list"
    >
      <SoonaNoResults
        v-if="!isLoading && isSuccess && competitors.length === 0"
        key="watchlist-empty-search-filter"
      >
        <template #header>oops, we couldn’t find a match!</template>
        <template #body>
          try a different search or filter combination
        </template>
        <template #button>
          <SoonaButton
            copy="reset filters"
            variation="secondary-black"
            :on-click="resetFilters"
          />
        </template>
      </SoonaNoResults>
      <CompetitorPreviewCard
        v-for="(competitor, i) in competitors"
        :key="i"
        :competitor="competitor"
      />
    </TransitionGroup>
    <PaginatorFull
      v-model:page="currentPage"
      v-model:records-per-page="itemsPerPage"
      class="competitors-card-list__paginator-top"
      :total-pages="competitorsPagination.totalPages"
      :total-count="competitorsPagination.totalCount"
      :page-count="competitors.length"
    />
    <ReconnectAmazonDialog
      v-if="showReconnectAmazonDialog"
      @close="showReconnectAmazonDialog = false"
    />
  </div>
</template>

<style lang="scss" scoped>
.competitors-card-list {
  margin: 1rem 0;
  display: flex;
  gap: 1rem;
  flex-direction: column;

  &__header {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: baseline;
    gap: 1rem;

    &--actions {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      gap: 1rem;
    }
  }

  &__search {
    padding-bottom: 1rem;
  }

  &__list {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

// transitions

/* base */
.competitor {
  backface-visibility: hidden;
  z-index: 1;
}

/* moving */
.competitor-move {
  transition: all 0.4s ease;
}

/* appearing */
.competitor-enter-active {
  transition: all 0.3s ease-out;
}

/* disappearing */
.competitor-leave-active {
  transition: all 0.3s ease-in;
  position: absolute;
  z-index: 0;
}

/* appear at / disppear to */
.competitor-enter-from,
.competitor-leave-to {
  opacity: 0;
}
</style>
