<script setup>
import { useFlag } from 'src/composables/useFlag';
import ShotListV2 from '@/components/user/anytime/shotlist/ShotList.vue';
import ShotList from '@/components/user/anytime/Shotlist.vue';

const toasterShotListBuilderFlag = useFlag('toaster_shot_list_builder');
</script>

<template>
  <ShotListV2 v-if="toasterShotListBuilderFlag" />
  <ShotList v-else />
</template>
