<script setup>
import { ref, computed, watch, nextTick } from 'vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaForm from '@/components/ui_library/SoonaForm.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaAlert from '@/components/ui_library/SoonaAlert.vue';
import SoonaSuccess from '@/components/ui_library/SoonaSuccess.vue';
import { usePriorityError } from 'src/composables/usePriorityError';
import { useReservation } from 'src/queries/useReservation';
import { useGetAccountsOrder } from '@/queries/accounts-orders/useGetAccountsOrder';
import { useApplyCoupon } from 'src/queries/reservations/useApplyCoupon';
import { useRemoveCoupon } from 'src/queries/reservations/useRemoveCoupon';
import { useValidateCoupon } from 'src/queries/reservations/useValidateCoupon';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { queryKeys } from 'src/queries/query-keys';
import { useAutoApplyCoupon } from '@/queries/orders/useAutoApplyDiscount';
import { useStore } from 'vuex';
import { useQueryClient } from '@tanstack/vue-query';

const props = defineProps({
  reservationId: {
    required: true,
  },
  orderId: {
    type: [String, Number],
    default: null,
    required: false,
  },
  // provide products if not passing in orderId
  products: {
    type: Array,
    default: null,
    required: false,
  },
  orderType: {
    type: String,
    default: 'down_payment',
    validator: function (value) {
      return ['down_payment', 'additional_charge'].includes(value);
    },
  },
  noOrderDiscount: {
    type: Object,
    default: null,
    required: false,
  },
});

const emit = defineEmits(['noOrderCouponValidated', 'noOrderCouponRemoved']);
const { inputChanged, buttonClicked } = useBaseEvents();
const reservationId = computed(() => props.reservationId);
const orderId = computed(() => props.orderId);
const products = computed(() => props.products);
const orderType = computed(() => props.orderType);
const noOrderDiscount = computed(() => props.noOrderDiscount);
const store = useStore();
const queryClient = useQueryClient();

const noOrderCouponCode = computed(
  () => noOrderDiscount.value?.external_discount_id
);
const noOrderCouponName = computed(() => noOrderDiscount.value?.code);
const noOrderAutoApply = computed(() => noOrderDiscount.value?.auto_apply);

const {
  data: reservation,
  isLoading: reservationLoading,
  error: reservationError,
} = useReservation(reservationId);

const accountId = computed(() => reservation.value?.account?.id);

const { data: order, error: orderError } = useGetAccountsOrder(
  accountId,
  { orderId },
  { enabled: computed(() => !!accountId.value && !!orderId.value) }
);

const {
  mutate: applyCoupon,
  isPending: isApplying,
  error: applyError,
} = useApplyCoupon(reservationId);

const {
  mutate: removeCoupon,
  isPending: isRemoving,
  error: removeError,
} = useRemoveCoupon(reservationId);

const { mutate: autoApplyCoupon, isPending: isAutoApplying } =
  useAutoApplyCoupon(orderId);

const {
  mutate: validateCoupon,
  isPending: isValidating,
  error: validateError,
} = useValidateCoupon(reservationId);

const priorityErrorDialog = usePriorityError(
  validateError,
  applyError,
  orderError,
  reservationError
);
const priorityErrorRemove = usePriorityError(removeError);

const showPromoDialog = ref(false);
const coupon = ref(null);
const couponInput = ref(null);

const orderDiscount = computed(() => order.value?.discount);
const orderDiscountName = computed(() => order.value?.discount?.code ?? '');
const orderDiscountCode = computed(
  () => order.value?.discount?.external_discount_id ?? null
);
const validationCodeInvalid = ref(false);
const isInvalid = computed(() => {
  if (validationCodeInvalid.value) return true;
  return order.value?.discount?.expired ?? false;
});
const isAutoApply = computed(() => {
  return !!order.value?.discount?.auto_apply || noOrderAutoApply.value;
});
const showRemove = computed(() => {
  if (isAutoApply.value) return false;
  if (order.value?.discount || !!noOrderCouponCode.value) return true;
  return false;
});
const codeToDisplay = computed(() => {
  if (orderId.value === null && noOrderCouponName.value !== null)
    return noOrderCouponName.value;
  return orderDiscountName.value;
});

watch(
  orderDiscountCode,
  newVal => {
    if (orderId.value !== null && !isAutoApply.value) coupon.value = newVal;
  },
  { immediate: true }
);

const handleDialogToggle = async isShowing => {
  showPromoDialog.value = isShowing;
  if (!isShowing && !isAutoApply.value) coupon.value = orderDiscountCode.value;

  buttonClicked({
    context: 'booking',
    subContext: 'down payment',
    buttonLabel: 'promo code',
    buttonAction: 'toggleShowPromoModal',
  });

  await nextTick();
  if (isShowing) couponInput.value?.focus();
};

const reloadAdditionalChargeOrder = () => {
  store.dispatch('reservation/loadAdditionalChargeOrder', orderId.value);
};

const handleApply = (event, closeDialog) => {
  const data = new FormData(event.target);
  if (orderId.value === null) {
    validationCodeInvalid.value = false;
    validateCoupon(
      {
        coupon: data.get('coupon'),
        products: products.value,
        orderType: orderType.value,
        currentDiscountId: noOrderDiscount.value?.id,
      },
      {
        onSuccess: e => {
          emit('noOrderCouponValidated', e);
          closeDialog();
        },
      }
    );
    return;
  }

  applyCoupon(
    {
      coupon: data.get('coupon'),
      orderId: orderId.value,
    },
    {
      onSuccess: () => {
        closeDialog();
        queryClient.invalidateQueries({
          queryKey: queryKeys.accountOrder(accountId.value, {
            orderId: orderId.value,
          }),
        });
        if (orderType.value === 'additional_charge') {
          reloadAdditionalChargeOrder();
        }
      },
    }
  );
};

const handleRemove = () => {
  if (orderId.value === null) {
    coupon.value = null;
    emit('noOrderCouponRemoved');
    return;
  }

  removeCoupon(orderId.value, {
    onSuccess: () => {
      autoApplyCoupon(
        {},
        {
          onSettled: () => {
            queryClient.invalidateQueries({
              queryKey: queryKeys.accountOrder(accountId.value, {
                orderId: orderId.value,
              }),
            });
            if (orderType.value === 'additional_charge') {
              reloadAdditionalChargeOrder();
            }
          },
        }
      );
    },
  });
  buttonClicked({
    context: 'booking',
    subContext: 'down payment',
    buttonLabel: 'remove',
    buttonAction: 'removeCouponClicked',
  });
};

watch(
  // changed this to just track total quantity of products (not quantity of each product)
  // watching products will cause an infinite loop with the watch bestAutoApplyDiscount on ReservationShootUpgrades.vue
  // this logic will need to be adjusted if we update discounts to take in consideration the quantity of each product
  () => products.value?.length,
  () => {
    // re-validates coupon when products change,
    // in case coupon has product-specific requirements,
    // also updates product-specific discount values
    if (noOrderCouponCode.value !== null) {
      validationCodeInvalid.value = false;
      validateCoupon(
        {
          coupon: noOrderCouponCode.value,
          products: products.value,
          orderType: orderType.value,
        },
        {
          onSuccess: e => {
            emit('noOrderCouponValidated', e);
          },
          onError: e => {
            if (e?.response?.data?.code === 'invalid_coupon') {
              emit('noOrderCouponRemoved');
              validationCodeInvalid.value = true;
            }
          },
        }
      );
    }
  },
  { deep: true }
);

watch(orderDiscount, newDiscount => {
  if (!newDiscount) return;
  if (!newDiscount.auto_apply) return;
  if (newDiscount.is_valid && !newDiscount.expired) return;

  removeCoupon(orderId.value, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.accountOrder(accountId.value, {
          orderId: orderId.value,
        }),
      });
    },
  });
});
</script>

<template>
  <button
    v-bind="$attrs"
    type="button"
    class="u-button-reset u-label--regular promo-code__button"
    @click="handleDialogToggle(true)"
  >
    promo code
  </button>
  <component :is="isInvalid ? SoonaAlert : SoonaSuccess" v-if="showRemove">
    <span class="promo-code__has-remove">
      <span>
        promo code <b>{{ codeToDisplay }}</b>
        <template v-if="isInvalid"> is no longer valid</template>
        <template v-else> has been successfully applied</template>
      </span>
      <SoonaButton
        type="button"
        size="medium"
        variation="secondary-gray"
        :loading="isRemoving || isAutoApplying"
        @click="handleRemove"
      >
        remove
      </SoonaButton>
    </span>
  </component>
  <SoonaError v-if="priorityErrorRemove">
    we are having trouble removing this promo code. please try again later.
  </SoonaError>
  <SoonaDialog
    v-if="showPromoDialog"
    size="small"
    @close="handleDialogToggle(false)"
  >
    <template #heading>enter your promo code!</template>
    <template #default="{ close }">
      <SoonaForm id="coupon-form" @submit="e => handleApply(e, close)">
        <SoonaTextfield
          ref="couponInput"
          v-model="coupon"
          label="coupon"
          name="coupon"
          class="promo-code__input"
          :disabled="
            reservationLoading || isApplying || isRemoving || isValidating
          "
          :required="true"
          autocomplete="off"
          @blur="
            inputChanged({
              context: 'booking',
              subContext: 'down payment',
              inputLabel: 'coupon',
              inputType: 'text',
            })
          "
        />
        <SoonaError v-if="priorityErrorDialog" class="promo-code__dialog-error">
          {{ priorityErrorDialog }}
        </SoonaError>
      </SoonaForm>
    </template>
    <template #footer="{ close }">
      <SoonaButton
        type="button"
        variation="tertiary"
        size="medium"
        @click="close"
      >
        cancel
      </SoonaButton>
      <SoonaButton
        form="coupon-form"
        type="submit"
        :disabled="reservationLoading || isRemoving"
        :loading="isApplying || isValidating"
      >
        apply
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>

<style lang="scss" scoped>
@use '@/variables';

.promo-code {
  &__button {
    width: fit-content;
    text-decoration: underline;

    &:hover,
    &:focus-visible {
      text-decoration: none;
    }

    .booking-downpayment__payment--no-frame &,
    .downpayment__payment--no-frame & {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }

    + .soona-notification,
    + .soona-notification + .soona-notification {
      margin: 0 0 1.25rem;
    }
  }

  &__input {
    padding-bottom: 0;
  }

  &__dialog-error {
    margin-bottom: 0;
  }

  &__has-remove {
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;

    > button {
      align-self: center;
    }
  }
}
</style>
