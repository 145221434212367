<template>
  <div class="shipping">
    <div class="column is-full soona-container">
      <div class="columns is-centered">
        <div class="column is-half">
          <h2 class="title has-text-centered is-size-4-mobile">
            send us the goods!
          </h2>

          <!-- soona address -->

          <p class="soona-title-a1 mb-m"><b>SHIP TO:</b></p>

          <div class="location-address-container mb-l">
            <p class="u-small--regular">soona</p>
            <p class="u-small--regular">
              {{
                [
                  currentShipment.address_to.street1,
                  currentShipment.address_to.street2,
                ].join(' ')
              }}, {{ currentShipment.address_to.city }},
              {{ currentShipment.address_to.state }}
              {{ currentShipment.address_to.zip }}
            </p>
          </div>

          <!-- customer address -->

          <p class="soona-title-a1 mb-m"><b>FROM:</b></p>

          <div class="location-address-container mb-l">
            <p class="u-small--regular">
              {{ currentShipment.address_from.name }}
            </p>
            <p class="u-small--regular">
              {{
                [
                  currentShipment.address_from.street1,
                  currentShipment.address_from.street2,
                ].join(' ')
              }}, {{ currentShipment.address_from.city }},
              {{ currentShipment.address_from.state }}
              {{ currentShipment.address_from.zip }}
            </p>
          </div>

          <p class="soona-title-a1 mb-m"><b>SHIPPING OPTIONS:</b></p>

          <div class="shipping-form-container mb-l">
            <div
              v-for="rate in currentRates"
              :key="rate.rate_id"
              class="columns is-mobile"
            >
              <div class="column is-narrow pr-s">
                <input
                  type="radio"
                  name="rate"
                  data-cypress="shipping-option"
                  @click="
                    setRate(rate, `${rate.provider} ${rate.service_level}`)
                  "
                />
              </div>
              <div class="column">
                <label class="radio u-small--regular">
                  <p>
                    <b class="soona-title-a1"
                      >{{ rate.provider }} {{ rate.service_level }}</b
                    >
                  </p>
                  <p class="is-lowercase">
                    {{ overrideDurationTerms(rate.duration_terms) }}
                  </p>
                  <p>${{ rate.amount }}</p>
                </label>
              </div>
            </div>
          </div>

          <div class="free-shipping-policy">
            <p v-if="flatRate" class="u-small--regular mb-m">
              <b>important!</b> to take advantage of the flat rate shipping
              option you must use a <b><i>flat rate box from USPS</i></b
              >. the branded boxes are available for free
              <a
                class="soona-link"
                target="_blank"
                rel="noopener noreferrer"
                href="https://store.usps.com/store/results/shipping-supplies/flat-rate/_/N-7d0v8vZ1wrlxch"
                >online or for pick up at your local post office</a
              >. soona is not liable for issues with shipping. please ensure
              your box is properly sealed and packaged with appropriate
              materials.
            </p>

            <p class="u-small--regular mb-m">
              <b>restrictions!</b> please note that your package must not exceed
              70 pounds in weight. all postal service restrictions for shipping
              are applicable. please review the
              <a
                class="soona-link"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.usps.com/ship/shipping-restrictions.htm"
                >USPS Shipping Restrictions</a
              >
              before confirming.
            </p>

            <label class="u-small--regular">
              <input
                id="understand"
                class="mr-s"
                type="checkbox"
                name="understand"
                required
                autofocus
                @click="toggleDisable"
              />
              I agree to follow all guidelines and restrictions</label
            >
          </div>

          <div class="buttons is-centered">
            <SoonaButton
              :disabled="selectedRate == undefined || disable"
              :on-click="submitRateClicked"
              copy="continue"
              size="medium"
            />
          </div>
          <div class="buttons is-centered">
            <a class="soona-link u-small--regular" @click.prevent="onCancel">
              cancel
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted } from 'vue';
import { mapActions, mapState } from 'vuex';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useRoute } from 'vue-router';
import { useTitle } from '@vueuse/core';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';

export default {
  name: 'Rates',
  components: { SoonaButton },
  setup() {
    const { buttonClicked, inputChanged, linkClicked, pageViewed } =
      useBaseEvents();

    const route = useRoute();
    const title = useTitle();

    onMounted(() => {
      title.value = `${route.meta.page_title} | soona`;

      pageViewed();
    });

    return {
      buttonClicked,
      inputChanged,
      linkClicked,
    };
  },
  data() {
    return {
      disable: true,
      selectedRate: undefined,
    };
  },
  computed: {
    ...mapState({
      currentRates: state => state.reservation.currentRates,
      currentShipment: state => state.reservation.currentShipment,
      flatRate: state => state.reservation.flatRate,
      currentReservation: state => state.reservation.currentReservation,
    }),
  },
  methods: {
    ...mapActions('reservation', ['submitRate']),
    toggleDisable(e) {
      this.inputChanged({
        context: this.$route.meta.context,
        subContext: 'shipping rates',
        inputLabel: 'I agree to follow all guidelines and restrictions',
        inputType: 'checkbox',
        inputValue: e.target.checked,
      });

      this.disable = !this.disable;
    },
    submitRateClicked() {
      this.buttonClicked({
        context: this.$route.meta.context,
        subContext: 'shipping rates',
        buttonLabel: 'continue',
        buttonAction: 'advance shipping flow',
      });

      this.submitRate({ rate: this.selectedRate });
    },
    setRate(rate, inputLabel) {
      this.inputChanged({
        context: this.$route.meta.context,
        subContext: 'shipping rates',
        inputLabel: inputLabel,
        inputType: 'radio',
      });

      this.selectedRate = rate;
    },
    overrideDurationTerms(terms) {
      if (
        terms ===
        'Delivery within 1, 2, or 3 days based on where your package started and where it’s being sent.'
      ) {
        return 'delivery guaranteed within 3 business days based on your location';
      } else {
        return terms;
      }
    },
    onCancel() {
      this.linkClicked({
        context: this.$route.meta.context,
        subContext: 'shipping rates',
        linkLabel: 'cancel',
        linkHref: '/user/anytime#/reservation/info',
      });

      this.$router.push({ name: 'info' });
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';

.pr-s {
  padding-right: 0.375rem;
}

.radio {
  margin-left: 0;
}
</style>
