<script setup>
import SelfServeCheckoutItem from '@/components/user/anytime/self_serve_purchases/SelfServeCheckoutItem.vue';
import ProductSummaryPayment from '@/components/user/anytime/self_serve_purchases/ProductSummaryPayment.vue';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaNoResults from '@/components/ui_library/SoonaNoResults.vue';
import SoonaPayment from '@/components/SoonaPayment.vue';
import SoonaPaymentMethods from '@/components/SoonaPaymentMethods.vue';
import PromoCode from '@/components/PromoCode.vue';
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useFlag } from '@/composables/useFlag';
import SoonaBackButton from '@/components/ui_library/SoonaBackButton.vue';
import { useIntegrations } from '@/composables/useIntegrations';

const props = defineProps({
  canViewPaymentInfo: {
    type: Boolean,
    default: true,
    required: false,
  },
  isOrderProcessing: {
    type: Boolean,
    required: true,
  },
  reservationId: {
    type: String,
    default: null,
    required: false,
  },
  reservationIdForDiscount: {
    type: [String, Number],
    default: null,
    required: false,
  },
  orderId: {
    type: String,
    default: null,
    required: false,
  },
  isCompedOrder: {
    type: Boolean,
    default: false,
    required: false,
  },
  isPreferredOrder: {
    type: Boolean,
    default: false,
    required: false,
  },
  placeOrder: {
    type: Function,
    required: true,
  },
  products: {
    type: Object,
    default: null,
    required: true,
  },
  segment: {
    type: String,
    required: false,
  },
  isCreditable: {
    type: Boolean,
    required: false,
    default: true,
  },
  productsAreRemovable: {
    type: Boolean,
    required: false,
    default: true,
  },
  showPromoCode: {
    type: Boolean,
    required: false,
    default: false,
  },
  preferred: {
    type: Object,
    required: false,
    default: null,
  },
  discountObj: {
    type: Object,
    required: false,
    default: null,
  },
});

const emits = defineEmits([
  'incrementProduct',
  'decrementProduct',
  'setProductCount',
  'close',
  'back',
  'orderDeclined',
  'noOrderCouponValidated',
  'noOrderCouponRemoved',
]);

const termsOfServiceDate = import.meta.env.VITE_TERMS_OF_SERVICE_DATE;

const store = useStore();

const accountId = computed(() => store.state.currentUser?.accountId);

const headerSuffix = computed(() => {
  const suffix = props.products.length === 1 ? 'item' : 'items';
  return `(${props.products.length} ${suffix})`;
});

const products = computed(() => props.products);
const showPromoCode = computed(() => props.showPromoCode);
const orderId = computed(() => props.orderId);
const reservationId = computed(() => props.reservationId);
const reservationIdForDiscount = computed(() => props.reservationIdForDiscount);
const discountObj = computed(() => props.discountObj);
const isOrderProcessing = computed(() => props.isOrderProcessing);

const isOrder = computed(() => {
  return !!orderId.value && !reservationId.value;
});

function handleIncrementProduct(product) {
  emits('incrementProduct', product);
}
function handleDecrementProduct(product) {
  emits('decrementProduct', product);
}
function handleSetProductCount(product, newVal) {
  emits('setProductCount', product, newVal);
}

const { hasShopifyIntegration } = useIntegrations(accountId);

const subtotal = computed(() => {
  return products.value?.reduce(
    (sum, product) => sum + product.rate * product.quantity,
    0
  );
});

const creditsTotal = 0;

const couponDiscountAmount = computed(() => {
  if (!discountObj.value) return 0;
  if (discountObj.value?.amount) return discountObj.value.amount;
  return 0;
});

const finalTotal = computed(() => {
  return subtotal.value - creditsTotal - couponDiscountAmount.value;
});

const paymentMethodAction = async (
  paymentMethodId,
  paymentMethodType,
  savePaymentMethod
) => {
  if (showPromoCode.value && discountObj.value) {
    await props.placeOrder(
      paymentMethodId,
      paymentMethodType,
      savePaymentMethod,
      {
        externalDiscountId: discountObj.value?.external_discount_id,
      }
    );
    return;
  }
  await props.placeOrder(paymentMethodId, paymentMethodType, savePaymentMethod);
};

const paymentAction = async (stripe, value, saveCard) => {
  if (showPromoCode.value && discountObj.value) {
    await props.placeOrder(stripe, value, saveCard, {
      externalDiscountId: discountObj.value?.external_discount_id,
    });
    return;
  }
  await props.placeOrder(stripe, value, saveCard);
};

const paymentsPaymentMethodsFlag = useFlag('payments_payment_methods');
</script>

<template>
  <div class="title-bar u-container">
    <div>
      <SoonaBackButton button-text="back" @click="emits('back')" />
    </div>
    <div class="title-bar__header">
      <span class="large-title">checkout</span>
      <span class="title-bar__header-suffix">{{ headerSuffix }}</span>
    </div>
  </div>
  <div v-if="products?.length === 0" class="u-container checkout-empty">
    <SoonaNoResults>
      <template #header>checkout is empty</template>
      <template #button>
        <SoonaButton variation="secondary-gray" @on-click="emits('back')">
          back
        </SoonaButton>
      </template>
    </SoonaNoResults>
  </div>
  <div v-else class="checkout u-container">
    <div class="checkout__right">
      <div class="summary-section">
        <ProductSummaryPayment
          :account-id="accountId"
          :products="products"
          :preferred="preferred"
          :is-order="isOrder"
          :is-comped-order="isCompedOrder"
          :can-view-payment-info="canViewPaymentInfo"
          :is-creditable="isCreditable"
          :order-id="orderId"
          :reservation-id="reservationIdForDiscount || reservationId"
          :coupon="discountObj"
        >
          <template #payment>
            <SoonaPaymentMethods
              v-if="!isCompedOrder && paymentsPaymentMethodsFlag"
              :stripe-payment-required="
                !hasShopifyIntegration && finalTotal > 0
              "
              :on-payment-action="paymentMethodAction"
              :total="finalTotal"
              :account-id="accountId"
              :payment-method-types="['card', 'us_bank_account']"
              action-text="place order"
            >
              <template v-if="showPromoCode" #action-block>
                <PromoCode
                  :reservation-id="reservationIdForDiscount || reservationId"
                  :products="products"
                  :order-id="orderId"
                  :no-order-discount="discountObj"
                  order-type="additional_charge"
                  @no-order-coupon-validated="
                    e => emits('noOrderCouponValidated', e)
                  "
                  @no-order-coupon-removed="emits('noOrderCouponRemoved')"
                />
              </template>
            </SoonaPaymentMethods>
            <SoonaPayment
              v-else-if="!isCompedOrder"
              :stripe-payment-required="
                !hasShopifyIntegration && finalTotal > 0
              "
              :on-payment-action="paymentAction"
              action-text="place order"
            >
              <template v-if="showPromoCode" #action-block>
                <PromoCode
                  :reservation-id="reservationIdForDiscount || reservationId"
                  :products="products"
                  :order-id="orderId"
                  :no-order-discount="discountObj"
                  order-type="additional_charge"
                  @no-order-coupon-validated="
                    e => emits('noOrderCouponValidated', e)
                  "
                  @no-order-coupon-removed="emits('noOrderCouponRemoved')"
                />
              </template>
            </SoonaPayment>
            <div v-else class="summary-section__comped-submit-wrapper">
              <SoonaButton
                class="summary-section__comped-submit-button"
                @click="placeOrder"
              >
                accept order
              </SoonaButton>
            </div>
            <div
              v-if="isOrder && !isCompedOrder"
              class="summary-section__decline_order"
            >
              <SoonaButton
                copy="decline order"
                variation="tertiary"
                @on-click="emits('orderDeclined')"
              />
            </div>
          </template>
        </ProductSummaryPayment>
      </div>
      <div v-if="canViewPaymentInfo" class="terms-of-use-section">
        <p class="terms-of-use-section__copy">
          our crew commitment: we believe in quality content for all. if you
          don’t LOVE your content <b>we make it right</b>. please review our
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.soona.co/refund-policy"
            class="terms-of-use-section__link"
            @click="cancellationAndRefundPolicyClicked"
            >cancellation and refund policy</a
          >. by placing an order with soona you agree to our
          <a
            href="https://soona.co/terms/"
            target="_blank"
            class="terms-of-use-section__link"
            >terms of service</a
          >

          ({{ termsOfServiceDate }}).
        </p>
        <p v-if="isPreferredOrder" class="terms-of-use-section__copy">
          purchase of preferred credits is subject to additional terms found
          <a
            href="https://soona.co/preferred-terms"
            target="_blank"
            class="terms-of-use-section__link"
            >here</a
          >.
        </p>
        <p
          v-if="isPreferredOrder && !isCompedOrder"
          class="terms-of-use-section__copy"
        >
          ACH payment is also available. email us at
          <a class="terms-of-use-section__link" href="mailto:hey@soona.co"
            >hey@soona.co</a
          >
        </p>
      </div>
    </div>
    <div class="checkout__left">
      <div class="product-section">
        <SelfServeCheckoutItem
          v-for="product in products"
          :key="product.id"
          :product="product"
          :segment="segment"
          :is-order="isOrder"
          :is-removable="productsAreRemovable"
          :is-order-processing="isOrderProcessing"
          @increment-product="handleIncrementProduct"
          @decrement-product="handleDecrementProduct"
          @set-product-count="handleSetProductCount"
        >
          <template #product-info>
            <slot name="product-info" :product="product"></slot>
          </template>
        </SelfServeCheckoutItem>
      </div>
      <div
        v-if="canViewPaymentInfo"
        class="terms-of-use-section terms-of-use-section__mobile"
      >
        <hr />
        <p class="terms-of-use-section__copy">
          our crew commitment: we believe in quality content for all. if you
          don’t LOVE your content <b>we make it right</b>. please review our
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.soona.co/refund-policy"
            class="terms-of-use-section__link"
            @click="cancellationAndRefundPolicyClicked"
            >cancellation and refund policy</a
          >. by placing an order with soona you agree to our
          <a
            href="https://soona.co/terms/"
            target="_blank"
            class="terms-of-use-section__link"
            >terms of service</a
          >

          ({{ termsOfServiceDate }}).
        </p>
        <p v-if="isPreferredOrder" class="terms-of-use-section__copy">
          purchase of preferred credits is subject to additional terms found
          <a
            href="https://soona.co/preferred-terms"
            target="_blank"
            class="terms-of-use-section__link"
            >here</a
          >.
        </p>
        <p
          v-if="isPreferredOrder && !isCompedOrder"
          class="terms-of-use-section__copy"
        >
          ACH payment is also available. email us at
          <a class="terms-of-use-section__link" href="mailto:hey@soona.co"
            >hey@soona.co</a
          >
        </p>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables';

.title-bar {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  &__header-suffix {
    padding-left: 1rem;
  }
}

.checkout-empty {
  margin-top: 1.5rem;
}

.checkout {
  display: flex;
  flex-direction: row-reverse;

  @media (min-width: variables.$screen-sm-min) {
    gap: 3.5rem;
  }

  &__left {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (min-width: variables.$screen-sm-min) {
      width: 70%;
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    width: 0%;

    @media (min-width: variables.$screen-sm-min) {
      width: 40%;
    }
  }

  .summary-section {
    display: flex;
    flex-direction: column;
    padding-top: 1.5rem;

    &__comped-submit-wrapper {
      display: flex;
      justify-content: center;
    }
  }

  .terms-of-use-section {
    display: none;

    &__copy {
      font-size: 0.75rem;
      margin-bottom: 1rem;
    }

    &__link {
      text-decoration: underline;
    }

    &__mobile {
      display: block;
      margin-top: 1rem;
    }

    @media (min-width: variables.$screen-sm-min) {
      display: block;
      margin-top: 1rem;

      &__mobile {
        display: none;
      }
    }

    &__decline_order {
      text-align: center;
      margin-top: 0.5rem;
    }
  }

  .product-section {
    display: flex;
    flex-direction: column;

    &__right {
      grid-area: 2 / 2 / 2 / 2;
      padding-left: 0.75rem;
    }

    &__bottom {
      grid-area: 3 / 1 / 3 / 3;

      @media (min-width: variables.$screen-md-min) {
        grid-area: 3 / 2 / 3 / 2;
        padding-left: 0.75rem;
      }
    }
  }
}
</style>
