<script setup>
import { ref, onMounted, watchEffect } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaToggleWithText from '@/components/ui_library/SoonaToggleWithText.vue';
import SubscriptionsDialog from 'src/components/modal-payment-flows/subscriptions/SubscriptionsDialog.vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useRoute } from 'vue-router';
import { usePromotionEvents } from '@/composables/segment/usePromotionEvents';

const emit = defineEmits(['update:activePromo']);

const { linkClicked, inputChanged } = useBaseEvents();
const { promotionViewed } = usePromotionEvents();

const route = useRoute();

const showLearnMoreModal = ref(false);
const subContext = ref('down payment');
const selected = ref(false);

const updateOpt = value => {
  selected.value = value;

  inputChanged({
    context: route.meta.context,
    subContext: subContext.value,
    inputLabel: 'subscription opt-in',
    inputType: 'toggle',
    inputValue: value,
  });

  emit('update:activePromo', value ? 'subscription_promo_opt_in' : '');
};
const trackTosClick = e => {
  linkClicked({
    context: route.meta.context,
    subContext: subContext.value,
    linkLabel: 'terms of service',
    linkHref: e.target.href,
  });
};
const handleOpenModal = () => {
  linkClicked({
    context: route.meta.context,
    subContext: subContext.value,
    linkLabel: 'learn more',
    linkHref: null,
  });

  showLearnMoreModal.value = true;
};

watchEffect(() => {
  promotionViewed({
    creativeName: 'thirty day monthly tier one subscription trial',
    creativeSlot: 'booking checkout',
    promotionName: null,
  });
});

onMounted(async () => {
  emit('update:activePromo', selected.value ? 'subscription_promo_opt_in' : '');
});
</script>

<template>
  <div class="subscription-promo-banner u-body--regular">
    <div class="subscription-promo-banner--content">
      <div>
        <p class="u-label--heavy promo-details">
          free trial - unlock all soona platform features for 30 days
        </p>
        <p>$19/month after free trial</p>
      </div>
      <SoonaToggleWithText :is-selected="selected" @change="updateOpt" />
    </div>
    <div class="subscription-promo-banner--footer">
      <SoonaButton
        copy="learn more"
        variation="tertiary"
        @click="handleOpenModal"
      />
      <SoonaButton
        element="a"
        copy="terms of service"
        href="https://soona.co/terms"
        target="_blank"
        variation="tertiary"
        @click="trackTosClick"
      />
    </div>
  </div>
  <SubscriptionsDialog
    v-if="showLearnMoreModal"
    flow="promoInformationOnly"
    :standalone-sub-context="subContext"
    @close="showLearnMoreModal = false"
  />
</template>

<style scoped lang="scss">
@use '@/variables';

.subscription-promo-banner {
  margin: 0 1.5rem;
  padding: 1.3125rem 1.25rem 1.375rem;
  border-radius: 0.5rem;
  color: white;
  background: variables.$gradient-periwink-error;

  &--header {
    display: flex;
    justify-content: space-between;
  }

  &--content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0.4375rem 0 0.3125rem;

    & > div {
      display: flex;
      flex-direction: column;
      gap: 0.6875rem;
    }

    & .promo-details {
      font-size: 1rem;
      margin-right: 1rem;
    }
  }

  &--footer {
    display: flex;
    justify-content: space-between;

    button,
    a {
      color: variables.$white-default;
      text-decoration: underline;

      &:not(:disabled):hover {
        color: variables.$white-translucent-50;
      }
    }
  }
}

.checkout__summary-payment {
  .subscription-promo-banner {
    margin: 1rem 0 0;
    order: 5;
  }
}
</style>
