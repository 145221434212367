<script setup>
import { computed } from 'vue';
import SoonaPaymentMethods from 'src/components/SoonaPaymentMethods.vue';

const props = defineProps({
  hasShopifyIntegration: {
    type: Boolean,
    required: true,
  },
  totalAmount: {
    type: Number,
    required: true,
  },
  accountId: {
    type: [Number, String],
    required: true,
  },
});

const emits = defineEmits(['submit']);

const accountId = computed(() => props.accountId);
const termsOfServiceDate = import.meta.env.VITE_TERMS_OF_SERVICE_DATE;

const submitPayment = (
  paymentMethodId,
  paymentMethodType,
  savePaymentMethod,
  shopifyStore
) =>
  emits(
    'submit',
    paymentMethodId,
    paymentMethodType,
    savePaymentMethod,
    shopifyStore
  );
</script>
<template>
  <SoonaPaymentMethods
    :on-payment-action="submitPayment"
    :stripe-payment-required="!hasShopifyIntegration"
    :is-stripe-disclaimer-below-checkout-button="true"
    :action-text="'CONFIRM & PAY'"
    :total="totalAmount"
    :account-id="accountId"
    :payment-method-types="['card', 'us_bank_account']"
    :has-wide-payment-button="true"
    action-variation="solid-black"
  >
    <template #action-block>
      <div class="total">
        <h4 class="total__label">TOTAL</h4>
        <h4 class="total__amount">${{ totalAmount }}</h4>
      </div>
    </template>
    <template #footer-disclaimer>
      <p class="u-small--regular">
        our crew commitment: we believe in quality content for all. if you don’t
        LOVE your content <b>we make it right</b>. please review our
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.soona.co/refund-policy"
          class="terms-of-use-section__link"
          @click="cancellationAndRefundPolicyClicked"
          >cancellation and refund policy</a
        >. by placing an order with soona you agree to our
        <a
          href="https://soona.co/terms/"
          target="_blank"
          class="terms-of-use-section__link"
          >terms of service</a
        >

        ({{ termsOfServiceDate }}).
      </p>
    </template>
  </SoonaPaymentMethods>
</template>
<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.total {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

  &__label {
    font-weight: 900 !important;
  }

  &__amount {
    font-weight: 800 !important;
  }
}

.terms-of-use-section {
  &__copy {
    font-size: 0.75rem;
    margin-bottom: 1rem;
  }

  &__link {
    text-decoration: underline;
  }

  &__mobile {
    display: block;
    margin-top: 1rem;
  }

  @media (min-width: variables.$screen-sm-min) {
    display: block;
    margin-top: 1rem;
  }

  &__decline_order {
    text-align: center;
    margin-top: 0.5rem;
  }
}
</style>
