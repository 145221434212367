<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import AutoEditsFeedback from '@/components/crew/AutoEditsFeedback.vue';
import AddOnOrderCollections from 'src/components/user/anytime/crew/AddOnOrderCollections.vue';
import AddOnOrderLineItem from 'src/components/user/anytime/crew/AddOnOrderLineItem.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import { useBulkPendReservationFiles } from '@/queries/orders/useBulkPendReservationFiles';
import { useBulkRejectReservationFiles } from '@/queries/orders/useBulkRejectReservationFiles';
import { useUpdateAccountsOrder } from 'src/queries/accounts-orders/useUpdateAccountsOrder';

const props = defineProps({
  order: Object,
});

const store = useStore();

const order = computed(() => props.order);
const orderIsPaid = computed(() => props.order.status == 'paid');

const {
  mutate: bulkPend,
  error: bulkPendError,
  isPending: bulkPendIsLoading,
} = useBulkPendReservationFiles(order);
const {
  mutate: bulkReject,
  error: bulkRejectError,
  isPending: bulkRejectIsLoading,
} = useBulkRejectReservationFiles(order);
const {
  mutate: mutateAccountsOrders,
  error: updateAccountsOrdersError,
  isPending: updateAccountsOrdersIsLoading,
} = useUpdateAccountsOrder(order.value?.account?.id, {
  enabled: order.value?.account?.id,
});

const reservationFiles = computed(() => {
  const fileMap = order.value.reservation_line_items
    .filter(rli => rli.reservation_file)
    .reduce(
      (acc, rli) => ({
        ...acc,
        [rli.reservation_file.id]: rli.reservation_file,
      }),
      {}
    );

  return Object.values(fileMap);
});

const collections = computed(() => {
  let collections = [];
  order.value.reservation_line_items.forEach(rli => {
    if (rli.collection && rli.product.product_type !== 'media_add_on') {
      collections.push(rli.collection);
    }
  });
  return collections;
});

const anyCollectionHasRaws = computed(() => {
  return collections.value.some(collection =>
    collection.reservation_files.some(rf => rf.raw_url)
  );
});

const pendingEdits = computed(() => {
  return reservationFiles.value?.flatMap(rf =>
    rf.derivatives?.filter(
      derivative => derivative.edit_status === 'pending_edit'
    )
  );
});

const rejectedEdits = computed(() => {
  return reservationFiles.value?.flatMap(rf =>
    rf.derivatives?.filter(
      derivative => derivative.edit_status === 'rejected_edit'
    )
  );
});

const completeOrder = order => {
  mutateAccountsOrders(
    { order_id: order.id, status: 'completed' },
    {
      onSuccess: async () => {
        await store.dispatch('reservation/loadOrders', order.reservation_id);
      },
    }
  );
};

const showFeedback = ref(false);

const confirmBulkReject = () => {
  showFeedback.value = false;
  bulkReject(pendingEdits.value.map(pe => pe.id));
};
</script>
<template>
  <AutoEditsFeedback
    v-if="showFeedback"
    category="auto_edits_post_production"
    subject-type="Order"
    :subject-id="order.id"
    :open="showFeedback"
    options-type="post"
    @close="() => (showFeedback = false)"
    @confirm="confirmBulkReject"
  />
  <div class="add-on-order">
    <div class="add-on-order__action-buttons">
      <SoonaButton
        v-if="reservationFiles.some(rf => rf.raw_url) || anyCollectionHasRaws"
        copy="download order"
        element="a"
        :href="`/reservations/${order.reservation_id}/download_order_raw_files/${order.id}.zip`"
        size="medium"
      />
      <SoonaError v-if="bulkPendError">
        {{ bulkPendError }}
      </SoonaError>
      <SoonaButton
        v-if="orderIsPaid && rejectedEdits.length > 0"
        :disabled="bulkPendIsLoading"
        :is-loading="bulkPendIsLoading"
        copy="show all"
        variation="secondary-black"
        size="medium"
        @click="bulkPend(rejectedEdits.map(re => re.id))"
      />
      <SoonaError v-if="bulkRejectError">
        {{ bulkRejectError }}
      </SoonaError>
      <SoonaButton
        v-if="orderIsPaid && pendingEdits.length > 0"
        :disabled="bulkRejectIsLoading"
        :is-loading="bulkRejectIsLoading"
        copy="hide all"
        variation="secondary-black"
        size="medium"
        @click="() => (showFeedback = true)"
      />
      <SoonaError v-if="updateAccountsOrdersError">
        {{ updateAccountsOrdersError }}
      </SoonaError>
      <SoonaButton
        v-if="orderIsPaid"
        :disabled="updateAccountsOrdersIsLoading"
        :is-loading="updateAccountsOrdersIsLoading"
        copy="complete order"
        variation="secondary-black"
        size="medium"
        @click="completeOrder(order)"
      />
    </div>
    <AddOnOrderLineItem
      v-for="reservationFile in reservationFiles"
      :key="reservationFile.id"
      :reservation-file="reservationFile"
      :order="order"
    />
    <section v-if="collections.length > 0" class="collections-container">
      <AddOnOrderCollections :collections="collections" :order="order" />
    </section>
    <section class="totals-container">
      <p class="u-headline--heavy is-size-5-mobile">total:</p>
      <p class="u-headline--heavy is-size-5-mobile">
        {{
          Number(order.amount_collected).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })
        }}
      </p>
    </section>
  </div>
</template>
<style lang="scss">
.add-on-order {
  width: 100%;

  &__action-buttons {
    display: flex;
    gap: 0.5rem;
    margin-bottom: 1.5rem;
  }

  .totals-container {
    padding: 1.5rem;
    display: flex;
    justify-content: space-between;
  }

  .download-order-raw {
    margin-top: -2.7rem;
  }
}
</style>
