<script setup>
import { computed, ref, watch, watchEffect } from 'vue';
import { useEditsCollection } from '@/queries/edits_collection/useEditsCollection';
import { useInfiniteDigitalAssets } from '@/composables/useInfiniteDigitalAssets';
import { useInfiniteEditsCollectionDigitalAssets } from '@/queries/edits_collection_digital_assets/useInfiniteEditsCollectionDigitalAssets';
import { useInfiniteGalleryWrapper } from '@/components/infinite_asset_gallery/useInfiniteGalleryWrapper';
import { usePriorityErrors } from '@/composables/usePriorityErrors';
import { useReservation } from '@/composables/useReservation';
import { useUpdateDigitalAsset } from '@/queries/digital_assets/useUpdateDigitalAsset';
import { useUpdateEditsCollectionDigitalAsset } from '@/queries/edits_collection_digital_assets/useUpdateEditsCollectionDigitalAsset';
import AssignOriginalDialog from '@/components/user/anytime/reservation/gallery/edits/AssignOriginalDialog.vue';
import EditsGalleryDigitalAssetCard from '@/components/user/anytime/reservation/crew_edits/EditsGalleryDigitalAssetCard.vue';
import GalleryFilter from '@/components/user/anytime/reservation/crew_edits/GalleryFilter.vue';
import InfiniteGallery from '@/components/infinite_asset_gallery/InfiniteGallery.vue';
import MoreContentEmpty from '@/components/user/anytime/gallery/MoreContentEmpty.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaToggle from '@/components/ui_library/SoonaToggle.vue';
import StaffActions from '@/components/user/anytime/reservation/crew_edits/StaffActions.vue';
import { useBulkSelection } from '@/composables/digital_assets/useBulkSelection';
import { useElementBounding } from '@vueuse/core';
import EditsMultiSelectActionBar from '@/components/user/anytime/reservation/gallery/edits/EditsMultiSelectActionBar.vue';

const props = defineProps({
  isChatOpen: {
    default: false,
    type: Boolean,
  },
  userHasScrolledPastBreakpoint: {
    default: false,
    type: Boolean,
  },
  reservationId: {
    type: [Number, String],
    required: true,
  },
  filter: {
    type: String,
    required: false,
  },
});

const reservationId = computed(() => props.reservationId);

const { reservation, error: reservationError } = useReservation(reservationId);
const accountId = computed(() => reservation.value?.account_id);

const { data: editsCollection } = useEditsCollection(accountId);
const editsCollectionId = computed(() => editsCollection.value?.id);

// filter module
const selectedFilter = ref('all');

watchEffect(() => {
  if (!props.filter) return;

  selectedFilter.value = props.filter;
});

const filterLookup = {
  all: {},
  delivered: {
    edit_status: 'accepted',
    visibility: 'all',
  },
  pending: {
    edit_status: 'pending',
  },
  rejected: {
    edit_status: 'rejected',
  },
  're-edit': {
    edit_status: 'customer_rejected',
  },
  unlinked: {
    missing_original: 1,
  },
  hidden: {
    edit_status: 'accepted',
    visibility: 'crew',
  },
};

// size module
const pageWrapper = ref();
const galleryWrapper = ref();
const itemsPerPage = ref(30);

const { left, width } = useElementBounding(galleryWrapper);

const alwaysShowTitles = ref(false);

const { gutter, offsetTop, rowHeight, rowWidth } = useInfiniteGalleryWrapper({
  wrapperEl: galleryWrapper,
  heightRem: 14,
  gapRem: 0.75,
});

const filters = computed(() => ({
  reservation_id: reservationId.value,
  origin: 'soona',
  ...filterLookup[selectedFilter.value],
}));

const {
  assetRows,
  fetchPage,
  isLoading,
  error: infiniteAssetsError,
  rawData,
} = useInfiniteDigitalAssets(editsCollectionId, {
  rowWidth,
  itemsPerPage,
  gutter,
  height: rowHeight,
  filters,
  query: useInfiniteEditsCollectionDigitalAssets,
});

const galleryPriorityErrors = usePriorityErrors(
  reservationError,
  infiniteAssetsError
);

const { mutate: updateDigitalAsset, isPending: isUpdatingDigitalAsset } =
  useUpdateDigitalAsset(accountId);

const {
  mutate: updateEditsCollectionDigitalAsset,
  isPending: isUpdatingEditsCollectionDigitalAsset,
} = useUpdateEditsCollectionDigitalAsset(accountId, editsCollectionId);

const isUpdating = computed(
  () =>
    isUpdatingDigitalAsset.value || isUpdatingEditsCollectionDigitalAsset.value
);

const accept = editsCollectionDigitalAsset => {
  updateEditsCollectionDigitalAsset({
    cdaId: editsCollectionDigitalAsset.id,
    body: {
      edit_status: 'accepted',
      digital_asset_attributes: { visibility: 'all' },
    },
  });
};

const pend = editsCollectionDigitalAsset => {
  updateEditsCollectionDigitalAsset({
    cdaId: editsCollectionDigitalAsset.id,
    body: { edit_status: 'pending' },
  });
};

const reject = editsCollectionDigitalAsset => {
  updateEditsCollectionDigitalAsset({
    cdaId: editsCollectionDigitalAsset.id,
    body: { edit_status: 'rejected' },
  });
};

const show = editsCollectionDigitalAsset => {
  updateDigitalAsset({
    assetId: editsCollectionDigitalAsset.digital_asset.id,
    body: { visibility: 'all' },
  });
};

const hide = editsCollectionDigitalAsset => {
  updateDigitalAsset({
    assetId: editsCollectionDigitalAsset.digital_asset.id,
    body: { visibility: 'crew' },
  });
};

// assign original
const showOriginalDialog = ref(false);
const assignedEdit = ref(null);

const toggleShowOriginalDialog = (val, ecda) => {
  showOriginalDialog.value = val;

  assignedEdit.value = showOriginalDialog.value ? ecda?.digital_asset : null;
};

const { onSelectionClick, resetSelection, isAssetSelected, selectedAssets } =
  useBulkSelection(rawData, ecda => ecda);

watch(selectedFilter, () => {
  resetSelection();
});
</script>

<template>
  <div ref="pageWrapper" class="gallery-view">
    <div class="gallery-view__top">
      <h3 class="gallery-view__label">edits</h3>
      <StaffActions
        :account-id="accountId"
        :reservation-id="reservationId"
        :show-deliver-all-button="
          !!(selectedFilter === 'pending' && assetRows.length)
        "
      />
    </div>
    <GalleryFilter
      v-model="selectedFilter"
      :edits-collection-id="editsCollectionId"
      :reservation-id="reservationId"
      :always-show-titles="alwaysShowTitles"
    >
      <template #gallery-filter-right>
        <SoonaToggle
          :model-value="alwaysShowTitles"
          label="show titles"
          type="switch"
          @update:model-value="() => (alwaysShowTitles = !alwaysShowTitles)"
        />
      </template>
    </GalleryFilter>
    <SoonaError
      v-if="galleryPriorityErrors"
      :priority-errors="galleryPriorityErrors"
    />
    <section v-else-if="isLoading || assetRows.length" ref="galleryWrapper">
      <InfiniteGallery
        v-slot="{ data }"
        :rows="assetRows"
        :start-page="1"
        :initial-scroll-position="0"
        :height="rowHeight"
        :gap="gutter"
        :offset-top="offsetTop"
      >
        <EditsGalleryDigitalAssetCard
          v-for="ecda in data.assets"
          :key="ecda.id"
          :always-show-titles="alwaysShowTitles"
          :filter="selectedFilter"
          :loading="isUpdating"
          :flex-grow="data.width / rowWidth > 0.6 ? 1 : 0"
          :edits-collection-digital-asset="ecda"
          :reservation-id="reservationId"
          :selected="isAssetSelected(ecda)"
          :select-disabled="
            ecda.edit_status !== 'accepted' ||
            ecda.digital_asset.visibility === 'crew'
          "
          @request-page="pageNumber => fetchPage(pageNumber)"
          @open-assign-original-dialog="toggleShowOriginalDialog(true, ecda)"
          @accept="accept(ecda)"
          @pend="pend(ecda)"
          @reject="reject(ecda)"
          @show="show(ecda)"
          @hide="hide(ecda)"
          @selection-click="onSelectionClick(ecda, $event)"
        />
      </InfiniteGallery>
    </section>
    <MoreContentEmpty v-else>
      <template #icon>
        <SoonaIcon name="image-square-duotone" />
      </template>
      <template #message>no results for {{ selectedFilter }}</template>
    </MoreContentEmpty>
    <AssignOriginalDialog
      v-if="showOriginalDialog"
      :account-id="accountId"
      :reservation-id="reservationId"
      :digital-asset-id="assignedEdit?.id"
      @close="() => toggleShowOriginalDialog(false, null)"
    />
    <EditsMultiSelectActionBar
      :account-id="accountId"
      :reservation-id="reservationId"
      :is-chat-open="false"
      :page-bounding-rect-left="left"
      :page-bounding-rect-width="width"
      :selected-assets="selectedAssets"
      @close="resetSelection"
    />
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.gallery-view {
  position: relative;

  .account-info {
    display: inline-block;
  }

  .crew-tools-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: 0.5rem;

    .edit-actions-row {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      gap: 0.5rem;
    }
  }

  &__top {
    margin-top: 2.5rem;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  &__label {
    @include variables_fonts.u-body--heavy;

    @media (min-width: variables.$screen-sm-min) {
      @include variables_fonts.u-subheader--heavy;
    }
  }
}
</style>
