import { computed, toValue } from 'vue';

export const useUnPurchasedBaseFilters = filter => {
  const baseFilters = computed(() => {
    switch (toValue(filter)) {
      case 'hidden':
        return {
          origin: 'soona',
          ownership: 'soona',
          visibility: 'crew',
        };
      case 'raws':
      case 'raws_no_preview':
        return {
          origin: 'soona',
          ownership: 'soona',
          independent_raws_only: true,
          visibility: 'crew',
        };
      case 'bag':
      case 'in_bag':
        return {
          origin: 'soona',
          ownership: 'soona',
          visibility: 'all',
          collection_type: 'BagCollection',
        };
      case 'expert_picks':
        return {
          origin: 'soona',
          ownership: 'soona',
          visibility: 'all',
          collection_type: 'StaffPicksCollection',
        };
      case 'favorites':
        return {
          origin: 'soona',
          ownership: 'soona',
          visibility: 'all',
          collection_type: 'FavoritesCollection',
        };
      default:
        return {
          origin: 'soona',
          ownership: 'soona',
          visibility: 'all',
        };
    }
  });
  return { baseFilters };
};
