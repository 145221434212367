<script setup>
import { computed, watchEffect } from 'vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import DigitalAssetCommonActions from './DigitalAssetCommonActions.vue';
import DigitalAssetCommonStatuses from './DigitalAssetCommonStatuses.vue';
import OverlayDownloadAsset from '@/components/account_gallery/OverlayDownloadAsset.vue';
import SoonaSkeleton from '@/components/ui_library/SoonaSkeleton.vue';
import DigitalAssetImage from '@/components/infinite_asset_gallery/DigitalAssetImage.vue';

const props = defineProps({
  alwaysShowTitle: {
    type: Boolean,
    required: false,
    default: false,
  },
  digitalAsset: {
    type: Object,
    default: () => ({}), // this has a default b/c a placeholder could be passed in causing a render issue
  },
  pagedAsset: {
    type: Object,
    required: true,
  },
  flexGrow: {
    type: Number,
    default: 0,
  },
  to: {
    type: [String, Object],
    default: null,
  },
  selected: {
    type: Boolean,
    default: false,
  },
  selectVisible: {
    type: Boolean,
    default: false,
  },
  selectDisabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['request-page', 'selection-click']);

const id = computed(() => `da-gallery-card-${props.pagedAsset.id}`);
const titleId = computed(() => `${id.value}-title`);

// pagedAsset could be the same as digitalAsset but is more likely to be
// reservationDigitalAsset, catalogItemDigitalAsset, editsCollectionDigitalAsset etc.
const pagedAsset = computed(() => props.pagedAsset);
const aspectRatio = computed(() => props.digitalAsset?.preview?.ratio ?? 1);

watchEffect(() => {
  if (pagedAsset.value.placeholder) {
    emit('request-page', pagedAsset.value.page);
  }
});

const mediaIcon = computed(() => {
  switch (props.digitalAsset?.media_type) {
    case 'photo':
      return 'image-square';
    case 'video':
      return 'play';
    case 'animation':
      return 'gif-text';
    default:
      return null;
  }
});
const mediaIconOverlay = computed(() => {
  switch (props.digitalAsset?.media_type) {
    case 'video':
      return 'play';
    case 'animation':
      return 'gif-text';
    default:
      return null;
  }
});

const soonaOwned = computed(() => props.digitalAsset?.ownership === 'soona');
const customerOwned = computed(
  () => props.digitalAsset?.ownership === 'customer'
);
const hasDownloadUrl = computed(
  () => props.digitalAsset?.file?.url || props.digitalAsset?.web?.url
);
</script>

<template>
  <figure
    class="asset"
    :class="{ 'asset--selected': selected }"
    :aria-labelledby="titleId"
  >
    <SoonaSkeleton v-if="pagedAsset.placeholder" class="asset__placeholder" />
    <template v-else>
      <router-link v-if="to" class="asset__link" :to="to">
        <DigitalAssetImage
          :src="digitalAsset.preview?.url"
          :alt="digitalAsset.title"
        />
      </router-link>
      <div v-else class="asset__link">
        <DigitalAssetImage
          :src="digitalAsset.preview?.url"
          :alt="digitalAsset.title"
        />
      </div>
      <div class="asset__overlay">
        <slot name="asset-overlay" />
        <div class="asset__overlay__actions">
          <label
            v-if="selectVisible"
            class="asset__overlay__selection-label"
            :class="{
              'asset__overlay__selection-label--disabled': selectDisabled,
            }"
          >
            <input
              :checked="selected"
              type="checkbox"
              :disabled="selectDisabled"
              @click="$emit('selection-click', $event)"
            />
            <span class="u-visually-hidden">
              asset
              {{ digitalAsset.title || digitalAsset.id }}
              selected
            </span>
          </label>

          <div
            class="asset__overlay__action-buttons"
            :class="{ 'asset__overlay__action-buttons--forced': soonaOwned }"
          >
            <slot name="action-buttons" />
            <OverlayDownloadAsset
              v-if="customerOwned && hasDownloadUrl"
              :file-url="digitalAsset.file?.url"
              :web-url="digitalAsset.web?.url"
            />
            <DigitalAssetCommonActions
              v-if="digitalAsset?.id"
              :digital-asset="digitalAsset"
            />
          </div>
        </div>

        <div class="asset__overlay__bottom">
          <div class="asset__overlay__bottom__statuses">
            <slot name="statuses" />
            <DigitalAssetCommonStatuses
              v-if="digitalAsset?.id"
              :digital-asset="digitalAsset"
            />
          </div>
          <div
            v-if="mediaIconOverlay"
            class="asset__media-type"
            :class="{ 'asset__media-type--forced': alwaysShowTitle }"
          >
            <SoonaIcon :name="mediaIconOverlay" />
            <span class="u-visually-hidden">{{ digitalAsset.media_type }}</span>
          </div>
          <p
            :id="titleId"
            class="asset__title"
            :class="{ 'asset__title--forced': alwaysShowTitle }"
            :title="digitalAsset.title"
          >
            <SoonaIcon
              v-if="mediaIcon"
              :name="mediaIcon"
              size="medium"
              class="asset__title__media-type-icon"
            />
            <span class="asset__title__text u-label--regular">
              <span class="u-visually-hidden"
                >{{ digitalAsset.media_type }} -
              </span>
              {{ digitalAsset.title }}
            </span>
          </p>
        </div>
      </div>
    </template>
  </figure>
</template>

<style lang="scss" scoped>
@use '@/variables';

.asset {
  position: relative;
  height: 100%;
  /* prevent overflow from aspect-ratio if in narrow view */
  max-width: 100%;
  aspect-ratio: v-bind(aspectRatio);
  flex-grow: v-bind(flexGrow);
  border-radius: 0.3125rem;

  &:hover,
  &:focus-within {
    box-shadow: 0 0 0 0.25rem variables.$gray-30;
  }

  &--selected,
  &:hover,
  &:focus-within {
    .asset__title {
      display: flex;
    }

    .asset__media-type {
      display: none;
    }

    .asset__overlay__action-buttons {
      display: flex;
    }
  }

  &__placeholder {
    height: 100%;
    border-radius: 0.3125rem;
  }

  &__link {
    display: block;
    height: 100%;
    border-radius: 0.3125rem;
    overflow: hidden;
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.25rem;

    &__actions {
      display: flex;
      /* not wrapping here to force the action buttons to wrap instead */
      row-gap: 0.25rem;
      align-items: flex-start;
      padding: 0.5rem;
    }

    &__selection-label {
      display: block;
      pointer-events: auto;
      padding: 0.9375rem;
      margin: -0.5rem;
      cursor: pointer;
      border-radius: 10rem;

      &:not(.asset__overlay__selection-label--disabled):hover {
        background-color: variables.$black-translucent-12;
      }

      &--disabled {
        cursor: not-allowed;
      }

      input[type='checkbox'] {
        height: 1.125rem;
        width: 1.125rem;
        display: block;
        accent-color: variables.$periwink-blue-60;
        cursor: pointer;

        &:disabled {
          cursor: not-allowed;
        }
      }
    }

    &__action-buttons {
      margin-left: auto;
      display: none;
      flex-flow: row wrap;
      column-gap: 0.5rem;
      row-gap: 0.25rem;
      align-items: center;
      /* wrap items down to the right */
      justify-content: flex-end;

      &--forced {
        display: flex;
      }

      @media screen and (hover: none) {
        display: flex;
      }
    }

    &__bottom {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      flex: 1 1 auto;

      &__statuses {
        display: flex;
        flex-direction: column;
        align-self: flex-end;
        justify-content: flex-end;
        gap: 0.25rem;
        padding: 0.5rem;
      }
    }
  }

  &__title {
    color: variables.$white-default;
    background: variables.$black-translucent-60;
    width: 100%;
    transition: 0.25s opacity ease-in-out;
    display: none;
    align-items: center;
    gap: 0.25rem;
    padding: 0.25rem;
    border-radius: 0 0 0.3125rem 0.3125rem;
    pointer-events: auto;

    &--forced {
      display: flex;
    }

    &__text {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &__media-type-icon {
      flex: 0 0 1.25rem;
      height: 1.25rem;
      width: 1.25rem;
    }
  }

  &__media-type {
    position: absolute;
    left: 0.5rem;
    bottom: 0.5rem;
    padding: 0.5rem;
    border-radius: 10rem;
    color: variables.$white-default;
    background-color: variables.$black-translucent-60;
    display: flex;
    align-items: center;
    justify-content: center;

    &--forced {
      display: none;
    }

    svg {
      display: block;
      height: 1.5rem;
      width: 1.5rem;
    }
  }
}
</style>
