<script setup>
import { computed, ref } from 'vue';
import { useTitle } from '@vueuse/core';
import SoonaBackButton from 'src/components/ui_library/SoonaBackButton.vue';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaForm from 'src/components/ui_library/SoonaForm.vue';
import SoonaTextfield from 'src/components/ui_library/SoonaTextfield.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { useRouter } from 'vue-router';

useTitle('shopify sign in | soona');
const router = useRouter();

const windowHasHistory = computed(() => {
  return window.history.length > 1;
});

const storeDomain = ref('');

function onBackClick() {
  router.go(-1);
}

function signInClicked() {}
</script>

<template>
  <div class="shopify-sign-in">
    <div v-if="windowHasHistory" class="shopify-sign-in__back-button">
      <SoonaBackButton @click="onBackClick" />
    </div>
    <div class="shopify-sign-in__body">
      <SoonaIcon name="shopify-logo" size="3x-large" />

      <h2>sign in with your Shopify store</h2>
      <SoonaForm
        v-slot="{ hasErrors }"
        class="shopify-sign-in__body__form"
        @submit="signInClicked"
      >
        <SoonaTextfield
          v-model:model-value.trim="storeDomain"
          class="shopify-sign-in__body__form__textfield"
          label="store domain"
          type="text"
          placeholder="store-name.myshopify.com"
          :rules="['required']"
          required
        />
        <div v-if="errors" class="soona-errors" data-cypress="errors">
          <p
            v-for="(error, index) in errorMessages"
            :key="index"
            class="u-small--regular"
          >
            {{ error }}
          </p>
        </div>
        <p class="shopify-sign-in__body__form__help-text">
          find your shop name in the URL you use to access your store:
          <em>your-shop-name</em>.myshopify.com or admin.shopify.com/store/<em
            >your-shop-name</em
          >
        </p>
        <SoonaButton type="submit" :disabled="hasErrors" copy="sign in" />
      </SoonaForm>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables_fonts';

.shopify-sign-in {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  &__back-button {
    width: 75%;
  }
  &__body {
    width: 75%;
    display: flex;
    flex-direction: column;
    gap: 1.75rem;
    align-items: center;

    h2 {
      @include variables_fonts.u-headline--heavy;
    }

    h3 {
      width: 75%;
      text-align: center;
    }

    &__form {
      display: flex;
      flex-direction: column;
      width: 75%;
      align-items: center;

      &__textfield {
        width: 30rem;
        padding-bottom: 0.75rem;
      }

      &__help-text {
        @include variables_fonts.u-label--small;
        width: 30rem;
        text-align: center;
        padding-bottom: 1.5rem;
      }
    }
  }
}
</style>
