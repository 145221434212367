<script setup>
import { computed, ref } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import ShopifyReviewDialog from '@/components/user/anytime/thanks/ShopifyReviewDialog.vue';
import PaymentInterstitial from '@/components/booking/v3/p2/PaymentInterstitial.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaBottomBanner from '@/components/ui_library/SoonaBottomBanner.vue';
import { useFlag } from '@/composables/useFlag';
import { useAccount } from '@/queries/account/useAccount';
import { useIntegrations } from '@/composables/useIntegrations';
import { useMe } from '@/composables/user/useMe';
import { useRoute, useRouter } from 'vue-router';
import ShopMore from '@/components/account_gallery/ShopMore.vue';

const route = useRoute();
const router = useRouter();

const additionalCharge = computed(
  () => route.query.additional_charge === 'true'
);
const reservationId = computed(() =>
  route.query.reservation_id ? parseInt(route.query.reservation_id) : null
);

const prevRoute = computed(() => {
  // fixme: update logic to not use this API
  // eslint-disable-next-line no-restricted-properties
  const lastPath = router.options.history.state.back;
  return lastPath ? lastPath : '/';
});

// shopify review banner
const openShopifyReviewModal = ref(false);
const userEnjoysShopifyApp = ref(false);
const soonaShopifyReviewFlagIsOn = useFlag('soona_shopify_review');

const { currentAccountId } = useMe();

const { hasShopifyIntegration } = useIntegrations(currentAccountId);

const { data: account, isSuccess: accountIsLoaded } =
  useAccount(currentAccountId);

const shopifyReviewResponse = computed(
  () => account.value?.profile_data?.shopify_review?.response
);

const showShopifyReviewBanner = computed(() => {
  if (
    !accountIsLoaded.value &&
    !hasShopifyIntegration.value &&
    !soonaShopifyReviewFlagIsOn.value
  ) {
    return false;
  }

  return !shopifyReviewResponse.value;
});

const shopifyReview = opinion => {
  userEnjoysShopifyApp.value = opinion;
  openShopifyReviewModal.value = true;
};

const additionalChargeThankYou = computed(() => {
  return prevRoute.value?.match(/checkout\/[0-9]+/) || additionalCharge.value;
});

const buttonCopy = computed(() => {
  if (reservationId.value !== null) {
    return 'to reservation';
  } else {
    return 'book new';
  }
});

const buttonLink = computed(() => {
  if (reservationId.value !== null) {
    return `/user/reservation/${reservationId.value}`;
  } else {
    return '/book/new';
  }
});

const bodyFirstLine = computed(() => {
  if (additionalChargeThankYou.value) {
    return 'we have received your payment.';
  } else {
    return "we've received your order. great choices!";
  }
});

const bodySecondLine = computed(() => {
  if (additionalChargeThankYou.value) {
    return "we can't wait to keep creating with you.";
  } else {
    return 'your soona crew will work their editing magic.';
  }
});
</script>
<template>
  <div class="view-thanks">
    <template v-if="hasShopifyIntegration">
      <ShopifyReviewDialog
        v-if="openShopifyReviewModal"
        :enjoys="userEnjoysShopifyApp"
        :account-id="currentAccountId"
        @close="() => (openShopifyReviewModal = false)"
      />
      <PaymentInterstitial>
        <template #heading>thank you!</template>
        <template #bold-subheading>we've received your order.</template>
        <template #subheading>
          great choices! your soona crew will work their editing magic
        </template>
        <template #payment-description>
          <p>
            <router-link to="/booking/new">book again</router-link>
          </p>
        </template>
        <template #footer>
          <SoonaBottomBanner v-if="showShopifyReviewBanner">
            <template #icon>
              <SoonaIcon class="shopify-review-icon" name="heart-duotone" />
            </template>
            <template #heading
              >are you enjoying our soona integration?
            </template>
            <template #bottom>
              <SoonaButton
                copy="yes! I am!"
                variation="secondary-black"
                @click="shopifyReview(true)"
              />
              <SoonaButton
                copy="not really"
                variation="secondary-black"
                @click="shopifyReview(false)"
              />
            </template>
          </SoonaBottomBanner>
        </template>
      </PaymentInterstitial>
    </template>
    <template v-else>
      <div class="thank-you">
        <img
          class="view-thanks__heart-icon"
          src="@images/liked-light.svg"
          alt=""
        />
        <p class="thanks-header">thank you!</p>
        <p class="view-thanks__subtitle">
          {{ bodyFirstLine }}
        </p>
        <p class="view-thanks__subtitle">
          {{ bodySecondLine }}
        </p>
        <SoonaButton
          element="a"
          class="thanks-button"
          :href="buttonLink"
          :copy="buttonCopy"
        />
      </div>
      <ShopMore
        class="view-thanks__shop-more-digital-assets"
        :account-id="currentAccountId"
      >
        <template #title>did you miss any?</template>
      </ShopMore>
    </template>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.view-thanks {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  &__heart-icon {
    margin-left: -1.4em;
    width: 1.25rem;

    @media (min-width: variables.$screen-sm-min) {
      margin-left: -2em;
      width: 1.875rem;
    }
  }

  &__subtitle {
    @include variables_fonts.u-body--heavy;

    @media (min-width: variables.$screen-sm-min) {
      @include variables_fonts.u-title--heavy;
    }
  }

  &__shop-more-digital-assets {
    margin-top: 4.5rem;
  }

  .thank-you {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-width: 40.625rem;
    margin: auto;
    padding-left: 0.75rem;
    padding-right: 0.75rem;

    img {
      margin-bottom: -1.5rem;
    }

    p {
      font-weight: 800;
      text-transform: lowercase;
    }
  }

  .thanks-header {
    @include variables_fonts.u-display--heavy;

    color: variables.$periwink-blue-70;
  }

  .thanks-button {
    align-self: center;
    margin-top: 1rem;
  }

  .shopify-review-icon {
    color: variables.$friendly-red-50;
  }
}
</style>
