<script setup>
import { computed } from 'vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaFlag from '@/components/ui_library/SoonaFlag.vue';
import {
  BubbletapePink80,
  BubbletapePink20,
  PeriwinkBlue80,
  PeriwinkBlue20,
} from '@/variables.module.scss';
import PlaceholderImage from 'images/product-placeholder-periwink.jpg';
import SoonaSkeleton from '@/components/ui_library/SoonaSkeleton.vue';
import ListingActionRule from './ListingActionRule.vue';
import SoonaAlert from '@/components/ui_library/SoonaAlert.vue';
import { component as Viewer } from 'v-viewer';
import SoonaLink from '@/components/ui_library/SoonaLink.vue';
import { useRoute } from 'vue-router';

const props = defineProps({
  isLoading: {
    type: Boolean,
    default: false,
  },
  isOptimizing: {
    // loading state when an automated optimization is in progress
    type: Boolean,
    default: false,
  },
  isProcessing: {
    // loading state when uploading a new asset
    type: Boolean,
    default: false,
  },
  isPublishing: {
    // loading state when publishing to listing
    type: Boolean,
    default: false,
  },
  listingAction: {
    type: Object,
    required: true,
  },
});

defineEmits(['openComparisonViewer']);

const listingAction = computed(() => props.listingAction);

const integrationLogo = computed(() => {
  switch (listingAction.value.integration_type) {
    case 'AmazonIntegration':
      return 'amazon-logo';
    case 'ShopifyIntegration':
      return 'shopify-logo';
    default:
      return null;
  }
});

const originalAssetSource = computed(() => {
  return (
    listingAction.value.original_asset?.preview_url ??
    listingAction.value.main_asset_preview_url
  );
});

const replacementAssetSource = computed(() => {
  return (
    listingAction.value.replacement_asset_preview_url ??
    listingAction.value.replacement_asset?.preview_url
  );
});

const altText = computed(() => {
  return (
    listingAction.value.replacement_asset?.title ??
    listingAction.value.original_asset?.title ??
    ''
  );
});

// loading state variations module
const isLoading = computed(
  () =>
    props.isLoading ||
    props.isOptimizing ||
    props.isProcessing ||
    props.isPublishing
);

const flagText = computed(() => {
  if (props.isOptimizing) return 'optimizing';
  if (props.isPublishing) return 'publishing';
  return 'processing';
});

const flagTextColor = computed(() => {
  if (props.isPublishing) return PeriwinkBlue80;
  return BubbletapePink80;
});

const flagBackgroundColor = computed(() => {
  if (props.isPublishing) return PeriwinkBlue20;
  return BubbletapePink20;
});

const loadingIconName = computed(() => {
  if (props.isPublishing) return 'cloud-up-arrow';
  return 'wand-magic-sparkles';
});

// v-viewer module
let $viewer = null;

const inited = viewer => {
  $viewer = viewer;
};

const openVViewer = () => {
  $viewer.show();
};

defineExpose({
  openVViewer,
});

const viewWithViewer = computed(() => {
  // if there is a source asset and a replacement asset, display comparison viewer
  if (listingAction.value.source_asset && replacementAssetSource.value) {
    return false;
  } else {
    return (
      // if there is an original asset && no replacement asset, viewer will show the original asset
      (!!originalAssetSource.value && !replacementAssetSource.value) ||
      // if there is no original asset && there is a replacement asset, viewer will show the replacement asset (upload scenario)
      (!listingAction.value.original_asset && !!replacementAssetSource.value)
    );
  }
});

const vViewerImageSource = computed(() => {
  if (!listingAction.value.original_asset && replacementAssetSource.value) {
    return replacementAssetSource.value;
  }
  return originalAssetSource.value;
});

const route = useRoute();
const isListingDetail = computed(() => route.name === 'listing-optimize');
const displayLinkToCatalogItem = computed(
  () => listingAction.value.catalog_item_id && !isListingDetail.value
);
</script>

<template>
  <div v-if="isLoading" class="listing-action-template__loading">
    <SoonaSkeleton class="listing-action-template__loading--skeleton" />
    <div class="listing-action-template__loading--flag">
      <SoonaFlag
        :title="flagText"
        type="pill"
        :text-color="flagTextColor"
        :background-color="flagBackgroundColor"
      >
        <template #icon-left>
          <SoonaIcon
            :name="loadingIconName"
            size="small"
            class="listing-action-template__loading--flag--icon"
          />
        </template>
      </SoonaFlag>
    </div>
  </div>
  <div v-else class="listing-action-template">
    <div class="listing-action-template__image">
      <Viewer
        v-if="viewWithViewer"
        class="listing-action-template__image--viewer"
        :options="{
          toolbar: false,
          navbar: false,
          title: false,
          backdrop: true,
          minZoomRatio: 0.01,
          maxZoomRatio: 2,
        }"
        @inited="inited"
      >
        <img
          :id="`v-viewer-image__${listingAction.id}`"
          :src="vViewerImageSource"
          :alt="altText"
          class="listing-action-template__image--clickable"
        />
      </Viewer>
      <img
        v-else-if="replacementAssetSource"
        :src="replacementAssetSource"
        :alt="altText"
        class="listing-action-template__image--clickable"
        @click="$emit('openComparisonViewer')"
      />
      <img v-else :src="PlaceholderImage" :alt="altText" />
    </div>
    <div class="listing-action-template__body">
      <slot name="error"></slot>
      <SoonaAlert
        v-if="listingAction.status === 'failed_to_publish'"
        class="listing-action-template__alert"
        no-margin
      >
        publish failed.
      </SoonaAlert>
      <SoonaAlert
        v-if="
          listingAction.requires_manual_source_selection &&
          listingAction.status === 'active'
        "
        no-margin
      >
        please upload a new image with a neutral background and no text or
        overlays.
      </SoonaAlert>
      <template v-else-if="$slots['alert']">
        <SoonaAlert class="listing-action-template__alert" no-margin>
          <slot name="alert" />
        </SoonaAlert>
      </template>
      <slot name="listing-rule">
        <!-- only displays if nothing is in this slot -->
        <ListingActionRule
          v-if="listingAction.status === 'active'"
          :listing-action="listingAction"
        />
      </slot>

      <div
        class="listing-action-template__body--section listing-action-template__body--name"
      >
        <div>
          <SoonaIcon :name="integrationLogo" />
        </div>
        <SoonaLink
          v-if="displayLinkToCatalogItem"
          variation="black"
          :to="{
            name: 'product-detail',
            params: {
              productId: listingAction.catalog_item_id,
            },
          }"
        >
          {{ listingAction.catalog_item_name }}
        </SoonaLink>
        <span v-else>{{ listingAction.catalog_item_name }}</span>
      </div>
      <slot name="additional-content"></slot>
    </div>

    <div class="listing-action-template__actions">
      <slot name="actions"></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.listing-action-template {
  padding-top: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1.5rem;
  border-bottom: 0.0625rem solid variables.$gray-30;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;

  &__loading {
    position: relative;

    &--skeleton {
      height: 10rem;
      width: 100%;
      border-radius: 0.3125rem;
      border: none;
    }

    &--flag {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__image {
    flex: 0 0 auto;
    border-radius: 0.25rem;
    overflow: hidden;
    width: 6.25rem;

    &--viewer {
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &--clickable {
      cursor: pointer;
    }
  }

  &__body {
    order: 2;
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    &--section {
      display: flex;
      gap: 0.5rem;
    }
  }

  &__alert {
    margin-bottom: 0.5rem;
  }

  &__actions {
    order: 1;
    flex: 0 0 auto;
  }

  @media (min-width: variables.$screen-sm-min) {
    flex-wrap: nowrap;
    &__image {
      width: 10.25rem;
    }

    &__body {
      order: 0;
      flex: 1 1 auto;
    }

    &__actions {
      order: 0;
    }
  }
}
</style>
