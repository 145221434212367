<template>
  <div>
    <div class="crew-tools-view drop-shadow-container mb-l">
      <div class="crew-tools-container">
        <h3 class="crew-tools-container__customer-name">
          actions for {{ account.name }}
        </h3>
        <div class="crew-tools-container__actions">
          <SoonaButton
            data-cypress="crew-tools-toggle-vip"
            variation="secondary-gray"
            size="medium"
            :copy="account.vip ? 'remove VIP flag' : 'flag as VIP'"
            @on-click="toggleVipStatus"
          />
          <span v-if="capabilities.modify_subscriptions.hasCapability">
            <SoonaButton
              v-if="manageEnterpriseBusinessTierFlag && !isDigitalSubscriber"
              class="business-tier"
              data-cypress="crew-tools-add-business-tier"
              :disabled="
                businessSubscriptionItemAdding ||
                businessSubscriptionItemRemoving
              "
              variation="secondary-gray"
              size="medium"
              copy="flag as business tier"
              @on-click="openSubscriptionConfirmationAddModal"
            />
            <SoonaButton
              v-if="
                manageEnterpriseBusinessTierFlag &&
                isDigitalSubscriber &&
                subscriptionTier.slug === businessTier?.slug
              "
              class="business-tier"
              data-cypress="crew-tools-remove-business-tier"
              :disabled="
                businessSubscriptionItemAdding ||
                businessSubscriptionItemRemoving
              "
              variation="secondary-gray"
              size="medium"
              copy="remove business tier"
              @on-click="openSubscriptionConfirmationRemoveModal"
            />
            <SoonaButton
              v-if="
                hasSoonaStorage &&
                !hasShopifyIntegration &&
                !soonaStoragePendingCancellation
              "
              data-cypress="crew-tools-remove-fast-pass"
              :disabled="loading"
              variation="secondary-gray"
              size="medium"
              copy="remove fast pass"
              @on-click="openConfirmationRemoveModal"
            />
            <SoonaButton
              v-if="canEnrollInFastPass && !hasSoonaStorage"
              data-cypress="crew-tools-add-fast-pass"
              :disabled="subscriptionCreating || subscriptionUpdating"
              variation="secondary-gray"
              size="medium"
              copy="flag as fast pass"
              @on-click="openConfirmationAddModal"
            />
            <SoonaButton
              v-if="soonaStoragePendingCancellation"
              :disabled="true"
              :title="`ending on ${soonaStorageCurrentPeriodEnd}`"
              copy="pending cancellation"
              variation="secondary-gray"
              size="medium"
            />
          </span>
          <SoonaButton
            v-if="account.external_user_id"
            element="a"
            :href="`${stripe_dashboard}/customers/${account.external_user_id}`"
            target="_blank"
            copy="view on stripe"
            variation="secondary-gray"
            size="medium"
          />
          <SoonaButton
            v-if="$route.params.accountId"
            element="router-link"
            :to="{ path: `/crew/bag/${$route.params.accountId}` }"
            data-cypress="crew-tools-view-bag"
            copy="view bag"
            variation="secondary-gray"
            size="medium"
          />
          <!-- todo: what do we do here?? -->
          <SoonaButton
            v-else-if="bagCollection"
            element="router-link"
            :to="{ path: `/album/${bagCollection.id}` }"
            data-cypress="crew-tools-view-bag"
            copy="view bag"
            variation="secondary-gray"
            size="medium"
          />
          <SoonaDropdownMenu
            v-if="capabilities.manage_crew_booking_builder.hasCapability"
            copy="create shoot"
            variation="secondary-gray"
            data-cypress="crew-tools-create-shoot-dropdown"
          >
            <template #default="{ clickCapture, keydown, mouseover }">
              <SoonaDropdownMenuItem>
                <a
                  role="menuitem"
                  data-cypress="crew-tools-customer-booking"
                  :href="`/#/crew/booking/new?account_id=${$route.params.accountId}`"
                  @click.capture="clickCapture"
                  @keydown="keydown"
                  @mouseover="mouseover"
                >
                  customer booking
                </a>
              </SoonaDropdownMenuItem>
              <SoonaDropdownMenuItem>
                <a
                  role="menuitem"
                  data-cypress="crew-tools-internal-booking"
                  :href="`/#/crew/booking/new?account_id=${$route.params.accountId}&type=internal`"
                  @click.capture="clickCapture"
                  @keydown="keydown"
                  @mouseover="mouseover"
                >
                  internal booking
                </a>
              </SoonaDropdownMenuItem>
            </template>
          </SoonaDropdownMenu>
        </div>
      </div>
      <div>
        <h3 class="level-left u-body--heavy is-size-7-mobile">
          account flags:
        </h3>
        <div class="account-flag-container">
          <SoonaFlag
            v-for="flag in accountFlags"
            :key="flag.id"
            :title="flag.title"
            :text-color="flag.text_color"
            :background-color="flag.background_color"
          />
        </div>
        <SoonaButton
          :on-click="openFlagModal"
          :copy="accountHasFlags ? 'edit flags' : 'add flags'"
          size="medium"
          variation="tertiary"
        >
        </SoonaButton>
        <!-- Modal for transfering booking to a new account -->
        <SoonaDialog v-if="showTransferModal" @close="closeTransferModal">
          <template #heading>transfer all bookings to a new account</template>
          <template #default>
            <SoonaTextfield
              v-model="newAccountId"
              type="number"
              placeholder="enter an id"
              label="account ID"
              :required="true"
              :disabled="transferBookingIsLoading"
            />
            <div v-if="errorMessages" class="has-text-danger">
              <p>
                {{ errorMessages.code }}
              </p>
            </div>
            <fieldset>
              <legend>would you like to transfer their quote orders?</legend>
              <SoonaToggle
                v-model="transferQuotes"
                type="radio"
                label="yes"
                name="quote"
                native-value="true"
              />
              <SoonaToggle
                v-model="transferQuotes"
                type="radio"
                label="no"
                name="quote"
                native-value="false"
              />
            </fieldset>
          </template>
          <template #footer="{ close }">
            <SoonaButton
              variation="tertiary"
              data-cypress="button-dialog-cancel"
              @click="close"
            >
              cancel
            </SoonaButton>
            <SoonaButton
              :disabled="!newAccountId || transferBookingIsLoading"
              data-cypress="button-dialog-confirm"
              @on-click="transferBookingToNewUser"
            >
              confirm
            </SoonaButton>
          </template>
        </SoonaDialog>

        <!-- add tier 3 -->
        <SoonaDialog
          v-if="showSubscriptionConfirmationAddModal"
          @close="closeSubscriptionConfirmationAddModal"
        >
          <template #heading>add business tier</template>
          <span>
            are you sure you want to create a subscription and send welcome
            emails?
          </span>
          <SoonaError v-if="priorityError">{{ priorityError }}</SoonaError>
          <template #footer="{ close }">
            <SoonaButton
              variation="tertiary"
              data-cypress="button-dialog-cancel"
              @on-click="close"
            >
              cancel
            </SoonaButton>
            <SoonaButton
              :loading="
                businessSubscriptionItemAdding ||
                businessSubscriptionItemRemoving
              "
              data-cypress="button-dialog-confirm"
              @on-click="() => addBusinessSubscription()"
            >
              add business tier
            </SoonaButton>
          </template>
        </SoonaDialog>
        <!-- remove tier 3 -->
        <SoonaDialog
          v-if="showSubscriptionConfirmationRemoveModal"
          @close="closeSubscriptionConfirmationRemoveModal"
        >
          <template #heading>remove business tier</template>
          <span>
            are you sure you want to cancel business tier and send cancellation
            emails?
          </span>
          <SoonaError v-if="priorityError">{{ priorityError }}</SoonaError>
          <template #footer="{ close }">
            <SoonaButton
              variation="tertiary"
              data-cypress="button-dialog-cancel"
              @on-click="close"
            >
              cancel
            </SoonaButton>
            <SoonaButton
              :loading="
                businessSubscriptionItemAdding ||
                businessSubscriptionItemRemoving
              "
              data-cypress="button-dialog-confirm"
              @on-click="() => removeBusinessSubscription()"
            >
              remove business tier
            </SoonaButton>
          </template>
        </SoonaDialog>

        <!-- remove fast pass -->
        <SoonaDialog
          v-if="hasSoonaStorage && showConfirmationRemoveModal"
          @close="closeConfirmationRemoveModal"
        >
          <template #heading>remove fast pass</template>
          <p>set fast pass to expire at the end of the current billing cycle</p>
          <div v-if="errorMessages" class="has-text-danger">
            <p>{{ errorMessages.message }}</p>
          </div>
          <template #footer="{ close }">
            <SoonaButton
              variation="tertiary"
              data-cypress="button-dialog-cancel"
              @on-click="close"
            >
              cancel
            </SoonaButton>
            <SoonaButton
              :loading="
                subscriptionCreating || subscriptionUpdating || fastPastRemoving
              "
              data-cypress="button-dialog-confirm"
              @on-click="() => cancelFastPassItem()"
            >
              remove fast pass
            </SoonaButton>
          </template>
        </SoonaDialog>
        <!-- add fast pass -->
        <SoonaDialog
          v-if="!hasSoonaStorage && showConfirmationAddModal"
          @close="closeConfirmationAddModal"
        >
          <template #heading>add fast pass</template>
          <span v-if="account.external_user_id">
            create a subscription and charge the account
          </span>
          <span v-else>create a subscription and invoice the account</span>
          <SoonaError v-if="priorityError">{{ priorityError }}</SoonaError>
          <template #footer="{ close }">
            <SoonaButton
              variation="tertiary"
              data-cypress="button-dialog-cancel"
              @on-click="close"
            >
              cancel
            </SoonaButton>
            <SoonaButton
              :loading="
                subscriptionCreating || subscriptionUpdating || fastPastRemoving
              "
              data-cypress="button-dialog-confirm"
              @on-click="() => createOrUpdateSubscription(fastPassTier)"
            >
              add fast pass
            </SoonaButton>
          </template>
        </SoonaDialog>
      </div>
      <div class="mt-l">
        <h3 class="mt-m level-left u-body--heavy is-size-7-mobile">
          crew notes:
        </h3>
        <add-note
          :notes="accountNotes"
          :is-pending="isPendingNote"
          :error="createNoteError"
          @submit-note="createNewUserNote"
          @delete-note="deleteUserNote"
        >
          <template #paginator>
            <PaginatorFull
              v-model:page="notesCurrentPage"
              v-model:records-per-page="notesItemsPerPage"
              :total-pages="accountNotesPagination.totalPages"
              :total-count="accountNotesPagination.totalCount"
              :page-count="accountNotes.length"
            />
          </template>
        </add-note>
      </div>
    </div>
    <AvailableAccountCredits
      :account-id="accountId"
      :current-page="currentPage"
    />
    <SoonaDialog
      v-if="showFlagModal"
      :key="reRenderFlagSelector"
      class="flag-modal"
      @close="closeFlagModal"
    >
      <template #heading>account flags</template>
      <p class="modal-body-text">select flags below to mark this account</p>
      <ul class="collab-select">
        <li v-for="flag in flags" :key="flag.id" class="u-body--regular">
          <checkbox
            class="flag-checkbox"
            :handle-click="flagToggle"
            :item="flag"
            :status="isFlagOnAccount(flag)"
          />
          {{ flag.title }}
        </li>
      </ul>
      <template #footer="{ close }">
        <SoonaButton
          variation="tertiary"
          data-cypress="button-dialog-cancel"
          @on-click="close"
        >
          cancel
        </SoonaButton>
        <SoonaButton
          data-cypress="button-dialog-confirm"
          @on-click="updateFlags"
        >
          update flags
        </SoonaButton>
      </template>
    </SoonaDialog>
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue';
import { mapGetters, mapActions, mapState } from 'vuex';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import AddNote from 'src/components/user/anytime/note/AddNote.vue';
import Checkbox from 'src/components/Checkbox.vue';
import AvailableAccountCredits from 'src/components/user/anytime/credits/AvailableAccountCredits.vue';
import PaginatorFull from 'src/components/directory/PaginatorFull.vue';
import { useNotes } from 'src/queries/notes/useNotes';
import { useCreateNote } from 'src/queries/notes/useCreateNote';
import { useDeleteNote } from 'src/queries/notes/useDeleteNote';
import { useCapabilities } from 'src/composables/useCapabilities';
import SoonaDropdownMenu from 'src/components/ui_library/SoonaDropdownMenu.vue';
import SoonaDropdownMenuItem from 'src/components/ui_library/SoonaDropdownMenuItem.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaFlag from 'src/components/ui_library/SoonaFlag.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaToggle from '@/components/ui_library/SoonaToggle.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';
import { useAccount } from '@/composables/useAccount';
import { useCreateSubscription } from '@/queries/subscriptions/useCreateSubscription';
import { useUpdateSubscription } from '@/queries/subscriptions/useUpdateSubscription';
import { useAddBusinessSubscriptionItem } from '@/queries/subscriptions/useAddBusinessSubscriptionItem';
import { useRemoveBusinessSubscriptionItem } from '@/queries/subscriptions/useRemoveBusinessSubscriptionItem';
import { usePriorityError } from '@/composables/usePriorityError';
import { useTierBySlug } from '@/queries/tiers/useTierBySlug';
import { useIntegrations } from '@/composables/useIntegrations';
import { useFlag } from '@/composables/useFlag';
import { keepPreviousData } from '@tanstack/vue-query';
import { useSubscription } from '@/composables/subscription/useSubscription';
import { useCancelSubscriptionItem } from '@/queries/subscriptions/useCancelSubscriptionItem';

export default {
  name: 'CrewTools',
  components: {
    SoonaTextfield,
    SoonaToggle,
    SoonaDialog,
    SoonaDropdownMenuItem,
    SoonaDropdownMenu,
    SoonaError,
    AddNote,
    AvailableAccountCredits,
    Checkbox,
    PaginatorFull,
    SoonaButton,
    SoonaFlag,
  },
  props: {
    accountId: {
      type: [String, Number],
      required: true,
    },
  },
  setup(props) {
    const accountId = computed(() => Number(props.accountId));

    const manageEnterpriseBusinessTierFlag = useFlag(
      'apollo_enterprise_business_tier'
    );

    const capabilitiesRef = ref([
      { capability: 'modify_subscriptions' },
      { capability: 'transfer_reservations' },
      { capability: 'manage_crew_booking_builder' },
    ]);

    const capabilities = useCapabilities(capabilitiesRef);

    const {
      account,
      hasSoonaStorage,
      soonaStorageCurrentPeriodEnd,
      soonaStoragePendingCancellation,
      isDigitalSubscriber,
    } = useAccount(accountId);

    // create and update fastpass
    const { hasShopifyIntegration } = useIntegrations(accountId);

    // trialing ff check
    const trialingSubxFastPassFlag = useFlag('toaster_trialing_subx_fast_pass');
    const isTrialing = computed(() => account.value?.is_trialing);

    const pendingCancelAddOnSubscriptionItem = computed(() => {
      if (!account.value?.subscription) {
        return null;
      }
      // we only have 1 add on, but we'll need to refactor this later if we have more
      return account.value?.subscription.subscription_items.find(
        x =>
          x.subscription_item_type === 'add_on' &&
          x.status === 'pending_cancelation'
      );
    });

    const canEnrollInFastPass = computed(() => {
      return (
        account.value?.external_user_id &&
        !pendingCancelAddOnSubscriptionItem.value &&
        !hasShopifyIntegration.value &&
        (!isTrialing.value || trialingSubxFastPassFlag.value)
      );
    });

    const {
      mutate: createSubscription,
      isPending: subscriptionCreating,
      error: subscriptionCreatingError,
    } = useCreateSubscription(accountId);

    const {
      mutate: updateSubscription,
      isPending: subscriptionUpdating,
      error: subscriptionUpdatingError,
    } = useUpdateSubscription(accountId);

    const {
      mutate: addBusinessSubscriptionItem,
      isPending: businessSubscriptionItemAdding,
      error: businessSubscriptionItemAddingError,
    } = useAddBusinessSubscriptionItem(accountId);

    const {
      mutate: removeBusinessSubscriptionItem,
      isPending: businessSubscriptionItemRemoving,
      error: businessSubscriptionItemRemovingError,
    } = useRemoveBusinessSubscriptionItem(accountId);

    const { data: businessTier } = useTierBySlug('tier-three');
    const { data: fastPassTier } = useTierBySlug('fast-pass');

    const { subscriptionTier } = useSubscription(accountId);

    const activeBaseSubscriptionItem = computed(() => {
      if (!account.value?.subscription) return null;

      return account.value?.subscription.subscription_items.find(
        x => x.subscription_item_type === 'base'
      );
    });

    const activeFastPassSubscriptionItem = computed(() => {
      if (!account.value?.subscription) return null;

      return account.value?.subscription.subscription_items.find(
        x => x.subscription_item_type === 'add_on'
      );
    });

    const fastPassSubscriptionPrice = computed(() => {
      return fastPassTier.value?.product.prices.find(
        x =>
          x.recurring_interval ===
          (activeBaseSubscriptionItem.value?.recurring_interval || 'month')
      );
    });

    const activeSubscriptionId = computed(() => {
      if (!account.value?.subscription) return null;

      return account.value?.subscription.id;
    });

    // add/remove tier 3 business tier
    const showSubscriptionConfirmationAddModal = ref(false);
    const showSubscriptionConfirmationRemoveModal = ref(false);

    const openSubscriptionConfirmationAddModal = () => {
      showSubscriptionConfirmationAddModal.value = true;
    };
    const closeSubscriptionConfirmationAddModal = () => {
      showSubscriptionConfirmationAddModal.value = false;
    };

    const openSubscriptionConfirmationRemoveModal = () => {
      showSubscriptionConfirmationRemoveModal.value = true;
    };
    const closeSubscriptionConfirmationRemoveModal = () => {
      showSubscriptionConfirmationRemoveModal.value = false;
    };

    // add/remove fast pass
    const showConfirmationAddModal = ref(false);
    const showConfirmationRemoveModal = ref(false);

    const openConfirmationAddModal = () => {
      showConfirmationAddModal.value = true;
    };
    const closeConfirmationAddModal = () => {
      showConfirmationAddModal.value = false;
    };

    const openConfirmationRemoveModal = () => {
      showConfirmationRemoveModal.value = true;
    };
    const closeConfirmationRemoveModal = () => {
      showConfirmationRemoveModal.value = false;
    };

    const addBusinessSubscription = () => {
      addBusinessSubscriptionItem(
        {
          accountId: accountId.value,
        },
        {
          onSuccess: () => {
            showSubscriptionConfirmationAddModal.value = false;
          },
        }
      );
    };

    const removeBusinessSubscription = () => {
      removeBusinessSubscriptionItem(
        {
          accountId: accountId.value,
        },
        {
          onSuccess: () => {
            showSubscriptionConfirmationRemoveModal.value = false;
          },
        }
      );
    };

    const createOrUpdateSubscription = () => {
      if (!account.value?.subscription) {
        let startDate = new Date();

        createSubscription(
          {
            accountId: accountId.value,
            billingCycleAnchor: startDate,
            isInternal: true,
            priceIds: [fastPassSubscriptionPrice.value?.id],
            slug: 'fast-pass',
            startDate: startDate,
            useDefaultMethod: true,
          },
          {
            onSuccess: () => {
              showConfirmationAddModal.value = false;
            },
          }
        );
      } else {
        updateSubscription(
          {
            add_price_ids: [fastPassSubscriptionPrice.value?.id],
            remove_price_ids: [],
            subscription_id: activeSubscriptionId.value,
            is_internal: true,
          },
          {
            onSuccess: () => {
              showConfirmationAddModal.value = false;
            },
          }
        );
      }
    };

    const {
      mutate: cancelSubscriptionItem,
      error: cancelFastPassError,
      isLoading: fastPastRemoving,
    } = useCancelSubscriptionItem(accountId);

    const cancelFastPassItem = () => {
      let body = {
        cancellation_details: 'staff removed fast pass',
        cancellation_reason: 'no_longer_need_storage',
        remove_item_id: activeFastPassSubscriptionItem.value?.id,
        subscription_id: activeSubscriptionId.value,
        is_internal: true,
      };
      cancelSubscriptionItem(body, {
        onSuccess: () => {
          showConfirmationRemoveModal.value = false;
        },
      });
    };

    // accounts notes
    const notesCurrentPage = ref(1);
    const notesItemsPerPage = ref(10);
    // go back to the first page when itemsPerPage changes
    watch(notesItemsPerPage, () => {
      notesCurrentPage.value = 1;
    });

    const { data: accountNotesData } = useNotes(
      'accounts',
      accountId,
      {
        currentPage: notesCurrentPage,
        itemsPerPage: notesItemsPerPage,
      },
      { placeholderData: keepPreviousData }
    );
    const {
      mutate: createNote,
      isPending: isPendingNote,
      error: createNoteError,
    } = useCreateNote('accounts', accountId);
    const { mutate: deleteNote } = useDeleteNote('accounts', accountId);

    const createNewUserNote = (reply, assets, noteType) => {
      createNote({
        content: reply,
        assets: assets,
        note_type: noteType,
      });
    };

    const deleteUserNote = note => {
      deleteNote(
        { noteId: note.id },
        {
          onSuccess: () => {
            notesCurrentPage.value = 1;
          },
        }
      );
    };

    const accountNotesPagination = computed(() => {
      if (accountNotesData.value) {
        return accountNotesData.value.pagination;
      } else {
        return {
          totalCount: 0,
          currentPage: 0,
          totalPages: 0,
          itemsPerPage: 0,
        };
      }
    });

    const priorityError = usePriorityError(
      subscriptionCreatingError,
      subscriptionUpdatingError,
      businessSubscriptionItemAddingError,
      businessSubscriptionItemRemovingError,
      cancelFastPassError
    );

    return {
      accountNotes: computed(() => accountNotesData.value?.notes ?? []),
      accountNotesPagination,
      isPendingNote,
      createNoteError,
      capabilities,
      hasSoonaStorage,
      isDigitalSubscriber,
      hasShopifyIntegration,
      soonaStorageCurrentPeriodEnd,
      soonaStoragePendingCancellation,
      notesCurrentPage,
      notesItemsPerPage,
      createNewUserNote,
      deleteUserNote,
      canEnrollInFastPass,
      createOrUpdateSubscription,
      addBusinessSubscription,
      removeBusinessSubscription,
      cancelFastPassItem,
      openConfirmationAddModal,
      closeConfirmationAddModal,
      openConfirmationRemoveModal,
      closeConfirmationRemoveModal,
      manageEnterpriseBusinessTierFlag,
      openSubscriptionConfirmationAddModal,
      openSubscriptionConfirmationRemoveModal,
      closeSubscriptionConfirmationAddModal,
      closeSubscriptionConfirmationRemoveModal,
      showSubscriptionConfirmationAddModal,
      showSubscriptionConfirmationRemoveModal,
      showConfirmationAddModal,
      showConfirmationRemoveModal,
      subscriptionCreating,
      subscriptionUpdating,
      businessSubscriptionItemAdding,
      businessSubscriptionItemRemoving,
      priorityError,
      businessTier,
      fastPassTier,
      activeBaseSubscriptionItem,
      subscriptionTier,
      fastPastRemoving,
    };
  },
  data() {
    return {
      showTransferModal: false,
      showChangeBookingModal: false,
      showTechSupportDropdown: false,
      newAccountId: null,
      transferQuotes: false,
      errorMessages: undefined,
      loading: false,
      showFlagModal: false,
      selectedFlags: {},
      reRenderFlagSelector: 0,
      transferBookingIsLoading: false,
    };
  },
  computed: {
    ...mapState({
      account: state => state.account,
      accountFlags: state => state.account.flags,
      accountAccountsFlags: state => state.account.accounts_flags,
      subscription: state => state.account.subscription,
      flags: state => state.flag.flags,
    }),
    ...mapGetters('collections', ['bagCollection']),
    stripe_dashboard() {
      return import.meta.env.VITE_STRIPE_DASHBOARD_URL;
    },
    currentPage() {
      return 'crewTools';
    },
    accountHasFlags() {
      return !!this.account.flags?.length;
    },
  },
  methods: {
    ...mapActions('account', [
      'updateIsVipStatus',
      'transferReservations',
      'removeFlag',
    ]),
    ...mapActions('flag', ['loadAllFlags', 'updateAccountFlags']),
    async updateFlags() {
      let flagsArray = [];
      for (let flagId in this.selectedFlags) {
        flagsArray.push(this.selectedFlags[flagId]);
      }
      await this.updateAccountFlags({
        accountId: this.accountId,
        newflags: flagsArray,
      });
      this.closeFlagModal();
    },
    flagToggle(_, flag) {
      if (this.selectedFlags[flag.id]) {
        this.selectedFlags[flag.id]._destroy =
          !this.selectedFlags[flag.id]._destroy;
      } else {
        this.selectedFlags[flag.id] = {
          account_id: this.accountId,
          flag_id: flag.id,
          _destroy: false,
        };
      }
    },
    isFlagOnAccount(flag) {
      return this.selectedFlags[flag.id] !== undefined;
    },
    async openFlagModal() {
      this.reRenderFlagSelector++;
      this.selectedFlags = {};
      for (let i = 0; i < this.accountFlags.length; i++) {
        this.selectedFlags[this.accountFlags[i].id] = {
          id: this.accountAccountsFlags[i].id,
          account_id: this.accountId,
          flag_id: this.accountFlags[i].id,
          _destroy: false,
        };
      }
      await this.loadAllFlags();
      this.showFlagModal = true;
    },
    closeFlagModal() {
      this.showFlagModal = false;
    },
    toggleVipStatus() {
      return this.updateIsVipStatus({ accountId: this.account.id });
    },
    transferBookingToNewUser() {
      this.transferBookingIsLoading = true;
      this.transferReservations({
        newAccountId: this.newAccountId,
        transferQuotes: JSON.parse(this.transferQuotes),
      }).then(([, errors]) => {
        if (errors) {
          this.errorMessages = errors;
          this.transferBookingIsLoading = false;
        } else {
          window.location.reload();
        }
      });
    },
    closeTransferModal() {
      this.errorMessages = undefined;
      return (this.showTransferModal = false);
    },
  },
};
</script>
<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.crew-tools-container {
  margin-bottom: 1.5rem;
  padding: 1rem;
  border-radius: 0.3125rem;
  background-color: variables.$periwink-blue-10;
  border: 0.0625rem solid variables.$gray-30;

  &__customer-name {
    @include variables_fonts.u-label--heavy;

    color: variables.$black-default;
    margin-bottom: 0.5rem;
  }

  &__actions {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;

    .business-tier {
      margin-right: 0.5rem;
    }
  }
}

.account-flag-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.flag-modal {
  .collab-select {
    max-height: 364px;
    max-width: 617px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    li {
      display: flex;
      width: 50%;
      margin-top: 10px;
      .flag-checkbox {
        margin-right: 5px;
        display: flex;
        align-items: center;
      }
      :deep(label span) {
        height: 15px;
        width: 15px;
        border: 2px solid variables.$gray-80;
        border-radius: 5px;
        display: inline-block;
        background-color: variables.$white-default;
        transition: background-color 0.3s;
      }
      :deep(label span:hover) {
        border: 2px solid variables.$friendly-red-50;
        cursor: pointer;
      }
      :deep([type='checkbox']:checked + span) {
        background-color: variables.$friendly-red-50;
        color: variables.$white-default;
        border: 2px solid variables.$friendly-red-50;
      }
      :deep([type='checkbox']:checked + span:before) {
        content: '\2714';
        position: relative;
        font-size: 10pt;
        font-weight: 400;
        bottom: 8px;
        left: 0px;
      }
    }
  }
}
</style>
