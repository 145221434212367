<script setup>
import { computed, ref } from 'vue';
import { useQueryClient } from '@tanstack/vue-query';

import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaUploadDragAndDropModal from '@/components/uploader/SoonaUploadDragAndDropModal.vue';
import SoonaUploadProgressModal from '@/components/uploader/SoonaUploadProgressModal.vue';

import { useUploader } from '@/composables/useUploader.js';
import { queryKeys } from '@/queries/query-keys';
import { useCreateReservationFile } from '@/queries/reservation-files/useCreateReservationFile.js';

const props = defineProps({
  orderId: {
    type: [Number, String],
    required: true,
  },
  reservationId: {
    type: [Number, String],
    required: true,
  },
  originalReservationFileId: {
    type: [Number, String],
  },
  variation: {
    type: String,
    default: 'secondary-black',
  },
});

const orderId = computed(() => props.orderId);
const reservationId = computed(() => props.reservationId);
const originalReservationFileId = computed(
  () => props.originalReservationFileId
);

const queryClient = useQueryClient();

const { mutate: createReservationFile } =
  useCreateReservationFile(reservationId);

const createEditFromUpload = async blob => {
  const reservationFile = {
    title: blob.filename,
    media_type: blob.content_type.startsWith('image') ? 0 : 1,
    file: blob.signed_id,
    edit_status: 'pending_edit',
    created_by_app: 'web',
    payment_status: 'paid',
  };

  if (originalReservationFileId.value) {
    reservationFile['reservation_file_originals_attributes'] = [
      {
        original_reservation_file_id: originalReservationFileId.value,
      },
    ];
  }

  createReservationFile(reservationFile, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.orderAddOns(orderId),
      });
    },
  });
};

const dragAndDropModalVisible = ref(false);
const maxConcurrent = ref(5);
const uploadInterval = ref(100);
const fileSizeLimit = ref('');
const label = ref('');
const accept = ref('');
const uploadType = ref('');
const handleUploadFunc = ref(null);
const startUploadFunc = ref(null);
const handleDropFunc = ref(null);
const handleCancelFunc = ref(null);
const activeUploadsLocal = ref([]);

function validateNotCR2(file) {
  if (file.name.toLowerCase().endsWith('.cr2'))
    return 'cannot upload RAW (CR2) files here';
}

function hideDragAndDropModal() {
  dragAndDropModalVisible.value = false;
}

function onUploadComplete(blob) {
  createEditFromUpload(blob);
}

function showDragAndDropModalEdit() {
  label.value = 'edits';
  accept.value = '.mp4, .jpeg, .jpg, .png, .gif';
  fileSizeLimit.value = '';
  const {
    handleUpload,
    handleDrop,
    activeUploads,
    cancelUploads,
    startUpload,
  } = useUploader(
    maxConcurrent,
    uploadInterval,
    fileSizeLimit,
    validateNotCR2,
    null,
    onUploadComplete
  );
  startUploadFunc.value = startUpload;
  handleUploadFunc.value = handleUpload;
  handleDropFunc.value = handleDrop;
  handleCancelFunc.value = cancelUploads;
  activeUploadsLocal.value = activeUploads.value;
  uploadType.value = 'edit';
  dragAndDropModalVisible.value = true;
}
</script>
<template>
  <SoonaButton
    :variation="variation"
    size="medium"
    copy="upload edits"
    @click="showDragAndDropModalEdit()"
  />
  <SoonaUploadDragAndDropModal
    v-if="dragAndDropModalVisible"
    :accept="accept"
    :active-uploads="activeUploadsLocal"
    :is-multiple="true"
    :label="label"
    @close="hideDragAndDropModal"
    @handle-drop="handleDropFunc"
    @handle-upload="handleUploadFunc"
  />
  <SoonaUploadProgressModal
    v-if="activeUploadsLocal.length > 0"
    :is-multiple="true"
    :active-uploads="activeUploadsLocal"
    :label="label"
    :hide-label="false"
    @cancel-uploads="handleCancelFunc"
    @start-upload="startUploadFunc"
  />
</template>
