<script setup>
import { computed, ref, watch, watchEffect } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaForm from '@/components/ui_library/SoonaForm.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';
import SoonaSelect from '@/components/ui_library/SoonaSelect.vue';

const props = defineProps({
  editMode: {
    default: false,
    type: Boolean,
  },
  formId: {
    type: String,
    required: true,
  },
  cancelEdits: {
    default: false,
    type: Boolean,
  },
  showFormHeader: {
    default: true,
    type: Boolean,
  },
  schedulingPreferences: {
    type: Array,
    default: () => [],
  },
  schedulingNotes: {
    type: String,
    default: '',
  },
  schedulingPreferredTimeZone: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['submit', 'reset-cancel-edits']);

const days = [
  { label: 'monday', value: 'monday' },
  { label: 'tuesday', value: 'tuesday' },
  { label: 'wednesday', value: 'wednesday' },
  { label: 'thursday', value: 'thursday' },
  { label: 'friday', value: 'friday' },
];

const slots = [
  { label: 'morning: 8am - 12pm', value: 'morning' },
  { label: 'midday: 11am - 3pm', value: 'afternoon' },
  { label: 'afternoon: 2pm - 6pm', value: 'evening' },
];

const timeZoneOptions = [
  { label: 'eastern (ET)', value: 'eastern (ET)' },
  { label: 'central (CT)', value: 'central (CT)' },
  { label: 'mountain (MT)', value: 'mountain (MT)' },
  { label: 'pacific (PT)', value: 'pacific (PT)' },
];

const isExpanded = ref({});
const availablity = ref([]);
const notes = ref('');
const selectedTimeZone = ref(null);

const schedulingPreferences = computed(() => props.schedulingPreferences);
const schedulingNotes = computed(() => props.schedulingNotes);
const schedulingPreferredTimeZone = computed(
  () => props.schedulingPreferredTimeZone
);
const editMode = computed(() => props.editMode);
const cancelEdits = computed(() => props.cancelEdits);

watchEffect(() => {
  if (schedulingPreferences.value) {
    availablity.value = schedulingPreferences.value;
    notes.value = schedulingNotes.value;
    selectedTimeZone.value = schedulingPreferredTimeZone.value;
    isExpanded.value = schedulingPreferences.value.reduce((acc, slot) => {
      acc[slot.day] = true;
      return acc;
    }, {});
  }
});

watch(
  () => cancelEdits.value,
  () => {
    if (cancelEdits.value) {
      availablity.value = schedulingPreferences.value;
      notes.value = schedulingNotes.value;
      selectedTimeZone.value = schedulingPreferredTimeZone.value;
      isExpanded.value = schedulingPreferences.value.reduce((acc, slot) => {
        acc[slot.day] = true;
        return acc;
      }, {});

      emit('reset-cancel-edits');
    }
  }
);

const preferenceExists = (day, slot) => {
  return !!availablity.value.find(
    availability => availability.day === day && availability.period === slot
  );
};

// toggling
const toggleSlot = (day, slot) => {
  if (!editMode.value) return;

  if (preferenceExists(day, slot)) {
    availablity.value = availablity.value.map(availability => {
      if (
        availability.day === day &&
        availability.period === slot &&
        availability._destroy === true
      ) {
        return { ...availability, _destroy: false };
      } else if (
        availability.day === day &&
        availability.period === slot &&
        (!availability._destroy || availability._destroy === false)
      ) {
        return { ...availability, _destroy: true };
      }
      return availability;
    });
  } else {
    availablity.value = [...availablity.value, { day, period: slot }];
  }
};

const toggleAllDaySlots = day => {
  if (!editMode.value) return;

  isExpanded.value[day]
    ? (isExpanded.value[day] = false)
    : (isExpanded.value[day] = true);

  if (isExpanded.value[day] === true) {
    slots.forEach(slot => {
      if (preferenceExists(day, slot.value)) {
        availablity.value = availablity.value.map(availability => {
          if (availability?.day === day) {
            return { ...availability, _destroy: false };
          }
          return availability;
        });
      } else {
        availablity.value = [...availablity.value, { day, period: slot.value }];
      }
    });
  } else if (isExpanded.value[day] === false) {
    availablity.value = availablity.value.map(availability => {
      if (availability?.day === day) {
        return { ...availability, _destroy: true };
      }
      return availability;
    });
  }
};

// checked state
const checkedRangeAvailability = (day, range) => {
  return !!availablity.value.find(
    availability =>
      availability?.day === day &&
      availability?.period === range &&
      (!availability._destroy || availability._destroy === false)
  );
};

const checkedDayAvailability = day => {
  return !!availablity.value.find(
    availability =>
      availability?.day === day &&
      (!availability._destroy || availability._destroy === false)
  );
};

// flexible scheduling
const getAllAvailableSlots = () => {
  let availableSlots = schedulingPreferences.value?.slice();

  days.forEach(day => {
    slots.forEach(slot => {
      if (
        !schedulingPreferences.value.find(
          availablity =>
            availablity.day === day.value && availablity.period === slot.value
        )
      ) {
        availableSlots.push({ day: day.value, period: slot.value });
      }
    });
  });

  return availableSlots;
};

const allDaysSlotsSelected = () => {
  return days.every(day => {
    return slots.every(slot => {
      return checkedRangeAvailability(day.value, slot.value);
    });
  });
};

const toggleAllAvailableSlots = () => {
  if (!editMode.value) return;

  if (allDaysSlotsSelected()) {
    availablity.value = availablity.value.map(availability => {
      return { ...availability, _destroy: true };
    });
    isExpanded.value = {};
  } else {
    availablity.value = getAllAvailableSlots();
    isExpanded.value = availablity.value.reduce((acc, slot) => {
      acc[slot.day] = true;
      return acc;
    }, {});
  }
};
</script>

<template>
  <SoonaForm
    :id="formId"
    class="scheduling-preferences"
    :aria-labelledby="`${formId}-heading`"
    @submit="() => emit('submit', { availablity, notes, selectedTimeZone })"
  >
    <h4 v-if="showFormHeader" class="scheduling-preferences__title">
      which days of the week are you generally available?
    </h4>
    <div class="scheduling-preferences__preferred-time-zone">
      <SoonaSelect
        v-model:model-value="selectedTimeZone"
        placeholder="select your preferred time zone"
        :options="timeZoneOptions"
        :disabled="!editMode"
      >
        <template #label>preferred time zone</template>
      </SoonaSelect>
    </div>

    <hr class="u-divider" />

    <div class="selection-wrapper">
      <SoonaButton
        :aria-disabled="!editMode"
        :aria-pressed="allDaysSlotsSelected()"
        class="availability-toggle availability-toggle--day"
        :class="{ 'un-editable': !editMode }"
        copy="i’m flexible"
        variation="secondary-gray"
        @on-click="toggleAllAvailableSlots()"
      >
        <template #icon-left>
          <SoonaIcon
            v-if="allDaysSlotsSelected()"
            class="check-icon"
            name="circle-check-solid"
            size="small"
            style="vertical-align: middle"
          />
          <SoonaIcon
            v-else
            name="plus"
            size="small"
            style="vertical-align: middle"
          />
        </template>
      </SoonaButton>
    </div>
    <div v-for="day in days" :key="day.value" class="selection-wrapper">
      <SoonaButton
        :id="`availability-header-${day.value}`"
        :aria-controls="`availability-panel-${day.value}`"
        :aria-disabled="!editMode"
        :aria-expanded="isExpanded[day.value]"
        :aria-pressed="checkedDayAvailability(day.value)"
        class="availability-toggle availability-toggle--day"
        :class="{
          'un-editable': !editMode,
        }"
        variation="secondary-gray"
        @on-click="toggleAllDaySlots(day.value)"
      >
        <template #icon-left>
          <SoonaIcon
            v-if="checkedDayAvailability(day.value)"
            class="check-icon"
            name="circle-check-solid"
            size="small"
            style="vertical-align: middle"
          />
          <SoonaIcon
            v-else
            name="plus"
            size="small"
            style="vertical-align: middle"
          />
        </template>
        {{ day.label }}
      </SoonaButton>
      <div
        :id="`availability-panel-${day.value}`"
        :ref="`availability-panel-${day.value}`"
        :aria-labelledby="`availability-header-${day.value}`"
        class="selection-wrapper selection-wrapper--time"
        role="region"
      >
        <SoonaButton
          v-for="slot in slots"
          :key="slot.value"
          :aria-disabled="!editMode"
          :aria-pressed="checkedRangeAvailability(day.value, slot.value)"
          class="availability-toggle availability-toggle--time"
          :class="{
            'un-editable': !editMode,
            'availability-toggle--open': isExpanded[day.value],
          }"
          variation="secondary-gray"
          @on-click="toggleSlot(day.value, slot.value)"
        >
          <template #icon-left>
            <SoonaIcon
              v-if="checkedRangeAvailability(day.value, slot.value)"
              class="check-icon disabled"
              name="circle-check-solid"
              size="small"
              style="vertical-align: middle"
            />
            <SoonaIcon
              v-else
              name="plus"
              size="small"
              style="vertical-align: middle"
            />
          </template>
          {{ slot.label }}
        </SoonaButton>
      </div>
    </div>

    <hr />

    <SoonaTextfield
      v-model="notes"
      :disabled="!editMode"
      label="notes to the crew"
      :maxlength="150"
      placeholder="let us know any specific dates that do not work this month"
      type="text"
    />
  </SoonaForm>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.scheduling-preferences {
  &__sub-heading {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__title {
    @include variables_fonts.u-title--heavy;

    margin-bottom: 1rem;
  }

  &__preferred-time-zone {
    margin-bottom: 1rem;
    max-width: variables.$screen-xxs-min;
  }

  .check-icon {
    color: variables.$green-apple-50;
  }

  .selection-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    &--time {
      width: 80%;
    }

    .availability-toggle {
      align-items: center;
      border-radius: variables.$radius-small;
      margin-bottom: 1rem;
      width: 100%;

      &--day {
        margin-bottom: 1rem;
        width: 100%;
      }

      &--time {
        display: none;
      }

      &--open {
        display: inherit;
        height: auto;
      }

      &.un-editable {
        color: variables.$gray-50;
        background-color: variables.$gray-20;
        border-color: variables.$gray-20;
        cursor: not-allowed;
      }

      &[aria-pressed='true'] {
        border-color: variables.$black-default;
        background-color: variables.$green-apple-10;
        color: variables.$black-default;

        &.un-editable {
          border-color: variables.$black-default;
          background-color: variables.$green-apple-10;
          cursor: not-allowed;
        }
      }

      &:hover,
      &:focus-visible {
        background-color: variables.$green-apple-10;
        box-shadow: variables.$elevation-0;
        transition: box-shadow 0.2s ease-out;

        &.un-editable {
          background-color: variables.$gray-20;
          box-shadow: none;
          cursor: not-allowed;
          transition: none;
        }

        &[aria-pressed='true'] {
          background-color: variables.$green-apple-10;
          box-shadow: none;
          transition: none;
        }
      }
    }
  }
}
</style>
